<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (total_items == 0))" class="alert alert-warning" role="alert">
            There are no flytes sent
        </div>
        <div v-if="(total_items > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-6">Flyte sent</div>
                                        <div class="col-6">Number of files</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row" :id="'flyte-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-6" style="padding-top: 3px">
                                            <loading v-if="!item.sent_at_formatted" size="1" align="left"></loading>
                                            <h5 v-else class="font-size-14 mb-0">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleFlyteDetails(item.id)"
                                                >
                                                    {{ item.sent_at_formatted }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-sm-5 col-4" style="padding-top: 3px">
                                            <loading v-if="(item.files_count === null)" size="1" align="left"></loading>
                                            <p v-else class="mb-0">{{ item.files_count }}</p>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleFlyteDetails(item.id)"
                                            >
                                                <span
                                                    v-if="(flyte_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'flyte-details' + item.id">
                                            <flyte-sent-details :share="item"></flyte-sent-details>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'my-account-flytes-sent',
                loading: false,
                errors: false,
                items: [],
                total_items: 0,
                current_page: 1,
                last_page: 1,
                flyte_details_open: []
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            $('body').removeClass('login-body');
            this.getFlytesSent();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            total_items: function (val) {
                this.$root.status_info.right = this.total_items + ' flyte';
                if (this.total_items !== 1) {
                    this.$root.status_info.right += 's';
                }
            }
        },
        methods: {
            getFlytesSent: function (page = 1) {
                if (this.loading || (page < 1) || ((page > 1) && (page <= this.current_page))) {
                    return false;
                }
                this.current_page = page;
                this.loading = true;
                this.errors = false;
                if (page == 1) {
                    this.items = [];
                    this.total_items = 0;
                    this.current_page = 1;
                    this.last_page = 1;
                    this.flyte_details_open = [];
                }

                /**
                 * Send get flytes sent request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/report/flytes-sent/cb::'
                    + this.$root.user.id + '?page=' + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.total_items = response.data.flytes.total;
                    vm.current_page = response.data.flytes.current_page;
                    vm.last_page = response.data.flytes.last_page;
                    response.data.flytes.data.forEach((item) => {
                        item.sent_at_formatted = null;
                        item.files_count = null;
                        vm.items.push(item);
                    });
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            handleScroll(event) {
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < 200) {
                        this.getFlytesSent(this.current_page + 1);
                    }
                }
            },
            toggleFlyteDetails: function (id) {
                if (this.flyte_details_open.indexOf(id) < 0) {
                    this.flyte_details_open.push(id);
                    $('#flyte-row' + id).addClass('highlighted');
                    $('#flyte-details' + id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#flyte-details' + id).slideUp('slow', function () {
                        $('#flyte-row' + id).removeClass('highlighted');
                        for (var n = 0; n < vm.flyte_details_open.length; n++) { 
                            if (vm.flyte_details_open[n] == id) { 
                                vm.flyte_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            }
        }
    }
</script>
