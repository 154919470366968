<template>
    <div>
    </div>
</template>

<script>
    export default {
        mounted () {
            $('body').removeClass('login-body');

            var vm = this;
            setTimeout(function () {
                const component = vm.$root.findComponent(vm.$parent, 'email-support');
                if (component) {
                    component.resetForm(vm.$route.params.subject || null);
                }
                $('#email-support-modal').modal('show');

                if (!vm.$root.previous_route) {
                    if (vm.$root.isLoggedIn()) {
                        vm.$router.push({ name: vm.$root.isProofsGuest() ? 'proofs' : 'home' });

                        return null;
                    }
                    vm.$router.push({ name: 'login' });

                    return null;
                }
                if (vm.$root.previous_route == 'login') {
                    vm.$router.push({ name: vm.$root.isProofsGuest() ? 'proofs' : 'home' });

                    return null;
                }

                vm.$router.push({ name: vm.$root.previous_route });
            }, 200);
        }
    }
</script>
