<template>
    <div class="topnav page-topnav" style="padding: 0 12px; max-height: none; overflow-y: visible">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand topnav-menu">
                <div class="navbar-collapse align-items-center justify-content-between">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <h6>{{ pageTitle() }}</h6>
                        </li>
                    </ul>
                    <ul class="navbar-nav">
                        <!--<li class="nav-item d-none d-md-inline">
                            <breadcrumbs :items="breadcrumbItems()"></breadcrumbs>
                        </li>-->
                        <li v-if="hasAction()" class="nav-item" style="width: 100px; height: 36.5px">
                            <div class="btn-group" style="position: absolute; height: 36.5px; right: 0">
                                <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    Actions
                                    <i
                                        class="fas fa-chevron-down"
                                        style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                    ></i>
                                </button>
                                <div v-if="($route.name == 'keyword-categories')" class="dropdown-menu dropdown-menu-right">
                                    <a
                                        class="dropdown-item"
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#keyword-category-add-form-modal"
                                        @click.prevent="resetKeywordCategoryAddForm()"
                                    >
                                        <i class="far fa-plus mr-1"></i> Add keyword category
                                    </a>
                                </div>
                                <div v-if="($route.name == 'user-groups')" class="dropdown-menu dropdown-menu-right">
                                    <a
                                        class="dropdown-item"
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#user-group-add-form-modal"
                                        @click.prevent="resetUserGroupAddForm()"
                                    >
                                        <i class="far fa-users-medical mr-1"></i> Add user group
                                    </a>
                                </div>
                                <div
                                    v-if="($route.name == 'pre-approved-email-domains')"
                                    class="dropdown-menu dropdown-menu-right"
                                >
                                    <a
                                        class="dropdown-item"
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#pre-approved-email-domain-add-form-modal"
                                        @click.prevent="resetPreApprovedEmailDomainAddForm()"
                                    >
                                        <i class="far fa-plus mr-1"></i> Add domain
                                    </a>
                                </div>
                                <div
                                    v-if="($route.name == 'detected-label-exclusions')"
                                    class="dropdown-menu dropdown-menu-right"
                                >
                                    <a
                                        class="dropdown-item"
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#detected-label-exclusion-add-form-modal"
                                        @click.prevent="resetDetectedLabelExclusionAddForm()"
                                    >
                                        <i class="far fa-plus mr-1"></i> Add keyword
                                    </a>
                                </div>
                                <div
                                    v-if="($route.name == 'alerts')"
                                    class="dropdown-menu dropdown-menu-right"
                                >
                                    <a
                                        class="dropdown-item"
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#alert-add-form-modal"
                                        @click.prevent="resetAlertAddForm()"
                                    >
                                        <i class="far fa-user-plus mr-1"></i> Add administrator
                                    </a>
                                </div>
                                <div
                                    v-if="($route.name == 'print-order-copy-recipients')"
                                    class="dropdown-menu dropdown-menu-right"
                                >
                                    <a
                                        class="dropdown-item"
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#print-order-copy-recipient-add-form-modal"
                                        @click.prevent="resetPrintOrderCopyRecipientAddForm()"
                                    >
                                        <i class="far fa-user-plus mr-1"></i> Add recipient
                                    </a>
                                </div>
                                <div
                                    v-if="($route.name == 'asset-reviews')"
                                    class="dropdown-menu dropdown-menu-right"
                                >
                                    <span v-if="hasExport('xls')">
                                        <span v-if="!downloading">
                                            <a
                                                class="dropdown-item"
                                                href="#"
                                                @click.prevent="downloadExport('xls')"
                                            >
                                                <i class="far fa-download mr-1"></i> Excel
                                            </a>
                                        </span>
                                        <span v-else>
                                            <span class="text-muted" style="padding-left: 24px; line-height: 30px">
                                                <i class="far fa-spinner-third fa-spin mr-1"></i> 
                                                <span style="position: relative; top: 1px">Excel</span>
                                            </span>
                                        </span>
                                    </span>
                                    <span v-if="hasSelectedItems()">
                                        <a
                                            class="dropdown-item"
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#asset-reviews-bulk-edit-modal"
                                            @click="resetAssetReviewsBulkEditModal()"
                                        >
                                            <i class="far fa-edit mr-1"></i> Bulk actions
                                        </a>
                                    </span>
                                    <span v-else>
                                        <span class="text-muted" style="padding-left: 24px; line-height: 30px">
                                            <i class="far fa-edit mr-1"></i> Bulk actions
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li v-if="(!hasAction() && hasExport())" class="nav-item" style="height: 36.5px">
                            <div style="position: absolute; height: 36.5px; right: 0">
                                <span v-if="hasExport('csv')">
                                    <button
                                        v-if="!downloading"
                                        :key="'download-csv-export'"
                                        type="button"
                                        class="btn btn-primary"
                                        @click.prevent="downloadExport('csv')"
                                    >
                                        <i class="far fa-download mr-1"></i> CSV
                                    </button>
                                    <button v-else :key="'downloading-csv-export'" type="button" class="btn btn-primary">
                                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                        <span style="opacity: 0.5">CSV</span>
                                    </button>
                                </span>
                                <span v-if="hasExport('xls')">
                                    <button
                                        v-if="!downloading"
                                        :key="'download-xls-export'"
                                        type="button"
                                        class="btn btn-primary"
                                        @click.prevent="downloadExport('xls')"
                                    >
                                        <i class="far fa-download mr-1"></i> Excel
                                    </button>
                                    <button v-else :key="'downloading-xls-export'" type="button" class="btn btn-primary">
                                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                        <span style="opacity: 0.5">Excel</span>
                                    </button>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['csv_download', 'xls_download'],
        data () {
            return {
                downloading: false
            }
        },
        mounted () {
            //
        },
        methods: {
            pageTitle: function () {
                if (this.$route.name == 'custom-fields') {
                    return 'Custom fields';
                }
                if (this.$route.name == 'keyword-categories') {
                    return 'Keyword assistant';
                }
                if (this.$route.name == 'user-groups') {
                    return 'User groups';
                }
                if (this.$route.name == 'pre-approved-email-domains') {
                    return 'Auto-approve domains';
                }
                if (this.$route.name == 'asset-approvals') {
                    return this.$root.ucfirst(this.$root.contents.asset_title) + ' approvals';
                }
                if (this.$route.name == 'artwork-approvals') {
                    return this.$root.ucfirst(this.$root.contents.template_title) + ' marketing approvals';
                }
                if (this.$route.name == 'asset-reviews') {
                    return this.$root.ucfirst(this.$root.contents.asset_title) + 's due for review';
                }
                if (this.$route.name == 'alerts') {
                    return 'Administrator alerts';
                }
                if (this.$route.name == 'reports-overview') {
                    return 'Reports - system overview';
                }
                if (this.$route.name == 'reports-registrations') {
                    return 'Reports - registrations';
                }
                if (this.$route.name == 'reports-visits') {
                    return 'Reports - visits';
                }
                if (this.$route.name == 'reports-asset-uploads') {
                    return 'Reports - uploads';
                }
                if (this.$route.name == 'reports-asset-downloads') {
                    return 'Reports - downloads';
                }
                if (this.$route.name == 'reports-flytes-sent') {
                    return 'Reports - flyte activity';
                }
                if (this.$route.name == 'reports-artwork-created') {
                    return 'Reports - ' + this.$root.contents.template_title + 's created';
                }
                if (this.$route.name == 'reports-templates') {
                    return 'Reports - ' + this.$root.contents.template_title + 's';
                }
                if (this.$route.name == 'reports-orders') {
                    return 'Reports - orders';
                }
                if (this.$route.name == 'profile-fields') {
                    return this.$root.ucfirst(this.$root.contents.template_title) + ' profile field set';
                }
                if (this.$route.name == 'print-order-copy-recipients') {
                    return 'Copy item order recipients';
                }
                if (this.$route.name == 'detected-label-exclusions') {
                    return 'AI keyword exclusion list';
                }

                return 'Settings';
            },
            /*breadcrumbItems: function () {
                let items = [{
                    id: 0,
                    text: "Settings",
                    link: 'settings'
                }];
                if (this.$route.name == 'settings') {
                    items.push({
                        id: 1,
                        text: "Overview",
                        active: true
                    });
                }
                if (this.$route.name == 'custom-fields') {
                    items.push({
                        id: 1,
                        text: "Custom fields",
                        active: true
                    });
                }
                if (this.$route.name == 'keyword-categories') {
                    items.push({
                        id: 1,
                        text: "Keyword assistant",
                        active: true
                    });
                }
                if (this.$route.name == 'user-groups') {
                    items.push({
                        id: 1,
                        text: "User groups",
                        active: true
                    });
                }

                return items;
            },*/
            hasAction: function () {
                if ((this.$route.name == 'detected-label-exclusions') && !this.$root.client.assets_use_detected_labels) {
                    return false;
                }

                const pages = [
                    'keyword-categories',
                    'user-groups',
                    'pre-approved-email-domains',
                    'alerts',
                    'asset-reviews',
                    'print-order-copy-recipients',
                    'detected-label-exclusions'
                ];

                return (pages.indexOf(this.$route.name) > -1);
            },
            hasExport: function (format = null) {
                if (!this.csv_download && !this.xls_download) {
                    return false;
                }
                if ((format != 'xls') && this.csv_download) {
                    const csvPages = [
                        'reports-registrations',
                        'reports-visits',
                        'reports-asset-uploads',
                        'reports-artwork-created',
                        'reports-asset-downloads'
                    ];

                    if (csvPages.indexOf(this.$route.name) > -1) {
                        return true;
                    }
                }
                if ((format != 'csv') && this.xls_download) {
                    const xlsPages = [
                        'asset-reviews',
                        'reports-orders',
                        'reports-templates',
                        'users'
                    ];

                    if (xlsPages.indexOf(this.$route.name) > -1) {
                        return true;
                    }
                }

                return false;
            },
            resetKeywordCategoryAddForm: function () {
                const pageComponent = this.$root.findComponent(this.$parent.$parent, 'keyword-categories');
                if (pageComponent) {
                    const component = this.$root.findComponent(pageComponent, 'keyword-category-add-form');
                    if (component) {
                        component.resetForm();
                    }
                }
            },
            resetUserGroupAddForm: function () {
                const pageComponent = this.$root.findComponent(this.$parent.$parent, 'user-groups');
                if (pageComponent) {
                    const component = this.$root.findComponent(pageComponent, 'user-group-add-form');
                    if (component) {
                        component.resetForm();
                    }
                }
            },
            resetPreApprovedEmailDomainAddForm: function () {
                const pageComponent = this.$root.findComponent(this.$parent.$parent, 'pre-approved-email-domains');
                if (pageComponent) {
                    const component = this.$root.findComponent(pageComponent, 'pre-approved-email-domain-add-form');
                    if (component) {
                        component.resetForm();
                    }
                }
            },
            resetDetectedLabelExclusionAddForm: function () {
                const pageComponent = this.$root.findComponent(this.$parent.$parent, 'detected-label-exclusions');
                if (pageComponent) {
                    const component = this.$root.findComponent(pageComponent, 'detected-label-exclusion-add-form');
                    if (component) {
                        component.resetForm();
                    }
                }
            },
            resetAlertAddForm: function () {
                const pageComponent = this.$root.findComponent(this.$parent.$parent, 'alerts');
                if (pageComponent) {
                    const component = this.$root.findComponent(pageComponent, 'alert-add-form');
                    if (component) {
                        component.resetForm();
                    }
                }
            },
            resetPrintOrderCopyRecipientAddForm: function () {
                const pageComponent = this.$root.findComponent(this.$parent.$parent, 'print-order-copy-recipients');
                if (pageComponent) {
                    const component = this.$root.findComponent(pageComponent, 'print-order-copy-recipient-add-form');
                    if (component) {
                        component.resetForm();
                    }
                }
            },
            downloadExport: function (format) {
                this.downloading = true;
                /**
                 * Send request to API
                 */
                const download = (format == 'xls') ? this.xls_download : this.csv_download;
                const data = {
                    item_type: 'report',
                    item_id: 0,
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/' + response.data.token;
                    window.location.href = url;
                })
                .catch(function (error) {
                    // Do anything?
                })
                .finally(() =>
                    vm.downloading = null
                );
            },
            hasSelectedItems: function () {
                if (this.$route.name == 'asset-reviews') {
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    const component = this.$root.findComponent(appComponent, 'asset-reviews');
                    if (component) {
                        if (component.reviews_selected.length > 0) {
                            return true;
                        }
                    }

                    return false;
                }

                return false;
            },
            resetAssetReviewsBulkEditModal: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const assetReviewsComponent = this.$root.findComponent(appComponent, 'asset-reviews');
                const component = this.$root.findComponent(assetReviewsComponent, 'asset-reviews-bulk-edit');
                if (component) {
                    component.resetModal();
                }
            }
        }
    }
</script>
