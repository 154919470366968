<template>
    <div style="position: relative; left: -5px; margin-top: 1px">
        <div class="filter">
            <searchable-option-list
                v-model="status"
                :value="status"
                :options="status_options"
                field_id="filter-by-status"
                search_placeholder="Select a status"
                :readonly="isArtworksLoading()"
            ></searchable-option-list>
        </div>
        <div class="filter keyword">
            <form v-on:submit.prevent="keywordSearch()">
                <div class="keyword-field">
                    <div class="input-group">
                        <input
                            v-model="keyword"
                            type="text"
                            class="form-control"
                            id="filter-by-keyword"
                            placeholder="Search name or email"
                            aria-label="Search name or email"
                            :readonly="isArtworksLoading()"
                        >
                        <div class="input-group-append">
                            <button class="btn btn-primary big-icon" type="submit" :disabled="isArtworksLoading()">
                                <i class="fal fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <button
                        v-if="keyword && !isArtworksLoading()"
                        class="btn btn-link clear-keyword"
                        @click.prevent="clearKeyword()"
                    >
                        <i class="far fa-times"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['artwork_approval_filters'],
        data() {
            return {
                status_options: [
                    {
                        id: 'Awaiting approval',
                        name: 'Awaiting approval'
                    },
                    {
                        id: 'Approved',
                        name: 'Approved'
                    },
                    {
                        id: 'Declined',
                        name: 'Declined'
                    }
                ],
                status: 'Awaiting approval',
                keyword: ''
            };
        },
        mounted () {
            this.artwork_approval_filters.forEach((item) => {
                if (item.key == 'keyword') {
                    this.keyword = item.value;
                }
            });
        },
        watch: {
            artwork_approval_filters: function (val) {
                this.updateFilters();
            },
            status: function (val) {
                var vm = this;
                if (!val) {
                    setTimeout(function () {
                        vm.status = 'Awaiting approval';
                    }, 200);

                    return null;
                }
                this.$root.addFilter('artwork_approval_filters', {
                    key: 'status',
                    value: val,
                    text: val,
                    filters_key: 'artwork_approval_filters'
                });
                if ((val !== 'Approved') && this.expired) {
                    this.expired = null;

                    return null;
                }
                setTimeout(function () {
                    vm.getArtworks();
                }, 200);
            }
        },
        methods: {
            updateFilters: function () {
                let statusFound = null;
                this.artwork_approval_filters.forEach((item) => {
                    if (item.key == 'status') {
                        statusFound = item.value;
                    }
                });

                if (this.status !== statusFound) {
                    this.status = statusFound;
                }
            },
            keywordSearch: function () {
                const keywordTrimmed = this.keyword.trim();
                
                let keywordsRemoved = false;
                let filters = [];
                this.$root.artwork_approval_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        keywordsRemoved = true;
                    } else {
                        filters.push(item);
                    }
                });
                this.$root.artwork_approval_filters = filters;

                if (!keywordTrimmed) {
                    if (keywordsRemoved) {
                        this.getArtworks();
                    }

                    return null;
                }
                this.$root.addFilter('artwork_approval_filters', {
                    key: 'keyword',
                    value: keywordTrimmed,
                    text: '&quot;' + keywordTrimmed.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + '&quot;',
                    filters_key: 'artwork_approval_filters'
                });
                this.getArtworks();
            },
            clearKeyword: function () {
                this.keyword = '';
                this.keywordSearch();
                $('#filter-by-keyword').focus();
            },
            getArtworks: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'artwork-approvals');
                if (component) {
                    component.getArtworks();
                }
            },
            isArtworksLoading: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'artwork-approvals');
                if (!component) {
                    return true;
                }

                return component.loading;
            }
        }
    }
</script>
<style scoped>
    .filter {
        display: inline-block;
        width: 200px;
        position: relative;
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .filter.keyword {
        width: 300px;
    }

    .keyword-field {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }

    @media (max-width: 575px) {
        .filter, .filter.keyword {
            width: 100%;
            margin-right: 0;
        }
    }
</style>
