<template>
    <div :id="field_id" :class="field_class" style="height: auto">
        <span v-if="(items.length > 0)">
            <span
                v-for="item in items"
                :key="item.id"
                class="badge badge-soft-secondary contact-links font-size-12 mr-2 mb-2"
            >
                {{ item.text }}
                <a
                    v-if="!readonly"
                    href="#"
                    v-b-tooltip.hover
                    title="Remove"
                    @click.prevent="removeKeyword(item)"
                >
                    <i class="far fa-times"></i>
                </a>
                <span v-if="readonly">
                    <i class="far fa-times"></i>
                </span>
            </span>
        </span>
        <div v-if="((items.length == 0) && placeholder)" class="text-muted mb-2">{{ placeholder }}</div>

        <form v-on:submit.prevent="addKeyword(true)">
            <div class="input-group">
                <input
                    v-model="keyword"
                    type="text"
                    class="form-control"
                    :id="'keyword-input-' + field_id"
                    :placeholder="keywordPlaceholder()"
                    v-on:focus="keywordPlaceholder(true)"
                    v-on:blur="keywordPlaceholder(true)"
                    :aria-label="keyword_placeholder"
                    :disabled="readonly"
                >
                <div class="input-group-append">
                    <button
                        class="btn btn-secondary big-icon"
                        type="submit"
                        :disabled="(readonly || !keyword)"
                    >
                        <i class="fal fa-plus"></i>
                    </button>
                </div>
            </div>
            <b-tooltip
                v-if="keyword"
                :target="'keyword-input-' + field_id"
                triggers="hover"
            >
                Add
            </b-tooltip>
        </form>
    </div>
</template>

<script>
    export default {
        props: [
            'value',
            'field_id',
            'field_class',
            'readonly',
            'placeholder',
            'keyword_placeholder',
            'add_current_keyword'
        ],
        data() {
            return {
                items: [],
                keyword: ''
            };
        },
        mounted () {
            this.resetKeywords(this.value);
        },
        watch: {
            value: function (val) {
                this.resetKeywords(val);
            },
            add_current_keyword: function(val) {
                if (val) {
                    this.addKeyword();
                }
            }
        },
        methods: {
            resetKeywords: function (keywordsInput) {
                this.items = [];
                if (keywordsInput) {
                    const keywords = keywordsInput.split(',');
                    keywords.forEach((text, index) => {
                        if (text.trim() != '') {
                            this.items.push({
                                id: (index + 1),
                                text: text.trim()
                            });
                        }
                    });
                }
            },
            addKeyword: function (focusInput = false) {
                if (this.keyword != '') {
                    let keywords = this.keyword.split(',');
                    keywords.forEach((text, index) => {
                        if (text.trim() != '') {
                            let id = this.getNextId(text.trim());
                            if (id) {
                                this.items.push({
                                    id: id,
                                    text: text.trim()
                                });
                            }
                        }
                    });
                    this.$emit('input', this.getKeywords());
                    this.$emit('change');
                    this.keyword = '';
                }
                if (focusInput) {
                    $('#keyword-input-' + this.field_id).focus();
                }
            },
            getNextId: function (text) {
                let maxId = 0;
                this.items.forEach((item, index) => {
                    if (item.text == text) {
                        maxId = null;
                    }
                    if ((maxId !== null) && (item.id > maxId)) {
                        maxId = item.id;
                    }
                });
                if (maxId === null) {
                    return null;
                }

                return (maxId + 1);
            },
            removeKeyword: function (itemToRemove) {
                let itemIndex = -1;
                this.items.forEach((item, index) => {
                    if (item == itemToRemove) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.items.splice(itemIndex, 1);
                    this.$emit('input', this.getKeywords());
                    this.$emit('change');
                }
            },
            getKeywords: function () {
                let keywords = [];
                this.items.forEach((item, index) => {
                    keywords.push(item.text);
                });

                return keywords.join(',');
            },
            keywordPlaceholder: function (update = false) {
                const focused = $('#keyword-input-' + this.field_id).is(':focus');
                if (focused) {
                    if (update) {
                        $('#keyword-input-' + this.field_id).attr('placeholder', null);
                    }

                    return null;
                }
                const text = (this.keyword_placeholder || 'Enter keyword(s)');
                if (update) {
                    $('#keyword-input-' + this.field_id).attr('placeholder', text);

                    return null;
                }

                return text;
            }
        }
    }
</script>
