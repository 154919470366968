<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (total_items == 0))" class="alert alert-warning" role="alert">
            <span v-if="(!getStatus() || (getStatus() == 'Awaiting approval'))">
                There are no artwork to approve
            </span>
            <span v-if="(getStatus() == 'Approved')">
                There are no approved artwork
            </span>
            <span v-if="(getStatus() == 'Declined')">
                There are no declined artwork
            </span>
        </div>
        <div v-if="(total_items > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-1 col-12 pb-sm-0 pb-1">
                                            ID
                                        </div>
                                        <div class="col-sm-3 col-12 pb-sm-0 pb-1">
                                            Submitted on
                                        </div>
                                        <div class="col-sm-4 col-12 pb-sm-0 pb-1">
                                            Submitted by
                                        </div>
                                        <div class="col-sm-3 col-12">
                                            Status
                                        </div>
                                        <div class="col-sm-1 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row" :id="'approval-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-1 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.id }}
                                        </div>
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            {{ getFinishedAt(item) }}
                                        </div>
                                        <div class="col-4 d-none d-sm-block" style="padding-top: 3px">
                                            <span v-if="hasDetails(item.id)">
                                                <h5 class="font-size-14 mb-1">
                                                    <a
                                                        href="#"
                                                        class="text-dark"
                                                        @click.prevent="toggleViewApproval(item)"
                                                    >
                                                        {{ approval_details[item.id].name }}
                                                    </a>
                                                </h5>
                                                <p class="text-muted mb-0">
                                                    <i class="far fa-envelope mr-1"></i>
                                                    {{ approval_details[item.id].email }}
                                                    <br>
                                                    <i class="far fa-phone mr-1"></i>
                                                    {{ approval_details[item.id].telephone }}
                                                </p>
                                            </span>
                                            <span v-else><loading :align="'left'" :size="1"></loading></span>
                                        </div>
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            <span v-if="item.declined">
                                                Declined on {{ formatDate(item.declined_at) }}
                                            </span>
                                            <span v-if="item.approved">
                                                Approved on {{ formatDate(item.approved_at) }}
                                            </span>
                                            <span v-if="(!item.approved && !item.declined)">Awaiting approval</span>
                                        </div>
                                        <div class="col-10 d-sm-none">
                                            <p class="mb-1">{{ item.id }}</p>
                                            <p class="mb-1">{{ getFinishedAt(item) }}</p>
                                            <span v-if="hasDetails(item.id)">
                                                <h5 class="font-size-14 mb-1">
                                                    <a
                                                        href="#"
                                                        class="text-dark"
                                                        @click.prevent="toggleViewApproval(item)"
                                                    >
                                                        {{ approval_details[item.id].name }}
                                                    </a>
                                                </h5>
                                                <p class="text-muted mb-1">
                                                    <i class="far fa-envelope mr-1"></i>
                                                    {{ approval_details[item.id].email }}
                                                    <br>
                                                    <i class="far fa-phone mr-1"></i>
                                                    {{ approval_details[item.id].telephone }}
                                                </p>
                                            </span>
                                            <p v-else class="mb-1"><loading :align="'left'" :size="1"></loading></p>
                                            <p class="mb-0">
                                                <span v-if="item.declined">
                                                    Declined on {{ formatDate(item.declined_at) }}
                                                </span>
                                                <span v-if="item.approved">
                                                    Approved on {{ formatDate(item.approved_at) }}
                                                </span>
                                                <span v-if="(!item.approved && !item.declined)">Awaiting approval</span>
                                            </p>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleViewApproval(item)"
                                            >
                                                <span
                                                    v-if="(approval_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'approval-details' + item.id">
                                            <artwork-approval-details
                                                :data="item"
                                                :details="(approval_details_open.indexOf(item.id) > -1)
                                                    ? approval_details[item.id] : null"
                                            ></artwork-approval-details>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <div
            v-if="$root.hasPermission('templates_brand_approvals')"
            class="modal fade"
            id="decline-artwork-approval-request-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="decline-artwork-approval-request-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <decline-artwork-approval-request></decline-artwork-approval-request>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'artwork-approvals',
                loading: false,
                errors: false,
                items: [],
                total_items: 0,
                current_page: 1,
                last_page: 1,
                approval_details_open: [],
                approval_details: {}
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            $('body').removeClass('login-body');
            
            this.$root.artwork_approval_filters = [];
            if (!this.$root.hasPermission('templates_brand_approvals')) {
                this.errors = 'Sorry, you do not have access to this function.';

                return false;
            }
            this.getArtworks();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            //
        },
        methods: {
            getArtworks: function (page = 1) {
                if (this.loading || (page < 1) || ((page > 1) && (page <= this.current_page))) {
                    return false;
                }

                let filters = ['finished', '!deleted'];
                let filteredByStatus = false;
                this.$root.artwork_approval_filters.forEach((item) => {
                    if (item.key == 'status') {
                        filteredByStatus = true;
                        if (item.value == 'Awaiting approval') {
                            filters.push('!approved');
                            filters.push('!declined');
                        } else {
                            filters.push(item.value.toLowerCase());
                        }
                    }
                    if (item.key == 'keyword') {
                        filters.push('kw::' + btoa(item.value));
                    }
                });
                if (!filteredByStatus) {
                    filters.push('!approved');
                    filters.push('!declined');
                    this.$root.addFilter('artwork_approval_filters', {
                        key: 'status',
                        value: 'Awaiting approval',
                        text: 'Awaiting approval',
                        filters_key: 'artwork_approval_filters'
                    }, true);
                }
                this.current_page = page;
                this.loading = true;
                this.errors = false;
                const filtersString = (filters.length > 0) ? '/' + filters.join('|') : '';

                if (page == 1) {
                    this.items = [];
                    this.approval_details = {};
                    this.total_items = 0;
                    this.current_page = 1;
                    this.last_page = 1;
                    this.approval_details_open = [];
                }

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/0/artworks'
                    + filtersString + '?page=' + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.total_items = response.data.artworks.total;
                    vm.current_page = response.data.artworks.current_page;
                    vm.last_page = response.data.artworks.last_page;
                    if (response.data.artworks.data.length == 0) {
                        vm.loading = false;
                    } else {
                        response.data.artworks.data.forEach((item) => {
                            vm.items.push(item);
                            vm.getArtworkDetails(item);
                        });
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                    vm.loading = false;
                })
                .finally(() => {
                    //vm.loading = false;
                });
            },
            getArtworkDetails: function (item) {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + item.template_id
                    + '/artwork/' + item.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.approval_details[response.data.artwork.id] = {
                        comments_html: response.data.artwork.comments_html,
                        name: response.data.artwork.name,
                        email: response.data.artwork.email,
                        telephone: response.data.artwork.telephone,
                        resource_id: response.data.artwork.resource_id,
                        resource_title: response.data.artwork.resource_title,
                        template_replaced: response.data.artwork.template_replaced
                    }
                    if (response.data.artwork.approved_on) {
                        vm.approval_details[response.data.artwork.id].approved_on =
                            response.data.artwork.approved_on;
                    }
                    if (response.data.artwork.declined_on) {
                        vm.approval_details[response.data.artwork.id].declined_on =
                            response.data.artwork.declined_on;
                    }
                    if (response.data.artwork.approver_name) {
                        vm.approval_details[response.data.artwork.id].approver_name =
                            response.data.artwork.approver_name;
                    }
                    if (response.data.artwork.reason_for_decline_html) {
                        vm.approval_details[response.data.artwork.id].reason_for_decline_html =
                            response.data.artwork.reason_for_decline_html;
                    }
                    if (response.data.artwork.pdf) {
                        vm.approval_details[response.data.artwork.id].pdf =
                            response.data.artwork.pdf;
                    }
                    if (response.data.artwork.print_order) {
                        vm.approval_details[response.data.artwork.id].print_order =
                            response.data.artwork.print_order;
                    }
                    if (response.data.artwork.finished_at_formatted) {
                        vm.approval_details[response.data.artwork.id].finished_at_formatted =
                            response.data.artwork.finished_at_formatted;
                    }

                    let allLoaded = true;
                    vm.items.forEach((item) => {
                        if (!vm.hasDetails(item.id)) {
                            allLoaded = false;
                        }
                    });
                    if (allLoaded) {
                        this.loading = false;
                    }
                })
                .catch(function (error) {
                    //
                })
                .finally(() => {
                    //
                });
            },
            getFinishedAt: function (artwork) {
                if (this.approval_details[artwork.id]) {
                    if (this.approval_details[artwork.id].finished_at_formatted) {
                        return this.approval_details[artwork.id].finished_at_formatted;
                    }
                }

                return this.formatDate(artwork.finished_at);
            },
            formatDate: function (rawDate) {
                const date = rawDate.substr(0, 10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            handleScroll(event) {
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < 200) {
                        this.getArtworks(this.current_page + 1);
                    }
                }
            },
            toggleViewApproval: function (item) {
                if (this.approval_details_open.indexOf(item.id) < 0) {
                    this.approval_details_open.push(item.id);
                    $('#approval-row' + item.id).addClass('highlighted');
                    $('#approval-details' + item.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#approval-details' + item.id).slideUp('slow', function () {
                        $('#approval-row' + item.id).removeClass('highlighted');
                        for (var n = 0; n < vm.approval_details_open.length; n++) { 
                            if (vm.approval_details_open[n] == item.id) { 
                                vm.approval_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            hasDetails: function (itemId) {
                if (itemId in this.approval_details) {
                    return true;
                }

                return false;
            },
            removeApproval: function (approvalId) {
                for (let n = (this.items.length - 1); n >= 0; n--) {
                    if (this.items[n].id == approvalId) {
                        this.items.splice(n, 1);
                        this.total_items--;
                    }
                }
            },
            getStatus: function () {
                let status = null;
                this.$root.artwork_approval_filters.forEach((item) => {
                    if (item.key == 'status') {
                        status = item.value;
                    }
                });

                return status;
            }
        }
    }
</script>
