<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="price-panel-edit-modal-label">Edit price panel</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body" style="min-height: 300px">
            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div v-if="loading"><loading></loading></div>

            <div v-if="(!loading && !errors)">
                <div v-if="!price_panel">
                    <searchable-option-list
                        v-model="price_panel_id"
                        :value="price_panel_id"
                        :options="price_panels"
                        field_id="edit-price-panel"
                        search_placeholder="Select price panel"
                    ></searchable-option-list>
                </div>
                <div v-else>
                    <div v-if="updated" class="alert alert-success" role="alert">
                        <div class="row">
                            <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                            <div class="col" v-html="updated"></div>
                        </div>
                    </div>
                    <div v-else>
                        <price-panel-edit-form
                            :data="price_panel"
                            :suppliers="suppliers"
                            :is_visible="is_ready"
                            :is_modal="true"
                        ></price-panel-edit-form>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="(!loading && !errors && !updated && price_panel)" class="modal-footer">
            <span v-if="!updating">
                <button type="button" class="btn btn-primary" @click="onSubmit()">
                    <i class="far fa-save mr-1"></i> Save
                </button>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Save</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                loading: true,
                updating: false,
                updated: false,
                price_panel: null,
                suppliers: null,
                is_ready: false,
                price_panel_ids: [],
                price_panels: [],
                price_panel_id: null
            }
        },
        mounted () {
            //
        },
        watch: {
            price_panel_id: function (val) {
                this.is_ready = false;
                let pricePanel = null;
                this.price_panels.forEach((item) => {
                    if (item.id == val) {
                        pricePanel = item;
                    }
                });
                this.price_panel = pricePanel;
                if (!val) {
                    return null;
                }
                var vm = this;
                setTimeout(function() {
                    vm.is_ready = true;
                }, 2000);
            },
            updated: function (val) {
                if (val) {
                    // Close modal after 2 seconds automatically.
                    setTimeout(function() {
                        $('#price-panel-edit-modal').modal('hide');
                    }, 2000);
                }
            }
        },
        methods: {
            reset: function (pricePanelIds) {
                this.updating = false;
                this.updated = false;
                this.price_panel_ids = pricePanelIds;
                if (pricePanelIds.length == 0) {
                    this.errors = 'No price panel is assigned to this item.';

                    return null;
                }
                this.loading = true;
                this.is_ready = false;
                this.price_panel = null;
                this.price_panels = [];
                this.price_panel_id = null;
                this.getPricePanels();
                this.getSuppliers();
            },
            onSubmit: function () {
                const component = this.$root.findComponent(this, 'price-panel-edit-form');
                if (component) {
                    component.onSubmit();
                }
            },
            getPricePanels: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/price-panels';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.price_panel_types.forEach((type) => {
                        type.price_panels.forEach((item) => {
                            if (vm.price_panel_ids.indexOf(item.id) > -1) {
                                item.name = item.title;
                                vm.price_panels.push(item);
                            }
                        });
                    });
                    if (vm.price_panels.length == 1) {
                        vm.price_panel_id = vm.price_panels[0].id;

                        return null;
                    }
                    // Order by title.
                    for (let i = 0; i < vm.price_panels.length - 1; i++) {
                        for (let j = i + 1; j < vm.price_panels.length; j++) {
                            if (vm.price_panels[j].title < vm.price_panels[i].title) {
                                let temp = vm.price_panels[i];
                                vm.price_panels[i] = vm.price_panels[j];
                                vm.price_panels[j] = temp;
                            }
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#price-panel-edit-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            getSuppliers: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order-suppliers';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let suppliers = [];
                    response.data.suppliers.forEach((item) => {
                        item.name = item.company || (item.first_name + ' ' + item.last_name);
                        suppliers.push(item);
                    });
                    vm.suppliers = suppliers;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#price-panel-edit-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                });
            }
        }
    }
</script>
