<template>
    <div>
        <div v-if="!isArchived()" class="table-responsive">
            <table class="table table-centered table-hover">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            <div class="row">
                                <div class="col-2">Sort</div>
                                <div class="col-9">Name</div>
                                <div class="col-1">&nbsp;</div>
                            </div>
                        </th>
                    </tr>
                </thead>
            </table>
            <div v-if="folder" class="subfolders" :id="'subfolders-live' + folder.id">
                <table class="table table-centered table-hover subfolders-list">
                    <draggable
                        v-model="items_paginated"
                        ghost-class="vue-drag-ghost"
                        handle=".drag-handle"
                        tag="tbody"
                        @end="onDragSortEnd"
                        animation="200"
                    >
                        <tr v-for="item in items_paginated" :key="item.id">
                            <td scope="row" :id="'subfolder-row' + item.id" class="pb-0">
                                <div class="row">
                                    <div class="col-2 font-size-18 contact-links">
                                        <a
                                            href="#"
                                            class="drag-handle"
                                            style="cursor: move"
                                            v-b-tooltip.hover
                                            title="Re-order"
                                            @click.prevent="doNothing()"
                                        >
                                            <i class="fas fa-arrow-down-arrow-up"></i>
                                        </a>
                                    </div>
                                    <div class="col-9" style="padding-top: 3px">
                                        <h5 class="font-size-14 mb-0">
                                            <a href="#" class="text-dark" @click.prevent="toggleEditSubfolder(item)">
                                                {{ item.name }}
                                            </a>
                                        </h5>
                                    </div>
                                    <div class="col-1 font-size-18 contact-links">
                                        <a
                                            href="#"
                                            class="float-right"
                                            @click.prevent="toggleEditSubfolder(item)"
                                        >
                                            <span
                                                v-if="(subfolder_details_open.indexOf(item.id) < 0)"
                                                :key="'close-' + item.id"
                                            >
                                                <i class="fa-regular fa-chevron-circle-down"></i>
                                            </span>
                                            <span v-else :key="'open-' + item.id">
                                                <i class="fa-regular fa-chevron-circle-up"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div class="row list-details-td mt-2">
                                    <div class="col-12 list-details" :id="'subfolder-details' + item.id">
                                        <subfolder-edit-form
                                            :data="item"
                                            :list_component="list_component"
                                        ></subfolder-edit-form>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </draggable>
                </table>
            </div>
        </div>
        <div v-else class="table-responsive">
            <table class="table table-centered table-hover">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            <div class="row">
                                <div class="col-11">Name</div>
                                <div class="col-1">&nbsp;</div>
                            </div>
                        </th>
                    </tr>
                </thead>
            </table>
            <div v-if="folder" class="subfolders" :id="'subfolders-archived' + folder.id">
                <table class="table table-centered table-hover subfolders-list">
                    <tbody>
                        <tr v-for="item in items_paginated" :key="item.id">
                            <td scope="row" :id="'subfolder-row' + item.id" class="pb-0">
                                <div class="row">
                                    <div class="col-11" style="padding-top: 3px">
                                        <h5 class="font-size-14 mb-0">
                                            <a href="#" class="text-dark" @click.prevent="toggleEditSubfolder(item)">
                                                {{ item.name }}
                                            </a>
                                        </h5>
                                    </div>
                                    <div class="col-1 font-size-18 contact-links">
                                        <a
                                            href="#"
                                            class="float-right"
                                            @click.prevent="toggleEditSubfolder(item)"
                                        >
                                            <span
                                                v-if="(subfolder_details_open.indexOf(item.id) < 0)"
                                                :key="'close-a-' + item.id"
                                            >
                                                <i class="fa-regular fa-chevron-circle-down"></i>
                                            </span>
                                            <span v-else :key="'open-a-' + item.id">
                                                <i class="fa-regular fa-chevron-circle-up"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div class="row list-details-td mt-2">
                                    <div class="col-12 list-details" :id="'subfolder-details' + item.id">
                                        <subfolder-edit-form
                                            :data="item"
                                            :list_component="list_component"
                                        ></subfolder-edit-form>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    export default {
        components: {
            draggable,
        },
        props: ['status', 'sf_items', 'list_component', 'folder'],
        data () {
            return {
                items: [],
                subfolder_details_open: [],
                items_per_page: 25,
                items_paginated: [],
                page: 0,
                paginating: false
            }
        },
        mounted () {
            this.items = this.sf_items;
            this.page = 1;

            var vm = this;
            $('#subfolders-' + (this.isArchived() ? 'archived' : 'live') + this.folder.id).on('scroll', function () {
                vm.handleScroll();
            });
        },
        watch: {
            sf_items: function (val) {
                this.subfolder_details_open = [];
                this.items = val;
                this.items_paginated = [];
                this.page = 0;
            },
            page: function (val) {
                if (val) {
                    const indexStart = this.items_paginated.length;
                    let indexEnd = (val * this.items_per_page) - 1;
                    if (indexEnd > (this.items.length - 1)) {
                        indexEnd = this.items.length - 1;
                    }
                    if (indexEnd < indexStart) {
                        return null;
                    }
                    for (let index = indexStart; index <= indexEnd; index++) {
                        this.items_paginated.push(this.items[index]);
                    }

                    return null;
                }
                this.page = 1;
            },
            paginating: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.paginating = false;
                    }, 500);
                }
            }
        },
        methods: {
            handleScroll: function () {
                if (this.paginating) {
                    return null;
                }
                const status = (this.isArchived() ? 'archived' : 'live');
                const scrollTop = $('#subfolders-' + status + this.folder.id).scrollTop();
                const contentHeight = $('#subfolders-' + status + this.folder.id + ' > table').outerHeight();
                const scrollPosition = contentHeight - (scrollTop + 400);
                if (scrollPosition < 50) {
                    this.paginating = true;
                    this.page++;
                }
            },
            isArchived: function () {
                return (this.status == 'archived');
            },
            toggleEditSubfolder: function (subfolder) {
                if (this.subfolder_details_open.indexOf(subfolder.id) < 0) {
                    this.subfolder_details_open.push(subfolder.id);
                    $('#subfolder-row' + subfolder.id).addClass('highlighted');
                    $('#subfolder-details' + subfolder.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#subfolder-details' + subfolder.id).slideUp('slow', function () {
                        $('#subfolder-row' + subfolder.id).removeClass('highlighted');
                        for (var n = 0; n < vm.subfolder_details_open.length; n++) { 
                            if (vm.subfolder_details_open[n] == subfolder.id) { 
                                vm.subfolder_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            onDragSortEnd: function(event) {
                if (event.newIndex == event.oldIndex) {
                    return false;
                }
                this.errors = false;
                /**
                 * Send move asset type request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/'
                    + this.folder.id + '/subfolder/' + this.items_paginated[event.newIndex].id + '/move';
                const data = {
                    sort_order: event.newIndex
                };
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.$root.folders_changed = true;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.$parent.subfolders_errors = error.response.data.error;
                    }
                });
            },
            doNothing: function () {
                return null;
            }
        }
    }
</script>
<style scoped>
    .subfolders {
        max-height: 400px;
        overflow-y: auto;
    }

    .subfolders-list tbody tr:first-child td {
        border-top: none;
    }
</style>
