<template>
    <div>
        <div v-if="(report == 'registrations')">
            <loading v-if="!user"></loading>
            <user-edit-form
                v-else
                :data="user"
                :company_name="client_company_name"
                :roles="roles"
                :groups="user_groups"
                :profiles="profile_groups"
                :is_visible="true"
            ></user-edit-form>
        </div>
        <div v-if="(report == 'visits')" class="table-responsive">
            <loading v-if="items.length == 0"></loading>
            <table v-else class="table table-centered table-hover">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" class="py-2">
                            <div class="row">
                                <div class="col-md-3 col-6">Date</div>
                                <div class="col">Device</div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td scope="row" class="pt-2" style="padding-bottom: 5px">
                            <div class="row">
                                <div class="col-md-3 col-6" style="padding-top: 1px">
                                    {{ item.created_at_formatted }}
                                </div>
                                <div class="col">
                                    {{ item.device }}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="(report == 'asset-downloads')" class="table-responsive">
            <loading v-if="items.length == 0"></loading>
            <table v-else class="table table-centered table-hover">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" class="py-2">
                            <div class="row">
                                <div class="col-4">User</div>
                                <div v-if="$root.isFeatured('profiles')" class="col-4">
                                    {{ $root.ucfirst($root.contents.profile_group_title) }}
                                </div>
                                <div class="col">Download date</div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td scope="row" class="pt-2" style="padding-bottom: 5px">
                            <div class="row">
                                <div class="col-4" style="padding-top: 1px">
                                    {{ item.first_name ? item.first_name + ' ' + item.last_name
                                        : (item.download_email ? item.download_email : 'n/a') }}
                                </div>
                                <div v-if="$root.isFeatured('profiles')" class="col-4">
                                    {{ item.profile_group_name }}
                                </div>
                                <div class="col">
                                    {{ item.created_at_formatted }}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="(report == 'artwork-created')" class="table-responsive">
            <loading v-if="items.length == 0"></loading>
            <table v-else class="table table-centered table-hover">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" class="py-2">
                            <div class="row">
                                <div class="col-4">User</div>
                                <div v-if="$root.isFeatured('profiles')" class="col-4">
                                    {{ $root.ucfirst($root.contents.profile_group_title) }}
                                </div>
                                <div class="col">Created on</div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td scope="row" class="pt-2" style="padding-bottom: 5px">
                            <div class="row">
                                <div class="col-4" style="padding-top: 1px">
                                    {{ item.first_name + ' ' + item.last_name }}
                                </div>
                                <div v-if="$root.isFeatured('profiles')" class="col-4">
                                    {{ item.profile_group_name }}
                                </div>
                                <div class="col">
                                    {{ item.created_at_formatted }}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="(report == 'orders')">
            <print-order-details
                :data="details"
                :is_visible="visible"
                :view_only="true"
            ></print-order-details>
        </div>
        <div v-if="(report == 'flytes-sent')">
            <flyte-sent-details v-if="visible" :share="details"></flyte-sent-details>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['report', 'details', 'visible', 'profile_groups'],
        data () {
            return {
                items: [],
                user: null,
                items_updated: false,
                get_content_response: null,
                client_company_name: '',
                roles: [],
                user_groups: []
            }
        },
        mounted () {
            if (this.details && this.visible) {
                this.updateItems(this.details);
            }
        },
        watch: {
            details: function (val) {
                if (val && this.visible) {
                    this.updateItems(val);
                }
            },
            visible: function (val) {
                if (val && this.details) {
                    this.updateItems(this.details);
                }
            },
            get_content_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.key == 'company_name') {
                            this.client_company_name = val.content;
                        }
                    }
                }
            }
        },
        methods: {
            updateItems: function (details) {
                if (this.items_updated) {
                    return null;
                }
                this.items_updated = true;

                if (this.report == 'registrations') {
                    this.getUserDetails(details);
                    this.getRoles();
                    this.getUserGroups();
                    this.client_company_name = this.$root.client.client_name;
                    if (this.$root.client.company_contact_details) {
                        this.$root.getContent(this, 'company_name');
                    }

                    return null;
                }

                if (this.report == 'visits') {
                    this.items = [];
                    details.forEach((item, index) => {
                        item.id = index;
                        this.items.push(item);
                    });
                }

                if (this.report == 'asset-downloads') {
                    this.items = [];
                    details.forEach((item, index) => {
                        item.id = index;
                        this.items.push(item);
                    });
                }

                if (this.report == 'artwork-created') {
                    this.items = [];
                    details.forEach((item, index) => {
                        item.id = index;
                        this.items.push(item);
                    });
                }
            },
            getUserDetails: function (userId) {
                /**
                 * Send get user request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user/' + userId;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user = response.data.user;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getRoles: function () {
                /**
                 * Send get roles request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/settings/roles';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.roles = response.data.roles;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getUserGroups: function () {
                /**
                 * Send get user groups request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/!private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_groups = response.data.user_groups;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getUsers: function (reloadReport = false) {
                if (reloadReport) {
                    this.$parent.getReport();

                    return null
                }
                this.getUserDetails(this.details);
            },
            getStatusDate: function (item, status = null) {
                if (!item) {
                    return '';
                }
                if (!status) {
                    status = item.order_status;
                }
                switch (status) {
                    case 'On hold':
                        return item.on_hold_at ? this.formatDate(item.on_hold_at) : '';
                    case 'In production':
                        return item.in_production_at ? this.formatDate(item.in_production_at) : '';
                    case 'Dispatched':
                        return item.dispatched_at ? this.formatDate(item.dispatched_at) : '';
                    case 'Completed':
                        return item.completed_at ? this.formatDate(item.completed_at) : '';
                    default:
                        return '';
                }
            },
            formatDate: function (rawDate) {
                const date = rawDate.substr(0, 10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            }
        }
    }
</script>
