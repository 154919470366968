<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="email-team-modal-label">
                {{ (chase ? 'Chase' : 'Email') }}
                {{ reviewer ? reviewer.first_name + ' ' + reviewer.last_name : 'team' }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>

        <div class="modal-body">
            <div v-if="sent" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form v-on:submit.prevent="onSubmit()">
                    <div class="form-group">
                        <label for="email-team-message" id="email-team-message-label" class="mandatory-label">
                            Message
                        </label>
                        <textarea
                            v-model="message"
                            id="email-team-message"
                            class="form-control mandatory-field"
                            :readonly="sending"
                            rows="4"
                        ></textarea>
                    </div>
                </form>
            </div>
        </div>

        <div v-if="!sent" class="modal-footer">
            <span v-if="!sending">
                <button :key="'send-button'" type="button" class="btn btn-primary" @click="onSubmit()">
                    <i class="far fa-paper-plane mr-1"></i> Send
                </button>
            </span>
            <span v-else>
                <button :key="'send-button-spinning'" type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Send</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['proof'],
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                message: null,
                reviewer: null,
                chase: false
            }
        },
        mounted () {
            //
        },
        watch: {
            errors: function (val) {
                if (!val) {
                    $('.mandatory-label').removeClass('text-danger');
                    $('.mandatory-field').removeClass('field-error');
                }
            }
        },
        methods: {
            reset: function (reviewer = null, chase = false) {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.message = null;
                this.reviewer = reviewer;
                this.chase = chase;
            },
            onSubmit: function () {
                this.errors = false;
                this.sending = true;

                /**
                 * Send request to API.
                 */
                const data = {
                    user_id: this.reviewer ? this.reviewer.user_id : null,
                    message: this.message,
                    chase: this.chase,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                    + '/email-team';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.sent = response.data.message;

                    // Close after 2 seconds.
                    setTimeout(function () {
                        $('#email-team-modal').modal('hide');
                    }, 2000);

                    if (vm.chase) {
                        vm.$parent.getProof(vm.proof.id);
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            $('#email-team-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    $('#email-team-' + key + '-label').addClass('text-danger');
                                    $('#email-team-' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.sending = false;
                });
            }
        }
    }
</script>
