<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="template-images-modal-label">Select: {{ field.title }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body" style="overflow-x: hidden">
            <div v-if="errors" v-html="errors" class="alert alert-danger form-errors" role="alert"></div>

            <div v-if="total_items > 1">
                <form v-on:submit.prevent="getImages()">
                    <div class="row">
                        <div class="col-xl-3 col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <searchable-option-list
                                    v-model="sort_by"
                                    :value="sort_by"
                                    :options="sort_by_options"
                                    field_id="ti-sort-by"
                                    search_placeholder="Sort by"
                                    :readonly="loading"
                                ></searchable-option-list>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6 col-12">
                            <div class="form-group keyword">
                                <div class="input-group">
                                    <input
                                        v-model="keyword"
                                        type="text"
                                        class="form-control ti-search-by-keyword"
                                        placeholder="Keyword search"
                                        aria-label="Keyword search"
                                        :readonly="loading"
                                    >
                                    <div class="input-group-append">
                                        <button
                                            class="btn btn-secondary big-icon"
                                            type="submit"
                                            :disabled="loading"
                                        >
                                            <i class="fal fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                                <button
                                    v-if="keyword && !loading"
                                    class="btn btn-link clear-keyword"
                                    @click.prevent="clearKeyword()"
                                >
                                    <i class="far fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <loading v-if="(!errors && loading)"></loading>

            <div
                v-if="(!errors && !loading && (items.length == 0))"
                class="alert alert-warning form-errors"
                role="alert"
            >
                There are no images available
            </div>

            <div
                v-if="(!loading && (items.length > 0))"
                class="pr-3"
                style="max-height: 480px; overflow-x: hidden; overflow-y: auto; width: calc(100% + 12px)"
            >
                <div class="row">
                    <div v-if="(field.user_images || canChooseAsset())" :class="imagePreviewClass()">
                        <template-image-upload
                            v-if="field.user_images"
                            :is_embedded="true"
                            :can_choose_asset="canChooseAsset()"
                        ></template-image-upload>

                        <div v-else class="card add-panel">
                            <div
                                class="card-body px-4"
                                style="padding-top: 95px"
                            >
                                <div class="form-group">
                                    <button
                                        type="button"
                                        class="btn btn-secondary btn-block"
                                        @click.prevent="chooseAsset()"
                                    >
                                        <i class="far fa-images mr-1"></i>
                                        Choose {{ $root.contents.asset_title }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="item in items"
                        :key="(item.is_asset ? 'asset_' : '') + item.id"
                        :class="imagePreviewClass()"
                    >
                        <template-image-preview
                            :data="item"
                            :selected="isSelected(item)"
                            :user_collection_id="user_collection_id"
                            v-on:change="onImageSelected"
                        ></template-image-preview>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'template-images',
                errors: false,
                loading: false,
                caller_component: null,
                field: {
                    title: ''
                },
                items: [],
                user_collection_id: null,
                uploaded_images: [],
                total_items: 0,
                sort_by: 'newest',
                sort_by_options: [
                    {
                        id: 'newest',
                        name: 'Recent first'
                    },
                    {
                        id: 'oldest',
                        name: 'Oldest first'
                    },
                    {
                        id: 'title_asc',
                        name: 'Alpha sort (A-Z)'
                    },
                    {
                        id: 'title_desc',
                        name: 'Alpha sort (Z-A)'
                    }
                ],
                keyword: null
            }
        },
        mounted () {
            //
        },
        watch: {
            sort_by: function (val) {
                if (!val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.sort_by = 'newest';
                    }, 200);

                    return null;
                }
                this.getImages();
            }
        },
        methods: {
            reset: function (component = null, forceGetImages = false) {
                if (!this.$root.hasPermission('templates_use')) {
                    this.errors = 'Sorry, you do not have access to this function.';

                    return false;
                }
                this.errors = false;
                if (component) {
                    this.caller_component = component;
                }
                this.field = this.caller_component.item;
                this.items = [];
                this.total_items = 0;
                this.user_collection_id = null;
                this.uploaded_images = [];
                this.sort_by = 'newest';
                this.keyword = null;
                this.getImages(null, forceGetImages);
            },
            getImages: function (autoSelectAsset = null, force = false) {
                if (this.loading && !force) {
                    return null;
                }
                this.errors = false;
                this.loading = true;
                if (autoSelectAsset) {
                    this.items = [];
                } else {
                    if (this.items.length == 0) {
                        this.getImage();

                        return null;
                    }

                    if (this.items.length > 1) {
                        let images = [];
                        if (this.items[0].id == 'original') {
                            images.push(this.items[0]);
                        }
                        this.items = images;
                    }
                }

                let filters = ['sb::' + this.sort_by];
                if (this.keyword) {
                    filters.push('kw::' + btoa(this.keyword));
                }
                let profileId = 0;
                if (this.caller_component) {
                    if (this.caller_component.profile_id) {
                        profileId = this.caller_component.profile_id;
                    }
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.field.template_id + '/field-images/' + this.field.id + '/profile/' + profileId + '/'
                    + filters.join('|');
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_collection_id = response.data.user_collection_id;
                    response.data.images.forEach((image) => {
                        if (autoSelectAsset) {
                            if (image.is_asset && (image.id == autoSelectAsset)) {
                                vm.onImageSelected({
                                    preview: image.preview,
                                    file_path: image.file_path,
                                });
                                vm.removeAssets([autoSelectAsset]);
                            }
                        } else {
                            vm.items.push(image);
                        }
                    });
                    if (!vm.total_items) {
                        vm.total_items = vm.items.length;
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';

                    if (!$('#template-images-modal').hasClass('show')) {
                        $('#template-images-modal').modal('show');
                    }
                })
                .finally(() =>
                    vm.loading = false
                );
            },
            getImage: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.field.template_id + '/field-image/' + this.field.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.items.push({
                        id: 'original',
                        title: 'Original image',
                        preview: response.data.image_src
                    });
                })
                .catch(function (error) {
                    //
                })
                .finally(() =>
                    vm.getImages(null, true)
                );
            },
            imagePreviewClass: function () {
                if (this.$root.is_xl_screen) {
                    return 'col-3';
                }

                return 'col-lg-3 col-md-4 col-sm-6 col-12';
            },
            isSelected: function (item) {
                if ((item.id == 'original') && !this.caller_component.item.data) {
                    return true;
                }
                if (!this.caller_component.item.data) {
                    return false;
                }

                return (this.caller_component.item.data.indexOf(item.file_path) > -1);
            },
            onImageSelected: function (event) {
                this.caller_component.loading = true;
                this.caller_component.image_src = event.preview;
                const data = {
                    preview: event.preview,
                    edit_image: null,
                    data: (event.file_path || null)
                };
                this.caller_component.$parent.updateTemplateField(
                    this.caller_component.data,
                    data,
                    true
                );
                if ($('#template-images-modal').hasClass('show')) {
                    $('#template-images-modal').modal('hide');
                }

                var vm = this;
                setTimeout(function () {
                    vm.caller_component.updateItem();
                }, 500);
            },
            selectOriginalImage: function () {
                this.onImageSelected({
                    preview: this.items[0].preview,
                    file_path: null
                });
            },
            doNothing: function () {
                return null;
            },
            addUploadedImages: function (files) {
                setTimeout(function () {
                    $('#template-images-modal').modal('show');
                }, 500);

                if (files.length == 0) {
                    return null;
                }

                this.loading = true;
                this.uploaded_images = files;
                this.linkUploadedFiles();
            },
            linkUploadedFiles: function () {
                let filesLinked = 0;
                let file = null;
                this.uploaded_images.forEach((item) => {
                    if (item.linked) {
                        filesLinked++;
                    } else if (!file) {
                        file = item;
                    }
                });

                if (filesLinked == this.uploaded_images.length) {
                    this.reset(null, true);

                    return null;
                }

                const path = '/' + this.$root.app.client_id + '/collection/' + this.user_collection_id + '/linked-file';
                const url = this.$root.app.api_url + path;
                const data = {
                    title: file.filename,
                    id: file.id,
                    filename: file.filename,
                    file_type: file.file_type,
                    file_size: file.file_size,
                    temp_file: file.temp_file
                };
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.uploaded_images.forEach((item) => {
                        if (item.id == response.data.file_id) {
                            item.linked = true;
                        }
                    });
                    vm.linkUploadedFiles();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                });
            },
            canChooseAsset: function () {
                if (!this.items) {
                    return true;
                }
                let hasAdminImages = false;
                this.items.forEach((item) => {
                    if ((item.id != 'original') && !item.is_own_collection) {
                        hasAdminImages = true;
                    }
                });

                return !hasAdminImages;
            },
            chooseAsset: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'select-item');
                component.reset(
                    ['asset'],
                    'Choose ' + this.$root.contents.asset_title,
                    this,
                    'template-images',
                    null,
                    null,
                    ['image/jpeg', 'image/pjpeg', 'image/png', 'application/pdf', 'image/svg', 'image/svg+xml'],
                    'Only ' + this.$root.contents.folders_title
                        + ' with suitable files will appear in the drop down menu',
                    false // select multiple
                );
                $('#template-images-modal').modal('hide');
                setTimeout(function () {
                    $('#select-item-modal').modal('show');
                }, 500);
            },
            selectedItem: function (field, itemType, value) {
                this.loading = true;
                if (value.length == 0) {
                    $('#template-images-modal').modal('show');

                    return null;
                }

                // If selected asset already added to the collection just update artwork.
                let itemIndex = -1;
                this.items.forEach((item, index) => {
                    if (item.is_asset && (item.id == value)) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.onImageSelected({
                        preview: this.items[itemIndex].preview,
                        file_path: this.items[itemIndex].file_path,
                    });

                    return null;
                }

                var assetSelected = value;
                this.caller_component.loading = true;

                /**
                 * Send add assets to collection request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.user_collection_id + '/resource';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: { resources: [value] },
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    setTimeout(function () {
                        vm.getImages(assetSelected, true);
                    }, 200);
                })
                .catch(function (error) {
                    //
                });
            },
            removeAssets: function (assetIds) {
                /**
                 * Send remove assets from collection request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.user_collection_id + '/resource/' + assetIds.join(',');
                var vm = this;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    //
                })
                .catch(function (error) {
                    //
                });
            },
            clearKeyword: function () {
                if (this.loading) {
                    return null;
                }
                this.keyword = null;
                this.getImages();
                setTimeout(function () {
                    $('.ti-search-by-keyword').focus();
                }, 100);
            }
        }
    }
</script>
<style scoped>
    .add-panel {
        border: 3px dashed #eff2f7;
        background-color: transparent;
        height: 246px;
    }

    .add-panel .card-body {
        height: calc(100% - 6px);
    }

    .form-group.keyword {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        bottom: 1px;
        right: 35px;
        z-index: 9;
    }
</style>
