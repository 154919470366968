<template>
    <div class="row">
        <div class="col-md-3">
            <div v-if="(hasResources() && hasTemplates())" class="ml-2 mr-2 mb-3" style="max-width: 300px">
                <div class="btn-group btn-block" role="group">
                    <button
                        type="button"
                        :class="'btn btn-' + ((template === null) ? 'primary' : 'light')"
                        @click.prevent="template = null"
                    >All</button>
                    <!--<button
                        type="button"
                        :class="'btn btn-' + ((template === 0) ? 'primary' : 'light')"
                        @click.prevent="template = 0"
                    >Standard</button>-->
                    <button
                        type="button"
                        :class="'btn btn-' + ((template === 1) ? 'primary' : 'light')"
                        @click.prevent="template = 1"
                    >Editable only</button>
                </div>
            </div>
            <form v-on:submit.prevent="keywordSearch()" class="ml-2 mr-2 mb-3" style="max-width: 300px">
                <div class="form-group keyword m-0">
                    <div class="input-group">
                        <input
                            v-model="keyword"
                            type="text"
                            class="form-control search-by-keyword"
                            placeholder="Keyword search"
                            aria-label="Keyword search"
                            @focus="onFocus()"
                            @keydown="onKeydown($event)"
                            @keyup="onKeyup($event)"
                        >
                        <div class="input-group-append">
                            <button class="btn btn-primary big-icon" type="submit">
                                <i class="fal fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <button v-if="keyword" class="btn btn-link clear-keyword" @click.prevent="clearKeyword()">
                        <i class="far fa-times"></i>
                    </button>
                    <div v-if="$root.client.asset_search_autocomplete" class="dropdown">
                        <div
                            class="dropdown-menu search-autocomplete"
                            :id="'search-autocomplete-sdc' + (small ? '-small' : '')"
                            style="max-width: 280px; max-height: 210px; overflow-x: hidden; overflow-y: auto"
                        >
                            <a
                                v-for="option in autocomplete_options"
                                :key="option.id"
                                :id="'search-autocomplete-sdc' + (small ? '-small' : '') + '-option' + option.id"
                                href="#"
                                :class="'dropdown-item' + ((option.id == selected_autocomplete_option) ? ' active' : '')"
                                style="white-space: normal"
                                @click.prevent="getAsset(option)"
                            >{{ option.name }}</a>
                        </div>
                    </div>
                </div>
            </form>

            <ul class="list-unstyled megamenu-list mb-3">
                <li class="p-0">
                    <router-link
                        class="all-folders py-1 pl-2 pr-2"
                        :to="routerLinkTo()"
                        v-on:click.native="folderSearch()"
                    >
                        <h5 class="font-size-14 mt-0 mb-0">All {{ $root.contents.folders_title }}</h5>
                    </router-link>
                </li>
            </ul>

            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div v-if="loading"><loading></loading></div>

            <ul v-for="folder in columns[0]" :key="folder.id" class="list-unstyled megamenu-list mb-3">
                <li class="megamenu-folder p-0">
                    <router-link
                        class="py-1 pl-2 pr-2"
                        :to="routerLinkTo()"
                        v-on:click.native="folderSearch(folder)"
                    >
                        <h5 class="font-size-14 mt-0 mb-0">{{ folder.name }}</h5>
                    </router-link>
                </li>
                <li class="p-0" v-for="subfolder in folder.subfolders" :key="subfolder.id">
                    <router-link
                        class="py-2px pl-3 pr-2"
                        :to="routerLinkTo()"
                        v-on:click.native="folderSearch(folder, subfolder)"
                    >{{ subfolder.name }}</router-link>
                </li>
            </ul>
        </div>

        <div class="col-md-3">
            <ul v-for="folder in columns[1]" :key="folder.id" class="list-unstyled megamenu-list mb-3">
                <li class="megamenu-folder p-0">
                    <router-link
                        class="py-1 pl-2 pr-2"
                        :to="routerLinkTo()"
                        v-on:click.native="folderSearch(folder)"
                    >
                        <h5 class="font-size-14 mt-0 mb-0">{{ folder.name }}</h5>
                    </router-link>
                </li>
                <li class="p-0" v-for="subfolder in folder.subfolders" :key="subfolder.id">
                    <router-link
                        class="py-2px pl-3 pr-2"
                        :to="routerLinkTo()"
                        v-on:click.native="folderSearch(folder, subfolder)"
                    >{{ subfolder.name }}</router-link>
                </li>
            </ul>
        </div>

        <div class="col-md-3">
            <ul v-for="folder in columns[2]" :key="folder.id" class="list-unstyled megamenu-list mb-3">
                <li class="megamenu-folder p-0">
                    <router-link
                        class="py-1 pl-2 pr-2"
                        :to="routerLinkTo()"
                        v-on:click.native="folderSearch(folder)"
                    >
                        <h5 class="font-size-14 mt-0 mb-0">{{ folder.name }}</h5>
                    </router-link>
                </li>
                <li class="p-0" v-for="subfolder in folder.subfolders" :key="subfolder.id">
                    <router-link
                        class="py-2px pl-3 pr-2"
                        :to="routerLinkTo()"
                        v-on:click.native="folderSearch(folder, subfolder)"
                    >{{ subfolder.name }}</router-link>
                </li>
            </ul>
        </div>
        <div class="col-md-3">
            <ul v-for="folder in columns[3]" :key="folder.id" class="list-unstyled megamenu-list mb-3">
                <li class="megamenu-folder p-0">
                    <router-link
                        class="py-1 pl-2 pr-2"
                        :to="routerLinkTo()"
                        v-on:click.native="folderSearch(folder)"
                    >
                        <h5 class="font-size-14 mt-0 mb-0">{{ folder.name }}</h5>
                    </router-link>
                </li>
                <li class="p-0" v-for="subfolder in folder.subfolders" :key="subfolder.id">
                    <router-link
                        class="py-2px pl-3 pr-2"
                        :to="routerLinkTo()"
                        v-on:click.native="folderSearch(folder, subfolder)"
                    >{{ subfolder.name }}</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['folders_changed', 'assets_info', 'small'],
        data () {
            return {
                errors: false,
                loading: true,
                keyword: '',
                folders: [],
                columns: [],
                template: null,
                autocomplete_options: [],
                selected_autocomplete_option: null,
                last_key: null,
                keypress_time: 0
            }
        },
        mounted () {
            if (this.assets_info || !this.$root.hasPermission('assets_template_filter')) {
                this.getFolders();
            }
            var vm = this;
            $(document).on('click', function () {
                $('#search-autocomplete-sdc' + (vm.small ? '-small' : '')).hide();
                vm.selected_autocomplete_option = null;
                vm.last_key = null;
            });
        },
        watch: {
            folders_changed: function (val) {
                if (this.$root.hasPermission('assets_template_filter')) {
                    return null;
                }
                if (val) {
                    this.getFolders();
                    var vm = this;
                    setTimeout(function () {
                        vm.$root.folders_changed = false;
                    }, 1000);
                }
            },
            assets_info: function (val) {
                if (val) {
                    this.getFolders();
                    var vm = this;
                    setTimeout(function () {
                        vm.$root.folders_changed = false;
                    }, 1000);
                }
            },
            template: function (val) {
                this.updateColumns();
            }
        },
        methods: {
            keywordSearch: function () {
                let keywordTrimmed = this.keyword.trim();
                // Need to remove all existing filters to avoid false results.
                this.$root.asset_filters = [];
                // Add new keyword filter if not empty.
                if (keywordTrimmed != '') {
                    let keywords = [];
                    const keywordLength = keywordTrimmed.length;
                    if (
                        (keywordTrimmed.substring(0, 1) == '"')
                        && (keywordTrimmed.substring(keywordLength - 1) == '"')
                    ) {
                        keywords.push(keywordTrimmed.substring(1, keywordLength - 1));
                    } else {
                        keywords = keywordTrimmed.split(',');
                    }
                    let keywordsAdded = [];
                    keywords.forEach((keyword) => {
                        keywordTrimmed = keyword.trim();
                        if ((keywordTrimmed != '') && (keywordsAdded.indexOf(keywordTrimmed) < 0)) {
                            keywordsAdded.push(keywordTrimmed);
                        }
                    });
                    if (keywordsAdded.length > 0) {
                        this.$root.addFilter('asset_filters', {
                            key: 'main-keyword',
                            value: keywordsAdded.join('||'),
                            text: '&quot;' + keywordsAdded.join('&quot;, &quot;').replace(/"/g, '&quot;')
                                .replace(/'/g, '&apos;') + '&quot;',
                            filters_key: 'asset_filters'
                        });
                    }
                    this.keyword = '';
                }
                if (this.template !== null) {
                    this.$root.addFilter('asset_filters', {
                        key: 'template',
                        value: this.template,
                        text: this.template ? 'Editable' : 'Standard',
                        filters_key: 'asset_filters'
                    });
                    this.template = null;
                }
                this.getAssets();
            },
            folderSearch: function (folder = null, subfolder = null) {
                this.keyword = '';
                let currentFolderId = -1;
                let subfolderAdded = false;
                // Remove keyword and other folder filters
                if (!folder) {
                    this.$root.asset_filters = [];
                } else {
                    let filters = [];
                    this.$root.asset_filters.forEach((item) => {
                        if (item.key == 'folder') {
                            currentFolderId = item.value;
                        }
                        if (subfolder) {
                            if ((item.key == 'subfolder') && (item.value == subfolder.id)) {
                                subfolderAdded = true;
                            }
                        }
                        filters.push(item);
                    });
                    for (let itemIndex = (filters.length - 1); itemIndex >= 0; itemIndex--) {
                        if ((filters[itemIndex].key == 'folder') && (currentFolderId != folder.id)) {
                            filters.splice(itemIndex, 1);
                        } else if (
                            (filters[itemIndex].key == 'subfolder') && (!subfolder || (currentFolderId != folder.id))
                        ) {
                            filters.splice(itemIndex, 1);
                        } else if ((filters[itemIndex].key != 'folder') && (filters[itemIndex].key != 'subfolder')) {
                            filters.splice(itemIndex, 1);
                        }
                    }
                    this.$root.asset_filters = filters;
                }
                // Add new folder filters according to selection
                if (folder && (currentFolderId != folder.id)) {
                    this.$root.addFilter('asset_filters', {
                        key: 'folder',
                        value: folder.id,
                        subfolders: folder.subfolders,
                        text: '<i class="fal fa-folder font-size-16 mr-1"></i> '
                            + folder.name.replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                        filters_key: 'asset_filters'
                    });
                }
                if (folder && subfolder && !subfolderAdded) {
                    this.$root.addFilter('asset_filters', {
                        key: 'subfolder',
                        value: subfolder.id,
                        name: subfolder.name,
                        text: '<i class="fal fa-folder-tree font-size-16 mr-1"></i> '
                            + subfolder.name.replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                        filters_key: 'asset_filters'
                    });
                }
                if (this.template !== null) {
                    this.$root.addFilter('asset_filters', {
                        key: 'template',
                        value: this.template,
                        text: this.template ? 'Editable' : 'Standard',
                        filters_key: 'asset_filters'
                    });
                    this.template = null;
                }
                this.getAssets();
            },
            getAssets: function () {
                // If not assets page redirect
                if (!this.$parent.$parent.isAssetsPage()) {
                    this.$router.push({ name: 'assets' });

                    return false;
                }
                const component = this.$root.findComponent(this.$parent.$parent.$parent, 'assets');
                if (component) {
                    component.getAssets();
                    console.log('get assets: search dropdown');
                }
                this.$root.hideMainNavDropdowns();
            },
            getFolders: function () {
                /**
                 * Send request to API
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folders/!pagination';
                if (!this.isArchive()) {
                    url += '|!archived';
                }
                if (this.$root.client.hide_empty_folders) {
                    url += '|!empty';
                    if (this.hasResources() && this.hasTemplates()) {
                        url += '|resource-counts'
                    }
                }
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folders = response.data.folders;
                    vm.updateColumns();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                }).finally(() => {
                    vm.loading = false;
                });
            },
            updateColumns: function () {
                const filtersheight = (this.hasResources() && this.hasTemplates()) ? 5 : 3;
                const isArchive = this.isArchive();
                let folders = [];
                let numItems = 0;
                this.folders.forEach((item) => {
                    let includeFolder = true;
                    if ((this.template !== null) && ('resource_counts' in item)) {
                        if (
                            (!this.template && (item.resource_counts.resources == 0))
                            || (this.template && (item.resource_counts.templates == 0))
                        ) {
                            includeFolder = false;
                        }
                    }
                    if (includeFolder) {
                        let folder = {};
                        for (const [key, value] of Object.entries(item)) {
                            if (key != 'subfolders') {
                                folder[key] = value;
                            }
                        }
                        numItems++;
                        folder.subfolders = [];
                        item.subfolders.forEach((subfolder) => {
                            let includeSubfolder = true;
                            if (!isArchive && subfolder.archived) {
                                includeSubfolder = false;
                            }
                            if (includeSubfolder && (this.template !== null) && ('resource_counts' in subfolder)) {
                                if (
                                    (!this.template && (subfolder.resource_counts.resources == 0))
                                    || (this.template && (subfolder.resource_counts.templates == 0))
                                ) {
                                    includeSubfolder = false;
                                }
                            }
                            if (includeSubfolder) {
                                folder.subfolders.push(subfolder);
                                numItems++;
                            }
                        });
                        folders.push(folder);
                    }
                });
                const itemsPerColumn = Math.ceil((numItems + filtersheight) / 4);

                this.columns = [];
                let columnFolders = [];
                let columnNumItems = 0;
                folders.forEach((item) => {
                    let folderNumItems = (item.subfolders.length + 1);
                    let columnFull = (columnNumItems >= itemsPerColumn);
                    let firstColumnFull =
                        ((this.columns.length == 0) && (columnNumItems > (itemsPerColumn - filtersheight - 1)));
                    //let columnFull = ((columnNumItems + folderNumItems) >= (itemsPerColumn + 2));
                    //let firstColumnFull =
                    //    ((this.columns.length == 0) && ((columnNumItems + folderNumItems) > (itemsPerColumn - 2)));
                    if (columnFull || firstColumnFull) {
                        this.columns.push(columnFolders);
                        columnFolders = [];
                        columnNumItems = 0;
                    }
                    columnFolders.push(item);
                    columnNumItems += folderNumItems;
                });
                if (columnNumItems > 0) {
                    this.columns.push(columnFolders);
                }
                this.columns.push([]);
                this.columns.push([]);
                this.columns.push([]);
                this.columns.push([]);
            },
            clearKeyword: function () {
                this.keyword = '';
                var vmMainNav = this.$parent.$parent;
                setTimeout(function () {
                    vmMainNav.showSearchDropdown();
                    setTimeout(function () {
                        $('.search-by-keyword').focus();
                    }, 200);
                }, 10);
            },
            routerLinkTo: function () {
                if (this.$route.name == 'assets-filtered') {
                    const linkTo = {
                        name: this.$route.name,
                        params: this.$route.params
                    };

                    return linkTo;
                }

                return { name: 'assets' };
            },
            hasResources: function () {
                if (!this.assets_info) {
                    return false;
                }

                return this.isArchive() ? this.assets_info.resources_archived : this.assets_info.resources_live;
            },
            hasTemplates: function () {
                if (!this.assets_info) {
                    return false;
                }

                return this.isArchive() ? this.assets_info.templates_archived : this.assets_info.templates_live;
            },
            isArchive: function () {
                if ((this.$route.name != 'assets-filtered') || (this.$route.params.filters != 'archived')) {
                    return false;
                }

                return true;
            },
            onFocus: function () {
                if (!this.$root.client.asset_search_autocomplete) {
                    return null;
                }
                if (!this.keyword) {
                    this.autocomplete_options = [];
                    this.selected_autocomplete_option = null;
                    this.last_key = null;
                    $('#search-autocomplete-sdc' + (this.small ? '-small' : '')).hide();

                    if (!this.small) {
                        this.setDropdownHeight();
                    }

                    return null;
                }
                if (this.autocomplete_options.length > 0) {
                    this.showAutocomplete();

                    return null;
                }
            },
            onKeydown: function (event) {
                if (!this.$root.client.asset_search_autocomplete) {
                    return null;
                }
                this.last_key = event.key;
                if (
                    (event.key != 'ArrowUp')
                    && (event.key != 'ArrowDown')
                    && ((event.key != 'Enter') || (!this.selected_autocomplete_option))
                ) {
                    if (!this.small) {
                        this.setDropdownHeight();
                    }

                    return null;
                }
                event.preventDefault();
            },
            onKeyup: function (event) {
                if (!this.$root.client.asset_search_autocomplete) {
                    return null;
                }
                this.lookupAsset(true);
            },
            lookupAsset: function (delay = false) {
                if (
                    (this.last_key == 'ArrowUp')
                    || (this.last_key == 'ArrowDown')
                    || ((this.last_key == 'Enter') && (this.selected_autocomplete_option))
                ) {
                    this.showAutocomplete();

                    return null;
                }
                $('#search-autocomplete-sdc' + (this.small ? '-small' : '')).hide();
                this.autocomplete_options = [];
                this.selected_autocomplete_option = null;
                this.last_key = null;

                if (this.keyword.length < 3) {
                    return null;
                }

                if (delay) {
                    this.keypress_time = new Date();
                    var vm = this;
                    setTimeout(function () {
                        vm.lookupAsset();
                    }, 500);
                }

                const time = new Date();
                if ((time - this.keypress_time) < 500) {
                    return null;
                }

                const filters = [
                    this.isArchive() ? 'archived' : '!archived',
                    'mk::' + btoa(this.keyword),
                    'lt::100'
                ];

                /**
                 * Send search assets request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/' + filters.join('|');
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.resources.total == 0) {
                        return null;
                    }
                    response.data.resources.data.forEach((item) => {
                        // Duplicate display protection - check if already added.
                        let itemIndex = -1;
                        vm.autocomplete_options.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            vm.autocomplete_options.push({
                                id: item.id,
                                name: item.id + ' - ' + item.title
                            });
                        }
                    });
                    vm.showAutocomplete();
                })
                .catch(function (error) {
                    //
                });
            },
            showAutocomplete: function () {
                if (this.autocomplete_options.length == 0) {
                    return null;
                }
                $('#search-autocomplete-sdc' + (this.small ? '-small' : '')).show();
                let currentIndex = -1;
                let prevIndex = -1;
                let nextIndex = -1;
                if (this.selected_autocomplete_option) {
                    this.autocomplete_options.forEach((option, index) => {
                        if (option.id == this.selected_autocomplete_option) {
                            currentIndex = index;
                        } else {
                            if (currentIndex == -1) {
                                prevIndex = index;
                            }
                            if ((currentIndex > -1) && (nextIndex == -1)) {
                                nextIndex = index;
                            }
                        }
                    });
                    if (currentIndex == -1) {
                        this.selected_autocomplete_option = null;
                    }
                }
                if (this.last_key == 'ArrowDown') {
                    this.selected_autocomplete_option = (nextIndex > -1)
                        ? this.autocomplete_options[nextIndex].id
                        : this.autocomplete_options[0].id;
                    this.scrollToAutocompleteOption();
                }
                if (this.last_key == 'ArrowUp') {
                    this.selected_autocomplete_option = (prevIndex > -1)
                        ? this.autocomplete_options[prevIndex].id
                        : this.autocomplete_options[0].id;
                    this.scrollToAutocompleteOption();
                }
                if ((this.last_key == 'Enter') && (currentIndex > -1)) {
                    this.selected_autocomplete_option = null;
                    this.last_key = null;
                    this.getAsset(this.autocomplete_options[currentIndex]);
                }
            },
            scrollToAutocompleteOption: function () {
                let scrollTo = 0;
                let found = false;
                this.autocomplete_options.forEach((option) => {
                    if (!found) {
                        if (option.id != this.selected_autocomplete_option) {
                            let oid = 'search-autocomplete-sdc' + (this.small ? '-small' : '') + '-option' + option.id;
                            scrollTo += $('#' + oid).outerHeight();
                        } else {
                            found = true;
                        }
                    }
                });
                $('#search-autocomplete-sdc' + (this.small ? '-small' : '')).animate({ scrollTop: scrollTo }, 200);
            },
            getAsset: function (selectedAsset) {
                this.$root.asset_filters = [];
                $('#search-autocomplete-sdc' + (this.small ? '-small' : '')).hide();
                this.$root.addFilter('asset_filters', {
                    key: 'resource_id',
                    value: selectedAsset.id,
                    text: '&quot;' + selectedAsset.id + '&quot;',
                    filters_key: 'asset_filters'
                });
                this.getAssets();
            },
            setDropdownHeight: function () {
                setTimeout(function () {
                    let maxHeight = $('.dropdown-megamenu').innerHeight() - 90;
                    if (maxHeight < 100) {
                        maxHeight = 100;
                    }
                    $('#search-autocomplete-sdc').css('max-height', maxHeight);
                }, 200);
            }
        }
    }
</script>
<style scoped>
    .form-group.keyword {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }

    .list-unstyled li {
        font-weight: 300;
    }

    .list-unstyled li h5 {
        line-height: 1.4;
    }

    .py-2px {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    #search-autocomplete-sdc-small a.dropdown-item, #search-autocomplete-sdc-small a.dropdown-item.active,
    #search-autocomplete-sdc-small a.dropdown-item.active:hover {
        color: #16181b !important;
    }
</style>
