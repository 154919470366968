import { render, staticRenderFns } from "./NonShareableAssetImagePreview.vue?vue&type=template&id=bbd9a8ec&scoped=true"
import script from "./NonShareableAssetImagePreview.vue?vue&type=script&lang=js"
export * from "./NonShareableAssetImagePreview.vue?vue&type=script&lang=js"
import style0 from "./NonShareableAssetImagePreview.vue?vue&type=style&index=0&id=bbd9a8ec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbd9a8ec",
  null
  
)

export default component.exports