<template>
    <div
        :class="panelClass()"
        data-toggle="modal"
        data-target="#flyte-form-modal"
        @click="resetFlyteForm()"
        style="cursor: pointer"
    >
        <div
            :class="headerClass()"
            style="text-transform: uppercase; line-height: 1.1; height: 52px; z-index: 1"
        >
            <table style="width: 100%; height: 44px">
                <tbody>
                    <tr>
                        <td valign="middle">
                            FLYTE FILE SENDER
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-body">
            <p style="font-weight: 300">Send files from<br>your desktop<br>to anyone,<br>anywhere</p>
            <div style="position: absolute; bottom: 20px; right: 20px">
                <i class="fal fa-paper-plane" style="font-size: 140px; opacity: 0.6"></i>
            </div>
            <div class="pb-3" style="position: absolute; left: 20px; bottom: 0; width: 100%">
                <button type="button" :class="buttonClass()">SEND</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                reset_height_interval: null,
                prev_width: 0,
                prev_width2: 0
            }
        },
        mounted () {
            var vm = this;
            setTimeout(function () {
                vm.resetHeight();
            }, 200);

            vm.reset_height_interval = setInterval(function () {
                vm.resetHeight();
            }, 999);

            $(window).resize(function () {
                vm.resetHeight();
            });
        },
        beforeDestroy() {
            clearInterval(this.reset_height_interval);
        },
        methods: {
            panelClass: function () {
                const panelColour = (this.$root.client.flyte_panel_colour || 'third');

                return 'card mb-3 flyte-panel bg-' + panelColour + ' text-' + panelColour;
            },
            headerClass: function () {
                const headerColour = (this.$root.client.flyte_panel_header_colour || 'third');

                return 'card-header font-size-16 py-1 bg-' + headerColour + ' text-' + headerColour;
            },
            buttonClass: function () {
                const buttonColour = (this.$root.client.flyte_panel_button_colour || 'primary');

                return 'btn btn-light bg-' + buttonColour + ' text-' + buttonColour;
            },
            resetHeight: function () {
                const width = $('.flyte-panel').outerWidth();
                let noReset = false;
                if ((width == this.prev_width) || ((width == this.prev_width2) && (width < this.prev_width))) {
                    noReset = true;
                }
                this.prev_width2 = this.prev_width;
                this.prev_width = width;
                if (noReset) {
                    return null;
                }
                $('.flyte-panel').css('height', Math.round(width / 0.816) + 'px');
                const contentWidth = $('.flyte-panel .card-body').innerWidth();
                $('.flyte-panel .card-body > p').css('font-size', (contentWidth / 270) + 'rem');
            },
            resetFlyteForm: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'flyte-form');
                if (component) {
                    component.resetForm();
                }
            }
        }
    }
</script>
<style scoped>
    .flyte-panel {
        height: 250px;
        transition: width height .35 ease;
    }

    .flyte-panel .card-body {
        position: relative;
        font-size: 15px;
    }

    .flyte-panel .card-body p {
        transition: font-size 0.35s ease;
    }
</style>
