<template>
    <div class="card asset-preview in-selection">
        <div class="thumbnail" @click.prevent="viewArtwork()" v-b-tooltip.hover title="View" style="cursor: pointer">
            <img :src="image_src" :alt="title">
            <div v-if="!is_visible" class="preview-loading">
                <loading size="1"></loading>
            </div>
        </div>

        <div class="card-body" style="position: relative">
            <div class="title mb-2" :id="'saved-artwork-title' + id">
                {{ short_title }}
            </div>

            <b-tooltip v-if="(short_title != title)" :target="'saved-artwork-title' + id" triggers="hover">
                {{ title }}
            </b-tooltip>

            <div>
                <span class="asset-info stay">
                    <span v-if="(!finished || approved || declined)">{{ formatDate(created_at) }}</span>
                    <span v-if="declined" class="badge badge-danger font-size-11 ml-1">Declined</span>
                    <span v-if="(finished && !approved && !declined)" class="badge badge-danger font-size-11">
                        Awaiting review
                    </span>
                    <span v-if="!finished" class="badge bg-secondary text-secondary font-size-11 ml-1">Saved</span>
                </span>
                &nbsp;
            </div>

            <div
                class="font-size-22 contact-links"
                style="position: absolute; right: 15px; bottom: 6px"
            >
                <span v-if="has_usage_history">
                    <a href="#" @click.prevent="viewHistory()">
                        <span v-b-tooltip.hover title="History" class="text-inv-secondary">
                            <i class="fal fa-list-timeline"></i>
                        </span>
                    </a>
                </span>
                <span v-if="(finished && !approved && !declined)" class="ml-1 text-danger">
                    <span v-b-tooltip.hover title="Locked - awaiting review">
                        <i class="fal fa-lock-alt"></i>
                    </span>
                </span>
                <span v-if="(declined || approved || !finished)" class="ml-1">
                    <span
                        v-if="(!opening_artwork_editor || edit_artwork_duplicate)"
                        :key="'edit-artwork'"
                    >
                        <a href="#" @click.prevent="editArtwork()">
                            <span v-b-tooltip.hover title="Edit">
                                <i class="fal fa-pen-paintbrush"></i>
                            </span>
                        </a>
                    </span>
                    <span v-else :key="'editing-artwork'">
                        <a href="#" @click.prevent="doNothing()">
                            <i class="fal fa-spinner-third fa-spin"></i>
                        </a>
                    </span>
                </span>
                <span v-if="finished && (approved || declined)" class="ml-1">
                    <span
                        v-if="(!opening_artwork_editor || !edit_artwork_duplicate)"
                        :key="'duplicate-artwork'"
                    >
                        <a href="#" @click.prevent="editArtwork(true)">
                            <span v-b-tooltip.hover title="Copy">
                                <i class="fal fa-copy"></i>
                            </span>
                        </a>
                    </span>
                    <span v-else :key="'duplicating-artwork'">
                        <a href="#" @click.prevent="doNothing()">
                            <i class="fal fa-spinner-third fa-spin"></i>
                        </a>
                    </span>
                </span>
                <span :key="'delete-artwork'" class="ml-1">
                    <a
                        href="#"
                        v-b-tooltip.hover
                        title="Delete"
                        @click.prevent="deleteArtwork()"
                    >
                        <i class="fal fa-trash-alt"></i>
                    </a>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['asset', 'template', 'data', 'is_visible'],
        data () {
            return {
                id: 0,
                image_src: '/images/file-icon.png',
                title: '',
                short_title: '',
                created_at: null,
                approved: false,
                declined: false,
                finished: false,
                opening_artwork_editor: false,
                edit_artwork_duplicate: false,
                has_usage_history: false
            }
        },
        mounted () {
            this.updateData(this.data);
            var vm = this;
            $(window).resize(function () {
                if (vm.is_visible) {
                    vm.short_title = vm.title;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                }
            });
        },
        watch: {
            data: function (val) {
                if (val) {
                    this.updateData(val);
                }
            },
            is_visible: function (val) {
                if (val) {
                    if (this.image_src == '/images/file-icon.png') {
                        this.getPreviewImage();
                    }
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                }
            }
        },
        methods: {
            updateData: function (data) {
                if (data) {
                    this.id = data.id;
                    this.title = data.title;
                    this.short_title = data.title;
                    this.created_at = data.created_at;
                    this.approved = data.approved;
                    this.declined = data.declined;
                    this.finished = data.finished;
                    this.has_usage_history = data.has_usage_history;
                    if (this.is_visible && (this.image_src == '/images/file-icon.png')) {
                        this.getPreviewImage();
                    }
                    var vm = this;
                    if (this.is_visible) {
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 500);
                    }
                }
            },
            getPreviewImage: function () {
                /**
                 * Send request to API
                 */
                const path = '/template/' + this.data.template_id + '/artwork/' + this.id + '/preview/1/small';
                var vm = this;

                axios({
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + path,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    //
                });
            },
            fitTitle: function () {
                if (!$('#saved-artwork-title' + this.id)[0]) {
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 200);

                    return null;
                }
                if ($('#saved-artwork-title' + this.id)[0].scrollHeight > 20) {
                    let words = this.short_title.split(' ');
                    words.pop();
                    if (words.length > 0) {
                        this.short_title = words.join(' ') + '...';
                        var vm = this;
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 200);
                    }
                }
            },
            formatDate: function (rawDate) {
                if (!rawDate) {
                    return '';
                }
                const date = rawDate.substr(0 ,10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            viewArtwork: function () {
                if (this.opening_artwork_editor) {
                    return null;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'artwork-view');
                if (component) {
                    component.reset(this.template, this.data);
                    $('#artwork-view-modal').modal('show');
                }
            },
            editArtwork: function (duplicate = false, confirmed = false) {
                if (this.asset.replaced_at >= this.created_at) {
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: null,
                        title: '<i class="far fa-exclamation-triangle font-size-20 text-danger mr-2"></i>'
                            + (duplicate ? 'Copy' : 'Edit') + ' version',
                        text: 'The ' + this.$root.contents.template_title + ' used to create your version has '
                            + 'been updated so cannot be ' + (duplicate ? 'copied' : 'edited') + '. Please create '
                            + 'a new version.'
                    };
                    $('#message-modal').modal('show');

                    return null;
                }

                this.edit_artwork_duplicate = duplicate;

                if (!this.$parent.$parent.artwork_changed || confirmed) {
                    if (this.opening_artwork_editor) {
                        return null;
                    }
                    this.opening_artwork_editor = true;

                    /**
                     * Send get artwork request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                        + this.template.id + '/artwork/' + this.id;
                    var vm = this;

                    axios({
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.opening_artwork_editor = false;
                        const appComponent = vm.$root.findComponent(this.$root, 'main-app');
                        appComponent.showArtworkEditor(
                            vm.asset,
                            vm.template,
                            response.data.artwork,
                            vm.edit_artwork_duplicate
                        );
                    })
                    .catch(function (error) {
                        //vm.errors = error.response.data.error || 'Something went wrong, please try again';
                        vm.opening_artwork_editor = false;
                    });

                    return null;
                }

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: (duplicate ? 'Copy' : 'Edit') + ' saved artwork',
                    text: 'Are you sure you want to discard your current changes?',
                    component: this,
                    action: 'edit-artwork'
                };
                $('#confirm-delete-modal').modal('show');
            },
            deleteArtwork: function (confirmed = false) {
                if (confirmed) {
                    let templateId = this.data.template_id;
                    let artworkId = this.id;
                    this.$parent.removeSavedArtwork(this.id);

                    /**
                     * Send delete artwork request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + templateId
                        + '/artwork/' + artworkId;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        //
                    })
                    .catch(function (error) {
                        //
                    });

                    return null;
                }
                if (this.opening_artwork_editor) {
                    return null;
                }

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const text = (this.$parent.$parent.artwork_changed && (this.id == this.$parent.$parent.artwork.id))
                    ? 'Are you sure you want to discard your current changes and delete: ' + this.title + '?'
                    : 'Are you sure you want to delete: ' + this.title + '?'
                appComponent.confirm_delete = {
                    title: 'Delete saved artwork',
                    text: text,
                    component: this,
                    action: 'delete-artwork'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                if (action == 'edit-artwork') {
                    this.editArtwork(this.edit_artwork_duplicate, true);
                }
                if (action == 'delete-artwork') {
                    this.deleteArtwork(true);
                }
            },
            doNothing: function () {
                return null;
            },
            viewHistory: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'artwork-history');
                if (component) {
                    component.reset(this.data, this.asset);
                    $('#artwork-history-modal').modal('show');
                }
            }
        }
    }
</script>
<style scoped>
    .asset-preview.in-selection .thumbnail {
        height: 132px;
    }

    .asset-preview.in-selection .thumbnail img {
        max-height: 130px;
    }

    .asset-preview:hover .card-body .asset-info.stay {
        opacity: 1;
    }

    .preview-loading {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 2;
        height: 20px;
    }
</style>
