<template>
    <div class="topnav page-topnav" style="padding: 0 12px; max-height: none; overflow-y: visible">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand topnav-menu">
                <div class="navbar-collapse align-items-center justify-content-between">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <h6>{{ pageTitle() }}</h6>
                        </li>
                    </ul>
                    <ul class="navbar-nav">
                        <li class="nav-item" style="height: 36.5px">
                            <div style="position: absolute; height: 36.5px; right: 0">
                                <button
                                    v-if="($route.name == 'my-account')"
                                    :key="'email-support'"
                                    type="button"
                                    class="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#email-support-modal"
                                    @click.prevent="resetSupportForm()"
                                >
                                    <i class="far fa-envelope mr-1" style="position: relative; top: 1px"></i>
                                    Email support
                                </button>
                                <button
                                    v-if="($route.name == 'my-account-user-groups')"
                                    :key="'add-user-group'"
                                    type="button"
                                    class="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#user-group-add-form-modal"
                                    @click.prevent="resetUserGroupAddForm()"
                                >
                                    <i class="far fa-users-medical mr-1"></i> Add user group
                                </button>
                                <router-link
                                    v-if="($route.name != 'my-account') && ($route.name != 'my-account-user-groups')"
                                    :key="'my-account'"
                                    :to="{ name: 'my-account' }"
                                    custom
                                    v-slot="{ navigate }"
                                >
                                    <button class="btn btn-primary" @click="navigate">
                                        <i class="far fa-user mr-1"></i> My account
                                    </button>
                                </router-link>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                //
            }
        },
        mounted () {
            //
        },
        methods: {
            pageTitle: function () {
                switch (this.$route.name) {
                    case 'my-account-uploads':
                        return 'My uploads';
                    case 'my-account-downloads':
                        return 'My downloads';
                    case 'my-account-assets-awaiting-approval':
                        return this.$root.ucfirst(this.$root.contents.asset_title) + 's awaiting approval';
                    case 'my-account-assets-approved':
                        return this.$root.ucfirst(this.$root.contents.asset_title) + 's approved';
                    case 'my-account-collections-shared':
                        return 'Collections shared';
                    case 'my-account-flytes-sent':
                        return 'Flytes sent';
                    case 'my-account-print-orders':
                        return 'Orders';
                    case 'my-account-artworks-awaiting-approval':
                        return 'Items awaiting approval';
                    case 'my-account-artworks-approved':
                    case 'my-account-artwork-approved':
                        return 'Approved items';
                    case 'my-account-artworks-declined':
                    case 'my-account-artwork-declined':
                        return 'Declined items';
                    case 'my-account-artwork-actions':
                        return this.$root.ucfirst(this.$root.contents.template_title) + ' approval history';
                    case 'my-account-user-groups':
                        return 'User groups';
                    default:
                        return 'My account';
                }
            },
            resetSupportForm: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'email-support');
                if (component) {
                    component.resetForm();
                }
            },
            resetUserGroupAddForm: function () {
                const pageComponent = this.$root.findComponent(this.$parent.$parent, 'my-account-user-groups');
                if (pageComponent) {
                    const component = this.$root.findComponent(pageComponent, 'user-group-add-form');
                    if (component) {
                        component.resetForm();
                    }
                }
            }
        }
    }
</script>
