<template>
    <div class="table-responsive">
        <table class="table table-centered table-hover">
            <thead class="thead-light">
                <tr>
                    <th scope="col">
                        <div class="row">
                            <div class="col-11">Title</div>
                            <div class="col-1">&nbsp;</div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td scope="row" :id="'price-panel-row' + item.id" class="pb-0">
                        <div class="row">
                            <div class="col-11" style="padding-top: 3px">
                                <h5 class="font-size-14 mb-0">
                                    <a href="#" class="text-dark" @click.prevent="toggleEditPricePanel(item)">
                                        {{ item.title }}
                                    </a>
                                </h5>
                            </div>
                            <div class="col-1 font-size-18 contact-links">
                                <a
                                    href="#"
                                    class="float-right"
                                    @click.prevent="toggleEditPricePanel(item)"
                                >
                                    <span
                                        v-if="(price_panel_details_open.indexOf(item.id) < 0)"
                                        :key="'close-' + item.id"
                                    >
                                        <i class="fa-regular fa-chevron-circle-down"></i>
                                    </span>
                                    <span v-else :key="'open-' + item.id">
                                        <i class="fa-regular fa-chevron-circle-up"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="row list-details-td mt-2">
                            <div class="col-12 list-details" :id="'price-panel-details' + item.id">
                                <price-panel-edit-form
                                    :data="item"
                                    :suppliers="suppliers"
                                    :is_visible="(price_panel_details_open.indexOf(item.id) > -1)"
                                ></price-panel-edit-form>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: ['data', 'suppliers'],
        data () {
            return {
                items: [],
                price_panel_details_open: []
            }
        },
        mounted () {
            this.items = this.data;
        },
        watch: {
            data: function (val) {
                this.price_panel_details_open = [];
                this.items = val;
            }
        },
        methods: {
            toggleEditPricePanel: function (item) {
                if (this.price_panel_details_open.indexOf(item.id) < 0) {
                    this.price_panel_details_open.push(item.id);
                    $('#price-panel-row' + item.id).addClass('highlighted');
                    $('#price-panel-details' + item.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#price-panel-details' + item.id).slideUp('slow', function () {
                        $('#price-panel-row' + item.id).removeClass('highlighted');
                        for (var n = 0; n < vm.price_panel_details_open.length; n++) { 
                            if (vm.price_panel_details_open[n] == item.id) { 
                                vm.price_panel_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            }
        }
    }
</script>
