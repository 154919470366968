<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="decline-artwork-approval-request-modal-label">
                Decline approval request
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="sent" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>
            <div :style="'display: ' + (sent ? 'none' : 'block')">
                <form>
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="form-group">
                        <label for="reason_for_decline" id="reason_for_decline-label" class="mandatory-label">
                            Reason for decline *
                        </label>
                        <textarea
                            v-model="reason_for_decline"
                            id="reason_for_decline"
                            class="form-control mandatory-field"
                            :readonly="sending"
                            rows="4"
                            v-on:keyup="$root.unHighlightErrors('reason_for_decline')"
                            v-on:change="$root.unHighlightErrors('reason_for_decline')"
                        ></textarea>
                    </div>

                    <div v-if="!show_attachment_upload">
                        <button
                            class="btn btn-secondary btn-sm"
                            @click.prevent="toggleAttachmentUpload()"
                        >
                            <i class="far fa-file-pdf mr-1"></i> Attach annotated PDF
                        </button>
                    </div>
                    <div :style="'display: ' + (show_attachment_upload ? 'block' : 'none')">
                        <vue-dropzone
                            ref="declineArtworkDropzone"
                            id="decline-artwork-dropzone"
                            :options="dropzone_options"
                            v-on:vdropzone-sending="onSendingFile"
                            v-on:vdropzone-upload-progress="onProgressUpdate"
                            v-on:vdropzone-success="onFileUploaded"
                            v-on:vdropzone-error="onUploadError"
                            v-on:vdropzone-drag-over="onDragOver"
                            v-on:vdropzone-drag-leave="onDragLeave"
                            v-on:vdropzone-drop="onDragLeave"
                            v-on:vdropzone-file-added="onFileAdded"
                            :useCustomSlot=true
                        >
                            <div v-if="file" class="dz-uploaded-file p-2 mb-1">
                                <div class="dz-thumbnail">
                                    <span v-if="!file.preview_image">
                                        <img
                                            :src="$root.imagePlaceholder(file.filename)"
                                            class="img-fluid"
                                            alt=""
                                        >
                                    </span>
                                    <img
                                        v-else
                                        :src="file.preview_image"
                                        class="img-fluid"
                                        alt=""
                                    >
                                </div>
                                <div class="dz-filename">{{ file.filename }}</div>
                                <div class="dz-size" v-html="$root.formatFileSize(file.file_size)"></div>
                                <div class="dz-remove">
                                    <a
                                        v-if="!sending"
                                        href="#"
                                        v-b-tooltip.hover
                                        title="Delete"
                                        @click.prevent="removeUploadedFile()"
                                    >
                                        <i class="fal fa-trash-alt"></i>
                                    </a>
                                    <span v-if="sending"><i class="fal fa-trash-alt"></i></span>
                                </div>
                                <div
                                    class="dz-upload-info"
                                    :id="'upload-info' + file.id"
                                    style="left: 160px"
                                >
                                    <!--<i class="fal fa-clock mr-1"></i> Pending upload-->
                                </div>
                                <div
                                    class="dz-progress"
                                    :id="'upload-progress' + file.id"
                                    style="width: calc(100% - 172px)"
                                >
                                    <div class="dz-upload"></div>
                                </div>
                            </div>
                            <a
                                href="#"
                                class="dz-add-files"
                                id="decline-artwork-add-files"
                                @click.prevent="doNothing()"
                                :style="dzAddFilesStyle()"
                            >
                                <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                                Click or drop PDF file here to upload
                            </a>
                        </vue-dropzone>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="!sent" class="modal-footer">
            <span v-if="!sending">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()" :disabled="uploading">
                        <i class="far fa-paper-plane mr-1"></i> Send
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Send</span>
                </button>
            </span>
            <button type="button" class="btn btn-light ml-2" data-dismiss="modal" :disabled="sending">
                <i class="far fa-times mr-1"></i> Cancel
            </button>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    export default {
        components: {
            'vue-dropzone': vue2Dropzone
        },
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                reason_for_decline: null,
                artwork: null,
                show_attachment_upload: false,
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    acceptedFiles: 'application/pdf',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: true,
                    chunkSize: 6000000,
                    retryChunks: true,
                    retryChunksLimit: 3,
                    maxFilesize: (100 * 1000 * 1000),
                    autoProcessQueue: false,
                    timeout: 0,
                    clickable: '#decline-artwork-add-files',
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                file: null,
                uploading: false
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (data, isAssetReplaced) {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.file = null;
                this.reason_for_decline = isAssetReplaced 
                    ? 'The ' + this.$root.contents.template_title + ' has been updated so '
                        + 'cannot be approved. Please create a new version and request approval.'
                    : null;
                this.artwork = data;
                this.show_attachment_upload = false;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
            },
            onSubmit: function () {
                this.errors = false;
                this.sending = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send decline artwork request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.artwork.template_id + '/artwork/' + this.artwork.id + '/decline';
                let data = {
                    reason_for_decline: this.reason_for_decline,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                if (this.file) {
                    data.temp_file = this.file.temp_file;
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.sent = response.data.message;

                    // Remove from approval list.
                    vm.$parent.removeApproval(vm.artwork.id);

                    // Close after 4 seconds automatically.
                    setTimeout(function () {
                        $('#decline-artwork-approval-request-modal').modal('hide');
                    }, 4000);

                    // Clear notification.
                    vm.$root.clearNotification('admin', 0, 'artwork_approval_request', vm.artwork.id);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#' + key + '-label').addClass('text-danger');
                                $('#' + key).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            },
            toggleAttachmentUpload: function () {
                this.show_attachment_upload = !this.show_attachment_upload;
            },
            doNothing: function () {
                return false;
            },
            dzAddFilesStyle: function () {
                if (!this.file) {
                    return 'display: block';
                }

                return 'display: none';
            },
            onFileAdded: function (file) {
                if (!this.uploading) {
                    this.errors = false;
                }
                if (this.file) {
                    this.$refs.declineArtworkDropzone.removeFile(file);

                    return null;
                }

                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.declineArtworkDropzone.removeFile(file);
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: 'Maximum upload size exceeded',
                        text: 'The maximum size of the image you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of ' + file.name + ' is '
                            + this.$root.formatFileSize(file.size) + '.'
                    };
                    $('#message-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                    return null;
                }

                this.errors = false;
                let newFile = {
                    id: file.upload.uuid,
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null)
                };
                this.file = newFile;
                var vm = this;
                setTimeout(function () {
                    if (vm.file) {
                        vm.$refs.declineArtworkDropzone.processQueue();
                        vm.uploading = true;
                        $('#upload-info' + vm.file.id).html('Preparing upload...');
                    }
                }, 500);
            },
            onDragOver: function (event) {
                $('#decline-artwork-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#decline-artwork-dropzone').removeClass('dz-drag-highlight');
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', file.upload.uuid);
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if (!this.file) {
                    return null;
                }
                if ((progress < 100) && (this.file.id == file.upload.uuid)) {
                    if (isNaN(bytesSent)) {
                        bytesSent = file.size * (progress / 100);
                    }
                    this.file.upload.progress = progress;
                    this.file.upload.bytes_sent = bytesSent;
                    $('#upload-info' + this.file.id)
                        .html(this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%');
                    $('#upload-progress' + this.file.id + ' > div').css('width', progress + '%');
                }
            },
            onFileUploaded: function (file, response) {
                if (!this.file) {
                    return null;
                }
                if (this.file.id == file.upload.uuid) {
                    this.file.upload.progress = 100;
                    this.file.upload.bytes_sent = file.size;
                    $('#upload-info' + this.file.id).html('Finalising upload...');
                    $('#upload-progress' + this.file.id + ' > div').css('width', '100%');
                    if (!this.file.preview_image) {
                        this.file.preview_image = (file.dataURL || null);
                    }

                    this.getUploadInfo(this.file.id);
                    this.$refs.declineArtworkDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId
                    + '/preview/500x500';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.file.id != response.data.file_id) {
                        return null;
                    }
                    vm.file.temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.file.id).html('');

                    if (response.data.preview) {
                        vm.file.preview_image = response.data.preview.src;
                    }
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                this.uploading = false;
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    if (!file.accepted) {
                        let error = 'Invalid file type: ' + file.name;
                        if (!this.errors) {
                            error += '<br><br>Please choose a PDF document.';
                            this.errors = error;
                        } else {
                            this.errors = error + '<br>' + this.errors;
                        }
                        this.$refs.declineArtworkDropzone.removeFile(file);
                        this.file = null;
                    }
                    if (!this.errors) {
                        this.errors = 'Something went wrong, please try again';
                    }
                }
                this.uploading = false;
                console.log(file, message);
            },
            removeUploadedFile: function(confirmed = false) {
                if (confirmed) {
                    const removeUploadFileId = (this.file.upload.progress < 100) ? this.file.id : null;

                    this.file = null;
                    this.errors = false;

                    if (removeUploadFileId) {
                        this.$refs.declineArtworkDropzone.getUploadingFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.declineArtworkDropzone.removeFile(file);
                            }
                        });
                        this.$refs.declineArtworkDropzone.getQueuedFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.declineArtworkDropzone.removeFile(file);
                            }
                        });
                    }

                    this.toggleAttachmentUpload();
                
                    return null;
                }

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Delete file',
                    text: 'Are you sure you want to delete: ' + this.file.filename + '?',
                    component: this,
                    action: 'delete-file'
                };
                $('#confirm-delete-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            confirmDelete: function (action) {
                if (action == 'delete-file') {
                    this.removeUploadedFile(true);
                }
            }
        }
    }
</script>
<style scoped>
    #decline-artwork-dropzone {
        min-height: 126px;
    }

    #decline-artwork-add-files {
        padding-top: 45px;
    }

    .dz-uploaded-file {
        cursor: default;
    }
</style>
