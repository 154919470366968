<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="subfolder-archive-modal-label">
                Archive {{ $root.contents.subfolder_title }}: {{ subfolder_name }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="archived" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="archived"></div>
                </div>
            </div>
            <form v-else v-on:submit.prevent="archiveSubfolder()">
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="loading"><loading></loading></div>

                <div v-else>
                    <p>{{ additionalThings() }}</p>
                    <p v-if="(assets_live_count > 0)">
                        If you need to manage {{ $root.contents.asset_title }}s you should do this first.
                        You can move {{ $root.contents.asset_title }}s to other {{ $root.contents.folders_title }}
                        by adding them to a collection and using the Bulk actions function.
                    </p>
                </div>
            </form>
        </div>
        <div v-if="!archived" class="modal-footer">
            <span v-if="!archiving">
                <button
                    :key="'archive-subfolder-button'"
                    type="button"
                    class="btn btn-primary mr-2"
                    @click="archiveSubfolder()"
                    :disabled="loading"
                >
                    <i class="far fa-archive mr-1"></i> Archive {{ $root.contents.subfolder_title }}
                    <span v-if="assets_live_count > 0">
                        and {{ $root.contents.asset_title }}<span v-if="assets_live_count > 1">s</span>
                    </span>
                </button>
                <button
                    v-if="(assets_live_count > 0)"
                    :key="'archive-assets-only-button'"
                    type="button"
                    class="btn btn-primary mr-2"
                    @click="archiveSubfolder(true)"
                    :disabled="loading"
                >
                    <i class="far fa-archive mr-1"></i> Archive
                    {{ $root.contents.asset_title }}<span v-if="assets_live_count > 1">s</span> only
                </button>
            </span>
            <span v-else>
                <button :key="'archiving-button'" type="button" class="btn btn-primary mr-2">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Archiving</span>
                </button>
            </span>
            <button
                :key="'cancel-button'"
                type="button"
                class="btn btn-light"
                data-dismiss="modal"
                :disabled="archiving"
            >
                <i class="far fa-times mr-1"></i> Cancel
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: false,
                errors: false,
                folder_id: 0,
                subfolder_id: 0,
                subfolder_name: '',
                assets_live_count: 0,
                archiving: false,
                archived: false,
                list_component: null
            }
        },
        mounted () {
            //
        },
        watch: {
            assets_live_count: function (val) {
                if (val > 0) {
                    $('#subfolder-archive-modal .modal-dialog').addClass('modal-lg');

                    return null;
                }
                $('#subfolder-archive-modal .modal-dialog').removeClass('modal-lg');
            }
        },
        methods: {
            reset: function (subfolder, listComponent) {
                this.errors = false;
                this.loading = true;
                this.folder_id = subfolder.folder_id;
                this.subfolder_id = subfolder.id;
                this.subfolder_name = subfolder.name;
                this.list_component = listComponent;
                this.assets_live_count = 0;
                this.archiving = false;
                this.archived = false;
                this.getAssetInfo();
            },
            getAssetInfo: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/' + this.folder_id
                    + '/subfolder/' + this.subfolder_id + '/archive/resource-info';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.assets_live_count = response.data.live;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#subfolder-archive-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            additionalThings: function () {
                if (this.assets_live_count == 0) {
                    return 'Are you sure you want to archive this ' + this.$root.contents.subfolder_title + '?'
                }
                let text = 'This ' + this.$root.contents.subfolder_title + ' contains ' + this.assets_live_count
                    + ' ' + this.$root.contents.asset_title;
                if (this.assets_live_count > 1) {
                    text += 's';
                }
                text += '. Any ' + this.$root.contents.asset_title + 's associated with other '
                    + this.$root.contents.subfolders_title + ' (within the same ' + this.$root.contents.folder_title
                    + ') will be unaffected.';

                return text;
            },
            archiveSubfolder: function (assetsOnly = false) {
                this.archiving = true;
                const data = {
                    resources_only: assetsOnly
                };
                /**
                 * Send archive subfolder request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/' + this.folder_id
                    + '/subfolder/' + this.subfolder_id + '/archive';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.archived = response.data.message;
                    vm.list_component.getSubfolders();
                    vm.$root.folders_changed = true;

                    setTimeout(function () {
                        $('#subfolder-archive-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#subfolder-archive-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    }
                })
                .finally(() => {
                    vm.archiving = false;
                });
            }
        }
    }
</script>
