<template>
    <div>
        <div class="card linked-file-details">
            <div class="card-body" style="position: relative">
                <div
                    v-if="errors"
                    v-html="errors"
                    class="alert alert-danger"
                    role="alert"
                    style="width: calc(100% - 30px)"
                ></div>

                <div v-if="linked_file" class="row">
                    <div class="col-lg-8 col-md-6 mt-2r mt-md-0 asset-preview">
                        <div
                            v-if="(prevItem() || nextItem())"
                            class="d-md-none"
                            style="position: absolute; top: -36px"
                        >
                            <button
                                type="button"
                                :class="'prev-next close' + (prevItem() ? '' : ' disabled')"
                                id="collection-linked-file-details-prev-button-sm"
                                :style="'float: none' + (prevItem() ? '' : '; cursor: default')"
                                @click="viewItem(prevItem())"
                            >
                                <span style="font-size: 22px">
                                    <i class="fal fa-arrow-alt-left"></i>
                                </span>
                            </button>
                            <b-tooltip
                                v-if="prevItem()"
                                target="collection-linked-file-details-prev-button-sm"
                                triggers="hover"
                            >
                                Previous
                            </b-tooltip>

                            <button type="button" class="close disabled" style="float: none; cursor: default">
                                <span style="font-size: 20px; position: relative; top: -1px">
                                    <i class="fal fa-horizontal-rule fa-rotate-90"></i>
                                </span>
                            </button>

                            <button
                                type="button"
                                :class="'prev-next close' + (nextItem() ? '' : ' disabled')"
                                id="collection-linked-file-details-next-button-sm"
                                :style="'float: none' + (nextItem() ? '' : '; cursor: default')"
                                @click="viewItem(nextItem())"
                            >
                                <span style="font-size: 22px">
                                    <i class="fal fa-arrow-alt-right"></i>
                                </span>
                            </button>
                            <b-tooltip
                                v-if="nextItem()"
                                target="collection-linked-file-details-next-button-sm"
                                triggers="hover"
                            >
                                Next
                            </b-tooltip>
                        </div>
                        <div class="thumbnail">
                            <div v-if="!image_src" class="image-loading">
                                <loading></loading>
                            </div>
                            <img v-else class="preview-image" :src="image_src" :alt="linked_file.title">
                            <video
                                v-if="(image_src && video_src)"
                                class="video-player"
                                :width="video_width"
                                :height="video_height"
                                controls
                            >
                                <source :src="video_src" type="video/mp4">
                            </video>
                            <audio
                                v-if="(image_src && audio_src)"
                                class="audio-player"
                                controls
                            >
                                <source :src="audio_src" type="audio/mpeg">
                            </audio>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6" style="padding-top: 30px">
                        <div
                            v-if="(prevItem() || nextItem())"
                            class="d-none d-md-block"
                            style="position: absolute; top: -4px"
                        >
                            <button
                                type="button"
                                :class="'prev-next close' + (prevItem() ? '' : ' disabled')"
                                id="collection-linked-file-details-prev-button"
                                :style="'float: none' + (prevItem() ? '' : '; cursor: default')"
                                @click="viewItem(prevItem())"
                            >
                                <span style="font-size: 22px">
                                    <i class="fal fa-arrow-alt-left"></i>
                                </span>
                            </button>
                            <b-tooltip
                                v-if="prevItem()"
                                target="collection-linked-file-details-prev-button"
                                triggers="hover"
                            >
                                Previous
                            </b-tooltip>

                            <button type="button" class="close disabled" style="float: none; cursor: default">
                                <span style="font-size: 20px; position: relative; top: -1px">
                                    <i class="fal fa-horizontal-rule fa-rotate-90"></i>
                                </span>
                            </button>

                            <button
                                type="button"
                                :class="'prev-next close' + (nextItem() ? '' : ' disabled')"
                                id="collection-linked-file-details-next-button"
                                :style="'float: none' + (nextItem() ? '' : '; cursor: default')"
                                @click="viewItem(nextItem())"
                            >
                                <span style="font-size: 22px">
                                    <i class="fal fa-arrow-alt-right"></i>
                                </span>
                            </button>
                            <b-tooltip
                                v-if="nextItem()"
                                target="collection-linked-file-details-next-button"
                                triggers="hover"
                            >
                                Next
                            </b-tooltip>
                        </div>
                        
                        <h5>{{ linked_file.title }}</h5>
                        <hr class="mt-0">

                        <p>ATTACHMENT INFORMATION</p>
                        <div class="form-group">
                            <p class="mb-1">Description: {{ linked_file.description }}</p>
                            <p class="mb-1">File format: {{ linked_file.file_type }}</p>
                            <p class="mb-1">Added: {{ created_at_formatted }}</p>
                        </div>

                        <div v-if="($root.hasPermission('assets_download') || in_share)" class="form-group">
                            <loading v-if="downloads_loading" align="left"></loading>
                            <div v-else>
                                <p v-if="(download_options.length == 0)">
                                    There are no available download options
                                </p>
                                <div v-else class="download-options-container pr-3">
                                    <div
                                        v-for="item in download_options"
                                        :key="item.file_path"
                                        class="form-control p-0 mb-3"
                                        style="height: auto"
                                    >
                                        <div class="row no-gutters" style="min-height: 36px">
                                            <div class="col-4 pt-2 pb-2 pl-2" style="text-transform: uppercase">
                                                {{ item.extension }}
                                                <span v-if="item.colour" class="badge badge-info font-size-11">
                                                    {{ item.colour }}
                                                </span>
                                            </div>
                                            <div class="col-sm-2 col-3 pt-2 pb-2">
                                                {{ $root.formatFileSize(item.size) }}
                                            </div>
                                            <div class="col-sm-4 col-5 pt-2 pb-2 pl-2">
                                                <span v-if="item.dimensions">
                                                    {{ item.dimensions[0] }}x{{ item.dimensions[1] }}px
                                                </span>&nbsp;
                                            </div>
                                            <div class="col-sm-2 text-sm-right">
                                                <button
                                                    v-if="(downloading != item.file_path)"
                                                    class="btn btn-primary download-option-button"
                                                    type="button"
                                                    v-b-tooltip.hover.left
                                                    title="Download"
                                                    :disabled="downloading"
                                                    @click="downloadLinkedFile(item)"
                                                >
                                                    <i class="fal fa-download"></i>
                                                </button>
                                                <span v-else>
                                                    <button
                                                        class="btn btn-primary download-option-button"
                                                        type="button"
                                                    >
                                                        <i class="fal fa-spinner-third fa-spin"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="canDeleteLinkedFile()" class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <button
                                        type="button"
                                        class="btn btn-secondary btn-block"
                                        :disabled="deleting"
                                        @click="deleteLinkedFile()"
                                    >
                                        Delete attachment
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="position: absolute; right: 22px; top: 12px">
                    <button type="button" class="close" aria-label="Close" @click="close()">
                        <span aria-hidden="true" style="font-size: 38px"><i class="fal fa-times"></i></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['in_share', 'email'],
        data () {
            return {
                errors: false,
                linked_file: null,
                image_src: null,
                video_src: null,
                video_width: 0,
                video_height: 0,
                audio_src: null,
                get_date_time_formatted_response: null,
                created_at_formatted: '',
                downloads_loading: false,
                download_options: [],
                downloading: null,
                deleting: false
            }
        },
        mounted () {
            //
        },
        watch: {
            get_date_time_formatted_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.id == 'created_at') {
                            this.created_at_formatted = val.date_time_formatted;
                        }
                    }
                }
            },
        },
        methods: {
            setLinkedFile: function (linkedFile) {
                if (!linkedFile) {
                    this.linked_file = null;

                    return null;
                }

                this.errors = false;
                this.linked_file = linkedFile;
                this.created_at_formatted = '';
                this.$root.getDateTimeFormatted(this, 'created_at', this.linked_file.created_at, 'g:ia d/m/Y');
                this.downloads_loading = true;
                this.download_options = [];
                this.image_src = null;
                this.video_src = null;
                this.audio_src = null;
                this.getPreviewImage();
                this.getDownloadOptions();

                const itemIndex = this.getItemIndex();
                let totalItems = this.$parent.total_items || this.$parent.items.length;
                if (this.$parent.component_name == 'view-collection') {
                    totalItems = (this.$parent.total_items_assets > 0)
                        ? this.$parent.total_items_linked_files + this.$parent.total_items_assets
                        : this.$parent.linked_file_ids.length + this.$parent.asset_ids.length;
                }
                if (
                    ((itemIndex < 0) || (itemIndex > (this.$parent.items.length - 3)))
                    && (totalItems > this.$parent.items.length)
                ) {
                    this.$parent.getAssets(this.$parent.current_page + 1, false);
                }
            },
            close: function () {
                this.$parent.$data.view_asset = null;
            },
            getPreviewImage: function () {
                /**
                 * Send request to API
                 */
                let size = 'mediumres';
                if (this.linked_file.file_type == 'image/gif') {
                    size = 'highres';
                }
                if (this.linked_file.file_extension == 'svg') {
                    size = 'svg';
                }
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.linked_file.collection_id + '/linked-file/' + Math.abs(this.linked_file.id) + '/preview/'
                    + size;
                if (!this.$root.isLoggedIn() && this.in_share) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    url: url,
                    headers: (!this.$root.isLoggedIn() && this.in_share) ? null : { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                        $('.video-player').hide();
                        setTimeout(function () {
                            vm.video_width = $('.preview-image').outerWidth();
                            vm.video_height = $('.preview-image').outerHeight();
                            $('.video-player').show();
                        }, 200);
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                });
            },
            getDownloadOptions: function () {
                /**
                 * Send request to API
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.linked_file.collection_id + '/linked-file/' + Math.abs(this.linked_file.id)
                    + '/download-options';
                if (!this.$root.isLoggedIn() && this.in_share) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    url: url,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    vm.download_options = response.data.download_options;
                    if (vm.linked_file.file_type == 'video/mp4') {
                        vm.downloadLinkedFile(vm.download_options[0], true);
                    }
                    if (vm.linked_file.file_type == 'audio/mpeg') {
                        vm.downloadLinkedFile(vm.download_options[0], false, true);
                    }
                })
                .catch(function (error) {
                    //
                })
                .finally(() =>
                    vm.downloads_loading = false
                );
            },
            downloadLinkedFile: function (download, streamVideo = false, streamAudio = false) {
                if (!streamVideo && !streamAudio) {
                    this.downloading = download.file_path;
                }
                /**
                 * Send request to API
                 */
                const data = {
                    item_type: 'collection_linked_file',
                    item_id: Math.abs(this.linked_file.id),
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension,
                    stream_video: streamVideo,
                    stream_audio: streamAudio
                };
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                if (!this.$root.isLoggedIn() && this.in_share) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    if (response.data.stream_video) {
                        vm.video_src = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/stream-video/'
                            + response.data.token;

                        return null;
                    }
                    if (response.data.stream_audio) {
                        vm.audio_src = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/stream-audio/'
                            + response.data.token;

                        return null;
                    }
                    window.location.href = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.$parent.$parent.message = {
                            type: 'error',
                            title: 'Download',
                            text: error.response.data.error
                        };
                        $('#message-modal').modal('show');
                    }
                })
                .finally(() =>
                    vm.downloading = null
                );
            },
            deleteLinkedFile: function (confirmed = false) {
                if (confirmed) {
                    this.deleting = true;
                    if (this.$route.name == 'view-collection') {
                        const linkedFiles = [this.linked_file.id];
                        this.$parent.deleteLinkedFiles(linkedFiles);
                        this.$parent.view_asset = null;

                        return null;
                    }

                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                        + this.linked_file.collection_id + '/linked-file/' + Math.abs(this.linked_file.id);
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.$parent.view_asset = null;
                        const component = vm.$root.findComponent(vm.$parent, 'asset-selection');
                        if (component) {
                            component.getCollection(vm.linked_file.collection_id);
                        }
                        if (vm.$route.name == 'collections') {
                            vm.$parent.getCollections();
                        }
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    });

                    return null;
                }

                this.$parent.$parent.confirm_delete = {
                    title: 'Delete attachment',
                    text: 'Are you sure you want to delete: ' + this.linked_file.title + '?'
                        + '<br><br>This action cannot be undone.',
                    component: this,
                    action: 'delete-linked-file'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action = null) {
                this.deleteLinkedFile(true);
            },
            canDeleteLinkedFile: function () {
                if (!this.$root.isLoggedIn()) {
                    return false;
                }
                if (this.in_share) {
                    return false;
                }

                return this.$root.hasPermission('assets_manage_attachments');
            },
            prevItem: function () {
                const itemIndex = this.getItemIndex();
                if (itemIndex < 1) {
                    return null;
                }
                if (this.$parent.view_asset_from_selection) {
                    const component = this.$root.findComponent(this.$parent, 'asset-selection');
                    return component.assets[itemIndex - 1]
                }
                
                return this.$parent.items[itemIndex - 1];
            },
            nextItem: function () {
                const itemIndex = this.getItemIndex();
                if (itemIndex < 0) {
                    return null;
                }
                if (this.$parent.view_asset_from_selection) {
                    const component = this.$root.findComponent(this.$parent, 'asset-selection');

                    return (itemIndex < (component.assets.length - 1)) ? component.assets[itemIndex + 1] : null;
                }
                
                return (itemIndex < (this.$parent.items.length - 1)) ? this.$parent.items[itemIndex + 1] : null;
            },
            getItemIndex: function () {
                if (!this.linked_file) {
                    return -1;
                }
                let itemIndex = -1;
                if (this.$parent.view_asset_from_selection) {
                    const component = this.$root.findComponent(this.$parent, 'asset-selection');
                    if (component.assets.length == 0) {
                        return -1;
                    }
                    component.assets.forEach((item, index) => {
                    if (item.id == this.linked_file.id) {
                            itemIndex = index;
                        }
                    });

                    return itemIndex;
                }

                if (this.$parent.items.length == 0) {
                    return -1;
                }
                this.$parent.items.forEach((item, index) => {
                    if (item.id == this.linked_file.id) {
                        itemIndex = index;
                    }
                });

                return itemIndex;
            },
            viewItem: function (item) {
                if (!item) {
                    return null;
                }
                this.$parent.view_asset = item;
            }
        }
    }
</script>
<style scoped>
    .mt-2r {
        margin-top: 2rem;
    }

    .linked-file-details {
        display: none;
    }

    .asset-preview .thumbnail {
        height: 572px;
    }

    .asset-preview .thumbnail img {
        max-height: 570px;
    }

    .asset-preview .thumbnail .image-loading {
        position: absolute;
        height: 30px;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .asset-preview .thumbnail .video-player {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    #collection-linked-file-details-prev-button, #collection-linked-file-details-next-button {
        opacity: 1;
    }

    #collection-linked-file-details-prev-button.disabled, #collection-linked-file-details-next-button.disabled {
        color: #000;
        opacity: 0.5;
    }

    .audio-player {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    .download-options-container, .share-options-container {
        width: calc(100% + 15px);
        max-height: 440px;
        overflow-y: auto;
    }

    @media (max-width: 1199px) {
        .asset-preview .thumbnail {
            height: 412px;
        }

        .asset-preview .thumbnail img {
            max-height: 410px;
        }

        .download-options-container, .share-options-container {
            max-height: 280px;
        }
    }

    @media (max-width: 991px) {
        .asset-preview .thumbnail {
            height: 246px;
        }

        .asset-preview .thumbnail img {
            max-height: 244px;
        }

        .download-options-container, .share-options-container {
            max-height: 184px;
        }
    }

    @media (max-width: 767px) {
        .asset-preview .thumbnail {
            height: 382px;
        }

        .asset-preview .thumbnail img {
            max-height: 380px;
        }

        .download-options-container, .share-options-container {
            max-height: none;
        }
    }

    @media (max-width: 575px) {
        .asset-preview .thumbnail {
            height: 274px;
        }

        .asset-preview .thumbnail img {
            max-height: 272px;
        }
    }
</style>
