<template>
    <div>
        <div class="asset-selection collection">
            <div class="container-fluid tools">
                <div class="row pl-2 pr-2">
                    <div class="col-10">
                        <table>
                            <tr>
                                <td v-if="!loading" valign="middle" height="60">
                                    <span style="position: relative">
                                        <i class="fa-light fa-floppy-disk font-size-22 mr-2"></i>
                                        <span class="badge badge-pill collection-items-count">
                                            {{ num_artworks }}
                                        </span>
                                    </span>
                                </td>
                                <td v-if="!loading" valign="middle" height="60">
                                    <div
                                        class="selection-name collection"
                                        id="saved-artworks-bar-title"
                                        style="cursor: pointer"
                                        @click="toggleExpanded()"
                                    >
                                        <span>Saved versions</span>
                                    </div>
                                    <b-tooltip
                                        target="saved-artworks-bar-title"
                                        triggers="hover"
                                    >
                                        {{ is_expanded ? 'Hide' : 'Show' }}
                                    </b-tooltip>
                                </td>
                                <td v-if="loading" valign="middle" height="60">
                                    <span>
                                        <span>
                                            <span><i class="fal fa-spinner-third fa-spin font-size-22"></i></span>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-2">
                        <table class="asset-selection-buttons">
                            <tr>
                                <td class="text-right" valign="middle" height="60">
                                    <button
                                        v-if="!is_expanded"
                                        type="button"
                                        class="btn btn-link px-1 px-sm-2 mr-1 mr-md-2"
                                        v-b-tooltip.hover
                                        title="Show"
                                        @click="toggleExpanded()"
                                    >
                                        <i class="fal fa-chevron-circle-up font-size-22"></i>
                                    </button>
                                    <span v-else>
                                        <button
                                            type="button"
                                            class="btn btn-link px-1 px-sm-2 mr-1 mr-md-2"
                                            v-b-tooltip.hover
                                            title="Hide"
                                            @click="toggleExpanded()"
                                        >
                                            <i class="fal fa-chevron-circle-down font-size-22"></i>
                                        </button>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="view-selection pt-2 pb-3">
                <div class="container-fluid">
                    <div v-if="loading"><loading></loading></div>

                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div v-if="(!loading && !errors)" style="position: relative">
                        <div class="selected-assets">
                            <div v-for="item in saved_artworks" :key="item.id" class="preview ml-4">
                                <saved-artwork-bar-preview
                                    :asset="asset"
                                    :template="template"
                                    :data="item"
                                    :is_visible="is_expanded"
                                ></saved-artwork-bar-preview>
                            </div>
                        </div>
                        <span
                            class="sa-scroll-left contact-links"
                            style="font-size: 40px"
                            @click="scrollAssets('left')"
                        >
                            <a href="#" @click.prevent="doNothing()"><i class="fal fa-chevron-left"></i></a>
                        </span>
                        <span
                            class="sa-scroll-right contact-links"
                            style="font-size: 40px"
                            @click="scrollAssets('right')"
                        >
                            <a href="#" @click.prevent="doNothing()"><i class="fal fa-chevron-right"></i></a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['asset', 'template', 'artworks', 'num_artworks', 'initial', 'collapse'],
        data () {
            return {
                loading: false,
                errors: false,
                is_expanded: false,
                saved_artworks: [],
                scroll_assets_check_interval: null
            }
        },
        mounted () {
            var vmSavedArtworksBar = this;
            vmSavedArtworksBar.scroll_assets_check_interval = setInterval(function () {
                vmSavedArtworksBar.scrollAssetsCheck();
            }, 999);

            this.updateArtworks(this.artworks);

            $(window).on('resize', function () {
                vmSavedArtworksBar.toggleSelection();
            });
            this.toggleSelection();
        },
        beforeDestroy() {
            clearInterval(this.scroll_assets_check_interval);
        },
        watch: {
            num_artworks: function (val, oldVal) {
                $('.asset-selection .collection-items-count').css({
                    'font-size': '115%',
                    'min-width': '21px'
                });
                setTimeout(function () {
                    $('.asset-selection .collection-items-count').css({
                        'font-size': '80%',
                        'min-width': '16px'
                    });
                }, 360);
                this.toggleSelection();
                /* Jira R2-456 - No longer need to auto-expand
                if (val && this.initial && !this.is_expanded) {
                    var vm = this;
                    setTimeout(function () {
                        vm.toggleExpanded();
                    }, 200);
                }
                */
                this.updateArtworks(this.artworks);
            },
            is_expanded: function (val) {
                if (val) {
                    $('.view-selection').slideDown(350);
                } else {
                    $('.view-selection').slideUp(350);
                }
            },
            collapse: function (val) {
                if (val && this.is_expanded) {
                    this.toggleExpanded();
                }
            }
        },
        methods: {
            updateArtworks: function (artworks) {
                if (!artworks) {
                    this.saved_artworks = [];

                    return null;
                }
                if (artworks.length == 0) {
                    this.saved_artworks = [];

                    return null;
                }
                if (this.saved_artworks.length == 0) {
                    this.saved_artworks = artworks;

                    return null;
                }
                artworks.forEach((artwork) => {
                    let itemIndex = -1;
                    this.saved_artworks.forEach((item, index) => {
                        if (item.id == artwork.id) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex < 0) {
                        this.saved_artworks.unshift(artwork);
                    }
                });
            },
            toggleSelection: function () {
                const toolsHeight = $('.asset-selection > .tools').outerHeight();
                let height = '0px';
                if (this.num_artworks > 0) {
                    height = this.is_expanded ? (238 + toolsHeight) + 'px' : toolsHeight + 'px';
                } else {
                    //height = toolsHeight + 'px';
                    this.is_expanded = false;
                    $('.sa-scroll-left').hide();
                    $('.sa-scroll-right').hide();
                }
                $('.asset-selection').css('height', height);
                this.scrollAssetsCheck();
            },
            toggleExpanded: function () {
                this.is_expanded = !this.is_expanded;
                const toolsHeight = $('.asset-selection > .tools').outerHeight();
                $('.asset-selection').css('height', this.is_expanded ? (238 + toolsHeight) + 'px' : toolsHeight + 'px');
            },
            scrollAssetsCheck: function () {
                if (this.is_expanded) {
                    if ($('.selected-assets')[0]) {
                        if ($('.selected-assets')[0].scrollWidth > ($('.selected-assets').outerWidth() + 24)) {
                            $('.sa-scroll-left').fadeIn('slow');
                            $('.sa-scroll-right').fadeIn('slow');

                            return null;
                        }
                    }
                    $('.sa-scroll-left').fadeOut('slow');
                    $('.sa-scroll-right').fadeOut('slow');
                }
            },
            scrollAssets: function (direction) {
                let steps = Math.ceil($('.selected-assets').innerWidth() - 200);
                if (steps < 295) {
                    steps = 295;
                }
                if (direction == 'right') {
                    $('.selected-assets').animate({ scrollLeft: '+=' + steps }, 700);

                    return null;
                }
                $('.selected-assets').animate({ scrollLeft: '-=' + steps }, 700);
            },
            doNothing: function () {
                return null;
            },
            removeSavedArtwork: function (artworkId) {
                let itemIndex = -1;
                this.saved_artworks.forEach((item, index) => {
                    if (item.id == artworkId) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.saved_artworks.splice(itemIndex, 1);
                    this.$parent.removeSavedArtwork(artworkId);
                }
            }
        }
    }
</script>
<style scoped>
    .view-selection {
        display: none;
        height: 238px;
    }

    .selected-assets {
        position: relative;
        top: 10px;
        left: 10px;
        height: 209px;
        max-width: calc(100% - 18px);
        overflow-x: hidden;
        overflow-y: hidden;
        white-space: nowrap;
        display: inline-block;
    }

    .selected-assets .preview {
        position: relative;
        display: inline-block;
        width: 250px;
        height: 204px;
        white-space: normal;
    }

    .selected-assets .preview:first-child {
        margin-left: 0 !important;
    }

    .sa-scroll-left {
        position: absolute;
        top: 90px;
        left: -35px;
        cursor: pointer;
        display: none;
        padding: 0px 17px 0px 13px;
        border-radius: 30px;
        line-height: 50px;
    }
    
    .sa-scroll-right {
        position: absolute;
        top: 90px;
        right: -35px;
        cursor: pointer;
        display: none;
        padding: 0px 13px 0px 17px;
        border-radius: 30px;
        line-height: 50px;
    }

    .selection-name {
        font-size: 16.25px;
        font-weight: 300;
        max-height: 45px;
        line-height: 1.4;
    }

    .selection-name.collection {
        overflow: hidden;
    }

    .asset-selection-buttons {
        width: 100%;
    }

    .asset-selection .collection-items-count {
        position: absolute;
        top: -12px;
        right: 4px;
        transition: font-size .35s ease, min-width .35s ease;
        padding: 3px 5px 3px 5px;
        min-width: 16px;
        font-size: 80%;
    }

    /*.asset-selection .files-count {
        transition: left .35s ease, top .35s ease, font-size .35s ease, min-width .35s ease;
        line-height: 1;
        font-size: 16px;
        padding: 3px 6px 3px 6px;
        min-width: 22px;
    }*/

    @media (max-width: 767px) {
        .asset-selection .tools {
            height: 60px;
        }

        /*.asset-selection-buttons {
            position: relative;
            top: -15px;
        }*/

        .selected-assets {
            overflow-x: auto;
        }

        .asset-selection .tools.autoheight {
            height: auto;
        }

        .asset-selection .tools.autoheight .asset-selection-buttons {
            position: static;
        }
    }

    @media (max-width: 1199px) {
        .sa-scroll-left {
            left: -10px;
            background-color: rgba(255, 255, 255, 0.8);
        }
        
        .sa-scroll-right {
            right: -10px;
            background-color: rgba(255, 255, 255, 0.8);
        }
    }

    /*@media (max-width: 575px) {
        .selected-assets .preview {
            width: 272px;
        }
    }*/
</style>
