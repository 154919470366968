<template>
    <div :class="panelClass()">
        <div :class="'card-body welcome-panel text-center pt-' + (hasProfile() ? '4' : '5')">
            <div class="avatar-container mb-3">
                <div v-if="$root.user.profile_image" class="avatar-md bordered rounded-circle">
                    <img
                        class="rounded-circle avatar-md"
                        :src="$root.user.profile_image"
                        alt="avatar"
                    >
                </div>
                <div v-else class="avatar-md bordered rounded-circle mb-3">
                    <span class="avatar-title rounded-circle">
                        {{ $root.initial($root.user.first_name, $root.user.last_name) }}
                    </span>
                </div>
            </div>
            <p :class="'mb-' + (hasProfile() ? '1' : '2')">WELCOME BACK</p>
            <p style="font-weight: 300">{{ $root.user.first_name }}</p>
            <div class="text-center pb-3" style="position: absolute; left: 0; bottom: 0; width: 100%">
                <router-link :to="{ name: 'my-account' }" custom v-slot="{ navigate }">
                    <button :class="buttonClass()" @click="navigate" style="min-width: 200px">
                        <i class="far fa-user mr-1"></i> Manage my account
                    </button>
                </router-link>
                <div v-if="hasProfile()" class="mt-2" style="min-height: 34px">
                    <loading v-if="profile_group_loading" size="1"></loading>
                    <router-link
                        v-if="!profile_group_loading && hasProfileGroup()"
                        :to="{ name: 'view-profile-group', params: { id: profile_group_id } }"
                        custom
                        v-slot="{ navigate }"
                    >
                        <button :class="buttonClass()" @click="navigate" style="min-width: 200px">
                            <i class="far fa-building mr-1"></i> Manage my {{ $root.contents.profile_group_title }}
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                profile_group_id: null,
                profile_group_loading: true,
                reset_height_interval: null,
                prev_width: 0,
                prev_width2: 0
            }
        },
        mounted () {
            if (this.hasProfile()) {
                this.getProfileGroup();
            }
            var vm = this;
            setTimeout(function () {
                vm.resetHeight();
            }, 200);

            vm.reset_height_interval = setInterval(function () {
                vm.resetHeight();
            }, 999);

            $(window).resize(function () {
                vm.resetHeight();
            });
        },
        beforeDestroy() {
            clearInterval(this.reset_height_interval);
        },
        methods: {
            panelClass: function () {
                const panelColour = (this.$root.client.welcome_panel_colour || 'third');

                return 'card mb-3 bg-' + panelColour + ' text-' + panelColour;
            },
            buttonClass: function () {
                const buttonColour = (this.$root.client.welcome_panel_button_colour || 'primary');

                return 'btn btn-light bg-' + buttonColour + ' text-' + buttonColour;
            },
            resetHeight: function () {
                const width = $('.welcome-panel').outerWidth();
                let noReset = false;
                if ((width == this.prev_width) || ((width == this.prev_width2) && (width < this.prev_width))) {
                    noReset = true;
                }
                this.prev_width2 = this.prev_width;
                this.prev_width = width;
                if (noReset) {
                    return null;
                }
                const height = Math.round(width / 0.816);
                $('.welcome-panel').css('height', height + 'px');
                if (height > 290) {
                    $('.welcome-panel').removeClass('pt-5');
                    $('.welcome-panel').removeClass('pt-4');
                    $('.welcome-panel').addClass(this.hasProfile() ? 'pt-4' : 'pt-5');
                    $('.welcome-panel .avatar-container').css('height', '100px');
                    $('.welcome-panel .avatar-container .bordered').css({
                        'width': '100px',
                        'height': '100px'
                    });

                    return null;
                }
                $('.welcome-panel').removeClass('pt-5');
                $('.welcome-panel').removeClass('pt-4');
                $('.welcome-panel').removeClass('pt-3');
                $('.welcome-panel').addClass(this.hasProfile() ? 'pt-3' : 'pt-4');
                $('.welcome-panel .avatar-container').css('height', '80px');
                $('.welcome-panel .avatar-container .bordered').css({
                    'width': '80px',
                    'height': '80px'
                });
            },
            hasProfile: function () {
                // Jira R2-818 - DISABLED.
                return false;
                
                if (!this.$root.user) {
                    return false;
                }
                if (!this.$root.isFeatured('profiles')) {
                    return false;
                }
                if (!this.$root.user.profile_id) {
                    return false;
                }
                if (!this.profile_group_loading && !this.profile_group_id) {
                    return false;
                }

                return true;
            },
            hasProfileGroup: function () {
                return (this.hasProfile() && this.profile_group_id);
            },
            getProfileGroup: function () {
                this.profile_group_loading = true;
                /**
                 * Send get profile request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile/'
                    + this.$root.user.profile_id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.profile_group_id = response.data.profile.profile_group_id;
                })
                .catch(function (error) {
                    // Do anything?
                })
                .finally(() =>
                    vm.profile_group_loading = false
                );
            }
        }
    }
</script>
<style scoped>
    .welcome-panel {
        position: relative;
        height: 250px;
        font-size: 15px;
        transition: width height .35 ease;
    }

    .avatar-container {
        position: relative;
        height: 100px;
    }

    .avatar-md.bordered {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: 100px;
        height: 100px;
        padding: 5px;
        background-color: rgba(255, 255, 255, 0.25);
    }

    .avatar-md img {
        width: 100%;
        height: 100%;
    }

    .avatar-title {
        opacity: 0.8;
        font-size: 40px;
        font-weight: 400;
        background-color: transparent;
    }
</style>
