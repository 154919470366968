import { render, staticRenderFns } from "./AssetPreview.vue?vue&type=template&id=e20ba50e&scoped=true"
import script from "./AssetPreview.vue?vue&type=script&lang=js"
export * from "./AssetPreview.vue?vue&type=script&lang=js"
import style0 from "./AssetPreview.vue?vue&type=style&index=0&id=e20ba50e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e20ba50e",
  null
  
)

export default component.exports