<template>
    <div class="card asset-preview">
        <div class="row no-gutters">
            <div class="col" style="border-right: 1px solid #eff2f7">
                <div
                    class="thumbnail"
                    style="cursor: pointer"
                    @click="viewCollection()"
                >
                    <img
                        v-if="((data.resources.length + data.linked_files.length) > 0)"
                        :id="'preview-image1' + id"
                        :src="(image_src1 ? image_src1 : default_src)"
                        :alt="title"
                    >
                </div>
            </div>
            <div class="col" style="border-right: 1px solid #eff2f7">
                <div
                    class="thumbnail"
                    style="cursor: pointer"
                    @click="viewCollection()"
                >
                    <img
                        v-if="((data.resources.length + data.linked_files.length) > 1)"
                        :id="'preview-image2' + id"
                        :src="(image_src2 ? image_src2 : default_src)"
                        :alt="title"
                    >
                </div>
            </div>
            <div class="col" style="border-right: 1px solid #eff2f7">
                <div
                    class="thumbnail"
                    style="cursor: pointer"
                    @click="viewCollection()"
                >
                    <img
                        v-if="((data.resources.length + data.linked_files.length) > 2)"
                        :id="'preview-image3' + id"
                        :src="(image_src3 ? image_src3 : default_src)"
                        :alt="title"
                    >
                </div>
            </div>
            <div class="col">
                <div
                    class="thumbnail"
                    style="cursor: pointer"
                    @click="viewCollection()"
                >
                    <img
                        v-if="((data.resources.length + data.linked_files.length) > 3)"
                        :id="'preview-image4' + id"
                        :src="(image_src4 ? image_src4 : default_src)"
                        :alt="title"
                    >
                </div>
            </div>
        </div>
        <div class="card-body pb-1" style="position: relative">
            <div class="title mb-2 text-dark font-size-15" :id="'asset-title' + id" @click="viewCollection()">
                {{ short_title }}
            </div>

            <b-tooltip
                v-if="(short_title != title)"
                :target="'asset-title' + id"
                triggers="hover"
            >
                {{ title }}
            </b-tooltip>

            <div class="mb-1" style="position: relative; height: 28px">
                <div style="position: absolute; right: 0px; top: 2px">{{ itemsCount() }}</div>
                <div v-if="(collaborators.length > 1)" style="height: 28px; position: relative">
                    <div
                        v-for="item in collaborators"
                        :key="id + '-collaborator-' + item.collaborator_id"
                        class="mr-1"
                        style="display: inline-block"
                    >
                        <div
                            v-if="item.profile_image"
                            :key="'collaborator-image-' + item.user_id"
                            class="rounded-circle avatar-xs bordered"
                            :style="'left: ' + (item.index * 22 - 3) + 'px'"
                            v-b-tooltip.hover
                            :title="(item.owner ? 'Owner' : 'Collaborator') + ': ' + item.first_name + ' '
                                + item.last_name"
                        >
                            <img class="rounded-circle avatar-xs" :src="item.profile_image" alt="avatar">
                        </div>
                        <div
                            v-else
                            :key="'collaborator-initial-' + item.user_id"
                            class="rounded-circle avatar-xs bordered"
                            :style="'left: ' + (item.index * 22 - 3) + 'px'"
                            v-b-tooltip.hover
                            :title="(item.owner ? 'Owner' : 'Collaborator') + ': ' + item.first_name + ' '
                                + item.last_name"
                        >
                            <span class="avatar-title rounded-circle">
                                {{ $root.initial(item.first_name, item.last_name) }}
                            </span>
                        </div>
                    </div>
                    <div
                        v-if="(collaborators.length < all_collaborators_count)"
                        class="rounded-circle avatar-xs bordered"
                        :style="'left: ' + (collaborators.length * 22 - 3) + 'px'"
                        v-b-tooltip.hover
                        title="View all"
                    >
                        <router-link :to="{ name: 'view-collection', params: { id: id } }">
                            <span class="avatar-title rounded-circle bg-light text-dark">
                                +{{ all_collaborators_count - collaborators.length }}
                            </span>
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="font-size-22 contact-links">
                <span v-if="canDownloadCollection()">
                    <a
                        v-if="!downloading"
                        href="#"
                        @click.prevent="downloadCollection()"
                    >
                        <span v-b-tooltip.hover title="Download">
                            <i class="fal fa-download mr-2"></i>
                        </span>
                    </a>
                    <span v-else>
                        <i class="fal fa-spinner-third fa-spin mr-2"></i>
                    </span>
                </span>
                <a
                    v-if="canShareCollection()"
                    href="#"
                    data-toggle="modal"
                    data-target="#collection-share-modal"
                    @click.prevent="shareCollection()"
                >
                    <span v-b-tooltip.hover title="Share">
                        <i class="fal fa-share-alt mr-2"></i>
                    </span>
                </a>
                <a
                    href="#"
                    data-toggle="modal"
                    data-target="#collaborator-add-form-modal"
                    @click.prevent="resetCollaboratorAddForm()"
                >
                    <span v-b-tooltip.hover title="Add collaborator">
                        <i class="fal fa-user-plus"></i>
                    </span>
                </a>
                <span class="float-right">
                    <a
                        v-if="(data.user_id == $root.user.id)"
                        href="#"
                        class="mr-2"
                        @click.prevent="deleteCollection()"
                    >
                        <span v-b-tooltip.hover title="Delete collection">
                            <i class="fal fa-trash-alt"></i>
                        </span>
                    </a>
                    <a
                        href="#"
                        class="mr-2"
                        @click.prevent="viewCollection()"
                    >
                        <span v-b-tooltip.hover title="Open collection">
                            <i class="fa-light fa-gallery-thumbnails"></i>
                        </span>
                    </a>

                    <span
                        v-if="($root.current_collection != id)"
                        v-b-tooltip.hover
                        :title="'Make active to collect new ' + $root.contents.asset_title + 's'"
                        @click="selectCollection()"
                    >
                        <span><i class="far fa-toggle-on fa-flip-horizontal"></i></span>
                    </span>
                    <span v-else v-b-tooltip.hover title="Active">
                        <span>
                            <a
                                href="#"
                                class="collection-selected"
                                @click.prevent="selectCollection(false)"
                            >
                                <i class="far fa-toggle-on"></i>
                            </a>
                        </span>
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data () {
            return {
                id: 0,
                default_src: '/images/file-icon.png',
                image_src1: null,
                image_src2: null,
                image_src3: null,
                image_src4: null,
                title: '',
                short_title: '',
                asset_ids: [],
                assets: [],
                linked_files: [],
                downloading: false,
                collaborators: [],
                all_collaborators_count: 0,
            }
        },
        mounted () {
            this.updateData(this.data);
            var vm = this;
            $(window).resize(function () {
                vm.short_title = vm.title;
                setTimeout(function () {
                    vm.fitTitle();
                }, 500);
            });
        },
        watch: {
            data: function (val) {
                if (val) {
                    this.updateData(val);
                }
            }
        },
        methods: {
            updateData: function (data) {
                if (data) {
                    this.id = data.id;
                    this.title = data.name;
                    this.short_title = data.name;
                    this.asset_ids = data.resources;
                    this.getAssets();
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                        vm.getCollaborators();
                    }, 500);
                }
            },
            getAssets: function () {
                if (this.asset_ids.length == 0) {
                    // Get previews from linked files
                    this.getLinkedFiles();

                    return null;
                }
                /**
                 * Send request to API
                 */
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/resources/cl::'
                    + this.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.assets = response.data.resources.data;
                    vm.getPreviewImages();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                });
            },
            getPreviewImages: function () {
                let previews = [];
                // Try JPEG and PNG images first
                let fileTypes = ['jpg', 'jpeg', 'png'];
                this.assets.forEach((item, index) => {
                    if ((previews.length < 4) && (fileTypes.indexOf(item.file_extension) > -1)) {
                        previews.push(item.id);
                        this.getPreviewImage(item.id, item.file_extension);
                    }
                });
                if (previews.length == 4) {
                    return null;
                }
                // Try other images
                fileTypes = ['tiff', 'gif', 'svg', 'psd', 'pdf'];
                this.assets.forEach((item, index) => {
                    if ((previews.length < 4) && (fileTypes.indexOf(item.file_extension) > -1)) {
                        previews.push(item.id);
                        this.getPreviewImage(item.id, item.file_extension);
                    }
                });
                if (previews.length == 4) {
                    return null;
                }
                // Try other types
                this.assets.forEach((item, index) => {
                    if ((previews.length < 4) && (previews.indexOf(item.id) < 0)) {
                        previews.push(item.id);
                        this.getPreviewImage(item.id, item.file_extension);
                    }
                });
                if (previews.length < 4) {
                    // Get previews from linked files
                    this.getLinkedFiles();
                }
            },
            getPreviewImage: function (assetId, extension) {
                /**
                 * Send request to API
                 */
                const size = (extension == 'svg') ? 'svg' : 'lowres';
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id
                    + '/resource/' + assetId + '/preview/' + size;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        if (!vm.image_src1) {
                            vm.image_src1 = response.data.image_src;
                            if (vm.image_src1.substr(0, 5) == 'data:') {
                                $('#preview-image1' + vm.id).addClass('image');
                            }

                            return null;
                        }
                        if (!vm.image_src2) {
                            vm.image_src2 = response.data.image_src;
                            if (vm.image_src2.substr(0, 5) == 'data:') {
                                $('#preview-image2' + vm.id).addClass('image');
                            }

                            return null;
                        }
                        if (!vm.image_src3) {
                            vm.image_src3 = response.data.image_src;
                            if (vm.image_src3.substr(0, 5) == 'data:') {
                                $('#preview-image3' + vm.id).addClass('image');
                            }

                            return null;
                        }
                        if (!vm.image_src4) {
                            vm.image_src4 = response.data.image_src;
                            if (vm.image_src4.substr(0, 5) == 'data:') {
                                $('#preview-image4' + vm.id).addClass('image');
                            }
                        }
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            getLinkedFiles: function () {
                if (this.data.linked_files.length == 0) {
                    return null;
                }
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/' + this.id
                    + '/linked-files/in::' + this.data.linked_files.join(',');
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.linked_files = response.data.linked_files.data;
                    vm.getLinkedFilePreviewImages();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                });
            },
            getLinkedFilePreviewImages: function () {
                let previews = [];
                if (this.image_src1) {
                    previews.push(0);
                }
                if (this.image_src2) {
                    previews.push(0);
                }
                if (this.image_src3) {
                    previews.push(0);
                }
                if (this.image_src4) {
                    previews.push(0);
                }
                if (previews.length == 4) {
                    return null;
                }
                // Try JPEG and PNG images first
                let fileTypes = ['jpg', 'jpeg', 'png'];
                this.linked_files.forEach((item) => {
                    if ((previews.length < 4) && (fileTypes.indexOf(item.file_extension) > -1)) {
                        previews.push(item.id);
                        this.getLinkedFilePreviewImage(item.id, item.file_extension);
                    }
                });
                if (previews.length == 4) {
                    return null;
                }
                // Try other images
                fileTypes = ['tiff', 'gif', 'svg', 'psd', 'pdf'];
                this.linked_files.forEach((item) => {
                    if ((previews.length < 4) && (fileTypes.indexOf(item.file_extension) > -1)) {
                        previews.push(item.id);
                        this.getLinkedFilePreviewImage(item.id, item.file_extension);
                    }
                });
                if (previews.length == 4) {
                    return null;
                }
                // Try other types
                this.linked_files.forEach((item) => {
                    if ((previews.length < 4) && (previews.indexOf(item.id) < 0)) {
                        previews.push(item.id);
                        this.getLinkedFilePreviewImage(item.id, item.file_extension);
                    }
                });
            },
            getLinkedFilePreviewImage: function (linkedFileId, extension) {
                /**
                 * Send request to API
                 */
                const size = (extension == 'svg') ? 'svg' : 'lowres';
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/' + this.id
                    + '/linked-file/' + linkedFileId + '/preview/' + size;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        if (!vm.image_src1) {
                            vm.image_src1 = response.data.image_src;
                            if (vm.image_src1.substr(0, 5) == 'data:') {
                                $('#preview-image1' + vm.id).addClass('image');
                            }

                            return null;
                        }
                        if (!vm.image_src2) {
                            vm.image_src2 = response.data.image_src;
                            if (vm.image_src2.substr(0, 5) == 'data:') {
                                $('#preview-image2' + vm.id).addClass('image');
                            }

                            return null;
                        }
                        if (!vm.image_src3) {
                            vm.image_src3 = response.data.image_src;
                            if (vm.image_src3.substr(0, 5) == 'data:') {
                                $('#preview-image3' + vm.id).addClass('image');
                            }

                            return null;
                        }
                        if (!vm.image_src4) {
                            vm.image_src4 = response.data.image_src;
                            if (vm.image_src4.substr(0, 5) == 'data:') {
                                $('#preview-image4' + vm.id).addClass('image');
                            }
                        }
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            fitTitle: function () {
                if (!$('#asset-title' + this.id)[0]) {
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 200);

                    return null;
                }
                if ($('#asset-title' + this.id)[0].scrollHeight > 30) {
                    let words = this.short_title.split(' ');
                    words.pop();
                    if (words.length > 0) {
                        this.short_title = words.join(' ') + '...';
                        var vm = this;
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 200);
                    }
                }
            },
            viewCollection: function () {
                this.$router.push({
                    name: 'view-collection',
                    params: {
                        id: this.id
                    }
                });
            },
            downloadCollection: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'collection-download');
                if (component) {
                    this.downloading = true;
                    component.initData(this, this.data);
                }
            },
            shareCollection: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'collection-share');
                if (component) {
                    component.reset(
                        { id: this.data.id, name: this.data.name },
                        this.data.resources,
                        this.data.linked_files
                    );
                }
            },
            deleteCollection: function (confirmed = false) {
                if (confirmed) {
                    this.$parent.$data.errors = false;
                    this.$parent.$data.loading = true;

                    /**
                     * Send delete collection request to API
                     */
                    const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id
                        + '/collection/' + this.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                    })
                    .then(response => {
                        if (vm.$root.current_collection == vm.id) {
                            vm.$root.current_collection = null;
                            vm.$root.selected_assets = [];
                        }
                        vm.$parent.getCollections();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        vm.$parent.$data.errors = "Error deleting collection, please try again";
                        vm.$parent.$data.loading = false;
                    });

                    return null;
                }

                let text = 'Are you sure you want to delete this collection?';
                if ((this.data.resources.length > 0) || (this.data.linked_files.length > 0)) {
                    text += '<br><br>';
                }
                if (this.data.resources.length > 0) {
                    let resources = this.$root.contents.asset_title;
                    if (this.data.resources.length > 1) {
                        resources += 's';
                    }
                    text += 'This will not delete the ' + resources + ' from the system. ';
                }
                if (this.data.linked_files.length > 0) {
                    const attachments = (this.data.linked_files.length > 1) ? 'attachments' : 'attachment';
                    text += 'The ' + attachments + ' will be permanently deleted.'
                }

                this.$parent.$parent.confirm_delete = {
                    title: this.title,
                    text: text,
                    component: this,
                    action: 'delete-collection'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deleteCollection(true);
            },
            selectCollection: function (select = true) {
                const component = this.$root.findComponent(this.$parent, 'asset-selection');
                if (component) {
                    component.$data.assets = [];
                }

                if (select) {
                    this.$root.current_collection = this.id;

                    return null;
                }
                this.$root.current_collection = null;
                this.$root.selected_assets = [];
            },
            itemsCount: function () {
                const itemsTotal = this.data.linked_files.length + this.data.resources.length;
                let text = itemsTotal + ' ' + this.$root.contents.asset_title;
                if (itemsTotal != 1) {
                    text += 's';
                }

                return text;
                /*let text = '';
                if (this.data.linked_files.length > 0) {
                    text = this.data.linked_files.length + ' attachment';
                    if (this.data.linked_files.length != 1) {
                        text += 's';
                    }
                }
                if (text != '') {
                    text += (this.collaborators.length > 1) ? '<br>+ ' : ' + ';
                }
                text += this.data.resources.length + ' ' + this.$root.contents.asset_title;
                if (this.data.resources.length != 1) {
                    text += 's';
                }

                return text;*/
            },
            canDownloadCollection: function () {
                if ((this.data.resources.length > 0) && !this.$root.hasPermission('assets_download')) {
                    return false;
                }

                return ((this.data.resources.length > 0) || (this.data.linked_files.length > 0));
            },
            canShareCollection: function () {
                if (!this.$root.hasPermission('assets_send')) {
                    return false;
                }

                return ((this.data.resources.length > 0) || (this.data.linked_files.length > 0));
            },
            resetCollaboratorAddForm: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'collaborator-add-form');
                if (component) {
                    component.resetForm(this.data.id, this.data.name);
                }
            },
            getCollaborators: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection-collaborators/'
                    + this.data.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.all_collaborators_count = response.data.collaborators.length;
                    vm.collaborators = [];
                    response.data.collaborators.forEach((item, index) => {
                        if (index < 6) {
                            item.index = index;
                            vm.collaborators.push(item);
                        }
                    });
                })
                .catch(function (error) {
                    // Do anything?
                });
            }
        }
    }
</script>
<style scoped>
    .asset-preview .thumbnail {
        /*height: 152px;*/
        height: 80px;
        overflow: hidden;
    }

    .asset-preview .thumbnail img {
        /*max-height: 150px;*/
        max-height: 78px;
    }

    .asset-preview .thumbnail img.image {
        max-width: none;
        /*max-height: 300px;*/
        max-height: 100px;
        top: 0px;
        left: 0px;
        bottom: auto;
        right: auto;
        margin: 0;
    }

    .asset-preview .card-body .title {
        height: 21px;
        line-height: 18px;
        font-weight: 500;
        cursor: pointer;
    }

    .avatar-xs.bordered {
        position: absolute;
        width: 34px;
        height: 34px;
        top: -5px;
        font-size: 12px;
        transition: top .35s ease;
    }

    .avatar-xs.bordered {
        border: 2px solid #fff;
    }

    .avatar-xs.bordered:hover {
        top: -7px !important;
        z-index: 2;
    }

    .avatar-xs img {
        width: 30px;
        height: 30px;
    }
</style>
