<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="keyword-category-add-form-modal-label">Add keyword category</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form v-if="$root.hasPermission('assets_keyword_assistant')" v-on:submit.prevent="onSubmit()">
                    <div class="form-group">
                        <label for="keyword-category-name" id="keyword-category-name-label" class="mandatory-label">
                            Name *
                        </label>
                        <input
                            v-model="keyword_category.name"
                            type="text"
                            id="keyword-category-name"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('keyword-category-name')"
                            v-on:change="$root.unHighlightErrors('keyword-category-name')"
                        >
                    </div>
                </form>
            </div>
        </div>
        <div v-if="($root.hasPermission('assets_keyword_assistant') && !updated)" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-check mr-1"></i> Add
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Add</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                keyword_category: {}
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function () {
                if (!this.$root.hasPermission('assets_keyword_assistant')) {
                    this.errors = 'Sorry, you do not have access to this function.';

                    return false;
                }
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.keyword_category = {};
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                setTimeout(function () {
                    $('#keyword-category-name').focus();
                }, 500);
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const data = this.keyword_category;
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/keyword-category';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.updated = 'Success';
                    if (response.data.message) {
                        vm.updated = response.data.message;
                        const component = vm.$root.findComponent(vm.$parent.$parent, 'keyword-categories');
                        if (component) {
                            component.getKeywordSuggestions();
                        }
                    }

                    setTimeout(function () {
                        $('#keyword-category-add-form-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#keyword-category-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    $('#keyword-category-' + key + '-label').addClass('text-danger');
                                    $('#keyword-category-' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            }
        }
    }
</script>
