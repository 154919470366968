<template>
    <div>
        <div
            v-if="!$root.isLoggedIn() && (initial_loading || errors || is_email_required)"
            class="account-pages mx-md-5 my-5 pt-sm-5"
        >
            <div class="container-fluid">
                <div :class="$root.loginPanelClass()">
                    <div class="col-sm-6 col-lg-4 col-xl-3 login-panel-col">
                        <div class="card overflow-hidden">
                            <div :class="$root.app.login_panel_header_class + ' px-4 py-2 text-center'">
                                <img
                                    v-if="$root.app.login_logo"
                                    :src="$root.app.login_logo"
                                    :alt="$root.client.client_name"
                                    class="img-fluid"
                                >
                            </div>
                            <div class="card-body pt-2 pb-1">
                                <div class="p-2">
                                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                                    <div v-if="initial_loading"><loading></loading></div>

                                    <div
                                        v-if="(!errors && !initial_loading && !is_email_required && (items.length == 0))"
                                        class="alert alert-warning"
                                        role="alert"
                                    >
                                        Sorry, the shared items are no longer available
                                    </div>

                                    <div v-if="!initial_loading && is_email_required">
                                        <form v-on:submit.prevent="getShare(true)">
                                            <div class="form-group">
                                                <label for="share-email" id="share-email-label" class="mandatory-label">
                                                    To view the file(s) {{ shared_by_name }}
                                                    has sent enter your email below
                                                </label>
                                                <input
                                                    v-model="email"
                                                    type="email"
                                                    id="share-email"
                                                    class="form-control mandatory-field"
                                                    v-on:keyup="$root.unHighlightErrors('share-email')"
                                                    v-on:change="$root.unHighlightErrors('share-email')"
                                                >
                                            </div>
                                            <div class="form-group">
                                                <button type="submit" class="btn btn-primary btn-block">
                                                    <i class="far fa-paper-plane mr-1"></i> Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                    <div class="text-center" style="font-size: 0.7em; position: relative; height: 10px">
                                        Powered by <a href="https://resourcebase.com" target="_blank">Resourcebase</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="assets-container">
                <div class="assets-grid">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div
                        v-if="(!errors && !loading && !is_email_required && (items.length == 0))"
                        class="alert alert-warning"
                        role="alert"
                    >
                        Sorry, the shared {{ $root.contents.asset_title }}s are no longer available
                    </div>

                    <div v-if="(items.length > 0)">
                        <div v-if="$root.is_4xl_screen" class="d-none d-xl-block">
                            <div v-for="group in grouped_items_4xl" :key="group.id" class="row">
                                <div v-for="item in group.items" :key="item.id" class="col" style="width: 14.2857%">
                                    <collection-linked-file-preview
                                        v-if="((item.id < 0) && ('title' in item))"
                                        :data="item"
                                        :in_share="share.id"
                                        :email="($route.params.email || email)"
                                    ></collection-linked-file-preview>
                                    <asset-preview
                                        v-if="((item.id > 0) && !is_flyte && !is_artwork && !is_proof)"
                                        :data="item"
                                        :in_share="share.id"
                                        :email="($route.params.email || email)"
                                        :is_visible="true"
                                    ></asset-preview>
                                    <flyte-file-preview
                                        v-if="(item.original_id && is_flyte)"
                                        :data="item"
                                        :in_share="share.id"
                                        :email="($route.params.email || email)"
                                    ></flyte-file-preview>
                                    <shared-artwork-preview
                                        v-if="(item.original_id && is_artwork)"
                                        :data="item"
                                        :in_share="share.id"
                                        :email="($route.params.email || email)"
                                    ></shared-artwork-preview>
                                    <shared-proof-preview
                                        v-if="(item.original_id && is_proof)"
                                        :data="item"
                                        :in_share="share.id"
                                        :email="($route.params.email || email)"
                                    ></shared-proof-preview>
                                </div>
                            </div>
                        </div>

                        <div v-if="($root.is_xl_screen && !$root.is_3xl_screen)" class="d-none d-xl-block">
                            <div v-for="group in grouped_items" :key="group.id" class="row">
                                <div v-for="item in group.items" :key="item.id" class="col" style="width: 20%">
                                    <collection-linked-file-preview
                                        v-if="((item.id < 0) && ('title' in item))"
                                        :data="item"
                                        :in_share="share.id"
                                        :email="($route.params.email || email)"
                                    ></collection-linked-file-preview>
                                    <asset-preview
                                        v-if="((item.id > 0) && !is_flyte && !is_artwork && !is_proof)"
                                        :data="item"
                                        :in_share="share.id"
                                        :email="($route.params.email || email)"
                                        :is_visible="true"
                                    ></asset-preview>
                                    <flyte-file-preview
                                        v-if="(item.original_id && is_flyte)"
                                        :data="item"
                                        :in_share="share.id"
                                        :email="($route.params.email || email)"
                                    ></flyte-file-preview>
                                    <shared-artwork-preview
                                        v-if="(item.original_id && is_artwork)"
                                        :data="item"
                                        :in_share="share.id"
                                        :email="($route.params.email || email)"
                                    ></shared-artwork-preview>
                                    <shared-proof-preview
                                        v-if="(item.original_id && is_proof)"
                                        :data="item"
                                        :in_share="share.id"
                                        :email="($route.params.email || email)"
                                    ></shared-proof-preview>
                                </div>
                            </div>
                        </div>

                        <div v-if="(!$root.is_xl_screen || ($root.is_3xl_screen && !$root.is_4xl_screen))" class="row">
                            <div
                                v-for="item in items"
                                :key="item.id"
                                :class="($root.is_xxl_screen ? 'col-xl-2 ' : '') + 'col-lg-3 col-md-4 col-sm-6'"
                            >
                                <collection-linked-file-preview
                                    v-if="((item.id < 0) && ('title' in item))"
                                    :data="item"
                                    :in_share="share.id"
                                    :email="($route.params.email || email)"
                                ></collection-linked-file-preview>
                                <asset-preview
                                    v-if="((item.id > 0) && !is_flyte && !is_artwork && !is_proof)"
                                    :data="item"
                                    :in_share="share.id"
                                    :email="($route.params.email || email)"
                                    :is_visible="true"
                                ></asset-preview>
                                <flyte-file-preview
                                    v-if="(item.original_id && is_flyte)"
                                    :data="item"
                                    :in_share="share.id"
                                    :email="($route.params.email || email)"
                                ></flyte-file-preview>
                                <shared-artwork-preview
                                    v-if="(item.original_id && is_artwork)"
                                    :data="item"
                                    :in_share="share.id"
                                    :email="($route.params.email || email)"
                                ></shared-artwork-preview>
                                <shared-proof-preview
                                    v-if="(item.original_id && is_proof)"
                                    :data="item"
                                    :in_share="share.id"
                                    :email="($route.params.email || email)"
                                ></shared-proof-preview>
                            </div>
                        </div>
                    </div>
                </div>

                <collection-linked-file-details
                    v-if="share"
                    :in_share="share.id"
                    :email="($route.params.email || email)"
                ></collection-linked-file-details>
                <asset-details v-if="share" :in_share="share.id" :email="($route.params.email || email)"></asset-details>
            </div>

            <div style="position: relative; top: -20px; height: 40px; overflow: visible">
                <loading v-if="loading"></loading>
            </div>

            <div
                v-if="is_flyte"
                class="modal fade"
                id="flyte-download-modal"
                data-backdrop="static"
                data-keyboard="false"
                tabindex="-1"
                aria-labelledby="flyte-download-modal-label"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-scrollable">
                    <flyte-download></flyte-download>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'shared',
                initial_loading: true,
                loading: false,
                errors: false,
                share: null,
                grouped_items: [],
                grouped_items_4xl: [],
                items: [],
                view_asset: false,
                view_asset_tab: this.$root.client.asset_details_default_tab,
                view_asset_from_selection: false,
                container_height: 0,
                scroll_top: 0,
                is_email_required: false,
                email: null,
                is_flyte: false,
                is_artwork: false,
                is_proof: false,
                shared_by_name: ''
            }
        },
        mounted () {
            this.$root.share_info = {};
            this.getShare(true);
            if (this.$root.isLoggedIn()) {
                $('body').removeClass('login-body');

                return null;
            }
            $('body').addClass('login-body');
        },
        watch: {
            view_asset: function (val, oldVal) {
                if (val) {
                    if (oldVal) {
                        if ((val.id < 0) && (oldVal.id > 0) || (val.id > 0) && (oldVal.id < 0)) {
                            (oldVal.id < 0) ? this.hideLinkedFileDetails() : this.hideAssetDetails();
                            var vm = this;
                            setTimeout(function () {
                                (vm.view_asset.id < 0) ? vm.showLinkedFileDetails(vm.view_asset)
                                    : vm.showAssetDetails(vm.view_asset);
                            }, 800);

                            return null;
                        }
                        (val.id < 0) ? this.showLinkedFileDetails(val, false) : this.showAssetDetails(val, false);

                        return null;
                    }
                    (val.id < 0) ? this.showLinkedFileDetails(val) : this.showAssetDetails(val);

                    return null;
                }
                if (!oldVal) {
                    return null;
                }
                (oldVal.id < 0) ? this.hideLinkedFileDetails() : this.hideAssetDetails();
            }
        },
        methods: {
            showLinkedFileDetails: function (linkedFile, fade = true) {
                var vm = this;
                const component = this.$root.findComponent(this, 'collection-linked-file-details');
                component.setLinkedFile(linkedFile);
                if (!fade) {
                    return null;
                }
                this.scroll_top = $('html, body').scrollTop();
                this.container_height = $('.assets-container').outerHeight();
                $('.assets-container').css('min-height', this.container_height);
                $('.assets-grid').fadeOut(350, function () {
                    $('.page-topnav').hide();
                    $('.status-bar').hide();
                    let headerBarHeight = $('.header-bar').outerHeight();
                    if ($('.maintenance-notice').is(":visible")) {
                        headerBarHeight += $('.maintenance-notice').outerHeight() || 0;
                    }
                    const mainNavHeight = $('.main-nav-container').outerHeight();
                    if (vm.scroll_top > headerBarHeight) {
                        $('html, body').scrollTop(headerBarHeight + 1);
                        $('.page-content').css('margin-top', mainNavHeight);
                    } else {
                        $('html, body').scrollTop(0);
                    }
                    $('.linked-file-details').fadeIn(350, function () {
                        $('.assets-container').css('min-height', 'auto');
                    });
                });
            },
            hideLinkedFileDetails: function () {
                var vm = this;
                $('.linked-file-details').fadeOut(350, function () {
                    $('.assets-container').css('min-height', vm.container_height);
                    $('html, body').scrollTop(vm.scroll_top);
                    $('.page-topnav').show();
                    $('.status-bar').show();
                    $('.assets-grid').fadeIn(350, function () {
                        const component = vm.$root.findComponent(vm, 'collection-linked-file-details');
                        component.setLinkedFile(null);
                        $('.assets-container').css('min-height', 'auto');
                    });
                });
            },
            showAssetDetails: function (asset, fade = true) {
                var vm = this;
                const component = this.$root.findComponent(this, 'asset-details');
                component.setAsset(asset, this.view_asset_tab);
                if (!fade) {
                    return null;
                }
                this.scroll_top = $('html, body').scrollTop();
                this.container_height = $('.assets-container').outerHeight();
                $('.assets-container').css('min-height', this.container_height);
                $('.assets-grid').fadeOut(350, function () {
                    $('.page-topnav').hide();
                    $('.status-bar').hide();
                    let headerBarHeight = $('.header-bar').outerHeight();
                    if ($('.maintenance-notice').is(":visible")) {
                        headerBarHeight += $('.maintenance-notice').outerHeight() || 0;
                    }
                    const mainNavHeight = $('.main-nav-container').outerHeight();
                    if (vm.scroll_top > headerBarHeight) {
                        $('html, body').scrollTop(headerBarHeight + 1);
                        $('.page-content').css('margin-top', mainNavHeight);
                    } else {
                        $('html, body').scrollTop(0);
                    }
                    $('.asset-details').fadeIn(350, function () {
                        $('.assets-container').css('min-height', 'auto');
                    });
                });
            },
            hideAssetDetails: function () {
                var vm = this;
                $('.asset-details').fadeOut(350, function () {
                    $('.assets-container').css('min-height', vm.container_height);
                    $('html, body').scrollTop(vm.scroll_top);
                    $('.page-topnav').show();
                    $('.status-bar').show();
                    $('.assets-grid').fadeIn(350, function () {
                        const component = vm.$root.findComponent(vm, 'asset-details');
                        component.setAsset(null);
                        $('.assets-container').css('min-height', 'auto');
                    });
                });
            },
            getShare: function (initial) {
                if (initial) {
                    this.initial_loading = true;
                }
                this.loading = true;
                this.errors = false;
                this.items = [];
                this.is_email_required = false;
                const email = (this.$route.params.email || this.email);

                /**
                 * Send request to API
                 */
                let url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/share';
                if (this.$root.user) {
                    url += '-auth'
                }
                url += '/' + this.$route.params.code;
                if (email) {
                    url += '/' + email;
                }
                var vm = this;

                axios({
                    url: url,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    // Mark notification as read
                    setTimeout(function () {
                        const appComponent = vm.$root.findComponent(vm.$root, 'main-app');
                        if (appComponent) {
                            const component = vm.$root.findComponent(appComponent, 'main-nav');
                            if (component) {
                                component.markNotificationAsRead('user', null, 'share', response.data.share.id);
                            }
                        }
                    }, 1200);

                    if (response.data.share.filters.length > 0) {
                        vm.$root.asset_filters = [];
                        let hasFolderFilter = false;
                        response.data.share.filters.forEach((item) => {
                            let filter = {
                                key: item.key,
                                value: item.value,
                                name: item.name || null,
                                text: item.text,
                                filters_key: 'asset_filters'
                            };
                            if (item.key == 'folder') {
                                filter.subfolders = [];
                                vm.getFolder(item.value);
                                hasFolderFilter = true;
                            }
                            vm.$root.addFilter('asset_filters', filter);
                        });
                        if (!hasFolderFilter) { 
                            vm.$router.push({ name: 'assets' });
                        }

                        return null;
                    }
                    vm.share = response.data.share;

                    let shareInfo = {
                        shared_by: (vm.share.shared_by || null),
                        expires_at: vm.share.expires_at,
                        flyte_downloading: false,
                        number_of_items: 0,
                        items_title: 'items',
                        is_flyte: false,
                        is_artwork: false,
                        is_proof: false,
                        collection_id: vm.share.collection_id,
                        page_loaded: true
                    };

                    let numberOfItems = 0;
                    const assetsOnly = (
                        ((vm.share.flyte_files.length + vm.share.linked_files.length) == 0)
                        && !vm.share.artwork
                        && !vm.share.proof
                    );
                    if (vm.share.flyte_files.length > 0) {
                        vm.is_flyte = true;
                        shareInfo.is_flyte = true;
                        numberOfItems += vm.share.flyte_files.length;
                    }
                    if (vm.share.artwork) {
                        vm.is_artwork = true;
                        shareInfo.is_artwork = true;
                        numberOfItems = 1;
                        vm.share.artwork.original_id = parseInt(vm.share.artwork.id);
                        vm.share.artwork.id = 'artwork_' + vm.share.artwork.original_id;
                        vm.items.push(vm.share.artwork);
                    }
                    if (vm.share.proof) {
                        vm.is_proof = true;
                        shareInfo.is_proof = true;
                        numberOfItems = 1;
                        vm.share.proof.original_id = parseInt(vm.share.proof.id);
                        vm.share.proof.id = 'proof_' + vm.share.proof.original_id;
                        vm.items.push(vm.share.proof);
                    }
                    if (vm.share.collection_id) {
                        numberOfItems += vm.share.linked_files.length;
                        vm.share.linked_files.forEach((item) => {
                            item.id = (-1 * item.id);
                            vm.items.push(item);
                        });
                    }
                    vm.share.flyte_files.forEach((item) => {
                        item.original_id = parseInt(item.id);
                        item.id = 'flyte_' + item.original_id;
                        vm.items.push(item);
                    });
                    numberOfItems += vm.share.resources.length;
                    vm.share.resources.forEach((item) => {
                        vm.items.push(item);
                    });
                    shareInfo.number_of_items = numberOfItems;
                    shareInfo.items_title = assetsOnly ? vm.$root.contents.asset_title : 'file';
                    if (numberOfItems != 1) {
                        shareInfo.items_title += 's';
                    }
                    shareInfo.external_view_only = vm.share.external_view_only;
                    vm.$root.share_info = shareInfo;
                    $('body').removeClass('login-body');

                    vm.groupAssets();
                    vm.loading = false;
                    vm.initial_loading = false;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        vm.$root.requested_route = vm.$route;
                        const email = (vm.$route.params.email || vm.email);
                        if (email) {
                            vm.$root.login_email = email;
                        }
                        vm.$router.push({ name: 'login' });

                        return null;
                    }
                    vm.loading = false;
                    vm.initial_loading = false;
                    if (error.response.status == 406) {
                        vm.is_email_required = true;
                        vm.shared_by_name = error.response.data.shared_by_name || '';
                        setTimeout(function () {
                            $('#share-email').focus();
                        }, 200);

                        return null;
                    }
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
                /*.finally(() => {
                    vm.loading = false;
                    vm.initial_loading = false;
                });*/
            },
            getAssets: function () {
                this.view_asset = false;
                this.getShare();
            },
            groupAssets: function () {
                let group_items = [];
                this.grouped_items = [];
                this.items.forEach((item, index) => {
                    group_items.push(item);
                    if (group_items.length == 5) {
                        this.grouped_items.push({ id: 'g' + index, items: group_items });
                        group_items = [];
                    }
                });
                if (group_items.length > 0) {
                    for (let n = group_items.length; n < 5; n++) {
                        group_items.push({ id: (0 - n)});
                    }
                    this.grouped_items.push({ id: 'last', items: group_items });
                }

                // 4xl
                group_items = [];
                this.grouped_items_4xl = [];
                this.items.forEach((item, index) => {
                    group_items.push(item);
                    if (group_items.length == 7) {
                        this.grouped_items_4xl.push({ id: 'g' + index, items: group_items });
                        group_items = [];
                    }
                });
                if (group_items.length > 0) {
                    for (let n = group_items.length; n < 7; n++) {
                        group_items.push({ id: (0 - n)});
                    }
                    this.grouped_items_4xl.push({ id: 'last', items: group_items });
                }
            },
            downloadFlyte: function () {
                if (this.share.flyte_files.length == 0) {
                    return null;
                }
                const component = this.$root.findComponent(this, 'flyte-download');
                if (component) {
                    this.$root.share_info.flyte_downloading = true;
                    const email = (this.$route.params.email || this.email);
                    component.initData(
                        this.share.flyte_files,
                        (!this.$root.isLoggedIn() ? this.share.id : null),
                        ((!this.$root.isLoggedIn() && email) ? email : null)
                    );
                }
            },
            getFolder: function (folderId) {
                /**
                 * Send get folder request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/' + folderId;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.$root.asset_filters.forEach((item) => {
                        if ((item.key == 'folder') && (item.value == response.data.folder.id)) {
                            item.subfolders = response.data.folder.subfolders;
                        }
                    });
                    vm.$router.push({ name: 'assets' });
                })
                .catch(function (error) {
                    vm.loading = false;
                    vm.initial_loading = false;
                });
            }
        }
    }
</script>
