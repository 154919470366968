<template>
    <div>
        <div v-if="$root.hasPermission('assets_custom_fields')" class="row">
            <div class="col-md-3 mb-2">
                <!--<div class="card">
                    <h5 class="card-header">Filter list by {{ $root.contents.folder_title }}</h5>
                    <div class="card-body">
                        <searchable-option-list
                            v-model="current_folder"
                            :value="current_folder"
                            :options="getFolderOptions()"
                            field_id="current-folder"
                            :readonly="updating"
                        ></searchable-option-list>
                    </div>
                </div>-->

                <div class="card">
                    <h5 class="card-header">Field types</h5>
                    <draggable
                        class="field-types list-group"
                        ghost-class="vue-drag-ghost"
                        drag-class="drag-item"
                        v-model="field_types"
                        :group="{ name: 'field-types', pull: 'clone', put: false }"
                        :sort="false"
                        animation="500"
                    >
                        <div class="list-group-item" v-for="item in field_types" :key="item.id">
                            {{ item.name }}
                            <div class="font-size-20 contact-links" style="position: absolute; right: 20px; top: 8px">
                                <i class="fal fa-arrows-alt"></i>
                            </div>
                        </div>
                    </draggable>
                </div>
            </div>

            <div class="col" style="margin-bottom: 180px">
                <div class="card">
                    <h5 class="card-header">Custom fields added</h5>
                    <div class="pt-0 pb-0">
                        <div v-if="errors" v-html="errors" class="alert alert-danger mt-3 ml-3 mr-3" role="alert"></div>

                        <div v-if="loading" class="mt-2"><loading></loading></div>

                        <div v-if="!loading" :class="containerClass()">
                            <div v-if="(customFieldsCount() == 0)" class="drag-and-drop">
                                Drag &amp; drop field types here
                            </div>
                            <draggable
                                :class="customFieldsClass()"
                                ghost-class="vue-drag-ghost"
                                v-model="custom_fields"
                                group="field-types"
                                handle=".drag-handle"
                                animation="200"
                                @add="onAddField"
                                @end="onMoveField"
                            >
                                <div v-for="item in custom_fields" :key="item.id">
                                    <div
                                        v-if="hasCurrentFolder(item)"
                                        class="list-group-item"
                                    >
                                        <div class="row">
                                            <div v-if="(item.id > 0)" class="col-sm-1 font-size-18 contact-links">
                                                <a
                                                    href="#"
                                                    class="drag-handle"
                                                    style="cursor: move"
                                                    v-b-tooltip.hover
                                                    title="Re-order"
                                                    @click.prevent="doNothing()"
                                                >
                                                    <i class="fas fa-arrow-down-arrow-up"></i>
                                                </a>
                                                <span v-if="!item.edit_mode" class="d-sm-none float-right">
                                                    <a
                                                        href="#"
                                                        v-b-tooltip.hover.left
                                                        title="Edit"
                                                        @click.prevent="editCustomField(item)"
                                                    >
                                                        <i class="far fa-edit"></i>
                                                    </a>
                                                </span>
                                                <span v-else class="d-sm-none float-right">
                                                    <span>
                                                        <a
                                                            href="#"
                                                            v-b-tooltip.hover.left
                                                            title="Cancel"
                                                            @click.prevent="editCustomField(item, false)"
                                                        >
                                                            <i class="far fa-times"></i>
                                                        </a>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="col-sm-10">
                                                <div v-if="(item.id > 0)">
                                                    <custom-field-edit-form
                                                        v-if="item.edit_mode"
                                                        :data="item"
                                                        :field_types="field_types"
                                                        :update="update_item"
                                                        :cancel="cancel_item"
                                                        :folders="folders"
                                                    >
                                                    </custom-field-edit-form>

                                                    <div v-else>
                                                        <label>
                                                            {{ item.name }} <span v-if="item.required">*</span>
                                                            <span
                                                                class="small text-muted ml-2"
                                                                style="text-transform: lowercase"
                                                            >
                                                                ({{ getFieldTypeName(item.type) }})
                                                            </span>
                                                        </label>
                                                        <span v-if="(item.type == 'text')">
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                :placeholder="item.help_text"
                                                                readonly
                                                            >
                                                        </span>
                                                        <span v-if="(item.type == 'textarea')">
                                                            <textarea
                                                                class="form-control"
                                                                :placeholder="item.help_text"
                                                                readonly
                                                            ></textarea>
                                                        </span>
                                                        <span v-if="(item.type == 'multiselect')">
                                                            <searchable-option-list
                                                                v-model="item.value"
                                                                :value="[]"
                                                                :multiple="true"
                                                                :options="getOptions(item)"
                                                                :field_id="'multiselect' + item.id"
                                                                :search_placeholder="(item.help_text || null)"
                                                            ></searchable-option-list>
                                                        </span>
                                                        <span v-if="(item.type == 'singleselect')">
                                                            <searchable-option-list
                                                                v-model="item.value"
                                                                :options="getOptions(item)"
                                                                :field_id="'singleselect' + item.id"
                                                                :search_placeholder="(item.help_text || null)"
                                                            ></searchable-option-list>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <loading align="left" size="2"></loading>
                                                </div>
                                            </div>
                                            <div
                                                v-if="(item.id > 0)"
                                                class="col-sm-1 d-none d-sm-block font-size-18 contact-links"
                                            >
                                                <div v-if="!item.edit_mode">
                                                    <div class="float-right text-center" style="width: 21px">
                                                        <a
                                                            href="#"
                                                            v-b-tooltip.hover.left
                                                            title="Edit"
                                                            @click.prevent="editCustomField(item)"
                                                        >
                                                            <i class="far fa-edit"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div>
                                                        <div class="float-right text-center" style="width: 21px">
                                                            <a
                                                                href="#"
                                                                v-b-tooltip.hover.left
                                                                title="Cancel"
                                                                @click.prevent="editCustomField(item, false)"
                                                            >
                                                                <i class="far fa-times"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="alert alert-danger" role="alert">
            Sorry, you do not have access to this function.
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    export default {
        components: { draggable },
        data() {
            return {
                loading: false,
                errors: false,
                field_types: [
                    {
                        id: 'text',
                        name: 'Text'
                    },
                    {
                        id: 'textarea',
                        name: 'Text area'
                    },
                    {
                        id: 'multiselect',
                        name: 'Drop down multi select'
                    },
                    {
                        id: 'singleselect',
                        name: 'Drop down single select'
                    }
                ],
                custom_fields: [],
                update_item: 0,
                updating: false,
                cancel_item: 0,
                folders: [],
                current_folder: null
            };
        },
        mounted () {
            $('body').removeClass('login-body');
            this.loading = true;
            //this.getCustomFields();
            this.getFolders();
        },
        watch: {
            update_item: function (val) {
                this.updating = (val > 0);
            }
        },
        methods: {
            getCustomFields: function (editField = null) {
                this.errors = false;

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/custom-fields';
                var vm = this;
                var autoEditField = editField;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.custom_fields = response.data.custom_fields;
                    if (autoEditField) {
                        vm.custom_fields.forEach((item, index) => {
                            if (item.id == autoEditField) {
                                item.edit_mode = true;
                            }
                        });
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            onAddField: function (event) {
                this.errors = false;

                const type = this.custom_fields[event.newIndex].id;
                const name = this.custom_fields[event.newIndex].name;

                let area = 'B';
                if (this.$root.isFeatured('assets') && !this.$root.isFeatured('templates')) {
                    area = 'A';
                } else if (!this.$root.isFeatured('assets') && this.$root.isFeatured('templates')) {
                    area = 'T';
                }

                this.custom_fields[event.newIndex] = {
                    id: 0,
                    name: name,
                    type: type,
                    sort_order: event.newIndex,
                    area: area
                };
                if (this.current_folder > 0) {
                    this.custom_fields[event.newIndex].folders = [this.current_folder];
                }
                
                /**
                 * Send create custom field request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/custom-field';
                const data = this.custom_fields[event.newIndex];
                var vm = this;
                var itemIndex = event.newIndex;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Update item id
                    vm.custom_fields[itemIndex].id = response.data.custom_field_id;
                    vm.custom_fields[itemIndex].edit_mode = true;
                    vm.getCustomFields(response.data.custom_field_id);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    // show error
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = 'Something went wrong, please try again';
                    }
                    // remove item
                    vm.custom_fields.splice(itemIndex, 1);
                })
                .finally(() => {
                    // update draggable
                    vm.custom_fields.push({ id: -1 });
                    vm.custom_fields.pop();
                });
            },
            onMoveField: function(event) {
                if (event.newIndex == event.oldIndex) {
                    return false;
                }
                this.errors = false;
                /**
                 * Send move custom field request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/custom-field/'
                    + this.custom_fields[event.newIndex].id + '/move';
                const data = {
                    sort_order: event.newIndex
                };
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Do anything?
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            containerClass: function () {
                if (this.customFieldsCount() == 0) {
                    return 'drop-container';
                }

                return '';
            },
            customFieldsClass: function () {
                if (this.customFieldsCount() == 0) {
                    return 'custom-fields list-group mt-3 mb-3 min-height';
                }

                return 'custom-fields list-group';
            },
            editCustomField: function (item, editMode = true) {
                if (this.updating) {
                    return null;
                }
                if (editMode) {
                    item.edit_mode = true;
                    this.custom_fields.push({ id: -1 });
                    this.custom_fields.pop();

                    return null;
                }
                this.cancel_item = item.id;
            },
            updateCustomField: function (item) {
                if (this.updating) {
                    return null;
                }
                this.update_item = item.id;
            },
            deleteCustomField: function (item, confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.loading = true;

                    /**
                     * Send delete custom field request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/custom-field/'
                        + item;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.getCustomFields();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    });

                    return null;
                }

                if (this.updating) {
                    return null;
                }

                this.$parent.confirm_delete = {
                    title: 'Delete custom field',
                    text: 'Are you sure you want to delete: ' + item.name + '?'
                        + '<br><br>(This will delete any content in this field throughout the system)',
                    component: this,
                    action: 'delete-' + item.id
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                const itemId = parseInt(action.substr(7));
                this.deleteCustomField(itemId, true);
            },
            getFolders: function() {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folders/!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folders = response.data.folders;
                    vm.getCustomFields();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = 'Something went wrong, please try again';
                    }
                });
            },
            getOptions: function (item) {
                const itemOptions = (item.options || []);
                let options = [];
                itemOptions.forEach((option, index) => {
                    options.push({
                        id: index,
                        name: option.text,
                        disabled: true
                    });
                });

                return options;
            },
            getFieldTypeName: function (typeId) {
                let fieldTypeName = null;
                this.field_types.forEach((item) => {
                    if (item.id == typeId) {
                        fieldTypeName = item.name;
                    }
                });

                return fieldTypeName;
            },
            doNothing: function () {
                return null;
            },
            getFolderOptions: function () {
                /*let options = [{
                    id: -1,
                    name: 'All ' + this.$root.contents.folder_title
                }];*/
                let options = [];
                if (this.folders) {
                    this.folders.forEach((item) => {
                        let name = item.name;
                        if (item.archived) {
                            name += ' (archived)';
                        }
                        options.push({
                            id: item.id,
                            name: name
                        });
                    });
                }

                return options;
            },
            customFieldsCount: function () {
                if (this.custom_fields.length == 0) {
                    return 0;
                }
                if (this.current_folder === null) {
                    return this.custom_fields.length;
                }
                let count = 0;
                this.custom_fields.forEach((item) => {
                    if (this.hasCurrentFolder(item)) {
                        count++;
                    }
                });

                return count;
            },
            hasCurrentFolder: function (item) {
                if (/*item.edit_mode || */(this.current_folder === null)) {
                    return true;
                }
                if (!item.folders) {
                    /*if (this.current_folder > 0) {
                        return false;
                    } else {*/
                        return true;
                    //}
                }
                if (item.folders.length == 0) {
                    /*if (this.current_folder > 0) {
                        return false;
                    } else {*/
                        return true;
                    //}
                }

                return (item.folders.indexOf(this.current_folder) > -1);
            }
        }
    }
</script>
<style scoped>
    .field-types div {
        cursor: move;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .field-types div:hover {
        background-color: #eff2f7;
    }

    .drag-item {
        border: 1px solid #999;
    }

    .drop-container {
        position: relative;
        border-left: 1px solid #eff2f7;
        border-right: 1px solid #eff2f7;
        border-bottom: 1px solid #eff2f7;
        border-bottom-left-radius: .25;
        border-bottom-right-radius: .25;
    }

    .min-height {
        min-height: 44px;
    }

    .drag-and-drop {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 190px;
        height: 20px;
    }

    .custom-fields .list-group-item {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .custom-fields .list-group-item:hover {
        background-color: #eff2f7;
    }
</style>
