<template>
    <div>
        <div
            :class="'home-container'
                + (($root.current_collection || ($root.selected_assets.length > 0)) ? ' padding' : '')"
        >
            <div class="home-body">
                <loading v-if="loading"></loading>

                <div v-if="(!loading && (items.length > 0))">
                    <div v-if="$root.editing_homepage">
                        <draggable
                            v-model="items"
                            ghost-class="vue-drag-ghost"
                            handle=".drag-handle"
                            tag="div"
                            class="row"
                            @end="onDragSortEnd"
                            animation="200"
                            draggable=".home-panel-container"
                        >
                            <div
                                v-for="item in items"
                                :key="item.id"
                                :class="panelColClass(item.id, $root.is_xxl_screen)"
                            >
                                <welcome-panel v-if="(item.id == 'welcome-panel')"></welcome-panel>
                                <home-panel
                                    v-if="((item.id > 0) || (item.id == 'add-panel'))"
                                    :data="item"
                                    :folders="folders"
                                ></home-panel>
                                <flyte-panel v-if="(item.id == 'flyte-panel')"></flyte-panel>
                            </div>
                        </draggable>
                    </div>
                    <div v-else>
                        <div v-if="$root.is_4xl_screen" class="d-none d-xl-block">
                            <div v-for="group in grouped_items_4xl" :key="group.id" class="row">
                                <div v-for="item in group.items" :key="item.id" class="col px-2">
                                    <welcome-panel v-if="(item.id == 'welcome-panel')"></welcome-panel>
                                    <home-panel
                                        v-if="((item.id > 0) || (item.id == 'add-panel'))"
                                        :data="item"
                                        :folders="folders"
                                    ></home-panel>
                                    <flyte-panel v-if="(item.id == 'flyte-panel')"></flyte-panel>
                                </div>
                            </div>
                        </div>

                        <div v-if="($root.is_xl_screen && !$root.is_3xl_screen)" class="d-none d-xl-block">
                            <div v-for="group in grouped_items" :key="group.id" class="row">
                                <div v-for="item in group.items" :key="item.id" class="col px-2">
                                    <welcome-panel v-if="(item.id == 'welcome-panel')"></welcome-panel>
                                    <home-panel
                                        v-if="((item.id > 0) || (item.id == 'add-panel'))"
                                        :data="item"
                                        :folders="folders"
                                    ></home-panel>
                                    <flyte-panel v-if="(item.id == 'flyte-panel')"></flyte-panel>
                                </div>
                            </div>
                        </div>

                        <div v-if="(!$root.is_xl_screen || ($root.is_3xl_screen && !$root.is_4xl_screen))" class="row">
                            <div
                                v-for="item in items"
                                :key="item.id"
                                :class="($root.is_xxl_screen ? 'col-xl-2 ' : '') + 'col-lg-3 col-md-4 col-sm-6 px-2'"
                            >
                                <welcome-panel v-if="(item.id == 'welcome-panel')"></welcome-panel>
                                <home-panel
                                    v-if="((item.id > 0) || (item.id == 'add-panel'))"
                                    :data="item"
                                    :folders="folders"
                                ></home-panel>
                                <flyte-panel v-if="(item.id == 'flyte-panel')"></flyte-panel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <collection-linked-file-details></collection-linked-file-details>
            <asset-details></asset-details>
        </div>

        <div
            v-if="($root.hasPermission('homepage_edit') && $root.editing_homepage)"
            class="modal fade"
            id="home-panel-edit-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="home-panel-edit-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <home-panel-edit-form></home-panel-edit-form>
            </div>
        </div>

        <asset-selection
            v-if="(($root.hasPermission('assets_access') || $root.hasPermission('templates_view')) && !$root.editing_artwork)"
            :selected="$root.selected_assets"
            :collections="collections"
            :collection="$root.current_collection"
        ></asset-selection>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    export default {
        components: {
            draggable,
        },
        data () {
            return {
                component_name: 'home',
                view_asset: false,
                view_asset_tab: this.$root.client.asset_details_default_tab,
                view_asset_from_selection: false,
                container_height: 0,
                scroll_top: 0,
                collections: [],
                loading: true,
                items: [],
                grouped_items: [],
                grouped_items_4xl: [],
                folders: []
            }
        },
        mounted () {
            $('body').removeClass('login-body');
            this.getPanels();
            if (this.$root.hasPermission('assets_access') || this.$root.hasPermission('templates_view')) {
                this.getCollections();
            }
            this.getFolders();
        },
        watch: {
            view_asset: function (val, oldVal) {
                if (val) {
                    if (oldVal) {
                        if ((val.id < 0) && (oldVal.id > 0) || (val.id > 0) && (oldVal.id < 0)) {
                            (oldVal.id < 0) ? this.hideLinkedFileDetails() : this.hideAssetDetails();
                            var vm = this;
                            setTimeout(function () {
                                (vm.view_asset.id < 0) ? vm.showLinkedFileDetails(vm.view_asset)
                                    : vm.showAssetDetails(vm.view_asset);
                            }, 800);

                            return null;
                        }
                        (val.id < 0) ? this.showLinkedFileDetails(val, false) : this.showAssetDetails(val, false);

                        return null;
                    }
                    (val.id < 0) ? this.showLinkedFileDetails(val) : this.showAssetDetails(val);

                    return null;
                }
                if (!oldVal) {
                    return null;
                }
                (oldVal.id < 0) ? this.hideLinkedFileDetails() : this.hideAssetDetails();
            }
        },
        methods: {
            showLinkedFileDetails: function (linkedFile, fade = true) {
                var vm = this;
                const component = this.$root.findComponent(this, 'collection-linked-file-details');
                component.setLinkedFile(linkedFile);
                if (!fade) {
                    return null;
                }
                this.scroll_top = $('html, body').scrollTop();
                this.container_height = $('.home-container').outerHeight();
                $('.home-container').css('min-height', this.container_height);
                $('.home-body').fadeOut(350, function () {
                    $('.page-topnav').hide();
                    $('.status-bar').hide();
                    let headerBarHeight = $('.header-bar').outerHeight();
                    if ($('.maintenance-notice').is(":visible")) {
                        headerBarHeight += $('.maintenance-notice').outerHeight() || 0;
                    }
                    const mainNavHeight = $('.main-nav-container').outerHeight();
                    if (vm.scroll_top > headerBarHeight) {
                        $('html, body').scrollTop(headerBarHeight + 1);
                        $('.page-content').css('margin-top', mainNavHeight);
                    } else {
                        $('html, body').scrollTop(0);
                    }
                    $('.linked-file-details').fadeIn(350, function () {
                        $('.home-container').css('min-height', 'auto');
                    });
                });
            },
            hideLinkedFileDetails: function () {
                var vm = this;
                $('.linked-file-details').fadeOut(350, function () {
                    $('.home-container').css('min-height', vm.container_height);
                    $('html, body').scrollTop(vm.scroll_top);
                    $('.page-topnav').show();
                    $('.status-bar').show();
                    $('.home-body').fadeIn(350, function () {
                        const component = vm.$root.findComponent(vm, 'collection-linked-file-details');
                        component.setLinkedFile(null);
                        $('.home-container').css('min-height', 'auto');
                    });
                });
            },
            showAssetDetails: function (asset, fade = true) {
                var vm = this;
                const component = this.$root.findComponent(this, 'asset-details');
                component.setAsset(asset, this.view_asset_tab);
                if (!fade) {
                    return null;
                }
                this.scroll_top = $('html, body').scrollTop();
                this.container_height = $('.home-container').outerHeight();
                $('.home-container').css('min-height', this.container_height);
                $('.home-body').fadeOut(350, function () {
                    $('.page-topnav').hide();
                    $('.status-bar').hide();
                    let headerBarHeight = $('.header-bar').outerHeight();
                    if ($('.maintenance-notice').is(":visible")) {
                        headerBarHeight += $('.maintenance-notice').outerHeight() || 0;
                    }
                    const mainNavHeight = $('.main-nav-container').outerHeight();
                    if (vm.scroll_top > headerBarHeight) {
                        $('html, body').scrollTop(headerBarHeight + 1);
                        $('.page-content').css('margin-top', mainNavHeight);
                    } else {
                        $('html, body').scrollTop(0);
                    }
                    $('.asset-details').fadeIn(350, function () {
                        $('.home-container').css('min-height', 'auto');
                    });
                });
            },
            hideAssetDetails: function () {
                var vm = this;
                $('.asset-details').fadeOut(350, function () {
                    $('.home-container').css('min-height', vm.container_height);
                    $('html, body').scrollTop(vm.scroll_top);
                    $('.page-topnav').show();
                    $('.status-bar').show();
                    $('.home-body').fadeIn(350, function () {
                        const component = vm.$root.findComponent(vm, 'asset-details');
                        component.setAsset(null);
                        $('.home-container').css('min-height', 'auto');
                    });
                });
            },
            getCollections: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collections';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.collections = response.data.collections;
                    // Delete chosen collection if no longer exists.
                    if (vm.$root.current_collection) {
                        let found = false;
                        vm.collections.forEach((item, index) => {
                            if (item.id == vm.$root.current_collection) {
                                found = true;
                            }
                        });
                        if (!found) {
                            vm.$root.current_collection = null;
                            vm.$root.selected_assets = [];
                        }
                    }
                })
                .catch(function (error) {
                    //
                });
            },
            getPanels: function () {
                this.items = [];
                this.loading = true;

                /**
                 * Send request to API
                 */
                const filters = (this.$root.editing_homepage) ? 'ug::' + this.$root.edit_homepage_user_group
                    : 'no-body-images';
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/homepage-panels/' + filters;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.items = [];
                    if (vm.$root.client.show_welcome_panel !== false) {
                        vm.items.push({ id: 'welcome-panel' });
                    }
                    response.data.panels.forEach((item) => {
                        if (vm.$root.editing_homepage) {
                            item.body_image_original = item.body_image ? item.body_image.toString() : null;
                        }
                        vm.items.push(item);
                    });
                    if (vm.$root.editing_homepage) {
                        vm.items.push({ id: 'add-panel' });
                    }
                    if (vm.$root.hasPermission('flyte_access')) {
                        vm.items.push({ id: 'flyte-panel' });
                    }
                    if (!vm.$root.editing_homepage) {
                        vm.groupItems();
                    }
                })
                .catch(function (error) {
                    //
                })
                .finally(() =>
                    vm.loading = false
                );
            },
            onDragSortEnd: function(event) {
                if (!this.$root.editing_homepage) {
                    return null;
                }
                if (event.newIndex == event.oldIndex) {
                    return null;
                }
                const sortOrder = (this.$root.client.show_welcome_panel !== false) ? event.newIndex - 1 : event.newIndex;
                /**
                 * Send move folder request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/homepage-panel/'
                    + this.items[event.newIndex].id + '/move';
                const data = {
                    sort_order: sortOrder
                };
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    //
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                });
            },
            panelColClass: function (itemId, xxlScreen) {
                let className = 'col-xl-2 col-lg-3 col-md-4 col-sm-6 px-2';
                if (!xxlScreen) {
                    className = 'col-lg-3 col-md-4 col-sm-6 px-2';
                }
                if ((itemId != 'add-panel') && (itemId != 'welcome-panel') && (itemId != 'flyte-panel')) {
                    className += ' home-panel-container';
                }

                return className;
            },
            groupItems: function () {
                let group_items = [];
                this.grouped_items = [];
                this.items.forEach((item, index) => {
                    group_items.push(item);
                    if (group_items.length == 5) {
                        this.grouped_items.push({ id: 'g' + index, items: group_items });
                        group_items = [];
                    }
                });
                if (group_items.length > 0) {
                    for (let n = group_items.length; n < 5; n++) {
                        group_items.push({ id: (0 - n)});
                    }
                    this.grouped_items.push({ id: 'last', items: group_items });
                }

                // 4xl
                group_items = [];
                this.grouped_items_4xl = [];
                this.items.forEach((item, index) => {
                    group_items.push(item);
                    if (group_items.length == 7) {
                        this.grouped_items_4xl.push({ id: 'g' + index, items: group_items });
                        group_items = [];
                    }
                });
                if (group_items.length > 0) {
                    for (let n = group_items.length; n < 7; n++) {
                        group_items.push({ id: (0 - n)});
                    }
                    this.grouped_items_4xl.push({ id: 'last', items: group_items });
                }
            },
            getFolders: function () {
                /**
                 * Send request to API
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folders/!pagination|!archived';
                if (this.$root.client.hide_empty_folders) {
                    url += '|!empty';
                }
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folders = response.data.folders;
                })
                .catch(function (error) {
                    // Do anything?
                });
            }
        }
    }
</script>
<style scoped>
    .home-container.padding {
        padding-bottom: 15rem;
    }

    @media (max-width: 767px) {
        .home-container.padding {
            padding-bottom: 17rem;
        }
    }
</style>
