<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="asset-download-history-modal-label">Download history</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <loading v-if="loading"></loading>

            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div v-if="!loading && !errors">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col">User</div>
                                        <div v-if="$root.isFeatured('profiles')" class="col-5">
                                            {{ $root.ucfirst($root.contents.profile_group_title) }}
                                        </div>
                                        <div class="col-3">Downloaded on</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row">
                                    <div class="row">
                                        <div class="col" style="padding-top: 1px">
                                            {{ item.first_name + ' ' + item.last_name }}
                                        </div>
                                        <div v-if="$root.isFeatured('profiles')" class="col-5">
                                            {{ item.profile_group_name }}
                                        </div>
                                        <div class="col-3">
                                            {{ item.created_at_formatted }}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['asset'],
        data () {
            return {
                loading: false,
                errors: false,
                items: []
            }
        },
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            reset: function () {
                this.getReport();
            },
            getReport: function () {
                this.loading = true;
                this.errors = false;
                this.items = [];

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/report/resource-downloads/admin|id::' + this.asset.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.downloads.total > 0) {
                        response.data.downloads.data[0].items.forEach((item, index) => {
                            item.id = index;
                            vm.items.push(item);
                        });
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#asset-download-history-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.loading = false;
                });
            }
        }
    }
</script>
