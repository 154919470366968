<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="filter-share-modal-label">Live link</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="(sent && (type != 'link'))" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>

            <div v-if="(!sent || (type == 'link'))">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <a
                            :class="'nav-link' + ((type == 'link') ? ' active' : '')"
                            id="filter-filter-share-link-tab"
                            data-toggle="tab"
                            href="#filter-share-link"
                            role="tab"
                            aria-controls="filter-share-link"
                            :aria-selected="((type == 'link') ? 'true' : 'false')"
                            @click.prevent="type = 'link'"
                        >Get a link</a>
                    </li>
                    <li class="nav-item">
                        <a
                            :class="'nav-link' + ((type == 'email') ? ' active' : '')"
                            id="filter-share-email-tab"
                            data-toggle="tab"
                            href="#filter-share-email"
                            role="tab"
                            aria-controls="filter-share-email"
                            :aria-selected="((type == 'email') ? 'true' : 'false')"
                            @click.prevent="type = 'email'"
                        >Email</a>
                    </li>
                </ul>

                <div v-if="errors" v-html="errors" class="alert alert-danger mt-3 mb-0" role="alert"></div>

                <div class="tab-content pt-3">
                    <div
                        :class="'tab-pane fade' + ((type == 'link') ? ' show active' : '')"
                        id="filter-share-link"
                        role="tabpanel"
                        aria-labelledby="filter-share-link-tab"
                    >
                        <form>
                            <div class="form-group">
                                <!--<label for="filter-share-link-link-expires">Expires</label>-->
                                <div class="input-group">
                                    <b-form-datepicker
                                        v-model="link_link_expires"
                                        id="filter-share-link-link-expires"
                                        class="form-control"
                                        start-weekday="1"
                                        locale="en-GB"
                                        :min="tomorrow()"
                                        :hideHeader="true"
                                        :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                                        :disabled="sending"
                                    ></b-form-datepicker>
                                    <div class="input-group-append">
                                        <button
                                            class="btn btn-light dropdown-toggle date-presets-button"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :disabled="(updating_link_expiry_date || sending)"
                                        >
                                            Expiry date
                                            <i
                                                class="fas fa-chevron-down d-none d-xl-inline-block"
                                                style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a
                                                v-for="preset in link_expiry_date_presets"
                                                :key="preset.value"
                                                class="dropdown-item"
                                                href="#"
                                                @click.prevent="setDate('link_link_expires', preset.value)"
                                            >{{ preset.text }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <!--<label for="filter-share-link-generated">
                                    Link to {{ $root.contents.asset_title }}
                                </label>-->
                                <div class="input-group">
                                    <input
                                        v-model="sent"
                                        type="text"
                                        id="filter-share-link-generated"
                                        class="form-control"
                                        :readonly="true"
                                    >
                                    <div class="input-group-append">
                                        <button
                                            class="btn btn-secondary"
                                            type="button"
                                            @click="copyLinkToClipBoard()"
                                            :disabled="!sent"
                                        >
                                            {{ copy_link_button_text }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div
                        :class="'tab-pane fade' + ((type == 'email') ? ' show active' : '')"
                        id="filter-share-email"
                        role="tabpanel"
                        aria-labelledby="filter-share-email-tab"
                    >
                        <form>
                            <div class="form-group">
                                <label
                                    for="filter-share-email-subject"
                                    id="filter-share-email-subject-label"
                                    class="mandatory-label"
                                >
                                    Subject line *
                                </label>
                                <input
                                    v-model="email_subject"
                                    type="text"
                                    id="filter-share-email-subject"
                                    class="form-control mandatory-field"
                                    :readonly="sending"
                                    v-on:keyup="$root.unHighlightErrors('filter-share-email-subject')"
                                    v-on:change="$root.unHighlightErrors('filter-share-email-subject')"
                                >
                            </div>
                            <div class="form-group dropdown">
                                <label id="filter-share-email-recipients-label" class="mandatory-label">
                                    Recipient email(s) *
                                </label>
                                <div
                                    class="form-control mandatory-field pb-0"
                                    id="filter-share-email-recipients"
                                    @click="focusEmailRecipientKeyword()"
                                    style="height: auto; min-height: 38px"
                                >
                                    <span
                                        v-for="item in email_recipients"
                                        :key="item.email"
                                        :class="emailRecipientClass(item.email)"
                                        v-b-tooltip.hover
                                        title=""
                                    >
                                        <span v-if="!item.name">{{ item.email }}</span>
                                        <span v-else v-b-tooltip.hover :title="item.email">{{ item.name }}</span>
                                        <a
                                            v-if="!sending"
                                            href="#"
                                            v-b-tooltip.hover
                                            title="Remove"
                                            @click.prevent="removeEmailRecipient(item)"
                                        >
                                            <i class="far fa-times"></i>
                                        </a>
                                        <span v-if="sending">
                                            <i class="far fa-times"></i>
                                        </span>
                                    </span>

                                    <div style="display: inline-block; position: relative">
                                        <input
                                            v-model="email_recipient_keyword"
                                            type="text"
                                            id="filter-share-email-recipient-keyword"
                                            class="form-control px-1 mb-2 share-email-recipient-keyword"
                                            v-on:focus="searchEmailRecipient(true)"
                                            v-on:blur="focusEmailRecipientKeyword(false)"
                                            style="width: 280px; max-width: calc(100% - 40px)"
                                        >
                                        <button
                                            v-if="email_recipient_keyword"
                                            type="button"
                                            class="btn btn-secondary btn-sm"
                                            v-b-tooltip.hover
                                            title="Add recipient"
                                            @click="addEmailRecipient()"
                                            :disabled="sending"
                                            style="padding-top: 1px; padding-bottom: 1px; position: absolute; top: 0"
                                        >
                                            <i class="far fa-plus"></i>
                                        </button>
                                    </div>
                                </div>

                                <div
                                    class="dropdown-menu share-email-recipient-dropdown"
                                    style="width: 280px; max-height: 230px; overflow-y: auto"
                                >
                                    <div v-for="item in email_recipient_results" :key="item.id">
                                        <a
                                            class="dropdown-item"
                                            href="#"
                                            @click.prevent="addEmailRecipient(item)"
                                        >
                                            {{ item.first_name }} {{ item.last_name }}
                                            <p class="small mb-1">{{ item.email }}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="filter-share-email-message">Message</label>
                                <textarea
                                    v-model="email_message"
                                    id="filter-share-email-message"
                                    class="form-control"
                                    :readonly="sending"
                                    style="height: 120px"
                                ></textarea>
                            </div>
                            <div class="form-group">
                                <label for="filter-share-email-link-expires">Expires</label>
                                <div class="input-group">
                                    <b-form-datepicker
                                        v-model="email_link_expires"
                                        id="filter-share-email-link-expires"
                                        class="form-control"
                                        start-weekday="1"
                                        locale="en-GB"
                                        :min="tomorrow()"
                                        :hideHeader="true"
                                        :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                                        :disabled="sending"
                                    ></b-form-datepicker>
                                    <div class="input-group-append">
                                        <button
                                            class="btn btn-light dropdown-toggle date-presets-button"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :disabled="(updating_link_expiry_date || sending)"
                                        >
                                            Preset periods
                                            <i
                                                class="fas fa-chevron-down d-none d-xl-inline-block"
                                                style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="preset in link_expiry_date_presets"
                                                :key="preset.value"
                                                class="dropdown-item"
                                                href="#"
                                                @click.prevent="setDate('email_link_expires', preset.value)"
                                            >{{ preset.text }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="(!sent || (type == 'link'))" class="modal-footer">
            <span v-if="(type == 'email')" :key="'email-buttons'">
                <span v-if="!sending">
                    <button :key="'send-button'" type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-paper-plane mr-1"></i> Send
                    </button>
                </span>
                <span v-else>
                    <button :key="'sending-button'" type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">Send</span>
                    </button>
                </span>
            </span>
            <span v-if="(type == 'link')" :key="'link-buttons'">
                <button type="button" class="btn btn-primary" @click="done()" :disabled="!sent">
                    <i class="far fa-check mr-1"></i> Done
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                is_modal: true,
                errors: false,
                sending: false,
                sent: null,
                type: 'link',
                email_subject: null,
                email_recipients: [],
                email_message: null,
                email_link_expires: null,
                email_recipient_keyword: '',
                block_recipient_keyword_auto_process: false,
                email_recipient_results: [],
                keypress_time: 0,
                updating_link_expiry_date: false,
                link_expiry_date_presets: [
                    {
                        value: '1d',
                        text: 'In 1 day'
                    },
                    {
                        value: '7d',
                        text: 'In 1 week'
                    },
                    {
                        value: '14d',
                        text: 'In 2 weeks'
                    },
                    {
                        value: '1m',
                        text: 'In 1 month'
                    },
                    {
                        value: '1y',
                        text: 'In 1 year'
                    },
                    {
                        value: 'never',
                        text: 'Never expires'
                    }
                ],
                link_subject: null,
                link_link_expires: null,
                copy_link_button_text: 'Copy link',
                link_access: 'user',
                highlight_emails: [],
                validating: false,
                leave_errors: false
            }
        },
        mounted () {
            //
        },
        watch: {
            errors: function (val) {
                if (!val) {
                    $('.mandatory-label').removeClass('text-danger');
                    $('.mandatory-field').removeClass('field-error');
                }
            },
            type: function (val) {
                if (this.leave_errors) {
                    this.leave_errors = false;

                    return null;
                }
                this.reset(false);
                if (val == 'link' && this.link_link_expires) {
                    this.onSubmit();
                }
            },
            email_recipient_keyword: function (val) {
                this.$root.unHighlightErrors('filter-share-email-recipients');
                if (val) {
                    this.searchEmailRecipient(true);

                    return null;
                }
                $('.share-email-recipient-dropdown').dropdown('hide');
            },
            link_link_expires: function (val) {
                this.onSubmit();
            },
            copy_link_button_text: function (val) {
                if (val == 'Copied!') {
                    var vm = this;
                    setTimeout(function () {
                        vm.copy_link_button_text = 'Copy link';
                    }, 2000);
                }
            }
        },
        methods: {
            reset: function (first = true) {
                this.errors = false;
                this.sending = false;
                this.sent = null;
                this.validating = false;
                if (!first) {
                    return null;
                }
                const itemName = this.$root.contents.asset_title;
                this.email_subject = this.$root.user.first_name + ' ' + this.$root.user.last_name + ' sent you '
                    + itemName + 's from ' + this.$root.client.client_name;
                this.email_message = null;
                this.email_recipients = [];
                this.email_recipient_keyword = '';
                this.block_recipient_keyword_auto_process = false;
                this.link_subject = this.email_subject;
                this.highlight_emails = [];
                this.link_access = 'user';
                //this.setDate('email_link_expires', '1m');
                this.email_link_expires = null;
                this.link_link_expires = null;
                //const linkLinkExpires = this.link_link_expires;
                this.type = 'link';
                $('#filter-share-link-tab').click();
                /*this.setDate('link_link_expires', '1m');
                if (linkLinkExpires == this.link_link_expires) {
                    this.onSubmit();
                }*/
            },
            onSubmit: function (validate = false) {
                this.sent = null;
                this.errors = false;
                this.highlight_emails = [];
                this.sending = true;
                this.validating = validate;

                let data = {
                    type: this.type,
                    filters: this.getManualFilters()
                };

                if (this.type == 'email') {
                    data.subject = this.email_subject;
                    data.recipients = this.email_recipients;
                    if (this.email_recipient_keyword) {
                        data.recipients.push({email: this.email_recipient_keyword});
                    }
                    if (this.email_message) {
                        data.message = this.email_message;
                    }
                    if (this.email_link_expires) {
                        data.link_expires = this.email_link_expires;
                    }
                    data.app_info = {
                        app_url: this.$root.$data.app.app_url
                    }
                }
                if (this.type == 'link') {
                    if (this.email_subject) {
                        data.subject = this.email_subject;
                    }
                    if (this.link_link_expires) {
                        data.link_expires = this.link_link_expires;
                    }
                    if ((this.link_access != 'none') && (this.link_access != '3rd_party')) {
                        data.type += ('_' + this.link_access);
                    }
                }

                /**
                 * Send share filters request to API.
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/share';
                if (validate) {
                    url += '/validate';
                }
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.validating) {
                        vm.validating = false;

                        return null;
                    }
                    const type = response.data.type.split('_');
                    if (vm.type != type[0]) {
                        $('#filter-share-' + type[0] + '-tab').click();
                        vm.type = type[0];
                    }
                    if (vm.type == 'email') {
                        const itemName = vm.$root.contents.asset_title;
                        vm.sent = 'Live link for ' + itemName + 's have been emailed.';

                        // Close after 4 seconds automatically.
                        setTimeout(function () {
                            if (vm.is_modal) {
                                $('#filter-share-modal').modal('hide');

                                return null;
                            }
                        }, 4000);

                        return null;
                    }
                    if (vm.type == 'link') {
                        vm.sent = vm.$root.app.app_url + '/shared/' + response.data.access_code;

                        return null;
                    }
                    vm.sent = response.data.message;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#filter-share-modal').modal('hide');
                        vm.$root.doLogout();
                    }

                    const type = error.response.data.type ? error.response.data.type.split('_') : null;
                    if (type) {
                        if (vm.type != type[0]) {
                            vm.leave_errors = true;
                            $('#filter-share-' + type[0] + '-tab').click();
                            vm.type = type[0];
                        }
                    }

                    if ((error.response.status == 406) && (error.response.data.error == 'NotRegistered')) {
                        vm.errors = 'You can not share search results externally.';
                        if (error.response.data.emails) {
                            error.response.data.emails.forEach((email) => {
                                vm.highlight_emails.push(email);
                            });
                        }
                        $('.share-options-container').animate({ scrollTop: 0 }, 700);

                        return null;
                    }

                    if (vm.validating) {
                        vm.validating = false;
                    }
                    
                    // Show error.
                    $('.share-options-container').animate({ scrollTop: 0 }, 700);
                    if (error.response.data.error) {
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#filter-share-' + vm.type + '-' + key + '-label').addClass('text-danger');
                                $('#filter-share-' + vm.type + '-' + key).addClass('field-error');
                            }
                        }
                    } else {
                        vm.errors = 'Something went wrong, please try again';
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            },
            focusEmailRecipientKeyword: function (focus = true, delayed = false) {
                if (focus) {
                    $('#filter-share-email-recipient-keyword').css('display', 'inline');
                    $('#filter-share-email-recipient-keyword').focus();
                    var vm = this;
                    setTimeout(function () {
                        vm.block_recipient_keyword_auto_process = false;
                    }, 500);

                    return null;
                }

                if (!delayed) {
                    var vm = this;
                    setTimeout(function () {
                        vm.focusEmailRecipientKeyword(false, true);
                    }, 500);

                    return null;
                }
                if (this.block_recipient_keyword_auto_process) {
                    return null;
                }
                if (this.email_recipient_keyword) {
                    this.addEmailRecipient(null, false);
                }
                $('#filter-share-email-recipient-keyword').css('display', 'none');
                $('.share-email-recipient-dropdown').dropdown('hide');
            },
            searchEmailRecipient: function (delay = false) {
                if (!this.email_recipient_keyword) {
                    return null;
                }
                if (delay) {
                    this.keypress_time = new Date();
                    var vm = this;
                    setTimeout(function () {
                        vm.searchEmailRecipient();
                    }, 500);
                }

                const time = new Date();
                if ((time - this.keypress_time) < 500) {
                    return null;
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|approved|!declined|!deleted|!invited|kw::'
                    + btoa(this.email_recipient_keyword);
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.email_recipient_results = [];
                    response.data.users.forEach((item, index) => {
                        if ((item.email != vm.$root.user.email) && !vm.isEmailRecipientAdded(item.email)) {
                            vm.email_recipient_results.push(item);
                        }
                    });
                    if (vm.email_recipient_results.length > 0) {
                        setTimeout(function () {
                            $('.share-email-recipient-dropdown').dropdown('show');
                        }, 200);

                        return null;
                    }
                    $('.share-email-recipient-dropdown').dropdown('hide');
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#filter-share-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                });
            },
            isEmailRecipientAdded: function (email) {
                if (!email || (this.email_recipients.length == 0)) {
                    return false;
                }
                let found = false;
                this.email_recipients.forEach((item) => {
                    if (item.email.toLowerCase() == email.toLowerCase()) {
                        found = true;
                    }
                });

                return found;
            },
            addEmailRecipient: function (user = null, addNew = true) {
                if (!user && !this.email_recipient_keyword) {
                    return null;
                }
                const email = user ? user.email.trim() : this.email_recipient_keyword.trim();

                this.email_recipient_keyword = '';
                this.email_recipient_results = [];
                setTimeout(function () {
                    $('.share-email-recipient-dropdown').dropdown('hide');
                }, 250);

                if ((email.length > 0) && !this.isEmailRecipientAdded(email)) {
                    const recipient = {
                        email: email,
                        name: user ? user.first_name + ' ' + user.last_name : null,
                        user_id : user ? user.id : null
                    };
                    this.email_recipients.push(recipient);
                    this.onSubmit(true);
                }

                if (addNew) {
                    this.block_recipient_keyword_auto_process = true;
                    var vm = this;
                    setTimeout(function () {
                        vm.focusEmailRecipientKeyword();
                    }, 200);
                }
            },
            removeEmailRecipient: function (recipient) {
                this.$root.unHighlightErrors('share-email-recipients');
                this.block_recipient_keyword_auto_process = true;
                let itemIndex = -1;
                this.email_recipients.forEach((item, index) => {
                    if (item == recipient) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.email_recipients.splice(itemIndex, 1);
                }
            },
            tomorrow: function () {
                const newDate = new Date();
                newDate.setDate(newDate.getDate() + 1);

                return newDate;
            },
            setDate: function (dateField, value) {
                this.updating_link_expiry_date = true;
                if (value == 'never') {
                    this[dateField] = null;
                    if (dateField == 'link_link_expires') {
                        this.onSubmit();
                    }
                } else {
                    const newDate = new Date();
                    if (value == '1d') {
                        newDate.setDate(newDate.getDate() + 1);
                    }
                    if (value == '7d') {
                        newDate.setDate(newDate.getDate() + 7);
                    }
                    if (value == '14d') {
                        newDate.setDate(newDate.getDate() + 14);
                    }
                    if (value == '1m') {
                        newDate.setMonth(newDate.getMonth() + 1);
                    }
                    if (value == '1y') {
                        newDate.setFullYear(newDate.getFullYear() + 1);
                    }
                    const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
                    const day = ('0' + newDate.getDate()).slice(-2);
                    this[dateField] = newDate.getFullYear() + '-' + month + '-' + day;
                }
                this.updating_link_expiry_date = false;
            },
            copyLinkToClipBoard: function () {
                let copyText = document.querySelector('#filter-share-link-generated');
                copyText.select();

                try {
                    if (document.execCommand('copy')) {
                        this.copy_link_button_text = 'Copied!';
                    }
                } catch (err) {
                    //
                }

                window.getSelection().removeAllRanges();
            },
            done: function () {
                if (this.is_modal) {
                    $('#filter-share-modal').modal('hide');

                    return null;
                }
            },
            emailRecipientClass: function (email) {
                let classList = 'badge ';
                classList += (this.highlight_emails.indexOf(email) > -1) ? 'badge-soft-danger'
                    : 'badge-soft-secondary';
                classList += ' contact-links font-size-12 mr-2 mb-2';

                return classList;
            },
            getManualFilters: function () {
                let filters = [];
                this.$root.asset_filters.forEach((item) => {
                    if (!item.auto && ((item.key != 'sort_by') || (item.value != 'newest'))) {
                        let filter = {
                            key: item.key,
                            value: item.value,
                            name: item.name || null,
                            text: item.text
                        };
                        filters.push(filter);
                    }
                });

                return filters;
            }
        }
    }
</script>
<style scoped>
    .share-email-recipient-keyword {
        display: none;
        width: auto;
        height: 20px;
        border: none;
    }

    .share-email-recipient-keyword:focus {
        background-color: #f8f9fa;
    }

    .modal-body {
        min-height: 350px;
    }
</style>
