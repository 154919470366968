<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="decline-user-modal-label">
                <span v-if="spam">Remove spam application</span>
                <span v-else>Decline user application</span>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="sent" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="spam">
                    This will remove the application from the system completely without giving any reason
                </div>
                <form v-else>
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <label for="reason_for_decline" id="reason_for_decline-label" class="mandatory-label">
                        Reason for declining {{ user.first_name }} {{ user.last_name }}'s application
                    </label>
                    <textarea
                        v-model="reason_for_decline"
                        id="reason_for_decline"
                        class="form-control mandatory-field"
                        :readonly="sending"
                    ></textarea>
                </form>
            </div>
        </div>
        <div v-if="!sent" class="modal-footer">
            <span v-if="spam">
                <span v-if="!sending" :key="'remove-button'">
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-trash-alt mr-1"></i> Remove application
                    </button>
                </span>
                <span v-else :key="'removing-button'">
                    <button type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">Remove application</span>
                    </button>
                </span>
            </span>
            <span v-else>
                <span v-if="!sending" :key="'send-button'">
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-paper-plane mr-1"></i> Send
                    </button>
                </span>
                <span v-else :key="'sending-button'">
                    <button type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">Send</span>
                    </button>
                </span>
            </span>
            <button type="button" class="btn btn-light ml-2" data-dismiss="modal" :disabled="sending">
                <i class="far fa-times mr-1"></i> Cancel
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                reason_for_decline: '',
                user: {
                    first_name: '',
                    last_name: ''
                },
                spam: false
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (user, spam = false) {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.reason_for_decline = '';
                this.user = user;
                this.spam = spam;
            },
            onSubmit: function () {
                this.errors = false;
                this.sending = true;

                /**
                 * Send decline user request to API.
                 */
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/user/' + this.user.id
                    + '/decline';
                let data = {
                    spam: this.spam,
                    app_info: {
                        app_url: this.$root.$data.app.app_url
                    }
                }
                if (!this.spam) {
                    data.reason_for_decline = this.reason_for_decline;
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.sent = response.data.message;

                    // Reload user list.
                    vm.$parent.getUsers();

                    // Close after 4 seconds automatically.
                    setTimeout(function () {
                        $('#decline-user-modal').modal('hide');
                    }, 4000);

                    // Clear notification.
                    vm.$root.clearNotification('admin', 0, 'registration', vm.user.id);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#decline-user-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    // Show error.
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() =>
                    vm.sending = false
                );
            }
        }
    }
</script>
