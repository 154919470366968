<template>
    <div class="card asset-preview">
        <div class="thumbnail">
            <img :src="image_src" :alt="title">
        </div>

        <div class="card-body" style="position: relative">
            <div
                class="title mb-2"
                :id="'flyte-file-title' + id"
            >
                {{ short_title }}
            </div>

            <b-tooltip
                v-if="(short_title != title)"
                :target="'flyte-file-title' + id"
                triggers="hover"
            >
                {{ title }}
            </b-tooltip>

            <div>
                <span
                    class="asset-info stay"
                    :id="'flyte-file-info' + id"
                >
                    <span
                        v-if="extension"
                        class="badge badge-primary font-size-11 mr-1"
                        style="text-transform: uppercase"
                    >
                        {{ extension }}
                    </span>
                    {{ $root.formatFileSize(data.file_size) }}
                </span>
                &nbsp;
            </div>

            <div
                class="font-size-22 contact-links"
                style="position: absolute; right: 15px; bottom: 6px"
            >
                <a
                    v-if="!downloading"
                    href="#"
                    @click.prevent="initDownload()"
                >
                    <span v-b-tooltip.hover title="Download">
                        <i class="fal fa-download"></i>
                    </span>
                </a>
                <span v-else>
                    <i class="fal fa-spinner-third fa-spin"></i>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'in_share', 'email'],
        data () {
            return {
                id: 0,
                image_src: '/images/file-icon.png',
                title: '',
                short_title: '',
                extension: null,
                file_type: null,
                downloading: false,
                download_options: []
            }
        },
        mounted () {
            this.updateData(this.data);
            var vm = this;
            $(window).resize(function () {
                vm.short_title = vm.title;
                setTimeout(function () {
                    vm.fitTitle();
                }, 500);
            });
        },
        watch: {
            data: function (val) {
                if (val) {
                    this.updateData(val);
                }
            }
        },
        methods: {
            updateData: function (data) {
                if (data) {
                    this.id = data.original_id;
                    this.title = data.filename;
                    this.short_title = data.filename;
                    this.extension = data.file_extension;
                    this.file_type = data.file_type;
                    this.download_options = [];
                    this.getPreviewImage();
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                }
            },
            getPreviewImage: function () {
                /**
                 * Send request to API
                 */
                const size = (this.extension == 'svg') ? 'svg' : 'lowres';
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/flyte/' + this.id + '/preview/'
                    + size;
                if (!this.$root.isLoggedIn() && this.in_share) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    url: url,
                    headers: (!this.$root.isLoggedIn() && this.in_share) ? null : { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            fitTitle: function () {
                if (!$('#flyte-file-title' + this.id)[0]) {
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 200);

                    return null;
                }
                if ($('#flyte-file-title' + this.id)[0].scrollHeight > 20) {
                    let separator = ' ';
                    let words = this.short_title.split(separator);
                    if (words.length == 1) {
                        separator = '-';
                        words = this.short_title.split(separator);
                    }
                    if (words.length == 1) {
                        separator = '_';
                        words = this.short_title.split(separator);
                    }
                    if (words.length == 1) {
                        separator = '.';
                        words = this.short_title.split(separator);
                    }
                    words.pop();
                    if (words.length > 0) {
                        this.short_title = words.join(separator) + '...';
                        var vm = this;
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 200);
                    }
                }
            },
            initDownload: function () {
                this.downloading = true;
                if (this.download_options.length == 0) {
                    this.getDownloadOptions();

                    return null;
                }
                /**
                 * Send request to API
                 */
                const download = this.download_options[0];
                const data = {
                    item_type: 'flyte',
                    item_id: this.id,
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension
                };
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                if (this.in_share && !this.$root.user) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                    window.location.href = url;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();

                        return null;
                    }
                    vm.showError(
                        'Download',
                        error.response.data.error ? error.response.data.error : 'Something went wrong, please try again'
                    );
                })
                .finally(() =>
                    vm.downloading = null
                );
            },
            showError: function (title, text) {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: 'error',
                    title: title,
                    text: text
                };
                $('#message-modal').modal('show');
            },
            getDownloadOptions: function () {
                /**
                 * Send request to API
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/flyte/' + this.id
                    + '/download-options';
                if (this.in_share && !this.$root.user) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    url: url,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    vm.download_options = response.data.download_options;
                    if (vm.download_options.length > 0) {
                        vm.initDownload();

                        return null;
                    }
                    vm.downloading = false;
                    vm.showError(
                        'Download',
                        'There is no file available to download'
                    );
                })
                .catch(function (error) {
                    vm.downloading = false;
                    vm.showError(
                        'Download',
                        error.response.data.error ? error.response.data.error : 'Something went wrong, please try again'
                    );
                });
            }
        }
    }
</script>
<style scoped>
    .asset-preview:hover .card-body .asset-info.stay {
        opacity: 1;
    }

    .asset-preview .title {
        overflow-wrap: anywhere;
    }
</style>
