<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div class="row">
            <div class="col-xl-2 col-lg-3 col-sm-4 mb-3">
                <div class="bg-light text-center" style="padding: 1px">
                    <img class="img-fluid" :src="image_src" :alt="data.title">
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="mb-0">Who will see this {{ $root.contents.asset_title }}?</label>
                            <div>{{ data.audience_types }}</div>
                        </div>
                        <div v-if="data.usage_notes" class="form-group">
                            <label class="mb-0">Brief details</label>
                            <div>{{ data.usage_notes }}</div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label class="mb-0">How will it be used?</label>
                            <div>{{ data.usage_specify }}</div>
                        </div>
                        <div class="form-group">
                            <label class="mb-0">Usage date</label>
                            <div>{{ usage_start_date_formatted ? usage_start_date_formatted : '...' }}</div>
                        </div>
                    </div>
                </div>
                <!--<div v-if="details" class="form-group">{{ details.approval_info }}</div>-->
            </div>
        </div>
        <span v-if="(data.approved && !data.expired)">
            <span v-if="!downloading">
                <button
                    :key="'download-button'"
                    type="button"
                    class="btn btn-primary mr-sm-2 mb-2 mb-sm-0"
                    @click.prevent="viewAssetDetails()"
                >
                    <i class="far fa-download mr-1"></i> Download
                </button>
            </span>
            <span v-else>
                <button
                    :key="'download-button-spinning'"
                    type="button"
                    class="btn btn-primary mr-sm-2"
                >
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Download</span>
                </button>
            </span>
        </span>

        <button
            v-if="!data.approved"
            :key="'chase-button'"
            type="button"
            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
            :disabled="updating"
            data-toggle="modal"
            data-target="#email-support-modal"
            @click.prevent="resetSupportForm()"
        >
            <i class="far fa-envelope mr-1"></i> Chase
        </button>

        <span v-if="!updating">
            <button
                :key="'cancel-button'"
                type="button"
                class="btn btn-secondary mr-sm-2"
                :disabled="downloading"
                @click.prevent="cancelApprovalRequest()"
            >
                <i class="far fa-trash-alt mr-1"></i> Cancel
            </button>
        </span>
        <span v-else>
            <button
                :key="'cancel-button-spinning'"
                type="button"
                class="btn btn-secondary mr-sm-2"
            >
                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                <span style="opacity: 0.5">Cancel</span>
            </button>
        </span>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data () {
            return {
                errors: false,
                updating: false,
                downloading: false,
                usage_start_date_formatted: '',
                get_date_time_formatted_response: null,
                image_src: '/images/file-icon.png'
            }
        },
        mounted () {
            this.$root.getDateTimeFormatted(this, 'usage_start_date', this.data.usage_start_date, 'j M Y');
            this.getPreviewImage();
        },
        watch: {
            get_date_time_formatted_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.id == 'usage_start_date') {
                            this.usage_start_date_formatted = val.date_time_formatted;
                        }
                    }
                }
            }
        },
        methods: {
            getPreviewImage: function () {
                /**
                 * Send request to API
                 */
                const size = (this.data.file_extension == 'svg') ? 'svg' : 'lowres';
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/' + this.data.asset_id
                    + '/preview/' + size;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            cancelApprovalRequest: function () {
                this.updating = true;
                /**
                 * Send approve asset request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource-access-request/' + this.data.approval_id;
                var vm = this;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Remove item
                    for (let n = vm.$parent.items.length - 1; n >= 0; n--) {
                        if (vm.$parent.items[n].approval_id == vm.data.approval_id) {
                            vm.$parent.items.splice(n, 1);
                            vm.$parent.total_items--;
                        }
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            resetSupportForm: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'email-support');
                if (component) {
                    component.resetForm('resources', this.$root.user.first_name + ' ' + this.$root.user.last_name
                        + " is chasing approval for '" + this.data.title + "' " + this.$root.contents.asset_title
                        + ' No. ' + this.data.asset_id);
                }
            },
            viewAssetDetails: function () {
                this.$root.asset_filters = [];
                this.$root.addFilter('asset_filters', {
                    key: 'resource_id',
                    value: parseInt(this.data.asset_id),
                    text: '&quot;' + this.data.asset_id + '&quot;',
                    filters_key: 'asset_filters'
                });
                this.$router.push({ name: 'assets' });
            }
            /*initDownload: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                if (appComponent) {
                    const component = this.$root.findComponent(appComponent, 'asset-download');
                    if (component) {
                        this.downloading = true;
                        component.initData(this);
                    }
                }
            }*/
        }
    }
</script>
<style scoped>
    img {
        max-height: 200px;
    }

    @media (max-width: 575px) {
        .btn {
            display: block;
            width: 100%;
        }
    }
</style>
