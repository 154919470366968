<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div class="row">
            <div class="col-xl-2 col-lg-3 col-sm-4 mb-3">
                <div class="bg-light text-center" style="padding: 1px">
                    <img class="img-fluid" :src="image_src" :alt="data.title">
                </div>
            </div>
            <div class="col">
                <div class="mr-1 pt-2" style="display: inline-block; height: 37px">
                    <h5 class="m-0">Review date</h5>
                </div>
                <div v-if="!changing_review_date" style="display: inline-block">
                    <div class="mr-2 mb-2 pt-2" style="display: inline-block">
                        <h5 class="m-0">{{ review_date ? $parent.formatDate(review_date) : '...' }}</h5>
                    </div>
                    <div style="display: inline-block">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click.prevent="changing_review_date = true"
                            :disabled="(!review_date || deleting)"
                            style="position: relative; top: -2px"
                        >
                            <i class="far fa-calendar-alt mr-1"></i> Change review date
                        </button>
                    </div>
                </div>
                <div v-else style="display: inline-block">
                    <div style="display: inline-block; width: 220px; position: relative">
                        <form v-on:submit.prevent="updateReviewDate()" style="position: absolute; top: -25px">
                            <div class="input-group">
                                <b-form-datepicker
                                    v-model="new_review_date"
                                    :class="'form-control review-date-' + data.id"
                                    start-weekday="1"
                                    locale="en-GB"
                                    :min="today()"
                                    :hideHeader="true"
                                    :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                                    :disabled="(updating_review_date || deleting)"
                                    style="height: 37px"
                                ></b-form-datepicker>
                                <div class="input-group-append">
                                    <button
                                        v-if="!updating_review_date"
                                        class="btn btn-primary big-icon"
                                        type="submit"
                                        v-b-tooltip.hover
                                        title="Save"
                                        :disabled="deleting"
                                    >
                                        <strong><i class="fal fa-save"></i></strong>
                                    </button>
                                    <button
                                        v-else
                                        type="button"
                                        class="btn btn-primary"
                                    >
                                        <span>
                                            <i class="fal fa-spinner-third fa-spin big-icon"></i>
                                        </span>
                                    </button>
                                    <button
                                        class="btn btn-light big-icon"
                                        type="button"
                                        v-b-tooltip.hover
                                        title="Cancel"
                                        :disabled="updating_review_date"
                                        @click="changing_review_date = false"
                                    >
                                        <i class="fal fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <hr class="mt-2">
                <div v-if="details" class="form-group">
                    Added by {{ details.uploader_name }} &nbsp; {{ created_at_formatted }}
                </div>
            </div>
        </div>

        <button
            type="button"
            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
            @click.prevent="viewAsset()"
            :disabled="deleting"
        >
            <i class="far fa-eye mr-1"></i> View {{ $root.contents.asset_title }}
        </button>
        <span v-if="$root.hasPermission('assets_delete')">
            <span v-if="!deleting">
                <button
                    type="button"
                    class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                    @click="deleteAsset()"
                >
                    <i class="far fa-trash-alt mr-1"></i> Delete {{ $root.contents.asset_title }}
                </button>
            </span>
            <button v-else type="button" class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0">
                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                Deleting {{ $root.contents.asset_title }}
            </button>
        </span>
        <button
            v-if="canArchive()"
            type="button"
            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
            @click.prevent="archiveAsset()"
            :disabled="deleting"
        >
            <i class="far fa-archive mr-1"></i> Archive {{ $root.contents.asset_title }}
        </button>
    </div>
</template>

<script>
    export default {
        props: ['data', 'visible'],
        data () {
            return {
                errors: false,
                deleting: false,
                review_date: null,
                created_at_formatted: '',
                get_date_time_formatted_response: null,
                image_src: '/images/file-icon.png',
                details: null,
                changing_review_date: false,
                new_review_date: null,
                updating_review_date: false
            }
        },
        mounted () {
            //
        },
        watch: {
            visible: function (val) {
                if (val && !this.review_date) {
                    this.$root.getDateTimeFormatted(this, 'created_at', this.data.created_at, 'g:ia d/m/Y');
                    this.review_date = this.data.review_date;
                    this.new_review_date = this.data.review_date;
                    this.getPreviewImage();
                    this.getReviewDetails();
                }
            },
            get_date_time_formatted_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.id == 'created_at') {
                            this.created_at_formatted = val.date_time_formatted;
                        }
                    }
                }
            },
            changing_review_date: function () {
                var vm = this;
                setTimeout(function () {
                    $('.review-date-' + vm.data.id + ' > button').click();
                }, 200);
            }
        },
        methods: {
            getPreviewImage: function () {
                /**
                 * Send request to API
                 */
                const size = (this.data.file_extension == 'svg') ? 'svg' : 'lowres';
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + this.data.id + '/preview/' + size;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            getReviewDetails: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + this.data.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.details = {
                        uploader_name: response.data.resource.uploader_name
                    }
                })
                .catch(function (error) {
                    //
                });
            },
            updateReviewDate: function () {
                this.updating_review_date = true;
                /**
                 * Send update review date request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + this.data.id + '/review-date';
                const data = {
                    review_date: this.new_review_date
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // show confirmation message
                    vm.changing_review_date = false;
                    vm.$parent.getAssets();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = 'Something went wrong, please try again';
                    }
                })
                .finally(() => {
                    vm.updating_review_date = false;
                });
            },
            viewAsset: function () {
                this.$root.asset_filters = [];
                this.$root.addFilter('asset_filters', {
                    key: 'resource_id',
                    value: this.data.id,
                    text: '&quot;' + this.data.id + '&quot;',
                    filters_key: 'asset_filters'
                });
                if (this.data.archived) {
                    this.$router.push({ name: 'assets-filtered', params: { filters: 'archived' } });

                    return null;
                }
                this.$router.push({ name: 'assets' });
            },
            deleteAsset: function (confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.deleting = true;

                    /**
                     * Send delete resource request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                        + '/resource/' + this.data.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        // Deselect asset
                        let selectedAssets = vm.$root.selected_assets;
                        let itemIndex = -1;
                        selectedAssets.forEach((item, index) => {
                            if (item == this.asset.id) {
                                itemIndex = index;
                            }
                        });
                        if (itemIndex > -1) {
                            selectedAssets.splice(itemIndex, 1);
                        }
                        // Reload assets
                        vm.$parent.getAssets();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        vm.errors = "Error deleting " + vm.$root.contents.asset_title + ", please try again";
                    })
                    .finally(() =>
                        vm.deleting = false
                    );

                    return null;
                }

                this.$parent.$parent.confirm_delete = {
                    title: 'Delete ' + this.$root.contents.asset_title,
                    text: 'Are you sure you want to delete: ' + this.data.title + '?'
                        + '<br><br>This action cannot be undone.',
                    component: this,
                    action: 'delete-asset'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deleteAsset(true);
            },
            archiveAsset: function () {
                const component = this.$root.findComponent(this.$parent.$parent, 'asset-archive');
                if (component) {
                    component.resetForm(this.data, this.$parent);
                    setTimeout(function() {
                        $('#asset-archive-modal').modal('show');
                    }, 200);
                }
            },
            today: function () {
                return new Date();
            },
            canArchive: function () {
                if (!this.data) {
                    return false;
                }
                if (!this.data.temlate && this.$root.hasPermission('assets_archive')) {
                    return true;
                }
                if (this.data.temlate && this.$root.hasPermission('templates_archive')) {
                    return true;
                }

                return false;
            }
        }
    }
</script>
<style scoped>
    img {
        max-height: 200px;
    }

    .input-group-append .btn {
        z-index: 0;
    }

    @media (max-width: 575px) {
        .btn {
            display: block;
            width: 100%;
        }
    }
</style>
