import { render, staticRenderFns } from "./ProofsTopNav.vue?vue&type=template&id=7e70f7df&scoped=true"
import script from "./ProofsTopNav.vue?vue&type=script&lang=js"
export * from "./ProofsTopNav.vue?vue&type=script&lang=js"
import style0 from "./ProofsTopNav.vue?vue&type=style&index=0&id=7e70f7df&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e70f7df",
  null
  
)

export default component.exports