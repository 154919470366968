<template>
    <div style="position: relative; left: -5px; margin-top: 1px">
        <div class="filter">
            <searchable-option-list
                v-model="status"
                :value="status"
                :options="status_options"
                field_id="filter-by-status"
                search_placeholder="Select a status"
                :readonly="isApprovalsLoading()"
            ></searchable-option-list>
        </div>
        <div v-if="(status == 'Approved')" class="filter">
            <searchable-option-list
                v-model="expired"
                :value="expired"
                :options="expired_options"
                field_id="filter-by-expired"
                search_placeholder="All approvals"
                :readonly="isApprovalsLoading()"
            ></searchable-option-list>
        </div>
        <div class="filter keyword">
            <form v-on:submit.prevent="keywordSearch()">
                <div class="keyword-field">
                    <div class="input-group">
                        <input
                            v-model="keyword"
                            type="text"
                            class="form-control"
                            id="filter-by-keyword"
                            placeholder="Search name or email"
                            aria-label="Search name or email"
                            :readonly="isApprovalsLoading()"
                        >
                        <div class="input-group-append">
                            <button class="btn btn-primary big-icon" type="submit" :disabled="isApprovalsLoading()">
                                <i class="fal fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <button
                        v-if="keyword && !isApprovalsLoading()"
                        class="btn btn-link clear-keyword"
                        @click.prevent="clearKeyword()"
                    >
                        <i class="far fa-times"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['asset_approval_filters'],
        data() {
            return {
                status_options: [
                    {
                        id: 'Awaiting approval',
                        name: 'Awaiting approval'
                    },
                    {
                        id: 'Approved',
                        name: 'Approved'
                    },
                    {
                        id: 'Declined',
                        name: 'Declined'
                    }
                ],
                status: 'Awaiting approval',
                expired_options: [
                    {
                        id: 1,
                        name: 'Expired approvals'
                    },
                    {
                        id: 0,
                        name: 'Active approvals'
                    },
                    {
                        id: null,
                        name: 'All approvals'
                    }
                ],
                expired: null,
                keyword: ''
            };
        },
        mounted () {
            this.asset_approval_filters.forEach((item) => {
                if (item.key == 'keyword') {
                    this.keyword = item.value;
                }
            });
        },
        watch: {
            asset_approval_filters: function (val) {
                this.updateFilters();
            },
            status: function (val) {
                var vm = this;
                if (!val) {
                    setTimeout(function () {
                        vm.status = 'Awaiting approval';
                    }, 200);

                    return null;
                }
                this.$root.addFilter('asset_approval_filters', {
                    key: 'status',
                    value: val,
                    text: val,
                    filters_key: 'asset_approval_filters'
                });
                if ((val !== 'Approved') && this.expired) {
                    this.expired = null;

                    return null;
                }
                setTimeout(function () {
                    vm.getApprovals();
                }, 200);
            },
            expired: function (val) {
                var vm = this;
                if (val !== null) {
                    this.$root.addFilter('asset_approval_filters', {
                        key: val ? 'expired' : '!expired',
                        value: 1,
                        text: val ? this.expired_options[0].name : this.expired_options[1].name,
                        filters_key: 'asset_approval_filters'
                    });
                    this.$root.addFilter('asset_approval_filters', {
                        key: val ? '!expired' : 'expired',
                        value: null
                    });

                    setTimeout(function () {
                        vm.getApprovals();
                    }, 200);

                    return null;
                }
                // Otherwise remove expired filter
                this.$root.addFilter('asset_approval_filters', {
                    key: 'expired',
                    value: null
                });
                this.$root.addFilter('asset_approval_filters', {
                    key: '!expired',
                    value: null
                });
                
                setTimeout(function () {
                    vm.getApprovals();
                }, 200);
            }
        },
        methods: {
            updateFilters: function () {
                let statusFound = null;
                let expiredFound = null;
                this.asset_approval_filters.forEach((item) => {
                    if (item.key == 'status') {
                        statusFound = item.value;
                    }
                    if (item.key == 'expired') {
                        expiredFound = 1;
                    }
                    if (item.key == '!expired') {
                        expiredFound = 0;
                    }
                });

                if (statusFound != 'Approved') {
                    expiredFound = null;
                }

                if (this.status !== statusFound) {
                    this.status = statusFound;
                }

                if (this.expired !== expiredFound) {
                    this.expired = expiredFound;
                }
            },
            keywordSearch: function () {
                const keywordTrimmed = this.keyword.trim();
                
                let keywordsRemoved = false;
                let filters = [];
                this.$root.asset_approval_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        keywordsRemoved = true;
                    } else {
                        filters.push(item);
                    }
                });
                this.$root.asset_approval_filters = filters;

                if (!keywordTrimmed) {
                    if (keywordsRemoved) {
                        this.getApprovals();
                    }

                    return null;
                }
                this.$root.addFilter('asset_approval_filters', {
                    key: 'keyword',
                    value: keywordTrimmed,
                    text: '&quot;' + keywordTrimmed.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + '&quot;',
                    filters_key: 'asset_approval_filters'
                });
                this.getApprovals();
            },
            clearKeyword: function () {
                this.keyword = '';
                this.keywordSearch();
                $('#filter-by-keyword').focus();
            },
            getApprovals: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'asset-approvals');
                if (component) {
                    component.getApprovals();
                }
            },
            isApprovalsLoading: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'asset-approvals');
                if (!component) {
                    return true;
                }

                return component.loading;
            }
        }
    }
</script>
<style scoped>
    .filter {
        display: inline-block;
        width: 200px;
        position: relative;
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .filter.keyword {
        width: 300px;
    }

    .keyword-field {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }

    @media (max-width: 575px) {
        .filter, .filter.keyword {
            width: 100%;
            margin-right: 0;
        }
    }
</style>
