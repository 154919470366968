<template>
    <div class="dz-thumbnail">
        <div v-if="!image_src" class="preview-loading">
            <loading size="1"></loading>
        </div>
        <img
            v-else
            :src="image_src"
            class="img-fluid"
            alt="preview"
            @click="$parent.viewArtwork(item)"
            style="cursor: pointer"
            v-b-tooltip.hover
            title="View"
        >
    </div>
</template>

<script>
    export default {
        props: ['item', 'image_src'],
        mounted () {
            //
        }
    }
</script>
<style scoped>
    .dz-thumbnail {
        width: 80px;
    }

    .preview-loading {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 2;
        height: 20px;
    }
</style>
