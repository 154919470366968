<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="confirm-delete-modal-label">{{ data.title }}</h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                @click="onClose()"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-html="data.text"></div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary mr-2" @click="doConfirm()">
                <span v-if="hasYesIcon()" :key="'yes-button-' + data.buttons.yes_icon">
                    <i :class="'far ' + data.buttons.yes_icon + ' mr-1'"></i>
                    {{ data.buttons.yes }}
                </span>
                <span v-else :key="'yes-button-default'">
                    <i class="far fa-check mr-1"></i> {{ data.buttons ? data.buttons.yes: 'Yes' }}
                </span>
            </button>
            <button type="button" class="btn btn-light" @click="doCancel()">
                <span v-if="hasCancelIcon()" :key="'cancel-button-' + data.buttons.cancel_icon">
                    <i :class="'far ' + data.buttons.cancel_icon + ' mr-1'"></i>
                    {{ data.buttons.cancel }}
                </span>
                <span v-else :key="'cancel-button-default'">
                    <i class="far fa-times mr-1"></i> {{ data.buttons ? data.buttons.cancel: 'Cancel' }}
                </span>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        mounted () {
            //
        },
        methods: {
            doConfirm: function () {
                this.data.component.confirmDelete(this.data.action);
                this.onClose();
            },
            doCancel: function () {
                if (this.data.cancel_action) {
                    this.data.component.confirmDelete(this.data.cancel_action);
                }
                this.onClose();
            },
            onClose: function () {
                $('#confirm-delete-modal').modal('hide');
                this.$parent.resetModalBackdrop();
                var vm = this;
                setTimeout(function () {
                    vm.$parent.confirm_delete.buttons = null;
                    vm.$parent.confirm_delete.cancel_action = null;
                }, 500);
            },
            hasYesIcon: function () {
                if (!this.data.buttons) {
                    return false;
                }
                
                return this.data.buttons.yes_icon;
            },
            hasCancelIcon: function () {
                if (!this.data.buttons) {
                    return false;
                }
                
                return this.data.buttons.cancel_icon;
            }
        }
    }
</script>
