<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="folder-delete-modal-label">
                Delete {{ $root.contents.folder_title }}: {{ folder_name }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="deleted" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="deleted"></div>
                </div>
            </div>
            <form v-else v-on:submit.prevent="deleteFolder()">
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="loading"><loading></loading></div>

                <div v-if="!loading && canDelete()">
                    <p>
                        This action permanently deletes the {{ $root.contents.folder_title }}{{ additionalThings() }}.
                    </p>
                    <p v-if="((assets_live_count > 0) || (assets_archived_count > 0) || (custom_fields_count > 0))">
                        If you need to manage
                        <span v-if="((assets_live_count > 0) || (assets_archived_count > 0))">
                            {{ $root.contents.asset_title }}s
                        </span>
                        <span
                            v-if="(((assets_live_count > 0) || (assets_archived_count > 0))
                                && (custom_fields_count > 0))"
                        >or</span>
                        <span v-if="(custom_fields_count > 0)">custom fields</span>
                        you should do this first.
                    </p>
                    <p v-if="(assets_live_count > 0)">
                        You can move live {{ $root.contents.asset_title }}s to other {{ $root.contents.folders_title }}
                        by adding them to a collection and using the Bulk actions function.
                    </p>
                </div>
            </form>
        </div>
        <div v-if="!loading && !deleted && canDelete()" class="modal-footer">
            <span v-if="!deleting">
                <span>
                    <button type="button" class="btn btn-primary mr-2" @click="deleteFolder()" :disabled="loading">
                        <i class="far fa-trash-alt mr-1"></i> Delete {{ $root.contents.folder_title }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary mr-2">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Delete {{ $root.contents.folder_title }}</span>
                </button>
            </span>
            <button type="button" class="btn btn-light" data-dismiss="modal" :disabled="deleting">
                <i class="far fa-times mr-1"></i> Cancel
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: false,
                errors: false,
                folder_id: 0,
                folder_name: '',
                subfolders_count: 0,
                custom_fields_count: 0,
                assets_live_count: 0,
                assets_archived_count: 0,
                profile_groups_count: 0,
                deleting: false,
                deleted: false
            }
        },
        mounted () {
            //
        },
        methods: {
            reset: function (folder) {
                this.loading = true;
                this.errors = false;
                this.folder_id = folder.id;
                this.folder_name = folder.name;
                this.subfolders_count = folder.subfolders_count;
                this.custom_fields_count = folder.custom_fields_count;
                this.assets_live_count = 0;
                this.assets_archived_count = 0;
                this.profile_groups_count = 0;
                this.deleting = false;
                this.deleted = false;
                this.getAssetInfo();
            },
            getAssetInfo: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/' + this.folder_id
                    + '/resource-info';
                var vm = this;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.assets_live_count = response.data.live;
                    vm.assets_archived_count = response.data.archived;
                    vm.profile_groups_count = response.data.profile_groups || 0;
                    if (vm.profile_groups_count > 0) {
                        vm.errors = 'This ' + vm.$root.contents.folder_title + ' is linked to ';
                        if (vm.profile_groups_count > 1) {
                            vm.errors += vm.profile_groups_count + ' ' + vm.$root.contents.profile_groups_title
                                + ' and therefore cannot be deleted until the '
                                + vm.$root.contents.profile_groups_title + ' are removed.';
                        } else {
                            vm.errors += 'a ' + vm.$root.contents.profile_group_title
                                + ' and therefore cannot be deleted until the '
                                + vm.$root.contents.profile_group_title + ' is removed.';
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#folder-delete-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            additionalThings: function () {
                let text = '';
                if (this.subfolders_count > 0) {
                    text = ', ' + this.subfolders_count + ' ';
                    if (this.subfolders_count > 1) {
                        text += this.$root.contents.subfolders_title;
                    } else {
                        text += this.$root.contents.subfolder_title;
                    }
                }
                if ((this.assets_live_count > 0) || (this.assets_archived_count > 0)) {
                    text += ' with ';
                    if (this.assets_live_count > 0) {
                        text += this.assets_live_count + ' live ';
                    }
                    if ((this.assets_live_count > 0) && (this.assets_archived_count > 0)) {
                        text += 'and '
                    }
                    if (this.assets_archived_count > 0) {
                        text += this.assets_archived_count + ' archived ';
                    }
                    text += this.$root.contents.asset_title;
                    if ((this.assets_live_count + this.assets_archived_count) > 1) {
                        text += 's';
                    }
                }
                if (this.custom_fields_count > 0) {
                    text += ' and any custom fields that are assigned only to this ' + this.$root.contents.folder_title;
                }

                return text;
            },
            deleteFolder: function () {
                this.deleting = true;
                /**
                 * Send delete folder request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/' + this.folder_id;
                var vm = this;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.deleted = response.data.message;
                    vm.$parent.getFolders();
                    vm.$root.folders_changed = true;

                    setTimeout(function () {
                        $('#folder-delete-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#folder-delete-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.deleting = false;
                });
            },
            canDelete: function () {
                return (this.profile_groups_count == 0);
            }
        }
    }
</script>
