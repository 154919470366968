<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="artwork-history-modal-label">History: {{ asset.title }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <loading v-if="loading"></loading>

            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div v-if="(!errors && !loading)" class="history-items">
                <p>Your title: {{ artwork.title }}</p> 
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-4">Date</div>
                                        <div class="col">Action</div>
                                        <div class="col-2">Order number</div>
                                        <div class="col-2"></div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row">
                                    <artwork-history-item :data="item"></artwork-history-item>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!--<div v-if="loading" style="position: absolute; left: 0; bottom: 7px; width: 100%">
                <loading></loading>
            </div>-->
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                artwork: {
                    title: ''
                },
                asset: {
                    title: ''
                },
                loading: false,
                errors: false,
                items: [],
                get_date_time_formatted_response: null
            }
        },
        mounted () {
            //
        },
        watch: {
            get_date_time_formatted_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        $('#date' + val.id.substr(3)).html(val.date_time_formatted);
                    }
                }
            }
        },
        methods: {
            reset: function (artwork, asset) {
                this.artwork = artwork;
                this.asset = asset;
                this.getHistory();
            },
            getHistory: function () {
                this.loading = true;
                this.errors = false;
                this.items = [];

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.artwork.template_id + '/artwork/' + this.artwork.id + '/usage-history';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.history.forEach((item) => {
                        vm.items.push(item);
                        vm.$root.getDateTimeFormatted(vm, 'df_' + item.id, item.created_at);
                    });
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.loading = false;
                });
            }
        }
    }
</script>
<style scoped>
    .history-items {
        height: 450px;
        overflow-y: auto;
    }
</style>
