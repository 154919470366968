<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="collection-download-modal-label">{{ collection.name }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="loading">
                <loading></loading>
                <div class="mt-3 text-center">Preparing your download</div>
            </div>

            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="(approval_status == 'awaiting-approval')" class="alert alert-info">
                    Your request to download {{ $root.contents.asset_title }}s from this collection is awaiting approval
                </div>

                <p v-if="(downloads.length > 0)">DOWNLOAD OPTIONS</p>

                <div
                    v-for="download in downloads"
                    :key="download.file_path"
                    class="form-control p-0 mb-3"
                    style="height: auto"
                >
                    <div v-if="download.name" class="row no-gutters">
                        <div class="col p-2 download-option-title">
                            {{ download.name }}
                        </div>
                    </div>
                    <div class="row no-gutters" style="min-height: 36px">
                        <div class="col-2 pt-2 pb-2 pl-2" style="text-transform: uppercase">
                            {{ download.extension }}
                        </div>
                        <div class="col-sm-4 col-5 pt-2 pb-2">
                            <span v-if="download.dimensions">
                                {{ download.dimensions }}
                            </span>&nbsp;
                        </div>
                        <div class="col-sm-4 col-5 pt-2 pb-2">
                            {{ download.num_files }} file{{ (download.num_files > 1) ? 's' : '' }} -
                            {{ $root.formatFileSize(download.size) }}
                        </div>
                        <div class="col-sm-2 text-sm-right">
                            <button
                                v-if="!downloading"
                                class="btn btn-primary download-option-button"
                                type="button"
                                v-b-tooltip.hover.left
                                title="Download"
                                :disabled="(downloading || (approval_status == 'awaiting-approval'))"
                                @click="downloadFile(download)"
                            >
                                <i class="fal fa-download"></i>
                            </button>
                            <span v-else>
                                <button
                                    class="btn btn-primary download-option-button"
                                    type="button"
                                >
                                    <i class="fal fa-spinner-third fa-spin"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>

                <div v-if="warnings" v-html="warnings" class="alert alert-warning mt-3 mb-0" role="alert"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: true,
                errors: false,
                caller_component: null,
                collection: {
                    name: '...',
                },
                downloading: false,
                downloads: [],
                approval_status: 'approved',
                warnings: false,
                in_share: null,
                email: null
            }
        },
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            initData: function (callerComponent, collection, inShare = null, email = null) {
                this.caller_component = callerComponent;
                this.collection = collection;
                this.in_share = inShare;
                this.email = email;
                this.loading = true;
                this.errors = false;
                this.downloads = [];
                this.approval_status = 'appoved';
                this.warnings = false;
                this.prepareDownload();

                var vm = this;
                setTimeout(function () {
                    if (vm.approval_status == 'requires-approval') {
                        return null;
                    }
                    $('#collection-download-modal').modal('show');
                    vm.caller_component.downloading = false;
                }, 1500);
            },
            prepareDownload: function (zipMode = 'info') {
                /**
                 * Send request to API
                 */
                let resources = [];
                let linkedFiles = [];
                if (!this.collection.id && (this.$root.selected_assets.length > 0)) {
                    resources = this.$root.selected_assets;
                    this.collection.id = 0;
                } else if (
                    (this.$route.name == 'view-collection')
                    && (this.$root.collection_selected_assets.length > 0)
                ) {
                    this.$root.collection_selected_assets.forEach((item) => {
                        (item < 0) ? linkedFiles.push(Math.abs(item)) : resources.push(item);
                    });
                } else if ((this.$route.name == 'shared') || (this.$route.name == 'shared-email')) {
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    const sharedComponent = this.$root.findComponent(appComponent, 'shared');
                    sharedComponent.items.forEach((item) => {
                        (item.id < 0) ? linkedFiles.push(Math.abs(item.id)) : resources.push(item.id);
                    });
                }
                let data = {
                    zip_mode: zipMode
                };
                if (resources.length > 0) {
                    data.resources = resources;
                }
                if (linkedFiles.length > 0) {
                    data.linked_files = linkedFiles;
                }
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/collection/' + this.collection.id +'/prepare-download';
                if (this.in_share && !this.$root.user) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    if (response.data.download_options[0].file_path) {
                        let downloadIndex = -1;
                        vm.downloads.forEach((item, index) => {
                            if (response.data.download_options[0].name == item.name) {
                                if (item.name == 'All original files') {
                                    downloadIndex = index;
                                } else if (response.data.download_options[0].dimensions == item.dimensions) {
                                    downloadIndex = index;
                                }
                            }
                        });
                        if (downloadIndex > -1) {
                            vm.downloads[downloadIndex] = response.data.download_options[0];
                            vm.downloadFile(response.data.download_options[0]);
                        }

                        return null;
                    }
                    vm.downloads = response.data.download_options;
                    if (response.data.warnings) {
                        vm.warnings = response.data.warnings.replace(/resource/g, vm.$root.contents.asset_title)
                            .replace(/\|/g, '<br>');
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#collection-download-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.status == 406) {
                        if (error.response.data.not_requested.length > 0) {
                            vm.approval_status = 'requires-approval';
                            vm.showApprovalRequestForm(error.response.data.not_requested);

                            return null;
                        }
                        vm.approval_status = 'awaiting-approval';
                        $('#collection-download-modal').modal('show');
                        vm.caller_component.downloading = false;

                        return null;
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error.replace(/resource/g, vm.$root.contents.asset_title);
                        if (error.response.data.warnings) {
                            vm.warnings = error.response.data.warnings
                                .replace(/resource/g, vm.$root.contents.asset_title).replace(/\|/g, '<br>');
                        }

                        return null;
                    }
                    vm.errors = 'Something went wrong, please try again';
                })
                .finally(() =>
                    vm.loading = null
                );
            },
            downloadFile: function (download) {
                this.errors = false;
                if (download.zip_mode) {
                    this.loading = true;
                    this.prepareDownload(download.zip_mode);

                    return null;
                }
                this.downloading = true;
                /**
                 * Send request to API
                 */
                const data = {
                    item_type: 'collection',
                    item_id: this.collection.id,
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension,
                    resources: download.resources
                };
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                if (this.in_share && !this.$root.user) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                    window.location.href = url;
                    $('#collection-download-modal').modal('hide');
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#collection-download-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() =>
                    vm.downloading = null
                );
            },
            showApprovalRequestForm: function (assetIds) {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'asset-approval-request-form');
                if (component) {
                    component.resetForm(assetIds, null, null, this);
                    this.caller_component.downloading = false;
                    $('#asset-approval-request-form-modal').modal('show');

                    setTimeout(function () {
                        $('#collection-download-modal').modal('hide');
                    }, 900);
                }
            }
        }
    }
</script>
