<template>
    <div class="table-responsive">
        <div v-if="loading"><loading></loading></div>

        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <table v-if="(!loading && !errors)" class="table table-centered table-hover">
            <thead class="thead-light">
                <tr>
                    <th scope="col" class="py-2">
                        <div class="row">
                            <div class="col-3">Shared on</div>
                            <div class="col-9">Recipients</div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td scope="row" class="pt-2" style="padding-bottom: 5px">
                        <div class="row">
                            <div class="col-3" style="padding-top: 1px">
                                {{ item.shared_at_formatted }}
                            </div>
                            <div class="col-9" v-html="getRecipientsInfo(item.recipients)"></div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: ['collection_id'],
        data () {
            return {
                loading: true,
                errors: false,
                items: []
            }
        },
        mounted () {
            this.getCollectionSharedDetails();
        },
        methods: {
            getCollectionSharedDetails: function () {
                /**
                 * Send get collection shared details request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/report/collection-shared/'
                    + this.collection_id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.items = response.data.collection_shared;
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            getRecipientsInfo: function (recipients) {
                if (recipients.length == 0) {
                    return '&nbsp;';
                }
                let recipientsBadges = [];
                recipients.forEach((recipient) => {
                    recipientsBadges.push('<span class="badge badge-info font-size-12 mr-1 mb-1">' + recipient
                        + '</span>');
                });

                return recipientsBadges.join(' ');
            }
        }
    }
</script>
