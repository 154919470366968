<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <loading v-if="loading"></loading>

        <div v-if="(!errors && !loading && (items.length == 0))" class="alert alert-warning" role="alert">
            You have no saved versions of this {{ $root.contents.template_title }}.
        </div>

        <div v-if="(!loading && (items.length > 0))">
            <div v-for="item in items" :key="item.id" class="dz-uploaded-file p-2 mb-1">
                <saved-artwork-preview
                    :key="item.id + '-' + images_loaded"
                    :item="item"
                    :image_src="image_src[item.id]"
                ></saved-artwork-preview>
                <div class="dz-filename">
                    {{ item.title }}
                    <br v-if="(item.declined || (item.finished && !item.approved) || !item.finished)">
                    <span v-if="item.declined" class="badge badge-danger font-size-11">Declined</span>
                    <span
                        v-if="(item.finished && !item.approved && !item.declined)"
                        class="badge badge-danger font-size-11"
                    >
                        Awaiting review
                    </span>
                    <span v-if="!item.finished" class="badge bg-secondary text-secondary font-size-11">Saved</span>
                </div>
                <div class="dz-size">
                    <span v-b-tooltip.hover :title="'Created on ' + formatDate(item.created_at)">
                        {{ formatDate(item.created_at) }}
                    </span>
                </div>
                <div class="dz-remove">
                    <!--<a href="#" @click.prevent="viewArtwork(item)">
                        <span v-b-tooltip.hover title="View">
                            <i class="fal fa-eye"></i>
                        </span>
                    </a>-->
                    <span v-if="item.has_usage_history">
                        <a href="#" @click.prevent="viewHistory(item)">
                            <span v-b-tooltip.hover title="History" class="text-inv-secondary">
                                <i class="fal fa-list-timeline"></i>
                            </span>
                        </a>
                    </span>
                    <span v-if="(item.finished && !item.approved && !item.declined)" class="ml-1 text-danger">
                        <span v-b-tooltip.hover title="Locked - awaiting review">
                            <i class="fal fa-lock-alt"></i>
                        </span>
                    </span>
                    <span v-if="(item.declined || item.approved || !item.finished)" class="ml-1">
                        <span
                            v-if="((opening_artwork_editor != item) || edit_artwork_duplicate)"
                            :key="'edit-artwork' + item.id"
                        >
                            <a href="#" @click.prevent="editArtwork(item)">
                                <span v-b-tooltip.hover title="Edit">
                                    <i class="fal fa-pen-paintbrush"></i>
                                </span>
                            </a>
                        </span>
                        <span v-else :key="'editing-artwork' + item.id">
                            <a href="#" @click.prevent="doNothing()">
                                <i class="fal fa-spinner-third fa-spin"></i>
                            </a>
                        </span>
                    </span>
                    <span v-if="item.finished && (item.approved || item.declined)" class="ml-1">
                        <span
                            v-if="((opening_artwork_editor != item) || !edit_artwork_duplicate)"
                            :key="'duplicate-artwork' + item.id"
                        >
                            <a href="#" @click.prevent="editArtwork(item, true)">
                                <span v-b-tooltip.hover title="Copy">
                                    <i class="fal fa-copy"></i>
                                </span>
                            </a>
                        </span>
                        <span v-else :key="'duplicating-artwork' + item.id">
                            <a href="#" @click.prevent="doNothing()">
                                <i class="fal fa-spinner-third fa-spin"></i>
                            </a>
                        </span>
                    </span>
                    <span class="ml-1">
                        <a
                            href="#"
                            v-b-tooltip.hover
                            title="Delete"
                            @click.prevent="deleteArtwork(item)"
                        >
                            <i class="fal fa-trash-alt"></i>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['asset', 'template', 'editing_artwork'],
        data () {
            return {
                errors: false,
                item_to_delete: null,
                loading: true,
                downloading: null,
                items: [],
                image_src: {},
                images_loaded: 0,
                opening_artwork_editor: null,
                edit_artwork_duplicate: false
            }
        },
        mounted () {
            if (this.template) {
                this.getArtworks(this.template);
            }
        },
        watch: {
            template: function (val) {
                if (val) {
                    this.getArtworks(val);
                }
            },
            editing_artwork: function (val) {
                if (!val && this.template) {
                    this.getArtworks(this.template);
                }
            }
        },
        methods: {
            getArtworks: function (template) {
                this.loading = true;
                this.errors = false;
                this.items = [];
                this.image_src = {};
                this.$parent.num_saved_artworks = 0;
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/template/' + template.id + '/artworks/!deleted';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.artworks.forEach((item) => {
                        vm.image_src[item.id] = null;
                        vm.getPreviewImage(item.id);
                        vm.items.push(item);
                    });
                    vm.$parent.num_saved_artworks = response.data.artworks.length;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() =>
                    vm.loading = false
                );
            },
            getPreviewImage: function (artworkId) {
                /**
                 * Send request to API
                 */
                const path = '/template/' + this.template.id + '/artwork/' + artworkId + '/preview/1/small';
                var vm = this;

                axios({
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + path,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src[response.data.artwork_id] = response.data.image_src;
                        vm.images_loaded++;
                    }
                })
                .catch(function (error) {
                    //
                });
            },
            viewArtwork: function (item) {
                if (this.opening_artwork_editor) {
                    return null;
                }
                $('#artwork-view-modal').modal('show');
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'artwork-view');
                if (component) {
                    component.reset(this.template, item);
                }
            },
            editArtwork: function (item, duplicate = false) {
                if (this.asset.replaced_at >= item.created_at) {
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: null,
                        title: '<i class="far fa-exclamation-triangle font-size-20 text-danger mr-2"></i>'
                            + (duplicate ? 'Copy' : 'Edit') + ' version',
                        text: 'The ' + this.$root.contents.template_title + ' used to create your version has '
                            + 'been updated so cannot be ' + (duplicate ? 'copied' : 'edited') + '. Please create '
                            + 'a new version.'
                    };
                    $('#message-modal').modal('show');

                    return null;
                }

                if (!('fields' in item)) {
                    if (this.opening_artwork_editor) {
                        return null;
                    }

                    this.opening_artwork_editor = item;
                    this.edit_artwork_duplicate = duplicate;

                    /**
                     * Send get artwork request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                        + '/template/' + this.template.id + '/artwork/' + item.id;
                    var vm = this;

                    axios({
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.editArtwork(response.data.artwork, vm.edit_artwork_duplicate);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                        vm.opening_artwork_editor = null;
                    });

                    return null;
                }
                this.opening_artwork_editor = null;
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.showArtworkEditor(this.asset, this.template, item, duplicate);
            },
            deleteArtwork: function (item, confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.loading = true;

                    /**
                     * Send delete artwork request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                        + '/template/' + this.template.id + '/artwork/' + item.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        // Reload artworks
                        vm.getArtworks(vm.template);
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Error deleting artwork, please try again.';
                        vm.loading = false;
                    });

                    return null;
                }
                if (this.opening_artwork_editor) {
                    return null;
                }

                this.item_to_delete = item;
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Delete saved artwork',
                    text: 'Are you sure you want to delete: ' + item.title + '?',
                    component: this,
                    action: 'delete-artwork'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deleteArtwork(this.item_to_delete, true);
            },
            formatDate: function (rawDate) {
                const date = rawDate.substr(0 ,10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            doNothing: function () {
                return null;
            },
            viewHistory: function (item) {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'artwork-history');
                if (component) {
                    component.reset(item, this.asset);
                    $('#artwork-history-modal').modal('show');
                }
            }
        }
    }
</script>
<style scoped>
    .dz-uploaded-file {
        cursor: default;
    }

    .dz-uploaded-file .dz-filename {
        width: calc(100% - 155px);
    }

    .dz-uploaded-file .dz-size {
        bottom: 10px;
        left: 103px;
    }

    .dz-uploaded-file .dz-remove {
        bottom: 8px;
    }
</style>
