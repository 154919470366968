<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                    {{ $root.client.client_name }}
                    <span v-if="hasTermsAndConds()">
                        |
                        <a href="#" data-toggle="modal" data-target="#terms-modal">
                            Terms of use<span class="d-none d-sm-inline"> and privacy policy</span>
                        </a>
                    </span>
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right d-none d-sm-block">
                        Powered by <a href="https://resourcebase.com" target="_blank">Resourcebase</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        mounted () {
            //
        },
        methods: {
            hasTermsAndConds: function () {
                if (!this.$root.contents) {
                    return false;
                }

                if (!this.$root.contents.terms_and_conditions) {
                    return false;
                }

                return true;
            }
        }
    }
</script>
