<template>
    <div>
        <div class="row">
            <div class="col-4" style="padding-top: 3px">
                <span :id="'date' + data.id">{{ formatDate(data.created_at) }}</span>
            </div>
            <div class="col" style="padding-top: 3px">
                {{ data.action }}
            </div>
            <div class="col-2" style="padding-top: 3px">
                <span v-if="data.print_order_id">{{ data.print_order_id }}</span>
            </div>
            <div class="col-2 font-size-18 contact-links">
                <a
                    v-if="data.print_order_id"
                    href="#"
                    class="float-right"
                    @click.prevent="viewOrder()"
                    v-b-tooltip
                    title="View order"
                >
                    <i class="fa-regular fa-eye"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            formatDate: function (rawDate) {
                const date = rawDate.substr(0 ,10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            viewOrder: function () {
                $('#artwork-history-modal').modal('hide');
                if (this.$root.editing_artwork) {
                    this.$parent.$parent.hideArtworkEditor();
                }
                this.$router.push({
                    name: 'my-account-print-orders-filtered',
                    params: {
                        filters: 'id::' + this.data.print_order_id
                    }
                });
            }
        }
    }
</script>
