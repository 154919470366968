<template>
    <div class="form-group">
        <div class="row">
            <div class="col pr-0">
                <label for="panel-item-quantity0" class="d-block text-center">Pack size</label>
            </div>
            <div class="col pr-0">
                <label for="panel-item-price_pounds0" class="d-block text-center">&pound;</label>
            </div>
            <div class="col pr-0">
                <label for="panel-item-price_euros0" class="d-block text-center">&euro;</label>
            </div>
            <div class="col pr-0">
                <label for="panel-item-price_dollars0" class="d-block text-center">&dollar;</label>
            </div>
            <div class="col pr-0">
                <label for="panel-item-price_pounds_pnp0" class="d-block text-center" style="white-space: nowrap">
                    &pound; P&amp;P
                </label>
            </div>
            <div class="col pr-0">
                <label for="panel-item-price_euros_pnp0" class="d-block text-center" style="white-space: nowrap">
                    &euro; P&amp;P
                </label>
            </div>
            <div class="col pr-0">
                <label for="panel-item-price_dollars_pnp0" class="d-block text-center" style="white-space: nowrap">
                    &dollar; P&amp;P
                </label>
            </div>
            <div class="col"></div>
        </div>
        <div v-for="item in items" :key="item.index" class="row mb-1">
            <div class="col pr-0">
                <input
                    v-model="item.quantity"
                    type="number"
                    :id="'panel-item-quantity' + item.index"
                    class="form-control"
                    :readonly="readonly"
                    min="0"
                    max="100000"
                >
            </div>
            <div class="col pr-0">
                <input
                    v-model="item.price_pounds"
                    type="number"
                    :id="'panel-item-price_pounds' + item.index"
                    class="form-control"
                    :readonly="readonly"
                    min="0"
                    max="100000"
                    step="0.01"
                >
            </div>
            <div class="col pr-0">
                <input
                    v-model="item.price_euros"
                    type="number"
                    :id="'panel-item-price_euros' + item.index"
                    class="form-control"
                    :readonly="readonly"
                    min="0"
                    max="100000"
                    step="0.01"
                >
            </div>
            <div class="col pr-0">
                <input
                    v-model="item.price_dollars"
                    type="number"
                    :id="'panel-item-price_dollars' + item.index"
                    class="form-control"
                    :readonly="readonly"
                    min="0"
                    max="100000"
                    step="0.01"
                >
            </div>
            <div class="col pr-0">
                <input
                    v-model="item.price_pounds_pnp"
                    type="number"
                    :id="'panel-item-price_pounds_pnp' + item.index"
                    class="form-control"
                    :readonly="readonly"
                    min="0"
                    max="100000"
                    step="0.01"
                >
            </div>
            <div class="col pr-0">
                <input
                    v-model="item.price_euros_pnp"
                    type="number"
                    :id="'panel-item-price_euros_pnp' + item.index"
                    class="form-control"
                    :readonly="readonly"
                    min="0"
                    max="100000"
                    step="0.01"
                >
            </div>
            <div class="col pr-0">
                <input
                    v-model="item.price_dollars_pnp"
                    type="number"
                    :id="'panel-item-price_dollars_pnp' + item.index"
                    class="form-control"
                    :readonly="readonly"
                    min="0"
                    max="100000"
                    step="0.01"
                >
            </div>
            <div class="col">
                <button
                    v-if="(item.index == (items.length - 1))"
                    :key="'add-button'"
                    type="button"
                    class="btn btn-secondary btn-block"
                    v-b-tooltip.hover
                    title="Add pack"
                    @click.prevent="addItem()"
                    :disabled="readonly"
                    style="margin-top: 1px"
                >
                    <i class="far fa-plus font-size-16" style="position: relative; top: 1px"></i>
                </button>
                <button
                    v-else
                    :key="'delete-button' + item.index"
                    type="button"
                    class="btn btn-secondary btn-block"
                    v-b-tooltip.hover
                    title="Delete pack"
                    @click.prevent="removeItem(item)"
                    :disabled="readonly"
                    style="margin-top: 1px"
                >
                    <i class="far fa-trash-alt font-size-16" style="position: relative; top: 1px"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['value', 'readonly', 'add_current_item'],
        data() {
            return {
                items: []
            };
        },
        mounted () {
            if (this.value) {
                this.resetItems(this.value);
            }
        },
        watch: {
            value: function (val) {
                this.resetItems(val);
            },
            add_current_item: function(val) {
                if (val) {
                    this.addItem();
                }
            }
        },
        methods: {
            resetItems: function (itemsInput) {
                if (!itemsInput) {
                    return null;
                }
                this.items = [];
                let index = 0;
                itemsInput.forEach((item) => {
                    item.index = index;
                    this.items.push(item);
                    index++;
                });
                this.items.push({ index: index, id: 'new' });
            },
            addItem: function () {
                if (this.items.length == 0) {
                    return null;
                }
                let lastIndex = this.items.length - 1;
                const lastItem = this.items[lastIndex];
                if (!lastItem.quantity) {
                    return null;
                }
                this.items.push({ index: (lastIndex + 1), id: 'new' });
                this.returnItems();
            },
            removeItem: function (itemToRemove) {
                let itemIndex = -1;
                this.items.forEach((item, index) => {
                    if (item == itemToRemove) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.items.splice(itemIndex, 1);
                    this.returnItems();
                }
            },
            returnItems: function () {
                let items = [];
                this.items.forEach((item, index) => {
                    if (index < (this.items.length - 1) || item.quantity) {
                        items.push(item);
                    }
                });
                this.$emit('input', items);
                this.$emit('change');
            }
        }
    }
</script>
