<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="collection-download-modal-label">Download all</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="loading">
                <loading></loading>
                <div class="mt-3 text-center">Preparing your download</div>
            </div>

            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="download" class="form-control p-0 mb-3" style="height: auto">
                    <div class="row no-gutters" style="min-height: 36px">
                        <div class="col-4 pt-2 pb-2 pl-2" style="text-transform: uppercase">
                            {{ download.extension }}
                        </div>
                        <div class="col-sm-3 col-4 pt-2 pb-2">
                            {{ $root.formatFileSize(download.size) }}
                        </div>
                        <div class="col-sm-3 col-4 pt-2 pb-2 pl-2">
                            {{ download.num_files }} file{{ (download.num_files > 1) ? 's' : '' }}
                        </div>
                        <div class="col-sm-2 text-sm-right">
                            <button
                                v-if="!downloading"
                                class="btn btn-primary download-option-button"
                                type="button"
                                v-b-tooltip.hover.left
                                title="Download"
                                :disabled="downloading"
                                @click="downloadFile()"
                            >
                                <i class="fal fa-download"></i>
                            </button>
                            <span v-else>
                                <button
                                    class="btn btn-primary download-option-button"
                                    type="button"
                                >
                                    <i class="fal fa-spinner-third fa-spin"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: true,
                errors: false,
                files: [],
                downloading: false,
                download: null,
                in_share: null,
                email: null
            }
        },
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            initData: function (flyteFiles, inShare = null, email = null) {
                this.loading = true;
                this.errors = false;
                this.download = null;
                this.in_share = inShare;
                this.email = email;
                this.files = [];
                flyteFiles.forEach((item) => {
                    this.files.push(item.original_id);
                });
                this.prepareDownload();

                var vm = this;
                setTimeout(function () {
                    $('#flyte-download-modal').modal('show');
                    vm.$root.share_info.flyte_downloading = false;
                }, 500);
            },
            prepareDownload: function () {
                /**
                 * Send request to API
                 */
                const data = {
                    files: this.files
                };
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/flyte/prepare-download';
                if (this.in_share && !this.$root.user) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.download = response.data.download;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#flyte-download-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;

                        return null;
                    }
                    vm.errors = 'Something went wrong, please try again';
                })
                .finally(() =>
                    vm.loading = null
                );
            },
            downloadFile: function () {
                this.errors = false;
                this.downloading = true;
                /**
                 * Send request to API
                 */
                const data = {
                    item_type: 'flyte_all',
                    item_id: 0,
                    file_path: this.download.file_path,
                    file_type: this.download.file_type,
                    download_filename: this.download.filename + '.' + this.download.extension,
                    files: this.download.files
                };
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                if (this.in_share && !this.$root.user) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                    window.location.href = url;
                    $('#flyte-download-modal').modal('hide');
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#flyte-download-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() =>
                    vm.downloading = null
                );
            }
        }
    }
</script>
