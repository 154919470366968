<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (items.length == 0))" class="alert alert-warning" role="alert">
            There are no user groups
        </div>
        <div v-if="(items.length > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-9">User group</div>
                                        <div class="col-2">Users</div>
                                        <div class="col-1">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row" :id="'user-group-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-9" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a href="#" class="text-dark" @click.prevent="toggleEditUserGroup(item)">
                                                    {{ item.name }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-2" style="padding-top: 3px">
                                            <span v-if="item.user_info">
                                                {{ item.user_info }}
                                            </span>
                                            <span v-else><loading :align="'left'" :size="1"></loading></span>
                                        </div>
                                        <div class="col-1 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleEditUserGroup(item)"
                                            >
                                                <span
                                                    v-if="(user_group_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'user-group-details' + item.id">
                                            <user-group-edit-form
                                                :data="item"
                                                :roles="roles"
                                            ></user-group-edit-form>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <div
            class="modal fade"
            id="user-group-add-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="user-group-add-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <user-group-add-form></user-group-add-form>
            </div>
        </div>

        <div
            class="modal fade"
            id="user-group-user-add-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="user-group-user-add-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <user-group-user-add-form></user-group-user-add-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'user-groups',
                loading: false,
                errors: false,
                items: [],
                roles: [],
                user_group_details_open: []
            }
        },
        mounted () {
            $('body').removeClass('login-body');

            if (!this.$root.hasPermission('users_user_groups')) {
                this.errors = 'Sorry, you do not have access to this function.';

                return false;
            }
            this.getUserGroups();
            this.getRoles();
        },
        methods: {
            getUserGroups: function () {
                this.loading = true;
                this.errors = false;
                this.items = [];
                this.user_group_details_open = [];

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/!private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.user_groups.forEach((item) => {
                        item.user_info = null;
                        vm.items.push(item);
                    });
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            getRoles: function () {
                /**
                 * Send get roles request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/settings/roles';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.roles = response.data.roles;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            toggleEditUserGroup: function (item) {
                if (this.user_group_details_open.indexOf(item.id) < 0) {
                    this.user_group_details_open.push(item.id);
                    $('#user-group-row' + item.id).addClass('highlighted');
                    $('#user-group-details' + item.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#user-group-details' + item.id).slideUp('slow', function () {
                        $('#user-group-row' + item.id).removeClass('highlighted');
                        for (var n = 0; n < vm.user_group_details_open.length; n++) { 
                            if (vm.user_group_details_open[n] == item.id) { 
                                vm.user_group_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            }
        }
    }
</script>
