<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="collaborator-add-form-modal-label">
                Add collaborator to: {{ collection_name }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-7 col-sm-6 col-12">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <a
                                class="nav-link active"
                                id="cl-users-tab"
                                data-toggle="tab"
                                href="#cl-users"
                                role="tab"
                                aria-controls="cl-users"
                                aria-selected="true"
                            >Add users</a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="cl-groups-tab"
                                data-toggle="tab"
                                href="#cl-groups"
                                role="tab"
                                aria-controls="cl-groups"
                                aria-selected="false"
                            >
                                User group
                                <span v-if="$root.hasPermission('users_own_user_groups')">
                                    <a
                                        class="ml-1"
                                        href="#"
                                        id="cl-user-groups-tooltip"
                                        @click.prevent="doNothing()"
                                    >
                                        <i class="far fa-info-circle"></i>
                                    </a>
                                    <b-tooltip
                                        target="cl-user-groups-tooltip"
                                        triggers="hover"
                                    >
                                        To set up a user group go to
                                        <router-link
                                            class="blue"
                                            :to="{ name: 'my-account' }"
                                            v-on:click.native="closeModal()"
                                        >My account</router-link>. 
                                        User groups are sets of users that can be added to a collection as collaborators
                                        in one click.
                                    </b-tooltip>
                                </span>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content pt-3 pl-3 pr-3">
                        <div
                            class="tab-pane fade show active"
                            id="cl-users"
                            role="tabpanel"
                            aria-labelledby="cl-users-tab"
                        >
                            <form v-on:submit.prevent="keywordSearch()">
                                <div class="form-group keyword">
                                    <div class="input-group">
                                        <input
                                            v-model="keyword"
                                            :type="full_email_search ? 'email' : 'text'"
                                            class="form-control"
                                            id="collaborator-user-keyword"
                                            :placeholder="full_email_search ? 'Enter user email to search'
                                                : 'Search user name or email'"
                                            :aria-label="full_email_search ? 'Enter user email to search'
                                                : 'Search user name or email'"
                                            autocomplete="off"
                                            v-on:keyup="keywordSearch(true)"
                                        >
                                        <div class="input-group-append">
                                            <button class="btn btn-primary big-icon" type="submit">
                                                <i class="fal fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        v-if="keyword"
                                        class="btn btn-link clear-keyword"
                                        @click.prevent="clearKeyword()"
                                    >
                                        <i class="far fa-times"></i>
                                    </button>
                                </div>
                            </form>

                            <div v-if="errors" class="alert alert-danger" role="alert" v-html="errors"></div>

                            <div v-else style="height: 265px; overflow-y: auto">
                                <loading v-if="searching"></loading>

                                <div v-else>
                                    <p v-if="(users.length == 0)" class="text-center">
                                        <span v-if="searched">
                                            No result found
                                        </span>
                                    </p>

                                    <table v-else class="table table-sm">
                                        <tbody>
                                            <tr
                                                scope="row"
                                                v-for="user in users"
                                                :key="user.id"
                                                :style="isUserAdded(user.id) ? '' : 'cursor: pointer'"
                                                @click="addUser(user)"
                                            >
                                                <td class="pl-2">
                                                    {{ user.first_name }} {{ user.last_name }}
                                                    <p class="small mb-1">{{ user.email }}</p>
                                                </td>
                                                <td class="pr-2 font-size-18 contact-links">
                                                    <span
                                                        v-if="isUserAdded(user.id)"
                                                        class="float-right"
                                                        v-b-tooltip.hover.left
                                                        title="Added"
                                                    >
                                                        <i class="far fa-check"></i>
                                                    </span>
                                                    <span v-else>
                                                        <span
                                                            v-if="(user.id in adding)"
                                                            class="float-right"
                                                            :key="'adding' + user.id"
                                                        >
                                                            <i class="fal fa-spinner-third fa-spin"></i>
                                                        </span>
                                                        <span
                                                            v-else
                                                            class="float-right"
                                                            :key="'add' + user.id"
                                                            v-b-tooltip.hover.left
                                                            title="Add"
                                                        >
                                                            <i class="far fa-user-plus"></i>
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="cl-groups"
                            role="tabpanel"
                            aria-labelledby="cl-groups-tab"
                            style="height: 320px; overflow-y: auto"
                        >
                            <loading v-if="user_groups_loading"></loading>
                            <div v-if="!user_groups_loading && (user_groups.length == 0)">
                                There are no groups available
                            </div>
                            <div v-if="!user_groups_loading && (user_groups.length > 0)" class="table-responsive">
                                <table class="table table-centered table-hover">
                                    <tbody>
                                        <tr v-for="item in user_groups" :key="item.id">
                                            <td scope="row" :id="'cl-user-group-row' + item.id" class="pb-0">
                                                <div class="row">
                                                    <div class="col-8" style="padding-top: 3px">
                                                        {{ item.name }}
                                                    </div>
                                                    <div class="col-4 font-size-18 contact-links">
                                                        <a
                                                            href="#"
                                                            class="float-right"
                                                            @click.prevent="toggleViewUserGroup(item)"
                                                        >
                                                            <span
                                                                v-if="(user_group_details_open.indexOf(item.id) < 0)"
                                                                :key="'close-' + item.id"
                                                            >
                                                                <i class="fa-regular fa-chevron-circle-down"></i>
                                                            </span>
                                                            <span v-else :key="'open-' + item.id">
                                                                <i class="fa-regular fa-chevron-circle-up"></i>
                                                            </span>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            class="float-right mr-2"
                                                            v-b-tooltip.hover.left
                                                            title="Add"
                                                            @click.prevent="addGroup(item)"
                                                        >
                                                            <i class="fa-regular fa-plus"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="row list-details-td mt-2">
                                                    <div
                                                        class="col-12 list-details"
                                                        :id="'cl-user-group-details' + item.id"
                                                    >
                                                        <table class="table table-sm">
                                                            <tbody>
                                                                <tr
                                                                    scope="row"
                                                                    v-for="user in item.users"
                                                                    :key="'group-user' + item.id + '_' + user.id"
                                                                >
                                                                    <td>{{ user.first_name }} {{ user.last_name }}</td>
                                                                    <td>
                                                                        <small style="overflow-wrap: anywhere">
                                                                            {{ user.email }}
                                                                        </small>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col" style="padding-top: 12px">
                    <label>Collaborators added</label>
                    <div class="form-control" style="height: 351px; overflow-y: auto">
                        <loading v-if="users_added.length == 0"></loading>
                        <div v-else>
                            <div
                                v-for="item in users_added"
                                :key="'user-' + item.user_id"
                                class="row collaborator"
                            >
                                <div class="col pt-1 pb-1">
                                    <p class="mb-0">
                                        {{ item.first_name }} {{ item.last_name }}
                                        <span
                                            v-if="item.owner"
                                            class="badge badge-primary font-size-11 ml-1"
                                            style="text-transform: uppercase"
                                        >
                                            OWNER
                                        </span>
                                    </p>
                                    <p class="small text-muted mb-0" style="overflow-wrap: anywhere">{{ item.email }}</p>
                                </div>
                                <div class="col-2 text-right font-size-18 contact-links pt-1">
                                    <a
                                        v-if="!item.owner"
                                        href="#"
                                        v-b-tooltip.hover.left
                                        title="Remove"
                                        @click.prevent="removeUser(item.user_id)"
                                    >
                                        <i class="fal fa-trash-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button pe="button" class="btn btn-primary" data-dismiss="modal">
                <i class="far fa-check mr-1"></i> Done
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                adding: {},
                collection_id: 0,
                collection_name: '',
                users_added: [],
                keyword: '',
                searching: false,
                searched: false,
                users: [],
                keypress_time: 0,
                full_email_search: false,
                user_groups_loading: false,
                user_groups: [],
                user_group_details_open: []
            }
        },
        mounted () {
            if (this.$root.client.collaborator_search_by_full_email) {
                this.full_email_search = true;
            }
        },
        methods: {
            resetForm: function (collectionId, collectionName) {
                this.errors = false;
                this.adding = {};
                this.collection_id = collectionId;
                this.collection_name = collectionName;
                this.users_added = [];
                this.keyword = '';
                this.searching = false;
                this.searched = false;
                this.users = [];
                this.getUsersAdded();
                this.getUserGroups();

                setTimeout(function () {
                    $('#collaborator-user-keyword').focus();
                }, 500);
            },
            getUsersAdded: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection-collaborators/'
                    + this.collection_id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.collaborators.forEach((item) => {
                        vm.users_added.push(item);
                    });
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#collaborator-add-form-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            keywordSearch: function (delay = false) {
                this.errors = false;
                if (this.full_email_search) {
                    // Check if valid email entered.
                    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.keyword))) {
                        if (delay) {
                            return null;
                        }
                        this.errors = 'Please enter a valid email';

                        return null;
                    }
                }
                if (!this.keyword) {
                    this.searching = false;
                    this.searched = false;

                    return null;
                }
                this.searching = true;

                if (delay) {
                    this.keypress_time = new Date();
                    var vm = this;
                    setTimeout(function () {
                        vm.keywordSearch();
                    }, 500);
                }

                const time = new Date();
                if ((time - this.keypress_time) < 500) {
                    return null;
                }

                this.users = [];
                this.searched = false;
                
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|approved|!declined|!deleted|!invited|kw::' + btoa(this.keyword);
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let users = [];
                    response.data.users.forEach((item) => {
                        if (!this.isUserAdded(item.id)) {
                            users.push(item);
                        }
                    });
                    vm.users = users;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#collaborator-add-form-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.searching = false;
                    vm.searched = true;
                });
            },
            clearKeyword: function () {
                this.keyword = '';
                this.users = [];
                this.searched = false;
                $('#collaborator-user-keyword').focus();
            },
            addUser: function (user) {
                if (this.isUserAdded(user.id) || (user.id in this.adding)) {
                    return null;
                }
                this.searching = true;
                this.adding[user.id] = true;
                this.errors = false;
                this.searching = false;

                /**
                 * Send request to API.
                 */
                const data = {
                    collection_id: this.collection_id,
                    user_id: user.id,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection-collaborator';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Make current collection active. R2-232
                    if (vm.$root.current_collection != vm.collection_id) {
                        vm.$root.current_collection = vm.collection_id;
                    }
                    const userId = response.data.user_id;
                    vm.users_added.push({
                        collaborator_id: response.data.collaborator_id,
                        user_id: userId,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        email: response.data.email,
                        owner: false
                    });
                    delete vm.adding[userId];
                    vm.removeUserFromResults(user.id);
                    vm.keyword = '';

                    if (vm.$route.name == 'view-collection') {
                        const appComponent = vm.$root.findComponent(vm.$root, 'main-app');
                        const component = vm.$root.findComponent(appComponent, 'view-collection');
                        component.getCollaborators();

                        return null;
                    }
                    vm.$router.push({ name: 'view-collection', params: { id: vm.collection_id } });
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            $('#collaborator-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    }
                });
            },
            removeUser: function (userId) {
                if (!this.isUserAdded(userId)) {
                    return null;
                }
                let itemIndex = -1;
                this.users_added.forEach((item, index) => {
                    if (item.user_id == userId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex < 0) {
                    return null;
                }
                const collaboratorId = this.users_added[itemIndex].collaborator_id;
                this.users_added.splice(itemIndex, 1);

                /**
                 * Send delete request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection-collaborator/'
                    + collaboratorId;
                var vm = this;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.$route.name == 'view-collection') {
                        const appComponent = vm.$root.findComponent(vm.$root, 'main-app');
                        const component = vm.$root.findComponent(appComponent, 'view-collection');
                        component.getCollaborators();

                        return null;
                    }
                    vm.$router.push({ name: 'view-collection', params: { id: vm.collection_id } });
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            closeModal: function () {
                $('#collaborator-add-form-modal').modal('hide');
            },
            doNothing: function () {
                return null;
            },
            getUserGroups: function () {
                this.user_groups_loading = true;
                this.user_groups = [];
                this.user_group_details_open = [];
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.user_groups.forEach((item) => {
                        item.users = [];
                        vm.getUserGroup(item.id);
                        vm.user_groups.push(item);
                    });
                })
                .catch(function (error) {
                    // Do anything?
                })
                .finally(() => {
                    vm.user_groups_loading = false;
                });
            },
            toggleViewUserGroup: function (item) {
                if (this.user_group_details_open.indexOf(item.id) < 0) {
                    this.user_group_details_open.push(item.id);
                    $('#cl-user-group-row' + item.id).addClass('highlighted');
                    $('#cl-user-group-details' + item.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#cl-user-group-details' + item.id).slideUp('slow', function () {
                        $('#cl-user-group-row' + item.id).removeClass('highlighted');
                        for (var n = 0; n < vm.user_group_details_open.length; n++) { 
                            if (vm.user_group_details_open[n] == item.id) { 
                                vm.user_group_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            getUserGroup: function (userGroupId) {
                /**
                 * Send get user group request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-group/'
                    + userGroupId;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_groups.forEach((item) => {
                        if (item.id == response.data.user_group.id) {
                            item.users = response.data.user_group.users;
                        }
                    });
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            addGroup: function (userGroup) {
                userGroup.users.forEach((item) => {
                    this.addUser(item);
                });
            },
            isUserAdded: function (userId) {
                let itemIndex = -1;
                this.users_added.forEach((item, index) => {
                    if (item.user_id == userId) {
                        itemIndex = index;
                    }
                });

                return (itemIndex > -1);
            },
            removeUserFromResults: function (userId) {
                let itemIndex = -1;
                this.users.forEach((item, index) => {
                    if (item.id == userId) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.users.splice(itemIndex, 1);
                }
            }
        }
    }
</script>
<style scoped>
    .tab-content {
        border-left: 1px solid #ced4da;
        border-right: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        height: 350px;
    }

    .form-group.keyword {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }

    .table-sm tr:hover {
        background-color: #f8f9fa;
    }

    .table-sm tr:first-child td {
        border-top: none;
    }
</style>
