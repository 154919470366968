<template>
    <div class="topnav d-lg-none">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
                <div id="main-nav-small-content" class="collapse navbar-collapse">
                    <ul class="navbar-nav">
                        <li
                            v-if="($root.hasPermission('assets_access') || $root.hasPermission('templates_access'))"
                            class="nav-item dropdown"
                        >
                            <a
                                class="nav-link dropdown-toggle arrow-none"
                                href="#"
                                id="main-nav-small-search"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Search our
                                {{ is_archive ? 'archive' : ($root.contents.asset_title + 's') }}
                                <div class="arrow-down"></div>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="main-nav-small-search">
                                <search-dropdown-content
                                    :folders_changed="$root.folders_changed"
                                    :assets_info="$root.assets_info"
                                    :small="true"
                                ></search-dropdown-content>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('assets_access') || $root.hasPermission('templates_view'))"
                            class="nav-item"
                        >
                            <router-link class="nav-link" :to="{ name: 'collections' }">
                                My collections
                            </router-link>
                        </li>
                        <li v-if="$root.hasPermission('proofs_access')" class="nav-item">
                            <a
                                class="nav-link"
                                href="#"
                                @click.prevent="$parent.viewProofs()"
                            >
                                Proof tracker
                            </a>
                        </li>
                        <li v-if="$root.hasPermission('podcodes_access')" class="nav-item">
                            <router-link class="nav-link" :to="{ name: 'pod-codes' }">
                                POD codes
                            </router-link>
                        </li>
                        <li v-if="$root.hasPermission('flyte_access')" class="nav-item">
                            <a
                                class="nav-link"
                                href="#"
                                data-toggle="modal"
                                data-target="#flyte-form-modal"
                                @click.prevent="$parent.resetFlyteForm()"
                            >
                                Flyte
                            </a>
                        </li>
                        <li v-if="$root.client.show_support_link" class="nav-item">
                            <a
                                class="nav-link"
                                href="#"
                                data-toggle="modal"
                                data-target="#email-support-modal"
                                @click.prevent="$parent.resetSupportForm()"
                            >
                                Support
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['is_archive'],
        mounted () {
            //
        }
    }
</script>
