<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (total_items == 0))" class="alert alert-warning" role="alert">
            There are no orders that match your selection
        </div>
        <div v-if="((total_items > 0) || sorting)" class="card">
            <div class="card-body">
                <div
                    class="table-responsive"
                    :style="(canUpdateStatus() ? 'padding-bottom: 170px' : '')"
                >
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                title="Sort by No."
                                                @click.prevent="sortBy('id')"
                                            >
                                                Order number
                                                <span v-if="(sorted_by == 'id_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'id_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                title="Sort by date"
                                                @click.prevent="sortBy('created')"
                                            >
                                                Date
                                                <span v-if="(sorted_by == 'created_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'created_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-sm-3 col-12 pb-sm-0 pb-1">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                :title="'Sort by ' + $root.contents.asset_title"
                                                @click.prevent="sortBy('resource')"
                                            >
                                                {{ $root.ucfirst($root.contents.asset_title) }}
                                                <span v-if="(sorted_by == 'resource_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'resource_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                title="Sort by cost"
                                                @click.prevent="sortBy('total-price')"
                                            >
                                                Cost
                                                <span v-if="(sorted_by == 'total-price_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'total-price_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-sm-2 col-12">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                title="Sort by status"
                                                @click.prevent="sortBy('approval-status')"
                                            >
                                                Status
                                                <span v-if="(sorted_by == 'approval-status_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'approval-status_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-sm-1 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row" :id="'order-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-1">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleViewOrder(item)"
                                                >
                                                    {{ item.id }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ formatDate(item.created_at) }}
                                        </div>
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.resource_title }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            <span v-if="(item.currency == 'pound')">
                                                &pound;{{ item.total_price.toFixed(2) }}
                                            </span>
                                            <span v-if="(item.currency == 'euro')">
                                                &euro;{{ item.total_price.toFixed(2) }}
                                            </span>
                                            <span v-if="(item.currency == 'dollar')">
                                                &dollar;{{ item.total_price.toFixed(2) }}
                                            </span>
                                        </div>
                                        <div
                                            class="col-2 d-none d-sm-block"
                                            :style="canUpdateStatus(item) ? '' : 'padding-top: 3px'"
                                        >
                                            {{ approvalStatus(item) }}
                                            <div v-if="canUpdateStatus(item)" class="btn-group btn-block">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ item.order_status }} {{ getStatusDate(item) }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <div v-for="option in status_options" :key="option.key">
                                                        <a
                                                            v-if="option.key != item.order_status"
                                                            class="dropdown-item"
                                                            href="#"
                                                            @click.prevent="updateStatus(item, option.key)"
                                                        >{{ option.value }} {{ getStatusDate(item, option.key) }}</a>
                                                        <a
                                                            v-else
                                                            class="dropdown-item active text-muted"
                                                            href="#"
                                                            @click.prevent="doNothing()"
                                                        >{{ option.value }} {{ getStatusDate(item, option.key) }}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-10 d-sm-none">
                                            <h5 class="font-size-14 mb-1">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleViewOrder(item)"
                                                >
                                                    {{ item.id }}
                                                </a>
                                            </h5>
                                            <p class="mb-1">{{ formatDate(item.created_at) }}</p>
                                            <p class="mb-1">{{ item.resource_title }}</p>
                                            <p class="mb-1">
                                                <span v-if="(item.currency == 'pound')">
                                                    &pound;{{ item.total_price.toFixed(2) }}
                                                </span>
                                                <span v-if="(item.currency == 'euro')">
                                                    &euro;{{ item.total_price.toFixed(2) }}
                                                </span>
                                                <span v-if="(item.currency == 'dollar')">
                                                    &dollar;{{ item.total_price.toFixed(2) }}
                                                </span>
                                            </p>
                                            {{ approvalStatus(item) }}
                                            <div v-if="canUpdateStatus(item)" class="btn-group btn-block mb-0">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    style="text-align: left"
                                                >
                                                    {{ item.order_status }}
                                                    <i
                                                        class="fas fa-chevron-down"
                                                        style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                                    ></i>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <div v-for="option in status_options" :key="option.key + '-xs'">
                                                        <a
                                                            v-if="option.key != item.order_status"
                                                            class="dropdown-item"
                                                            href="#"
                                                            @click.prevent="updateStatus(item, option.key)"
                                                        >{{ option.value }} {{ getStatusDate(item, option.key) }}</a>
                                                        <a
                                                            v-else
                                                            class="dropdown-item active text-muted"
                                                            href="#"
                                                            @click.prevent="doNothing()"
                                                        >{{ option.value }} {{ getStatusDate(item, option.key) }}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleViewOrder(item)"
                                            >
                                                <span
                                                    v-if="(order_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'order-details' + item.id">
                                            <print-order-details
                                                :data="item"
                                                :is_visible="(order_details_open.indexOf(item.id) > -1)"
                                            ></print-order-details>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <div
            v-if="canApprove()"
            class="modal fade"
            id="decline-print-order-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="decline-print-order-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <decline-print-order></decline-print-order>
            </div>
        </div>

        <div
            v-if="($route.name == 'my-account-print-orders')"
            class="modal fade"
            id="chase-print-order-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="chase-print-order-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <chase-print-order></chase-print-order>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'print-orders',
                loading: false,
                errors: false,
                items: [],
                total_items: 0,
                current_page: 1,
                last_page: 1,
                order_details_open: [],
                sorted_by: 'id_desc',
                sorting: false,
                status_options: [
                    {
                        key: 'Order placed',
                        value: 'Order placed'
                    },
                    {
                        key: 'Approved',
                        value: 'Approved'
                    },
                    {
                        key: 'On hold',
                        value: 'On hold'
                    },
                    {
                        key: 'In production',
                        value: 'In production'
                    },
                    {
                        key: 'Dispatched',
                        value: 'Dispatched'
                    },
                    {
                        key: 'Completed',
                        value: 'Completed'
                    }
                ],
                auto_open_order_details: false
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            $('body').removeClass('login-body');
            this.setStatusFilters();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            total_items: function (val) {
                this.$root.status_info.right = this.total_items + ' order';
                if (this.total_items !== 1) {
                    this.$root.status_info.right += 's';
                }
            }
        },
        methods: {
            setStatusFilters: function () {
                if (this.$route.name == 'print-orders-awaiting-approval') {
                    this.$root.print_order_filters = [];
                    this.$root.addFilter('print_order_filters', {
                        key: 'status',
                        value: 'awaiting-approval',
                        text: 'Awaiting approval',
                        filters_key: 'print_order_filters'
                    });
                    this.$router.push({ name: 'print-orders' });

                    return null;
                }

                if (this.$route.name == 'print-orders-declined') {
                    this.$root.print_order_filters = [];
                    this.$root.addFilter('print_order_filters', {
                        key: 'status',
                        value: 'declined',
                        text: 'Declined',
                        filters_key: 'print_order_filters'
                    });
                    this.$router.push({ name: 'print-orders' });

                    return null;
                }

                if (this.$route.name == 'print-orders-archived') {
                    this.$root.print_order_filters = [];
                    if (this.$root.hasPermission('printing_view_archived_orders')) {
                        this.$root.addFilter('print_order_filters', {
                            key: 'status',
                            value: 'archived',
                            text: 'Archived orders',
                            filters_key: 'print_order_filters'
                        });
                    }
                    this.$router.push({ name: 'print-orders' });

                    return null;
                }

                if (this.$route.params.filters) {
                    const params = this.$route.params.filters.split('|');
                    let redirect = false;
                    let requestedPrintOrderIds = [];
                    params.forEach((item) => {
                        if (item.substr(0, 4) == 'id::') {
                            requestedPrintOrderIds = item.substr(4).split(',');
                            redirect = true;
                        }
                    });
                    if (redirect) {
                        this.$root.print_order_filters = [];
                        requestedPrintOrderIds.forEach((printOrderId) => {
                            this.$root.addFilter('print_order_filters', {
                                key: 'keyword',
                                value: printOrderId,
                                text: '&quot;' + printOrderId + '&quot;',
                                filters_key: 'print_order_filters'
                            });
                        });
                        this.$router.push({ name: 'my-account-print-orders' });

                        return null;
                    }
                }

                this.getOrders();
            },
            getOrders: function (page = 1) {
                if (this.loading || (page < 1) || ((page > 1) && (page <= this.current_page))) {
                    return false;
                }

                let filters = ['!deleted', 'es::' + btoa('Artwork approval required'), 'sb::' + this.sorted_by];
                if (this.$route.name == 'my-account-print-orders') {
                    filters.push('own');
                }
                let filteredByArchived = false;
                let numKeywords = 0;
                this.$root.print_order_filters.forEach((item) => {
                    if (item.key == 'status') {
                        if (item.value == 'awaiting-approval') {
                            filters.push('!approved');
                            filters.push('!declined');
                        }
                        if (item.value == 'approved') {
                            filters.push('approved');
                            filters.push('!declined');
                        }
                        if (item.value == 'declined') {
                            filters.push('!approved');
                            filters.push('declined');
                        }
                        if (item.value == 'archived') {
                            filters.push('archived');
                            filteredByArchived = true;
                        }
                    }
                    if (item.key == 'approver') {
                        filters.push('ar::' + item.value);
                    }
                    if (item.key == 'keyword') {
                        filters.push('kw::' + btoa(item.value));
                        numKeywords++;
                    }
                });
                if (
                    !filteredByArchived
                    && (
                        (this.$route.name == 'my-account-print-orders')
                        || this.$root.hasPermission('printing_orders_view')
                        || this.$root.hasPermission('printing_financial_approvals')
                    )
                ) {
                    filters.push('!archived');
                }

                if (!this.$root.client.print_ordering_approval && (filters.indexOf('approved') < 0)) {
                    filters.push('approved');
                    if (filters.indexOf('!declined') < 0) {
                        filters.push('!declined');
                    }
                }

                this.current_page = page;
                this.loading = true;
                this.errors = false;
                const filtersString = (filters.length > 0) ? '/' + filters.join('|') : '';

                if (page == 1) {
                    this.items = [];
                    this.total_items = 0;
                    this.current_page = 1;
                    this.last_page = 1;
                    this.order_details_open = [];
                    this.auto_open_order_details = (numKeywords == 1);
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-orders' + filtersString
                    + '?page=' + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.total_items = response.data.orders.total;
                    vm.current_page = response.data.orders.current_page;
                    if ((vm.current_page == 1) && ('approvers' in response.data)) {
                        vm.$root.print_order_result_approvers = response.data.approvers;
                    }
                    vm.last_page = response.data.orders.last_page;
                    response.data.orders.data.forEach((item) => {
                        // Duplicate display protection - check if already added.
                        let itemIndex = -1;
                        vm.items.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            item.total_price = parseFloat(item.total_price);
                            item.supplier_can_update_status = false;
                            vm.items.push(item);
                        }
                    });
                    if (vm.auto_open_order_details && (vm.total_items == 1)) {
                        setTimeout(function () {
                            vm.toggleViewOrder(vm.items[0]);
                        }, 1000);
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.loading = false;
                    vm.sorting = false;
                });
            },
            formatDate: function (rawDate) {
                const date = rawDate.substr(0, 10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            sortBy: function (field) {
                const currentSort = this.sorted_by.split('_');
                let newSort = currentSort;
                if (currentSort[0] == field) {
                    newSort[1] = (currentSort[1] == 'asc') ? 'desc' : 'asc';
                } else {
                    newSort[0] = field;
                }
                this.sorted_by = newSort.join('_');
                this.sorting = true;
                this.getOrders();
            },
            handleScroll(event) {
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < (this.canUpdateStatus() ? 370 : 200)) {
                        this.getOrders(this.current_page + 1);
                    }
                }
            },
            toggleViewOrder: function (item) {
                if (this.order_details_open.indexOf(item.id) < 0) {
                    this.order_details_open.push(item.id);
                    $('#order-row' + item.id).addClass('highlighted');
                    $('#order-details' + item.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#order-details' + item.id).slideUp('slow', function () {
                        $('#order-row' + item.id).removeClass('highlighted');
                        for (var n = 0; n < vm.order_details_open.length; n++) { 
                            if (vm.order_details_open[n] == item.id) { 
                                vm.order_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            canUpdateStatus: function (item = null) {
                if (!item) {
                    let found = false;
                    this.items.forEach((item) => {
                        if (
                            !item.archived
                            && item.approved
                            && (
                                item.supplier_can_update_status
                                || (
                                    (this.$route.name == 'print-orders')
                                    && this.$root.hasPermission('printing_orders_edit')
                                )
                            )
                        ) {
                            found = true;
                        }
                    });

                    return found;
                }

                if (item.archived || !item.approved) {
                    return false;
                }

                if (item.supplier_can_update_status) {
                    return true;
                }

                return ((this.$route.name == 'print-orders') && this.$root.hasPermission('printing_orders_edit'));
            },
            updateStatus: function (item, status) {
                item.order_status = status;
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order/' + item.id
                    + '/update-status';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: { order_status: status},
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.updated_date_field) {
                        vm.items.forEach((item) => {
                            if (item.id == response.data.print_order_id) {
                                item[response.data.updated_date_field] = response.data.updated_date_value;
                            }
                        });
                    }
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            doNothing: function () {
                return null;
            },
            canApprove: function () {
                if (this.$route.name != 'print-orders') {
                    return false;
                }

                return this.$root.hasPermission('printing_financial_approvals');
            },
            removeOrder: function (printOrderId) {
                for (let n = (this.items.length - 1); n >= 0; n--) {
                    if (this.items[n].id == printOrderId) {
                        this.items.splice(n, 1);
                        this.total_items--;
                    }
                }
            },
            getStatusDate: function (item, status = null) {
                if (!item) {
                    return '';
                }
                if (!status) {
                    status = item.order_status;
                }
                switch (status) {
                    case 'On hold':
                        return item.on_hold_at ? this.formatDate(item.on_hold_at) : '';
                    case 'In production':
                        return item.in_production_at ? this.formatDate(item.in_production_at) : '';
                    case 'Dispatched':
                        return item.dispatched_at ? this.formatDate(item.dispatched_at) : '';
                    case 'Completed':
                        return item.completed_at ? this.formatDate(item.completed_at) : '';
                    default:
                        return '';
                }
            },
            updatePrintOrderData: function (itemId, field, value) {
                this.items.forEach((item) => {
                    if (item.id == itemId) {
                        item[field] = value;
                    }
                });
            },
            approvalStatus: function (item) {
                if (!item.approved && !item.declined) {
                    return 'Awaiting approval' + (item.archived ? ', Archived' : '');
                }
                if (item.approved) {
                    if (this.canUpdateStatus(item)) {
                        return '';
                    }

                    let status = item.order_status;
                    if (this.getStatusDate(item) != '') {
                        status += ' ' + this.getStatusDate(item);
                    }
                    if (item.archived) {
                        status += ', Archived';
                    }

                    return status;
                }

                return 'Declined' + (item.archived ? ', Archived' : '');
            }
        }
    }
</script>
