<template>
    <div class="modal-content">
        <div v-if="(data.title || !data.keep_open)" class="modal-header">
            <h5 class="modal-title" id="message-modal-label" v-html="data.title"></h5>
            <button
                v-if="!data.keep_open"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="onClose()"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div :class="modalBodyClass()">
            <div v-if="data.loading" class="mb-4">
                <loading></loading>
            </div>
            <div v-if="data.type">
                <div v-if="data.type == 'success'" class="alert alert-success" role="alert">
                    <div class="row">
                        <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                        <div class="col" v-html="data.text"></div>
                    </div>
                </div>
                <div v-if="data.type == 'error'" class="alert alert-danger" role="alert">
                    <span v-html="data.text"></span>
                </div>
                <div v-if="data.type == 'warning'" class="alert alert-warning" role="alert">
                    <span v-html="data.text"></span>
                </div>
            </div>
            <div v-else v-html="data.text"></div>
            <div v-if="data.link">
                <a href="#" @click.prevent="linkAction()">{{ data.link.link_text }}</a>
                <span v-if="data.link.additional_text">{{ data.link.additional_text }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        mounted () {
            //
        },
        methods: {
            onClose: function () {
                this.$parent.resetModalBackdrop();
                $('#message-modal > div').removeClass('modal-lg');
            },
            linkAction: function () {
                this.data.link.component.messageLinkAction(this.data.link.action);
                this.onClose();
                $('#message-modal').modal('hide');
            },
            modalBodyClass: function () {
                let className = 'modal-body';
                if (this.data.text.indexOf('img src') > 0) {
                    className += ' bg-light';
                }

                return className;
            }
        }
    }
</script>
