<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="asset-approval-request-form-modal-label">{{ modalTitle() }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col">
                        <span v-html="updated"></span>
                        <router-link
                            v-if="show_my_account_link"
                            :to="{ name: 'my-account' }"
                            v-on:click.native="closeModal()"
                        >My account</router-link><span v-if="show_my_account_link">.</span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form v-if="$root.hasPermission('assets_download')" v-on:submit.prevent="onSubmit()">
                    <div class="form-group">
                        <label
                            for="asset-approval-audience_types"
                            id="asset-approval-audience_types-label"
                            class="mandatory-label"
                        >
                            <span v-if="(asset_ids.length > 1)">
                                Who will see these {{ $root.contents.asset_title }}s? *
                            </span>
                            <span v-else>Who will see this {{ $root.contents.asset_title }}? *</span>
                        </label>
                        <searchable-option-list
                            v-model="audience_types"
                            :value="audience_types"
                            :options="audience_type_options"
                            :multiple="true"
                            field_id="asset-approval-audience_types"
                            field_class="form-control mandatory-field"
                            search_placeholder="Audience types"
                        ></searchable-option-list>
                    </div>
                    <div v-if="$root.getSetting('asset_approval_usage_notes', false)" class="form-group">
                        <label
                            for="asset-approval-usage_notes"
                            id="asset-approval-usage_notes-label"
                            class="mandatory-label"
                        >
                            Please give brief details *
                        </label>
                        <textarea
                            v-model="usage_notes"
                            id="asset-approval-usage_notes"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('asset-approval-usage_notes')"
                            v-on:change="$root.unHighlightErrors('asset-approval-usage_notes')"
                        ></textarea>
                    </div>
                    <div class="form-group">
                        <label
                            for="asset-approval-usage_specify"
                            id="asset-approval-usage_specify-label"
                            class="mandatory-label"
                        >
                            How will it be used? (Please list all uses) E.g. Social media, print, presentation etc. *
                        </label>
                        <input
                            v-model="usage_specify"
                            type="text"
                            id="asset-approval-usage_specify"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('asset-approval-usage_specify')"
                            v-on:change="$root.unHighlightErrors('asset-approval-usage_specify')"
                        >
                    </div>
                    <div class="form-group">
                        <label
                            for="asset-approval-usage_start_date"
                            id="asset-approval-usage_start_date-label"
                            class="mandatory-label"
                        >
                            When will you start using it? *
                        </label>
                        <b-form-datepicker
                            v-model="usage_start_date"
                            id="asset-approval-usage_start_date"
                            class="form-control mandatory-field"
                            start-weekday="1"
                            locale="en-GB"
                            :min="today()"
                            :hideHeader="true"
                            :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                            :disabled="updating"
                        ></b-form-datepicker>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="($root.hasPermission('assets_download') && !updated)" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button type="button" class="btn btn-primary" :key="submitKey()" @click="onSubmit()">
                        <i :class="submitIconClass()"></i> {{ submitText() }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ submitText() }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                asset_ids: [],
                audience_types: [],
                usage_start_date: null,
                usage_specify: null,
                usage_notes: null,
                audience_type_options: [
                    {
                        id: 'Internal',
                        name: 'Internal'
                    },
                    {
                        id: 'Clients',
                        name: 'Clients'
                    },
                    {
                        id: 'Suppliers',
                        name: 'Suppliers'
                    },
                    {
                        id: 'General Public',
                        name: 'General Public'
                    }
                ],
                asset_details_component: null,
                asset_download_component: null,
                collection_download_component: null,
                show_my_account_link: false
            }
        },
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            resetForm: function (
                assets,
                assetDetailsComponent = null,
                assetDownloadComponent = null,
                collectionDownloadComponent = null
            ) {
                if (!this.$root.hasPermission('assets_download')) {
                    this.errors = 'Sorry, you do not have access to this function.';

                    return false;
                }
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.asset_ids = Array.isArray(assets) ? assets : [assets.id];
                this.audience_types = [];
                const month = ('0' + (this.today().getMonth() + 1)).slice(-2);
                const day = ('0' + this.today().getDate()).slice(-2);
                this.usage_start_date = this.today().getFullYear() + '-' + month + '-' + day;
                this.usage_specify = null;
                this.usage_notes = null;
                this.asset_details_component = assetDetailsComponent;
                this.asset_download_component = assetDownloadComponent;
                this.collection_download_component = collectionDownloadComponent;
                this.show_my_account_link = false;

                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const data = {
                    app_info: {
                        app_url: this.$root.app.app_url
                    },
                    resources: this.asset_ids,
                    audience_types: this.audience_types.join(', '),
                    usage_start_date: this.usage_start_date,
                    usage_specify: this.usage_specify,
                    usage_notes: this.usage_notes
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource-access-request';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (!vm.$root.hasPermission('assets_approval_required')) {
                        vm.closeModal();
                        vm.updated = 'Submitted';
                        if (vm.asset_details_component) {
                            vm.asset_details_component.approval_status = 'approved';
                            vm.asset_details_component.getEditorImage();
                        }
                        if (vm.asset_download_component) {
                            vm.asset_download_component.approval_status = 'approved';
                            vm.asset_download_component.getDownloadOptions();
                            setTimeout(function () {
                                $('#asset-download-modal').modal('show');
                            }, 900);
                        }
                        if (vm.collection_download_component) {
                            vm.collection_download_component.approval_status = 'approved';
                            vm.collection_download_component.prepareDownload();
                            setTimeout(function () {
                                $('#collection-download-modal').modal('show');
                            }, 900);
                        }

                        return null;
                    }
                    vm.updated = 'Your request has been sent to the Administrators.<br><br>When your request has'
                        + ' been reviewed you will receive an email. You can also check the status of your'
                        + ' requests in';
                    vm.show_my_account_link = true;
                    if (vm.asset_details_component) {
                        vm.asset_details_component.approval_status = 'awaiting-approval';
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.closeModal();
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = 'The fields highlighted below are required';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    $('#asset-approval-' + key + '-label').addClass('text-danger');
                                    $('#asset-approval-' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            today: function () {
                return new Date();
            },
            modalTitle: function () {
                let editingImage = false;
                if (this.asset_details_component) {
                    if (this.asset_details_component.editing_image) {
                        editingImage = true;
                    }
                }
                if (editingImage) {
                    if (!this.$root.hasPermission('assets_approval_required')) {
                        return 'To edit this image please record how it will be used';
                    }

                    return 'Image edit requires approval';
                }
                if (!this.$root.hasPermission('assets_approval_required')) {
                    if (this.collection_download_component) {
                        return 'To download this collection please record how it will be used';
                    }

                    return 'To download this ' + this.$root.contents.asset_title + ' please record how it will be used';
                }

                return 'Download requires administrator approval';
            },
            submitIconClass: function () {
                if (!this.$root.hasPermission('assets_approval_required')) {
                    let editingImage = false;
                    if (this.asset_details_component) {
                        if (this.asset_details_component.editing_image) {
                            editingImage = true;
                        }
                    }
                    if (editingImage) {
                        return 'far fa-crop-alt mr-1';
                    }

                    return 'far fa-download mr-1';
                }

                return 'far fa-paper-plane mr-1';
            },
            submitKey: function () {
                if (!this.$root.hasPermission('assets_approval_required')) {
                    let editingImage = false;
                    if (this.asset_details_component) {
                        if (this.asset_details_component.editing_image) {
                            editingImage = true;
                        }
                    }
                    if (editingImage) {
                        return 3;
                    }

                    return 2;
                }

                return 1;
            },
            submitText: function () {
                if (!this.$root.hasPermission('assets_approval_required')) {
                    let editingImage = false;
                    if (this.asset_details_component) {
                        if (this.asset_details_component.editing_image) {
                            editingImage = true;
                        }
                    }
                    if (editingImage) {
                        return 'Edit image';
                    }

                    return 'Download';
                }

                return 'Submit request';
            },
            closeModal: function () {
                $('#asset-approval-request-form-modal').modal('hide');
            }
        }
    }
</script>
