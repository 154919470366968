<template>
    <div style="position: relative">
        <div :id="id" :style="editorStyle()"></div>
        <div class="image-editor-position-area" :style="'display: ' + (is_image_loaded ? 'block' : 'none')"></div>
        <div class="image-editor-backdrop"></div>
        <div class="image-editor-crop-custom-backdrop">
            <div class="image-editor-crop-custom-rect">
                <span class="m-2 px-1" style="display: inline-block; background-color: rgba(0, 0, 0, 0.5)"></span>
                <div class="image-editor-crop-custom-rect-grid top-third"></div>
                <div class="image-editor-crop-custom-rect-grid top-2-thirds"></div>
                <div class="image-editor-crop-custom-rect-grid left-third"></div>
                <div class="image-editor-crop-custom-rect-grid left-2-thirds"></div>
                <div class="image-editor-crop-custom-rect-resize top-left"></div>
                <div class="image-editor-crop-custom-rect-resize top"></div>
                <div class="image-editor-crop-custom-rect-resize top-right"></div>
                <div class="image-editor-crop-custom-rect-resize left"></div>
                <div class="image-editor-crop-custom-rect-resize right"></div>
                <div class="image-editor-crop-custom-rect-resize bottom-left"></div>
                <div class="image-editor-crop-custom-rect-resize bottom"></div>
                <div class="image-editor-crop-custom-rect-resize bottom-right"></div>
                <div class="image-editor-crop-custom-rect-move"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import 'tui-image-editor/dist/tui-image-editor.css';
    import * as ImageEditor from 'tui-image-editor/dist/tui-image-editor.js';
    window.ImageEditor = ImageEditor;
    export default {
        props: [ 'id', 'width', 'height', 'image', 'corner_size', 'no_crop_pixels', 'preview'],
        data () {
            return {
                editor: null,
                is_image_loaded: false,
                original_image: null,
                crop_ratio: null,
                crop_resize: null,
                crop_custom: false,
                crop_custom_mode: null,
                image_file_size: {
                    jpeg: 0,
                    png: 0
                },
                setting_image_file_sizes: false,
                image_position: {
                    left: 0,
                    top: 0,
                    width: 0,
                    height: 0,
                    zoom: 1,
                    data: null,
                    moving: false
                },
                block_position: false
            }
        },
        mounted () {
            this.editor = new window.ImageEditor(document.querySelector('#' + this.id), {
                cssMaxWidth: (this.width || 800),
                cssMaxHeight: (this.height || 500),
                selectionStyle: {
                    cornerStyle: 'circle',
                    cornerSize: (this.corner_size || 20),
                    cornerColor: 'white',
                    cornerStrokeColor: 'white',
                    transparentCorners: false,
                    lineWidth: 2,
                    borderColor: 'white',
                    rotatingPointOffset: 0
                },
                includeUI: {
                    uiSize: {
                        width: (this.width || 800),
                        height: (this.height || 500)
                    },
                    applyCropSelectionStyle: true
                },
                usageStatistics: false
            });
            if (this.image) {
                this.loadImage(this.image);
            }

            var vmImageEditor = this;
            $('.image-editor-position-area, .image-editor-crop-custom-backdrop').mousedown(function (event) {
                vmImageEditor.onMouseDownPosition('mouse', event);
            });
            $('.image-editor-position-area, .image-editor-crop-custom-backdrop').on(
                'touchstart',
                function (event) {
                    vmImageEditor.onMouseDownPosition('touch', event);
                }
            );

            $('.image-editor-backdrop').mousedown(function (event) {
                vmImageEditor.onMouseDownCustomRect('mouse', event);
            });
            $('.image-editor-backdrop').on('touchstart', function (event) {
                vmImageEditor.onMouseDownCustomRect('touch', event);
            });

            $('.image-editor-crop-custom-rect-move').mousedown(function (event) {
                vmImageEditor.onMouseDownRectMove('mouse', event);
            });
            $('.image-editor-crop-custom-rect-move').on('touchstart', function (event) {
                vmImageEditor.onMouseDownRectMove('touch', event);
            });

            $('.image-editor-crop-custom-rect-resize.top-left').mousedown(function (event) {
                vmImageEditor.onMouseDownRectResize('resize-top-left', 'nwse-resize');
            });
            $('.image-editor-crop-custom-rect-resize.top-left').on('touchstart', function (event) {
                vmImageEditor.onMouseDownRectResize('resize-top-left', 'nwse-resize');
            });

            $('.image-editor-crop-custom-rect-resize.top').mousedown(function (event) {
                vmImageEditor.onMouseDownRectResize('resize-top', 'ns-resize');
            });
            $('.image-editor-crop-custom-rect-resize.top').on('touchstart', function (event) {
                vmImageEditor.onMouseDownRectResize('resize-top', 'ns-resize');
            });

            $('.image-editor-crop-custom-rect-resize.top-right').mousedown(function (event) {
                vmImageEditor.onMouseDownRectResize('resize-top-right', 'nesw-resize');
            });
            $('.image-editor-crop-custom-rect-resize.top-right').on('touchstart', function (event) {
                vmImageEditor.onMouseDownRectResize('resize-top-right', 'nesw-resize');
            });

            $('.image-editor-crop-custom-rect-resize.right').mousedown(function (event) {
                vmImageEditor.onMouseDownRectResize('resize-right', 'ew-resize');
            });
            $('.image-editor-crop-custom-rect-resize.right').on('touchstart', function (event) {
                vmImageEditor.onMouseDownRectResize('resize-right', 'ew-resize');
            });

            $('.image-editor-crop-custom-rect-resize.bottom-right').mousedown(function (event) {
                vmImageEditor.onMouseDownRectResize('resize-bottom-right', 'nwse-resize');
            });
            $('.image-editor-crop-custom-rect-resize.bottom-right').on('touchstart', function (event) {
                vmImageEditor.onMouseDownRectResize('resize-bottom-right', 'nwse-resize');
            });

            $('.image-editor-crop-custom-rect-resize.bottom').mousedown(function (event) {
                vmImageEditor.onMouseDownRectResize('resize-bottom', 'ns-resize');
            });
            $('.image-editor-crop-custom-rect-resize.bottom').on('touchstart', function (event) {
                vmImageEditor.onMouseDownRectResize('resize-bottom', 'ns-resize');
            });

            $('.image-editor-crop-custom-rect-resize.bottom-left').mousedown(function (event) {
                vmImageEditor.onMouseDownRectResize('resize-bottom-left', 'nesw-resize');
            });
            $('.image-editor-crop-custom-rect-resize.bottom-left').on('touchstart', function (event) {
                vmImageEditor.onMouseDownRectResize('resize-bottom-left', 'nesw-resize');
            });

            $('.image-editor-crop-custom-rect-resize.left').mousedown(function (event) {
                vmImageEditor.onMouseDownRectResize('resize-left', 'ew-resize');
            });
            $('.image-editor-crop-custom-rect-resize.left').on('touchstart', function (event) {
                vmImageEditor.onMouseDownRectResize('resize-left', 'ew-resize');
            });

            $(window).mousemove(function (event) {
                vmImageEditor.onMouseMove('mouse', event);
            });
            $(window).on('touchmove', function (event) {
                vmImageEditor.onMouseMove('touch', event);
            });

            $(window).mouseup(function (event) {
                vmImageEditor.onMouseUp();
            });
            $(window).on('touchend', function (event) {
                vmImageEditor.onMouseUp();
            });
        },
        watch: {
            crop_custom_mode: function (val) {
                if (!val) {
                    $('.image-editor-crop-custom-backdrop').css('cursor', 'grab');
                }
            }
        },
        methods: {
            onMouseDownPosition: function (eventType, event) {
                if (!this.is_image_loaded || this.block_position) {
                    this.block_position = false;

                    return null;
                }
                let relX = 0;
                let relY = 0;
                if (eventType == 'touch') {
                    relX = Math.ceil(event.originalEvent.touches[0].pageX - $('.tui-image-editor').offset().left);
                    relY = Math.ceil(event.originalEvent.touches[0].pageY - $('.tui-image-editor').offset().top);
                } else {
                    relX = Math.ceil(event.originalEvent.pageX - $('.tui-image-editor').offset().left);
                    relY = Math.ceil(event.originalEvent.pageY - $('.tui-image-editor').offset().top);
                }
                this.image_position.original_left = relX;
                this.image_position.original_top = relY;
                this.image_position.moving = true;
            },
            onMouseDownCustomRect: function (eventType, event) {
                if (!this.crop_custom) {
                    return null;
                }
                if (this.crop_custom.rect) {
                    return null;
                }
                this.block_position = true;
                this.crop_custom_mode = 'resize-bottom-right';
                if (eventType == 'touch') {
                    this.updateCropCustomRect(
                        event.originalEvent.touches[0].pageX,
                        event.originalEvent.touches[0].pageY,
                        true
                    );

                    return null;
                }
                this.updateCropCustomRect(event.originalEvent.pageX, event.originalEvent.pageY, true);
            },
            onMouseDownRectMove: function (eventType, event) {
                if (!this.crop_custom) {
                    return null;
                }
                if (this.crop_custom_mode) {
                    return null;
                }
                this.block_position = true;
                let relX = 0;
                let relY = 0;
                if (eventType == 'touch') {
                    relX = Math.ceil(event.originalEvent.touches[0].pageX - $('.tui-image-editor').offset().left);
                    relY = Math.ceil(event.originalEvent.touches[0].pageY - $('.tui-image-editor').offset().top);
                } else {
                    relX = Math.ceil(event.originalEvent.pageX - $('.tui-image-editor').offset().left);
                    relY = Math.ceil(event.originalEvent.pageY - $('.tui-image-editor').offset().top);
                }
                this.crop_custom.rect.move = {
                    original_left: relX,
                    original_top: relY
                };
                this.crop_custom_mode = 'move';
            },
            onMouseDownRectResize: function (mode, cursor) {
                if (!this.crop_custom) {
                    return null;
                }
                if (this.crop_custom_mode) {
                    return null;
                }
                this.block_position = true;
                this.crop_custom_mode = mode;
                $('.image-editor-crop-custom-backdrop').css('cursor', cursor);
            },
            onMouseMove: function (eventType, event) {
                const flags = (event.buttons !== undefined) ? event.buttons : event.which;
                const mouseDown = ((flags & 1) === 1);
                if (!mouseDown) {
                    this.onMouseUp();

                    return null;
                }

                let mouseX = 0;
                let mouseY = 0;
                if (eventType == 'touch') {
                    mouseX = event.originalEvent.touches[0].pageX;
                    mouseY = event.originalEvent.touches[0].pageY;
                } else {
                    mouseX = event.originalEvent.pageX;
                    mouseY = event.originalEvent.pageY;
                }

                if (this.image_position.moving) {
                    this.moveImage(mouseX, mouseY);

                    return null;
                }

                if (!this.crop_custom) {
                    return null;
                }
                if (!this.crop_custom.rect) {
                    return null;
                }
                if (!this.crop_custom_mode) {
                    return null;
                }
                this.updateCropCustomRect(mouseX, mouseY);
            },
            onMouseUp: function () {
                if (this.image_position.moving) {
                    this.image_position.moving = false;
                    this.image_position.left = this.image_position.new_left;
                    this.image_position.top = this.image_position.new_top;
                    if (this.crop_custom) {
                        this.previewImage();

                        return null;
                    }
                    this.previewImage(false);

                    return null;
                }
                if (!this.crop_custom) {
                    return null;
                }
                if (!this.crop_custom.rect) {
                    return null;
                }
                this.crop_custom_mode = null;
            },
            updateImagePosition: function () {
                if (!this.image_position.data) {
                    this.image_position.data = new Image();
                }
                this.image_position.data.src = '';
                this.image_position.left = 0;
                this.image_position.top = 0;
                this.image_position.zoom = 1;
                var vm = this;
                setTimeout(function () {
                    const left = Math.ceil($('.tui-image-editor').offset().left - $('#' + vm.id).offset().left) - 1;
                    const top = Math.ceil($('.tui-image-editor').offset().top - $('#' + vm.id).offset().top) - 1;
                    const width = Math.ceil($('.tui-image-editor').innerWidth()) + 1;
                    const height = Math.ceil($('.tui-image-editor').innerHeight()) + 1;

                    vm.image_position.data.src = vm.getImage('png');
                    vm.image_position.width = parseInt($('.lower-canvas').attr('width'));
                    vm.image_position.height = parseInt($('.lower-canvas').attr('height'));
                    vm.image_position.editor_width = width;
                    vm.image_position.editor_height = height;

                    $('.image-editor-position-area').css({
                        'left': left + 'px',
                        'top': top + 'px',
                        'width': width + 'px',
                        'height': height + 'px'
                    });
                }, 500);
            },
            reloadEditorImage: function () {
                this.editor.loadImageFromURL(this.getImage('png'), 'Image');
            },
            moveImage: function (mouseX, mouseY) {
                const relX = Math.floor(mouseX - $('.tui-image-editor').offset().left);
                const relY = Math.floor(mouseY - $('.tui-image-editor').offset().top);
                const diffX = Math.floor(
                    (this.image_position.width / this.image_position.editor_width) * (relX - this.image_position.original_left)
                );
                const diffY = Math.floor(
                    (this.image_position.height / this.image_position.editor_height) * (relY - this.image_position.original_top)
                );
                this.image_position.new_left = this.image_position.left + diffX;
                this.image_position.new_top = this.image_position.top + diffY;
    
                const lowerCanvas = $('.lower-canvas')[0];
                const lowerCanvasContext = lowerCanvas.getContext("2d");
                lowerCanvasContext.clearRect(0, 0, this.image_position.width, this.image_position.height);
                lowerCanvasContext.drawImage(
                    this.image_position.data,
                    this.image_position.new_left,
                    this.image_position.new_top,
                    this.image_position.zoom * this.image_position.width,
                    this.image_position.zoom * this.image_position.height
                );
                this.previewImage();
            },
            zoomImage: function (zoom) {
                this.image_position.zoom *= zoom;
                const lowerCanvas = $('.lower-canvas')[0];
                const lowerCanvasContext = lowerCanvas.getContext("2d");
                lowerCanvasContext.clearRect(0, 0, this.image_position.width, this.image_position.height);
                this.image_position.left = Math.floor(
                    (this.image_position.width - (this.image_position.width * this.image_position.zoom)) / 2
                );
                this.image_position.top = Math.floor(
                    (this.image_position.height - (this.image_position.height * this.image_position.zoom)) / 2
                );
                lowerCanvasContext.drawImage(
                    this.image_position.data,
                    this.image_position.left,
                    this.image_position.top,
                    this.image_position.zoom * this.image_position.width,
                    this.image_position.zoom * this.image_position.height
                );
                
                if (this.crop_custom) {
                    this.previewImage();

                    return null;
                }
                this.previewImage(false);
            },
            previewImage: function (show = true, delay = true) {
                if (!this.preview) {
                    return null;
                }
                const previewCanvas = $('#' + this.preview);
                if (!show) {
                    previewCanvas.hide();
                    this.$emit('imagePreview', false);

                    return null;
                }
                if (delay) {
                    this.$emit('imagePreview', true);
                    var vm = this;
                    setTimeout(function () {
                        vm.previewImage(true, false);
                    }, 200);
                }
                const imageWidth = parseInt($('.lower-canvas').attr('width'));
                const imageHeight = parseInt($('.lower-canvas').attr('height'));
                let left = 0;
                let top = 0;
                let width = 0;
                let height = 0;
                if (this.crop_custom) {
                    left = Math.floor((imageWidth / this.crop_custom.editor.width) * this.crop_custom.rect.left);
                    top = Math.floor((imageHeight / this.crop_custom.editor.height) * this.crop_custom.rect.top);
                    width = Math.floor(
                        (imageWidth / this.crop_custom.editor.width) * this.crop_custom.rect.width
                    );
                    height = (this.crop_ratio == 1) ? cropWidth : Math.floor(
                        (imageHeight / this.crop_custom.editor.height) * this.crop_custom.rect.height
                    );
                } else {
                    width = imageWidth;
                    height = imageHeight;
                }
                const editorCanvas = $('.lower-canvas')[0];
                const editorCanvasContext = editorCanvas.getContext("2d");
                const canvasData = editorCanvasContext.getImageData(left, top, width, height);
                previewCanvas.attr('width', width);
                previewCanvas.attr('height', height);
                const previewCanvasContext = previewCanvas[0].getContext("2d");
                previewCanvasContext.putImageData(canvasData, 0, 0);
                previewCanvas.show();
            },
            editorStyle: function () {
                const width = (this.width || 800);
                const height = (this.height || 500);

                return 'width: ' + width + 'px; height: ' + height + 'px; background-color: #ffffff; position: relative';
            },
            loadImage: function (image) {
                this.cancelCrop();
                this.is_image_loaded = false;
                this.original_image = image;
                var vm = this;
                this.editor.loadImageFromURL(image, 'Image')
                    .then(response => {
                        vm.is_image_loaded = true;
                        vm.updateImagePosition();
                        vm.$emit('imageLoaded', response);
                    })
                    .catch(function (error) {
                        vm.$emit('error', 'Error loading image');
                    });
            },
            // https://codepen.io/jdeagle/pen/rhDkc
            imageDataToBlob: async function (imageData, fileType) {
                const binary = atob(imageData.split(',')[1]);
                await new Promise(r => setTimeout(r, 10));
                let array = [];
                for (var i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                }
                await new Promise(r => setTimeout(r, 10));

                return new Blob([new Uint8Array(array)], { type: fileType });
            },
            setImageFileSizes: async function (delay = true) {
                if (delay) {
                    if (this.setting_image_file_sizes) {
                        return null;
                    }
                    this.setting_image_file_sizes = true;
                    var vm = this;
                    setTimeout(function () {
                        vm.setImageFileSizes(false);
                    }, 500);

                    return null;
                }
                await new Promise(r => setTimeout(r, 10));
                let imageData = this.getImage('jpeg');
                await new Promise(r => setTimeout(r, 10));
                let file = await this.imageDataToBlob(imageData, 'image/jpeg');
                await new Promise(r => setTimeout(r, 10));
                this.image_file_size.jpeg = file.size;

                await new Promise(r => setTimeout(r, 10));
                imageData = this.getImage('png');
                await new Promise(r => setTimeout(r, 10));
                file = await this.imageDataToBlob(imageData, 'image/png');
                await new Promise(r => setTimeout(r, 10));
                this.image_file_size.png = file.size;

                this.setting_image_file_sizes = false;
            },
            getImageFileSize: function (fileType) {
                return this.image_file_size[fileType];
            },
            getImage: function (type = 'jpeg', download = false) {
                if (!this.is_image_loaded) {
                    return null;
                }
                const lowerCanvas = $('.lower-canvas')[0];
                let imageData = '';
                if (type == 'jpeg') {
                    imageData = lowerCanvas.toDataURL('image/jpeg', 1.0);
                } else {
                    imageData = lowerCanvas.toDataURL('image/' + type);
                }

                if (download) {
                    this.downloadImage(imageData, download);

                    return null;
                }

                return imageData.toString();
            },
            downloadImage: async function (imageData, filename) {
                if (!this.is_image_loaded) {
                    return null;
                }
                const image = await fetch(imageData);
                const imageBlob = await image.blob();
                const imageUrl = URL.createObjectURL(imageBlob);
                const link = document.createElement('a');
                link.href = imageUrl;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            undo: function (steps = 1) {
                if (!this.is_image_loaded) {
                    return null;
                }
                this.cancelCrop();
                if (!this.editor.isEmptyUndoStack()) {
                    this.editor.undo(steps);
                    this.updateImagePosition();
                }
            },
            reset: function () {
                if (!this.is_image_loaded) {
                    return null;
                }
                this.cancelCrop();
                if (!this.editor.isEmptyUndoStack()) {
                    this.editor.clearUndoStack();
                }
                if (!this.editor.isEmptyRedoStack()) {
                    this.editor.clearRedoStack();
                }
                this.editor.loadImageFromURL(this.original_image, 'Image');
                this.updateImagePosition();
            },
            redo: function (steps = 1) {
                if (!this.is_image_loaded) {
                    return null;
                }
                this.cancelCrop();
                if (!this.editor.isEmptyRedoStack()) {
                    this.editor.redo(steps);
                    this.updateImagePosition();
                }
            },
            initCrop: function (ratio = 0, width = null, height = null) {
                if (!this.is_image_loaded) {
                    return null;
                }
                if (ratio <= 0) {
                    return null;
                }
                this.cancelCrop();
                this.crop_resize = width ? { width: width, height: height } : null;
                this.initCropCustom(ratio);
            },
            initCropCustom: function (ratio = null) {
                if (!this.is_image_loaded) {
                    return null;
                }
                this.cancelCrop();
                if (!ratio) {
                    this.crop_resize = null;
                }
                this.crop_ratio = ratio;
                const left = Math.ceil($('.tui-image-editor').offset().left - $('#' + this.id).offset().left) - 1;
                const top = Math.ceil($('.tui-image-editor').offset().top - $('#' + this.id).offset().top) - 1;
                const width = Math.ceil($('.tui-image-editor').innerWidth()) + 1;
                const height = Math.ceil($('.tui-image-editor').innerHeight()) + 1;
                this.crop_custom = {
                    editor: {
                        left: left,
                        top: top,
                        width: width,
                        height: height
                    },
                    rect: null
                };
                if (ratio) {
                    $('.image-editor-crop-custom-backdrop').css({
                        'left': left + 'px',
                        'top': top + 'px',
                        'width': width + 'px',
                        'height': height + 'px'
                    });
                    let rectWidth = width - 40;
                    let rectHeight = (ratio == 1) ? rectWidth : Math.round(rectWidth / ratio);
                    if (rectHeight > (height - 40)) {
                        rectHeight = height - 40;
                        rectWidth = (ratio == 1) ? rectHeight : Math.round(rectHeight * ratio);
                    }
                    this.crop_custom.rect = {
                        left: Math.floor((width - rectWidth) / 2),
                        top: Math.floor((height - rectHeight) / 2),
                        width: rectWidth,
                        height: rectHeight
                    };
                    $('.image-editor-crop-custom-rect').css({
                        'left': this.crop_custom.rect.left + 'px',
                        'top': this.crop_custom.rect.top + 'px',
                        'width': this.crop_custom.rect.width + 'px',
                        'height': this.crop_custom.rect.height + 'px',
                    });
                    $('.image-editor-crop-custom-backdrop').show();
                    if (!this.no_crop_pixels && (rectWidth > 120) && (rectHeight > 40)) {
                        if (this.crop_resize) {
                            $('.image-editor-crop-custom-rect > span')
                                .html(this.crop_resize.width + 'x' + this.crop_resize.height + 'px');
                        } else {
                            const imageWidth = parseInt($('.upper-canvas').attr('width'));
                            const imageHeight = parseInt($('.upper-canvas').attr('height'));
                            const cropWidth = Math.floor((imageWidth / this.crop_custom.editor.width) * rectWidth);
                            const cropHeight = (ratio == 1) ? cropWidth
                                : Math.floor((imageHeight / this.crop_custom.editor.height) * rectHeight);
                            $('.image-editor-crop-custom-rect > span').html(cropWidth + 'x' + cropHeight + 'px');
                        }
                        $('.image-editor-crop-custom-rect > span').show();
                    } else {
                        $('.image-editor-crop-custom-rect > span').hide();
                    }
                    this.crop_custom_mode = null;
                    var vm = this;
                    setTimeout(function () {
                        vm.previewImage();
                    }, 500);

                    return null;
                }
                $('.image-editor-backdrop').css({
                    'left': left + 'px',
                    'top': top + 'px',
                    'width': width + 'px',
                    'height': height + 'px'
                });
                $('.image-editor-backdrop').show();
            },
            updateCropCustomRect: function (mouseX, mouseY, start = false) {
                let relX = Math.floor(mouseX - $('.tui-image-editor').offset().left);
                let relY = Math.floor(mouseY - $('.tui-image-editor').offset().top);
                if (start) {
                    $('.image-editor-crop-custom-backdrop').css({
                        'left': this.crop_custom.editor.left + 'px',
                        'top': this.crop_custom.editor.top + 'px',
                        'width': this.crop_custom.editor.width + 'px',
                        'height': this.crop_custom.editor.height + 'px'
                    });
                    $('.image-editor-crop-custom-rect').css({
                        'left': relX + 'px',
                        'top': relY + 'px',
                        'width': '1px',
                        'height': '1px'
                    });
                    $('.image-editor-crop-custom-rect > span').html('&nbsp');
                    $('.image-editor-crop-custom-rect > span').hide();
                    $('.image-editor-backdrop').hide();
                    $('.image-editor-crop-custom-backdrop').show();
                    this.crop_custom.rect = {
                        left: relX,
                        top: relY,
                        width: 1,
                        height: 1
                    }

                    return null;
                }
                let left = this.crop_custom.rect.left;
                let top = this.crop_custom.rect.top;
                let width = this.crop_custom.rect.width;
                let height = this.crop_custom.rect.height;
                if (this.crop_custom_mode == 'move') {
                    const diffX = relX - this.crop_custom.rect.move.original_left;
                    const diffY = relY - this.crop_custom.rect.move.original_top;
                    let newLeft = (left + diffX);
                    if (newLeft < 1) {
                        newLeft = 1;
                    }
                    if ((newLeft + width) >= this.crop_custom.editor.width) {
                        newLeft = this.crop_custom.editor.width - width - 1;
                    }
                    left = newLeft;
                    this.crop_custom.rect.move.original_left = relX;
                    let newTop = (top + diffY);
                    if (newTop < 1) {
                        newTop = 1;
                    }
                    if ((newTop + height) >= this.crop_custom.editor.height) {
                        newTop = this.crop_custom.editor.height - height - 1;
                    }
                    top = newTop;
                    this.crop_custom.rect.move.original_top = relY;
                } else {
                    let newRect = this.newCropRect(relX, relY);
                    if (
                        (newRect.width > 0)
                        && (newRect.height > 0)
                        && (newRect.left >= 1)
                        && (newRect.top >= 1)
                        && ((newRect.left + newRect.width) < this.crop_custom.editor.width)
                        && ((newRect.top + newRect.height) < this.crop_custom.editor.height)
                    ) {
                        left = newRect.left;
                        top = newRect.top;
                        width = newRect.width;
                        height = newRect.height;
                    }
                }
                $('.image-editor-crop-custom-rect').css({
                    'left': left + 'px',
                    'top': top + 'px',
                    'width': width + 'px',
                    'height': height + 'px'
                });
                if (!this.no_crop_pixels && (width > 120) && (height > 40)) {
                    if (this.crop_resize) {
                        $('.image-editor-crop-custom-rect > span')
                            .html(this.crop_resize.width + 'x' + this.crop_resize.height + 'px');
                    } else {
                        const imageWidth = parseInt($('.upper-canvas').attr('width'));
                        const imageHeight = parseInt($('.upper-canvas').attr('height'));
                        const cropWidth = Math.floor((imageWidth / this.crop_custom.editor.width) * width);
                        const cropHeight = Math.floor((imageHeight / this.crop_custom.editor.height) * height);
                        $('.image-editor-crop-custom-rect > span').html(cropWidth + 'x' + cropHeight + 'px');
                    }
                    $('.image-editor-crop-custom-rect > span').show();
                } else {
                    $('.image-editor-crop-custom-rect > span').hide();
                }
                this.crop_custom.rect.left = left;
                this.crop_custom.rect.top = top;
                this.crop_custom.rect.width = width;
                this.crop_custom.rect.height = height;
                this.previewImage();
            },
            applyCropCustom: function () {
                if (!this.crop_custom) {
                    return null;
                }
                const imageWidth = parseInt($('.upper-canvas').attr('width'));
                const imageHeight = parseInt($('.upper-canvas').attr('height'));
                const cropLeft = Math.floor((imageWidth / this.crop_custom.editor.width) * this.crop_custom.rect.left);
                const cropTop = Math.floor((imageHeight / this.crop_custom.editor.height) * this.crop_custom.rect.top);
                const cropWidth = Math.floor(
                    (imageWidth / this.crop_custom.editor.width) * this.crop_custom.rect.width
                );
                const cropHeight = (this.crop_ratio == 1) ? cropWidth : Math.floor(
                    (imageHeight / this.crop_custom.editor.height) * this.crop_custom.rect.height
                );
                const cropRect = {
                    left: cropLeft,
                    top: cropTop,
                    width: cropWidth,
                    height: cropHeight
                };
                this.applyCrop(cropRect);
                $('.image-editor-crop-custom-backdrop').hide();
                this.previewImage(false);
                this.crop_custom = false;
            },
            applyCrop: function (cropRect = null, delay = true) {
                if (!this.is_image_loaded) {
                    return null;
                }
                if (!cropRect) {
                    this.applyCropCustom();

                    return null;
                }
                var vm = this;
                if (
                    delay
                    && (
                        (this.image_position.left != 0)
                        || (this.image_position.top != 0)
                        || (this.image_position.zoom != 1)
                    )
                ) {
                    this.reloadEditorImage();
                    var applyCropRect = cropRect;
                    setTimeout(function () {
                        vm.applyCrop(applyCropRect, false);
                    }, 500);

                    return null;
                }
                this.editor.crop(cropRect)
                    .then(response => {
                        let data = {
                            width: Math.floor(response.newWidth),
                            height: Math.floor(response.newHeight)
                        };
                        if (vm.crop_resize) {
                            data.width = vm.crop_resize.width;
                            data.height = vm.crop_resize.height;
                            setTimeout(function () {
                                vm.resize(vm.crop_resize.width, vm.crop_resize.height, false);
                            }, 200);
                        } else {
                            vm.updateImagePosition();
                        }
                        vm.$emit('imageCropped', data);
                    });
            },
            cancelCrop: function () {
                if (!this.is_image_loaded) {
                    return null;
                }
                if (this.crop_custom) {
                    this.previewImage(false);
                    this.crop_custom = false;
                    $('.image-editor-backdrop').hide();
                    $('.image-editor-crop-custom-backdrop').hide();

                    return null;
                }
                if (this.editor.getDrawingMode() === 'CROPPER') {
                    this.editor.stopDrawingMode();
                }
            },
            resize: function (width, height, delay = true) {
                if (!this.is_image_loaded) {
                    return null;
                }
                this.cancelCrop();
                if (
                    delay
                    && (
                        (this.image_position.left != 0)
                        || (this.image_position.top != 0)
                        || (this.image_position.zoom != 1)
                    )
                ) {
                    this.reloadEditorImage();
                    var vm = this;
                    var resizeWidth = width;
                    var resizeHeight = height;
                    setTimeout(function () {
                        vm.resize(resizeWidth, resizeHeight, false);
                    }, 500);

                    return null;
                }
                this.editor.resize({ width: width, height: height });
                this.updateImagePosition();
            },
            applyFlip: function (direction, delay = true) {
                if (!this.is_image_loaded) {
                    return null;
                }
                this.cancelCrop();
                if (
                    delay
                    && (
                        (this.image_position.left != 0)
                        || (this.image_position.top != 0)
                        || (this.image_position.zoom != 1)
                    )
                ) {
                    this.reloadEditorImage();
                    var vm = this;
                    var applyFlipDirection = direction;
                    setTimeout(function () {
                        vm.applyFlip(applyFlipDirection, false);
                    }, 500);

                    return null;
                }
                if (direction == 'X') {
                    this.editor.flipX();
                    this.updateImagePosition();

                    return null;
                }
                this.editor.flipY();
                this.updateImagePosition();
            },
            rotate: function (degrees, delay = true) {
                if (!this.is_image_loaded) {
                    return null;
                }
                this.cancelCrop();
                if (
                    delay
                    && (
                        (this.image_position.left != 0)
                        || (this.image_position.top != 0)
                        || (this.image_position.zoom != 1)
                    )
                ) {
                    this.reloadEditorImage();
                    var vm = this;
                    var rotateDegrees = degrees;
                    setTimeout(function () {
                        vm.rotate(rotateDegrees, false);
                    }, 500);

                    return null;
                }
                this.editor.setAngle(degrees);
                this.updateImagePosition();
            },
            setBrightness: function (level, delay = true) {
                if (!this.is_image_loaded) {
                    return null;
                }
                this.cancelCrop();
                if (
                    delay
                    && (
                        (this.image_position.left != 0)
                        || (this.image_position.top != 0)
                        || (this.image_position.zoom != 1)
                    )
                ) {
                    this.reloadEditorImage();
                    var vm = this;
                    var setBrightnessLevel = level;
                    setTimeout(function () {
                        vm.setBrightness(setBrightnessLevel, false);
                    }, 500);

                    return null;
                }
                this.editor.applyFilter('brightness', { brightness: level });
                this.updateImagePosition();
            },
            setGreyscale: function (value = true, delay = true) {
                if (!this.is_image_loaded) {
                    return null;
                }
                this.cancelCrop();
                if (
                    delay
                    && (
                        (this.image_position.left != 0)
                        || (this.image_position.top != 0)
                        || (this.image_position.zoom != 1)
                    )
                ) {
                    this.reloadEditorImage();
                    var vm = this;
                    var setGreyscaleValue = value;
                    setTimeout(function () {
                        vm.setGreyscale(setGreyscaleValue, false);
                    }, 500);

                    return null;
                }
                if (value) {
                    this.editor.applyFilter('Grayscale');
                    this.updateImagePosition();

                    return null;
                }
                this.editor.removeFilter('Grayscale');
                this.updateImagePosition();
            },
            newCropRect: function (relX, relY) {
                let left = null;
                let top = null;
                let width = null;
                let height = null;
                const changeLeft = ['resize-top-left', 'resize-left', 'resize-bottom-left'];
                if (changeLeft.indexOf(this.crop_custom_mode) > -1) {
                    left = relX;
                    width = this.crop_custom.rect.width + (this.crop_custom.rect.left - relX);
                    if (this.crop_ratio) {
                        height = width / this.crop_ratio;
                        if (this.crop_custom_mode == 'resize-top-left') {
                            top = (this.crop_custom.rect.top + this.crop_custom.rect.height) - height;
                        }
                        if (this.crop_custom_mode == 'resize-left') {
                            top = this.crop_custom.rect.top - ((height - this.crop_custom.rect.height) / 2);
                        }
                    }
                }
                const changeTop = ['resize-top-left', 'resize-top', 'resize-top-right'];
                if (changeTop.indexOf(this.crop_custom_mode) > -1) {
                    top = relY;
                    height = this.crop_custom.rect.height + (this.crop_custom.rect.top - relY);
                    if (this.crop_ratio) {
                        width = height * this.crop_ratio;
                        if (this.crop_custom_mode == 'resize-top-left') {
                            left = (this.crop_custom.rect.left + this.crop_custom.rect.width) - width;
                        }
                        if (this.crop_custom_mode == 'resize-top') {
                            left = this.crop_custom.rect.left - ((width - this.crop_custom.rect.width) / 2);
                        }
                    }
                }
                const changeWidth = ['resize-top-right', 'resize-right', 'resize-bottom-right'];
                if (changeWidth.indexOf(this.crop_custom_mode) > -1) {
                    width = relX - this.crop_custom.rect.left;
                    if (this.crop_ratio) {
                        height = width / this.crop_ratio;
                        if (this.crop_custom_mode == 'resize-top-right') {
                            top = (this.crop_custom.rect.top + this.crop_custom.rect.height) - height;
                        }
                        if (this.crop_custom_mode == 'resize-right') {
                            top = this.crop_custom.rect.top - ((height - this.crop_custom.rect.height) / 2);
                        }
                    }
                }
                const changeHeight = ['resize-bottom-left', 'resize-bottom', 'resize-bottom-right'];
                if (changeHeight.indexOf(this.crop_custom_mode) > -1) {
                    height = relY - this.crop_custom.rect.top;
                    if (this.crop_ratio) {
                        width = height * this.crop_ratio;
                        if (this.crop_custom_mode == 'resize-bottom-left') {
                            left = (this.crop_custom.rect.left + this.crop_custom.rect.width) - width;
                        }
                        if (this.crop_custom_mode == 'resize-bottom') {
                            left = this.crop_custom.rect.left - ((width - this.crop_custom.rect.width) / 2);
                        }
                    }
                }

                if (left === null) {
                    left = this.crop_custom.rect.left;
                }
                if (top === null) {
                    top = this.crop_custom.rect.top;
                }
                if (width === null) {
                    width = this.crop_custom.rect.width;
                }
                if (height === null) {
                    height = this.crop_custom.rect.height;
                }

                return {
                    left: left,
                    top: top,
                    width: width,
                    height: height
                }
            }
        }
    }
</script>
<style scoped>
    .image-editor-backdrop {
        position: absolute;
        display: none;
        background-color: rgba(0, 0, 0, 0.5);
        cursor: crosshair;
    }

    .image-editor-crop-custom-backdrop {
        position: absolute;
        display: none;
        overflow: hidden;
        cursor: grab;
    }

    .image-editor-position-area {
        position: absolute;
        overflow: hidden;
        cursor: grab;
    }

    .image-editor-crop-custom-rect {
        position: absolute;
        border: 2px solid #ffffff;
        color: #ffffff;
        box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5);
    }

    .image-editor-crop-custom-rect-resize {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: #ffffff;
    }

    .image-editor-crop-custom-rect-resize.top-left {
        left: -10px;
        top: -10px;
        cursor: nwse-resize;
    }

    .image-editor-crop-custom-rect-resize.top {
        left: calc(50% - 10px);
        top: -10px;
        cursor: ns-resize;
    }

    .image-editor-crop-custom-rect-resize.top-right {
        right: -10px;
        top: -10px;
        cursor: nesw-resize;
    }

    .image-editor-crop-custom-rect-resize.left {
        left: -10px;
        top: calc(50% - 10px);
        cursor: ew-resize;
    }

    .image-editor-crop-custom-rect-resize.right {
        right: -10px;
        top: calc(50% - 10px);
        cursor: ew-resize;
    }

    .image-editor-crop-custom-rect-resize.bottom-left {
        left: -10px;
        bottom: -10px;
        cursor: nesw-resize;
    }

    .image-editor-crop-custom-rect-resize.bottom {
        left: calc(50% - 10px);
        bottom: -10px;
        cursor: ns-resize;
    }

    .image-editor-crop-custom-rect-resize.bottom-right {
        right: -10px;
        bottom: -10px;
        cursor: nwse-resize;
    }

    .image-editor-crop-custom-rect-grid {
        position: absolute;
        top: 0;
        left: 0;
    }

    .image-editor-crop-custom-rect-grid.top-third {
        width: 100%;
        height: 33%;
        border-bottom: 1px solid #ffffff;
    }

    .image-editor-crop-custom-rect-grid.top-2-thirds {
        width: 100%;
        height: 67%;
        border-bottom: 1px solid #ffffff;
    }

    .image-editor-crop-custom-rect-grid.left-third {
        width: 33%;
        height: 100%;
        border-right: 1px solid #ffffff;
    }

    .image-editor-crop-custom-rect-grid.left-2-thirds {
        width: 67%;
        height: 100%;
        border-right: 1px solid #ffffff;
    }

    .image-editor-crop-custom-rect-move {
        position: absolute;
        top: 10px;
        left: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        cursor: move;
    }
</style>
