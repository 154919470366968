<template>
    <div class="table-responsive mb-0">
        <table class="table table-centered table-hover">
            <thead class="thead-light">
                <tr>
                    <th scope="col">
                        <div class="row">
                            <div class="col-4">Name</div>
                            <div class="col">Email</div>
                            <div class="col-3">{{ user_group.private ? 'Telephone' : 'User role' }}</div>
                            <div v-if="!view_only" class="col-1">&nbsp;</div>
                        </div>
                    </th>
                </tr>
            </thead>
        </table>
        <div class="user-group-users" :id="'user-group-users' + user_group.id">
            <table class="table table-centered table-hover user-group-users-list">
                <tbody>
                    <tr v-for="item in items_paginated" :key="item.id">
                        <td scope="row">
                            <div class="row">
                                <div class="col-4" style="padding-top: 3px">
                                    {{ item.first_name }} {{ item.last_name }}
                                </div>
                                <div class="col" style="padding-top: 3px; overflow-wrap: anywhere">
                                    {{ item.email }}
                                </div>
                                <div class="col-3" style="padding-top: 3px">
                                    {{ user_group.private ? item.telephone : getRoleName(item.role) }}
                                </div>
                                <div v-if="!view_only" class="col-1 font-size-18 contact-links">
                                    <a
                                        href="#"
                                        class="float-right"
                                        v-b-tooltip.hover.left
                                        title="Remove"
                                        @click.prevent="removeUser(item)"
                                    >
                                        <i class="far fa-times"></i>
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'roles', 'user_group', 'view_only'],
        data () {
            return {
                items: [],
                items_per_page: 25,
                items_paginated: [],
                page: 0,
                paginating: false
            }
        },
        mounted () {
            this.items = this.data;
            this.page = 1;

            var vm = this;
            $('#user-group-users' + this.user_group.id).on('scroll', function () {
                vm.handleScroll();
            });
        },
        watch: {
            data: function (val) {
                this.items = val;
                this.items_paginated = [];
                this.page = 0;
                this.paginating = false;
            },
            page: function (val) {
                if (val) {
                    const indexStart = this.items_paginated.length;
                    let indexEnd = (val * this.items_per_page) - 1;
                    if (indexEnd > (this.items.length - 1)) {
                        indexEnd = this.items.length - 1;
                    }
                    if (indexEnd < indexStart) {
                        return null;
                    }
                    for (let index = indexStart; index <= indexEnd; index++) {
                        this.items_paginated.push(this.items[index]);
                    }

                    return null;
                }
                this.page = 1;
            },
            paginating: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.paginating = false;
                    }, 500);
                }
            }
        },
        methods: {
            handleScroll: function () {
                if (this.paginating) {
                    return null;
                }
                const scrollTop = $('#user-group-users' + this.user_group.id).scrollTop();
                const contentHeight = $('#user-group-users' + this.user_group.id + ' > table').outerHeight();
                const scrollPosition = contentHeight - (scrollTop + 400);
                if (scrollPosition < 50) {
                    this.paginating = true;
                    this.page++;
                }
            },
            removeUser: function (user, confirmed = false) {
                if (confirmed) {
                    this.$parent.users_errors = false;
                    this.$parent.users_loading = true;

                    /**
                     * Send remove user request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-group/'
                        + this.user_group.id + '/user/' + user;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.$parent.getUsers();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                        vm.$parent.users_loading = false;
                    });

                    return null;
                }

                const userGroupName = this.user_group.name ? this.user_group.name : 'the user group';
                this.$parent.$parent.$parent.confirm_delete = {
                    title: 'Remove user from user group',
                    text: 'Are you sure you want to remove ' + user.first_name + ' ' + user.last_name + ' from '
                        + userGroupName + '?',
                    component: this,
                    action: 'remove-user-' + user.id
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                if (action.indexOf('remove-user-') > -1) {
                    const userId = action.substr(12);
                    this.removeUser(userId, true);
                }
            },
            getRoleName: function (roleId) {
                if (!roleId || !this.roles) {
                    return '';
                }
                let itemIndex = -1;
                this.roles.forEach((item, index) => {
                    if (item.id == roleId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex < 0) {
                    return '';
                }

                return this.roles[itemIndex].name;
            }
        }
    }
</script>
<style scoped>
    .user-group-users {
        max-height: 400px;
        overflow-y: auto;
    }

    .user-group-users-list tbody tr:first-child td {
        border-top: none;
    }
</style>
