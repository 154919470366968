<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="resend-expired-invites-modal-label">Resend expired invites</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="sent" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>
            <div v-else>
                <p v-if="(num_expired_invites > 0)">
                    Are you sure you want to resend {{ num_expired_invites }} expired invites?
                </p>
                <p v-else>There are no expired invites at the moment</p>
            </div>
        </div>
        <div v-if="(!sent && (num_expired_invites > 0))" class="modal-footer">
            <span v-if="!sending">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-paper-plane mr-1"></i> Send
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Send</span>
                </button>
            </span>
            <button type="button" class="btn btn-light ml-2" data-dismiss="modal" :disabled="sending">
                <i class="far fa-times mr-1"></i> Cancel
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                num_expired_invites: 0
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function () {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.num_expired_invites = this.$root.num_expired_invites;
            },
            onSubmit: function () {
                if (this.num_expired_invites == 0) {
                    return null;
                }
                this.errors = false;
                this.sending = true;

                /**
                 * Send resend expired invites request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/user/0/resend-invite';
                const data = {
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.sent = response.data.message;

                    // Reload user list and hide Resend expired invites button.
                    const component = vm.$root.findComponent(vm.$parent, 'new-users');
                    if (component) {
                        component.getUsers();
                    }
                    vm.$root.num_expired_invites = 0;

                    // Close after 4 seconds automatically.
                    setTimeout(function () {
                        $('#resend-expired-invites-modal').modal('hide');
                    }, 4000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() =>
                    vm.sending = false
                );
            }
        }
    }
</script>
