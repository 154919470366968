<template>
    <div :style="'position: relative; height: ' + height + 'px; overflow: hidden'">
        <div
            :style="'position: relative; top: -8px; width: calc(100% + 20px); height: ' + (height + 18)
                + 'px; left: -12px'"
        >
            <apexchart type="area" :height="(height + 18)" :options="chart_options" :series="series"></apexchart>
        </div>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';
    export default {
        props: ['height', 'data'],
        components: {
            'apexchart': VueApexCharts,
        },
        data () {
            return {
                series: [{
                    name: 'series1',
                    data: this.data
                }],
                chart_options: {
                    chart: {
                        height: this.height + 18,
                        type: 'area',
                        toolbar: {
                            show: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    fill: {
                        colors: [this.$root.client.third_colour],
                        gradient: {
                            gradientToColors: ['#ffffff']
                        }
                    },
                    stroke: {
                        curve: 'smooth',
                        colors: [this.$root.client.third_colour],
                        width: 1.6
                    },
                    markers: {
                        colors: [this.$root.client.third_colour]
                    },
                    xaxis: {
                        labels: {
                            show: false
                        },
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        tooltip: {
                            enabled: false
                        }
                    },
                    yaxis: {
                        labels: {
                            show: false
                        }
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: false
                            }
                        },
                        yaxis: {
                            lines: {
                                show: false
                            }
                        }
                    },
                    tooltip: {
                        x: {
                            show: false
                        },
                        y: {
                            formatter: undefined,
                            title: {
                                formatter: (seriesName) => '',
                            }
                        },
                        marker: {
                            show: false,
                        }
                    }
                }
            }
        },
        mounted () {
            //
        }
    }
</script>

<!--
https://github.com/apexcharts/vue-apexcharts
https://apexcharts.com/vue-chart-demos/radialbar-charts/stroked-gauge/
-->