<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="asset-de-archive-modal-label">
                De-archive {{ $root.contents.asset_title }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="sent" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="((asset.subfolders.length > 0) && !asset.has_live_subfolder)">
                    You cannot de-archive this {{ $root.contents.asset_title }} as its
                    <span v-if="asset.folder_archived">{{ $root.contents.folder_title }} and</span>
                    {{ $root.contents.subfolder_title }} is archived.
                </div>
                <div v-if="(((asset.subfolders.length == 0) || asset.has_live_subfolder) && asset.folder_archived)">
                    You cannot de-archive this {{ $root.contents.asset_title }} as its {{ $root.contents.folder_title }}
                    is archived.
                </div>
                <form v-if="(!asset.folder_archived && (asset.has_live_subfolder || (asset.subfolders.length == 0)))">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>
                    <p>
                        Are you sure you want to de-archive: {{ asset.title }}?
                    </p>
                </form>
            </div>
        </div>
        <div
            v-if="(!sent && !asset.folder_archived && (asset.has_live_subfolder || (asset.subfolders.length == 0)))"
            class="modal-footer"
        >
            <span v-if="!sending">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-archive mr-1"></i> De-archive
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">De-archive</span>
                </button>
            </span>
            <button type="button" class="btn btn-light ml-2" data-dismiss="modal" :disabled="sending">
                <i class="far fa-times mr-1"></i> Cancel
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                asset: {
                    title: '',
                    subfolders: []
                },
                list_component: null
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (asset, listComponent) {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.asset = asset;
                this.list_component = listComponent;
            },
            onSubmit: function () {
                this.errors = false;
                this.sending = true;

                /**
                 * Send de-archive asset request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/'
                    + this.asset.id + '/de-archive';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: null,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.sent = vm.$root.ucfirst(vm.$root.contents.asset_title) + ' has been de-archived.';
                    vm.$root.folders_changed = true;

                    // Close after 2 seconds automatically
                    setTimeout(function () {
                        $('#asset-de-archive-modal').modal('hide');
                    }, 2000);

                    // Reload assets
                    const pages = [
                        'assets',
                        'assets-filtered',
                        'view-collection',
                        'asset-reviews',
                        'shared',
                        'asset-approvals'
                    ];
                    if (pages.indexOf(vm.list_component.component_name) > -1) {
                        vm.list_component.getAssets();

                        return null;
                    }
                    vm.list_component.view_asset = null;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#asset-de-archive-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    // show error
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = 'Something went wrong, please try again';
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            }
        }
    }
</script>
