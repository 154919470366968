<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="template-options-modal-label">Output options</h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div v-if="no_profile_error">
                <a
                    href="#"
                    data-toggle="modal"
                    data-target="#email-support-modal"
                    @click.prevent="resetSupportForm('no-profile')"
                >
                    Please click here to contact support to get a {{ $root.contents.profile_title }}
                    assigned to your account
                </a>
            </div>

            <loading v-if="loading"></loading>

            <div v-if="(!errors && !loading)">
                <div v-if="template.output_view_only" class="alert alert-warning" role="alert">
                    This {{ $root.contents.template_title }} is view only
                </div>

                <div v-else>
                    <div v-if="asset.suspended" class="alert alert-danger" role="alert">
                        <div class="row">
                            <div class="col-1" style="max-width: 30px">
                                <i
                                    class="far fa-exclamation-triangle font-size-16"
                                    style="position: relative; top: 4px"
                                ></i>
                            </div>
                            <div class="col">
                                {{ $root.ucfirst($root.contents.template_title) }} suspended.
                                <span v-if="$root.hasPermission('templates_manage_suspension')">
                                    Click GO LIVE on the {{ $root.contents.template_title }} details.
                                </span>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <div v-if="artwork">
                            <div v-if="artwork.pdf_preview" class="form-group">
                                <span v-if="(downloaded == 'pdf_preview')">
                                    <button
                                        :key="'downloaded-artwork-preview-button'"
                                        class="btn btn-secondary btn-block"
                                        type="button"
                                    >
                                        <i class="fal fa-check mr-1"></i> Downloaded
                                    </button>
                                </span>
                                <span v-else>
                                    <span v-if="(downloading != 'pdf_preview')">
                                        <button
                                            :key="'download-artwork-preview-button'"
                                            class="btn btn-secondary btn-block"
                                            type="button"
                                            @click="downloadArtwork('pdf_preview')"
                                            :disabled="(!artwork || downloading)"
                                        >
                                            <i class="far fa-download mr-1"></i> PDF proof
                                        </button>
                                    </span>
                                    <span v-else>
                                        <button
                                            :key="'downloading-artwork-preview-button'"
                                            class="btn btn-secondary btn-block"
                                            type="button"
                                        >
                                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                            <span style="opacity: 0.5">Downloading</span>
                                        </button>
                                    </span>
                                </span>
                                <hr>
                            </div>
                        </div>
                        <div v-if="template.output_download" class="form-group">
                            <span v-if="(downloaded == 'pdf')">
                                <button
                                    :key="'downloaded-artwork-button'"
                                    class="btn btn-primary btn-block"
                                    type="button"
                                >
                                    <i class="fal fa-check mr-1"></i> Downloaded
                                </button>
                            </span>
                            <span v-else>
                                <span v-if="(downloading != 'pdf')">
                                    <button
                                        :key="'download-artwork-button'"
                                        class="btn btn-primary btn-block"
                                        type="button"
                                        @click="downloadArtwork('pdf')"
                                        :disabled="(!artwork || downloading)"
                                    >
                                        <i class="far fa-download mr-1"></i>
                                        <span v-if="(asset.file_type == 'application/pdf')">Download artwork PDF</span>
                                        <span v-else style="text-transform: uppercase">{{ asset.file_extension }}</span>
                                    </button>
                                </span>
                                <span v-else>
                                    <button
                                        :key="'downloading-artwork-button'"
                                        class="btn btn-primary btn-block"
                                        type="button"
                                    >
                                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                        <span style="opacity: 0.5">Downloading</span>
                                    </button>
                                </span>
                            </span>
                        </div>
                        <div v-if="template.output_desktop" class="form-group">
                            <span v-if="(downloaded == 'pdf_ncm')">
                                <button
                                    :key="'downloaded-desktop-print-button'"
                                    class="btn btn-primary btn-block"
                                    type="button"
                                >
                                    <i class="fal fa-check mr-1"></i> Downloaded
                                </button>
                            </span>
                            <span v-else>
                                <span v-if="(downloading != 'pdf_ncm')">
                                    <button
                                        :key="'desktop-print-button'"
                                        class="btn btn-primary btn-block"
                                        type="button"
                                        @click="downloadArtwork('pdf_ncm')"
                                        :disabled="(!artwork || downloading)"
                                    >
                                        <i class="far fa-print mr-1"></i> Download desktop print
                                    </button>
                                </span>
                                <span v-else>
                                    <button
                                        :key="'downloading-desktop-print-button'"
                                        class="btn btn-primary btn-block"
                                        type="button"
                                    >
                                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                        <span style="opacity: 0.5">Downloading</span>
                                    </button>
                                </span>
                            </span>
                        </div>
                        <div v-if="(template.output_order && $root.isFeatured('printing'))" class="form-group">
                            <button class="btn btn-primary btn-block" type="button" @click="orderPrint()">
                                <i class="far fa-shopping-cart mr-1"></i> Order item
                            </button>
                        </div>
                        <div v-if="template.output_email" class="form-group">
                            <button class="btn btn-primary btn-block" type="button" @click="resetShareArtwork()">
                                <i class="far fa-envelope mr-1"></i> Email artwork
                            </button>
                        </div>
                        <div v-if="(template.output_image_only && artwork)">
                            <div v-if="!artwork.images">
                                There are no available output options
                            </div>
                            <div v-else>
                                <div v-if="artwork.images.length == 0">
                                    There are no available output options
                                </div>
                                <div v-else>
                                    <div v-for="item in artwork.images" :key="item.file_path" class="form-group">
                                        <span v-if="(downloading != item.file_path)">
                                            <button
                                                :key="'download-' + item.file_path"
                                                class="btn btn-primary btn-block"
                                                type="button"
                                                @click="downloadArtwork(item.file_path)"
                                                :disabled="(!artwork || downloading)"
                                            >
                                                <i class="far fa-download mr-1"></i>
                                                <span v-if="(item.extension == 'png')">PNG</span>
                                                <span v-else>JPEG</span>
                                                {{ $root.formatFileSize(item.size) }}
                                            </button>
                                        </span>
                                        <span v-else>
                                            <button
                                                :key="'downloading-' + item.file_path"
                                                class="btn btn-primary btn-block"
                                                type="button"
                                            >
                                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                <span style="opacity: 0.5">Downloading</span>
                                            </button>
                                        </span>
                                    </div>
                                    <div class="text-center small text-muted">
                                        NB: Smaller JPEG files will reduce the image quality
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                asset: null,
                errors: false,
                loading: true,
                template: null,
                artwork: null,
                caller_component: null,
                downloading: null,
                downloaded: null,
                no_profile_error: false
            }
        },
        mounted () {
            //
        },
        watch: {
            downloaded: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.downloaded = null;
                    }, 2000);
                }
            }
        },
        methods: {
            reset: function (asset, template = null, artwork = null, callerComponent = null) {
                this.loading = true;
                this.asset = asset;
                this.template = template;
                this.artwork = artwork;
                this.caller_component = callerComponent;
                this.errors = false;
                this.no_profile_error = false;
                if (!this.template) {
                    this.getTemplate(asset.id);

                    return null;
                }
                var vm = this;
                setTimeout(function () {
                    vm.loading = false;
                }, 500);
            },
            getTemplate: function (assetId) {
                /**
                 * Send get template details request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + assetId;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.template = response.data.template;
                    if (!vm.template.output_view_only && vm.template.editable && !vm.asset.suspended) {
                        vm.$parent.showArtworkEditor(vm.asset, vm.template);

                        return null;
                    }
                    $('#template-options-modal').modal('show');
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user.
                        $('#template-options-modal').modal('hide');
                        vm.$root.doLogout();

                        return null;
                    }
                    if (error.response.status == 406) {
                        vm.errors = 'You do not have a ' + vm.$root.contents.profile_title + ' assigned.';
                        vm.no_profile_error = true;
                    } else {
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    }
                    $('#template-options-modal').modal('show');
                })
                .finally(() => {
                    vm.loading = false;
                    if (vm.caller_component) {
                        vm.caller_component.showing_template_options = false;
                    }
                });
            },
            downloadArtwork: function (file) {
                if (this.isApprovalRequired() && (file != 'pdf_preview')) {
                    this.resetArtworkApprovalRequestForm();

                    return null;
                }
                if (this.downloading) {
                    return null;
                }
                this.downloading = file;
                if ((file == 'pdf_ncm') && !this.template.crop_marks) {
                    file = 'pdf';
                }
                let type = (file == 'pdf_preview') ? 'artwork_pdf_preview' : 'artwork_pdf';
                let download = null;
                if ((file == 'pdf') || (file == 'pdf_ncm') || (file == 'pdf_preview')) {
                    if (this.artwork[file]) {
                        download = this.artwork[file];
                    }
                } else {
                    type = 'artwork_image';
                    if (this.artwork.images) {
                        this.artwork.images.forEach((item) => {
                            if (item.file_path == file) {
                                download = item;
                            }
                        });
                    }
                }
                if (!download) {
                    return null;
                }
                this.errors = false;

                /**
                 * Send request to API
                 */
                const data = {
                    item_type: type,
                    item_id: this.artwork.id,
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                    window.location.href = url;

                    vm.downloaded = vm.downloading.toString();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() =>
                    vm.downloading = null
                );
            },
            orderPrint: function () {
                const component = this.$root.findComponent(this.$parent, 'print-order-form');
                if (component) {
                    component.resetForm(this.asset, this.template, this.artwork);
                    $('#template-options-modal').modal('hide');
                    setTimeout(function () {
                        $('#print-order-modal').modal('show');
                    }, 500);
                }
            },
            resetShareArtwork: function () {
                if (this.isApprovalRequired()) {
                    this.resetArtworkApprovalRequestForm();

                    return null;
                }
                const component = this.$root.findComponent(this.$parent, 'artwork-share');
                if (component) {
                    component.reset(this.artwork.id);
                    $('#template-options-modal').modal('hide');
                    setTimeout(function () {
                        $('#artwork-share-modal').modal('show');
                    }, 500);
                }
            },
            isApprovalRequired: function () {
                if (this.template.approval != 'Y') {
                    return false;
                }
                if (!this.artwork) {
                    return true;
                }
                if (!this.artwork.approved) {
                    return true;
                }

                return false;
            },
            resetArtworkApprovalRequestForm: function () {
                const component = this.$root.findComponent(this.$parent, 'artwork-approval-request-form');
                if (component) {
                    $('#template-options-modal').modal('hide');
                    component.resetForm();
                    setTimeout(function () {
                        $('#artwork-approval-request-modal').modal('show');
                    }, 500);
                }
            },
            resetSupportForm: function (messageId) {
                $('#template-options-modal').modal('hide');
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'email-support');
                if (component) {
                    if (messageId == 'no-profile') {
                        component.resetForm(
                            'users',
                            'Please assign a ' + this.$root.contents.profile_title + ' to my account'
                        );
                    }
                }
            }
        }
    }
</script>
