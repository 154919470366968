<template>
    <div>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <form v-on:submit.prevent="onSubmit()">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="form-group">
                        <label
                            :for="'name' + price_panel_type.id"
                            :id="'name-label' + price_panel_type.id"
                            class="mandatory-label"
                        >
                            Price panel type name *
                        </label>
                        <input
                            v-model="price_panel_type.name"
                            type="text"
                            :id="'name' + price_panel_type.id"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('name', price_panel_type.id)"
                            v-on:change="$root.unHighlightErrors('name', price_panel_type.id)"
                        >
                    </div>

                    <span v-if="!updating">
                        <button
                            type="submit"
                            class="btn btn-primary mr-2"
                            :disabled="updating"
                        >
                            <i class="far fa-save mr-1"></i> Save
                        </button>
                    </span>
                    <span v-else>
                        <button type="button" class="btn btn-primary mr-2">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Save</span>
                        </button>
                    </span>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        :disabled="updating"
                        @click.prevent="deletePricePanelType()"
                    >
                        <i class="far fa-trash-alt mr-1"></i> Delete
                    </button>
                </form>
            </div>
            <div class="col-lg-9 col-md-8 col-sm-6">
                <label>Price panels</label>

                <div v-if="panels_loading"><loading :align="'left'"></loading></div>

                <div
                    v-if="panels_errors"
                    v-html="panels_errors"
                    class="alert alert-danger"
                    role="alert"
                ></div>

                <div v-if="(!panels_errors && !panels_loading)">
                    <div
                        v-if="!hasPricePanels()"
                        class="alert alert-warning"
                        role="alert"
                    >
                        There are no price panels with this type
                    </div>
                    <price-panels
                        v-if="hasPricePanels()"
                        :data="price_panel_type.price_panels"
                        :suppliers="suppliers"
                    ></price-panels>

                    <div class="text-right">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-toggle="modal"
                            data-target="#price-panel-add-form-modal"
                            @click.prevent="resetPricePanelAddForm()"
                        >
                            <i class="far fa-plus mr-1"></i> Add price panel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'suppliers'],
        data () {
            return {
                errors: false,
                updating: false,
                price_panel_type: {},
                panels_loading: false,
                panels_errors: false
            }
        },
        mounted () {
            if (this.data) {
                this.price_panel_type = this.data;
            }
        },
        watch: {
            data: function (val) {
                this.price_panel_type = val;
            }
        },
        methods: {
            onSubmit: function () {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.errors = false;
                this.updating = true;
                /**
                 * Send update price panel type request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/price-panel-type/'
                    + this.price_panel_type.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: vm.price_panel_type,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // show confirmation message
                    vm.$parent.$parent.message = {
                        type: 'success',
                        title: 'Update price panel type',
                        text: response.data.message
                    };
                    $('#message-modal').modal('show');

                    // close after 2 seconds automatically
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = 'Something went wrong, please try again';
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#' + key + '-label' + vm.price_panel_type.id).addClass('text-danger');
                                $('#' + key + vm.price_panel_type.id).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            getPricePanels: function () {
                this.panels_errors = false;
                this.panels_loading = true;

                /**
                 * Send get price panels request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/price-panels/pt::'
                    + this.price_panel_type.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.price_panel_type.price_panels = response.data.price_panel_types
                        ? response.data.price_panel_types[0].price_panels : [];
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    vm.panels_errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.panels_loading = false;
                });
            },
            resetPricePanelAddForm: function () {
                const component = this.$root.findComponent(this.$parent, 'price-panel-add-form');
                if (component) {
                    component.resetForm(this.price_panel_type, this);
                }
            },
            hasPricePanels: function () {
                if (!this.price_panel_type.price_panels) {
                    return false;
                }

                return (this.price_panel_type.price_panels.length > 0);
            },
            deletePricePanelType: function (confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.loading = true;

                    /**
                     * Send delete price panel type request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/price-panel-type/'
                        + this.price_panel_type.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.$parent.getPricePanels();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        vm.panels_errors = error.response.data.error || 'Something went wrong, please try again';
                    });

                    return null;
                }

                if (this.updating) {
                    return null;
                }

                this.$parent.$parent.confirm_delete = {
                    title: 'Delete price panel type',
                    text: 'Are you sure you want to delete: ' + this.price_panel_type.name + '?'
                        + '<br><br>Note - all price panels within this type will be deleted as well.',
                    component: this,
                    action: 'delete-panel-type'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deletePricePanelType(true);
            }
        }
    }
</script>
