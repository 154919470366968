<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="proof-user-add-form-modal-label">Add members to team</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClose()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-7 col-sm-6 col-12">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <a
                                class="nav-link active"
                                id="pu-users-tab"
                                data-toggle="tab"
                                href="#pu-users"
                                role="tab"
                                aria-controls="pu-users"
                                aria-selected="true"
                                @click="errors = false"
                            >Add users</a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="pu-groups-tab"
                                data-toggle="tab"
                                href="#pu-groups"
                                role="tab"
                                aria-controls="pu-groups"
                                aria-selected="false"
                                @click="errors = false"
                            >
                                User group
                                <span v-if="$root.hasPermission('users_own_user_groups')">
                                    <a
                                        class="ml-1"
                                        href="#"
                                        id="user-groups-tooltip"
                                        @click.prevent="doNothing()"
                                    >
                                        <i class="far fa-info-circle"></i>
                                    </a>
                                    <b-tooltip
                                        target="user-groups-tooltip"
                                        triggers="hover"
                                    >
                                        To set up a user group go to
                                        <router-link
                                            class="blue"
                                            :to="{ name: 'my-account' }"
                                            v-on:click.native="closeModal()"
                                        >My account</router-link>. 
                                        User groups are sets of users that can be added to a proof team in one click.
                                    </b-tooltip>
                                </span>
                            </a>
                        </li>
                        <li v-if="canInviteGuest()" class="nav-item">
                            <a
                                class="nav-link"
                                id="pu-invite-tab"
                                data-toggle="tab"
                                href="#pu-invite"
                                role="tab"
                                aria-controls="pu-invite"
                                aria-selected="false"
                                @click="errors = false"
                            >Invite guest user</a>
                        </li>
                    </ul>
                    <div class="tab-content pt-3 pl-3 pr-3">
                        <div
                            class="tab-pane fade show active"
                            id="pu-users"
                            role="tabpanel"
                            aria-labelledby="pu-users-tab"
                        >
                            <form v-on:submit.prevent="keywordSearch()">
                                <div class="form-group keyword">
                                    <div class="input-group">
                                        <input
                                            v-model="keyword"
                                            type="text"
                                            class="form-control"
                                            id="proof-user-keyword"
                                            placeholder="Search name or email"
                                            aria-label="Search name or email"
                                            autocomplete="off"
                                            v-on:keyup="keywordSearch(true)"
                                        >
                                        <div class="input-group-append">
                                            <button class="btn btn-primary big-icon" type="submit">
                                                <i class="fal fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        v-if="keyword"
                                        class="btn btn-link clear-keyword"
                                        @click.prevent="clearKeyword()"
                                    >
                                        <i class="far fa-times"></i>
                                    </button>
                                </div>
                            </form>

                            <div v-if="errors" class="alert alert-danger" role="alert" v-html="errors"></div>

                            <div v-else style="height: 265px; overflow-y: auto">
                                <loading v-if="searching"></loading>

                                <div v-else>
                                    <p v-if="(users.length == 0)" class="text-center">
                                        <span v-if="searched">
                                            No result found
                                        </span>
                                    </p>

                                    <table v-else class="table table-sm">
                                        <tbody>
                                            <tr
                                                scope="row"
                                                v-for="user in users"
                                                :key="user.id"
                                                :style="isUserAdded(user.id) ? '' : 'cursor: pointer'"
                                                @click="addUser(user)"
                                            >
                                                <td class="pl-2">
                                                    {{ user.first_name }} {{ user.last_name }}
                                                    <p class="small mb-1">{{ user.email }}</p>
                                                </td>
                                                <td class="pr-2 font-size-18 contact-links">
                                                    <span
                                                        v-if="isUserAdded(user.id)"
                                                        class="float-right"
                                                        v-b-tooltip.hover.left
                                                        title="Added"
                                                    >
                                                        <i class="far fa-check"></i>
                                                    </span>
                                                    <span v-else>
                                                        <span
                                                            v-if="(user.id in adding)"
                                                            class="float-right"
                                                            :key="'adding' + user.id"
                                                        >
                                                            <i class="fal fa-spinner-third fa-spin"></i>
                                                        </span>
                                                        <span
                                                            v-else
                                                            class="float-right"
                                                            :key="'add' + user.id"
                                                            v-b-tooltip.hover.left
                                                            title="Add"
                                                        >
                                                            <i class="far fa-user-plus"></i>
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="pu-groups"
                            role="tabpanel"
                            aria-labelledby="pu-groups-tab"
                            style="height: 320px; overflow-y: auto"
                        >
                            <loading v-if="user_groups_loading"></loading>
                            <div v-if="!user_groups_loading && (user_groups.length == 0)">
                                There are no groups available
                            </div>
                            <div v-if="!user_groups_loading && (user_groups.length > 0)" class="table-responsive">
                                <table class="table table-centered table-hover">
                                    <tbody>
                                        <tr v-for="item in user_groups" :key="item.id">
                                            <td scope="row" :id="'p-user-group-row' + item.id" class="pb-0">
                                                <div class="row">
                                                    <div class="col-8" style="padding-top: 3px">
                                                        {{ item.name }}
                                                    </div>
                                                    <div class="col-4 font-size-18 contact-links">
                                                        <a
                                                            href="#"
                                                            class="float-right"
                                                            @click.prevent="toggleViewUserGroup(item)"
                                                        >
                                                            <span
                                                                v-if="(user_group_details_open.indexOf(item.id) < 0)"
                                                                :key="'close-' + item.id"
                                                            >
                                                                <i class="fa-regular fa-chevron-circle-down"></i>
                                                            </span>
                                                            <span v-else :key="'open-' + item.id">
                                                                <i class="fa-regular fa-chevron-circle-up"></i>
                                                            </span>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            class="float-right mr-2"
                                                            v-b-tooltip.hover.left
                                                            title="Add"
                                                            @click.prevent="addGroup(item)"
                                                        >
                                                            <i class="fa-regular fa-plus"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="row list-details-td mt-2">
                                                    <div
                                                        class="col-12 list-details"
                                                        :id="'p-user-group-details' + item.id"
                                                    >
                                                        <table class="table table-sm">
                                                            <tbody>
                                                                <tr
                                                                    scope="row"
                                                                    v-for="user in item.users"
                                                                    :key="'group-user' + item.id + '_' + user.id"
                                                                >
                                                                    <td>{{ user.first_name }} {{ user.last_name }}</td>
                                                                    <td>
                                                                        <small style="overflow-wrap: anywhere">
                                                                            {{ user.email }}
                                                                        </small>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="pu-invite"
                            role="tabpanel"
                            aria-labelledby="pu-invite-tab"
                        >
                            <div v-if="invited">
                                <div class="alert alert-success" role="alert">
                                    <div class="row">
                                        <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                                        <div class="col" v-html="invited"></div>
                                    </div>
                                </div>
                                <button
                                    :key="'reset-invite-button'"
                                    type="button"
                                    class="btn btn-secondary"
                                    @click="resetInvite()"
                                >
                                    Invite another guest user
                                </button>
                            </div>
                            <form v-else v-on:submit.prevent="inviteGuest()">
                                <div v-if="errors" class="alert alert-danger" role="alert" v-html="errors"></div>

                                <div class="form-group">
                                    <label
                                        for="proofs-guest-first_name"
                                        id="proofs-guest-name-label"
                                        class="mandatory-label"
                                    >Name *</label>
                                    <div class="input-group">
                                        <input
                                            v-model="guest_first_name"
                                            type="text"
                                            id="proofs-guest-first_name"
                                            class="form-control mandatory-field"
                                            :readonly="inviting || user_exists"
                                            :placeholder="placeholder('proofs-guest-first_name')"
                                            v-on:focus="placeholder('proofs-guest-first_name', true)"
                                            v-on:blur="placeholder('proofs-guest-first_name', true)"
                                            v-on:keyup="unHighlightNameErrors()"
                                            v-on:change="unHighlightNameErrors()"
                                        >
                                        <input
                                            v-model="guest_last_name"
                                            type="text"
                                            id="proofs-guest-last_name"
                                            class="form-control mandatory-field"
                                            :readonly="inviting || user_exists"
                                            :placeholder="placeholder('proofs-guest-last_name')"
                                            v-on:focus="placeholder('proofs-guest-last_name', true)"
                                            v-on:blur="placeholder('proofs-guest-last_name', true)"
                                            v-on:keyup="unHighlightNameErrors()"
                                            v-on:change="unHighlightNameErrors()"
                                        >
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label
                                        for="proofs-guest-email"
                                        id="proofs-guest-email-label"
                                        class="mandatory-label"
                                    >Email *</label>
                                    <input
                                        v-model="guest_email"
                                        type="email"
                                        id="proofs-guest-email"
                                        class="form-control mandatory-field"
                                        :readonly="inviting || user_exists"
                                        v-on:keyup="$root.unHighlightErrors('proofs-guest-email')"
                                        v-on:change="$root.unHighlightErrors('proofs-guest-email')"
                                    >
                                </div>

                                <div v-if="!user_exists" class="text-right">
                                    <span v-if="!inviting">
                                        <button
                                            :key="'invite-button'"
                                            type="submit"
                                            class="btn btn-secondary"
                                            @click="inviteGuest()"
                                        >
                                            <i class="far fa-paper-plane mr-1"></i> Send invite
                                        </button>
                                    </span>
                                    <span v-else>
                                        <button :key="'inviting-button'" type="button" class="btn btn-secondary">
                                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                            <span style="opacity: 0.5">Send invite</span>
                                        </button>
                                    </span>
                                </div>

                                <!--
                                <div v-if="user_exists">
                                    <div class="alert alert-warning" role="alert">
                                        This email is already registered. Add this user?
                                    </div>
                                    <button
                                        :key="'add-button'"
                                        type="button"
                                        class="btn btn-secondary mr-2"
                                        @click="addExistingUser()"
                                    >
                                        <i class="far fa-check mr-1"></i> Add
                                    </button>
                                    <button
                                        :key="'cancel-button'"
                                        type="button"
                                        class="btn btn-light"
                                        @click="resetInvite()"
                                    >
                                        <i class="far fa-times mr-1"></i> Cancel
                                    </button>
                                </div>
                                -->
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col" style="padding-top: 12px">
                    <label>Members added <span v-if="proof_ordered">- drag and drop to re-order</span></label>
                    <div class="form-control" style="height: 351px; overflow-y: auto">
                        <draggable
                            v-model="users_added"
                            ghost-class="vue-drag-ghost"
                            handle=".drag-handle"
                            tag="div"
                            @end="onDragSortEnd"
                            animation="200"
                        >
                            <div
                                v-for="item in users_added"
                                :key="'user-' + item.id"
                                class="row collaborator"
                            >
                                <div v-if="proof_ordered" class="col-2 pt-1 font-size-18 contact-links">
                                    <a
                                        href="#"
                                        class="drag-handle"
                                        style="cursor: move"
                                        v-b-tooltip.hover
                                        title="Re-order"
                                        @click.prevent="doNothing()"
                                    >
                                        <i class="fas fa-arrow-down-arrow-up"></i>
                                    </a>
                                </div>
                                <div class="col pt-1 pb-1">
                                    <p class="mb-0">
                                        {{ item.name }}
                                        <span
                                            v-if="proof_leader == item.id"
                                            class="badge badge-primary font-size-11 ml-1"
                                            style="text-transform: uppercase"
                                        >
                                            Leader
                                        </span>
                                    </p>
                                    <p class="small text-muted mb-0" style="overflow-wrap: anywhere">{{ item.email }}</p>
                                </div>
                                <div class="col-2 text-right font-size-18 contact-links pt-1">
                                    <a
                                        v-if="proof_leader != item.id"
                                        href="#"
                                        v-b-tooltip.hover.left
                                        title="Remove"
                                        @click.prevent="removeUser(item.id)"
                                    >
                                        <i class="fal fa-trash-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </draggable>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                type="button"
                class="btn btn-primary"
                data-dismiss="modal"
                @click="onClose()"
            >
                <i class="far fa-check mr-1"></i> Done
            </button>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    export default {
        components: {
            draggable,
        },
        data () {
            return {
                errors: false,
                adding: {},
                users_added: [],
                list_component: null,
                keyword: '',
                searching: false,
                searched: false,
                users: [],
                keypress_time: 0,
                proof_leader: null,
                proof_ordered: 0,
                user_groups_loading: false,
                user_groups: [],
                user_group_details_open: [],
                user_roles: [],
                inviting: false,
                invited: false,
                guest_first_name: '',
                guest_last_name: '',
                guest_email: '',
                user_exists: null
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (usersAdded, proofLeader, proofOrdered, listComponent) {
                this.errors = false;
                this.adding = {};
                this.users_added = [];
                usersAdded.forEach((item) => {
                    this.users_added.push(item);
                });
                this.proof_leader = proofLeader;
                this.proof_ordered = proofOrdered;
                this.list_component = listComponent;
                this.keyword = '';
                this.searching = false;
                this.searched = false;
                this.users = [];
                this.resetInvite();
                this.getUserGroups();
                this.getRoles();

                setTimeout(function () {
                    $('#pu-users-tab').click();
                    setTimeout(function () {
                        $('#proof-user-keyword').focus();
                    }, 500);
                }, 500);
            },
            keywordSearch: function (delay = false) {
                if (!this.keyword) {
                    this.searching = false;
                    this.searched = false;

                    return null;
                }
                this.searching = true;

                if (delay) {
                    this.keypress_time = new Date();
                    var vm = this;
                    setTimeout(function () {
                        vm.keywordSearch();
                    }, 500);
                }

                const time = new Date();
                if ((time - this.keypress_time) < 500) {
                    return null;
                }

                this.users = [];
                this.searched = false;
                
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|approved|!declined|!deleted|proof-user|kw::'
                    + btoa(this.keyword);
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let users = [];
                    response.data.users.forEach((item) => {
                        if (!this.isUserAdded(item.id)) {
                            users.push(item);
                        }
                    });
                    vm.users = users;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#proof-user-add-form-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.searching = false;
                    vm.searched = true;
                });
            },
            clearKeyword: function () {
                this.keyword = '';
                this.users = [];
                this.searched = false;
                $('#proof-user-keyword').focus();
            },
            addUser: function (user) {
                if (this.isUserAdded(user.id) || (user.id in this.adding)) {
                    return null;
                }
                this.searching = true;
                this.adding[user.id] = true;
                this.errors = false;
                this.searching = false;
                this.searched = false;
                this.users_added.push({
                    id: user.id,
                    name: user.first_name + ' ' + user.last_name,
                    email: user.email
                });
                this.list_component.proofUserAdded(user);
                this.keyword = '';
                delete this.adding[user.id];
                this.removeUserFromResults(user.id);
            },
            removeUser: function (userId) {
                if (!this.isUserAdded(userId)) {
                    return null;
                }
                let itemIndex = -1;
                this.users_added.forEach((item, index) => {
                    if (item.id == userId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.users_added.splice(itemIndex, 1);
                }
                this.list_component.removeProofUser(userId);
            },
            closeModal: function () {
                $('#proof-user-add-form-modal').modal('hide');
                if (this.list_component.component_name == 'proof-add-form') {
                    this.list_component.onClose(true);
                }
                this.$parent.resetModalBackdrop();
            },
            onClose: function () {
                this.$parent.resetModalBackdrop();
                if (this.list_component.component_name == 'proof-team-edit') {
                    setTimeout(function () {
                        $('#proof-team-edit-modal').modal('show');
                    }, 500);
                }
            },
            isUserAdded: function (userId) {
                let itemIndex = -1;
                this.users_added.forEach((item, index) => {
                    if (item.id == userId) {
                        itemIndex = index;
                    }
                });

                return (itemIndex > -1);
            },
            removeUserFromResults: function (userId) {
                let itemIndex = -1;
                this.users.forEach((item, index) => {
                    if (item.id == userId) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.users.splice(itemIndex, 1);
                }
            },
            doNothing: function () {
                return null;
            },
            getUserGroups: function () {
                this.user_groups_loading = true;
                this.user_groups = [];
                this.user_group_details_open = [];
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.user_groups.forEach((item) => {
                        item.users = [];
                        vm.getUserGroup(item.id);
                        vm.user_groups.push(item);
                    });
                })
                .catch(function (error) {
                    // Do anything?
                })
                .finally(() => {
                    vm.user_groups_loading = false;
                });
            },
            toggleViewUserGroup: function (item) {
                if (this.user_group_details_open.indexOf(item.id) < 0) {
                    this.user_group_details_open.push(item.id);
                    $('#p-user-group-row' + item.id).addClass('highlighted');
                    $('#p-user-group-details' + item.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#p-user-group-details' + item.id).slideUp('slow', function () {
                        $('#p-user-group-row' + item.id).removeClass('highlighted');
                        for (var n = 0; n < vm.user_group_details_open.length; n++) { 
                            if (vm.user_group_details_open[n] == item.id) { 
                                vm.user_group_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            getUserGroup: function (userGroupId) {
                /**
                 * Send get user group request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-group/'
                    + userGroupId;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_groups.forEach((item) => {
                        if (item.id == response.data.user_group.id) {
                            item.users = response.data.user_group.users;
                        }
                    });
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            addGroup: function (userGroup) {
                userGroup.users.forEach((item) => {
                    this.addUser(item);
                });
            },
            onDragSortEnd: function(event) {
                if (event.newIndex == event.oldIndex) {
                    return false;
                }
                this.list_component.reOrderUsers(event.newIndex, event.oldIndex);
            },
            getRoles: function () {
                /**
                 * Send get roles request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/settings/roles';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_roles = response.data.roles;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            canInviteGuest: function () {
                if (!this.$root.client || (this.user_roles.length == 0)) {
                    return false;
                }
                if (!this.$root.client.proofs_guest_role) {
                    return false;
                }
                let found = false;
                this.user_roles.forEach((item) => {
                    if (item.id == parseInt(this.$root.client.proofs_guest_role)) {
                        found = true;
                    }
                });

                return found;
            },
            inviteGuest: function () {
                this.errors = false;
                this.inviting = true;
                this.user_exists = null;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API.
                 */
                const data = {
                    first_name: this.guest_first_name,
                    last_name: this.guest_last_name,
                    email: this.guest_email,
                    role: parseInt(this.$root.client.proofs_guest_role),
                    proof_id: this.list_component.proof ? this.list_component.proof.id : 0,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.invited = response.data.message;
                    vm.addUser({
                        id: response.data.user_id,
                        first_name: vm.guest_first_name,
                        last_name: vm.guest_last_name,
                        email: vm.guest_email
                    });
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            $('#proof-user-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                                if (vm.errors.indexOf('already exists') > 0) {
                                    if (vm.isUserAdded(error.response.data.user.id)) {
                                        vm.errors = 'This user is already added.'
                                    } else {
                                        vm.errors = false;
                                        vm.user_exists = error.response.data.user;
                                        vm.addExistingUser();
                                    }
                                }
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                let errorHtml = null;
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    errorHtml = value.join('<br>') + '<br>';
                                    vm.errors += errorHtml;
                                    if ((key == 'first_name') || (key == 'last_name')) {
                                        $('#proofs-guest-name-label').addClass('text-danger');
                                    } else {
                                        $('#proofs-guest-' + key + '-label').addClass('text-danger');
                                    }
                                    $('#proofs-guest-' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.inviting = false
                );
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'proofs-guest-first_name':
                        text = 'First';
                        break;
                    case 'proofs-guest-last_name':
                        text = 'Last';
                        break;
                }
                if (update) {
                    $('#' + field).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            unHighlightNameErrors: function () {
                if (this.guest_first_name) {
                    $('#proofs-guest-first_name').removeClass('field-error');
                }
                if (this.guest_last_name) {
                    $('#proofs-guest-last_name').removeClass('field-error');
                }
                if (this.guest_first_name && this.guest_last_name) {
                    $('#proofs-guest-name-label').removeClass('text-danger');
                }
            },
            resetInvite: function () {
                this.inviting = false;
                this.invited = false;
                this.guest_first_name = '';
                this.guest_last_name = '';
                this.guest_email = '';
                this.user_exists = null;
            },
            addExistingUser: function () {
                this.addUser(this.user_exists);
                this.resetInvite();
            }
        }
    }
</script>
<style scoped>
    .tab-content {
        border-left: 1px solid #ced4da;
        border-right: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        height: 350px;
    }

    .form-group.keyword {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }

    .table-sm tr:hover {
        background-color: #f8f9fa;
    }

    .table-sm tr:first-child td {
        border-top: none;
    }
</style>
