<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div class="row">
            <div class="col-xl-2 col-lg-3 col-sm-4 mb-3">
                <div class="bg-light text-center" style="position: relative; padding: 1px">
                    <img
                        class="img-fluid"
                        :src="image_src"
                        :alt="details ? details.resource_title : 'preview'"
                        v-on:mouseover="viewImageIcon()"
                        v-on:mouseout="viewImageIcon(false)"
                    >
                    <div
                        v-if="data"
                        class="view-image-icon"
                        :id="'view-image-icon' + data.id"
                        v-b-tooltip.hover
                        title="View"
                        @click="viewArtwork()"
                        v-on:mouseover="viewImageIcon()"
                        v-on:mouseout="viewImageIcon(false)"
                    >
                        <i class="fal fa-eye"></i>
                    </div>
                </div>
            </div>
            <div class="col">
                <h5>
                    <span class="mr-3">
                        {{ $root.ucfirst($root.contents.template_title) }} No. {{ details ? details.resource_id : '' }}
                    </span>
                    <span v-if="details">{{ details.resource_title }}</span>
                </h5>
                <hr class="mt-0">
                <div v-if="details" class="row">
                    <div class="col">
                        <div v-if="data.declined">
                            <div v-if="details.reason_for_decline_html" class="form-group">
                                <label class="mb-0">
                                    Comments
                                    <span v-if="details.approver_name">by {{ details.approver_name }}</span>
                                </label>
                                <div v-html="details.reason_for_decline_html"></div>
                            </div>
                            <div v-if="details.declined_on" class="form-group">
                                Declined on {{ details.declined_on }}
                            </div>
                        </div>
                        <div v-if="data.approved && details.approved_on">
                            Approved by {{ details.approver_name }} on {{ details.approved_on }}
                        </div>
                        <div v-if="(!data.approved && !data.declined)">
                            <div class="form-group">
                                <label class="mb-0">Usage details</label>
                                <div v-html="details.comments_html"></div>
                            </div>
                            <div v-if="last_chased_at_formatted" class="form-group">
                                <label class="mb-0">Last chased</label>
                                <div v-html="last_chased_at_formatted"></div>
                            </div>
                        </div>
                    </div>
                    <div v-if="(hasPrintOrder() || canDownload('pdf_commented'))" class="col-md-6">
                        <div v-if="canDownload('pdf_commented')" class="form-group">
                            <span v-if="(downloading != 'pdf_commented')">
                                <button
                                    :key="'download-commented-button'"
                                    type="button"
                                    class="btn btn-secondary"
                                    :disabled="(updating || deleting || downloading)"
                                    @click.prevent="downloadArtwork('pdf_commented')"
                                >
                                    <i class="far fa-download mr-1"></i> Commented PDF
                                </button>
                            </span>
                            <span v-else>
                                <button
                                    :key="'download-commented-button-spinning'"
                                    type="button"
                                    class="btn btn-secondary"
                                >
                                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                    <span style="opacity: 0.5">Commented PDF</span>
                                </button>
                            </span>
                        </div>
                        <div v-if="hasPrintOrder()" class="form-group">
                            <label class="mb-0">Quantity</label>
                            <div>{{ details.print_order.quantity }}</div>
                        </div>
                        <div v-if="hasPrintOrder()" class="form-group">
                            <label class="mb-0">Cost</label>
                            <div v-if="details.print_order.currency == 'pound'">
                                &pound;{{ parseFloat(details.print_order.total_price).toFixed(2) }}
                            </div>
                            <div v-if="details.print_order.currency == 'euro'">
                                &euro;{{ parseFloat(details.print_order.total_price).toFixed(2) }}
                            </div>
                            <div v-if="details.print_order.currency == 'dollar'">
                                &dollar;{{ parseFloat(details.print_order.total_price).toFixed(2) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <span v-if="canViewTemplateOptions()">
            <button
                :key="'output-options-button'"
                type="button"
                class="btn btn-primary mr-sm-2 mb-2 mb-sm-0"
                @click.prevent="showTemplateOptions()"
                :disabled="(!template || deleting)"
            >
                <i class="far fa-square-list mr-1"></i> Output options
            </button>
        </span>

        <span v-if="canReEdit()">
            <button
                :key="'re-edit-button'"
                type="button"
                class="btn btn-primary mr-sm-2 mb-2 mb-sm-0"
                @click.prevent="editArtwork()"
                :disabled="(!template || deleting)"
            >
                <i class="far fa-pen-paintbrush mr-1"></i> Re-edit
            </button>
        </span>

        <span v-if="canApprove()">
            <span v-if="!updating">
                <button
                    :key="'approve-button'"
                    type="button"
                    class="btn btn-primary mr-sm-2 mb-2 mb-sm-0"
                    @click.prevent="approveArtwork()"
                >
                    <i class="far fa-check mr-1"></i> Approve
                </button>
            </span>
            <span v-else>
                <button
                    :key="'approve-button-spinning'"
                    type="button"
                    class="btn btn-primary mr-sm-2 mb-2 mb-sm-0"
                >
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Approving</span>
                </button>
            </span>
        </span>

        <button
            v-if="canDecline()"
            :key="'decline-button'"
            type="button"
            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
            :disabled="updating"
            @click.prevent="declineArtwork()"
        >
            <i class="far fa-ban mr-1"></i> Decline
        </button>

        <!--<button
            :key="'view-button'"
            type="button"
            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
            :disabled="(updating || !details || deleting)"
            @click.prevent="viewArtwork()"
        >
            <i class="far fa-eye mr-1"></i> View
        </button>-->

        <span v-if="canDownload('pdf')">
            <span v-if="(downloading != 'pdf')">
                <button
                    :key="'download-button'"
                    type="button"
                    class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                    :disabled="(updating || deleting || downloading)"
                    @click.prevent="downloadArtwork()"
                >
                    <i class="far fa-download mr-1"></i> Download
                </button>
            </span>
            <span v-else>
                <button
                    :key="'download-button-spinning'"
                    type="button"
                    class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                >
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Download</span>
                </button>
            </span>
        </span>

        <!--<span v-if="canDownload('pdf_commented')">
            <span v-if="(downloading != 'pdf_commented')">
                <button
                    :key="'download-commented-button'"
                    type="button"
                    class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                    :disabled="(updating || deleting || downloading)"
                    @click.prevent="downloadArtwork('pdf_commented')"
                >
                    <i class="far fa-download mr-1"></i> Commented PDF
                </button>
            </span>
            <span v-else>
                <button
                    :key="'download-commented-button-spinning'"
                    type="button"
                    class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                >
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Commented PDF</span>
                </button>
            </span>
        </span>-->

        <button
            v-if="canApprove()"
            :key="'request-info-button'"
            type="button"
            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
            :disabled="updating"
            @click.prevent="requestInfo()"
        >
            <i class="far fa-bullhorn mr-1"></i> Request info
        </button>

        <span v-if="canChase()">
            <span v-if="!chasing">
                <button
                    :key="'chase-button'"
                    type="button"
                    class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                    :disabled="(updating || deleting)"
                    @click.prevent="chase()"
                >
                    <i class="far fa-bullhorn mr-1"></i> Chase
                </button>
            </span>
            <span v-else>
                <button
                    :key="'chase-button-spinning'"
                    type="button"
                    class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                >
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Chase</span>
                </button>
            </span>
        </span>

        <span v-if="canDelete()">
            <span v-if="!deleting">
                <button
                    :key="'delete-button'"
                    type="button"
                    class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                    :disabled="chasing"
                    @click.prevent="deleteArtwork()"
                >
                    <span v-if="(data.approved || data.declined)">
                        <i class="far fa-trash-alt mr-1"></i> Delete
                    </span>
                    <span v-else>
                        <i class="far fa-times mr-1"></i> Cancel
                    </span>
                </button>
            </span>
            <span v-else>
                <button
                    :key="'delete-button-spinning'"
                    type="button"
                    class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                >
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ (data.approved || data.declined) ? 'Deleting' : 'Cancelling' }}</span>
                </button>
            </span>
        </span>
    </div>
</template>

<script>
    export default {
        props: ['data', 'details'],
        data () {
            return {
                errors: false,
                updating: false,
                image_src: '/images/file-icon.png',
                downloading: null,
                asset: null,
                template: null,
                deleting: false,
                chasing: false,
                last_chased_at_formatted: null
            }
        },
        mounted () {
            if (this.details) {
                this.getPreviewImage();
                if ((this.canReEdit() && !this.details.template_replaced) || this.canViewTemplateOptions(false)) {
                    this.getTemplate();
                }
                if (this.details.last_chased_at_formatted) {
                    this.last_chased_at_formatted = this.details.last_chased_at_formatted;
                }
            }
        },
        watch: {
            details: function (val) {
                if (val) {
                    this.getPreviewImage();
                    if (
                        ((this.canReEdit() && !val.template_replaced) || this.canViewTemplateOptions(false))
                        && !this.template
                    ) {
                        this.getTemplate();
                    }
                    if (val.last_chased_at_formatted) {
                        this.last_chased_at_formatted = val.last_chased_at_formatted;
                    }
                }
            }
        },
        methods: {
            getPreviewImage: function () {
                if (this.image_src != '/images/file-icon.png') {
                    return null;
                }
                /**
                 * Send request to API
                 */
                const path = '/template/' + this.data.template_id + '/artwork/' + this.data.id + '/preview/1/small';
                var vm = this;

                axios({
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + path,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    //
                });
            },
            requestInfo: function () {
                window.location.href='mailto:' + this.details.email + '?Subject=Approval%20information%20request';
            },
            approveArtwork: function () {
                if (this.details.template_replaced) {
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: null,
                        title: '<i class="far fa-exclamation-triangle font-size-20 text-danger mr-2"></i> '
                            + this.$root.ucfirst(this.$root.contents.template_title) + ' has been updated',
                        text: 'This ' + this.$root.contents.template_title + ' cannot be approved. '
                            + 'Use the Decline button to send a message asking ' + this.details.name
                            + ' to create a new version.'
                    };
                    $('#message-modal').modal('show');

                    return null;
                }
                this.updating = true;
                /**
                 * Send approve artwork request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.data.template_id + '/artwork/' + this.data.id + '/approve';
                const data = {
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // show confirmation message
                    vm.$parent.$parent.message = {
                        type: 'success',
                        title: 'Artwork approved',
                        text: response.data.message
                    };
                    $('#message-modal').modal('show');
                    vm.$parent.removeApproval(vm.data.id);

                    // close after 4 seconds automatically
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 4000);

                    // Clear notification.
                    vm.$root.clearNotification('admin', 0, 'artwork_approval_request', vm.data.id);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            declineArtwork: function () {
                if (!this.data || !this.details) {
                    return null;
                }
                const component = this.$root.findComponent(this.$parent, 'decline-artwork-approval-request');
                if (component) {
                    component.resetForm(this.data, this.details.template_replaced);
                    setTimeout(function() {
                        $('#decline-artwork-approval-request-modal').modal('show');
                    }, 200);
                }
            },
            canDownload: function (file = 'pdf') {
                if (!this.details) {
                    return false;
                }
                if (file == 'pdf_commented') {
                    if (this.$route.name == 'artwork-approvals') {
                        return null;
                    }
                    if (!this.data.declined || !this.data.finished || (this.data.created_by != this.$root.user.id)) {
                        return null;
                    }

                    return this.details.pdf_commented;
                }
                if (this.canViewTemplateOptions()) {
                    return false;
                }
                if (this.$route.name != 'artwork-approvals') {
                    // Check if template has output_download!!!!

                    if (!this.data.approved) {
                        return false;
                    }
                }

                return this.details.pdf;
            },
            downloadArtwork: function (file = 'pdf') {
                if (this.downloading) {
                    return null;
                }
                this.downloading = file;
                const download = this.details[file];
                /**
                 * Send request to API
                 */
                const data = {
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension,
                    item_type: 'artwork_pdf',
                    item_id: this.data.id
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                    window.location.href = url;
                })
                .catch(function (error) {
                    // Show error?
                    if (error.response.data.error) {
                        alert(error.response.data.error);
                    }
                })
                .finally(() =>
                    vm.downloading = null
                );
            },
            viewArtwork: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'artwork-view');
                if (component) {
                    const template = { id: this.data.template_id };
                    component.reset(template, this.data);
                    $('#artwork-view-modal').modal('show');
                }
            },
            canApprove: function () {
                if (!this.$root.hasPermission('templates_brand_approvals')) {
                    return false;
                }
                if (this.$route.name != 'artwork-approvals') {
                    return false;
                }

                return (!this.data.approved && !this.data.declined);
            },
            canDecline: function () {
                if (!this.$root.hasPermission('templates_brand_approvals')) {
                    return false;
                }
                if (this.$route.name != 'artwork-approvals') {
                    return false;
                }

                return !this.data.declined;
            },
            canChase: function () {
                if (this.$route.name == 'artwork-approvals') {
                    return false;
                }
                if (!this.data) {
                    return false;
                }
                if (this.data.approved || this.data.declined) {
                    return false;
                }

                return (this.data.created_by == this.$root.user.id);
            },
            chase: function () {
                this.chasing = true;
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.data.template_id + '/artwork/' + this.data.id + '/chase';
                var vm = this;

                const data = {
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.last_chased_at_formatted = response.data.last_chased_at_formatted;
                    vm.$parent.$parent.message = {
                        type: 'success',
                        title: 'Artwork approval',
                        text: 'Artwork approval chased'
                    };
                    $('#message-modal').modal('show');

                    // close after 4 seconds automatically
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 4000);
                })
                .catch(function (error) {
                    //
                })
                .finally(() =>
                    vm.chasing = false
                );
            },
            canDelete: function () {
                if (this.$route.name == 'artwork-approvals') {
                    return false;
                }
                if (!this.data) {
                    return false;
                }

                return (this.data.created_by == this.$root.user.id);
            },
            deleteArtwork: function (confirmed = false) {
                this.errors = false;
                if (confirmed) {
                    this.deleting = true;
                    /**
                     * Send delete artwork request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                        + this.data.template_id + '/artwork/' + this.data.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        // Show confirmation message.
                        vm.$parent.$parent.message = {
                            type: 'success',
                            title: 'Request ' + ((vm.data.approved || vm.data.declined) ? 'deleted' : 'cancelled'),
                            text: response.data.message
                        };
                        $('#message-modal').modal('show');
                        vm.$parent.removeApproval(vm.data.id);

                        // Close after 4 seconds automatically.
                        setTimeout(function() {
                            $('#message-modal').modal('hide');
                        }, 4000);
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    })
                    .finally(() => {
                        vm.deleting = false;
                    });

                    return null;
                }

                if (this.updating) {
                    return null;
                }

                this.$parent.$parent.confirm_delete = {
                    title: ((this.data.approved || this.data.declined) ? 'Delete' : 'Cancel') + ' approval request',
                    text: 'Are you sure you want to ' + ((this.data.approved || this.data.declined) ? 'delete' : 'cancel')
                        + ' this request?',
                    component: this,
                    action: 'delete'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                if (action == 'delete') {
                    this.deleteArtwork(true);
                }
            },
            hasPrintOrder: function () {
                if (!this.details) {
                    return false;
                }
                if (!this.details.print_order) {
                    return false;
                }

                return true;
            },
            canReEdit: function () {
                if (this.$route.name == 'artwork-approvals') {
                    return false;
                }
                if (!this.data) {
                    return false;
                }
                if (!this.data.declined) {
                    return false;
                }

                return (this.data.created_by == this.$root.user.id);
            },
            editArtwork: function () {
                if (this.details.template_replaced) {
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: null,
                        title: '<i class="far fa-exclamation-triangle font-size-20 text-danger mr-2"></i>'
                            + 'Re-edit ' + this.$root.contents.template_title,
                        text: 
                            + 'The ' + this.$root.contents.template_title + ' used to create your version has '
                            + 'been updated so cannot be re-edited. Please create a new version.'
                    };
                    $('#message-modal').modal('show');

                    return null;
                }

                let item = this.data;
                item.fields = this.details.fields;
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.showArtworkEditor(this.asset, this.template, item);
            },
            canViewTemplateOptions: function (checkTemplate = true) {
                if (this.$route.name == 'artwork-approvals') {
                    return false;
                }
                if (!this.data) {
                    return false;
                }
                if (!this.data.approved || !this.data.finished) {
                    return false;
                }
                if (checkTemplate) {
                    if (!this.template) {
                        return false;
                    }
                    if (this.template.output_view_only) {
                        return false;
                    }
                }

                return (this.data.created_by == this.$root.user.id);
            },
            showTemplateOptions: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                if (appComponent) {
                    const component = this.$root.findComponent(appComponent, 'template-options');
                    if (component) {
                        let item = this.data;
                        if (
                            (this.template.output_download || this.template.output_desktop || this.template.output_order)
                            && this.details.pdf
                        ) {
                            item.pdf = this.details.pdf;
                        }
                        if (this.template.output_desktop && this.details.pdf_ncm) {
                            item.pdf_ncm = this.details.pdf_ncm;
                        }
                        if (this.template.output_image_only && this.details.images) {
                            item.images = this.details.images;
                        }
                        component.reset(this.asset, this.template, item);
                        $('#template-options-modal').modal('show');
                    }
                }
            },
            getTemplate: function () {
                if (!this.asset) {
                    /**
                     * Send get asset details request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/'
                        + this.details.resource_id;
                    var vm = this;

                    axios({
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.asset = response.data.resource;
                        vm.getTemplate();
                    })
                    .catch(function (error) {
                        //
                    });

                    return null;
                }

                /**
                 * Send get template details request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.details.resource_id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.template = response.data.template;
                })
                .catch(function (error) {
                    //
                });
            },
            viewImageIcon: function (show = true) {
                if (show && !this.updating && this.details && !this.deleting) {
                    $('#view-image-icon' + this.data.id).show();

                    return null;
                }
                $('#view-image-icon' + this.data.id).hide();
            }
        }
    }
</script>
<style scoped>
    img {
        max-height: 200px;
    }

    .view-image-icon {
        position: absolute;
        width: 40px;
        height: 40px;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        padding: 6px 9px;
        line-height: 1.4;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        border-radius: 20px;
        display: none;
        cursor: pointer;
    }

    @media (max-width: 575px) {
        .btn {
            display: block;
            width: 100%;
        }
    }
</style>
