<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="artwork-view-modal-label">{{ title }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div v-else class="page-image bg-light">
                <div v-if="loading" class="page-loading">
                    <loading></loading>
                </div>
                <img v-else :src="image_src[current_page]" class="img-fluid" :alt="'Page ' + current_page">
            </div>
        </div>
        <div class="modal-footer" style="display: block; height: 67px">
            <div class="paginator">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <button
                            class="btn btn-secondary big-icon"
                            type="button"
                            id="artwork-view-prev"
                            :disabled="(current_page == 1)"
                            @click="prevPage()"
                        ><i class="far fa-chevron-left"></i></button>
                        <b-tooltip
                            v-if="(current_page > 1)"
                            target="artwork-view-prev"
                            triggers="hover"
                        >Previous page</b-tooltip>
                    </div>
                    <input
                        :value="'Page ' + current_page + ' of ' + num_pages"
                        type="text"
                        class="form-control text-center px-1"
                        :readonly="true"
                        style="max-width: 120px"
                    >
                    <div class="input-group-append">
                        <button
                            class="btn btn-secondary big-icon"
                            type="button"
                            id="artwork-view-next"
                            :disabled="(current_page == num_pages)"
                            @click="nextPage()"
                        ><i class="far fa-chevron-right"></i></button>
                        <b-tooltip
                            v-if="(current_page < num_pages)"
                            target="artwork-view-next"
                            triggers="hover"
                        >Next page</b-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                template_id: null,
                artwork_id: null,
                num_pages: 0,
                title: '',
                current_page: 0,
                loading: true,
                errors: false,
                image_src: []
            }
        },
        mounted () {
            //
        },
        watch: {
            current_page: function (val) {
                if (val) {
                    this.getPreviewImage(val);
                }
            }
        },
        methods: {
            reset: function (template, artwork) {
                this.template_id = template.id;
                this.artwork_id = artwork.id;
                this.num_pages = artwork.number_of_pages;
                this.title = artwork.title;
                this.current_page = 0;
                this.image_src = [null];

                var vm = this;
                setTimeout(function () {
                    vm.current_page = 1;
                }, 200);
            },
            getPreviewImage: function (page) {
                this.loading = true;
                this.errors = false;
                var vm = this;

                if (this.image_src[page]) {
                    setTimeout(function () {
                        vm.loading = false;
                    }, 500);

                    return null;
                }

                /**
                 * Send request to API
                 */
                const path = '/template/' + this.template_id + '/artwork/' + this.artwork_id + '/preview/' + page;

                axios({
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + path,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src[vm.current_page] = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#artwork-view-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    }
                })
                .finally(() =>
                    vm.loading = false
                );
            },
            prevPage: function () {
                if (this.loading || (this.current_page == 1)) {
                    return null;
                }
                this.current_page--;
            },
            nextPage: function () {
                if (this.loading || (this.current_page == this.num_pages)) {
                    return null;
                }
                this.current_page++;
            }
        }
    }
</script>
<style scoped>
    .page-image {
        position: relative;
        text-align: center;
        padding: 1px;
        min-height: 300px;
    }

    .page-image img {
        max-height: 600px;
    }

    .page-loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 50px;
        margin: auto;
    }

    .paginator {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: 200px;
    }
</style>
