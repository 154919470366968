<template>
    <div>

        
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data () {
            return {
                //
            }
        },
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            //
        }
    }
</script>
