<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="proof-team-edit-modal-label">Update team members</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onSubmit(true)">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div class="table-responsive mb-0" style="overflow-x: hidden">
                <p v-if="proof.ordered">Drag and drop to re-order</p>
                <table class="table table-centered table-sm">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col">
                                <div class="row">
                                    <div v-if="proof.ordered" class="col-2">Order</div>
                                    <div class="col">Members</div>
                                    <div class="col-2 text-center">Reviewer</div>
                                    <div class="col-2 text-center">Leader</div>
                                    <div class="col-1">&nbsp;</div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <draggable
                        v-model="proof_users"
                        ghost-class="vue-drag-ghost"
                        handle=".drag-handle"
                        tag="tbody"
                        @end="onDragSortEnd"
                        animation="200"
                    >
                        <tr v-for="item in proof_users" :key="item.id">
                            <td scope="row">
                                <div class="row">
                                    <div v-if="proof.ordered" class="col-2 font-size-18 contact-links">
                                        <a
                                            href="#"
                                            class="drag-handle"
                                            style="cursor: move"
                                            v-b-tooltip.hover
                                            title="Re-order"
                                            @click.prevent="doNothing()"
                                        >
                                            <i class="fas fa-arrow-down-arrow-up"></i>
                                        </a>
                                    </div>
                                    <div class="col" style="overflow-wrap: anywhere">
                                        {{ item.name }}
                                        <small><br>{{ item.email }}</small>
                                    </div>
                                    <div class="col-2 text-center pt-2 pl-3">
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                v-model="item.reviewer"
                                                type="checkbox"
                                                class="custom-control-input"
                                                :id="'reviewer' + proof.id + '_' + item.id"
                                                :disabled="updating"
                                                @click="users_changed = true"
                                            >
                                            <label
                                                class="custom-control-label"
                                                :for="'reviewer' + proof.id + '_' + item.id"
                                            >&nbsp;</label>
                                        </div>
                                    </div>
                                    <div class="col-2 text-center pt-2 pl-3">
                                        <div class="custom-control custom-radio">
                                            <input
                                                v-model="proof_leader"
                                                type="radio"
                                                class="custom-control-input"
                                                :id="'leader' + proof.id + '_' + item.id"
                                                :value="item.id"
                                                :disabled="updating"
                                            >
                                            <label
                                                class="custom-control-label"
                                                :for="'leader' + proof.id + '_' + item.id"
                                            >&nbsp;</label>
                                        </div>
                                    </div>
                                    <div class="col-1 text-right font-size-18 contact-links pt-1">
                                        <a
                                            v-if="(proof_leader != item.id) && !updating"
                                            href="#"
                                            v-b-tooltip.hover.left
                                            title="Remove member"
                                            @click.prevent="removeProofUser(item.id)"
                                        >
                                            <i class="fal fa-trash-alt"></i>
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </draggable>
                </table>
            </div>
        </div>
        <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary"
                @click.prevent="resetProofUserAddForm()"
                style="position: absolute; left: 12px"
                :disabled="updating"
            >
                <i class="far fa-user-plus mr-1"></i> Add members
            </button>
            <span v-if="!updating">
                <button
                    :key="'submit-button'"
                    type="button"
                    class="btn btn-primary"
                    @click.prevent="onSubmit()"
                >
                    <i class="far fa-check mr-1"></i> Finish
                </button>
            </span>
            <span v-else>
                <button :key="'submitting-button'" type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Finishing</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    export default {
        props: ['proof'],
        components: {
            draggable
        },
        data () {
            return {
                component_name: 'proof-team-edit',
                errors: false,
                proof_users: [],
                proof_leader: null,
                updating: false,
                users_changed: false
            }
        },
        mounted () {
            //
        },
        watch: {
            proof_leader: function (val, oldVal) {
                if (oldVal === null) {
                    return null;
                }
                this.users_changed = true;
            }
        },
        methods: {
            reset: function () {
                this.users_changed = false;
                this.errors = false;
                let proofUsers = [];
                this.proof.users.forEach((item) => {
                    proofUsers.push({
                        id: item.user_id,
                        name: item.first_name + ' ' + item.last_name,
                        email: item.email,
                        reviewer: item.reviewer
                    });
                    if (item.leader) {
                        this.proof_leader = item.user_id;
                    }
                });
                this.proof_users = proofUsers;
            },
            doNothing: function () {
                return null;
            },
            resetProofUserAddForm: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'proof-user-add-form');
                if (component) {
                    component.resetForm(this.proof_users, this.proof_leader, this.proof.ordered, this);
                    $('#proof-team-edit-modal').modal('hide');
                    setTimeout(function () {
                        $('#proof-user-add-form-modal').modal('show');
                    }, 500);
                }
            },
            proofUserAdded: function (user) {
                let found = false;
                this.proof_users.forEach((item) => {
                    if (item.id == user.id) {
                        found = true;
                    }
                });
                if (found) {
                    return null;
                }
                this.users_changed = true;
                this.proof_users.push({
                    id: user.id,
                    name: user.first_name + ' ' + user.last_name,
                    email: user.email,
                    reviewer: true
                });
            },
            removeProofUser: function (userId) {
                if (userId == this.proof_leader) {
                    return null;
                }
                let itemIndex = -1;
                this.proof_users.forEach((item, index) => {
                    if (item.id == userId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.users_changed = true;
                    this.proof_users.splice(itemIndex, 1);
                }
            },
            onDragSortEnd: function(event) {
                this.users_changed = true;
            },
            reOrderUsers: function (index, oldIndex) {
                let user = this.proof_users[oldIndex];
                this.proof_users.splice(oldIndex, 1);
                this.proof_users.splice(index, 0, user);
                this.users_changed = true;
            },
            onSubmit: function (onClose = false) {
                if (!this.users_changed) {
                    if (!onClose) {
                        $('#proof-team-edit-modal').modal('hide');
                    }

                    return null;
                }
                this.errors = false;
                this.updating = true;

                /**
                 * Send request to API.
                 */
                let data = {
                    app_info: {
                        app_url: this.$root.app.app_url
                    },
                    users: this.getProofUsersValue()
                };

                const path = '/' + this.$root.app.client_id + '/proof/' + this.proof.id + '/users';
                const url = this.$root.app.api_url + path;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    $('#proof-team-edit-modal').modal('hide');
                    vm.users_changed = false;

                    vm.$parent.loading = true;
                    if (vm.$route.params.tab == 'team') {
                        vm.$parent.getProof(vm.proof.id);

                        return null;
                    }
                    vm.$router.push({
                        name: 'view-proof-tab',
                        params: {
                            id: vm.proof.id,
                            tab: 'team'
                        }
                    });
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            $('#proof-team-edit-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error || 'Something went wrong, please try again';
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            getProofUsersValue: function () {
                let users = [];
                this.proof_users.forEach((item) => {
                    users.push({
                        user_id: item.id,
                        reviewer: item.reviewer,
                        leader: (this.proof_leader == item.id)
                    });
                });

                return users;
            }
        }
    }
</script>
