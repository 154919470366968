<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="template-table-fields-modal-label">{{ table_title }}</h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body px-4">
            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>
            <div v-if="table_rows.length > 0">
                <div class="row">
                    <div v-if="can_sort && (table_rows.length > 2)" class="col-1 px-1" style="max-width: 40px">
                        <label>Sort</label>
                    </div>
                    <div v-for="heading in table_headings" :key="heading" class="col px-1">
                        <label>{{ heading }}</label>
                    </div>
                    <div v-if="!is_full_table && (table_rows.length > 2)" class="col-1 px-1" style="max-width: 50px">
                    </div>
                </div>
                <draggable
                    v-model="visible_rows"
                    ghost-class="vue-drag-ghost"
                    handle=".drag-handle"
                    tag="div"
                    @end="onDragSortEnd"
                    animation="200"
                >
                    <div v-for="row in visible_rows" :key="row.row" class="row">
                        <div
                            v-if="can_sort && (table_rows.length > 2)"
                            class="col-1 px-1 font-size-18 contact-links pt-1"
                            style="max-width: 40px"
                        >
                            <a
                                href="#"
                                class="drag-handle"
                                style="cursor: move"
                                v-b-tooltip.hover
                                title="Re-order"
                                @click.prevent="doNothing()"
                            >
                                <i class="fas fa-arrow-down-arrow-up"></i>
                            </a>
                        </div>
                        <div v-for="item in row.fields" :key="item.id" class="col px-1">
                            <template-field
                                :artwork_editor_component="$parent"
                                :data="item"
                                :profile_id="$parent.profile_id"
                                :readonly="$parent.isProfileMissing()"
                                :reload="count"
                            ></template-field>
                        </div>
                        <div v-if="!is_full_table && (table_rows.length > 2)" class="col-1 px-1" style="max-width: 50px">
                            <button
                                v-if="row.row > 1"
                                type="button"
                                class="btn btn-secondary big-icon btn-block"
                                @click="deleteRow(row)"
                                style="width: 100%"
                                v-b-tooltip.hover.left
                                title="Delete row"
                            >
                                <i class="far fa-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                </draggable>
                <div v-if="num_visible_rows < (table_rows.length - 1)" class="row">
                    <div class="col-1 px-1" style="max-width: 40px">
                    </div>
                    <div class="col px-1">
                        <button type="button" class="btn btn-secondary" @click="addRow()">
                            <i class="far fa-plus mr-1"></i> Add row
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="updateArtwork()">
                <i class="far fa-eye mr-1"></i> Update preview
            </button>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    export default {
        props: ['artwork'],
        components: {
            draggable,
        },
        data () {
            return {
                errors: false,
                table_title: null,
                table_headings: [],
                table_rows: [],
                count: 0,
                num_visible_rows: 0,
                is_full_table: false,
                visible_rows: [],
                can_sort: false
            }
        },
        mounted () {
            //
        },
        watch: {
            num_visible_rows: function (val) {
                if (!val) {
                    return null;
                }
                this.updateVisibleRows(val);
            }
        },
        methods: {
            reset: function (data) {
                this.count++;
                this.errors = false;
                this.table_title = data.title;
                this.table_headings = [];
                this.table_rows = [];
                this.visible_rows = [];
                this.can_sort = false;

                // Build table.
                let numRows = 0;
                let isFullTable = false;
                //let showAllRows = false;
                data.fields.forEach((item) => {
                    let titleParts = item.title.split('_');
                    let heading = titleParts[1];
                    if (this.table_headings.indexOf(heading) < 0) {
                        this.table_headings.push(heading);
                    }
                    let rowNum = parseInt(titleParts[2]);
                    if (rowNum > numRows) {
                        numRows = rowNum;
                    }
                    if (item.title_suffix == '_TF') {
                        isFullTable = true;
                    }
                    /*if ((item.title_suffix == '_TF') || (item.title_suffix == '_TD')) {
                        showAllRows = true;
                    }*/
                    if ((item.title_suffix == '_T') || (item.title_suffix == '_TA') || (item.title_suffix == '_TD')) {
                        this.can_sort = true;
                    }
                });
                this.is_full_table = isFullTable;
                if ((this.table_headings.length == 0) || (numRows == 0)) {
                    this.errors = 'No table fields found.'

                    return null;
                }
                let rows = [{ row: 0 }];
                let numVisibleRows = isFullTable ? numRows : 1;
                for (let row = 1; row <= numRows; row++) {
                    let fields = [];
                    this.table_headings.forEach((item) => {
                        let field = this.getField(data.fields, item, row);
                        if (!isFullTable && field.data) {
                            numVisibleRows = row;
                        }
                        fields.push(field);
                    });
                    rows.push({
                        row: row,
                        fields: fields
                    });
                }
                this.table_rows = rows;
                if (this.num_visible_rows == numVisibleRows) {
                    this.updateVisibleRows(numVisibleRows);

                    return null;
                }
                this.num_visible_rows = numVisibleRows;
            },
            getField: function (fields, heading, row) {
                let found = null;
                fields.forEach((item) => {
                    if (item.title == this.table_title + '_' + heading + '_' + row) {
                        found = item;
                    }
                });
                if (!found) {
                    found = {
                        id: heading + '_' + row,
                        type: 'N'
                    }
                }

                return found;
            },
            updateArtwork: function () {
                $('#template-table-fields-modal').modal('hide');
                this.$parent.screenPreview();
            },
            addRow: function () {
                if (!this.is_full_table && !this.artwork.id) {
                    this.table_rows[this.num_visible_rows + 1].fields.forEach((item) => {
                        if (!item.data && this.$parent.original_field_contents[item.id]) {
                            let updateData = {};
                            if (this.$parent.original_field_contents[item.id].data) {
                                updateData.data = this.$parent.original_field_contents[item.id].data;
                                updateData.data2 = this.$parent.original_field_contents[item.id].data2;
                            }
                            this.$parent.updateTemplateField(item, updateData);
                        }
                    });
                }
                this.num_visible_rows++;
            },
            deleteRow: function (row) {
                const lastRow = this.table_rows.length - 1;
                for (let r = row.row; r < lastRow; r++) {
                    this.table_rows[r].fields.forEach((field) => {
                        let parts = field.title.split('_');
                        let nextField = this.getField(this.table_rows[r + 1].fields, parts[1], r + 1);
                        //
                        let originalData = this.$parent.original_field_contents[nextField.id]
                            ? this.$parent.original_field_contents[nextField.id].data : null;
                        let originalData2 = this.$parent.original_field_contents[nextField.id]
                            ? this.$parent.original_field_contents[nextField.id].data2 : null;
                        let updateData = {
                            data: this.convertData(originalData, nextField.single_line, field.single_line),
                            data2: this.convertData(originalData2, nextField.single_line, field.single_line)
                        };
                        this.$parent.original_field_contents[field.id] = updateData;
                        //
                        updateData = {
                            data: this.convertData(nextField.data, nextField.single_line, field.single_line),
                            data2: this.convertData(nextField.data2, nextField.single_line, field.single_line)
                        };
                        this.$parent.updateTemplateField(field, updateData);
                    });
                }
                let updateData = {
                    data: null,
                    data2: null
                };
                this.table_rows[lastRow].fields.forEach((field) => {
                    if (this.$parent.original_field_contents[field.id]) {
                        this.$parent.original_field_contents[field.id] = updateData;
                    }
                    this.$parent.updateTemplateField(field, updateData);
                });
                this.num_visible_rows--;
                this.count++;
            },
            convertData: function (sourceData, sourceSingleLine, destinationSingleLine) {
                if (!sourceData) {
                    return null;
                }
                if (sourceSingleLine == destinationSingleLine) {
                    return sourceData;
                }
                if (sourceSingleLine) {
                    return '<p>' + sourceData + '</p>';
                }

                return this.stripTags(sourceData);
            },
            stripTags: function (html) {
                if ((html === null) || (html === '')) {
                    return null;
                }
                html = html.toString();

                // Regular expression to identify HTML tags in
                // the input string. Replacing the identified
                // HTML tag with a null string.
                return html.replace(/(<([^>]+)>)/ig, '');
            },
            doNothing: function () {
                return null;
            },
            onDragSortEnd: function(event) {
                if (event.newIndex == event.oldIndex) {
                    return false;
                }
                let rows = [];
                let updateFields = [];
                this.table_rows.forEach((item, index) => {
                    let moved = true;
                    if (item.row == 0) {
                        moved = false;
                    } else if (item.row > this.num_visible_rows) {
                        moved = false;
                    } else if (item.row == this.visible_rows[index - 1].row) {
                        moved = false;
                    }
                    if (moved) {
                        let copyFrom = this.visible_rows[index - 1];
                        if (copyFrom) {
                            item.fields.forEach((field) => {
                                let parts = field.title.split('_');
                                let nextField = this.getField(copyFrom.fields, parts[1], copyFrom.row);
                                //
                                let originalData = this.$parent.original_field_contents[nextField.id]
                                    ? this.$parent.original_field_contents[nextField.id].data : null;
                                let originalData2 = this.$parent.original_field_contents[nextField.id]
                                    ? this.$parent.original_field_contents[nextField.id].data2 : null;
                                let updateData = {
                                    data: this.convertData(originalData, nextField.single_line, field.single_line),
                                    data2: this.convertData(originalData2, nextField.single_line, field.single_line)
                                };
                                this.$parent.original_field_contents[field.id] = updateData;
                                //
                                updateData = {
                                    data: this.convertData(nextField.data, nextField.single_line, field.single_line),
                                    data2: this.convertData(nextField.data2, nextField.single_line, field.single_line)
                                };
                                updateFields.push({
                                    field: field,
                                    updateData: updateData
                                });
                            });
                        }
                    }
                    rows.push(item);
                });

                this.table_rows = rows;
                this.updateVisibleRows(this.num_visible_rows);
                this.count++;

                updateFields.forEach((item) => {
                    this.$parent.updateTemplateField(item.field, item.updateData);
                });
            },
            updateVisibleRows: function (val) {
                let rows = [];
                this.table_rows.forEach((item) => {
                    if ((item.row > 0) && (item.row <= val)) {
                        rows.push(item);
                    }
                });
                this.visible_rows = rows;
            }
        }
    }
</script>
