<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="loading"><loading></loading></div>
        <div v-else>
            <div class="row">
                <div class="col-xl-2 col-lg-3 col-sm-4 mb-3">
                    <div class="bg-light text-center" style="padding: 1px">
                        <img class="img-fluid" :src="image_src" alt="preview">
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div v-if="$root.isFeatured('profiles')" class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">{{ $root.ucfirst($root.contents.profile_title) }}</label>
                                <div v-html="order_details ? order_details.profile_name : ' '"></div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">Ordered by</label>
                                <div v-html="order_details ? order_details.ordered_by_name : ' '"></div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">Order date</label>
                                <div v-html="order_details ? order_details.created_at_formatted : ' '"></div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">{{ $root.ucfirst($root.contents.asset_title) }}</label>
                                <div v-html="data.resource_title"></div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">{{ $root.ucfirst($root.contents.asset_title) }} No.</label>
                                <div v-html="data.asset_id"></div>
                            </div>
                        </div>
                        <div
                            v-if="showArtworkTitle()"
                            class="col-md-4 col-sm-6 col-12"
                        >
                            <div class="form-group">
                                <label class="mb-0">Your title</label>
                                <div v-html="order_details.artwork_title"></div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">Quantity</label>
                                <div v-html="data.quantity"></div>
                            </div>
                        </div>
                        <!--<div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">Cost</label>
                                <div>
                                    <span v-if="(data.currency == 'pound')">
                                        &pound;{{ data.total_price.toFixed(2) }}
                                    </span>
                                    <span v-if="(data.currency == 'euro')">
                                        &euro;{{ data.total_price.toFixed(2) }}
                                    </span>
                                    <span v-if="(data.currency == 'dollar')">
                                        &dollar;{{ data.total_price.toFixed(2) }}
                                    </span>
                                </div>
                            </div>
                        </div>-->
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">Order number</label>
                                <div v-html="data.id"></div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">Supplier</label>
                                <div v-html="order_details ? order_details.supplier_name : ' '"></div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">Order status</label>
                                <div v-html="getOrderStatus()"></div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">Delivery address</label>
                                <div v-html="order_details ? order_details.delivery_address_html : ' '"></div>
                            </div>
                        </div>
                        <div v-if="hasInvoiceAddress()" class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">Invoice address</label>
                                <div v-html="order_details.invoice_address_html"></div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">Additional instructions</label>
                                <div v-html="order_details ? order_details.comments_html : ' '"></div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">Specification</label>
                                <div v-html="order_details ? order_details.specification_html : ' '"></div>
                            </div>
                        </div>
                        <div v-if="$root.contents.print_order_custom_field_1_title" class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">{{ $root.contents.print_order_custom_field_1_title }}</label>
                                <div v-html="data.custom_field_1"></div>
                            </div>
                        </div>
                        <div v-if="$root.contents.print_order_custom_field_2_title" class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">{{ $root.contents.print_order_custom_field_2_title }}</label>
                                <div v-html="data.custom_field_2"></div>
                            </div>
                        </div>
                        <div v-if="$root.contents.print_order_custom_field_3_title" class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">{{ $root.contents.print_order_custom_field_3_title }}</label>
                                <div v-html="data.custom_field_3"></div>
                            </div>
                        </div>
                        <div v-if="$root.contents.print_order_custom_field_4_title" class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">{{ $root.contents.print_order_custom_field_4_title }}</label>
                                <div v-html="data.custom_field_4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <span v-if="!view_only">
                <span v-if="canApprove()">
                    <span v-if="!updating">
                        <button
                            :key="'approve-button'"
                            type="button"
                            class="btn btn-primary mr-sm-2 mb-2 mb-sm-0"
                            @click.prevent="approveOrder()"
                        >
                            <i class="far fa-check mr-1"></i> Approve
                        </button>
                    </span>
                    <span v-else>
                        <button
                            :key="'approve-button-spinning'"
                            type="button"
                            class="btn btn-primary mr-sm-2 mb-2 mb-sm-0"
                        >
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Approving</span>
                        </button>
                    </span>

                    <button
                        :key="'decline-button'"
                        type="button"
                        class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                        @click.prevent="declineOrder()"
                    >
                        <i class="far fa-ban mr-1"></i> Decline
                    </button>
                </span>

                <span v-if="(data.approved || ($route.name == 'print-orders'))">
                    <span v-if="!downloading">
                        <button
                            :key="'download-button'"
                            type="button"
                            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                            @click.prevent="downloadFile()"
                            :disabled="!download_data"
                        >
                            <i class="far fa-download mr-1"></i> Download
                            <span v-if="data.artwork_id">artwork</span>
                            <span v-else>item</span>
                        </button>
                    </span>
                    <span v-else>
                        <button
                            :key="'download-button-spinning'"
                            type="button"
                            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                        >
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Downloading</span>
                        </button>
                    </span>
                </span>

                <button
                    v-if="canChase()"
                    :key="'chase-button'"
                    type="button"
                    class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                    @click.prevent="chaseOrder()"
                >
                    <i class="far fa-bullhorn mr-1"></i> Chase
                </button>

                <span v-if="data.approved && canReOrder()">
                    <span v-if="!reordering">
                        <button
                            :key="'re-order-button'"
                            type="button"
                            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                            @click.prevent="reOrder()"
                        >
                            <i class="far fa-repeat mr-1"></i> Re-order
                        </button>
                    </span>
                    <span v-else>
                        <button
                            :key="'re-order-button-spinning'"
                            type="button"
                            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                        >
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Re-order</span>
                        </button>
                    </span>
                </span>

                <span v-if="canArchive()">
                    <span v-if="!archiving">
                        <button
                            :key="'archive-button'"
                            type="button"
                            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                            @click.prevent="archiveOrder()"
                        >
                            <i class="far fa-archive mr-1"></i> Archive
                        </button>
                    </span>
                    <span v-else>
                        <button
                            :key="'archive-button-spinning'"
                            type="button"
                            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                        >
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Archiving</span>
                        </button>
                    </span>
                </span>

                <span v-if="canDelete()">
                    <span v-if="!deleting">
                        <button
                            :key="'delete-button'"
                            type="button"
                            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                            @click.prevent="deleteOrder()"
                        >
                            <span v-if="data.archived">
                                <i class="far fa-trash-alt mr-1"></i> Delete
                            </span>
                            <span v-else>
                                <i class="far fa-times mr-1"></i> Cancel
                            </span>
                        </button>
                    </span>
                    <span v-else>
                        <button
                            :key="'delete-button-spinning'"
                            type="button"
                            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
                        >
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">{{ data.archived ? 'Deleting' : 'Cancelling' }}</span>
                        </button>
                    </span>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'is_visible', 'view_only'],
        data () {
            return {
                errors: false,
                updating: false,
                image_src: '/images/file-icon.png',
                order_details: null,
                loading: true,
                get_content_response: null,
                download_data: null,
                downloading: false,
                reordering: false,
                asset: null,
                template: null,
                archiving: false,
                deleting: false
            }
        },
        mounted () {
            //
        },
        watch: {
            is_visible: function (val) {
                if (val) {
                    this.getOrderDetails();
                    if (!this.$root.contents.print_order_custom_field_1_title) {
                        this.$root.getContent(this, 'print_order_custom_field_1_title');
                        this.$root.getContent(this, 'print_order_custom_field_2_title');
                        this.$root.getContent(this, 'print_order_custom_field_3_title');
                        this.$root.getContent(this, 'print_order_custom_field_4_title');
                    }
                }
            },
            get_content_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        this.$root.contents[val.key] = val.content;
                    }
                }
            }
        },
        methods: {
            getOrderDetails: function () {
                if (this.order_details) {
                    return null;
                }
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order/' + this.data.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.order_details = response.data.order;
                    if (response.data.preview_image) {
                        vm.image_src = response.data.preview_image;
                    }
                    if (vm.isReport()) {
                        return null;
                    }
                    if (response.data.order.supplier_can_update_status) {
                        vm.$parent.updatePrintOrderData(
                            vm.data.id,
                            'supplier_can_update_status',
                            response.data.order.supplier_can_update_status
                        );
                    }
                    vm.download_data = response.data.download;
                })
                .catch(function (error) {
                    //
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            downloadFile: function () {
                if (this.downloading) {
                    return null;
                }
                this.errors = false;
                this.downloading = true;
                const download = this.download_data;
                /**
                 * Send request to API
                 */
                const data = {
                    item_type: 'print_order_file',
                    item_id: this.data.id,
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                    window.location.href = url;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() =>
                    vm.downloading = false
                );
            },
            canApprove: function () {
                if (this.$route.name != 'print-orders') {
                    return false;
                }
                if (this.data.archived) {
                    return false;
                }
                if (this.data.approved || this.data.declined) {
                    return false;
                }
                if (!this.order_details) {
                    return false;
                }

                return this.$root.hasPermission('printing_financial_approvals');
            },
            approveOrder: function () {
                if (this.order_details.resource_replaced) {
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: null,
                        title: '<i class="far fa-exclamation-triangle font-size-20 text-danger mr-2"></i>'
                            + this.$root.ucfirst(this.$root.contents.asset_title) + ' has been updated',
                        text: 'The order cannot be approved. Use the Decline button to send a message asking '
                            + this.order_details.ordered_by_name + ' to place a new order.'
                    };
                    $('#message-modal').modal('show');

                    return null;
                }
                this.updating = true;
                /**
                 * Send approve order request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order/' + this.data.id
                    + '/approve';
                const data = {
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Show confirmation message.
                    vm.$parent.$parent.message = {
                        type: 'success',
                        title: 'Order approved',
                        text: response.data.message
                    };
                    $('#message-modal').modal('show');
                    vm.$parent.getOrders();

                    // Close after 4 seconds automatically.
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 4000);

                    // Clear notification.
                    vm.$root.clearNotification('admin', 0, 'print_order', vm.data.id);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            declineOrder: function () {
                if (!this.data) {
                    return null;
                }
                const component = this.$root.findComponent(this.$parent, 'decline-print-order');
                if (component) {
                    component.resetForm(this.data, this.order_details.resource_replaced);
                    setTimeout(function() {
                        $('#decline-print-order-modal').modal('show');
                    }, 200);
                }
            },
            canChase: function () {
                if (this.$route.name == 'print-orders') {
                    return false;
                }
                if (this.data.created_by != this.$root.user.id) {
                    return false;
                }
                if (this.data.archived || this.data.declined) {
                    return false;
                }
                if (!this.data.approved) {
                    return true;
                }

                return false;
                //return ((this.data.order_status != 'Dispatched') && (this.data.order_status != 'Completed'));
            },
            chaseOrder: function () {
                if (!this.data) {
                    return null;
                }
                const component = this.$root.findComponent(this.$parent, 'chase-print-order');
                if (component) {
                    component.resetForm(this.data, this.order_details);
                    setTimeout(function() {
                        $('#chase-print-order-modal').modal('show');
                    }, 200);
                }
            },
            canReOrder: function () {
                if (this.$route.name == 'print-orders') {
                    return false;
                }
                if (this.data.created_by != this.$root.user.id) {
                    return false;
                }
                if (!this.order_details) {
                    return false;
                }

                return true;
            },
            reOrder: function () {
                if (this.reordering) {
                    return null;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                if (this.order_details.resource_replaced) {
                    appComponent.message = {
                        type: null,
                        title: 'Re-order',
                        text: '<i class="far fa-exclamation-triangle font-size-20 text-danger mr-2"></i> '
                            + 'This order cannot be re-ordered because the '
                            + (this.data.artwork_id ? this.$root.contents.template_title
                                : this.$root.contents.asset_title)
                            + ' has been updated. Please place a new order.'
                    };
                    $('#message-modal').modal('show');

                    return null;
                }
                if (this.order_details.profile_field_changed) {
                    appComponent.message = {
                        type: null,
                        title: 'Re-order',
                        text: '<i class="far fa-exclamation-triangle font-size-20 text-danger mr-2"></i> '
                            + 'This order cannot be re-ordered because the profile it relates to has been updated. '
                            + 'Please place a new order.'
                    };
                    $('#message-modal').modal('show');

                    return null;
                }
                this.erros = false;
                if (!this.asset) {
                    this.getAsset();

                    return null;
                }
                if (this.asset.archived) {
                    this.errors = 'This order cannot be re-ordered because the ' + this.$root.contents.asset_title
                        + ' has been archived.';

                    return null;
                }
                if (this.asset.template) {
                    if (!this.template.output_order) {
                        this.errors = 'This order cannot be re-ordered because the ' + this.$root.contents.asset_title
                            + ' is no longer available to order.';

                        return null;
                    }
                }
                if (!this.asset.template && !this.asset.print_order) {
                    this.errors = 'This order cannot be re-ordered because the ' + this.$root.contents.asset_title
                        + ' is no longer available to order.';

                    return null;
                }
                if (this.$root.user.currency != this.data.currency) {
                    this.errors = 'You cannot re-order this item because your currency has changed.';

                    return null;
                }

                const component = this.$root.findComponent(appComponent, 'print-order-form');
                let order = this.data;
                order.file = this.download_data;
                if (component) {
                    component.resetForm(this.asset, this.template, null, order);
                    $('#print-order-modal').modal('show');
                }
            },
            canArchive: function () {
                if (this.data.archived) {
                    return false;
                }
                if (
                    (this.data.created_by != this.$root.user.id)
                    && !this.$root.hasPermission('printing_orders_archive')
                ) {
                    return false;
                }

                return (this.data.approved || this.data.declined);
            },
            archiveOrder: function (confirmed = false) {
                this.errors = false;
                if (confirmed) {
                    this.archiving = true;
                    /**
                     * Send archive print order request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order/'
                        + this.data.id + '/archive';
                    var vm = this;

                    axios({
                        method: 'put',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        // Show confirmation message.
                        vm.$parent.$parent.message = {
                            type: 'success',
                            title: 'Order archved',
                            text: response.data.message
                        };
                        $('#message-modal').modal('show');
                        vm.$parent.removeOrder(vm.data.id);

                        // Close after 4 seconds automatically.
                        setTimeout(function() {
                            $('#message-modal').modal('hide');
                        }, 4000);
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    })
                    .finally(() => {
                        vm.archiving = false;
                    });

                    return null;
                }

                if (this.updating) {
                    return null;
                }

                this.$parent.$parent.confirm_delete = {
                    title: 'Archive order',
                    text: 'Are you sure you want to archive order ' + this.data.id + '?',
                    component: this,
                    action: 'archive'
                };
                $('#confirm-delete-modal').modal('show');
            },
            canDelete: function () {
                if (this.$route.name != 'print-orders') {
                    return false;
                }
                if (!this.$root.hasPermission('printing_orders_delete')) {
                    return false;
                }
                if (
                    (this.data.order_status == 'In production')
                    || (this.data.order_status == 'Dispatched')
                    || (this.data.order_status == 'Completed')
                ) {
                    return false;
                }

                return (this.data.approved || this.data.archived);
            },
            deleteOrder: function (confirmed = false) {
                this.errors = false;
                if (confirmed) {
                    this.deleting = true;
                    /**
                     * Send delete print order request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order/' + this.data.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        // Show confirmation message.
                        vm.$parent.$parent.message = {
                            type: 'success',
                            title: 'Order ' + (vm.data.archived ? 'deleted' : 'cancelled'),
                            text: response.data.message
                        };
                        $('#message-modal').modal('show');
                        vm.$parent.removeOrder(vm.data.id);

                        // Close after 4 seconds automatically.
                        setTimeout(function() {
                            $('#message-modal').modal('hide');
                        }, 4000);
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    })
                    .finally(() => {
                        vm.deleting = false;
                    });

                    return null;
                }

                if (this.updating) {
                    return null;
                }

                this.$parent.$parent.confirm_delete = {
                    title: (this.data.archived ? 'Delete' : 'Cancel') + ' order',
                    text: 'Are you sure you want to ' + (this.data.archived ? 'delete' : 'cancel') + ' order '
                        + this.data.id + '?',
                    component: this,
                    action: 'delete'
                };
                $('#confirm-delete-modal').modal('show');
            },
            getAsset: function () {
                this.reordering = true;
                /**
                 * Send get asset details request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/' + this.data.asset_id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.asset = response.data.resource;
                    if (vm.asset.template) {
                        this.getTemplate(vm.asset.id);

                        return null;
                    }
                    vm.reordering = false;
                    setTimeout(function () {
                        vm.reOrder();
                    }, 200);
                })
                .catch(function (error) {
                    vm.reordering = false;
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                });
            },
            getTemplate: function (assetId) {
                /**
                 * Send get template details request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + assetId;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.template = response.data.template;
                    setTimeout(function () {
                        vm.reOrder();
                    }, 200);
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.reordering = false;
                });
            },
            confirmDelete: function (action) {
                if (action == 'archive') {
                    this.archiveOrder(true);
                }
                if (action == 'delete') {
                    this.deleteOrder(true);
                }
            },
            showArtworkTitle: function () {
                if (!this.order_details) {
                    return false;
                }
                if (this.$route.name != 'my-account-print-orders') {
                    return false;
                }
                if (this.order_details.artwork_title && (this.order_details.artwork_created_by == this.$root.user.id)) {
                    return true;
                }

                return false;
            },
            isReport: function () {
                return (this.$route.name == 'reports-orders');
            },
            hasInvoiceAddress: function () {
                if (!this.order_details) {
                    return false;
                }
                if (!this.order_details.invoice_address_html) {
                    return false;
                }

                return true;
            },
            getOrderStatus: function () {
                let statusText = '';
                if (this.data.declined) {
                    if (!this.order_details) {
                        statusText = 'Declined';
                    } else if (!this.order_details.declined_by_name) {
                        statusText = 'Declined ' + this.order_details.declined_at_formatted;
                    } else {
                        statusText = 'Declined by ' + this.order_details.declined_by_name + ' &nbsp; '
                            + this.order_details.declined_at_formatted;
                    }
                    if (this.data.reason_for_decline) {
                        statusText += '<br><br>Reason for decline: ' + this.data.reason_for_decline;
                    }
                } else if (this.data.approved) {
                    if (this.data.order_status != 'Approved') {
                        statusText = this.data.order_status + ' ' + this.$parent.getStatusDate(this.data);
                        if (this.order_details) {
                            statusText += '<br>';
                        }
                    }
                    if (!this.order_details) {
                        if (this.data.order_status == 'Approved') {
                            statusText += 'Approved';
                        }
                    } else if (!this.order_details.approved_by_name) {
                        statusText += 'Approved ' + this.order_details.approved_at_formatted;
                    } else {
                        statusText += 'Approved by ' + this.order_details.approved_by_name + ' &nbsp; '
                            + this.order_details.approved_at_formatted;
                    }
                } else {
                    statusText = 'Awaiting approval';
                }
                if (this.data.archived) {
                    statusText += ', archived';
                }

                return statusText;
            }
        }
    }
</script>
<style scoped>
    img {
        max-height: 200px;
    }

    @media (max-width: 575px) {
        .btn {
            display: block;
            width: 100%;
        }
    }
</style>
