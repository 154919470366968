<template>
    <div>
        <div v-click-outside="config" class="right-bar scrollable">
            <div>
                <div class="rightbar-title px-3 py-4">
                    <a href="#" class="right-bar-toggle float-right" @click.prevent="hide()">
                        <i class="far fa-times noti-icon" style="font-size: 12px"></i>
                    </a>
                    <h5 class="m-0">Filters</h5>
                </div>

                <hr class="mt-0">

                <div class="pl-3 pr-3 pt-1 pb-3">
                    <div v-if="(hasResources() && hasTemplates())" class="mb-3">
                        <div class="btn-group btn-block" role="group">
                            <button
                                type="button"
                                :class="'btn btn-' + ((template === null) ? 'primary' : 'light')"
                                @click.prevent="template = null"
                            >All</button>
                            <!--<button
                                type="button"
                                :class="'btn btn-' + ((template === 0) ? 'primary' : 'light')"
                                @click.prevent="template = 0"
                            >Standard</button>-->
                            <button
                                type="button"
                                :class="'btn btn-' + ((template === 1) ? 'primary' : 'light')"
                                @click.prevent="template = 1"
                            >Editable only</button>
                        </div>
                    </div>

                    <form
                        :class="($parent.component_name == 'view-collection') ? '' : 'd-md-none'"
                        v-on:submit.prevent="keywordSearch()"
                    >
                        <div class="form-group keyword">
                            <div class="input-group">
                                <input
                                    v-model="keyword"
                                    type="text"
                                    class="form-control"
                                    id="filter-by-keyword"
                                    :placeholder="($parent.component_name == 'view-collection') ? 'Filter by keyword'
                                        : 'Search within these results'"
                                    :aria-label="($parent.component_name == 'view-collection') ? 'Filter by keyword'
                                        : 'Search within these results'"
                                    autocomplete="off"
                                    @focus="onFocus()"
                                    @keydown="onKeydown($event)"
                                    @keyup="onKeyup($event)"
                                >
                                <div class="input-group-append">
                                    <button class="btn btn-primary big-icon" type="submit">
                                        <i class="fal fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <button v-if="keyword" class="btn btn-link clear-keyword" @click.prevent="clearKeyword()">
                                <i class="far fa-times"></i>
                            </button>
                            <div v-if="$root.client.asset_search_autocomplete" class="dropdown">
                                <div
                                    class="dropdown-menu search-autocomplete"
                                    id="search-autocomplete-aft"
                                >
                                    <a
                                        v-for="option in autocomplete_options"
                                        :key="option.id"
                                        :id="'search-autocomplete-aft-option' + option.id"
                                        href="#"
                                        :class="'dropdown-item'
                                            + ((option.id == selected_autocomplete_option) ? ' active' : '')"
                                        style="white-space: normal"
                                        @click.prevent="getAsset(option)"
                                    >{{ option.name }}</a>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div v-if="($parent.component_name != 'view-collection')" class="form-group">
                        <label>Order results by</label>
                        <div class="form-control" style="height: auto">
                            <div class="mb-2">
                                <div class="custom-control custom-radio" style="display: inline-block">
                                    <input
                                        v-model="sort_by"
                                        type="radio"
                                        id="sort-by-newest"
                                        class="custom-control-input"
                                        value="newest"
                                    >
                                    <label class="custom-control-label" for="sort-by-newest">
                                        Newest first
                                    </label>
                                </div>
                            </div>
                            <div class="mb-2">
                                <div class="custom-control custom-radio" style="display: inline-block">
                                    <input
                                        v-model="sort_by"
                                        type="radio"
                                        id="sort-by-oldest"
                                        class="custom-control-input"
                                        value="oldest"
                                    >
                                    <label class="custom-control-label" for="sort-by-oldest">
                                        Oldest first
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div class="custom-control custom-radio" style="display: inline-block">
                                    <input
                                        v-model="sort_by"
                                        type="radio"
                                        id="sort-by-relevance"
                                        class="custom-control-input"
                                        value="relevance"
                                        :disabled="!hasKeywords()"
                                    >
                                    <label
                                        :class="'custom-control-label' + (!hasKeywords() ? ' text-muted' : '')"
                                        for="sort-by-relevance"
                                    >Relevance</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group date-range">
                        <label for="filter-by-date-range">Date added</label>
                        <date-range-picker
                            v-model="date_range"
                            :opens="'left'"
                            :locale-data="{ format: 'dd/mm/yyyy', firstDay: 1 }"
                            :max-date="new Date()"
                            @toggle="setRightBarStyle"
                            @update="updateDateRange"
                            :autoApply="true"
                        >
                            <template v-slot:input="picker">
                                <span v-html="showDateRange(picker)"></span>
                            </template>
                        </date-range-picker>
                        <button
                            v-if="(date_range.startDate != null)"
                            class="btn btn-link clear-date-range"
                            @click.prevent="clearDateRange()"
                        >
                            <i class="far fa-times"></i>
                        </button>
                    </div>

                    <div v-for="cf in custom_fields" :key="cf.id">
                        <div v-if="cf.filter">
                            <form
                                v-if="((cf.type == 'text') || (cf.type == 'textarea'))"
                                v-on:submit.prevent="customFieldSearch(cf.id, true)"
                            >
                                <div class="form-group keyword">
                                    <label :for="'filter-by-cf' + cf.id">{{ cf.name }}</label>
                                    <div class="input-group">
                                        <input
                                            v-model="custom_field[cf.id]"
                                            type="text"
                                            class="form-control"
                                            :id="'filter-by-cf' + cf.id"
                                            :placeholder="placeholder('filter-by-cf' + cf.id)"
                                            v-on:focus="placeholder('filter-by-cf' + cf.id, true)"
                                            v-on:blur="placeholder('filter-by-cf' + cf.id, true)"
                                            aria-label="Enter keyword"
                                        >
                                        <div class="input-group-append">
                                            <button class="btn btn-primary big-icon" type="submit">
                                                <i class="fal fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        v-if="custom_field[cf.id]"
                                        class="btn btn-link clear-keyword custom-field"
                                        @click.prevent="clearKeyword(cf.id)"
                                    >
                                        <i class="far fa-times"></i>
                                    </button>
                                </div>
                            </form>
                            <div v-if="((cf.type == 'multiselect') || (cf.type == 'singleselect'))" class="form-group">
                                <label :for="'filter-by-cf' + cf.id">{{ cf.name }}</label>
                                <searchable-option-list
                                    v-model="custom_field[cf.id]"
                                    :value="custom_field[cf.id]"
                                    :options="getCustomFieldOptions(cf)"
                                    :multiple="true"
                                    :field_id="'filter-by-cf' + cf.id"
                                    :search_placeholder="'Select ' + cf.name.toLowerCase()"
                                    v-on:change="customFieldSearch(cf.id)"
                                ></searchable-option-list>
                                <!-- v-on:sol-dropdown="cfDropdown" -->
                            </div>
                        </div>
                    </div>

                    <div v-if="(getAssetTypeOptions().length > 0)" class="form-group">
                        <label>{{ $root.ucfirst($root.contents.asset_title) }} type</label>
                        <plain-option-list
                            v-model="asset_type"
                            :value="asset_type"
                            :multiple="true"
                            :options="getAssetTypeOptions()"
                            field_id="filter-by-asset-type"
                            field_class="form-control p-0"
                            max_height="160"
                        ></plain-option-list>
                    </div>

                    <div v-if="(getOrientationOptions().length > 0)" class="form-group">
                        <label for="filter-by-orientation">Orientation</label>
                        <searchable-option-list
                            v-model="orientation"
                            :value="orientation"
                            :options="getOrientationOptions()"
                            field_id="filter-by-orientation"
                            search_placeholder="Select orientation"
                            v-on:sol-dropdown="cfDropdown"
                        ></searchable-option-list>
                    </div>

                    <div class="form-group">
                        <label for="filter-by-uploader">Uploaded by</label>
                        <searchable-option-list
                            v-model="uploader"
                            :value="uploader"
                            :options="getUploaderOptions()"
                            field_id="filter-by-uploader"
                            search_placeholder="Select user"
                            v-on:sol-dropdown="cfDropdown"
                        ></searchable-option-list>
                    </div>

                    <div v-if="(getUserGroupOptions().length > 0)" class="form-group" style="position: relative">
                        <div
                            v-if="(getUserGroupOptions(true).length > 1)"
                            class="custom-control custom-checkbox"
                            style="position: absolute; right: 0px"
                        >
                            <input
                                v-model="select_all_user_groups"
                                type="checkbox"
                                class="custom-control-input"
                                id="select-all-user-groups"
                            >
                            <label class="custom-control-label" for="select-all-user-groups">
                                Select all
                            </label>
                        </div>
                        <label for="filter-by-user-group">User group</label>
                        <searchable-option-list
                            v-model="user_group"
                            :value="user_group"
                            :multiple="true"
                            :options="getUserGroupOptions()"
                            field_id="filter-by-user-group"
                            search_placeholder="Select user group(s)"
                            v-on:sol-dropdown="cfDropdown"
                        ></searchable-option-list>
                    </div>

                    <div class="form-group">
                        <label for="filter-by-restricted">Approval</label>
                        <searchable-option-list
                            v-model="restricted"
                            :value="restricted"
                            :multiple="false"
                            :options="restricted_options"
                            field_id="filter-by-restricted"
                            search_placeholder="Select"
                            v-on:sol-dropdown="cfDropdown"
                        ></searchable-option-list>
                    </div>
                </div>
            </div>
        </div>

        <div class="rightbar-overlay"></div>
    </div>
</template>

<script>
    import DateRangePicker from 'vue2-daterange-picker';
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
    export default {
        components: { DateRangePicker },
        props: [
            'asset_filters',
            'asset_result_types',
            'asset_result_orientations',
            'asset_result_uploaders',
            'asset_result_user_groups',
            'asset_result_custom_fields',
            'folder',
            'assets_info'
        ],
        data() {
            return {
                config: {
                    handler: this.handleRightBarClick,
                    middleware: this.middleware,
                    events: ['click']
                },
                keyword: '',
                asset_types: [],
                asset_type: [],
                orientations: [
                    {
                        id: 'P',
                        name: 'Portrait',
                    },
                    {
                        id: 'L',
                        name: 'Landscape',
                    },
                    {
                        id: 'S',
                        name: 'Square',
                    }
                ],
                orientation: null,
                date_range: {
                    startDate: null,
                    endDate: null
                },
                uploaders: [],
                uploader: null,
                custom_fields: [],
                custom_field: {},
                user_groups: [],
                user_group: [],
                select_all_user_groups: false,
                restricted: null,
                restricted_options: [
                    {
                        id: 1,
                        name: 'Approval required',
                    },
                    {
                        id: 0,
                        name: 'No approval required',
                    },
                    {
                        id: null,
                        name: 'All ' + this.$root.contents.asset_title + 's'
                    }
                ],
                disable_asset_type_search: false,
                sort_by: 'newest',
                template: null,
                autocomplete_options: [],
                selected_autocomplete_option: null,
                last_key: null,
                keypress_time: 0
            };
        },
        mounted () {
            this.getAssetTypes();
            if (this.asset_result_uploaders) {
                this.getUploaders(this.asset_result_uploaders);
            }
            this.getCustomFields();
            this.getUserGroups();
            var vm = this;
            $(document).on('click', function () {
                $('#search-autocomplete-aft').hide();
                vm.selected_autocomplete_option = null;
                vm.last_key = null;
            });
        },
        watch: {
            asset_filters: function (val) {
                this.updateFilters(val);
            },
            asset_result_uploaders: function (val) {
                this.getUploaders(val);
            },
            asset_result_types: function (val) {
                if (!this.$root.client.asset_type_filter_menu) {
                    return null;
                }
                let assetTypes = [];
                if (val) {
                    for (const [key, value] of Object.entries(val)) {
                        if (value.filtered > 0) {
                            assetTypes.push(parseInt(key));
                        }
                    }
                }
                this.disable_asset_type_search = true;
                this.asset_type = assetTypes;
            },
            asset_type: function (val) {
                if (this.disable_asset_type_search) {
                    this.disable_asset_type_search = false;

                    return null;
                }
                this.assetTypeSearch(val);
            },
            orientation: function (val) {
                this.orientationSearch();
            },
            user_group: function (val, oldVal) {
                if (val.length != oldVal.length) {
                    this.userGroupSearch(val);
                }
            },
            select_all_user_groups: function (val) {
                const userGroupOptions = this.getUserGroupOptions(true);
                let userGroupIds = [];
                userGroupOptions.forEach((item) => {
                    userGroupIds.push(item.id);
                });
                if (val) {
                    if (this.user_group.length < userGroupIds.length) {
                        this.user_group = userGroupIds;
                    }
                } else {
                    if (this.user_group.length == userGroupIds.length) {
                        this.user_group = [];
                    }
                }
            },
            uploader: function (val) {
                this.uploaderSearch();
            },
            folder: function (val) {
                this.getCustomFields();
            },
            restricted: function (val) {
                this.restrictedSearch(val);
            },
            sort_by: function (val) {
                if (!val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.sort_by = 'newest';
                    }, 200);

                    return null;
                }

                let text = 'Newest first';
                if (val == 'oldest') {
                    text = 'Oldest first';
                }
                if (val == 'relevance') {
                    text = 'Relevance';
                }
                this.$root.addFilter('asset_filters', {
                    key: 'sort_by',
                    value: val,
                    text: 'Order results by: ' + text,
                    filters_key: 'asset_filters'
                });
                this.$parent.getAssets();
                console.log('get assets: sorting');
            },
            template: function (val) {
                if (val !== null) {
                    this.$root.addFilter('asset_filters', {
                        key: 'template',
                        value: this.template,
                        text: this.template ? 'Editable' : 'Standard',
                        filters_key: 'asset_filters'
                    });
                    this.$parent.getAssets();
                    console.log('get assets:', this.template ? 'Editable' : 'Standard');

                    return null;
                }
                this.$root.addFilter('asset_filters', {
                    key: 'template',
                    value: null
                });
                this.$parent.getAssets();
                console.log('get assets:', 'All');
            }
        },
        methods: {
            hide() {
                $('body').toggleClass('right-bar-enabled');
            },
            handleRightBarClick(e, el) {
                $('body').removeClass('right-bar-enabled');
            },
            middleware(event, el) {
                if (!event.target.classList) {
                    return false;
                }

                return !event.target.classList.contains('toggle-right');
            },
            updateFilters: function (filters) {
                let assetTypeFound = [];
                let orientationFound = false;
                let dateRangeFound = false;
                const dateRangeString = (this.date_range.startDate + '-' + this.date_range.endDate);
                let uploaderFound = false;
                let customFieldFound = {};
                let customFieldId = 0;
                let userGroupFound = [];
                let restrictedFound = null;
                let sortByFound = null;
                let templateFound = null;
                filters.forEach((item) => {
                    if (item.key == 'asset_type') {
                        assetTypeFound.push(item.value);
                    }
                    if (item.key == 'orientation') {
                        orientationFound = item.value;
                    }
                    if (item.key == 'date_range') {
                        dateRangeFound = item.value;
                    }
                    if (item.key == 'uploader') {
                        uploaderFound = item.value;
                    }
                    if (item.key.substr(0, 2) == 'cf') {
                        customFieldId = parseInt(item.key.substr(2));
                        if (this.isCustomFieldSelect(customFieldId)) {
                            if (!(item.key in customFieldFound)) {
                                customFieldFound[item.key] = [];
                            }
                            let index = this.getCustomFieldOptionIndex(customFieldId, item.value);
                            if (index > -1) {
                                customFieldFound[item.key].push(index);
                            }
                        } else {
                            customFieldFound[item.key] = item.value;
                        }
                    }
                    if (item.key == 'user_group') {
                        userGroupFound.push(item.value);
                    }
                    if (item.key == 'restricted') {
                        restrictedFound = 1;
                    }
                    if (item.key == '!restricted') {
                        restrictedFound = 0;
                    }
                    if (item.key == 'sort_by') {
                        sortByFound = item.value;
                    }
                    if (item.key == 'template') {
                        templateFound = item.value;
                    }
                });
                if (!this.$root.client.asset_type_filter_menu && (this.asset_type.length > assetTypeFound.length)) {
                    this.asset_type = assetTypeFound;
                }
                this.orientation = orientationFound ? orientationFound : null;
                this.date_range = dateRangeFound
                    ? { startDate: dateRangeFound.substr(0, 10), endDate: dateRangeFound.substr(11, 10) }
                    : { startDate: null, endDate: null };
                this.uploader = uploaderFound ? uploaderFound : null;
                this.custom_fields.forEach((item) => {
                    let filterKey = 'cf' + item.id;
                    if (filterKey in customFieldFound) {
                        this.custom_field[item.id] = customFieldFound[filterKey];
                    } else if (item.id in this.custom_field) {
                        delete this.custom_field[item.id];
                    }
                });
                if (this.user_group.length > userGroupFound.length) {
                    this.user_group = userGroupFound;
                }
                this.restricted = restrictedFound;
                this.sort_by = sortByFound;
                this.template = templateFound;
            },
            keywordSearch: function () {
                let keywordTrimmed = this.keyword.trim();
                if (keywordTrimmed == '') {
                    return null;
                }
                if (this.$parent.component_name == 'view-collection') {
                    let keywordsAdded = [];
                    this.$root.asset_filters.forEach((item) => {
                        if (item.key == 'keyword') {
                            keywordsAdded.push(item.value);
                        }
                    });
                    if (keywordsAdded.indexOf(keywordTrimmed) > -1) {
                        return null;
                    }
                    if (this.$parent.component_name == 'view-collection') {
                        this.removeKeywordFilters();
                    }
                    this.$root.addFilter('asset_filters', {
                        key: 'keyword',
                        value: keywordTrimmed,
                        text: '&quot;' + keywordTrimmed.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + '&quot;',
                        filters_key: 'asset_filters'
                    });
                    this.$parent.getAssets();

                    return null;
                }
                let keywords = [];
                const keywordLength = keywordTrimmed.length;
                if (
                    (keywordTrimmed.substring(0, 1) == '"')
                    && (keywordTrimmed.substring(keywordLength - 1) == '"')
                ) {
                    keywords.push(keywordTrimmed.substring(1, keywordLength - 1));
                } else {
                    keywords = keywordTrimmed.split(',');
                }
                let keywordsAdded = [];
                keywords.forEach((keyword) => {
                    keywordTrimmed = keyword.trim();
                    if ((keywordTrimmed != '') && (keywordsAdded.indexOf(keywordTrimmed) < 0)) {
                        keywordsAdded.push(keywordTrimmed);
                    }
                });
                if (keywordsAdded.length > 0) {
                    this.$root.addFilter('asset_filters', {
                        key: 'keyword',
                        value: keywordsAdded.join('||'),
                        text: '&quot;' + keywordsAdded.join('&quot;, &quot;').replace(/"/g, '&quot;')
                            .replace(/'/g, '&apos;') + '&quot;',
                        filters_key: 'asset_filters'
                    });
                }
                this.keyword = '';
                this.$parent.getAssets();
            },
            assetTypeSearch: function(assetTypes) {
                let filtersAddedOrRemoved = 0;
                let filters = this.$root.asset_filters;
                let found = false;
                // Add
                assetTypes.forEach((item) => {
                    found = false;
                    filters.forEach((filter) => {
                        if ((filter.key == 'asset_type') && (filter.value == item)) {
                            found = true;
                        }
                    });
                    if (!found) {
                        this.$root.addFilter('asset_filters', {
                            key: 'asset_type',
                            value: item,
                            text: this.getAssetTypeName(item).replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                            filters_key: 'asset_filters'
                        });
                        filtersAddedOrRemoved++;
                    }
                });
                // Remove
                for (let itemIndex = (filters.length -1); itemIndex >= 0; itemIndex--) {
                    if (filters[itemIndex].key == 'asset_type') {
                        if (assetTypes.indexOf(filters[itemIndex].value) < 0) {
                            filters.splice(itemIndex, 1);
                            filtersAddedOrRemoved++;
                        }
                    }
                }
                // If an asset type filter added or removed make the rest manual and reload assets
                if (filtersAddedOrRemoved > 0) {
                    this.$root.asset_filters.forEach((filter) => {
                        if (filter.key == 'asset_type') {
                            filter.auto = false;
                        }
                    });
                    this.$parent.getAssets();
                    console.log('get assets: filter added/removed: asset type');
                }
            },
            userGroupSearch: function(userGroups) {
                let filters = this.$root.asset_filters;
                let numUserGroupFilters = 0;
                filters.forEach((item) => {
                    if (item.key == 'user_group') {
                        numUserGroupFilters++;
                    }
                });
                if (userGroups.length != numUserGroupFilters) {
                    // Remove all user group filters
                    for (let itemIndex = (filters.length -1); itemIndex >= 0; itemIndex--) {
                        if (filters[itemIndex].key == 'user_group') {
                            filters.splice(itemIndex, 1);
                        }
                    }
                    // Add selected ones.
                    userGroups.forEach((item) => {
                        this.$root.addFilter('asset_filters', {
                            key: 'user_group',
                            value: item,
                            text: this.getUserGroupName(item).replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                            filters_key: 'asset_filters'
                        });
                    });
                    this.$parent.getAssets();
                    console.log('get assets: filter added/removed: user group');
                    // Select all?
                    const numUserGroupOptions = this.getUserGroupOptions(true).length;
                    if ((userGroups.length < numUserGroupOptions) && this.select_all_user_groups) {
                        this.select_all_user_groups = false;
                    } else if ((userGroups.length == numUserGroupOptions) && !this.select_all_user_groups) {
                        this.select_all_user_groups = true;
                    }
                }
            },
            orientationSearch: function () {
                let orientation = null;
                this.orientations.forEach((item) => {
                    if (item.id == this.orientation) {
                        orientation = item;
                    }
                });
                if (orientation) {
                    this.$root.addFilter('asset_filters', {
                        key: 'orientation',
                        value: orientation.id,
                        text: orientation.name,
                        filters_key: 'asset_filters'
                    });
                    this.$parent.getAssets();
                    console.log('get assets: filter added: orientation');

                    return null;
                }
                // Otherwise remove orientation filter.
                this.$root.addFilter('asset_filters', {
                    key: 'orientation',
                    value: null
                });
                this.$parent.getAssets();
                console.log('get assets: filter removed: orientation');
            },
            dateRangeSearch: function () {
                if (this.date_range.startDate != null) {
                    this.$root.addFilter('asset_filters', {
                        key: 'date_range',
                        value: this.date_range.startDate + '-' + this.date_range.endDate,
                        text: this.formatDate(this.date_range.startDate) + ' - '
                            + this.formatDate(this.date_range.endDate),
                        filters_key: 'asset_filters'
                    });
                    this.$parent.getAssets();
                    console.log('get assets: filter added: date range');

                    return null;
                }
                // Otherwise remove date range filter.
                this.$root.addFilter('asset_filters', {
                    key: 'date_range',
                    value: null
                });
                this.$parent.getAssets();
                console.log('get assets: filter removed: date range');
            },
            uploaderSearch: function () {
                let user = null;
                this.uploaders.forEach((item) => {
                    if (item.id == this.uploader) {
                        user = item;
                    }
                });
                if (user) {
                    this.$root.addFilter('asset_filters', {
                        key: 'uploader',
                        value: user.id,
                        text: user.first_name.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + ' '
                            + user.last_name.replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                        filters_key: 'asset_filters'
                    });
                    this.$parent.getAssets();
                    console.log('get assets: filter added: uploader');

                    return null;
                }
                // Otherwise remove uploader filter.
                this.$root.addFilter('asset_filters', {
                    key: 'uploader',
                    value: null
                });
                this.$parent.getAssets();
                console.log('get assets: filter removed: uploader');
            },
            restrictedSearch: function (val) {
                if (val !== null) {
                    this.$root.addFilter('asset_filters', {
                        key: val ? 'restricted' : '!restricted',
                        value: 1,
                        text: val ? this.restricted_options[0].name : this.restricted_options[1].name,
                        filters_key: 'asset_filters'
                    });
                    this.$root.addFilter('asset_filters', {
                        key: val ? '!restricted' : 'restricted',
                        value: null
                    });
                    this.$parent.getAssets();
                    console.log('get assets: filter added: restricted');

                    return null;
                }
                // Otherwise remove restricted filter.
                this.$root.addFilter('asset_filters', {
                    key: 'restricted',
                    value: null
                });
                this.$root.addFilter('asset_filters', {
                    key: '!restricted',
                    value: null
                });
                this.$parent.getAssets();
                console.log('get assets: filter removed: restricted');
            },
            customFieldSearch: function (customFieldId, keyword = false) {
                const value = this.getCustomFieldValue(customFieldId);
                if (keyword) {
                    if (value) {
                        let isFilterMenu = false;
                        this.custom_fields.forEach((item) => {
                            if ((item.id == customFieldId) && item.filter_menu) {
                                isFilterMenu = true;
                            }
                        });
                        this.$root.addFilter('asset_filters', {
                            key: 'cf' + customFieldId,
                            value: value,
                            text: '&quot;' + value.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + '&quot;',
                            filters_key: 'asset_filters',
                            filter_menu: isFilterMenu
                        });
                        this.$parent.getAssets();
                        console.log('get assets: filter added: custom field');

                        return null;
                    }
                    // Otherwise remove custom field filter.
                    let filterIndex = -1;
                    this.asset_filters.forEach((item, index) => {
                        if (item.key == ('cf' + customFieldId)) {
                            filterIndex = index;
                        }
                    });
                    if (filterIndex > -1) {
                        this.$root.asset_filters.splice(filterIndex, 1);
                        this.$parent.getAssets();
                        console.log('get assets: filter removed: custom field');
                    }

                    return null;
                }
                let filtersAddedOrRemoved = 0;
                let filters = this.$root.asset_filters;
                let found = false;
                // Add
                value.forEach((item) => {
                    found = false;
                    filters.forEach((filter) => {
                        if ((filter.key == 'cf' + customFieldId) && (filter.value == item)) {
                            found = true;
                        }
                    });
                    if (!found) {
                        this.$root.addFilter('asset_filters', {
                            key: 'cf' + customFieldId,
                            value: item,
                            text: item.replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                            filters_key: 'asset_filters',
                        });
                        filtersAddedOrRemoved++;
                    }
                });
                // Remove
                for (let itemIndex = (filters.length -1); itemIndex >= 0; itemIndex--) {
                    if (filters[itemIndex].key == 'cf' + customFieldId) {
                        if (value.indexOf(filters[itemIndex].value) < 0) {
                            filters.splice(itemIndex, 1);
                            filtersAddedOrRemoved++;
                        }
                    }
                }
                // If a custom field filter added or removed make the rest manual and reload assets.
                if (filtersAddedOrRemoved > 0) {
                    this.$root.asset_filters.forEach((filter) => {
                        if (filter.key == 'cf' + customFieldId) {
                            filter.auto = false;
                        }
                    });
                    this.$parent.getAssets();
                    console.log('get assets: filter added/removed: custom field');
                }
            },
            clearKeyword: function (customFieldId = null) {
                if (customFieldId) {
                    this.custom_field[customFieldId] = '';
                    delete this.custom_field[customFieldId];
                    this.customFieldSearch(customFieldId, true);

                    return null;
                }
                this.keyword = '';
                if (this.$parent.component_name == 'view-collection') {
                    this.removeKeywordFilters();
                    this.$parent.getAssets();
                }
                $('#filter-by-keyword').focus();
            },
            getUploaders: function (asset_result_uploaders) {
                let userIds = [];
                if (asset_result_uploaders) {
                    for (const [key, value] of Object.entries(asset_result_uploaders)) {
                        userIds.push(key);
                    }
                } else if (this.uploader) {
                    userIds.push(this.uploader);
                }
                if (userIds.length == 0) {
                    this.uploaders = [];
                    this.uploader = null;

                    return null;
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|in::' + userIds.join(',');
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.uploaders = [];
                    let itemIndex = -1;
                    response.data.users.forEach((item, index) => {
                        vm.uploaders.push(item);
                        if (item.id == vm.uploader) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex < 0) {
                        vm.uploader = null;
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    // Do anything else?
                });
            },
            getAssetTypes: function() {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource-types/!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.asset_types = response.data.resource_types;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    // Do anything else?
                });
            },
            getCustomFields: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/custom-fields';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.custom_fields = [];
                    response.data.custom_fields.forEach((item) => {
                        if (!vm.folder || (item.folders.length == 0)) {
                            vm.custom_fields.push(item);
                        } else if (item.folders.length > 0) {
                            if (item.folders.indexOf(vm.folder) > -1) {
                                vm.custom_fields.push(item);
                            }
                        }
                    });
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getUserGroups: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/!private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_groups = response.data.user_groups;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getUserGroupName: function (userGroupId) {
                let userGroup = null;
                this.user_groups.forEach((item) => {
                    if (item.id == userGroupId) {
                        userGroup = item;
                    }
                });

                return userGroup ? userGroup.name : '';
            },
            getAssetTypeName: function (assetTypeId) {
                let assetType = null;
                this.asset_types.forEach((item) => {
                    if (item.id == assetTypeId) {
                        assetType = item;
                    }
                });

                return assetType ? assetType.name : '';
            },
            getAssetTypeOptions: function () {
                let options = [];
                this.asset_types.forEach((item) => {
                    if (item.active || (this.asset_type.indexOf(item.id) > -1)) {
                        let numResults = 0;
                        if (this.asset_result_types) {
                            numResults = (item.id in this.asset_result_types)
                                ? this.asset_result_types[item.id]['total'] : 0;
                        }
                        let name = item.name;
                        if (numResults > 0) {
                            name += ' <span class="badge badge-primary font-size-11">' + numResults + '</span>';
                        }
                        options.push({
                            id: item.id,
                            name: name,
                            disabled: (!this.asset_result_types || !(item.id in this.asset_result_types))
                        });
                    }
                });

                return options;
            },
            getOrientationOptions: function () {
                let options = [];
                this.orientations.forEach((item) => {
                    options.push({
                        id: item.id,
                        name: item.name,
                        disabled: (!this.asset_result_orientations || !(item.id in this.asset_result_orientations))
                    });
                });

                return options;
            },
            getUserGroupOptions: function (enabledOnly = false) {
                let options = [];
                this.user_groups.forEach((item) => {
                    if (
                        this.$root.hasPermission('users_user_groups')
                        || (this.$root.user.user_groups.indexOf(item.id) > -1)
                    ) {
                        if (
                            !enabledOnly
                            || (this.asset_result_user_groups && (item.id in this.asset_result_user_groups))
                        ) {
                            options.push({
                                id: item.id,
                                name: item.name,
                                disabled: (!this.asset_result_user_groups || !(item.id in this.asset_result_user_groups))
                            });
                        }
                    }
                });

                return options;
            },
            getUploaderOptions: function () {
                let options = [];
                if ((this.uploaders.length == 0) && this.$parent.total_items) {
                    options.push({
                        id: 0,
                        name: 'Loading...',
                        disabled: true
                    });
                }
                this.uploaders.forEach((item) => {
                    options.push({ id: item.id, name: item.first_name + ' ' + item.last_name });
                });

                return options;
            },
            getCustomFieldOptions: function (customField) {
                const customFieldOptions = (customField.options || []);
                const customFieldResults = this.asset_result_custom_fields
                    ? (this.asset_result_custom_fields[customField.id] || {}) : {};
                let options = [];
                customFieldOptions.forEach((option, index) => {
                    options.push({
                        id: index,
                        name: option.text,
                        disabled: !(option.value in customFieldResults)
                    });
                });

                return options;
            },
            getCustomFieldValue: function (customFieldId) {
                if (!(customFieldId in this.custom_field)) {
                    return null;
                }
                let customFieldValue = null;
                let value = this.custom_field[customFieldId];
                this.custom_fields.forEach((item) => {
                    if (customFieldId == item.id) {
                        if ((item.type == 'multiselect') || (item.type == 'singleselect')) {
                            customFieldValue = [];
                            let customFieldOptions = (item.options || []);
                            customFieldOptions.forEach((option, optionIndex) => {
                                if (value.indexOf(optionIndex) > -1) {
                                    customFieldValue.push(option.value);
                                }
                            });
                        } else {
                            customFieldValue = value.trim();
                        }
                    }
                });

                return customFieldValue;
            },
            getCustomFieldOptionIndex: function (customFieldId, value) {
                let index = -1;
                let customField = null;
                this.custom_fields.forEach((item) => {
                    if (item.id == customFieldId) {
                        customField = item;
                    }
                });
                if (!customField) {
                    return index;
                }
                
                if (customField.options) {
                    customField.options.forEach((option, optionIndex) => {
                        if (value == option.value) {
                            index = optionIndex;
                        }
                    });
                }

                return index;
            },
            isCustomFieldSelect: function (customFieldId) {
                let itemIndex = -1;
                this.custom_fields.forEach((item, index) => {
                    if (item.id == customFieldId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex < 0) {
                    return false;
                }
                if (this.custom_fields[itemIndex].type == 'singleselect') {
                    return true;
                }

                return (this.custom_fields[itemIndex].type == 'multiselect');
            },
            showDateRange: function(picker) {
                const icon = '<i class="fal fa-calendar-alt font-size-18 mr-3" style="position: relative; top: 2px; '
                    + 'opacity: 0.8"></i>';
                if (!picker.startDate) {
                    return icon + 'Select date range';
                }
                return icon + this.formatDate(picker.startDate) + ' - ' + this.formatDate(picker.endDate);
            },
            updateDateRange: function () {
                this.date_range = {
                    startDate: this.formatDate(this.date_range.startDate, 'Y-m-d'),
                    endDate: this.formatDate(this.date_range.endDate, 'Y-m-d')
                }
                this.dateRangeSearch();
            },
            setRightBarStyle: function (datePickerOpen) {
                if (datePickerOpen) {
                    $('.right-bar').removeClass('scrollable');
                } else {
                    $('.right-bar').addClass('scrollable');
                }
            },
            formatDate: function (date, format = 'd/m/Y') {
                const newDate = new Date(date);
                const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
                const day = ('0' + newDate.getDate()).slice(-2);
                if (format == 'd/m/Y') {
                    return day + '/' + month + '/' + newDate.getFullYear();
                }

                return newDate.getFullYear() + '-' + month + '-' + day;
            },
            clearDateRange: function () {
                this.date_range = {
                    startDate: null,
                    endDate: null
                };
                this.dateRangeSearch();
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'filter-by-keyword':
                        text = 'Filter by keyword';
                        break;
                    default:
                        text = 'Enter keyword';
                }
                if (update) {
                    $('#' + field).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            removeKeywordFilters: function () {
                let filters = this.$root.asset_filters;
                for (let itemIndex = (filters.length -1); itemIndex >= 0; itemIndex--) {
                    if (filters[itemIndex].key == 'keyword') {
                        filters.splice(itemIndex, 1);
                    }
                }
            },
            cfDropdown: function (event) {
                if (event.show) {
                    $('.right-bar.scrollable').css('padding-bottom', '150px');
                    $('.right-bar.scrollable').animate({ scrollTop: '+=200' }, 700);

                    return null
                }
                $('.right-bar.scrollable').css('padding-bottom', '0');
            },
            hasKeywords: function () {
                let found = false;
                this.$root.asset_filters.forEach((item) => {
                    if ((item.key == 'keyword') || (item.key == 'main-keyword')) {
                        found = true;
                    }
                });

                return found;
            },
            hasResources: function () {
                if (!this.assets_info) {
                    return false;
                }

                return this.isArchive() ? this.assets_info.resources_archived : this.assets_info.resources_live;
            },
            hasTemplates: function () {
                if (!this.assets_info) {
                    return false;
                }

                return this.isArchive() ? this.assets_info.templates_archived : this.assets_info.templates_live;
            },
            isArchive: function () {
                if ((this.$route.name != 'assets-filtered') || (this.$route.params.filters != 'archived')) {
                    return false;
                }

                return true;
            },
            onFocus: function () {
                if (!this.keyword) {
                    this.autocomplete_options = [];
                    this.selected_autocomplete_option = null;
                    this.last_key = null;
                    $('#search-autocomplete-aft').hide();

                    return null;
                }
                if (this.autocomplete_options.length > 0) {
                    var vm = this;
                    setTimeout(function () {
                        vm.showAutocomplete();
                    }, 200);
                }
            },
            onKeydown: function (event) {
                if (!this.$root.client.asset_search_autocomplete) {
                    return null;
                }
                this.last_key = event.key;
                if (
                    (event.key != 'ArrowUp')
                    && (event.key != 'ArrowDown')
                    && ((event.key != 'Enter') || (!this.selected_autocomplete_option))
                ) {
                    return null;
                }
                event.preventDefault();
            },
            onKeyup: function (event) {
                if (!this.$root.client.asset_search_autocomplete) {
                    return null;
                }
                this.lookupAsset(true);
            },
            lookupAsset: function (delay = false) {
                if (
                    (this.last_key == 'ArrowUp')
                    || (this.last_key == 'ArrowDown')
                    || ((this.last_key == 'Enter') && (this.selected_autocomplete_option))
                ) {
                    this.showAutocomplete();

                    return null;
                }
                $('#search-autocomplete-aft').hide();
                this.autocomplete_options = [];
                this.selected_autocomplete_option = null;
                this.last_key = null;

                if (this.keyword.length < 3) {
                    return null;
                }

                if (delay) {
                    this.keypress_time = new Date();
                    var vm = this;
                    setTimeout(function () {
                        vm.lookupAsset();
                    }, 500);
                }

                const time = new Date();
                if ((time - this.keypress_time) < 500) {
                    return null;
                }

                let filters = [
                    this.isArchive() ? 'archived' : '!archived',
                    'ak::' + btoa(this.keyword)
                ];
                let assetIds = [];
                let limit = 100;
                this.asset_filters.forEach((item) => {
                    if (item.key == 'main-keyword') {
                        let values = item.value.split('||');
                        values.forEach((keyword) => {
                            filters.push('mk::' + btoa(keyword));
                        });
                    }
                    if (item.key == 'keyword') {
                        filters.push('ak::' + btoa(item.value));
                    }
                    if ((item.key == 'folder') && (item.value > 0)) {
                        filters.push('fr::' + item.value);
                    }
                    if (item.key == 'subfolder') {
                        filters.push('sf::' + item.value);
                    }
                    if ((item.key == 'asset_type') && !item.auto) {
                        filters.push('rt::' + item.value);
                    }
                    if (item.key == 'orientation') {
                        filters.push('or::' + item.value);
                    }
                    if (item.key == 'date_range') {
                        filters.push('dr::' + item.value);
                    }
                    if (item.key == 'uploader') {
                        filters.push('cb::' + item.value);
                    }
                    if ((item.key.substr(0, 2) == 'cf') && !item.auto) {
                        filters.push(item.key + '::' + btoa(item.value));
                    }
                    if (item.key == 'user_group') {
                        filters.push('ug::' + item.value);
                    }
                    if (item.key == 'resource_id') {
                        assetIds.push(item.value);
                    }
                    if ((item.key == 'restricted') || (item.key == '!restricted')) {
                        filters.push(item.key);
                    }
                    if (item.key == 'limit') {
                        limit = item.value;
                    }
                    if (item.key == 'template') {
                        filters.push(item.value ? 'template' : '!template');
                    }
                });
                filters.push('lt::' + limit);
                if (assetIds.length > 0) {
                    filters.push('in::' + assetIds.join(','));
                }

                /**
                 * Send search assets request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/' + filters.join('|');
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.resources.total == 0) {
                        return null;
                    }
                    response.data.resources.data.forEach((item) => {
                        // Duplicate display protection - check if already added.
                        let itemIndex = -1;
                        vm.autocomplete_options.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            vm.autocomplete_options.push({
                                id: item.id,
                                name: item.id + ' - ' + item.title
                            });
                        }
                    });
                    vm.showAutocomplete();
                })
                .catch(function (error) {
                    //
                });
            },
            showAutocomplete: function () {
                if (this.autocomplete_options.length == 0) {
                    return null;
                }
                $('#search-autocomplete-aft').show();
                let currentIndex = -1;
                let prevIndex = -1;
                let nextIndex = -1;
                if (this.selected_autocomplete_option) {
                    this.autocomplete_options.forEach((option, index) => {
                        if (option.id == this.selected_autocomplete_option) {
                            currentIndex = index;
                        } else {
                            if (currentIndex == -1) {
                                prevIndex = index;
                            }
                            if ((currentIndex > -1) && (nextIndex == -1)) {
                                nextIndex = index;
                            }
                        }
                    });
                    if (currentIndex == -1) {
                        this.selected_autocomplete_option = null;
                    }
                }
                if (this.last_key == 'ArrowDown') {
                    this.selected_autocomplete_option = (nextIndex > -1)
                        ? this.autocomplete_options[nextIndex].id
                        : this.autocomplete_options[0].id;
                    this.scrollToAutocompleteOption();
                }
                if (this.last_key == 'ArrowUp') {
                    this.selected_autocomplete_option = (prevIndex > -1)
                        ? this.autocomplete_options[prevIndex].id
                        : this.autocomplete_options[0].id;
                    this.scrollToAutocompleteOption();
                }
                if ((this.last_key == 'Enter') && (currentIndex > -1)) {
                    this.selected_autocomplete_option = null;
                    this.last_key = null;
                    this.getAsset(this.autocomplete_options[currentIndex]);
                }
            },
            scrollToAutocompleteOption: function () {
                let scrollTo = 0;
                let found = false;
                this.autocomplete_options.forEach((option) => {
                    if (!found) {
                        if (option.id != this.selected_autocomplete_option) {
                            let oid = 'search-autocomplete-aft-option' + option.id;
                            scrollTo += $('#' + oid).outerHeight();
                        } else {
                            found = true;
                        }
                    }
                });
                $('#search-autocomplete-aft').animate({ scrollTop: scrollTo }, 200);
            },
            getAsset: function (selectedAsset) {
                $('#search-autocomplete-aft').hide();
                this.$root.addFilter('asset_filters', {
                    key: 'resource_id',
                    value: selectedAsset.id,
                    text: '&quot;' + selectedAsset.id + '&quot;',
                    filters_key: 'asset_filters'
                });
                this.keyword = '';
                this.autocomplete_options = [];
                this.selected_autocomplete_option = null;
                this.last_key = null;
                this.$parent.getAssets();
            }
        }
    }
</script>
<style scoped>
    .form-group.keyword {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }

    .clear-keyword.custom-field {
        top: 25px;
    }

    .form-group.date-range {
        position: relative;
    }

    .clear-date-range {
        position: absolute;
        top: 25px;
        right: 4px;
        z-index: 9;
    }

    #search-autocomplete-aft {
        max-width: 268px;
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: auto;
    }
</style>
