<template>
    <div class="topnav page-topnav" style="padding: 0 12px; max-height: none; overflow-y: visible">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand topnav-menu">
                <div class="navbar-collapse align-items-center justify-content-between">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <h6>
                                <span v-if="($route.name == 'asset-types-inactive')">Inactive</span>
                                <span v-else>Live</span>
                                {{ $root.$data.contents.asset_title }} types
                            </h6>
                        </li>
                    </ul>
                    <ul class="navbar-nav">
                        <!--<li class="nav-item d-none d-md-inline">
                            <breadcrumbs :items="breadcrumbs()"></breadcrumbs>
                        </li>-->
                        <li class="nav-item" style="width: 100px; height: 36.5px">
                            <div class="btn-group" style="position: absolute; height: 36.5px; right: 0">
                                <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    Actions
                                    <i
                                        class="fas fa-chevron-down"
                                        style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                    ></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a
                                        class="dropdown-item"
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#asset-type-add-form-modal"
                                        @click.prevent="resetAssetTypeAddForm()"
                                    >
                                        <i class="far fa-file-plus mr-1"></i>
                                        Add {{ $root.$data.contents.asset_title }} type
                                    </a>
                                    <router-link
                                        v-if="($route.name == 'asset-types')"
                                        class="dropdown-item"
                                        :to="{ name: 'asset-types-inactive' }"
                                    >
                                        <i class="far fa-archive mr-1"></i>
                                        View inactive {{ $root.$data.contents.asset_title }} types
                                    </router-link>
                                    <span v-if="($route.name == 'asset-types-inactive')">
                                        <router-link
                                            class="dropdown-item"
                                            :to="{ name: 'asset-types' }"
                                        >
                                            <i class="far fa-copy mr-1"></i>
                                            View live {{ $root.$data.contents.asset_title }} types
                                        </router-link>
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                //
            }
        },
        mounted () {
            //
        },
        methods: {
            resetAssetTypeAddForm: function () {
                const component = this.$root.findComponent(this.$parent.$parent, 'asset-type-add-form');
                if (component) {
                    component.resetForm();
                }
            },
            /*breadcrumbs: function() {
                if (this.$route.name == 'asset-types-inactive') {
                    return [
                        {
                            id: 0,
                            text: "Settings",
                            link: 'settings'
                        },
                        {
                            id: 1,
                            text: this.$root.ucfirst(this.$root.$data.contents.asset_title) + " types",
                            link: 'asset-types'
                        },
                        {
                            id: 2,
                            text: "Inactive",
                            active: true
                        }
                    ];
                }
                
                return [
                    {
                        id: 0,
                        text: "Settings",
                        link: 'settings'
                    },
                    {
                        id: 1,
                        text: this.$root.ucfirst(this.$root.$data.contents.asset_title) + " types",
                        active: true
                    }
                ];
            }*/
        }
    }
</script>
