<template>
    <div class="topnav page-topnav" style="padding: 0 12px; max-height: none; overflow-y: visible">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand topnav-menu">
                <div class="navbar-collapse align-items-center justify-content-between">
                    <ul class="navbar-nav">
                        <li v-if="($route.name == 'deletion-requests')" class="nav-item">
                            <h6>Deletion requests</h6>
                        </li>
                        <li v-else class="nav-item">
                            <h6>Users</h6>
                        </li>
                    </ul>
                    <ul class="navbar-nav">
                        <!--<li class="nav-item d-none d-md-inline">
                            <breadcrumbs :items="breadcrumbItems()"></breadcrumbs>
                        </li>-->
                        <li
                            v-if="($root.hasPermission('users_invite') || showResendExpiredInvites())"
                            class="nav-item"
                            style="width: 100px; height: 36.5px"
                        >
                            <div class="btn-group" style="position: absolute; height: 36.5px; right: 0">
                                <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    Actions
                                    <i
                                        class="fas fa-chevron-down"
                                        style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                    ></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <span v-if="hasExport()">
                                        <span v-if="!downloading">
                                            <a
                                                class="dropdown-item"
                                                href="#"
                                                @click.prevent="downloadExport()"
                                            >
                                                <i class="far fa-download mr-1"></i> Excel
                                            </a>
                                        </span>
                                        <span v-else>
                                            <span class="text-muted" style="padding-left: 24px; line-height: 30px">
                                                <i class="far fa-spinner-third fa-spin mr-1"></i> 
                                                <span style="position: relative; top: 1px">Excel</span>
                                            </span>
                                        </span>
                                    </span>
                                    <a
                                        v-if="$root.hasPermission('users_invite')"
                                        class="dropdown-item"
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#user-add-form-modal"
                                        @click.prevent="resetUserAddForm()"
                                    >
                                        <i class="far fa-user-plus mr-1"></i> Invite a user
                                    </a>
                                    <a
                                        v-if="showResendExpiredInvites()"
                                        class="dropdown-item"
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#resend-expired-invites-modal"
                                        @click.prevent="resendExpiredInvites()"
                                    >
                                        <i class="far fa-envelope mr-1"></i> Resend expired invites
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['expired_invites', 'user_filters', 'xls_download'],
        data () {
            return {
                num_expired_invites: 0,
                users_status: null,
                downloading: false
            }
        },
        mounted () {
            this.getNumberOfExpiredInvites();
            this.updateUsersStatus(this.user_filters);
        },
        watch: {
            expired_invites: function (val) {
                this.getNumberOfExpiredInvites();
            },
            user_filters: function (val) {
                this.updateUsersStatus(val);
            }
        },
        methods: {
            updateUsersStatus: function (filters) {
                let statusFound = null;
                filters.forEach((item) => {
                    if (item.key == 'status') {
                        statusFound = item.value;
                    }
                });
                this.users_status = statusFound;
            },
            /*breadcrumbItems: function () {
                let items = [{
                    id: 0,
                    text: "Settings",
                    link: 'settings'
                }];
                if (this.$route.name == 'users') {
                    items.push({
                        id: 1,
                        text: "Active users",
                        active: true
                    });
                }
                if (this.$route.name == 'new-users') {
                    items.push({
                        id: 1,
                        text: "Invites and applications",
                        active: true
                    });
                }

                return items;
            },*/
            getNumberOfExpiredInvites: function () {
                if (!this.$root.hasPermission('users_view_details') || !this.$root.hasPermission('users_invite')) {
                    return null;
                }
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!declined|!deleted|invited|expired';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.num_expired_invites = response.data.users.total;
                    vm.$root.num_expired_invites = vm.num_expired_invites;
                })
                .catch(function (error) {
                    // Do anything?
                })
            },
            resetUserAddForm: function () {
                const component = this.$root.findComponent(this.$parent.$parent, 'user-add-form');
                if (component) {
                    component.resetForm();
                }
            },
            resendExpiredInvites: function () {
                const component = this.$root.findComponent(this.$parent.$parent, 'resend-expired-invites');
                if (component) {
                    component.resetForm();
                }
            },
            showResendExpiredInvites: function () {
                if (!this.$root.hasPermission('users_invite') || (this.num_expired_invites < 2)) {

                    return false;
                }

                return (this.users_status == 'invited');
            },
            hasExport: function () {
                if (!this.xls_download) {
                    return false;
                }
                const xlsPages = [
                    'asset-reviews',
                    'reports-orders',
                    'reports-templates',
                    'users'
                ];

                if (xlsPages.indexOf(this.$route.name) > -1) {
                    return true;
                }

                return false;
            },
            downloadExport: function () {
                this.downloading = true;
                /**
                 * Send request to API
                 */
                const download = this.xls_download;
                const data = {
                    item_type: 'report',
                    item_id: 0,
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/' + response.data.token;
                    window.location.href = url;
                })
                .catch(function (error) {
                    // Do anything?
                })
                .finally(() =>
                    vm.downloading = null
                );
            }
        }
    }
</script>
