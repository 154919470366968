<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (total_items == 0))" class="alert alert-warning" role="alert">
            There are no users that match your selection
        </div>
        <div v-if="((total_items > 0) || sorting)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-1 col-2">&nbsp;</div>
                                        <div class="col-sm-3 col-10 pb-sm-0 pb-1">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                title="Sort by name"
                                                @click.prevent="sortBy('name')"
                                            >
                                                Name
                                                <span v-if="(sorted_by == 'name_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'name_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-2 d-sm-none">&nbsp;</div>
                                        <div class="col-sm-3 col-10 pb-sm-0 pb-1">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                :title="'Sort by ' + company_title"
                                                @click.prevent="sortBy('company')"
                                            >
                                                {{ $root.ucfirst(company_title) }}
                                                <span v-if="(sorted_by == 'company_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'company_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-2 d-sm-none">&nbsp;</div>
                                        <div class="col-sm-2 col-10 pb-sm-0 pb-1">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                title="Sort by role"
                                                @click.prevent="sortBy('role')"
                                            >
                                                User role
                                                <span v-if="(sorted_by == 'role_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'role_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-2 d-sm-none">&nbsp;</div>
                                        <div class="col-sm-2 col-10">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                title="Sort by status"
                                                @click.prevent="sortBy('deletion-requested')"
                                            >
                                                Status
                                                <span v-if="(sorted_by == 'deletion-requested_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'deletion-requested_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-sm-1 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row" :id="'user-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-sm-1 col-2">
                                            <div v-if="hasProfileImage(item)" class="avatar-xs">
                                                <img
                                                    class="rounded-circle avatar-xs"
                                                    :src="item.profile_image"
                                                    alt="avatar"
                                                >
                                            </div>
                                            <div v-else class="avatar-xs">
                                                <span class="avatar-title rounded-circle">
                                                    {{ $root.initial(item.first_name, item.last_name) }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-3 d-none d-sm-block">
                                            <h5 class="font-size-14 mb-1">
                                                <a href="#" class="text-dark" @click.prevent="toggleEditUser(item)">
                                                    {{ item.first_name + ' ' + item.last_name }}
                                                </a>
                                            </h5>
                                            <p class="text-muted mb-0">{{ item.job_title }}</p>
                                        </div>
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.company }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ getRoleName(item.role) }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            Deletion requested on {{ formatDate(item.deletion_requested_at) }}
                                        </div>
                                        <div class="col-8 d-sm-none">
                                            <h5 class="font-size-14 mb-1">
                                                <a href="#" class="text-dark" @click.prevent="toggleEditUser(item)">
                                                    {{ item.first_name + ' ' + item.last_name }}
                                                </a>
                                            </h5>
                                            <p class="text-muted mb-1">{{ item.job_title }}</p>
                                            <p class="mb-1">{{ item.company }}</p>
                                            <p class="mb-1">{{ getRoleName(item.role) }}</p>
                                            <p class="mb-1">
                                                Deletion requested on {{ formatDate(item.deletion_requested_at) }}
                                            </p>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleEditUser(item)"
                                            >
                                                <span
                                                    v-if="(user_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'user-details' + item.id">
                                            <user-edit-form
                                                :data="item"
                                                :company_name="client_company_name"
                                                :roles="roles"
                                                :groups="user_groups"
                                                :profiles="profile_groups"
                                                :is_visible="(user_details_open.indexOf(item.id) > -1)"
                                            ></user-edit-form>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <div
            v-if="$root.hasPermission('users_reset')"
            class="modal fade"
            id="reset-user-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="reset-user-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <reset-user></reset-user>
            </div>
        </div>

        <div
            v-if="$root.hasPermission('users_approve')"
            class="modal fade"
            id="decline-user-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="decline-user-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <decline-user></decline-user>
            </div>
        </div>

        <div
            v-if="$root.hasPermission('users_invite')"
            class="modal fade"
            id="cancel-invite-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="cancel-invite-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <cancel-invite></cancel-invite>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'deletion-requests',
                loading: false,
                errors: false,
                items: [],
                total_items: 0,
                current_page: 1,
                last_page: 1,
                user_details_open: [],
                get_content_response: null,
                client_company_name: '',
                roles: [],
                user_groups: [],
                sorted_by: 'deletion-requested_asc',
                sorting: false,
                profile_groups: [],
                company_title: 'company'
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            this.$root.user_filters = [];
            $('body').removeClass('login-body');

            if (
                !this.$root.hasPermission('users_view_details')
                && !this.$root.hasPermission('users_view_deleted_users')
            ) {
                this.errors = 'Sorry, you do not have access to this function.';

                return false;
            }
            this.getRoles();
            this.getUsers();
            this.getUserGroups();
            if (this.$root.isFeatured('profiles')) {
                this.getProfiles();
            }
            
            this.$root.getContent(this, 'company_title');
            this.client_company_name = this.$root.client.client_name;
            if (this.$root.client.company_contact_details) {
                this.$root.getContent(this, 'company_name');
            }
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            get_content_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.key == 'company_name') {
                            this.client_company_name = val.content;
                        }
                        if (val.key == 'company_title') {
                            this.company_title = val.content;
                        }
                    }
                }
            },
            total_items: function (val) {
                this.$root.status_info.right = this.total_items + ' people';
            }
        },
        methods: {
            getUsers: function (page = 1) {
                if (this.loading || (page < 1) || ((page > 1) && (page <= this.current_page))) {
                    return false;
                }
                this.current_page = page;
                this.loading = true;
                this.errors = false;

                let filters = ['!export|deletion-requested|!deleted|sb::' + this.sorted_by];
                this.$root.user_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        filters.push('kw::' + btoa(item.value));
                    }
                    if (item.key == 'role') {
                        filters.push('ur::' + item.value);
                    }
                });
                const filtersString = (filters.length > 0) ? '/' + filters.join('|') : '';

                if (page == 1) {
                    this.items = [];
                    this.total_items = 0;
                    this.current_page = 1;
                    this.last_page = 1;
                    this.user_details_open = [];
                }

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/users'
                    + filtersString + '?page=' + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.total_items = response.data.users.total;
                    vm.current_page = response.data.users.current_page;
                    vm.last_page = response.data.users.last_page;
                    response.data.users.data.forEach((item, index) => {
                        vm.items.push(item);
                    });
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                    vm.sorting = false;
                });
            },
            formatDate: function (rawDate) {
                if (!rawDate) {
                    return '';
                }
                const date = rawDate.substr(0 ,10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            handleScroll(event) {
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < 200) {
                        this.getUsers(this.current_page + 1);
                    }
                }
            },
            toggleEditUser: function (user) {
                if (this.user_details_open.indexOf(user.id) < 0) {
                    this.user_details_open.push(user.id);
                    $('#user-row' + user.id).addClass('highlighted');
                    $('#user-details' + user.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#user-details' + user.id).slideUp('slow', function () {
                        $('#user-row' + user.id).removeClass('highlighted');
                        for (var n = 0; n < vm.user_details_open.length; n++) { 
                            if (vm.user_details_open[n] == user.id) { 
                                vm.user_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            doNothing: function () {
                return false;
            },
            getRoles: function () {
                /**
                 * Send get roles request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/settings/roles';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.roles = response.data.roles;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getUserGroups: function () {
                /**
                 * Send get user groups request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/!private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_groups = response.data.user_groups;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getProfiles: function () {
                /**
                 * Send get profile groups request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-groups/!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.profile_groups = response.data.profile_groups;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getRoleName: function (roleId) {
                let name = '';
                this.roles.forEach((item, index) => {
                    if (item.id == roleId) {
                        name = item.name;
                    }
                });

                return name;
            },
            sortBy: function (field) {
                const currentSort = this.sorted_by.split('_');
                let newSort = currentSort;
                if (currentSort[0] == field) {
                    newSort[1] = (currentSort[1] == 'asc') ? 'desc' : 'asc';
                } else {
                    newSort[0] = field;
                }
                this.sorted_by = newSort.join('_');
                this.sorting = true;
                this.getUsers();
            },
            hasProfileImage: function (item) {
                if (!item.profile_image) {
                    return false;
                }

                return (item.profile_image.indexOf('data:') > -1);
            }
        }
    }
</script>
