<template>
    <div :style="'position: relative; width: ' + width + 'px; height: ' + height + 'px; overflow: hidden'">
        <apexchart type="radialBar" :width="width" :height="height * 1.5" :options="chart_options" :series="series">
        </apexchart>
        <div class="text-center" :style="'position: absolute; top: ' + subtitle.top + 'px; width: 100%'">
            <span
                :style="'font-size: ' + subtitle.font_size + '; font-weight: ' + subtitle.font_weight + '; color: '
                    + subtitle.colour"
            >
                {{ subtitle.text }}
            </span>
        </div>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';
    export default {
        props: ['percent', 'dash_array', 'width', 'height', 'title', 'subtitle'],
        components: {
            'apexchart': VueApexCharts,
        },
        data () {
            return {
                series: [this.percent],
                chart_options: {
                    chart: {
                        width: this.width,
                        height: this.height * 1.5,
                        type: 'radialBar',
                        offsetY: -1 * Math.floor(this.height / 5)
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -135,
                            endAngle: 135,
                            dataLabels: {
                                name: {
                                    fontFamily: this.title.font_family,
                                    fontSize: this.title.font_size,
                                    fontWeight: this.title.font_weight,
                                    color: this.title.colour,
                                    offsetY: this.title.top
                                },
                                value: {
                                    formatter: function (val) {
                                        return '';
                                    }
                                }
                            }
                        }
                    },
                    fill: {
                        colors: [this.$root.client.third_colour],
                        type: 'gradient',
                        gradient: {
                            shade: 'dark',
                            shadeIntensity: 0.15,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 65, 91]
                        },
                    },
                    stroke: {
                        dashArray: this.dash_array
                    },
                    labels: [this.title.text],
                }
            }
        },
        mounted () {
            //
        }
    }
</script>

<!--
https://github.com/apexcharts/vue-apexcharts
https://apexcharts.com/vue-chart-demos/radialbar-charts/stroked-gauge/
-->