<template>
    <div id="artwork-editor" :class="'container-fluid' + (hasSavedArtworks() ? ' padding' : '')">
        <div
            v-if="(!isProfileMissing() || (profile_groups.length > 0))"
            class="card"
            @click="collapse_saved_artworks = true"
        >
            <div class="card-body p-0" style="position: relative">
                <div class="artwork-editor-header p-3">
                    <button
                        :key="'close-button'"
                        type="button"
                        class="close ml-3"
                        aria-label="Close"
                        @click="onClose()"
                    >
                        <span aria-hidden="true" style="font-size: 38px"><i class="fal fa-times"></i></span>
                    </button>

                    <div
                        v-if="errors"
                        v-html="errors"
                        class="alert alert-danger"
                        role="alert"
                        style="width: calc(100% - 40px)"
                    ></div>

                    <div class="row no-gutters">
                        <div
                            :class="(((asset.number_of_pages < 2) || $root.is_xl2_screen) ? 'col-lg-4 ' : '') + 'col-12'"
                        >
                            <form v-if="editing_artwork_title" v-on:submit.prevent="updateArtworkTitle()">
                                <div class="input-group">
                                    <input
                                        v-model="new_artwork_title"
                                        type="text"
                                        class="form-control mandatory-field"
                                        id="new-artwork-title"
                                        aria-label="Artwork title"
                                        :readonly="updating_artwork_title"
                                        v-on:keyup="$root.unHighlightErrors('new-artwork-title')"
                                        v-on:change="$root.unHighlightErrors('new-artwork-title')"
                                    >
                                    <div class="input-group-append">
                                        <button
                                            v-if="!updating_artwork_title"
                                            class="btn btn-primary big-icon"
                                            type="submit"
                                            v-b-tooltip.hover
                                            title="Save"
                                            :disabled="new_artwork_title == artwork.title"
                                        >
                                            <strong><i class="fal fa-save"></i></strong>
                                        </button>
                                        <button
                                            v-else
                                            type="button"
                                            class="btn btn-primary"
                                        >
                                            <span>
                                                <i class="fal fa-spinner-third fa-spin big-icon"></i>
                                            </span>
                                        </button>
                                        <button
                                            class="btn btn-light big-icon"
                                            type="button"
                                            v-b-tooltip.hover
                                            title="Cancel"
                                            :disabled="updating_artwork_title"
                                            @click="toggleEditArtworkTitle()"
                                        >
                                            <i class="fal fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <table v-else>
                                <tbody>
                                    <tr>
                                        <td height="35" valign="middle">
                                            <h5 class="artwork-title mb-0">
                                                <span v-if="isEditable()">
                                                    {{ artwork.title ? artwork.title : 'Title' }}
                                                    <a
                                                        href="#"
                                                        @click.prevent="toggleEditArtworkTitle()"
                                                        v-b-tooltip.hover
                                                        title="Edit title"
                                                    >
                                                        <i class="far fa-edit ml-2"></i>
                                                    </a>
                                                </span>
                                                <span v-else>{{ asset ? asset.title : '' }}</span>
                                            </h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div
                            :class="'col ' + (((asset.number_of_pages < 2) || $root.is_xl2_screen)
                                ? 'pl-lg-4 pt-lg-0 ' : '') + 'pl-0 pt-2'"
                        >
                            <div v-if="isEditable()" class="row no-gutters">
                                <div
                                    v-if="(asset.number_of_pages > 1)"
                                    class="col-sm-2 col-12 pb-sm-0 pb-3"
                                    style="min-width: 150px; margin-right: 12px"
                                >
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <button
                                                class="btn btn-secondary big-icon"
                                                type="button"
                                                id="artwork-editor-prev"
                                                :disabled="((current_page == 1) || isProfileMissing())"
                                                @click="prevPage()"
                                            ><i class="far fa-chevron-left"></i></button>
                                            <b-tooltip
                                                v-if="(current_page > 1)"
                                                target="artwork-editor-prev"
                                                triggers="hover"
                                            >Previous page</b-tooltip>
                                        </div>
                                        <input
                                            :value="current_page + ' of ' + asset.number_of_pages"
                                            type="text"
                                            class="form-control text-center px-0"
                                            :readonly="true"
                                            style="height: 35px; max-width: 100px"
                                            v-b-tooltip.hover
                                            :title="'Page ' + current_page + ' of ' + asset.number_of_pages"
                                        >
                                        <div class="input-group-append">
                                            <button
                                                class="btn btn-secondary big-icon"
                                                type="button"
                                                id="artwork-editor-next"
                                                :disabled="((current_page == asset.number_of_pages)
                                                    || isProfileMissing())"
                                                @click="nextPage()"
                                            ><i class="far fa-chevron-right"></i></button>
                                            <b-tooltip
                                                v-if="(current_page < asset.number_of_pages)"
                                                target="artwork-editor-next"
                                                triggers="hover"
                                            >Next page</b-tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <span
                                        v-if="((asset.number_of_pages < 2) && $root.is_md_screen) || $root.is_lg_screen"
                                    >
                                        <button
                                            :key="'screen-preview-button-xxl'"
                                            class="btn btn-secondary mr-2"
                                            type="button"
                                            @click="screenPreview()"
                                            :disabled="isProfileMissing() || !fields_changed"
                                        >
                                            <i class="far fa-eye mr-1"></i> Update preview
                                        </button>
                                        <button
                                            :key="'pdf-preview-button-xxl'"
                                            class="btn btn-secondary mr-2"
                                            type="button"
                                            @click="pdfPreview()"
                                            :disabled="isProfileMissing()"
                                        >
                                            <i class="far fa-file-lines mr-1"></i> PDF preview
                                        </button>
                                        <button
                                            :key="'finish-artwork-button-xxl'"
                                            class="btn btn-primary"
                                            type="button"
                                            @click="createArtwork()"
                                            :disabled="isProfileMissing() || editing_artwork_title"
                                        >
                                            <i class="far fa-check mr-1"></i> Finish
                                        </button>
                                        <button
                                            :key="'save-button-xxl'"
                                            class="btn btn-secondary float-right"
                                            type="button"
                                            @click="createArtwork(true)"
                                            :disabled="isProfileMissing() || editing_artwork_title"
                                            :style="(((asset.number_of_pages < 2) && $root.is_lg_screen)
                                                || $root.is_xl2_screen || errors)
                                                ? '' : 'position: relative; left: 43px'"
                                        >
                                            <i class="far fa-save mr-1"></i> Save for later
                                        </button>
                                    </span>
                                    <span v-else>
                                        <button
                                            :key="'screen-preview-button'"
                                            class="btn btn-secondary big-icon mr-2"
                                            type="button"
                                            @click="screenPreview()"
                                            :disabled="isProfileMissing() || !fields_changed"
                                            v-b-tooltip.hover
                                            title="Update preview"
                                            style="width: 45px"
                                        ><i class="fal fa-eye"></i></button>
                                        <button
                                            :key="'pdf-preview-button'"
                                            class="btn btn-secondary big-icon mr-2"
                                            type="button"
                                            @click="pdfPreview()"
                                            :disabled="isProfileMissing()"
                                            v-b-tooltip.hover
                                            title="PDF preview"
                                            style="width: 45px"
                                        ><i class="fal fa-file-lines"></i></button>
                                        <button
                                            :key="'finish-artwork-button'"
                                            class="btn btn-primary big-icon"
                                            type="button"
                                            @click="createArtwork()"
                                            :disabled="isProfileMissing() || editing_artwork_title"
                                            v-b-tooltip.hover
                                            title="Finish"
                                            style="width: 45px"
                                        ><i class="far fa-check"></i></button>
                                        <button
                                            :key="'save-button'"
                                            class="btn btn-secondary big-icon float-right"
                                            type="button"
                                            @click="createArtwork(true)"
                                            :disabled="isProfileMissing() || editing_artwork_title"
                                            v-b-tooltip.hover
                                            title="Save for later"
                                            :style="'width: 45px; padding-left: 13px'
                                                + (errors ? '' : '; position: relative; left: 43px')"
                                        ><i class="fal fa-save font-size-20"></i></button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isEditable()" class="artwork-editor-body pl-3 pr-3 pb-3">
                    <div class="row">
                        <div class="col-lg-4 col-md-5 col-sm-6 col-12">
                            <div class="template-fields">
                                <div v-if="canChangeProfile()">
                                    <div class="form-group">
                                        <label
                                            for="artwork-profile_group_id"
                                            :class="(profile_group_id || loading_preview) ? '' : 'text-danger'"
                                        >
                                            {{ $root.ucfirst($root.contents.profile_group_title) }}
                                        </label>
                                        <searchable-option-list
                                            v-model="profile_group_id"
                                            :value="profile_group_id"
                                            :options="profile_groups"
                                            field_id="artwork-profile_group_id"
                                            :field_class="(profile_group_id || loading_preview) ? '' : 'field-error'"
                                            :readonly="updating_profile || !$root.hasPermission('templates_change_profile')"
                                            :search_placeholder="'Select a ' + $root.contents.profile_group_title"
                                            v-on:change="onProfileGroupChange()"
                                        ></searchable-option-list>
                                    </div>
                                    <div class="form-group">
                                        <label
                                            for="artwork-profile_id"
                                            :class="(profile_id || loading_preview) ? '' : 'text-danger'"
                                        >
                                            {{ $root.ucfirst($root.contents.profile_title) }}
                                        </label>
                                        <searchable-option-list
                                            v-model="profile_id"
                                            :value="profile_id"
                                            :options="getProfileOptions(profile_group_id)"
                                            field_id="artwork-profile_id"
                                            :field_class="(profile_id || loading_preview) ? '' : 'field-error'"
                                            :readonly="!canSelectProfile()"
                                            :search_placeholder="'Select a ' + $root.contents.profile_title"
                                            v-on:change="onProfileChange()"
                                        ></searchable-option-list>
                                    </div>
                                    <hr class="mt-4">
                                </div>

                                <div v-if="loading_fields">
                                    <loading></loading>
                                </div>
                                <div v-else>
                                    <div v-if="!templateFields()" class="alert alert-warning" role="alert">
                                        There is nothing editable on this page
                                    </div>
                                    <div v-else>
                                        <div v-for="item in templateFields()" :key="item.id">
                                            <template-field
                                                :artwork_editor_component="this_component"
                                                :data="item"
                                                :profile_id="profile_id"
                                                :readonly="isProfileMissing()"
                                            ></template-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div
                                :class="'asset-preview page template-preview-' + ($root.is_sm_screen ? 'sm' : 'xs')"
                            >
                                <div
                                    class="thumbnail"
                                    style="z-index: 1; margin-right: 43px"
                                >
                                    <div v-if="loading_preview" class="image-loading">
                                        <loading></loading>
                                        <div class="text-center text-muted">Loading page preview</div>
                                    </div>
                                    <img
                                        v-else
                                        draggable="false"
                                        class="preview-image"
                                        :src="image_src[current_page]"
                                        alt="preview"
                                        :style="'cursor: ' + (isPreviewMovable() ? 'grab' : 'default')"
                                    >
                                </div>

                                <div class="zoom-bar">
                                    <span :key="'zoom-in-button-wrapper'">
                                        <button
                                            :key="'zoom-in-button'"
                                            id="artwork-preview-zoom-in-button"
                                            class="btn btn-secondary font-size-20 btn-block mb-2"
                                            @click="zoomPreview()"
                                            :disabled="!preview_can_zoom_in"
                                        ><i class="fa-light fa-magnifying-glass-plus"></i></button>
                                        <b-tooltip
                                            v-if="preview_can_zoom_in"
                                            target="artwork-preview-zoom-in-button"
                                            triggers="hover"
                                            placement="left"
                                        >Zoom in</b-tooltip>
                                    </span>

                                    <span :key="'zoom-out-button-wrapper'">
                                        <button
                                            :key="'zoom-out-button'"
                                            id="artwork-preview-zoom-out-button"
                                            class="btn btn-secondary font-size-20 btn-block mb-2"
                                            @click="zoomPreview(false)"
                                            :disabled="!preview_can_zoom_out"
                                        ><i class="fa-light fa-magnifying-glass-minus"></i></button>
                                        <b-tooltip
                                            v-if="preview_can_zoom_out"
                                            target="artwork-preview-zoom-out-button"
                                            triggers="hover"
                                            placement="left"
                                        >Zoom out</b-tooltip>
                                    </span>

                                    <span :key="'fit-screen-button-wrapper'">
                                        <button
                                            :key="'fit-screen-button'"
                                            id="artwork-preview-fit-screen-button"
                                            class="btn btn-secondary font-size-20 btn-block"
                                            @click="preview_zoom_level = 0"
                                            :disabled="!preview_can_zoom_out"
                                        ><i class="fa-light fa-expand"></i></button>
                                        <b-tooltip
                                            v-if="preview_can_zoom_out"
                                            target="artwork-preview-fit-screen-button"
                                            triggers="hover"
                                            placement="left"
                                        >Fit screen</b-tooltip>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="artwork-download-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="artwork-download-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <artwork-download></artwork-download>
            </div>
        </div>

        <div
            v-if="($root.editing_artwork && template)"
            class="modal fade"
            id="template-image-editor-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="template-image-editor-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <template-image-editor></template-image-editor>
            </div>
        </div>

        <div
            v-if="($root.editing_artwork && artwork.id)"
            class="modal fade"
            id="artwork-save-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="artwork-save-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <artwork-save></artwork-save>
            </div>
        </div>

        <div
            v-if="!preview_zoom_tooltip_seen"
            class="modal fade"
            id="artwork-preview-zoom-tooltip-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="artwork-preview-zoom-tooltip-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <artwork-preview-zoom-tooltip></artwork-preview-zoom-tooltip>
            </div>
        </div>

        <div
            class="modal fade"
            id="template-table-fields-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="template-table-fields-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-xl">
                <template-table-fields :artwork="artwork"></template-table-fields>
            </div>
        </div>

        <saved-artworks-bar
            v-if="($root.editing_artwork && template)"
            :asset="asset"
            :template="template"
            :artworks="saved_artworks"
            :num_artworks="saved_artworks.length"
            :initial="!saved_artworks_loaded"
            :collapse="collapse_saved_artworks"
        ></saved-artworks-bar>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                asset: { number_of_pages: 0 },
                template: null,
                artwork: { title: '' },
                errors: false,
                editing_artwork_title: false,
                new_artwork_title: null,
                updating_artwork_title: false,
                current_page: 0,
                image_src: {},
                loading_preview: true,
                preview_resize_interval: null,
                loading_fields: false,
                temp_id: null,
                processing_artwork: null,
                saved_artworks: [],
                saved_artworks_loaded: false,
                collapse_saved_artworks: false,
                preview_can_zoom_in: false,
                preview_can_zoom_out: false,
                preview_zoom_level: 0,
                preview_zoom_level_max: 0,
                preview_move: null,
                profile_group_id: null,
                profile_id: null,
                old_profile_id: null,
                profile_groups: [],
                updating_profile: false,
                artwork_changed: false,
                fields_changed: false,
                artwork_processed: false,
                profile_fields: [],
                hide_message_modal_interval: null,
                loading_profiles: false,
                preview_zoom_tooltip_seen: false,
                this_component: null,
                original_field_contents: {}
            }
        },
        mounted () {
            this.this_component = this;
            var vm = this;
            $(window).resize(function () {
                $('html, body').scrollTop(0);
                vm.resetZoomPreview();
            });
        },
        beforeDestroy() {
            clearInterval(this.preview_resize_interval);
            clearInterval(this.hide_message_modal_interval);
        },
        watch: {
            current_page: function (val) {
                if (!val) {
                    return null;
                }
                this.resetZoomPreview();
                this.loading_fields = true;
                this.loading_preview = true;
                if (!(val in this.image_src)) {
                    this.image_src[val] = '/images/file-icon.png';
                    this.getPreviewImage();

                    return null;
                }

                var vm = this;
                setTimeout(function () {
                    vm.loading_preview = false;
                }, 500);
            },
            loading_fields: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.loading_fields = false;
                    }, 500);
                }
            },
            /*profile_id: function (val) {
                if (val) {
                    this.setProfile();
                }
            },*/
            collapse_saved_artworks: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.collapse_saved_artworks = false;
                    }, 500);
                }
            },
            preview_zoom_level: function (val) {
                if (!val) {
                    this.preview_can_zoom_in = false;
                    this.preview_can_zoom_out = false;
                    this.resetZoomPreview();

                    return null;
                }
                this.preview_can_zoom_out = true;
                if (val < this.preview_zoom_level_max) {
                    this.preview_can_zoom_in = true;

                    return null;
                }
                this.preview_can_zoom_in = false;
            },
            loading_preview: function (val) {
                if (!val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.initMovePreview();
                    }, 200);
                }
            }
        },
        methods: {
            reset: function () {
                var vm = this;
                if (this.current_page > 0) {
                    this.errors = false;
                    this.editing_artwork_title = false;
                    this.new_artwork_title = null;
                    this.asset = { number_of_pages: 0 };
                    this.template = null;
                    this.temp_id = null;
                    this.current_page = 0;
                    this.image_src = {};
                    this.saved_artworks = [];
                    this.profile_group_id = null;
                    this.profile_id = null;
                    this.old_profile_id = null;
                    this.saved_artworks_loaded = false;
                    this.artwork_changed = false;
                    this.fields_changed = false;
                    this.processing_artwork = null;
                    this.artwork_processed = false;
                    setTimeout(function () {
                        vm.reset();
                    }, 200);

                    return null;
                }
                this.preview_zoom_tooltip_seen = this.$cookie.get(
                    this.$root.app.client_id + '_apztt' + this.$root.user.id
                ) || false;
                this.asset = this.$root.editing_artwork.asset;
                this.template = this.$root.editing_artwork.template;
                this.artwork = { title: '' };
                this.original_field_contents = {};
                if (this.$root.editing_artwork.artwork) {
                    this.template.fields = this.$root.editing_artwork.artwork.fields;
                    for (const [key, value] of Object.entries(this.$root.editing_artwork.artwork)) {
                        if (
                            (key != 'fields')
                            && (!this.$root.editing_artwork.duplicate || ((key != 'id') && (key != 'title')))
                        ) {
                            this.artwork[key] = value;
                        }
                    }
                    this.temp_id = this.$root.editing_artwork.artwork.id;
                    if (this.$root.editing_artwork.duplicate) {
                        this.artwork.finished = false;
                    }
                } else {
                    this.template.fields.forEach((item) => {
                        if ((item.title_suffix == '_T') || (item.title_suffix == '_TA')) {
                            let parts = item.title.split('_');
                            if (parts[2] != '1') {
                                this.original_field_contents[item.id] = {
                                    data: item.data,
                                    data2: item.data2 || null
                                };
                                item.data = null;
                                item.data2 = null;
                            }
                        }
                    });
                }
                this.current_page = 1;
                if (!this.preview_resize_interval) {
                    this.preview_resize_interval = setInterval(function () {
                        vm.resizePreviews();
                    }, 200);
                }
                if (!this.hide_message_modal_interval) {
                    this.hide_message_modal_interval = setInterval(function () {
                        vm.hideMessageModal();
                    }, 500);
                }
                this.getArtworks();
                if (this.$root.isFeatured('profiles')) {
                    if (this.$root.profile_fields_changed) {
                        this.profile_fields = [];
                        this.$root.profile_fields_changed = false;
                    }
                    if (this.$root.profiles_changed) {
                        this.profile_groups = [];
                        this.$root.profiles_changed = false;
                    }
                    if (this.profile_fields.length == 0) {
                        this.getProfileFields();

                        return null;
                    }
                    if ((this.profile_groups.length == 0) && this.hasProfileFields()) {
                        this.showUpdatingPreview();
                        this.getProfiles();

                        return null;
                    }
                    this.setProfile();
                }
            },
            toggleEditArtworkTitle: function () {
                this.errors = false;
                $('.mandatory-field').removeClass('field-error');
                if (!this.editing_artwork_title) {
                    if (!this.isEditable()) {
                        return null;
                    }
                    this.new_artwork_title = this.artwork.title;

                    setTimeout(function () {
                        $('#new-artwork-title').focus();
                    }, 200);
                } else {
                    this.new_artwork_title = null;
                    this.processing_artwork = null;
                }
                this.editing_artwork_title = !this.editing_artwork_title;
            },
            updateArtworkTitle: function () {
                if (!this.updating_artwork_title) {
                    this.updating_artwork_title = true;
                    var vm = this;
                    setTimeout(function () {
                        vm.updateArtworkTitle();
                    }, 500);

                    return null;
                }

                if (!this.new_artwork_title) {
                    this.errors = 'The title field is required.';
                    this.updating_artwork_title = false;
                    $('#new-artwork-title').addClass('field-error');
                    $('#new-artwork-title').focus();

                    return null;
                }
                this.artwork_changed = true;
                this.artwork.title = this.new_artwork_title;
                this.updating_artwork_title = false;
                if (!this.processing_artwork) {
                    if (this.artwork.id) {
                        $('#artwork-save-modal').modal('show');
                    } else {
                        this.processing_artwork = this.artwork.finished ? 'Finishing' : 'Saving';
                    }
                }
                if ((this.processing_artwork == 'Saving') || (this.processing_artwork == 'Finishing')) {
                    this.createArtwork(this.processing_artwork == 'Saving');
                    this.new_artwork_title = null;
                    this.editing_artwork_title = false;

                    return null;
                }
                this.toggleEditArtworkTitle();
            },
            getPreviewImage: function () {
                this.loading_preview = true;
                /**
                 * Send request to API
                 */
                var vm = this;

                axios({
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + this.getPreviewImageApiPath(),
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src[vm.current_page] = response.data.image_src;
                        vm.resetZoomPreview();
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                })
                .finally(() =>
                    vm.loading_preview = false
                );
            },
            getPreviewImageApiPath: function () {
                if (this.temp_id) {
                    return '/template/' + this.template.id + '/artwork/' + this.temp_id + '/preview/'
                        + this.current_page;
                }
                let size = 'mediumres';
                if (this.asset.file_type == 'image/gif') {
                    size = 'highres';
                }
                /*if (this.asset.file_extension == 'svg') {
                    size = 'svg';
                }*/
                let path = '/resource/' + this.asset.id + '/preview/' + size;
                if (this.asset.number_of_pages || (this.current_page > 1)) {
                    path += '/' + this.current_page;
                }

                return path;
            },
            resetZoomPreview: function () {
                $('#artwork-editor .asset-preview.page .thumbnail img').removeClass('zoomable');
                $('#artwork-editor .asset-preview.page .thumbnail img').css({
                    'max-width': 'calc(100% - 2px)',
                    'margin': 'auto',
                    'left': 0,
                    'right': 0,
                    'top': 0,
                    'bottom': 0,
                    'height': 'unset'
                });
                this.preview_zoom_level = 0;
                this.preview_zoom_level_max = 0;
                this.preview_move = null;
            },
            zoomPreview: function (zoomIn = true) {
                if (zoomIn) {
                    if (this.preview_zoom_level < this.preview_zoom_level_max) {
                        this.preview_zoom_level++;
                    }

                    return null;
                }
                if (this.preview_zoom_level > 0) {
                    this.preview_zoom_level--;
                }
            },
            resizePreviews: function () {
                if (!this.asset.id || !this.$root.editing_artwork) {
                    return null;
                }
                const editorHeaderHeight = $('.artwork-editor-header').outerHeight();
                const fitScreenHeight = $(window).height() - editorHeaderHeight
                    - ((this.saved_artworks.length > 0) ? 170 : 110);
                
                let thumbnailHeight = fitScreenHeight - 2;
                $('#artwork-editor .asset-preview.page .thumbnail').css('height', thumbnailHeight);
                if (this.loading_preview) {
                    return null;
                }
                let imageHeight = fitScreenHeight - 2;
                const thumbnailWidth = $('#artwork-editor .asset-preview.page .thumbnail').outerWidth();
                let zoomAble = false;
                if (this.image_src[this.current_page]) {
                    if (this.image_src[this.current_page].indexOf('data:') == 0) {
                        const width = $('#artwork-editor .asset-preview.page .thumbnail img').outerWidth() || 0;
                        if (width) {
                            const height = $('#artwork-editor .asset-preview.page .thumbnail img').outerHeight();
                            const ratio = width / height;
                            let zeroLevelHeight = fitScreenHeight;
                            if ((zeroLevelHeight * ratio) > thumbnailWidth) {
                                zeroLevelHeight = Math.floor(thumbnailWidth / ratio);
                            }
                            const maxHeight = (ratio >= 1) ? 1100 : (1100 / ratio);
                            zoomAble = true;
                            imageHeight = Math.ceil(zeroLevelHeight * Math.pow(1.2, this.preview_zoom_level));
                            if (imageHeight > maxHeight) {
                                imageHeight = maxHeight;
                                this.preview_zoom_level_max = this.preview_zoom_level - 1;
                                this.preview_can_zoom_in = false;
                            } else {
                                this.preview_zoom_level_max = this.preview_zoom_level + 1;
                                this.preview_can_zoom_in = true;
                            }
                            this.showPreviewZoomTooltip();
                            $('#artwork-editor .asset-preview.page .thumbnail img').css({
                                'max-width': 'none',
                                'margin': 'unset'
                            });
                            const imageWidth = Math.round(imageHeight * ratio);
                            if (!$('#artwork-editor .asset-preview.page .thumbnail img').hasClass('zoomable')) {
                                $('#artwork-editor .asset-preview.page .thumbnail img').css({
                                    'left': Math.floor((thumbnailWidth - imageWidth) / 2) + 'px',
                                    'right': 'unset',
                                    'top': '0px',
                                    'bottom': 'unset'
                                });
                                $('#artwork-editor .asset-preview.page .thumbnail img').addClass('zoomable');
                            } else if ((imageWidth > (width + 2)) || (imageWidth < (width - 2))) {
                                const position = $('#artwork-editor .asset-preview.page .thumbnail img').first()
                                    .position();
                                const top = (imageWidth > (width + 2))
                                    ? position.top - Math.round((imageHeight - height) / 2)
                                    : position.top + Math.round((height - imageHeight) / 2);
                                const left = (imageWidth > (width + 2))
                                    ? position.left - Math.round((imageWidth - width) / 2)
                                    : position.left + Math.round((width - imageWidth) / 2);
                                $('#artwork-editor .asset-preview.page .thumbnail img').css({
                                    'left': left + 'px',
                                    'top': top + 'px'
                                });
                            }
                        }
                    }
                }
                $('#artwork-editor .asset-preview.page .thumbnail img').css({
                    'max-height': imageHeight,
                    'height': imageHeight
                });
                if (!zoomAble) {
                    this.resetZoomPreview();
                }
            },
            initMovePreview: function () {
                var vmArtworkEditor = this;
                $('#artwork-editor .asset-preview.page .thumbnail img').on('mousedown', function (event) {
                    vmArtworkEditor.onMouseDownPreview('mouse', event);
                });
                $('#artwork-editor .asset-preview.page .thumbnail img').on('touchstart', function (event) {
                    vmArtworkEditor.onMouseDownPreview('touch', event);
                });
                $(window).on('mousemove', function (event) {
                    vmArtworkEditor.onMouseMovePreview('mouse', event);
                });
                $(window).on('touchmove', function (event) {
                    vmArtworkEditor.onMouseMovePreview('touch', event);
                });
                $(window).on('mouseup', function (event) {
                    vmArtworkEditor.onMouseUpPreview();
                });
                $(window).on('touchend', function (event) {
                    vmArtworkEditor.onMouseUpPreview();
                });
            },
            isPreviewMovable: function () {
                if (!$('#artwork-editor .asset-preview.page .thumbnail img').hasClass('zoomable')) {
                    return false;
                }
                if (!this.preview_zoom_level) {
                    return false;
                }

                return true;
            },
            onMouseDownPreview: function (eventType, event) {
                if (!this.isPreviewMovable()) {
                    return null;
                }
                let mouseX = 0;
                let mouseY = 0;
                if (eventType == 'touch') {
                    mouseX = event.originalEvent.touches[0].pageX;
                    mouseY = event.originalEvent.touches[0].pageY;
                } else {
                    mouseX = event.originalEvent.pageX;
                    mouseY = event.originalEvent.pageY;
                }
                const position = $('#artwork-editor .asset-preview.page .thumbnail img').first().position();
                this.preview_move = {
                    originalX: mouseX,
                    originalY: mouseY,
                    originalImageLeft: position.left,
                    originalImageTop: position.top
                };
            },
            onMouseMovePreview: function (eventType, event) {
                if (!this.isPreviewMovable()) {
                    return null;
                }
                if (!this.preview_move) {
                    return null;
                }
                const flags = (event.buttons !== undefined) ? event.buttons : event.which;
                const mouseDown = ((flags & 1) === 1);
                if (!mouseDown) {
                    this.onMouseUpPreview();

                    return null;
                }
                let mouseX = 0;
                let mouseY = 0;
                if (eventType == 'touch') {
                    mouseX = event.originalEvent.touches[0].pageX;
                    mouseY = event.originalEvent.touches[0].pageY;
                } else {
                    mouseX = event.originalEvent.pageX;
                    mouseY = event.originalEvent.pageY;
                }
                const left = this.preview_move.originalImageLeft + mouseX - this.preview_move.originalX;
                const top = this.preview_move.originalImageTop + mouseY - this.preview_move.originalY;
                const imageWidth = $('#artwork-editor .asset-preview.page .thumbnail img').outerWidth();
                const imageHeight = $('#artwork-editor .asset-preview.page .thumbnail img').outerHeight();
                const thumbnailWidth = $('#artwork-editor .asset-preview.page .thumbnail').outerWidth();
                const thumbnailHeight = $('#artwork-editor .asset-preview.page .thumbnail').outerHeight();
                if (left > thumbnailWidth - 80) {
                    return null;
                }
                if (left < (80 - imageWidth)) {
                    return null;
                }
                if (top > thumbnailHeight - 80) {
                    return null;
                }
                if (top < (80 - imageHeight)) {
                    return null;
                }

                $('#artwork-editor .asset-preview.page .thumbnail img').css({
                    'left': left + 'px',
                    'top': top + 'px'
                });
            },
            onMouseUpPreview: function () {
                this.preview_move = null;
            },
            templateFields: function () {
                if (!this.template) {
                    return false;
                }
                if (!this.template.editable || (this.template.fields.length == 0)) {
                    return false;
                }
                let fields = [];
                let tablesAdded = [];
                const tableSuffixes = ['_T', '_TA', '_TD', '_TF'];
                this.template.fields.forEach((item) => {
                    if (item.page_number == this.current_page) {
                        let titleParts = item.title.split('_');
                        if ((tableSuffixes.indexOf(item.title_suffix) > -1) && (titleParts.length == 3)) {
                            if (tablesAdded.indexOf(titleParts[0]) < 0) {
                                // Group table fields.
                                let tableFields = [];
                                let errors = [];
                                let warnings = [];
                                this.template.fields.forEach((field) => {
                                    if (field.title.indexOf(titleParts[0] + '_') == 0) {
                                        tableFields.push(field);
                                        if (field.error || field.warning) {
                                            let titleParts2 = field.title.split('_');
                                            if (field.error) {
                                                errors.push(
                                                    titleParts2[1] + ' row ' + titleParts2[2] + ': ' + field.error
                                                );
                                            }
                                            if (field.warning) {
                                                warnings.push(
                                                    titleParts2[1] + ' row ' + titleParts2[2] + ': ' + field.warning
                                                );
                                            }
                                        }
                                    }
                                });
                                tablesAdded.push(titleParts[0]);
                                fields.push({
                                    id: item.id,
                                    type: 'table',
                                    title: titleParts[0],
                                    fields: tableFields,
                                    errors: errors,
                                    warnings: warnings
                                });
                            }
                        } else {
                            fields.push(item);
                        }
                    }
                });

                return (fields.length > 0) ? fields : false;
            },
            updateTemplateField: function (field, data, updatePreview = false, initial = false) {
                if (!this.template) {
                    return false;
                }
                if (!this.template.editable || (this.template.fields.length == 0)) {
                    return false;
                }
                this.template.fields.forEach((item) => {
                    if (
                        (item.type == field.type)
                        && (item.title.toLowerCase() == field.title.toLowerCase())
                        && (item.title_suffix == field.title_suffix)
                    ) {
                        for (const [key, value] of Object.entries(data)) {
                            item[key] = value;
                            if (!initial && (key == 'data')) {
                                this.artwork_changed = true;
                                this.fields_changed = true;
                            }
                        }
                    }
                });
                if (updatePreview) {
                    this.screenPreview();
                }
            },
            prevPage: function () {
                if (!this.asset.id || this.loading_fields || this.loading_preview) {
                    return null;
                }
                if (this.current_page == 1) {
                    return null;
                }
                this.current_page--;
            },
            nextPage: function () {
                if (!this.asset.id || this.loading_fields || this.loading_preview) {
                    return null;
                }
                if (this.current_page == this.asset.number_of_pages) {
                    return null;
                }
                this.current_page++;
            },
            isEditable: function () {
                if (!this.asset.id || !this.template) {
                    return false;
                }
                if (!this.template.editable) {
                    this.errors = 'This ' + this.$root.contents.template_title + ' is not editable';

                    return false;
                }

                return true;
            },
            screenPreview: function () {
                this.processing_artwork = 'Update preview';// + this.$root.contents.template_title;
                this.processArtwork();
            },
            pdfPreview: function () {
                if (!this.fields_changed && this.artwork.pdf_preview) {
                    this.resetDownloadArtwork(this.artwork.pdf_preview);

                    return null;
                }
                this.processing_artwork = 'PDF preview';
                this.processArtwork();
            },
            createArtwork: function (draft = false) {
                if (!draft && this.asset.suspended) {
                    this.$parent.message = {
                        type: null,
                        title: 'Finish',
                        text: '<p><i class="far fa-exclamation-triangle font-size-20 text-danger mr-2"></i>'
                            + this.$root.ucfirst(this.$root.contents.template_title) + ' suspended.</p>'
                            + '<p>You can keep your version by using Save for later.</p>',
                    };
                    $('#message-modal').modal('show');

                    return null;
                }
                this.processing_artwork = draft ? 'Saving' : 'Finishing';
                /*if (this.editing_artwork_title && this.new_artwork_title) {
                    //this.updateArtworkTitle();

                    return null;
                }*/
                if (!this.artwork.title) {
                    //this.processing_artwork = null;
                    this.editing_artwork_title = true;
                    this.errors = 'Please give your version a title.';
                    setTimeout(function () {
                        $('#new-artwork-title').addClass('field-error');
                        $('#new-artwork-title').focus();
                    }, 500);

                    return null;
                }
                // If not draft and already processed and not changed and approved just show output options.
                if (!draft && !this.artwork_changed && this.artwork.approved && this.artwork.finished) {
                    this.processing_artwork = null;
                    this.showTemplateOptions();

                    return null;
                }
                this.processArtwork();
            },
            showUpdatingPreview: function (title = 'initial') {
                let text = '<div style="text-align: center">';
                if (title == 'initial') {
                    title = null;
                    text += '<span class="font-size-16">Customising with your ' + this.$root.contents.profile_title
                        + "'s details</span>";
                } else {
                    text += (this.processing_artwork == 'Saving') ? 'Saving' : 'Updating preview';
                }
                text += '...</div>';
                this.$parent.message = {
                    type: null,
                    title: title,
                    loading: true,
                    text: text,
                    keep_open: true
                };
                if (!$('#message-modal').hasClass('show')) {
                    $('#message-modal').modal('show');
                }
            },
            processArtwork: function () {
                if (this.processing_artwork) {
                    this.showUpdatingPreview(this.processing_artwork);
                }
                if ((this.processing_artwork == 'Saving') || (this.processing_artwork == 'Finishing')) {
                    if (!this.fields_changed && this.artwork_processed) {
                        var vm = this;
                        setTimeout(function () {
                            vm.saveArtwork();
                        }, 550);

                        return null;
                    }
                }

                let fields = [];
                this.template.fields.forEach((item) => {
                    item.error = null;
                    item.warning = null;
                    fields.push({
                        type: item.type,
                        title: item.title,
                        title_suffix: item.title_suffix,
                        value: (item.type == 'I') ? (item.data || item.original_file_path) : item.data
                    });
                });

                /**
                 * Send request to API
                 */
                let tempId = 'unique';
                if (this.temp_id && isNaN(this.temp_id)) {
                    tempId = this.temp_id;
                }
                let data = {
                    fields: fields,
                    artwork_id: this.artwork.id || null,
                    temp_id: tempId
                };
                if (
                    (this.asset.number_of_pages > 1)
                    && (this.processing_artwork != 'Saving')
                    && (this.processing_artwork != 'Finishing')
                    && (this.processing_artwork != 'initial')
                ) {
                    data.page_number = this.current_page;
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + this.template.id
                    + '/artwork/process';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.temp_id = response.data.id;
                    /*if (
                        (vm.asset.number_of_pages == 1)
                        || (vm.processing_artwork == 'Saving')
                        || (vm.processing_artwork == 'Finishing')
                    ) {*/
                        vm.artwork_processed = true;
                    //}
                    vm.fields_changed = false;
                    vm.image_src = {};
                    vm.getPreviewImage();
                    if (response.data.pdf_preview) {
                        vm.artwork.pdf_preview = response.data.pdf_preview;
                    }
                    if (vm.processing_artwork == 'PDF preview') {
                        setTimeout(function () {
                            vm.resetDownloadArtwork(response.data.pdf_preview);
                        }, 2000);
                    }
                    if ((vm.processing_artwork == 'Saving') || (vm.processing_artwork == 'Finishing')) {
                        vm.saveArtwork();

                        return null;
                    }
                    vm.loading_fields = !vm.loading_fields;
                    vm.processing_artwork = null;
                })
                .catch(function (error) {
                    if (vm.processing_artwork == 'Initial') {
                        vm.processing_artwork = null;

                        return null;
                    }
                    vm.processing_artwork = null;
                    vm.$parent.message.loading = false;
                    vm.$parent.message.keep_open = false;
                    if ((error.response.status == 406) && error.response.data.errors) {
                        vm.showArtworkErrors(error.response.data);

                        return null;
                    }
                    vm.$parent.message.type = 'error';
                    vm.$parent.message.text = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    //
                });
            },
            showArtworkErrors: function (data) {
                let pageNum = 0;
                let text = '';
                if (data.warnings.length > 0) {
                    let tooMuchTextFields = 0;
                    data.warnings.forEach((warning) => {
                        this.template.fields.forEach((field) => {
                            let fieldTitle = field.title;
                            if (field.title_suffix) {
                                fieldTitle += field.title_suffix;
                            }
                            if (fieldTitle.toLowerCase() == warning.field.toLowerCase()) {
                                //field.warning = warning.warning;
                                if (warning.warning.indexOf('too much text') > 0) {
                                    if (!tooMuchTextFields) {
                                        field.warning = warning.warning; // Only highlight the first error.
                                        pageNum = field.page_number;
                                        tooMuchTextFields++;
                                    }
                                    //tooMuchTextFields++;
                                }
                                if (!pageNum && !tooMuchTextFields) {
                                    pageNum = field.page_number;
                                }
                            }
                        });
                    });
                    this.$parent.message.type = 'warning';
                    this.$parent.message.title = tooMuchTextFields ? 'Too much text' : 'Warning';
                    text = tooMuchTextFields ? 'There is too much text for the field'
                        : 'There are problems with the field';
                    if (tooMuchTextFields > 1) {
                        text += 's';
                    }
                    text += ' highlighted on the form';
                    this.$parent.message.text = text;

                    if ((pageNum > 0) && (pageNum != this.current_page)) {
                        this.current_page = pageNum;

                        return null;
                    }
                    this.loading_fields = true;

                    return null;
                }
                pageNum = 0;
                let charNotPresent = null;
                data.errors.forEach((error) => {
                    this.template.fields.forEach((field) => {
                        let fieldTitle = field.title;
                        if (field.title_suffix) {
                            fieldTitle += field.title_suffix;
                        }
                        if (fieldTitle.toLowerCase() == error.field.toLowerCase()) {
                            field.error = error.error;
                            if ((error.error == 'CharNotPresent') && !charNotPresent) {
                                if ((error.char != "'") && (error.char != '"')) {
                                    error.char = "'" + error.char + "'";
                                }
                                field.error = 'The ' + error.char + ' character is not present in the '
                                    + (error.font ? error.font + ' ' : '') + 'font';
                                pageNum = field.page_number;
                                charNotPresent = {
                                    user_first_name: this.$root.user.first_name,
                                    user_last_name: this.$root.user.last_name,
                                    user_email: this.$root.user.email,
                                    resource_id: this.asset.id,
                                    page_number: pageNum,
                                    field_title: field.title,
                                    field_title_suffix: field.title_suffix,
                                    char: error.char,
                                    font: error.font
                                };
                            }
                            if (!pageNum && !charNotPresent) {
                                pageNum = field.page_number;
                            }
                        }
                    });
                });
                this.$parent.message.type = 'error';
                this.$parent.message.title = charNotPresent ? 'Font issue' : 'Error';
                text = charNotPresent
                    ? 'There is a problem with the font in this field: ' + charNotPresent.field_title + '.'
                    : 'There are problems with the field(s) highlighted on the form';
                this.$parent.message.text = text;
                if (charNotPresent) {
                    this.$parent.message.link = {
                        component: this,
                        action: charNotPresent,
                        link_text: 'Click here',
                        additional_text: 'to report this.'
                    };
                }

                if ((pageNum > 0) && (pageNum != this.current_page)) {
                    this.current_page = pageNum;

                    return null;
                }
                this.loading_fields = true;
            },
            messageLinkAction: function (data) {
                /**
                 * Send request to API
                 */
                data.app_info = {
                    app_url: this.$root.app.app_url
                }

                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/report-field-error';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Do anything?
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            saveArtwork: function () {
                this.errors = false;
                // Check if requires approval.
                let requiresApproval = false;
                if (
                    (this.processing_artwork == 'Finishing')
                    && (this.template.approval == 'Y')
                    && (!this.$root.hasPermission('templates_auto_approval'))
                    && (!this.artwork.approved || !this.artwork.finished || this.artwork_changed)
                ) {
                    requiresApproval = true;
                }

                this.$parent.message.text = '<div style="text-align: center">'
                    + ((this.processing_artwork == 'Finishing') ? 'Finishing your version' : 'Saving')
                    + '...</div>';
                /**
                 * Send request to API
                 */
                const data = {
                    temp_id: this.temp_id,
                    title: this.artwork.title,
                    finished: ((this.processing_artwork == 'Finishing') && !requiresApproval),
                    profile_id: this.profile_id
                };
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + this.template.id
                    + '/artwork';
                if (this.artwork.id) {
                    url += '/' + this.artwork.id;
                }
                var vm = this;

                axios({
                    method: (this.artwork.id) ? 'put' : 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.artwork_changed = false;
                    vm.getArtworks(vm.artwork.id ? true : false);
                    const pdfPreview = vm.artwork.pdf_preview || null;
                    vm.artwork = response.data.artwork;
                    if (pdfPreview) {
                        vm.artwork.pdf_preview = pdfPreview;
                    }
                    vm.loading_fields = true;
                    if (vm.processing_artwork == 'Finishing') {
                        vm.artwork.pdf = response.data.pdf;
                        if (response.data.pdf_ncm) {
                            vm.artwork.pdf_ncm = response.data.pdf_ncm;
                        }
                        if (response.data.images) {
                            vm.artwork.images = response.data.images;
                        }

                        // Show download options.
                        setTimeout(function () {
                            vm.hideMessageModal(0);
                            vm.showTemplateOptions();
                        }, 200);

                        return null;
                    }
                    vm.$parent.message.type = 'success';
                    vm.$parent.message.text = 'Saved';
                })
                .catch(function (error) {
                    vm.$parent.message.type = 'error';
                    if ((error.response.status == 406) && (error.response.data.error == 'Resource replaced.')) {
                        vm.$parent.message.text =  'The ' + vm.$root.contents.template_title + ' used to create '
                            + 'your version has been updated. Please create a new version.';
                        vm.$parent.hideArtworkEditor();

                        return null;
                    }
                    vm.$parent.message.text = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.processing_artwork = null;
                    vm.$parent.message.loading = false;
                    vm.$parent.message.keep_open = false;
                });
            },
            hideMessageModal: function (delay = 2000) {
                if (!$('#message-modal').hasClass('show')) {
                    return null;
                }
                if (!this.$root.editing_artwork && !this.$parent.message.keep_open) {
                    return null;
                }
                if (this.$root.editing_artwork) {
                    if (this.processing_artwork || this.loading_profiles) {
                        return null;
                    }
                    if (this.$parent.message.link) {
                        return null;
                    }
                }
                var vm = this;
                if (!delay) {
                    $('#message-modal').modal('hide');
                    this.$parent.resetModalBackdrop();
                    setTimeout(function () {
                        vm.showPreviewZoomTooltip();
                    }, 1000);

                    return null;
                }
                setTimeout(function () {
                    vm.hideMessageModal(0);
                }, delay);
            },
            showPreviewZoomTooltip: function () {
                if (this.preview_zoom_tooltip_seen) {
                    return null;
                }
                if (!this.$root.editing_artwork) {
                    return null;
                }
                if ($('#message-modal').hasClass('show')) {
                    return null;
                }
                if (!this.image_src[this.current_page]) {
                    return null;
                }
                if (!this.preview_can_zoom_in && !this.preview_can_zoom_out) {
                    return null;
                }
                const component = this.$root.findComponent(this, 'artwork-preview-zoom-tooltip');
                if (component) {
                    component.doNotShowAgain();
                    $('#artwork-preview-zoom-tooltip-modal').modal('show');
                }
            },
            showTemplateOptions: function () {
                const component = this.$root.findComponent(this.$parent, 'template-options');
                if (component) {
                    component.reset(this.asset, this.template, this.artwork);
                    setTimeout(function () {
                        $('#template-options-modal').modal('show');
                    }, 1000);
                }
            },
            resetDownloadArtwork: function (data) {
                const component = this.$root.findComponent(this, 'artwork-download');
                if (component) {
                    component.reset('PDF preview', data);
                    $('#artwork-download-modal').modal('show');
                }
            },
            isFinished: function () {
                if (!('finished' in this.artwork)) {
                    return false;
                }
                if ((this.artwork.finished === true) || (this.artwork.finished === 1)) {
                    return true;
                }

                return false;
            },
            getArtworks: function (reload = false) {
                if (!this.template) {
                    this.saved_artworks = [];

                    return null;
                }
                if (reload) {
                    this.saved_artworks = [];
                }

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + this.template.id
                    + '/artworks/!deleted';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.saved_artworks = response.data.artworks;
                    setTimeout(function () {
                        vm.saved_artworks_loaded = true;
                    }, 500);
                })
                .catch(function (error) {
                    //
                });
            },
            hasSavedArtworks: function () {
                if (!this.template) {
                    return false;
                }
                if (!this.saved_artworks) {
                    return null;
                }

                return (this.saved_artworks.length > 0);
            },
            removeSavedArtwork: function (artworkId) {
                let itemIndex = -1;
                this.saved_artworks.forEach((item, index) => {
                    if (item.id == artworkId) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.saved_artworks.splice(itemIndex, 1);
                }
                if (this.$root.editing_artwork) {
                    if (this.artwork.id == artworkId) {
                        this.$parent.hideArtworkEditor();
                    }
                }
            },
            getProfileFields: function () {
                this.loading_profiles = true;
                /**
                 * Send get profile fields request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-fields';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.profile_fields = response.data.profile_fields;
                    if ((response.data.profile_fields.length > 0) && vm.hasProfileFields()) {
                        vm.showUpdatingPreview();
                        vm.getProfiles();

                        return null;
                    }
                    vm.loading_profiles = false;
                    vm.setProfile();
                })
                .catch(function (error) {
                    // Do anything?
                    vm.loading_profiles = false;
                });
            },
            getProfiles: function () {
                this.loading_profiles = true;
                this.$parent.message.text = '<div style="text-align: center"><span class="font-size-16">Loading '
                    + this.$root.contents.profile_groups_title + '</span>...</div>';
                /**
                 * Send get profile groups request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-groups/!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.profile_groups = response.data.profile_groups;
                    vm.setProfile();
                })
                .catch(function (error) {
                    // Do anything?
                })
                .finally(() => {
                    vm.loading_profiles = false;
                });
            },
            setProfile: function () {
                if (!this.$root.isFeatured('profiles')) {
                    return null;
                }
                if (!this.$root.user.profile_id && !this.$root.hasPermission('templates_change_profile')) {
                    this.$parent.message = {
                        type: null,
                        title: 'Edit ' + this.$root.contents.template_title,
                        text: '<p><i class="far fa-exclamation-triangle font-size-20 text-danger mr-2"></i>'
                            + 'You do not have a profile. Please contact the administrator.</p>',
                    };
                    if (!$('#message-modal').hasClass('show')) {
                        $('#message-modal').modal('show');
                    }
                    this.artwork_changed = false;
                    this.onClose();

                    return null;
                }
                if (!this.profile_id && this.artwork.profile_id) {
                    if (
                        (this.artwork.profile_id == this.$root.user.profile_id)
                        || this.$root.hasPermission('templates_change_profile')
                        || (
                            this.$root.hasPermission('templates_own_group_change_profile')
                            && this.isSameProfileGroup(this.artwork.profile_id, this.$root.user.profile_id)
                        )
                    ) {
                        this.profile_id = this.artwork.profile_id;
                        this.old_profile_id = this.artwork.profile_id;
                    }
                }
                if (!this.profile_id && this.$root.user.profile_id) {
                    this.profile_id = this.$root.user.profile_id;
                    this.old_profile_id = this.artwork.profile_id || null;
                }
                if (!this.profile_id) {
                    this.old_profile_id = this.profile_id;

                    return null;
                }
                if (!this.hasProfileFields()) {

                    return null;
                }
                this.updating_profile = true;
                let profileFound = false;
                this.profile_groups.forEach((group) => {
                    group.profiles.forEach((item) => {
                        if (item.id == this.profile_id) {
                            this.profile_group_id = group.id;
                            profileFound = true;
                        }
                    });
                });
                if (!profileFound) {
                    this.profile_group_id = null;
                    this.profile_id = null;
                }
                this.updating_profile = false;
                if (!this.profile_id) {

                    return null;
                }
                if (this.profile_id != this.old_profile_id) {
                    if (this.old_profile_id) {
                        this.artwork_changed = true;
                    }
                    // Profile has changed. Need to update fields/previews.
                    this.processing_artwork = 'initial';
                    var vm = this;
                    if (!this.old_profile_id/* && !this.hasProfileFields()*/) {
                        // Initial load - just process artwork.
                        setTimeout(function () {
                            vm.processArtwork();
                        }, 500);
                    } else {
                        // User changed profile - reload original template with the selected profile field contents.
                        setTimeout(function () {
                            vm.showUpdatingPreview(vm.processing_artwork);
                        }, 500);
                        this.artwork_processed = false;
                        this.reloadTemplate();
                    }
                }
                this.old_profile_id = this.profile_id;
                if (!this.artwork_processed) {
                    let profileFieldsChanged = false;
                    this.template.fields.forEach((item) => {
                        if (item.profile_field_changed) {
                            profileFieldsChanged = true;
                        }
                    });
                    if (profileFieldsChanged) {
                        this.screenPreview();
                    }
                }
            },
            hasProfileFields: function () {
                if (!this.$root.isFeatured('profiles')) {

                    return false;
                }
                if ((this.profile_fields.length == 0) || !this.template) {

                    return false;
                }
                let numMatchingFields = 0;
                this.template.fields.forEach((templateField) => {
                    this.profile_fields.forEach((profileField) => {
                        let suffix2 = (templateField.title_suffix == '_Lx') ? '_L' : 'none';
                        if (
                            (templateField.type == profileField.type)
                            && (templateField.title.toLowerCase() == profileField.title.toLowerCase()) 
                            && (
                                (templateField.title_suffix == profileField.title_suffix)
                                || (suffix2 == profileField.title_suffix)
                            )
                        ) {
                            numMatchingFields++;
                        }
                    });
                });

                return (numMatchingFields > 0);
            },
            canChangeProfile: function () {
                if (!this.hasProfileFields() || (this.profile_groups.length == 0)) {
                    return false;
                }

                if (this.$root.hasPermission('templates_change_profile')) {
                    return true;
                }

                if (!this.$root.hasPermission('templates_own_group_change_profile') || !this.$root.user.profile_id) {
                    return false;
                }

                if (!this.profile_group_id) {
                    return false;
                }

                return (!this.profile_id || (this.getProfileOptions(this.profile_group_id).length > 1));
            },
            onProfileGroupChange: function () {
                this.updating_profile = true;
                this.profile_id = null;
                this.updating_profile = false;
            },
            onProfileChange: function () {
                if (this.profile_id) {
                    this.setProfile();
                }
            },
            canSelectProfile: function () {
                if (this.updating_profile) {
                    return false;
                }
                if (!this.profile_group_id) {
                    return false;
                }

                return (this.profile_group_id > 0);
            },
            getProfileOptions: function (profileGroupId) {
                if ((this.profile_groups.length == 0) || !profileGroupId) {
                    return [];
                }
                let itemIndex = -1;
                this.profile_groups.forEach((item, index) => {
                    if (item.id == profileGroupId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    return this.profile_groups[itemIndex].profiles;
                }

                return [];
            },
            isProfileMissing: function () {
                if (!this.$root.isFeatured('profiles')) {
                    return false;
                }
                if (!this.profile_id) {
                    return true;
                }

                return false;
            },
            reloadTemplate: function () {
                /**
                 * Send get template details request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + this.asset.id
                    + '/profile/' + this.profile_id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.template.fields.forEach((field) => {
                        if (field.from_profile) {
                            vm.template.fields.forEach((item) => {
                                if (
                                    (field.type == item.type)
                                    && (field.title.toLowerCase() == item.title.toLowerCase())
                                    && (field.title_suffix == item.title_suffix)
                                ) {
                                    item.data = field.data;
                                    item.from_profile = field.from_profile;
                                    item.locked = field.locked;
                                    item.preview = null;
                                }
                            });
                        }
                    });
                    setTimeout(function () {
                        vm.processArtwork();
                    }, 200);
                })
                .catch(function (error) {
                    //
                });
                /*.finally(() => {
                    vm.reloading_template = false;
                });*/
            },
            onClose: function () {
                if (!this.artwork_changed) {
                    this.$parent.hideArtworkEditor();

                    return null;
                }
                this.$parent.confirm_delete = {
                    title: 'Close editor',
                    text: 'Are you sure you want to discard your current changes?',
                    component: this,
                    action: 'close-editor'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                if (action == 'close-editor') {
                    this.$parent.hideArtworkEditor();
                }
            },
            isSameProfileGroup: function (artworkProfileId, userProfileId) {
                if (!artworkProfileId || !userProfileId) {
                    return false;
                }
                if (this.profile_groups.length == 0) {
                    return false;
                }

                let artworkProfileGroupId = null;
                this.profile_groups.forEach((group) => {
                    group.profiles.forEach((item) => {
                        if (item.id == artworkProfileId) {
                            artworkProfileGroupId = group.id;
                        }
                    });
                });

                if (!artworkProfileGroupId) {
                    return false;
                }

                let userProfileGroupId = null;
                this.profile_groups.forEach((group) => {
                    group.profiles.forEach((item) => {
                        if (item.id == userProfileId) {
                            userProfileGroupId = group.id;
                        }
                    });
                });

                return (artworkProfileGroupId == userProfileGroupId);
            }
        }
    }
</script>
<style scoped>
    #artwork-editor {
        display: none;
        max-width: 1600px;
    }

    #artwork-editor.padding {
        padding-bottom: 50px;
    }

    .artwork-editor-header-cover {
        position: absolute;
        width: calc(100% - 32px);
        height: 0;
        background-color: white;
        z-index: 2;
    }

    .artwork-editor-header {
        position: relative;
        top: 0px;
        z-index: 2;
        background-color: white;
    }

    .artwork-editor-header.fixed {
        position: fixed;
        top: 70px;
    }

    .template-preview-sm.fixed {
        position: fixed;
    }

    h5.artwork-title {
        display: inline-block;
    }

    .asset-preview.page .thumbnail {
        height: 300px;
        overflow: hidden;
        padding: 0px;
        border: 1px solid #eff2f7;
    }

    .asset-preview.page .thumbnail img {
        max-height: 300px;
    }

    .asset-preview.page .thumbnail .image-loading {
        position: absolute;
        height: 30px;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .asset-preview.page .zoom-bar {
        position: absolute;
        top: 100px;
        right: 13px;
        width: 44px;
    }

    .template-preview-sm.fixed .zoom-bar {
        right: 2px;
    }
</style>
