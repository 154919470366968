<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="loading"><loading></loading></div>

        <div v-if="(!errors && !loading)" class="row">
            <div
                v-if="showLoginAndRegistrationSettings() || $root.hasPermission('content_edit')
                    || $root.hasPermission('homepage_edit')"
                class="col-xl-3 col-lg-4 col-md-6"
            >
                <div v-if="showLoginAndRegistrationSettings()" class="card">
                    <h5 class="card-header">Login &amp; registration</h5>
                    <ul class="list-group list-group-flush">
                        <li v-if="$root.hasPermission('content_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Login page background image
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent('Login page background image',
                                                'login_background')"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('setting_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Login panel position
                                </div>
                                <div class="col-4 text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            class="btn btn-light btn-sm dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            style="text-align: left"
                                        >
                                            {{ optionText(login_panel_position_options, settings.login_panel_position) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="option in login_panel_position_options"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('login_panel_position', option.key)"
                                            >{{ option.value }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('content_edit') && settings.allow_register)"
                            class="list-group-item"
                        >
                            <div
                                v-if="$root.hasPermission('content_edit')"
                                class="row no-gutters"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    Registration form introduction/instruction
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent('Registration form introduction/instruction',
                                                'registration_info', true)"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="(($root.hasPermission('setting_edit') || $root.hasPermission('content_edit'))
                                && settings.allow_register)"
                            class="list-group-item"
                        >
                            <div v-if="$root.hasPermission('setting_edit')" class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Additional fields for non-staff applicants
                                    <a
                                        class="font-size-15 ml-1"
                                        href="#"
                                        id="company-contact-details-tooltip"
                                        @click.prevent="doNothing()"
                                    >
                                        <i class="far fa-info-circle"></i>
                                    </a>
                                    <b-tooltip
                                        target="company-contact-details-tooltip"
                                        triggers="hover"
                                    >
                                        To facilitate Admin approval, require non-staff applicants to provide the name,
                                        email and telephone number for their staff contact.
                                    </b-tooltip>
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-22 text-right">
                                        <span
                                            v-if="!settings.company_contact_details"
                                            @click="updateSetting('company_contact_details', true)"
                                        >
                                            <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                        </span>
                                        <span v-else>
                                            <a
                                                href="#"
                                                @click.prevent="updateSetting('company_contact_details', false)"
                                            >
                                                <i class="far fa-toggle-on"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="($root.hasPermission('content_edit') && settings.company_contact_details)"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    Additional fields introduction/instruction
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent('Additional fields introduction/instruction',
                                                'company_contact_name_label', true)"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('users_pre_approved_email_domains') && settings.allow_register)"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Auto-approve domains
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'pre-approved-email-domains' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('setting_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    User password expires in
                                </div>
                                <div class="col-4 text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            class="btn btn-light btn-sm dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            style="text-align: left"
                                        >
                                            {{ optionText(password_expiry_days_options, settings.password_expiry_days) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="option in password_expiry_days_options"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('password_expiry_days', option.key)"
                                            >{{ option.value }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('content_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Company field title (term for "company")
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent('Term for &quot;company&quot;',
                                                'company_title', false, true)"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="$root.hasPermission('homepage_edit')" class="card">
                    <h5 class="card-header">Homepage</h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Show welcome panel
                                </div>
                                <div class="col-4 text-right">
                                    <div class="contact-links font-size-22 text-right">
                                        <span
                                            v-if="!settings.show_welcome_panel"
                                            @click="updateSetting('show_welcome_panel', true)"
                                        >
                                            <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                        </span>
                                        <span v-else>
                                            <a
                                                href="#"
                                                @click.prevent="updateSetting('show_welcome_panel', false)"
                                            >
                                                <i class="far fa-toggle-on"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="settings.show_welcome_panel"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div :class="'col-' + ($root.is_3xl_screen ? '6' : '12') + ' pt-1'">
                                    Welcome panel colour
                                </div>
                                <div class="col text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            :class="'btn btn-light btn-sm dropdown-toggle bg-'
                                                + settings.welcome_panel_colour + ' text-'
                                                + settings.welcome_panel_colour"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :style="colour_button_styles"
                                        >
                                            {{ optionText(colour_options, settings.welcome_panel_colour) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu limited-height">
                                            <a
                                                v-for="option in colour_options"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('welcome_panel_colour', option.key)"
                                            >
                                                <span
                                                    :class="'mr-2 bg-' + option.key"
                                                    :style="colour_span_styles"
                                                ></span>
                                                <span style="position: relative; top: -2px">{{ option.value }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="settings.show_welcome_panel"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div :class="'col-' + ($root.is_3xl_screen ? '6' : '12') + ' pt-1'">
                                    Welcome panel button colour
                                </div>
                                <div class="col text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            :class="'btn btn-light btn-sm dropdown-toggle bg-'
                                                + settings.welcome_panel_button_colour + ' text-'
                                                + settings.welcome_panel_button_colour"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :style="colour_button_styles"
                                        >
                                            {{ optionText(colour_options, settings.welcome_panel_button_colour) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu limited-height">
                                            <a
                                                v-for="option in colour_options"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('welcome_panel_button_colour', option.key)"
                                            >
                                                <span
                                                    :class="'mr-2 bg-' + option.key"
                                                    :style="colour_span_styles"
                                                ></span>
                                                <span style="position: relative; top: -2px">{{ option.value }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Homepage panels
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            @click.prevent="editHomepage()"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.isFeatured('flyte')" class="list-group-item">
                            <div class="row no-gutters">
                                <div :class="'col-' + ($root.is_3xl_screen ? '6' : '12') + ' pt-1'">
                                    Flyte panel header colour
                                </div>
                                <div class="col text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            :class="'btn btn-light btn-sm dropdown-toggle bg-'
                                                + settings.flyte_panel_header_colour + ' text-'
                                                + settings.flyte_panel_header_colour"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :style="colour_button_styles"
                                        >
                                            {{ optionText(colour_options, settings.flyte_panel_header_colour) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu limited-height">
                                            <a
                                                v-for="option in colour_options"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('flyte_panel_header_colour', option.key)"
                                            >
                                                <span
                                                    :class="'mr-2 bg-' + option.key"
                                                    :style="colour_span_styles"
                                                ></span>
                                                <span style="position: relative; top: -2px">{{ option.value }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row no-gutters" style="margin-top: 5px">
                                <div :class="'col-' + ($root.is_3xl_screen ? '6' : '12') + ' pt-1'">
                                    Flyte panel body colour
                                </div>
                                <div class="col text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            :class="'btn btn-light btn-sm dropdown-toggle bg-'
                                                + settings.flyte_panel_colour + ' text-'
                                                + settings.flyte_panel_colour"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :style="colour_button_styles"
                                        >
                                            {{ optionText(colour_options, settings.flyte_panel_colour) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu limited-height">
                                            <a
                                                v-for="option in colour_options"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('flyte_panel_colour', option.key)"
                                            >
                                                <span
                                                    :class="'mr-2 bg-' + option.key"
                                                    :style="colour_span_styles"
                                                ></span>
                                                <span style="position: relative; top: -2px">{{ option.value }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row no-gutters" style="margin-top: 5px">
                                <div :class="'col-' + ($root.is_3xl_screen ? '6' : '12') + ' pt-1'">
                                    Flyte panel button colour
                                </div>
                                <div class="col text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            :class="'btn btn-light btn-sm dropdown-toggle bg-'
                                                + settings.flyte_panel_button_colour + ' text-'
                                                + settings.flyte_panel_button_colour"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :style="colour_button_styles"
                                        >
                                            {{ optionText(colour_options, settings.flyte_panel_button_colour) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu limited-height">
                                            <a
                                                v-for="option in colour_options"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('flyte_panel_button_colour', option.key)"
                                            >
                                                <span
                                                    :class="'mr-2 bg-' + option.key"
                                                    :style="colour_span_styles"
                                                ></span>
                                                <span style="position: relative; top: -2px">{{ option.value }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="$root.hasPermission('content_edit')" class="card">
                    <h5 class="card-header">System &amp; company</h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.client.client_name }} UI version
                                </div>
                                <div class="col-4 pt-1 pb-1 text-right">
                                    {{ $root.app.client_version }}
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Resourcebase API version
                                </div>
                                <div class="col-4 pt-1 pb-1 text-right">
                                    {{ $root.app.api_version }}
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Company name
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent('Company name', 'company_name', false, true)"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="showAssetSettings() || showProofSettings()" class="col-xl-3 col-lg-4 col-md-6">
                <div v-if="showAssetSettings()" class="card">
                    <h5 class="card-header">{{ $root.ucfirst($root.contents.asset_title) }}s</h5>
                    <ul class="list-group list-group-flush">
                        <li v-if="$root.hasPermission('assets_asset_types')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.ucfirst($root.contents.asset_title) }} types
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'asset-types' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="$root.hasPermission('setting_edit')"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    Show {{ $root.contents.asset_title }} type menu bar
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-22 text-right">
                                        <span
                                            v-if="!settings.asset_type_filter_menu"
                                            @click="updateSetting('asset_type_filter_menu', true)"
                                        >
                                            <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                        </span>
                                        <span v-else>
                                            <a
                                                href="#"
                                                @click.prevent="updateSetting('asset_type_filter_menu', false)"
                                            >
                                                <i class="far fa-toggle-on"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li v-if="$root.hasPermission('content_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Term for "folder"
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent('Term for &quot;folder&quot;',
                                                'folder_title', false, true)"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="row no-gutters" style="margin-top: 5px">
                                <div class="col-8 pt-1 pb-1">
                                    Plural "folders"
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent('Plural &quot;folders&quot;',
                                                'folders_title', false, true)"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('content_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Term for "subfolder"
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent('Term for &quot;subfolder&quot;',
                                                'subfolder_title', false, true)"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="row no-gutters" style="margin-top: 5px">
                                <div class="col-8 pt-1 pb-1">
                                    Plural "subfolders"
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent('Plural &quot;subfolders&quot;',
                                                'subfolders_title', false, true)"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li v-if="$root.hasPermission('assets_manage_folders')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.ucfirst($root.contents.folders_title) + ' and '
                                        + $root.contents.subfolders_title }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'folders' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('assets_custom_fields')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Custom fields
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'custom-fields' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('assets_keyword_assistant')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Keyword assistant
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'keyword-categories' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="$root.client.assets_use_detected_labels
                                && $root.hasPermission('assets_keyword_assistant')"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    AI keyword exclusion list
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'detected-label-exclusions' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="$root.hasPermission('assets_manage_archive')
                                || $root.hasPermission('templates_manage_archive')"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Archived {{ $root.contents.asset_title }}s
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            title="View"
                                            @click.prevent="viewAssets({ name: 'assets-filtered', params: { filters: 'archived' } })"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('setting_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.ucfirst($root.contents.asset_title) }} approval expires in
                                </div>
                                <div class="col-4 text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            class="btn btn-light btn-sm dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            style="text-align: left"
                                        >
                                            {{ optionText(invite_expiry_days_options, settings.asset_approval_expiry_days) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="option in invite_expiry_days_options"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('asset_approval_expiry_days', option.key)"
                                            >{{ option.value }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('assets_approval_system')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.ucfirst($root.contents.asset_title) }} approvals
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'asset-approvals' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('assets_review')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.ucfirst($root.contents.asset_title) }}s due for review
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'asset-reviews' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="showProofSettings()" class="card">
                    <h5 class="card-header">Proof tracker</h5>
                    <ul class="list-group list-group-flush">
                        <li v-if="$root.hasPermission('setting_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Homepage view
                                </div>
                                <div class="col-4 text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            class="btn btn-light btn-sm dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            style="text-align: left"
                                        >
                                            {{ optionText(proofs_view_options, settings.proofs_view) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="option in proofs_view_options"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('proofs_view', option.key)"
                                            >{{ option.value }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('setting_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Default proof type
                                </div>
                                <div class="col-4 text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            class="btn btn-light btn-sm dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            style="text-align: left"
                                        >
                                            {{ optionText(proofs_type_options, settings.proofs_type) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="option in proofs_type_options"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('proofs_type', option.key)"
                                            >{{ option.value }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('proofs_view_archive')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Proof archive
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            title="View"
                                            @click.prevent="viewProofs(true)"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="showTemplateSettings() || showPrintOrderingSettings()" class="col-xl-3 col-lg-4 col-md-6">
                <div v-if="showTemplateSettings()" class="card">
                    <h5 class="card-header">{{ $root.ucfirst($root.contents.template_title) }}s</h5>
                    <ul class="list-group list-group-flush">
                        <li v-if="$root.hasPermission('templates_manage_resourcepage')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Font resource page
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#resourcepage-modal"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="showProfileFieldSettings()" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Profile field set
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'profile-fields' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="showProfileGroupSettings()" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.ucfirst($root.contents.profile_groups_title) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            title="View"
                                            @click.prevent="viewProfileGroups()"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('templates_brand_approvals')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.ucfirst($root.contents.template_title) }} marketing approvals
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'artwork-approvals' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="showPrintOrderingSettings()" class="card">
                    <h5 class="card-header">Item ordering</h5>
                    <ul class="list-group list-group-flush">
                        <li
                            v-if="$root.client.print_ordering_approval && ($root.hasPermission('printing_orders_view')
                                || $root.hasPermission('printing_financial_approvals'))"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Orders awaiting approval
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'print-orders-awaiting-approval' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="$root.client.print_ordering_approval && ($root.hasPermission('printing_orders_view')
                                || $root.hasPermission('printing_financial_approvals'))"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Declined orders
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'print-orders-declined' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="$root.hasPermission('printing_orders_view')
                                || $root.hasPermission('printing_financial_approvals')"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.client.print_ordering_approval ? 'All orders' : 'Orders' }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            title="View"
                                            @click.prevent="viewPrintOrders()"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('printing_view_archived_orders')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Archived orders
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'print-orders-archived' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('printing_manage_price_panels')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Price panels
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'price-panel-types' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('printing_manage_custom_fields')
                                || $root.hasPermission('content_edit') || $root.hasPermission('setting_edit'))"
                            class="list-group-item print-order-custom-fields"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Custom fields
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            @click.prevent="showPrintOrderCustomFields()"
                                        >
                                            <span v-b-tooltip.hover.left title="Show">
                                                <i class="far fa-chevron-circle-down"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('printing_manage_custom_fields')
                                || $root.hasPermission('content_edit') || $root.hasPermission('setting_edit'))"
                            class="list-group-item print-order-custom-field"
                            style="display: none"
                        >
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('content_edit'))"
                                class="row no-gutters"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    Custom field 1 title
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent('Item order form custom field 1 title',
                                                'print_order_custom_field_1_title')"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('setting_edit'))"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    Required field
                                </div>
                                <div class="col-4 text-right">
                                    <div class="contact-links font-size-22 text-right">
                                        <span
                                            v-if="!settings.print_order_custom_field_1_required"
                                            @click="updateSetting('print_order_custom_field_1_required', true)"
                                        >
                                            <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                        </span>
                                        <span v-else>
                                            <a
                                                href="#"
                                                @click.prevent="updateSetting('print_order_custom_field_1_required',
                                                    false)"
                                            >
                                                <i class="far fa-toggle-on"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('printing_manage_custom_fields')
                                || $root.hasPermission('content_edit') || $root.hasPermission('setting_edit'))"
                            class="list-group-item print-order-custom-field"
                            style="display: none"
                        >
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('content_edit'))"
                                class="row no-gutters"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    Custom field 2 title
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent('Item order form custom field 2 title',
                                                'print_order_custom_field_2_title')"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('setting_edit'))"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    Required field
                                </div>
                                <div class="col-4 text-right">
                                    <div class="contact-links font-size-22 text-right">
                                        <span
                                            v-if="!settings.print_order_custom_field_2_required"
                                            @click="updateSetting('print_order_custom_field_2_required', true)"
                                        >
                                            <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                        </span>
                                        <span v-else>
                                            <a
                                                href="#"
                                                @click.prevent="updateSetting('print_order_custom_field_2_required',
                                                    false)"
                                            >
                                                <i class="far fa-toggle-on"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('printing_manage_custom_fields')
                                || $root.hasPermission('content_edit') || $root.hasPermission('setting_edit'))"
                            class="list-group-item print-order-custom-field"
                            style="display: none"
                        >
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('content_edit'))"
                                class="row no-gutters"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    Custom field 3 title
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent('Item order form custom field 3 title',
                                                'print_order_custom_field_3_title')"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('setting_edit'))"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    Required field
                                </div>
                                <div class="col-4 text-right">
                                    <div class="contact-links font-size-22 text-right">
                                        <span
                                            v-if="!settings.print_order_custom_field_3_required"
                                            @click="updateSetting('print_order_custom_field_3_required', true)"
                                        >
                                            <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                        </span>
                                        <span v-else>
                                            <a
                                                href="#"
                                                @click.prevent="updateSetting('print_order_custom_field_3_required',
                                                    false)"
                                            >
                                                <i class="far fa-toggle-on"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('printing_manage_custom_fields')
                                || $root.hasPermission('content_edit') || $root.hasPermission('setting_edit'))"
                            class="list-group-item print-order-custom-field"
                            style="display: none"
                        >
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('content_edit'))"
                                class="row no-gutters"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    Custom field 4 title
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent('Item order form custom field 4 title',
                                                'print_order_custom_field_4_title')"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('setting_edit'))"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    Required field
                                </div>
                                <div class="col-4 text-right">
                                    <div class="contact-links font-size-22 text-right">
                                        <span
                                            v-if="!settings.print_order_custom_field_4_required"
                                            @click="updateSetting('print_order_custom_field_4_required', true)"
                                        >
                                            <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                        </span>
                                        <span v-else>
                                            <a
                                                href="#"
                                                @click.prevent="updateSetting('print_order_custom_field_4_required', false)"
                                            >
                                                <i class="far fa-toggle-on"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('printing_onscreen_confirmation')
                                    || $root.hasPermission('content_edit'))"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    On-screen confirmation
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent('On-screen confirmation',
                                                'print_order_confirmation', true)"
                                        >
                                            <span v-b-tooltip.hover.left title="Edit">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('printing_email_recipients')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Copy item order recipients
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'print-order-copy-recipients' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('printing_manage_suppliers')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Suppliers
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'print-order-suppliers' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="showUserSettings() || showReportSettings()" class="col-xl-3 col-lg-4 col-md-6">
                <div v-if="showUserSettings()" class="card">
                    <h5 class="card-header">Users</h5>
                    <ul class="list-group list-group-flush">
                        <!--<li v-if="$root.hasPermission('users_invite')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Invite a user
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#user-add-form-modal"
                                            @click.prevent="resetAddUserForm()"
                                        >
                                            <span v-b-tooltip.hover.left title="Invite a user">
                                                <i class="far fa-user-plus"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>-->
                        <li v-if="$root.hasPermission('users_view_details')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    All users
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            title="View"
                                            @click.prevent="viewUsers()"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('users_view_details')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Active users
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            title="View"
                                            @click.prevent="viewUsers('users', 'active')"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('users_view_details')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Invited users
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            title="View"
                                            @click.prevent="viewUsers('users', 'invited')"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('users_view_details')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Applied users
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            title="View"
                                            @click.prevent="viewUsers('users', 'applied')"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('users_view_deleted_users')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Deactivated users
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            title="View"
                                            @click.prevent="viewUsers('users', 'deleted')"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('users_view_details')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Deletion requests
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            title="View"
                                            @click.prevent="viewUsers('deletion-requests')"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('users_user_groups')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    User groups
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'user-groups' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('users_manage_alerts')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Administrator alerts
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'alerts' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="showReportSettings()" class="card">
                    <h5 class="card-header">Reports</h5>
                    <ul class="list-group list-group-flush">
                        <li v-if="$root.hasPermission('reports_overview')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    System overview
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'reports-overview' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_users')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Registrations
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'reports-registrations' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_users')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Visits
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'reports-visits' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_assets')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Uploads
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'reports-asset-uploads' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_assets')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Downloads
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'reports-asset-downloads' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_templates')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.ucfirst($root.contents.template_title) }}s created
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'reports-artwork-created' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_templates')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.ucfirst($root.contents.template_title) }}s
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'reports-templates' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_print_orders')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Orders
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'reports-orders' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_flyte')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Flyte activity
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            title="View"
                                            :to="{ name: 'reports-flytes-sent' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div
            v-if="$root.hasPermission('content_edit')"
            class="modal fade"
            id="edit-content-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="edit-content-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <edit-content></edit-content>
            </div>
        </div>
        <div
            v-if="$root.hasPermission('templates_manage_resourcepage')"
            class="modal fade"
            id="resourcepage-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="resourcepage-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <resourcepage></resourcepage>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: false,
                errors: false,
                settings: {
                    show_welcome_panel: false,
                    welcome_panel_colour: 'third',
                    welcome_panel_button_colour: 'primary',
                    flyte_panel_colour: 'third',
                    flyte_panel_header_colour: 'third',
                    flyte_panel_button_colour: 'primary',
                    login_panel_position: 'center',
                    allow_register: false,
                    company_contact_details: false,
                    must_accept_terms: false,
                    password_expiry_days: 366,
                    assets_per_page: 25,
                    asset_download_prefix: true,
                    asset_download_title: true,
                    asset_download_filename: true,
                    users_per_page: 25,
                    invite_expiry_days: 3,
                    asset_description_required: true,
                    asset_watermarking: true,
                    asset_approval_expiry_days: 7,
                    asset_approval_usage_notes: false,
                    asset_type_filter_menu: true,
                    print_order_custom_field_1_required: false,
                    print_order_custom_field_2_required: false,
                    print_order_custom_field_3_required: false,
                    print_order_custom_field_4_required: false,
                    proofs_view: 'folders',
                    proofs_type: 'unordered'
                },
                colour_options: [
                    {
                        key: 'primary',
                        value: this.$root.client.primary_colour_name
                    },
                    {
                        key: 'secondary',
                        value: this.$root.client.secondary_colour_name
                    },
                    {
                        key: 'third',
                        value: this.$root.client.third_colour_name
                    }
                ],
                login_panel_position_options: [
                    {
                        key: 'left',
                        value: 'Left'
                    },
                    {
                        key: 'center',
                        value: 'Center'
                    },
                    {
                        key: 'right',
                        value: 'Right'
                    }
                ],
                password_expiry_days_options: [
                    {
                        key: 0,
                        value: 'Never'
                    },
                    {
                        key: 31,
                        value: '1 month'
                    },
                    {
                        key: 61,
                        value: '2 months'
                    },
                    {
                        key: 92,
                        value: '3 months'
                    },
                    {
                        key: 183,
                        value: '6 months'
                    },
                    {
                        key: 366,
                        value: '1 year'
                    }
                ],
                invite_expiry_days_options: [
                    {
                        key: 2,
                        value: '2 days'
                    },
                    {
                        key: 3,
                        value: '3 days'
                    },
                    {
                        key: 7,
                        value: '7 days'
                    },
                    {
                        key: 14,
                        value: '14 days'
                    }
                ],
                per_page_options: [
                    {
                        key: 25,
                        value: 25
                    },
                    {
                        key: 50,
                        value: 50
                    },
                    {
                        key: 75,
                        value: 75
                    },
                    {
                        key: 100,
                        value: 100
                    }
                ],
                colour_button_styles: 'text-align: left; box-shadow: 0 0.05rem 0.75rem rgba(18, 38, 63, 0.03) '
                    + '!important',
                colour_span_styles: 'display: inline-block; width: 15px; height: 15px; border: 1px solid #eff2f7',
                proofs_view_options: [
                    {
                        key: 'folders',
                        value: 'Folders'
                    },
                    {
                        key: 'proofs',
                        value: 'All proofs'
                    }
                ],
                proofs_type_options: [
                    {
                        key: 'unordered',
                        value: 'Unordered'
                    },
                    {
                        key: 'ordered',
                        value: 'Ordered'
                    }
                ]
            }
        },
        mounted () {
            $('body').removeClass('login-body');
            this.loading = true;
            this.getSettings();
        },
        methods: {
            getSettings: function () {
                this.errors = false;

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/settings';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    for (const [key, value] of Object.entries(response.data.settings)) {
                        vm.settings[key] = value;
                    }
                    vm.getAdditionalColours();
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            getAdditionalColours: function () {
                if (this.$root.client.additional_colours) {
                    if (this.$root.client.additional_colours.length > 0) {
                        this.$root.client.additional_colours.forEach((item) => {
                            this.colour_options.push({ key: 'colour' + item.id, value: item.colour_name });
                        });
                    }

                    return null;
                }

                var vm = this;
                setTimeout(function () {
                    vm.getAdditionalColours();
                }, 500);
            },
            updateSetting: function(key, value = null) {
                if (value !== null) {
                    this.settings[key] = value;
                }
                let updateValue = this.settings[key];
                if (updateValue === true) {
                    updateValue = 'true';
                }
                if (updateValue === false) {
                    updateValue = 'false';
                }

                /**
                 * Send update setting request to API
                 */
                var url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/setting/' + key;
                var data = {
                    value: updateValue
                };
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // update client data stored in frontend
                    vm.$root.client[key] = vm.settings[key];
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.status == 404) {
                        /**
                         * Setting does not exists - create new
                         * Send create setting request to API
                         */
                        url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/setting';
                        data = {
                            key: key,
                            value: updateValue
                        };

                        axios({
                            method: 'post',
                            url: url,
                            data: data,
                            headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                        })
                        .then(response => {
                            // update client data stored in frontend
                            vm.$root.client[key] = vm.settings[key];
                        })
                        .catch(function (error) {
                            if (error.response.status == 401) {
                                // missing/lost API token - logout user
                                vm.$root.doLogout();
                            }
                            // reset setting if already exists in frontend
                            if (key in vm.$root.client) {
                                vm.settings[key] = vm.$root.client[key];
                            }
                            // show error
                            if (error.response.data.error) {
                                vm.errors = error.response.data.error;
                            } else {
                                vm.errors = 'Something went wrong, please try again';
                            }
                        });
                    } else {
                        // reset setting if already exists in frontend
                        if (key in vm.$root.client) {
                            vm.settings[key] = vm.$root.client[key];
                        }
                        // show error
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        } else {
                            vm.errors = 'Something went wrong, please try again';
                        }
                    }
                });
            },
            editContent: function (title, key, multiLine = false, mandatory = false) {
                const component = this.$root.findComponent(this, 'edit-content');
                if (component) {
                    component.resetForm(title, key, multiLine, mandatory);
                }
            },
            showLoginAndRegistrationSettings: function () {
                if (this.$root.hasPermission('content_edit') || this.$root.hasPermission('setting_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('users_pre_approved_email_domains')) {
                    return true;
                }

                return false;
            },
            showAssetSettings: function () {
                if (this.$root.hasPermission('content_edit') || this.$root.hasPermission('setting_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_asset_types')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_custom_fields')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_keyword_assistant')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_manage_archive')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_manage_archive')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_approval_system')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_review')) {
                    return true;
                }

                return false;
            },
            showProfileFieldSettings: function () {
                if (this.$root.hasPermission('profiles_fieldset_add')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_fieldset_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_fieldset_delete')) {
                    return true;
                }

                return false;
            },
            showProfileSettings: function () {
                if (this.$root.hasPermission('profiles_profiles_add')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_profiles_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_profiles_delete')) {
                    return true;
                }

                return false;
            },
            showProfileGroupSettings: function () {
                if (this.$root.hasPermission('profiles_groups_add')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_groups_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_groups_delete')) {
                    return true;
                }

                return this.showProfileSettings();
            },
            showTemplateSettings: function () {
                if (this.$root.hasPermission('templates_manage_resourcepage')) {
                    return true;
                }
                if (this.showProfileGroupSettings()) {
                    return true;
                }
                if (this.showProfileFieldSettings()) {
                    return true;
                }
                if (this.$root.hasPermission('templates_brand_approvals')) {
                    return true;
                }

                return false;
            },
            showUserSettings: function () {
                if (this.$root.hasPermission('users_invite')) {
                    return true;
                }
                if (this.$root.hasPermission('users_view_details')) {
                    return true;
                }
                if (this.$root.hasPermission('users_view_deleted_users')) {
                    return true;
                }
                if (this.$root.hasPermission('users_user_groups')) {
                    return true;
                }
                if (this.$root.hasPermission('users_manage_alerts')) {
                    return true;
                }

                return false;
            },
            showPrintOrderingSettings: function () {
                if (!this.$root.isFeatured('printing')) {
                    return false;
                }
                if (this.$root.hasPermission('printing_manage_suppliers')) {
                    return true;
                }
                if (this.$root.hasPermission('printing_manage_price_panels')) {
                    return true;
                }
                if (this.$root.hasPermission('printing_manage_custom_fields')) {
                    return true;
                }
                if (this.$root.hasPermission('setting_edit') || this.$root.hasPermission('content_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('printing_orders_view')) {
                    return true;
                }
                if (this.$root.hasPermission('printing_view_archived_orders')) {
                    return true;
                }
                if (this.$root.hasPermission('printing_financial_approvals')) {
                    return true;
                }
                if (this.$root.hasPermission('printing_onscreen_confirmation')) {
                    return true;
                }
                if (this.$root.hasPermission('printing_email_recipients')) {
                    return true;
                }

                return false;
            },
            showReportSettings: function () {
                if (this.$root.hasPermission('reports_users') || this.$root.hasPermission('reports_overview')) {
                    return true;
                }
                if (this.$root.hasPermission('reports_assets') || this.$root.hasPermission('reports_flyte')) {
                    return true;
                }
                if (this.$root.hasPermission('reports_templates') || this.$root.hasPermission('reports_print_orders')) {
                    return true;
                }

                return false;
            },
            showProofSettings: function () {
                if (!this.$root.isFeatured('proofs')) {
                    return false;
                }
                if (this.$root.hasPermission('setting_edit') || this.$root.hasPermission('proofs_view_archive')) {
                    return true;
                }

                return false;
            },
            optionText: function (options, key) {
                let itemIndex = -1;
                options.forEach((item, index) => {
                    if (item.key == key) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    return options[itemIndex].value;
                }

                return 'Select';
            },
            setOption: function (key, value) {
                this.settings[key] = value;
                this.updateSetting(key, value);
            },
            editHomepage: function () {
                if (!this.$root.hasPermission('homepage_edit')) {
                    return null;
                }
                this.$root.editing_homepage = true;
                this.$router.push({ name: 'home' });
            },
            viewAssets: function (route) {
                this.$root.asset_filters = [];
                this.$router.push(route);
            },
            viewUsers: function (pageName = 'users', status = null) {
                this.$root.user_filters = [];
                if (status) {
                    this.$root.addFilter('user_filters', {
                        key: 'status',
                        value: status,
                        text: this.$root.ucfirst(status),
                        filters_key: 'user_filters',
                        hidden: (status == -1)
                    });
                }
                this.$router.push({ name: pageName });
            },
            viewProofs: function (archived = false) {
                this.$root.proof_filters = [];
                if (archived) {
                    this.$root.addFilter('proof_filters', {
                        key: 'archived',
                        value: 1,
                        text: 'archived',
                        filters_key: 'proof_filters',
                        hidden: true
                    });
                }
                this.$router.push({ name: 'proofs' });
            },
            viewProfileGroups: function () {
                this.$root.profile_group_filters = [];
                this.$router.push({ name: 'profile-groups' });
            },
            doNothing: function () {
                return null;
            },
            resetAddUserForm: function () {
                const component = this.$root.findComponent(this.$parent, 'user-add-form');
                if (component) {
                    component.resetForm();
                }
            },
            viewPrintOrders: function () {
                this.$root.print_order_filters = [];
                this.$router.push({ name: 'print-orders' });
            },
            showPrintOrderCustomFields: function () {
                $('.print-order-custom-fields').slideUp(350);
                $('.print-order-custom-field').slideDown(350);
            }
        }
    }
</script>
<style scoped>
    .dropdown-menu.limited-height {
        max-height: 250px;
        overflow-y: auto;
    }
</style>
