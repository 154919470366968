<template>
    <div :id="field_id" :class="field_class" style="height: auto">
        <div v-if="(multiple && selectedItems())">
            <span
                v-for="item in selectedItems()"
                :key="item.id"
                class="badge badge-soft-secondary contact-links font-size-12 mr-2 mb-2"
            >
                <span v-html="item.name"></span>
                <a
                    v-if="!readonly"
                    href="#"
                    v-b-tooltip.hover
                    title="Remove"
                    @click.prevent="removeItem(item)"
                >
                    <i class="far fa-times"></i>
                </a>
                <span v-if="readonly">
                    <i class="far fa-times"></i>
                </span>
            </span>
        </div>

        <div v-if="(!selectedItems() && multiple && placeholder)" class="text-muted mb-2">{{ placeholder }}</div>

        <div>
            <div class="dropdown">
                <input
                    v-model="keyword"
                    type="text"
                    :class="'form-control dropdown-toggle pr-5' + (small ? ' form-control-sm' : '')"
                    :id="'option-keyword-' + field_id"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-toggle="dropdown"
                    data-display="static"
                    v-on:focus="keywordPlaceholder(true)"
                    v-on:keyup="showOptions()"
                    :placeholder="keywordPlaceholder()"
                    v-on:blur="keywordPlaceholder(true)"
                    aria-label="Click here to select"
                    :disabled="readonly"
                    autocomplete="off"
                    @click="is_dropdown_shown = !is_dropdown_shown"
                >
                <span :class="'sol-chevron' + (small ? '-sm' : '')" @click="showOptions(!is_dropdown_shown)">
                    <span><i class="fas fa-chevron-down"></i></span>
                </span>
                <button
                    v-if="((keyword || (!multiple && (singleselect_value !== null))) && !readonly)"
                    type="button"
                    :class="'btn btn-link clear-keyword' + (small ? ' btn-sm' : '')"
                    @click.prevent="clearKeyword()"
                >
                    <i class="far fa-times"></i>
                </button>
                <div
                    class="dropdown-menu options-dropdown"
                    :aria-labelledby="'option-keyword-' + field_id"
                >
                    <div v-if="(multiselect && select_all && (options.length > 1))">
                        <p class="small pr-4 mb-1 text-right">
                            <a href="#" @click.prevent="selectAll()">Select all</a>
                            <a href="#" class="ml-3" @click.prevent="deSelectAll()">Deselect all</a>
                        </p>
                        <div class="dropdown-divider"></div>
                    </div>
                    <div class="options">
                        <div v-for="item in getFilteredItems()" :key="item.id">
                            <div
                                :class="optionClass(item)"
                                @click="onOptionClick(item)"
                            >
                                <input
                                    v-if="multiselect"
                                    v-model="item.selected"
                                    type="checkbox"
                                    class="custom-control-input"
                                    :value="item.id"
                                    :disabled="readonly || item.disabled"
                                >
                                <input
                                    v-if="!multiselect"
                                    v-model="singleselect_value"
                                    type="radio"
                                    :id="field_id + '-item-' + item.id"
                                    class="custom-control-input"
                                    :value="item.id"
                                    :disabled="readonly || item.disabled"
                                >
                                <label
                                    class="custom-control-label"
                                    :for="field_id + '-item-' + item.id"
                                    v-html="item.name"
                                ></label>
                            </div>
                        </div>
                        <div v-if="(numFilteredItems() > max_items)">
                            <div class="dropdown-divider"></div>
                            <div class="dropdown-item-text">
                                <strong>
                                    There are {{ max_items }}+ results. Use the search field above to filter the list.
                                </strong>
                            </div>
                        </div>
                    </div>
                    <span
                        v-if="noMatch()"
                        class="dropdown-item-text"
                    >
                        No result found
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'value',
            'multiple',
            'options',
            'field_id',
            'field_class',
            'readonly',
            'placeholder',
            'search_placeholder',
            'select_all',
            'small'
        ],
        data() {
            return {
                multiselect: false,
                items: [],
                singleselect_value: null,
                keyword: '',
                is_dropdown_shown: false,
                max_items: 100
            };
        },
        mounted () {
            this.multiselect = (this.multiple || false);
            this.resetItems(this.options, this.value);
            $(document).on('click', '.options-dropdown', function (e) {
                e.stopPropagation();
            });
            $(document).on('click', '.sol-chevron', function (e) {
                e.stopPropagation();
            });

            var vm = this;
            $(document).on('click', function () {
                vm.is_dropdown_shown = false;
            });
        },
        watch: {
            options: function (val, oldVal) {
                let changed = false;
                if ((typeof val) !== (typeof oldVal)) {
                    changed = true;
                } else if ((typeof val === 'object') && (val !== null) && (oldVal !== null)) {
                    if (val.length != oldVal.length) {
                        changed = true;
                    } else {
                        val.forEach((elem, index) => {
                            if ((elem.id !== oldVal[index].id) || (elem.disabled != oldVal[index].disabled)) {
                                changed = true;
                            }
                        });
                    }
                } else {
                    changed = (val !== oldVal)
                }
                if (!changed) {
                    return null;
                }

                this.resetItems(val, this.value);
            },
            value: function (val, oldVal) {
                let changed = false;
                if ((typeof val) !== (typeof oldVal)) {
                    changed = true;
                } else if ((typeof val === 'object') && (val !== null) && (oldVal !== null)) {
                    if (val.length != oldVal.length) {
                        changed = true;
                    } else {
                        val.forEach((elem, index) => {
                            if (elem !== oldVal[index]) {
                                changed = true;
                            }
                        });
                    }
                } else {
                    changed = (val !== oldVal)
                }
                if (!changed) {
                    return null;
                }

                this.resetItems(this.options, val);
            },
            is_dropdown_shown: function (val) {
                this.$emit('sol-dropdown', { id: this.field_id, show: val });
            },
            singleselect_value: function (val) {
                this.keywordPlaceholder(true);
                if (this.multiselect || (val === this.value)) {
                    return null;
                }
                this.$emit('input', val);
                $('#option-keyword-' + this.field_id).dropdown('hide');
                this.keyword = '';
                this.$emit('change');
            }
        },
        methods: {
            resetItems: function (options, selectedOptions) {
                let selected = null;
                if ((typeof selectedOptions === 'undefined') || (selectedOptions === null)) {
                    selected = this.multiselect ? [] : null;
                } else {
                    selected = selectedOptions;
                }

                this.items = [];
                let singleSelectValue = null;
                if (options) {
                    options.forEach((item) => {
                        this.items.push({
                            id: item.id,
                            name: item.name,
                            disabled: item.disabled,
                            selected: this.multiselect ? (selected && (selected.indexOf(item.id) > -1))
                                : (item.id == selected)
                        });
                        if (!this.multiselect && (item.id == selected)) {
                            singleSelectValue = selected;
                        }
                    });
                }
                this.singleselect_value = singleSelectValue;
            },
            showOptions: function (show = true) {
                if (show) {
                    if (this.is_dropdown_shown) {
                        return null;
                    }
                    var vm = this;
                    setTimeout(function () {
                        $('#option-keyword-' + vm.field_id).dropdown('show');
                        vm.is_dropdown_shown = true;
                    }, 50);
                }
                $('#option-keyword-' + this.field_id).dropdown('hide');
                this.is_dropdown_shown = false;
            },
            optionClass: function (item) {
                const className = (this.multiselect ? 'custom-checkbox' : 'custom-radio' )
                    + ' custom-control dropdown-item pl-5 option';

                if (item.disabled) {
                    return className + ' disabled';
                }

                if (item.selected) {
                    return className + ' active';
                }

                return className;
            },
            onOptionClick: function (itemClicked) {
                if (this.readonly || itemClicked.disabled) {
                    return null;
                }
                if (this.multiselect) {
                    itemClicked.selected = !itemClicked.selected;
                    if (itemClicked.selected) {
                        if (itemClicked.id == -1) {
                            this.items.forEach((item) => {
                                if (item.id != -1) {
                                    item.selected = false;
                                }
                            });
                        } else {
                            this.items.forEach((item) => {
                                if (item.id == -1) {
                                    item.selected = false;
                                }
                            });
                        }
                    }
                    this.$emit('input', this.getItems());
                    this.$emit('change');

                    return null;
                }
                this.singleselect_value = itemClicked.id;
            },
            removeItem: function (itemToRemove) {
                itemToRemove.selected = false;
                if (!this.multiselect) {
                    this.singleselect_value = null;
                }
                this.$emit('input', this.getItems());
                this.$emit('change');
            },
            getItems: function () {
                if (this.multiselect) {
                    let selectedItems = [];
                    this.items.forEach((item) => {
                        if (item.selected) {
                            selectedItems.push(item.id);
                        }
                    });

                    return selectedItems;
                }

                return this.singleselect_value;
            },
            getFilteredItems: function () {
                let filteredItems = [];
                this.items.forEach((item) => {
                    if (
                        (
                            (filteredItems.length < this.max_items)
                            || (this.multiselect && item.selected)
                            || (!this.multiselect && (item.id == this.singleselect_value))
                        )
                        && (!this.keyword || (item.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1))
                    ) {
                        filteredItems.push(item);
                    }
                });
                
                return filteredItems;
            },
            numFilteredItems: function () {
                let numItems = 0;
                this.items.forEach((item) => {
                    if (!this.keyword || (item.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1)) {
                        numItems++;
                    }
                });
                
                return numItems;
            },
            selectedItems: function () {
                let itemsSelected = [];
                this.items.forEach((item) => {
                    if (
                        (this.multiselect && item.selected)
                        || (!this.multiselect && (item.id == this.singleselect_value))
                    ) {
                        itemsSelected.push(item);
                    }
                });

                return (itemsSelected.length > 0) ? itemsSelected : null;
            },
            keywordPlaceholder: function (update = false) {
                const focused = $('#option-keyword-' + this.field_id).is(':focus');
                if (focused) {
                    if (update) {
                        $('#option-keyword-' + this.field_id).attr('placeholder', null);
                    }

                    return null;
                }
                const text = (this.multiselect || this.singleselect_value === null)
                    ? (this.search_placeholder || 'Click here to select')
                    : this.selectedItems()[0].name;
                if (update) {
                    $('#option-keyword-' + this.field_id).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            noMatch: function () {
                let itemMatched = false;
                this.items.forEach((item) => {
                    if (item.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1) {
                        itemMatched = true;
                    }
                });

                return !itemMatched;
            },
            clearKeyword: function () {
                if (this.keyword) {
                    this.keyword = '';

                    return null;
                }
                if (!this.multiple) {
                    this.singleselect_value = null;
                }
            },
            selectAll: function () {
                this.items.forEach((item) => {
                    if (item.disabled) {
                        item.selected = false;
                    } else {
                        item.selected = (item.id != -1);
                    }
                });
                this.$emit('input', this.getItems());
                this.$emit('change');
            },
            deSelectAll: function () {
                this.items.forEach((item) => {
                    item.selected = (item.id == -1);
                });
                this.$emit('input', this.getItems());
                this.$emit('change');
            }
        }
    }
</script>
<style scoped>
    .options-dropdown {
        width: 100%;
    }

    .options-dropdown .options {
        max-height: 200px;
        overflow-y: auto;
    }

    .option {
        white-space: normal;
    }

    .option label {
        font-weight: 400;
    }

    .option.disabled label {
        color: #adb5bd;
    }

    .option.disabled:hover {
        background-color: transparent;
    }

    .sol-chevron {
        position: absolute;
        top: 8px;
        right: 12px;
    }

    .sol-chevron-sm {
        position: absolute;
        top: 4px;
        right: 10px;
        font-size: 11px;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 22px;
    }
</style>
