<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5
                class="modal-title"
                :id="(info_only ? 'proof_info' : 'proof-leader-actions') + '-modal-label'"
            >Proof info</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col">
                    <div v-if="!uploading_linked_file" class="table-responsive mb-0">
                        <table class="table table-sm">
                            <tbody>
                                <tr>
                                    <td style="border-top: none">
                                        Leader: {{ $parent.getLeaderName() }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span v-if="proof.proof_folder_id">
                                            Folder: {{ proof.folder_name }}
                                        </span>
                                        <span v-else>Standalone proof</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Proof type:
                                        {{ proof.ordered ? 'Ordered' : 'Unordered' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Leader alert: {{ $parent.getLeaderAlert() }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Time limit per reviewer:
                                        <span v-if="!proof.review_alerts_interval">No limit</span>
                                        <span v-if="proof.review_alerts_interval == 1">1 hour</span>
                                        <span v-if="proof.review_alerts_interval > 1">
                                            {{ proof.review_alerts_interval }} hours
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Start date: {{ $parent.formatDate(proof.created_at) }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        Project target date:
                                        {{ proof.target_date ? $parent.formatDate(proof.target_date) : 'Not set' }}
                                    </td>
                                </tr>
                                <tr v-if="proof.version > 1">
                                    <td>This proof: {{ $parent.formatDate(proof.version_created_at) }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        Cycle target date:
                                        {{ proof.version_target_date ? $parent.formatDate(proof.version_target_date)
                                            : 'Not set' }}
                                    </td>
                                </tr>
                                <tr v-if="proof.asset_id">
                                    <td>
                                        Linked {{ $root.contents.asset_title }}:&nbsp;
                                        <a
                                            v-if="proof.linked_resource"
                                            href="#"
                                            @click.prevent="$parent.downloadLinkedAsset()"
                                            style="white-space: nowrap"
                                        >
                                            <span
                                                v-if="!downloading_linked_asset"
                                                :key="'download-linked-asset'"
                                            >
                                                <i class="far fa-download"></i>
                                                Click to download
                                            </span>
                                            <span
                                                v-else
                                                :key="'downloading-linked-asset'"
                                            >
                                                <i class="fal fa-spinner-third fa-spin"></i>
                                                Downloading
                                            </span>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-if="!info_only" class="form-group" id="la-linked-file-upload" style="display: none">
                        <proof-linked-file-upload
                            :form="'leader-action'"
                            v-on:file-uploaded="onLinkedFileUploaded"
                        ></proof-linked-file-upload>
                    </div>
                </div>
                <div v-if="!info_only" class="col-lg-4 col-sm-6 col-12 pt-sm-0 pt-3">
                    <div v-if="$parent.isLeader()">
                        <button
                            v-if="!proof.archived"
                            :key="'email-proof-button'"
                            type="button"
                            class="btn btn-secondary btn-block mb-2"
                            data-toggle="modal"
                            data-target="#proof-share-modal"
                            @click="leaderAction('email-proof')"
                            :disabled="leader_action"
                        >
                            <i class="far fa-envelope mr-1"></i> Email proof
                        </button>
                        <button
                            v-if="!proof.archived"
                            :key="'change-folder-button'"
                            type="button"
                            class="btn btn-secondary btn-block mb-2"
                            data-toggle="modal"
                            data-target="#proof-change-folder-modal"
                            @click="leaderAction('change-folder')"
                            :disabled="leader_action"
                        >
                            <i class="far fa-folder mr-1"></i> Change folder
                        </button>
                    </div>
                    <div>
                        <button
                            v-if="leader_action != 'delete'"
                            :key="'delete-proof-button'"
                            type="button"
                            class="btn btn-secondary btn-block mb-2"
                            @click="leaderAction('delete-proof')"
                            :disabled="leader_action"
                        >
                            <i class="far fa-trash-can mr-1"></i> Delete proof
                        </button>
                        <button
                            v-else
                            :key="'deleting-proof-button'"
                            type="button"
                            class="btn btn-secondary btn-block mb-2"
                        >
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Delete proof</span>
                        </button>
                    </div>
                    <div v-if="$parent.isLeader()">
                        <div v-if="!proof.archived && !proof.approved && $root.client.proof_link_assets">
                            <button
                                v-if="leader_action != 'link-asset'"
                                :key="'link-asset-button'"
                                type="button"
                                class="btn btn-secondary btn-block mb-2"
                                @click="leaderAction('link-asset')"
                                :disabled="leader_action || !$root.hasPermission('assets_access')"
                            >
                                <i class="far fa-file mr-1"></i>
                                <span v-if="proof.asset_id">Change linked {{ $root.contents.asset_title }}</span>
                                <span v-else>
                                    Link {{ $root.anA($root.contents.asset_title) }}
                                    {{ $root.contents.asset_title }}
                                </span>
                            </button>
                            <button
                                v-else
                                :key="'linking-asset-button'"
                                type="button"
                                class="btn btn-secondary btn-block mb-2"
                            >
                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                <span style="opacity: 0.5">
                                    <span v-if="proof.asset_id">Change linked {{ $root.contents.asset_title }}</span>
                                    <span v-else>
                                        Link {{ $root.anA($root.contents.asset_title) }}
                                        {{ $root.contents.asset_title }}
                                    </span>
                                </span>
                            </button>

                            <button
                                v-if="leader_action != 'upload-linked-file'"
                                :key="'upload-linked-file-button'"
                                type="button"
                                class="btn btn-secondary btn-block mb-2"
                                @click="leaderAction('upload-linked-file')"
                                :disabled="leader_action"
                            >
                                <i class="far fa-cloud-upload mr-1"></i> Upload and link a file
                            </button>
                            <button
                                v-else
                                :key="'uploading-linked-file-button'"
                                type="button"
                                class="btn btn-secondary btn-block mb-2"
                            >
                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                <span style="opacity: 0.5">Upload and link a file</span>
                            </button>
                        </div>
                        <div v-if="!proof.archived && !proof.approved">
                            <button
                                :key="'change-leader-alerts'"
                                type="button"
                                class="btn btn-secondary btn-block mb-2"
                                data-toggle="modal"
                                data-target="#proof-change-leader-alerts-modal"
                                @click="leaderAction('change-leader-alerts')"
                                :disabled="leader_action"
                            >
                                <i class="far fa-bell-on mr-1"></i> Change leader alert
                            </button>
                            <button
                                :key="'change-review-alerts'"
                                type="button"
                                class="btn btn-secondary btn-block mb-2"
                                data-toggle="modal"
                                data-target="#proof-change-review-alerts-modal"
                                @click="leaderAction('change-review-alerts')"
                                :disabled="leader_action"
                            >
                                <i class="far fa-alarm-exclamation mr-1"></i> Change reviewer time limit
                            </button>
                            <button
                                :key="'change-target-dates'"
                                type="button"
                                class="btn btn-secondary btn-block mb-2"
                                data-toggle="modal"
                                data-target="#proof-change-target-dates-modal"
                                @click="leaderAction('change-target-dates')"
                                :disabled="leader_action"
                            >
                                <i class="far fa-calendar-days mr-1"></i> Update target dates
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['proof', 'leader_action', 'info_only', 'downloading_linked_asset'],
        data () {
            return {
                uploading_linked_file: false
            }
        },
        mounted () {
            //
        },
        watch: {
            uploading_linked_file: function (val) {
                if (val) {
                    $('#la-linked-file-upload').show();

                    return null;
                }
                $('#la-linked-file-upload').hide();
            },
            leader_action: function (val, oldVal) {
                if (oldVal == 'upload-linked-file') {
                    this.uploading_linked_file = false;
                    $('#proof-leader-actions-modal').modal('hide');
                }
            }
        },
        methods: {
            leaderAction: function (action) {
                if (action == 'upload-linked-file') {
                    const component = this.$root.findComponent(this, 'proof-linked-file-upload');
                    if (component) {
                        component.reset();
                        this.uploading_linked_file = true;
                    }

                    return null;
                }
                this.uploading_linked_file = false;
                $('#proof-leader-actions-modal').modal('hide');
                switch (action) {
                    case 'email-proof':
                        this.$parent.emailProof();
                        break;
                    case 'change-folder':
                        this.$parent.changeFolder();
                        break;
                    case 'delete-proof':
                        this.$parent.deleteProof();
                        break;
                    case 'link-asset':
                        this.$parent.chooseAsset();
                        break;
                    case 'change-leader-alerts':
                        this.$parent.changeLeaderAlerts();
                        break;
                    case 'change-review-alerts':
                        this.$parent.changeReviewAlerts();
                        break;
                    case 'change-target-dates':
                        this.$parent.changeTargetDates();
                        break;
                }
            },
            onLinkedFileUploaded: function (event) {
                this.$parent.linkAsset(null, event);
                this.$parent.leader_action = 'upload-linked-file';
            }
        }
    }
</script>
