<template>
    <div class="topnav page-topnav" style="padding: 0 12px; max-height: none; overflow-y: visible">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand topnav-menu">
                <div class="navbar-collapse align-items-center justify-content-between">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <h6>{{ pageTitle() }}</h6>
                        </li>
                    </ul>
                    <ul class="navbar-nav">
                        <li v-if="(hasActions() == 1)" class="nav-item" style="height: 36.5px">
                            <div style="position: absolute; height: 36.5px; right: 0">
                                <button
                                    v-if="($route.name == 'price-panel-types')"
                                    type="button"
                                    class="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#price-panel-type-add-form-modal"
                                    @click.prevent="resetPricePanelTypeAddForm()"
                                >
                                    <i class="far fa-plus mr-1"></i> Add price panel type
                                </button>
                                <button
                                    v-if="($route.name == 'print-order-suppliers')"
                                    type="button"
                                    class="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#user-add-form-modal"
                                    @click.prevent="resetPrintOrderSupplierAddForm()"
                                ><!--print-order-supplier-add-form-modal-->
                                    <i class="far fa-user-plus mr-1"></i> Add supplier
                                </button>
                            </div>
                        </li>
                        <li v-if="(hasActions() > 1)" class="nav-item" style="width: 100px; height: 36.5px">
                            <div class="btn-group" style="position: absolute; height: 36.5px; right: 0">
                                <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    Actions
                                    <i
                                        class="fas fa-chevron-down"
                                        style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                    ></i>
                                </button>
                                <!--<div
                                    v-if="($route.name == 'print-order-suppliers')"
                                    class="dropdown-menu dropdown-menu-right"
                                >
                                    <a
                                        class="dropdown-item"
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#print-order-supplier-add-form-modal"
                                        @click.prevent="resetPrintOrderSupplierAddForm()"
                                    >
                                        <i class="far fa-user-plus mr-1"></i> Add supplier
                                    </a>
                                </div>-->
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                //
            }
        },
        mounted () {
            //
        },
        methods: {
            pageTitle: function () {
                if (this.$route.name == 'price-panel-types') {
                    return 'Price panels';
                }
                if (this.$route.name == 'print-order-suppliers') {
                    return 'Suppliers';
                }

                return 'Orders';
            },
            hasActions: function () {
                if (this.$route.name == 'price-panel-types') {
                    return 1;
                }
                if ((this.$route.name == 'print-order-suppliers') && this.$root.hasPermission('users_invite')) {
                    return 1;
                }

                return 0;
            },
            resetPricePanelTypeAddForm: function () {
                const pageComponent = this.$root.findComponent(this.$parent.$parent, 'price-panel-types');
                if (pageComponent) {
                    const component = this.$root.findComponent(pageComponent, 'price-panel-type-add-form');
                    if (component) {
                        component.resetForm();
                    }
                }
            },
            resetPrintOrderSupplierAddForm: function () {
                const component = this.$root.findComponent(this.$parent.$parent, 'user-add-form');
                if (component) {
                    component.resetForm('supplier');
                }
                /*const pageComponent = this.$root.findComponent(this.$parent.$parent, 'print-order-suppliers');
                if (pageComponent) {
                    const component = this.$root.findComponent(pageComponent, 'print-order-supplier-add-form');
                    if (component) {
                        component.resetForm();
                    }
                }*/
            }
        }
    }
</script>
