<template>
    <div>
        <form v-on:submit.prevent="onSubmit()">
            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label :for="'pod-code-code' + data.id">POD code</label>
                        <input
                            v-model="pod_code.code"
                            type="text"
                            :id="'pod-code-code' + data.id"
                            class="form-control"
                            :readonly="true"
                        >
                    </div>
                    <div class="form-group">
                        <label
                            :for="'pod-code-title' + data.id"
                            :id="'pod-code-title-label' + data.id"
                            class="mandatory-label"
                        >
                            Title <span v-if="can_edit">*</span>
                        </label>
                        <input
                            v-model="pod_code.title"
                            type="text"
                            :id="'pod-code-title' + data.id"
                            class="form-control mandatory-field"
                            :readonly="(!can_edit || updating)"
                            v-on:keyup="$root.unHighlightErrors('pod-code-title' + data.id)"
                            v-on:change="$root.unHighlightErrors('pod-code-title' + data.id)"
                        >
                    </div>
                    <div class="form-group">
                        <label :for="'pod-code-brand' + data.id">Brand</label>
                        <input
                            v-model="pod_code.brand"
                            type="text"
                            :id="'pod-code-brand' + data.id"
                            class="form-control"
                            :readonly="(!can_edit || updating)"
                        >
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label :for="'pod-code-old_code' + data.id">Old code</label>
                        <input
                            v-model="pod_code.old_code"
                            type="text"
                            :id="'pod-code-old_code' + data.id"
                            class="form-control"
                            :readonly="(!can_edit || updating)"
                        >
                    </div>
                    <div class="form-group">
                        <label :for="'pod-code-live_date' + data.id">
                            Live date
                        </label>
                        <b-form-datepicker
                            v-model="pod_code.live_date"
                            :id="'pod-code-live_date' + data.id"
                            class="form-control"
                            start-weekday="1"
                            locale="en-GB"
                            :hideHeader="true"
                            :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                            :disabled="(!can_edit || updating)"
                        ></b-form-datepicker>
                    </div>
                </div>
            </div>

            <div v-if="(can_edit || can_delete)" class="row">
                <div class="col">
                    <span v-if="can_edit">
                        <span v-if="!updating">
                            <button
                                :key="'save'"
                                type="submit"
                                class="btn btn-primary mr-2"
                                :disabled="deleting"
                            >
                                <i class="far fa-save mr-1"></i> Save
                            </button>
                        </span>
                        <span v-else>
                            <button :key="'save-spinner'" type="button" class="btn btn-primary mr-2">
                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                <span style="opacity: 0.5">Save</span>
                            </button>
                        </span>
                    </span>

                    <span v-if="can_delete">
                        <span v-if="!deleting">
                            <button
                                :key="'delete'"
                                type="button"
                                class="btn btn-secondary"
                                @click.prevent="deletePodCode()"
                                :disabled="updating"
                            >
                                <i class="far fa-trash-alt mr-1"></i> Delete
                            </button>
                        </span>
                        <span v-else>
                            <button :key="'delete-spinner'" type="button" class="btn btn-secondary">
                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                <span style="opacity: 0.5">Delete</span>
                            </button>
                        </span>
                    </span>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data () {
            return {
                errors: false,
                updating: false,
                pod_code: {
                    id: null,
                    code: null,
                    title: null,
                    brand: null,
                    old_code: null,
                    live_date: null
                },
                can_edit: true,
                can_delete: true,
                deleting: false
            }
        },
        mounted () {
            if (this.data) {
                this.updateOriginalData();
            }
        },
        watch: {
            data: function (val) {
                this.updateOriginalData();
            }
        },
        methods: {
            onSubmit: function () {
                if (!this.can_edit) {
                    return null;
                }
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.errors = false;
                this.updating = true;
                /**
                 * Send update asset type request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/pod-code/' + this.data.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: vm.pod_code,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // show confirmation message
                    vm.$parent.$parent.message = {
                        type: 'success',
                        title: 'Update POD code',
                        text: 'POD code updated',
                    };
                    $('#message-modal').modal('show');
                    vm.$parent.getPodCodes();

                    // close after 2 seconds automatically
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = 'Something went wrong, please try again';
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#pod-code-' + key + '-label' + vm.data.id).addClass('text-danger');
                                $('#pod-code-' + key + vm.data.id).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            updateOriginalData: function () {
                for (const [key, value] of Object.entries(this.data)) {
                    this.pod_code[key] = value;
                }
                this.pod_code.code = 'POD ' + this.data.id;

                this.can_edit = this.$root.hasPermission('podcodes_edit');
                this.can_delete = this.$root.hasPermission('podcodes_delete');
            },
            deletePodCode: function (confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.deleting = true;

                    /**
                     * Send delete pod code request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/pod-code/' + this.data.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.$parent.getPodCodes();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    })
                    .finally(() => {
                        vm.deleting = false;
                    });

                    return null;
                }

                if (this.updating) {
                    return null;
                }

                this.$parent.$parent.confirm_delete = {
                    title: 'Delete POD code',
                    text: 'Are you sure you want to delete: ' + this.pod_code.code + '?'
                        + '<br><br>This action cannot be undone.',
                    component: this,
                    action: 'delete-pod-code'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deletePodCode(true);
            }
        }
    }
</script>
