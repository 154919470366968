<template>
    <div class="account-pages mx-md-5 my-5 pt-sm-5">
        <div class="container-fluid">
            <div :class="$root.loginPanelClass()">
                <div class="col-sm-6 col-lg-4 col-xl-3 login-panel-col">
                    <div class="card overflow-hidden">
                        <div :class="$root.app.login_panel_header_class + ' px-4 py-2 text-center'">
                            <img
                                v-if="$root.app.login_logo"
                                :src="$root.app.login_logo"
                                :alt="$root.client.client_name"
                                class="img-fluid"
                            >
                        </div>
                        <div class="card-body pt-2 pb-1">
                            <div class="p-2">
                                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                                <div v-if="loading"><loading></loading></div>

                                <div v-if="updated" class="alert alert-success" role="alert">
                                    <div class="row no-gutters">
                                        <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                                        <div class="col" v-html="updated"></div>
                                    </div>
                                </div>

                                <div v-if="(!updated && (user_id > 0))">
                                    <p>Please check your details and set your password.</p>
                                    <form v-on:submit.prevent="onSubmit()">
                                        <div class="form-group">
                                            <label for="first_name" id="name-label" class="mandatory-label">Your name *</label>
                                            <div class="input-group">
                                                <input
                                                    v-model="first_name"
                                                    type="text"
                                                    id="first_name"
                                                    class="form-control mandatory-field"
                                                    :readonly="updating"
                                                    :placeholder="placeholder('first_name')"
                                                    v-on:focus="placeholder('first_name', true)"
                                                    v-on:blur="placeholder('first_name', true)"
                                                    v-on:keyup="unHighlightNameErrors()"
                                                    v-on:change="unHighlightNameErrors()"
                                                >
                                                <input
                                                    v-model="last_name"
                                                    type="text"
                                                    id="last_name"
                                                    class="form-control mandatory-field"
                                                    :readonly="updating"
                                                    :placeholder="placeholder('last_name')"
                                                    v-on:focus="placeholder('last_name', true)"
                                                    v-on:blur="placeholder('first_name', true)"
                                                    v-on:keyup="unHighlightNameErrors()"
                                                    v-on:change="unHighlightNameErrors()"
                                                >
                                            </div>
                                        </div>
                                        <div v-if="!proof_id">
                                            <div class="form-group">
                                                <label for="job_title" id="job_title-label" class="mandatory-label">
                                                    Job title *
                                                </label>
                                                <input
                                                    v-model="job_title"
                                                    type="text"
                                                    id="job_title"
                                                    class="form-control mandatory-field"
                                                    :readonly="updating"
                                                    v-on:keyup="$root.unHighlightErrors('job_title')"
                                                    v-on:change="$root.unHighlightErrors('job_title')"
                                                >
                                            </div>
                                            <div class="form-group">
                                                <label for="company" id="company-label" class="mandatory-label">
                                                    {{ $root.ucfirst(company_title) }} *
                                                </label>
                                                <input
                                                    v-model="company"
                                                    type="text"
                                                    id="company"
                                                    class="form-control mandatory-field"
                                                    :readonly="updating"
                                                    v-on:keyup="$root.unHighlightErrors('company')"
                                                    v-on:change="$root.unHighlightErrors('company')"
                                                >
                                            </div>
                                            <div class="form-group">
                                                <label for="telephone" id="telephone-label" class="mandatory-label">
                                                    Telephone *
                                                </label>
                                                <input
                                                    v-model="telephone"
                                                    type="text"
                                                    id="telephone"
                                                    class="form-control mandatory-field"
                                                    :readonly="updating"
                                                    v-on:keyup="$root.unHighlightErrors('telephone')"
                                                    v-on:change="$root.unHighlightErrors('telephone')"
                                                >
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="email">
                                                Email
                                            </label>
                                            <input
                                                v-model="email"
                                                type="email"
                                                class="form-control"
                                                :readonly="true"
                                            >
                                        </div>
                                        <div class="form-group">
                                            <label
                                                for="password"
                                                id="password-label"
                                                class="mandatory-label"
                                            >
                                                Choose your password *
                                            </label>
                                            <input
                                                v-model="password"
                                                type="password"
                                                id="password"
                                                class="form-control mandatory-field"
                                                :readonly="updating"
                                                v-on:keyup="$root.unHighlightErrors('password')"
                                                v-on:change="$root.unHighlightErrors('password')"
                                                autocomplete="off"
                                            >
                                            <password-strength
                                                :password="password"
                                                v-model="password_score"
                                            ></password-strength>
                                        </div>
                                        <div class="form-group">
                                            <label
                                                for="confirm_password"
                                                id="confirm_password-label"
                                                class="mandatory-label"
                                            >
                                                Confirm password
                                            </label>
                                            <input
                                                v-model="confirm_password"
                                                type="password"
                                                id="confirm_password"
                                                class="form-control mandatory-field"
                                                :readonly="(updating || (password_score < 7))"
                                                v-on:keyup="$root.unHighlightErrors('confirm_password')"
                                                v-on:change="$root.unHighlightErrors('confirm_password')"
                                                autocomplete="off"
                                            >
                                        </div>

                                        <div class="form-group">
                                            <span v-if="!updating">
                                                <span>
                                                    <button
                                                        type="submit"
                                                        class="btn btn-primary"
                                                        :disabled="(!password || !confirm_password || (password_score < 7))"
                                                        style="width: 100%"
                                                    >
                                                        <i class="far fa-save mr-1"></i> Save
                                                    </button>
                                                </span>
                                            </span>
                                            <span v-else>
                                                <button type="button" class="btn btn-primary" style="width: 100%">
                                                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                    <span style="opacity: 0.5">Save</span>
                                                </button>
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div
                                    v-if="(error_code == 'new-invite-link') || (error_code == 'new-reset-link')"
                                    class="form-group text-center"
                                >
                                    <router-link
                                        :to="{ name: 'get-' + error_code, params: { code: code } }"
                                        custom
                                        v-slot="{ navigate }"
                                    >
                                        <button class="btn btn-primary btn-block" @click="navigate">
                                            <i class="far fa-link mr-1"></i> Get a new link
                                        </button>
                                    </router-link>
                                </div>

                                <div
                                    v-if="(!loading && ((error_code == 'invalid') || (error_code == 'expired')
                                        || (error_code == 'new-invite-link') || (error_code == 'new-reset-link')))"
                                    class="form-group text-center"
                                >
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#email-support-modal"
                                        @click.prevent="resetSupportForm()"
                                    >
                                        <i class="far fa-envelope mr-1"></i> Email support
                                    </a>
                                </div>

                                <div v-if="(!loading && errors)" class="form-group text-center">
                                    <router-link :to="{ name: 'login' }">
                                        <i class="far fa-sign-in-alt mr-1"></i> Click here to login
                                    </router-link>
                                </div>

                                <div class="text-center" style="font-size: 0.7em; position: relative; height: 10px">
                                    Powered by <a href="https://resourcebase.com" target="_blank">Resourcebase</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: true,
                errors: false,
                error_code: null,
                code: null,
                user_id: 0,
                first_name: null,
                last_name: null,
                job_title: null,
                company: null,
                telephone: null,
                email: null,
                password: '',
                confirm_password: '',
                password_score: 0,
                updating: false,
                updated: false,
                get_new_link: false,
                proof_id: null,
                get_content_response: null,
                company_title: 'company'
            }
        },
        mounted () {
            $('body').addClass('login-body');
            this.$root.getContent(this, 'company_title');
            this.validateCode();
        },
        watch: {
            get_content_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.key == 'company_title') {
                            this.company_title = val.content;
                        }
                    }
                }
            }
        },
        methods: {
            validateCode: function () {
                this.loading = true;
                this.errors = false;
                this.error_code = null;
                this.code = this.$route.params.code;
                this.proof_id = this.$route.params.proof_id || null;
                this.get_new_link =
                    ((this.$route.name == 'get-new-invite-link') || (this.$route.name == 'get-new-reset-link'));

                /**
                 * Send request to API.
                 */
                let data = {
                    code: this.code
                };
                if (this.get_new_link) {
                    data.get_new_link = true;
                    data.app_info = {
                        app_url: this.$root.app.app_url
                    };
                }
                var vm = this;
                const path = '/' + this.$root.app.client_id + '/user/validate-invite-code';
                const url = this.$root.app.api_url + path;

                axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(response => {
                    if (vm.get_new_link) {
                        vm.updated = response.data.message;
                        if (response.data.expires) {
                            vm.updated += '<br><br>This link will expire ' + response.data.expires + '.';
                        }

                        return null;
                    }
                    vm.user_id = response.data.user.id;
                    vm.first_name = response.data.user.first_name;
                    vm.last_name = response.data.user.last_name;
                    vm.job_title = response.data.user.job_title;
                    vm.company = response.data.user.company;
                    vm.telephone = response.data.user.telephone;
                    vm.email = response.data.user.email;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    if (error.response.data.error_code) {
                        vm.error_code = error.response.data.error_code;
                        if ((vm.error_code == 'verified') || (vm.error_code == 'reset')) {
                            if (vm.$root.isLoggedIn()) {
                                if (error.response.data.user_id == vm.$root.user.id) {
                                    vm.$router.push({ name: 'home' });
                                }
                            }

                            return null;
                        }
                        if ((vm.error_code == 'new-invite-link') || (vm.error_code == 'new-reset-link')) {
                            vm.errors = error.response.data.error;
                            vm.code = error.response.data.password_reset_code;

                            return null;
                        }
                        vm.errors = (error.response.data.error_code == 'expired')
                            ? 'Sorry, this link has already expired.' : 'Sorry, this link is invalid.';

                        return null;
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() =>
                    vm.loading = false
                );
            },
            resetSupportForm: function () {
                this.$root.token = null;
                this.$root.user = null;
                const component = this.$root.findComponent(this.$parent, 'email-support');
                if (component) {
                    let message = '';
                    if (this.error_code == 'new-invite-link') {
                        message = 'My invite link does not work. Error says: ' + this.errors;
                    } else if (this.error_code == 'new-reset-link') {
                        message = 'My password reset link does not work. Error says: ' + this.errors;
                    } else {
                        message = (this.error_code == 'expired')
                            ? 'My invite or password reset link has expired, please send a new one'
                            : 'My invite or password reset link does not work, please send a new one';
                    }
                    component.resetForm('access', message);
                }
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'first_name':
                        text = 'First';
                        break;
                    case 'last_name':
                        text = 'Last';
                        break;
                }
                if (update) {
                    $('#' + field).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            unHighlightNameErrors: function () {
                if (this.first_name) {
                    $('#first_name').removeClass('field-error');
                }
                if (this.last_name) {
                    $('#last_name').removeClass('field-error');
                }
                if (this.first_name && this.last_name) {
                    $('#name-label').removeClass('text-danger');
                }
            },
            onSubmit: function () {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.updating = true;
                this.errors = false;

                /**
                 * Send request to API.
                 */
                let data = {
                    code: this.code,
                    user_id: this.user_id,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    new_password: this.password,
                    confirm_new_password: this.confirm_password,
                };

                if (this.proof_id) {
                    data.proof_id = this.proof_id;
                } else {
                    data.job_title = this.job_title;
                    data.company = this.company;
                    data.telephone = this.telephone;
                }

                const path = '/' + this.$root.app.client_id + '/user/set-password';
                const url = this.$root.app.api_url + path;
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(response => {
                    vm.updated = response.data.message;
                    vm.$root.login_email = vm.email;
                    vm.$root.login_password = vm.password;
                    if (vm.proof_id) {
                        vm.$root.requested_route = {
                            name: 'view-proof',
                            params: {
                                id: vm.proof_id
                            }
                        };
                    } else {
                        vm.$root.requested_route = null;
                    }

                    setTimeout(function () {
                        vm.$router.push({ name: 'login' });
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>').replace(/company/g, vm.company_title) + '<br>';
                                    if ((key == 'first_name') || (key == 'last_name')) {
                                        $('#name-label').addClass('text-danger');
                                    } else {
                                        $('#' + key + '-label').addClass('text-danger');
                                    }
                                    $('#' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            }
        }
    }
</script>
