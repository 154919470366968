<template>
    <div>
        <form v-on:submit.prevent="onSubmit()">
            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div class="row">
                <div class="col-lg-8">
                    <div class="form-group">
                        <label>Alerts</label>
                        <div>
                            <span v-for="option in alert_options" :key="option.key">
                                <button
                                    v-if="alert[option.key]"
                                    class="btn btn-info btn-sm mr-2 mb-2"
                                    @click="selectOption(option.key, false)"
                                    :disabled="updating"
                                >
                                    <i class="far fa-check-square mr-1"></i> {{ option.name }}
                                </button>
                                <span v-else>
                                    <button
                                        class="btn btn-light btn-sm mr-2 mb-2"
                                        @click="selectOption(option.key)"
                                        :disabled="updating"
                                    >
                                        <i class="far fa-square mr-1"></i> {{ option.name }}
                                    </button>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label :for="'folders' + alert.id">Assigned to {{ $root.contents.folders_title }}</label>
                        <searchable-option-list
                            v-model="alert.folders"
                            :value="(alert.folders || [])"
                            :multiple="true"
                            :options="getFolderOptions()"
                            :field_id="'folders' + alert.id"
                            :readonly="updating"
                        ></searchable-option-list>
                    </div>
                </div>
            </div>

            <span v-if="!updating">
                <button
                    type="submit"
                    class="btn btn-primary mr-2"
                    :disabled="updating"
                >
                    <i class="far fa-save mr-1"></i> Save
                </button>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary mr-2">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Save</span>
                </button>
            </span>
            <button
                type="button"
                class="btn btn-secondary"
                :disabled="updating"
                @click.prevent="deleteAlert()"
            >
                <i class="far fa-user-minus mr-1"></i> Remove administrator
            </button>
        </form>
    </div>
</template>

<script>
    export default {
        props: ['data', 'alert_options', 'folders'],
        data () {
            return {
                errors: false,
                updating: false,
                alert: {},
            }
        },
        mounted () {
            this.updateData(this.data);
        },
        watch: {
            data: function (val) {
                this.updateData(val);
            }
        },
        methods: {
            updateData: function(data) {
                let newData = {};
                if (data) {
                    for (const [key, value] of Object.entries(data)) {
                        newData[key] = value;
                    }
                    if (newData.folders.length == 0) {
                        newData.folders = [-1];
                    }
                } else {
                    newData.folders = [-1];
                }
                this.alert = newData;
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                /**
                 * Send update request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/administrator-alert/' + this.alert.id;
                var vm = this;

                let selectedFolders = [];
                if (this.alert.folders) {
                    this.alert.folders.forEach((item) => {
                        if (item > 0) {
                            selectedFolders.push(item);
                        }
                    });
                }

                const alertOptions = [
                    'asset_approval',
                    'registration',
                    'deletion_request',
                    'template_approval',
                    'support',
                    'asset_review',
                    'reports',
                    'asset_upload',
                    'template_upload',
                    'weekly_artwork_created',
                    'monthly_orders'
                ];

                let data = {};
                alertOptions.forEach((item) => {
                    data[item] = this.alert[item];
                });
                data.folders = selectedFolders;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.$parent.getAlerts();
                    // show confirmation message
                    vm.$parent.$parent.message = {
                        type: 'success',
                        title: 'Update administrator alerts',
                        text: vm.alert.first_name + ' '+ vm.alert.last_name + "'s administrator alerts updated"
                    };
                    $('#message-modal').modal('show');

                    // close after 2 seconds automatically
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            deleteAlert: function (confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.$parent.items = [];
                    this.$parent.loading = true;

                    /**
                     * Send delete request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                        + '/administrator-alert/' + this.alert.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        //
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    })
                    .finally(() => {
                        vm.$parent.getAlerts();
                    });

                    return null;
                }

                if (this.updating) {
                    return null;
                }

                this.$parent.$parent.confirm_delete = {
                    title: 'Remove administrator',
                    text: 'Are you sure you want to remove: ' + this.alert.first_name + ' ' + this.alert.last_name
                        + '?<br><br>The user will no longer receive notifications from this system.',
                    component: this,
                    action: 'delete-alert'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deleteAlert(true);
            },
            selectOption: function (optionKey, select = true) {
                this.alert[optionKey] = select;
            },
            getFolderOptions: function () {
                let options = [{
                    id: -1,
                    name: 'All ' + this.$root.contents.folders_title
                }];
                if (this.folders && this.alert.folders) {
                    this.folders.forEach((item) => {
                        if (!item.archived || (this.alert.folders.indexOf(item.id) > -1)) {
                            let name = item.name;
                            if (item.archived) {
                                name += ' (archived)';
                            }
                            options.push({
                                id: item.id,
                                name: name
                            });
                        }
                    });
                }

                return options;
            }
        }
    }
</script>
