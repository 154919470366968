<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <loading v-if="loading"></loading>

        <div v-else>
            <div
                v-if="(!downloads_view && !download_only && asset_id && !previous_version
                    && $root.hasPermission('assets_manage_attachments'))"
                class="form-group"
            >
                <button
                    type="button"
                    class="btn btn-primary"
                    data-toggle="modal"
                    data-target="#asset-linked-file-add-form-modal"
                    @click.prevent="resetLinkedFileAddForm()"
                >
                    <i class="far fa-paperclip mr-1"></i> Add attachment
                </button>
            </div>

            <div v-if="(items.length > 0)">
                <div v-if="approval_info" class="alert alert-info" v-html="approval_info"></div>

                <div v-if="!downloads_view">
                    <div v-for="file in items" :key="file.id" class="dz-uploaded-file p-2 mb-1">
                        <div class="dz-thumbnail">
                            <span v-if="!hasPreview(file)">
                                <img
                                    :src="file.image_src"
                                    class="img-fluid"
                                    alt=""
                                >
                            </span>
                            <img
                                v-else
                                :src="file.image_src"
                                class="img-fluid"
                                alt=""
                                v-on:mouseover="viewImageIcon(file.id, true)"
                                v-on:mouseout="viewImageIcon(file.id, false)"
                            >
                            <div
                                v-if="file.image_src"
                                class="view-image-icon"
                                :id="'view-linked-image-icon' + file.id"
                                v-b-tooltip.hover
                                title="Enlarge"
                                @click="viewImage(file)"
                                v-on:mouseover="viewImageIcon(file.id, true)"
                                v-on:mouseout="viewImageIcon(file.id, false)"
                            >
                                <i class="fal fa-expand-arrows"></i>
                            </div>
                        </div>
                        <div class="dz-filename">
                            <span
                                v-if="(canEdit() && (editing_linked_file != file))"
                                v-b-tooltip.hover
                                title="Rename"
                                style="cursor: text"
                                @click="toggleEditLinkedFile(file)"
                            >{{ file.title }}</span>

                            <form v-if="(editing_linked_file == file)" v-on:submit.prevent="updateLinkedFile(file)">
                                <div class="input-group">
                                    <input
                                        v-model="new_title"
                                        type="text"
                                        class="form-control mandatory-field"
                                        :id="'new-title' + file.id"
                                        :readonly="updating_linked_file"
                                        v-on:keyup="$root.unHighlightErrors('new-title', file.id)"
                                        v-on:change="$root.unHighlightErrors('new-title', file.id)"
                                        style="height: 37px"
                                    >
                                    <div class="input-group-append">
                                        <button
                                            v-if="!updating_linked_file"
                                            class="btn btn-primary big-icon"
                                            type="submit"
                                            v-b-tooltip.hover
                                            title="Save"
                                        >
                                            <strong><i class="fal fa-save"></i></strong>
                                        </button>
                                        <button
                                            v-else
                                            type="button"
                                            class="btn btn-primary"
                                        >
                                            <span>
                                                <i class="fal fa-spinner-third fa-spin big-icon"></i>
                                            </span>
                                        </button>
                                        <button
                                            class="btn btn-light big-icon"
                                            type="button"
                                            v-b-tooltip.hover
                                            title="Cancel"
                                            :disabled="updating_linked_file"
                                            @click="toggleEditLinkedFile()"
                                        >
                                            <i class="fal fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>

                            <span v-if="!canEdit()">{{ file.title }}</span>
                        </div>
                        <div class="dz-size">
                            <span
                                class="badge badge-primary font-size-11 mr-2"
                                style="text-transform: uppercase; position: relative; top: -2px"
                            >
                                {{ file.file_extension }}
                            </span>
                            {{ $root.formatFileSize(file.file_size) }}
                        </div>
                        <div class="dz-remove">
                            <a
                                v-if="(approval_status != 'awaiting-approval')
                                    && ($root.hasPermission('assets_download_attachments')
                                    && (downloading != file.file_path))"
                                href="#"
                                @click.prevent="downloadLinkedFile(file)"
                            >
                                <span v-b-tooltip.hover title="Download">
                                    <i class="fal fa-download"></i>
                                </span>
                            </a>
                            <span v-if="(approval_status == 'awaiting-approval')">
                                <i class="fal fa-download"></i>
                            </span>
                            <span v-if="(downloading == file.file_path)">
                                <a
                                    href="#"
                                    @click.prevent="doNothing(item)"
                                >
                                    <i class="fal fa-spinner-third fa-spin"></i>
                                </a>
                            </span>
                            <span v-if="canEdit()" class="ml-2">
                                <a
                                    href="#"
                                    v-b-tooltip.hover
                                    title="Delete"
                                    @click.prevent="deleteLinkedFile(file)"
                                >
                                    <i class="fal fa-trash-alt"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div v-for="file in items" :key="file.id" class="form-control p-0 mb-3" style="height: auto">
                        <div class="row no-gutters">
                            <div class="col p-2 download-option-title">
                                {{ file.title }}
                            </div>
                        </div>
                        <div class="row no-gutters" style="min-height: 36px">
                            <div class="col-3 pt-2 pb-2 pl-2" style="text-transform: uppercase">
                                {{ file.file_extension }}
                            </div>
                            <div class="col-sm-3 col-8 pt-2 pb-2">
                                {{ $root.formatFileSize(file.file_size) }}
                            </div>
                            <div v-if="hasPreview(file)" class="col-6 pt-0 pb-0 pl-0 pt-sm-2 pb-sm-2 pl-sm-2 d-sm-none">
                                <button
                                    v-if="hasPreview(file)"
                                    class="btn btn-secondary download-option-button"
                                    type="button"
                                    v-b-tooltip.hover.left
                                    title="View"
                                    @click="viewImage(file)"
                                    style="width: calc(100% - 1px)"
                                >
                                    <i class="fal fa-eye"></i>
                                </button>
                            </div>
                            <div :class="'col-sm-6' + (hasPreview(file) ? ' col-6' : ' col')">
                                <button
                                    v-if="hasPreview(file)"
                                    class="btn btn-secondary download-option-button d-none d-sm-block"
                                    type="button"
                                    v-b-tooltip.hover.left
                                    title="View"
                                    @click="viewImage(file)"
                                    :style="$root.hasPermission('assets_download_attachments') ? 'right: 38px' : ''"
                                >
                                    <i class="fal fa-eye"></i>
                                </button>
                                <span v-if="$root.hasPermission('assets_download_attachments')">
                                    <button
                                        v-if="(downloading != file.file_path)"
                                        class="btn btn-primary download-option-button"
                                        type="button"
                                        v-b-tooltip.hover.left
                                        title="Download"
                                        :disabled="(downloading || (approval_status == 'awaiting-approval'))"
                                        @click="downloadLinkedFile(file)"
                                    >
                                        <i class="fal fa-download"></i>
                                    </button>
                                    <span v-else>
                                        <button
                                            class="btn btn-primary download-option-button"
                                            type="button"
                                        >
                                            <i class="fal fa-spinner-third fa-spin"></i>
                                        </button>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'data',
            'asset_id',
            'downloads_view',
            'approval_info',
            'approval_status', 
            'from_asset_preview',
            'previous_version',
            'download_only'
        ],
        data () {
            return {
                errors: false,
                item_to_delete: null,
                loading: true,
                downloading: null,
                items: [],
                editing_linked_file: null,
                new_title: null,
                updating_linked_file: false
            }
        },
        mounted () {
            this.items = this.data;
            this.loading = false;
            if (this.data && this.asset_id) {
                this.getPreviewImages(this.data);
            }
        },
        watch: {
            data: function (val) {
                this.items = val;
                this.loading = false;
                if (this.asset_id) {
                    this.getPreviewImages(val);
                }
            },
            asset_id: function (val) {
                if (this.data) {
                    this.getPreviewImages(val);
                }
            }
        },
        methods: {
            resetLinkedFileAddForm: function () {
                const component = this.$root.findComponent(this.$parent, 'asset-linked-file-add-form');
                if (component) {
                    component.resetForm(this.asset_id);
                }
            },
            hasPreview: function (file) {
                return (file.image_src.indexOf('data:') == 0);
            },
            viewImage: function (file) {
                this.viewImageIcon(file.id, false);

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: null,
                    title: file.title,
                    text: '<div class="text-center"><img src="' + file.image_src
                        + '" alt="" class="img-fluid" style="max-height: 500px"></div>'
                };
                $('#message-modal > div').addClass('modal-lg');
                $('#message-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            viewImageIcon: function (fileId, show) {
                if (show) {
                    $('#view-linked-image-icon' + fileId).show();
                } else {
                    $('#view-linked-image-icon' + fileId).hide();
                }
            },
            doNothing: function () {
                return null;
            },
            downloadLinkedFile: function (item) {
                if (this.downloading) {
                    return null;
                }

                if (this.approval_status == 'requires-approval') {
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    const component = this.$root.findComponent(appComponent, 'asset-approval-request-form');
                    if (component) {
                        component.resetForm(this.$parent.asset, this.from_asset_preview ? null : this.$parent);
                        $('#asset-previous-version-linked-files-modal').modal('hide');
                        $('#asset-download-modal').modal('hide');
                        setTimeout(function() {
                            $('#asset-approval-request-form-modal').modal('show');
                        }, 900);
                    }
                    
                    return null;
                }

                this.downloading = item.file_path;

                /**
                 * Send request to API
                 */
                const data = {
                    item_type: 'resource_linked_file',
                    item_id: item.id,
                    file_path: item.file_path,
                    file_type: item.file_type,
                    download_filename: item.download_filename + '.' + item.file_extension
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/' + response.data.token;
                    window.location.href = url;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                })
                .finally(() =>
                    vm.downloading = null
                );
            },
            deleteLinkedFile: function (item, confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.loading = true;

                    /**
                     * Send delete linked file request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                        + '/resource/' + item.asset_id + '/linked-file/' + item.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        // Reload linked files
                        vm.$parent.getLinkedFiles();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        vm.errors = "Error deleting attachment, please try again";
                    });

                    return null;
                }

                this.item_to_delete = item;
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Delete attachment',
                    text: 'Are you sure you want to delete: ' + item.title + '?',
                    component: this,
                    action: 'delete-linked-file'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deleteLinkedFile(this.item_to_delete, true);
            },
            toggleEditLinkedFile: function (item = null) {
                this.editing_linked_file = item;
                if (item) {
                    this.new_title = item.title;
                    setTimeout(function () {
                        $('#new-title' + item.id).focus();
                    }, 200);
                }
            },
            updateLinkedFile: function (file) {
                $('.mandatory-field').removeClass('field-error');
                this.updating_linked_file = file;
                this.errors = false;
                /**
                 * Send update linked file request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + file.asset_id + '/linked-file/' + file.id;
                const data = {
                    title: this.new_title
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.items.forEach((item) => {
                        if (item.id == vm.updating_linked_file.id) {
                            vm.updating_linked_file.title = vm.new_title;
                            item = vm.updating_linked_file;
                        }
                    });
                    vm.toggleEditLinkedFile();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = 'Something went wrong, please try again';
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#new-' + key + vm.updating_linked_file.id).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating_linked_file = null;
                });
            },
            canEdit: function () {
                return (
                    this.asset_id && !this.previous_version && this.$root.hasPermission('assets_manage_attachments')
                );
            },
            getPreviewImages: function (items) {
                items.forEach((item) => {
                    if (!this.hasPreview(item)) {
                        this.getPreviewImage(item.id);
                    }
                });
            },
            getPreviewImage: function (linkedFileId) {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/'
                    + this.asset_id + '/linked-file/' + linkedFileId + '/preview';
                var vm = this;

                axios({
                    url: url,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.items.forEach((item) => {
                            if (item.id == response.data.linked_file_id) {
                                item.image_src = response.data.image_src;
                            }
                        });
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            }
        }
    }
</script>
<style scoped>
    .dz-uploaded-file {
        cursor: default;
    }

    .dz-uploaded-file .dz-filename {
        width: calc(100% - 155px);
    }

    .dz-uploaded-file .dz-size {
        bottom: 10px;
        left: 158px;
    }

    .dz-uploaded-file .dz-remove {
        bottom: 8px;
    }
</style>
