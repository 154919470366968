<template>
    <div :id="field_id" :class="field_class" :style="fieldStyle()">
        <div v-for="item in items" :key="item.id">
            <div
                :class="optionClass(item)"
                @click="onOptionClick(item)"
            >
                <input
                    v-if="multiselect"
                    v-model="item.selected"
                    type="checkbox"
                    class="custom-control-input"
                    :value="item.id"
                    :disabled="item.disabled"
                >
                <input
                    v-if="!multiselect"
                    v-model="singleselect_value"
                    type="radio"
                    :id="field_id + '-item-' + item.id"
                    class="custom-control-input"
                    :value="item.id"
                    :disabled="item.disabled"
                >
                <label class="custom-control-label" :for="field_id + '-item-' + item.id" v-html="item.name">
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['value', 'multiple', 'options', 'field_id', 'field_class', 'max_height'],
        data() {
            return {
                multiselect: false,
                items: [],
                singleselect_value: null,
                maximum_height: 200
            };
        },
        mounted () {
            this.multiselect = (this.multiple || false);
            this.maximum_height = this.max_height ? parseInt(this.max_height) : 200;
            this.resetItems(this.options, this.value);
        },
        watch: {
            options: function (val) {
                this.resetItems(val, this.value);
            },
            value: function (val) {
                this.resetItems(this.options, val);
            }
        },
        methods: {
            resetItems: function (options, selected) {
                this.items = [];
                if (options) {
                    options.forEach((item, index) => {
                        this.items.push({
                            id: item.id,
                            name: item.name,
                            disabled: item.disabled,
                            selected: this.multiselect ? (selected && (selected.indexOf(item.id) > -1))
                                : (item.id == selected)
                        });
                        if (!this.multiselect && (item.id == selected)) {
                            this.singleselect_value = selected;
                        }
                    });
                }
            },
            optionClass: function (item) {
                const className = (this.multiselect ? 'custom-checkbox' : 'custom-radio' )
                    + ' custom-control option';

                if (item.disabled) {
                    return className + ' disabled';
                }

                if (item.selected) {
                    return className + ' active';
                }

                return className;
            },
            onOptionClick: function (itemClicked) {
                if (itemClicked.disabled) {
                    return null;
                }
                if (this.multiselect) {
                    itemClicked.selected = !itemClicked.selected;
                } else {
                    this.singleselect_value = itemClicked.id;
                }
                this.$emit('input', this.getItems());
                this.$emit('change');
            },
            removeItem: function (itemToRemove) {
                itemToRemove.selected = false;
                if (!this.multiselect) {
                    this.singleselect_value = null;
                }
                this.$emit('input', this.getItems());
                this.$emit('change');
            },
            getItems: function () {
                if (this.multiselect) {
                    let selectedItems = [];
                    this.items.forEach((item, index) => {
                        if (item.selected) {
                            selectedItems.push(item.id);
                        }
                    });

                    return selectedItems;
                }

                return this.singleselect_value;
            },
            selectedItems: function () {
                let itemsSelected = [];
                this.items.forEach((item, index) => {
                    if (item.selected) {
                        itemsSelected.push(item);
                    }
                });

                return (itemsSelected.length > 0) ? itemsSelected : null;
            },
            fieldStyle: function () {
                return 'height: auto; overflow-y: auto; max-height: ' + this.maximum_height + 'px';
            }
        }
    }
</script>
<style scoped>
    .option {
        padding: 0.35rem 1.5rem 0.35rem 2.3rem;
    }

    .option:hover, .option.active {
        background-color: #f8f9fa;
    }

    .option label {
        font-weight: 400;
    }

    .option.disabled label {
        color: #adb5bd;
    }

    .option.disabled:hover {
        background-color: transparent;
    }
</style>
