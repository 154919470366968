<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="template-archive-modal-label">Archive {{ $root.contents.template_title }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body" :style="($root.hasPermission('templates_delete') ? 'padding-bottom: 200px' : '')">
            <div v-if="sent" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>
            <div v-else>
                <form>
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <p>
                        Archiving the {{ $root.contents.template_title }} removes it from view for all users.
                        Users previous versions will no longer be available.
                    </p>
                    <p>
                        You can make this {{ $root.contents.template_title }} live again by visiting the archive.
                        Users will then have access to their previous versions.
                    </p>
                    <div v-if="$root.hasPermission('templates_delete')" class="form-group">
                        <!--<label for="auto-delete-period">Delete automatically</label>-->
                        <searchable-option-list
                            v-model="auto_delete_period"
                            :value="auto_delete_period"
                            :options="auto_delete_options"
                            field_id="auto-delete-period"
                            :readonly="sending"
                        ></searchable-option-list>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="!sent" class="modal-footer">
            <span v-if="!sending">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-archive mr-1"></i> Archive now
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Archive now</span>
                </button>
            </span>
            <button type="button" class="btn btn-light ml-2" data-dismiss="modal" :disabled="sending">
                <i class="far fa-times mr-1"></i> Cancel
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                asset: {
                    title: ''
                },
                list_component: null,
                auto_delete_period: 'never',
                auto_delete_date: null,
                auto_delete_options: [
                    {
                        id: 'never',
                        name: 'Never delete'
                    },
                    {
                        id: '7d',
                        name: 'Delete after 1 week'
                    },
                    {
                        id: '1m',
                        name: 'Delete after 1 month'
                    },
                    {
                        id: '3m',
                        name: 'Delete after 3 months'
                    },
                    {
                        id: '6m',
                        name: 'Delete after 6 months'
                    },
                    {
                        id: '12m',
                        name: 'Delete after 12 months'
                    }
                ]
            }
        },
        mounted () {
            //
        },
        watch: {
            auto_delete_period: function (val) {
                if (!val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.auto_delete_period = 'never';
                    }, 200);
                    return null;
                }
                this.setDate('auto_delete_date', val);
            }
        },
        methods: {
            resetForm: function (asset, listComponent) {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.asset = asset;
                this.list_component = listComponent;
                this.auto_delete_period = 'never';
                this.auto_delete_date = null;
            },
            onSubmit: function () {
                this.errors = false;
                this.sending = true;

                /**
                 * Send archive asset request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/'
                    + this.asset.id + '/archive';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: { auto_delete_date: this.auto_delete_date },
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.sent = vm.$root.ucfirst(vm.$root.contents.template_title) + ' has been archived.';
                    vm.$root.folders_changed = true;

                    // Deselect asset.
                    let selectedAssets = vm.$root.selected_assets;
                    let itemIndex = -1;
                    selectedAssets.forEach((item, index) => {
                        if (item == this.asset.id) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex > -1) {
                        selectedAssets.splice(itemIndex, 1);
                    }

                    // Close after 2 seconds automatically.
                    setTimeout(function () {
                        $('#template-archive-modal').modal('hide');
                    }, 2000);

                    // Reload assets.
                    const pages = [
                        'assets',
                        'assets-filtered',
                        'view-collection',
                        'asset-reviews',
                        'shared',
                        'asset-approvals'
                    ];
                    if (pages.indexOf(vm.list_component.component_name) > -1) {
                        vm.list_component.getAssets();

                        return null;
                    }
                    vm.list_component.view_asset = null;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#template-archive-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    // Show error.
                    vm.errors = (error.response.data.error) ? error.response.data.error
                        : 'Something went wrong, please try again';
                })
                .finally(() =>
                    vm.sending = false
                );
            },
            setDate: function (dateField, value) {
                if (value == 'never') {
                    this[dateField] = null;

                    return null;
                }
                const newDate = new Date();
                if (value == '7d') {
                    newDate.setDate(newDate.getDate() + 7);
                }
                if (value == '1m') {
                    newDate.setMonth(newDate.getMonth() + 1);
                }
                if (value == '3m') {
                    newDate.setMonth(newDate.getMonth() + 3);
                }
                if (value == '6m') {
                    newDate.setMonth(newDate.getMonth() + 6);
                }
                if (value == '12m') {
                    newDate.setMonth(newDate.getMonth() + 12);
                }
                const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
                const day = ('0' + newDate.getDate()).slice(-2);
                this[dateField] = newDate.getFullYear() + '-' + month + '-' + day;
            }
        }
    }
</script>
