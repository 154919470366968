<template>
    <div class="topnav page-topnav" style="padding: 0 12px; max-height: none; overflow-y: visible">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand topnav-menu">
                <div class="navbar-collapse align-items-center justify-content-between">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <h6>POD codes</h6>
                        </li>
                    </ul>
                    <ul v-if="$root.hasPermission('podcodes_create')" class="navbar-nav">
                        <li class="nav-item" style="height: 36.5px">
                            <div style="position: absolute; height: 36.5px; right: 0">
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#pod-code-add-form-modal"
                                    @click.prevent="resetPodCodeAddForm()"
                                >
                                    <i class="far fa-plus mr-1"></i> Create POD code
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        mounted () {
            //
        },
        methods: {
            resetPodCodeAddForm: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const podCodesComponent = this.$root.findComponent(appComponent, 'pod-codes');
                if (!podCodesComponent) {
                    return null;
                }
                const component = this.$root.findComponent(podCodesComponent, 'pod-code-add-form');
                if (component) {
                    component.resetForm();
                }
            }
        }
    }
</script>
