<template>
    <div>
        <div class="row">
            <div v-if="canEdit()" class="col-lg-3 col-md-4 col-sm-6">
                <form v-on:submit.prevent="onSubmit()">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="form-group">
                        <label
                            :for="'user-group-name' + user_group.id"
                            :id="'user-group-name-label' + user_group.id"
                            class="mandatory-label"
                        >
                            User group name *
                        </label>
                        <input
                            v-model="user_group.name"
                            type="text"
                            :id="'user-group-name' + user_group.id"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('user-group-name', user_group.id)"
                            v-on:change="$root.unHighlightErrors('user-group-name', user_group.id)"
                        >
                    </div>

                    <span v-if="!updating">
                        <button
                            type="submit"
                            class="btn btn-primary mr-2"
                            :disabled="updating"
                        >
                            <i class="far fa-save mr-1"></i> Save
                        </button>
                    </span>
                    <span v-else>
                        <button type="button" class="btn btn-primary mr-2">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Save</span>
                        </button>
                    </span>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        :disabled="updating"
                        @click.prevent="deleteUserGroup()"
                    >
                        <i class="far fa-trash-alt mr-1"></i> Delete
                    </button>
                </form>
            </div>
            <div class="col">
                <label>Users</label>

                <div v-if="users_loading"><loading :align="'left'"></loading></div>

                <div v-if="users_errors" v-html="users_errors" class="alert alert-danger" role="alert"></div>

                <div v-if="(!users_errors && !users_loading)">
                    <div class="mb-3">
                        <div v-if="(users.length == 0)" class="alert alert-warning" role="alert">
                            There are no users in this user group
                        </div>
                        <user-group-users
                            v-if="(users.length > 0)"
                            :data="users"
                            :roles="roles"
                            :user_group="user_group"
                            :view_only="!canEdit()"
                        ></user-group-users>
                    </div>

                    <div v-if="canEdit()" class="text-right">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click.prevent="resetUserGroupUserAddForm()"
                        >
                            <i class="far fa-user-plus mr-1"></i> Add user
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'roles', 'show_add_user_modal'],
        data () {
            return {
                errors: false,
                updating: false,
                user_group: {},
                users: [],
                num_assets: 0,
                users_loading: true,
                users_errors: false
            }
        },
        mounted () {
            if (this.data) {
                this.user_group = this.data;
                this.getUsers();
            }
        },
        watch: {
            data: function (val) {
                this.user_group = val;
                this.getUsers();
            }
        },
        methods: {
            onSubmit: function () {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.errors = false;
                this.updating = true;
                /**
                 * Send update user group request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-group/'
                    + this.user_group.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: vm.user_group,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Show confirmation message.
                    vm.$parent.$parent.message = {
                        type: 'success',
                        title: 'Update user group',
                        text: response.data.message
                    };
                    $('#message-modal').modal('show');

                    // close after 2 seconds automatically.
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = 'Something went wrong, please try again';
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#user-group-' + key + '-label' + vm.user_group.id).addClass('text-danger');
                                $('#user-group-' + key + vm.user_group.id).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            getUsers: function () {
                if (this.show_add_user_modal) {
                    this.resetUserGroupUserAddForm();
                    this.$parent.auto_open_group = null;
                }
                this.users_errors = false;

                /**
                 * Send get user group request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-group/'
                    + this.user_group.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.users = response.data.user_group.users;
                    vm.num_assets = response.data.user_group.number_of_resources;
                    vm.user_group.user_info = vm.users.length.toString();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.users_errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.users_loading = false;
                });
            },
            resetUserGroupUserAddForm: function () {
                const component = this.$root.findComponent(this.$parent, 'user-group-user-add-form');
                if (component) {
                    component.resetForm(this.user_group, this.users, this);
                    $('#user-group-user-add-form-modal').modal('show');
                }
            },
            deleteUserGroup: function (confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.loading = true;

                    /**
                     * Send delete user group request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-group/'
                        + this.user_group.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.$parent.getUserGroups();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    });

                    return null;
                }

                if (this.updating) {
                    return null;
                }

                let text = 'Are you sure you want to delete: ' + this.user_group.name + '?';
                if (this.num_assets) {
                    text += '<br><br>All ' + this.$root.contents.asset_title
                        + 's assigned to this user group will become available to all users.';
                }

                this.$parent.$parent.confirm_delete = {
                    title: 'Delete user group',
                    text: text,
                    component: this,
                    action: 'delete-group'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deleteUserGroup(true);
            },
            canEdit: function () {
                if (!this.user_group.id) {
                    return false;
                }
                if (!this.user_group.private) {
                    return true;
                }
                
                return (this.user_group.created_by == this.$root.user.id);
            }
        }
    }
</script>
