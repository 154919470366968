<template>
    <div class="py-3">
        <loading v-if="loading"></loading>
        <div v-else>
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form v-on:submit.prevent="onSubmit()">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label
                                    :for="'profile-user-first_name' + user.id"
                                    :id="'profile-user-name-label' + user.id" class="mandatory-label"
                                >Name *</label>
                                <div class="input-group">
                                    <input
                                        v-model="user.first_name"
                                        type="text"
                                        :id="'profile-user-first_name' + user.id"
                                        class="form-control mandatory-field"
                                        :readonly="(read_only || updating)"
                                        :placeholder="placeholder('profile-user-first_name')"
                                        v-on:focus="placeholder('profile-user-first_name', true)"
                                        v-on:blur="placeholder('profile-user-first_name', true)"
                                        v-on:keyup="unHighlightNameErrors()"
                                        v-on:change="unHighlightNameErrors()"
                                    >
                                    <input
                                        v-model="user.last_name"
                                        type="text"
                                        :id="'profile-user-last_name' + user.id"
                                        class="form-control mandatory-field"
                                        :readonly="(read_only || updating)"
                                        :placeholder="placeholder('profile-user-last_name')"
                                        v-on:focus="placeholder('profile-user-last_name', true)"
                                        v-on:blur="placeholder('profile-user-last_name', true)"
                                        v-on:keyup="unHighlightNameErrors()"
                                        v-on:change="unHighlightNameErrors()"
                                    >
                                </div>
                            </div>
                            <div class="form-group">
                                <label
                                    :for="'profile-user-job_title' + user.id"
                                    :id="'profile-user-job_title-label' + user.id"
                                    class="mandatory-label"
                                >Job title <span v-if="isUserAdmin()">*</span></label>
                                <input
                                    v-model="user.job_title"
                                    type="text"
                                    :id="'profile-user-job_title' + user.id"
                                    class="form-control mandatory-field"
                                    :readonly="(read_only || updating)"
                                    v-on:keyup="$root.unHighlightErrors('profile-user-job_title', user.id)"
                                    v-on:change="$root.unHighlightErrors('profile-user-job_title', user.id)"
                                >
                            </div>
                            <div class="form-group">
                                <label
                                    :for="'profile-user-company' + user.id"
                                    :id="'profile-user-company-label' + user.id"
                                    class="mandatory-label"
                                >{{ $root.ucfirst(company_title) }} <span v-if="isUserAdmin()">*</span></label>
                                <input
                                    v-model="user.company"
                                    type="text"
                                    :id="'profile-user-company' + user.id"
                                    class="form-control mandatory-field"
                                    :readonly="(read_only || updating)"
                                    v-on:keyup="$root.unHighlightErrors('profile-user-company', user.id)"
                                    v-on:change="$root.unHighlightErrors('profile-user-company', user.id)"
                                >
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label
                                    :for="'profile-user-email' + user.id"
                                    :id="'profile-user-email-label' + user.id"
                                    class="mandatory-label"
                                >Email *</label>
                                <input
                                    v-model="user.email"
                                    type="email"
                                    :id="'profile-user-email' + user.id"
                                    class="form-control mandatory-field"
                                    :readonly="(read_only || updating)"
                                    v-on:keyup="$root.unHighlightErrors('profile-user-email', user.id)"
                                    v-on:change="$root.unHighlightErrors('profile-user-email', user.id)"
                                >
                            </div>
                            <div class="form-group">
                                <label
                                    :for="'profile-user-telephone' + user.id"
                                    :id="'profile-user-telephone-label' + user.id"
                                    class="mandatory-label"
                                >Telephone *</label>
                                <input
                                    v-model="user.telephone"
                                    type="text"
                                    :id="'profile-user-telephone' + user.id"
                                    class="form-control mandatory-field"
                                    :readonly="(read_only || updating)"
                                    v-on:keyup="$root.unHighlightErrors('profile-user-telephone', user.id)"
                                    v-on:change="$root.unHighlightErrors('profile-user-telephone', user.id)"
                                >
                            </div>
                            <div class="form-group">
                                <label
                                    :for="'profile-user-role' + user.id"
                                    :id="'profile-user-role-label' + user.id"
                                    class="mandatory-label"
                                >User role <span v-if="isUserAdmin()">*</span></label>
                                <searchable-option-list
                                    v-model="user.role"
                                    :value="user.role"
                                    :options="user_roles"
                                    :field_id="'profile-user-role' + user.id"
                                    field_class="mandatory-field"
                                    :readonly="(read_only || !isUserAdmin() || updating)"
                                    search_placeholder="Select a role"
                                    v-on:change="$root.unHighlightErrors('profile-user-role', user.id)"
                                ></searchable-option-list>
                                <small :id="'role-description' + user.id" class="form-text text-muted">
                                    {{ roleDescription() }}
                                </small>
                            </div>
                        </div>
                    </div>
                </form>

                <span v-if="canUpdate()">
                    <span v-if="!updating">
                        <button :key="'save-button'" type="button" class="btn btn-primary mr-2" @click="onSubmit()">
                            <i class="far fa-save mr-1"></i> Save
                        </button>
                    </span>
                    <span v-else>
                        <button :key="'saving-button'" type="button" class="btn btn-primary mr-2">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Save</span>
                        </button>
                    </span>
                </span>
                <span v-if="canDelete()">
                    <span v-if="!deleting">
                        <button :key="'delete-button'" type="button" class="btn btn-secondary" @click="deleteUser()">
                            <i class="far fa-trash-alt mr-1"></i> Delete
                        </button>
                    </span>
                    <span v-else>
                        <button :key="'deleting-button'" type="button" class="btn btn-secondary">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Delete</span>
                        </button>
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'is_visible', 'user_roles'],
        data () {
            return {
                loading: true,
                errors: false,
                updating: false,
                updated: false,
                user: {},
                read_only: false,
                deleting: false,
                get_content_response: null,
                company_title: 'company'
            }
        },
        mounted () {
            //
        },
        watch: {
            is_visible: function (val) {
                if (val) {
                    this.resetForm();
                }
            },
            get_content_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.key == 'company_title') {
                            this.company_title = val.content;
                        }
                    }
                }
            }
        },
        methods: {
            resetForm: function () {
                this.errors = false;
                this.updated = false;
                this.loading = true;
                this.user = {};
                for (const [key, value] of Object.entries(this.data)) {
                    if ((key == 'company_contact_details') && (value != null)) {
                        this.user.company_contact_name = value.name;
                        this.user.company_contact_email = value.email;
                        this.user.company_contact_phone = value.phone;
                    } else {
                        this.user[key] = value;
                    }
                }
                this.read_only = !this.canUpdate();
                this.loading = false;
                this.$root.getContent(this, 'company_title');
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user/' + this.data.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: this.user,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = response.data.message;
                    if (vm.$parent.profile_group.primary_contact == this.data.id) {
                        vm.$parent.getProfileGroup();
                    }

                    // If current user reload user details.
                    if (vm.data.id == vm.$root.user.id) {
                        vm.$root.getUser();
                    }

                    setTimeout(function () {
                        vm.$parent.getUsers();
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>').replace(/company/g, vm.company_title) + '<br>';
                                    if ((key == 'first_name') || (key == 'last_name')) {
                                        $('#profile-user-name-label' + vm.user.id).addClass('text-danger');
                                    } else {
                                        $('#profile-user-' + key + '-label' + vm.data.id).addClass('text-danger');
                                    }
                                    $('#profile-user-' + key + vm.data.id).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            deleteUser: function (confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.deleting = true;

                    /**
                     * Send delete request to API
                     */
                    const cancel = (this.data.created_by && !this.data.email_verified_at
                        && this.$root.hasPermission('users_invite')) ? '/cancel-invite' : '';
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user/' + this.data.id
                        + cancel;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        if (vm.$parent.profile_group.primary_contact == this.data.id) {
                            vm.$parent.profile_group.primary_contact = null;
                            vm.$parent.updatePrimaryContact();
                        }
                        vm.$parent.loading_users = true;

                        // Clear notification.
                        vm.$root.clearNotification('admin', 0, 'deletion_request', vm.data.id);

                        // If current user logout.
                        if (vm.data.id == vm.$root.user.id) {
                            setTimeout(function () {
                                vm.$root.doLogout();
                            }, 5000);

                            return null;
                        }

                        setTimeout(function () {
                            vm.$parent.getUsers();
                        }, 2000);
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    })
                    .finally(() => {
                        vm.deleting = false;
                    });

                    return null;
                }

                if (this.updating) {
                    return null;
                }

                this.$parent.$parent.confirm_delete = {
                    title: 'Delete user',
                    text: 'Are you sure you want to delete: ' + this.data.first_name + ' ' + this.data.last_name + '?',
                    component: this,
                    action: 'delete-user'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deleteUser(true);
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field + this.user.id).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field + this.user.id).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'first_name':
                        text = 'First';
                        break;
                    case 'last_name':
                        text = 'Last';
                        break;
                }
                if (update) {
                    $('#' + field + this.user.id).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            unHighlightNameErrors: function () {
                if (this.user.first_name) {
                    $('#profile-user-first_name' + this.user.id).removeClass('field-error');
                }
                if (this.user.last_name) {
                    $('#profile-user-last_name' + this.user.id).removeClass('field-error');
                }
                if (this.user.first_name && this.user.last_name) {
                    $('#profile-user-name-label' + this.user.id).removeClass('text-danger');
                }
            },
            roleDescription: function () {
                let description = '';
                this.user_roles.forEach((item) => {
                    if (item.id == this.user.role) {
                        description = item.description;
                    }
                });

                return description;
            },
            canUpdate: function () {
                if (this.isUserAdmin()) {
                    return true;
                }

                return (this.$root.is_own_profile_group && this.$root.hasPermission('profiles_own_group_edit_users'));
            },
            canDelete: function () {
                if (this.$root.hasPermission('users_delete')) {
                    return true;
                }
                if (!this.$root.is_own_profile_group) {
                    return false;
                }

                return this.$root.hasPermission('profiles_own_group_delete_users');
            },
            isUserAdmin: function () {
                return this.$root.hasPermission('users_edit');
            }
        }
    }
</script>
