<template>
    <div class="topnav page-topnav" style="padding: 0 12px; max-height: none; overflow-y: visible">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand topnav-menu">
                <div class="navbar-collapse align-items-center justify-content-between">
                    <ul class="navbar-nav">
                        <li v-if="is_archive" class="nav-item">
                            <h6 class="contact-links" style="white-space: nowrap">
                                <i class="fal fa-archive font-size-16 mr-1"></i>
                                Archived {{ $root.contents.asset_title }}s
                            </h6>
                        </li>
                        <li v-if="(folder_filters.length == 0)" class="nav-item">
                            <h6 class="contact-links">
                                <i class="fal fa-folders font-size-16 mr-1"></i>
                                All {{ $root.contents.folders_title }}
                            </h6>
                        </li>
                        <li v-for="filter in folder_filters" :key="filter.id" class="nav-item">
                            <h6 class="contact-links" style="white-space: nowrap">
                                <span v-html="filter.text"></span>
                                <a
                                    href="#"
                                    v-b-tooltip.hover
                                    :title="'Close ' + $root.contents.folder_title"
                                    @click.prevent="$parent.removeFilter(filter)"
                                >
                                    <i class="fal fa-times-circle"></i>
                                </a>
                            </h6>
                        </li>
                        <li v-if="hasSubfolders()" class="nav-item">
                            <b-dropdown
                                variant="black"
                                class="dropdown-mega"
                                toggle-tag="a"
                                toggle-class="nav-link"
                                menu-class="dropdown-megamenu subfolders"
                                ref="subfolder_dropdown"
                            >
                                <template v-slot:button-content>
                                    <i class="fal fa-folder-tree mr-1"></i>
                                    {{ current_subfolder ? current_subfolder.name
                                        : 'All ' + $root.contents.subfolders_title }}
                                    <i
                                        class="fas fa-chevron-down"
                                        style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                    ></i>
                                </template>
                                <div class="row">
                                    <div class="col-md-3">
                                        <ul class="list-unstyled megamenu-list mb-2">
                                            <li class="p-0">
                                                <router-link
                                                    class="all-folders pt-1 pb-1 pl-2 pr-2"
                                                    :to="routerLinkTo()"
                                                    v-on:click.native="subfolderSearch()"
                                                >
                                                    <h5 class="font-size-14 mt-0 mb-0">All content</h5>
                                                </router-link>
                                            </li>
                                        </ul>

                                        <ul v-if="(columns[0].length > 0)" class="list-unstyled megamenu-list mb-2">
                                            <li class="p-0" v-for="subfolder in columns[0]" :key="subfolder.id">
                                                <router-link
                                                    class="pt-1 pb-1 pl-2 pr-2"
                                                    :to="routerLinkTo()"
                                                    v-on:click.native="subfolderSearch(subfolder)"
                                                >{{ subfolder.name }}</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-3">
                                        <ul v-if="(columns[1].length > 0)" class="list-unstyled megamenu-list mb-2">
                                            <li class="p-0" v-for="subfolder in columns[1]" :key="subfolder.id">
                                                <router-link
                                                    class="pt-1 pb-1 pl-2 pr-2"
                                                    :to="routerLinkTo()"
                                                    v-on:click.native="subfolderSearch(subfolder)"
                                                >{{ subfolder.name }}</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-3">
                                        <ul v-if="(columns[2].length > 0)" class="list-unstyled megamenu-list mb-2">
                                            <li class="p-0" v-for="subfolder in columns[2]" :key="subfolder.id">
                                                <router-link
                                                    class="pt-1 pb-1 pl-2 pr-2"
                                                    :to="routerLinkTo()"
                                                    v-on:click.native="subfolderSearch(subfolder)"
                                                >{{ subfolder.name }}</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-3">
                                        <ul v-if="(columns[3].length > 0)" class="list-unstyled megamenu-list mb-2">
                                            <li class="p-0" v-for="subfolder in columns[3]" :key="subfolder.id">
                                                <router-link
                                                    class="pt-1 pb-1 pl-2 pr-2"
                                                    :to="routerLinkTo()"
                                                    v-on:click.native="subfolderSearch(subfolder)"
                                                >{{ subfolder.name }}</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-dropdown>
                        </li>
                    </ul>
                    <ul class="navbar-nav">
                        <li class="nav-item d-none d-md-inline">
                            <form v-on:submit.prevent="keywordSearch()">
                                <div style="position: relative">
                                    <div class="input-group">
                                        <input
                                            v-model="keyword"
                                            type="text"
                                            class="form-control"
                                            id="search-by-keyword-top-nav"
                                            placeholder="Search within these results"
                                            aria-label="Search within these results"
                                            style="min-width: 200px"
                                            autocomplete="off"
                                            @focus="onFocus()"
                                            @keydown="onKeydown($event)"
                                            @keyup="onKeyup($event)"
                                        >
                                        <div class="input-group-append">
                                            <button class="btn btn-primary big-icon" type="submit">
                                                <i class="fal fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        v-if="keyword"
                                        type="button"
                                        class="btn btn-link clear-keyword"
                                        @click.prevent="clearKeyword()"
                                    >
                                        <i class="far fa-times"></i>
                                    </button>
                                    <div v-if="$root.client.asset_search_autocomplete" class="dropdown">
                                        <div
                                            class="dropdown-menu search-autocomplete"
                                            id="search-autocomplete-atn"
                                        >
                                            <a
                                                v-for="option in autocomplete_options"
                                                :key="option.id"
                                                :id="'search-autocomplete-atn-option' + option.id"
                                                href="#"
                                                :class="'dropdown-item'
                                                    + ((option.id == selected_autocomplete_option) ? ' active' : '')"
                                                style="white-space: normal"
                                                @click.prevent="getAsset(option)"
                                            >{{ option.name }}</a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </li>
                        <!--<li class="nav-item d-none d-md-inline pl-3" style="min-width: 130px; height: 36.5px">
                            <div class="btn-group btn-block" style="height: 36.5px">
                                <button
                                    type="button"
                                    class="btn btn-light dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    style="text-align: left"
                                >
                                    {{ sortByName() }}
                                    <i
                                        class="fas fa-chevron-down"
                                        style="font-size: 9px; position: absolute; top: 14px; right: 10px"
                                    ></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a
                                        v-for="option in getSortByOptions()"
                                        :class="'dropdown-item' + (option.disabled ? ' text-muted' : '')"
                                        :key="option.id"
                                        href="#"
                                        @click.prevent="option.disabled ? doNothing() : sort_by = option.id"
                                    >{{ option.name }}</a>
                                </div>
                            </div>
                        </li>-->
                        <li v-if="is_archive" class="nav-item" style="width: 100px; height: 36.5px">
                            <div class="btn-group" style="position: absolute; height: 36.5px; right: 0">
                                <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    Actions
                                    <i
                                        class="fas fa-chevron-down"
                                        style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                    ></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <!--<a
                                        class="dropdown-item"
                                        href="#"
                                        @click.prevent="showAddAssetModal()"
                                    >
                                        <i class="far fa-file-plus mr-1"></i> Add {{ $root.contents.asset_title }}
                                    </a>-->
                                    <!--<a
                                        class="dropdown-item"
                                        href="#"
                                        @click.prevent="goToLiveAssets()"
                                    >
                                        <i class="far fa-copy mr-1"></i>
                                        View live {{ $root.contents.asset_title }}s
                                    </a>-->
                                    <router-link
                                        class="dropdown-item"
                                        :to="{ name: 'assets' }"
                                    >
                                        <i class="far fa-copy mr-1"></i>
                                        View live {{ $root.contents.asset_title }}s
                                    </router-link>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['asset_filters', 'is_archive'],
        data () {
            return {
                folder_filters: [],
                current_folder: null,
                current_subfolder: null,
                columns: [],
                keyword: '',
                sort_by: 'newest',
                autocomplete_options: [],
                selected_autocomplete_option: null,
                last_key: null,
                keypress_time: 0
            }
        },
        mounted () {
            this.updateFilters(this.asset_filters);
            var vm = this;
            $(document).on('click', function () {
                $('#search-autocomplete-atn').hide();
                vm.selected_autocomplete_option = null;
                vm.last_key = null;
            });
        },
        watch: {
            asset_filters: function (val) {
                this.updateFilters(val);
            },
            sort_by: function (val) {
                if (!val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.sort_by = 'newest';
                    }, 200);

                    return null;
                }

                let text = 'Newest first';
                if (val == 'oldest') {
                    text = 'Oldest first';
                }
                if (val == 'relevance') {
                    text = 'Relevance';
                }
                this.$root.addFilter('asset_filters', {
                    key: 'sort_by',
                    value: val,
                    text: 'Order results by: ' + text,
                    filters_key: 'asset_filters'
                });
                //this.getAssets();
                //console.log('get assets: sorting');
            }
        },
        methods: {
            updateFilters: function (assetFilters) {
                let filters = [];
                let folderFound = false;
                let subfolderFound = false;
                let sortByFound = null;
                assetFilters.forEach((filter) => {
                    /*if ((filter.key == 'folder') || (filter.key == 'subfolder')) {
                        filters.push(filter);
                    }*/
                    if (filter.key == 'folder') {
                        filters.push(filter);
                        this.current_folder = {
                            id: filter.value,
                            subfolders: filter.subfolders
                        };
                        folderFound = true;
                    }
                    if (filter.key == 'subfolder') {
                        this.current_subfolder = {
                            id: filter.value,
                            name: filter.name
                        };
                        subfolderFound = true;
                    }
                    if (filter.key == 'sort_by') {
                        sortByFound = filter.value;
                    }
                });
                if (!folderFound || !subfolderFound) {
                    this.current_subfolder = null;
                    if (!folderFound) {
                        this.current_folder = null;
                    }
                }
                this.sort_by = sortByFound;

                this.folder_filters = filters;
                this.updateColumns();
            },
            updateColumns: function () {
                this.columns = [];
                if (!this.hasSubfolders()) {
                    return null;
                }

                const numItems = this.current_folder.subfolders.length;
                const itemsPerColumn = Math.ceil((numItems + 1) / 4);

                let columnSubfolders = [];
                let columnNumItems = 1;
                this.current_folder.subfolders.forEach((item) => {
                    let columnFull = (columnNumItems >= itemsPerColumn);
                    let firstColumnFull = ((this.columns.length == 0) && (columnNumItems > (itemsPerColumn - 1)));
                    if (columnFull || firstColumnFull) {
                        this.columns.push(columnSubfolders);
                        columnSubfolders = [];
                        columnNumItems = 0;
                    }
                    columnSubfolders.push(item);
                    columnNumItems++;
                });
                if (columnNumItems > 0) {
                    this.columns.push(columnSubfolders);
                }
                this.columns.push([]);
                this.columns.push([]);
                this.columns.push([]);
                this.columns.push([]);
            },
            hasSubfolders: function () {
                if (!this.current_folder) {
                    return false;
                }

                return (this.current_folder.subfolders.length > 0);
            },
            subfolderSearch: function (subfolder = null) {
                // Remove keyword and other folder filters
                let filters = [];
                this.$root.asset_filters.forEach((item) => {
                    /*if (subfolder) {
                        if ((item.key == 'subfolder') && (item.value == subfolder.id)) {
                            subfolderAdded = true;
                        }
                    }*/
                    filters.push(item);
                });
                for (let itemIndex = (filters.length - 1); itemIndex >= 0; itemIndex--) {
                    if (filters[itemIndex].key != 'folder') {
                        filters.splice(itemIndex, 1);
                    }
                }
                this.$root.asset_filters = filters;
                // Add new subfolder filters according to selection
                if (subfolder) {
                    this.$root.addFilter('asset_filters', {
                        key: 'subfolder',
                        value: subfolder.id,
                        name: subfolder.name,
                        text: '<i class="fal fa-folder-tree font-size-16 mr-1"></i> '
                            + subfolder.name.replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                        filters_key: 'asset_filters'
                    });
                }
                this.getAssets();
            },
            keywordSearch: function () {
                let keywordTrimmed = this.keyword.trim();
                if (keywordTrimmed == '') {
                    return null;
                }

                let keywords = [];
                const keywordLength = keywordTrimmed.length;
                if (
                    (keywordTrimmed.substring(0, 1) == '"')
                    && (keywordTrimmed.substring(keywordLength - 1) == '"')
                ) {
                    keywords.push(keywordTrimmed.substring(1, keywordLength - 1));
                } else {
                    keywords = keywordTrimmed.split(',');
                }
                let keywordsAdded = [];
                keywords.forEach((keyword) => {
                    keywordTrimmed = keyword.trim();
                    if ((keywordTrimmed != '') && (keywordsAdded.indexOf(keywordTrimmed) < 0)) {
                        keywordsAdded.push(keywordTrimmed);
                    }
                });
                if (keywordsAdded.length > 0) {
                    this.$root.addFilter('asset_filters', {
                        key: 'keyword',
                        value: keywordsAdded.join('||'),
                        text: '&quot;' + keywordsAdded.join('&quot;, &quot;').replace(/"/g, '&quot;')
                            .replace(/'/g, '&apos;') + '&quot;',
                        filters_key: 'asset_filters'
                    });
                }
                this.keyword = '';
                this.getAssets();
            },
            clearKeyword: function () {
                this.keyword = '';
                $('#search-by-keyword-top-nav').focus();
            },
            getAssets: function () {
                // If not assets page redirect
                if (!this.$parent.isAssetsPage()) {
                    this.$router.push({ name: 'assets' });

                    return false;
                }
                const component = this.$root.findComponent(this.$parent.$parent, 'assets');
                if (component) {
                    component.getAssets();
                    console.log('get assets: subfolder dropdown/keyword');
                }
                this.$root.hideMainNavDropdowns();
                this.hideSubfolderDropdown();
            },
            routerLinkTo: function () {
                if (this.$route.name == 'assets-filtered') {
                    const linkTo = {
                        name: this.$route.name,
                        params: this.$route.params
                    };

                    return linkTo;
                }

                return { name: 'assets' };
            },
            hideSubfolderDropdown: function () {
                if (this.$refs.subfolder_dropdown) {
                    this.$refs.subfolder_dropdown.hide(true);
                }
            },
            getSortByOptions: function () {
                return [
                    {
                        id: 'newest',
                        name: 'Newest first'
                    },
                    {
                        id: 'oldest',
                        name: 'Oldest first'
                    },
                    {
                        id: 'relevance',
                        name: 'Relevance',
                        disabled: !this.hasKeywords()
                    }
                ];
                /*if (this.hasKeywords()) {
                    options.push({
                        id: 'relevance',
                        name: 'Relevance'
                    });
                }

                return options;*/
            },
            sortByName: function () {
                if (this.sort_by == 'newest') {
                    return 'Newest first';
                }
                if (this.sort_by == 'oldest') {
                    return 'Oldest first';
                }
                if (this.sort_by == 'relevance') {
                    return 'Relevance';
                }

                return 'Sort by';
            },
            hasKeywords: function () {
                let found = false;
                this.$root.asset_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        found = true;
                    }
                });

                return found;
            },
            doNothing: function () {
                return null;
            },
            isArchive: function () {
                if ((this.$route.name != 'assets-filtered') || (this.$route.params.filters != 'archived')) {
                    return false;
                }

                return true;
            },
            onFocus: function () {
                if (!this.keyword) {
                    this.autocomplete_options = [];
                    this.selected_autocomplete_option = null;
                    this.last_key = null;
                    $('#search-autocomplete-atn').hide();

                    return null;
                }
                if (this.autocomplete_options.length > 0) {
                    var vm = this;
                    setTimeout(function () {
                        vm.showAutocomplete();
                    }, 200);
                }
            },
            onKeydown: function (event) {
                if (!this.$root.client.asset_search_autocomplete) {
                    return null;
                }
                this.last_key = event.key;
                if (
                    (event.key != 'ArrowUp')
                    && (event.key != 'ArrowDown')
                    && ((event.key != 'Enter') || (!this.selected_autocomplete_option))
                ) {
                    return null;
                }
                event.preventDefault();
            },
            onKeyup: function (event) {
                if (!this.$root.client.asset_search_autocomplete) {
                    return null;
                }
                this.lookupAsset(true);
            },
            lookupAsset: function (delay = false) {
                if (
                    (this.last_key == 'ArrowUp')
                    || (this.last_key == 'ArrowDown')
                    || ((this.last_key == 'Enter') && (this.selected_autocomplete_option))
                ) {
                    this.showAutocomplete();

                    return null;
                }
                $('#search-autocomplete-atn').hide();
                this.autocomplete_options = [];
                this.selected_autocomplete_option = null;
                this.last_key = null;

                if (this.keyword.length < 3) {
                    return null;
                }

                if (delay) {
                    this.keypress_time = new Date();
                    var vm = this;
                    setTimeout(function () {
                        vm.lookupAsset();
                    }, 500);
                }

                const time = new Date();
                if ((time - this.keypress_time) < 500) {
                    return null;
                }

                let filters = [
                    this.isArchive() ? 'archived' : '!archived',
                    'ak::' + btoa(this.keyword)
                ];
                let assetIds = [];
                let limit = 100;
                this.asset_filters.forEach((item) => {
                    if (item.key == 'main-keyword') {
                        let values = item.value.split('||');
                        values.forEach((keyword) => {
                            filters.push('mk::' + btoa(keyword));
                        });
                    }
                    if (item.key == 'keyword') {
                        filters.push('ak::' + btoa(item.value));
                    }
                    if ((item.key == 'folder') && (item.value > 0)) {
                        filters.push('fr::' + item.value);
                    }
                    if (item.key == 'subfolder') {
                        filters.push('sf::' + item.value);
                    }
                    if ((item.key == 'asset_type') && !item.auto) {
                        filters.push('rt::' + item.value);
                    }
                    if (item.key == 'orientation') {
                        filters.push('or::' + item.value);
                    }
                    if (item.key == 'date_range') {
                        filters.push('dr::' + item.value);
                    }
                    if (item.key == 'uploader') {
                        filters.push('cb::' + item.value);
                    }
                    if ((item.key.substr(0, 2) == 'cf') && !item.auto) {
                        filters.push(item.key + '::' + btoa(item.value));
                    }
                    if (item.key == 'user_group') {
                        filters.push('ug::' + item.value);
                    }
                    if (item.key == 'resource_id') {
                        assetIds.push(item.value);
                    }
                    if ((item.key == 'restricted') || (item.key == '!restricted')) {
                        filters.push(item.key);
                    }
                    if (item.key == 'limit') {
                        limit = item.value;
                    }
                    if (item.key == 'template') {
                        filters.push(item.value ? 'template' : '!template');
                    }
                });
                filters.push('lt::' + limit);
                if (assetIds.length > 0) {
                    filters.push('in::' + assetIds.join(','));
                }

                /**
                 * Send search assets request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/' + filters.join('|');
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.resources.total == 0) {
                        return null;
                    }
                    response.data.resources.data.forEach((item) => {
                        // Duplicate display protection - check if already added.
                        let itemIndex = -1;
                        vm.autocomplete_options.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            vm.autocomplete_options.push({
                                id: item.id,
                                name: item.id + ' - ' + item.title
                            });
                        }
                    });
                    vm.showAutocomplete();
                })
                .catch(function (error) {
                    //
                });
            },
            showAutocomplete: function () {
                if (this.autocomplete_options.length == 0) {
                    return null;
                }
                $('#search-autocomplete-atn').show();
                let currentIndex = -1;
                let prevIndex = -1;
                let nextIndex = -1;
                if (this.selected_autocomplete_option) {
                    this.autocomplete_options.forEach((option, index) => {
                        if (option.id == this.selected_autocomplete_option) {
                            currentIndex = index;
                        } else {
                            if (currentIndex == -1) {
                                prevIndex = index;
                            }
                            if ((currentIndex > -1) && (nextIndex == -1)) {
                                nextIndex = index;
                            }
                        }
                    });
                    if (currentIndex == -1) {
                        this.selected_autocomplete_option = null;
                    }
                }
                if (this.last_key == 'ArrowDown') {
                    this.selected_autocomplete_option = (nextIndex > -1)
                        ? this.autocomplete_options[nextIndex].id
                        : this.autocomplete_options[0].id;
                    this.scrollToAutocompleteOption();
                }
                if (this.last_key == 'ArrowUp') {
                    this.selected_autocomplete_option = (prevIndex > -1)
                        ? this.autocomplete_options[prevIndex].id
                        : this.autocomplete_options[0].id;
                    this.scrollToAutocompleteOption();
                }
                if ((this.last_key == 'Enter') && (currentIndex > -1)) {
                    this.selected_autocomplete_option = null;
                    this.last_key = null;
                    this.getAsset(this.autocomplete_options[currentIndex]);
                }
            },
            scrollToAutocompleteOption: function () {
                let scrollTo = 0;
                let found = false;
                this.autocomplete_options.forEach((option) => {
                    if (!found) {
                        if (option.id != this.selected_autocomplete_option) {
                            let oid = 'search-autocomplete-atn-option' + option.id;
                            scrollTo += $('#' + oid).outerHeight();
                        } else {
                            found = true;
                        }
                    }
                });
                $('#search-autocomplete-atn').animate({ scrollTop: scrollTo }, 200);
            },
            getAsset: function (selectedAsset) {
                $('#search-autocomplete-atn').hide();
                this.$root.addFilter('asset_filters', {
                    key: 'resource_id',
                    value: selectedAsset.id,
                    text: '&quot;' + selectedAsset.id + '&quot;',
                    filters_key: 'asset_filters'
                });
                this.keyword = '';
                this.autocomplete_options = [];
                this.selected_autocomplete_option = null;
                this.last_key = null;
                this.getAssets();
            }
        }
    }
</script>
<style scoped>
    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }

    #search-autocomplete-atn {
        left: 0;
        right: auto;
        max-width: 280px;
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    #search-autocomplete-atn a.dropdown-item, #search-autocomplete-atn a.dropdown-item.active,
    #search-autocomplete-atn a.dropdown-item.active:hover {
        color: #16181b !important;
    }
</style>
