<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="profile-group-add-form-modal-label">
                Add {{ $root.contents.profile_group_title }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form v-if="$root.hasPermission('profiles_groups_add')" v-on:submit.prevent="onSubmit()">
                    <div class="form-group">
                        <label for="profile-group-name" id="profile-group-name-label" class="mandatory-label">
                            {{ $root.ucfirst($root.contents.profile_group_title) }} name *
                        </label>
                        <input
                            v-model="profile_group.name"
                            type="text"
                            id="profile-group-name"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('profile-group-name')"
                            v-on:change="$root.unHighlightErrors('profile-group-name')"
                        >
                    </div>
                    <div v-if="$root.client.show_profile_group_description" class="form-group">
                        <label
                            for="profile-group-description"
                            id="profile-group-description-label"
                            class="mandatory-label"
                        >
                            {{ $root.ucfirst($root.contents.profile_group_description_title) }}
                            <span v-if="$root.client.profile_group_description_required">*</span>
                        </label>
                        <input
                            v-model="profile_group.description"
                            type="text"
                            id="profile-group-description"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('profile-group-description')"
                            v-on:change="$root.unHighlightErrors('profile-group-description')"
                        >
                    </div>
                    <div v-if="$root.client.show_profile_group_subfolder_id" class="form-group">
                        <label
                            for="profile-group-folder_id"
                            id="profile-group-folder_id-label"
                            class="mandatory-label"
                        >
                            {{ $root.ucfirst($root.contents.folder_title) }}
                            <span v-if="$root.client.profile_group_subfolder_id_required">*</span>
                        </label>
                        <searchable-option-list
                            v-model="profile_group.folder_id"
                            :value="profile_group.folder_id"
                            :options="getFolderOptions()"
                            field_id="profile-group-folder_id"
                            field_class="mandatory-field"
                            :search_placeholder="($root.client.profile_group_subfolder_id_required ? null : 'None')"
                            :readonly="updating"
                            v-on:change="onFolderChange()"
                        ></searchable-option-list>
                    </div>
                    <div v-if="$root.client.show_profile_group_subfolder_id" class="form-group">
                        <label
                            for="profile-group-subfolder_id"
                            id="profile-group-subfolder_id-label"
                            class="mandatory-label"
                        >
                            {{ $root.ucfirst($root.contents.subfolder_title) }}
                            <span v-if="$root.client.profile_group_subfolder_id_required">*</span>
                        </label>
                        <searchable-option-list
                            v-model="profile_group.subfolder_id"
                            :value="profile_group.subfolder_id"
                            :options="getSubfolderOptions()"
                            field_id="profile-group-subfolder_id"
                            field_class="mandatory-field"
                            :search_placeholder="($root.client.profile_group_subfolder_id_required ? null : 'None')"
                            :readonly="updating || !profile_group.folder_id"
                            v-on:change="$root.unHighlightErrors('profile-group-subfolder_id')"
                        ></searchable-option-list>
                    </div>
                    <div v-if="$root.client.print_ordering_approval" class="form-group">
                        <label for="profile-group-print_approver">
                            {{ $root.ucfirst($root.contents.financial_approver_title) }}
                        </label>
                        <searchable-option-list
                            v-model="profile_group.print_approver"
                            :value="profile_group.print_approver"
                            :options="getApproverOptions()"
                            field_id="profile-group-print_approver"
                            :readonly="updating"
                        ></searchable-option-list>
                    </div>
                    <div v-if="$root.client.show_profile_group_account_number" class="form-group">
                        <label
                            for="profile-group-account_number"
                            id="profile-group-account_number-label"
                            class="mandatory-label"
                        >
                            Account number
                            <span v-if="$root.client.profile_group_account_number_required">*</span>
                        </label>
                        <input
                            v-model="profile_group.account_number"
                            type="text"
                            id="profile-group-account_number"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('profile-group-account_number')"
                            v-on:change="$root.unHighlightErrors('profile-group-account_number')"
                        >
                    </div>
                    <div v-if="$root.client.show_profile_group_consultant" class="form-group">
                        <label
                            for="profile-group-consultant"
                            id="profile-group-consultant-label"
                            class="mandatory-label"
                        >
                            Consultant
                            <span v-if="$root.client.profile_group_consultant_required">*</span>
                        </label>
                        <searchable-option-list
                            v-model="profile_group.consultant"
                            :value="profile_group.consultant"
                            :options="getConsultantOptions()"
                            field_id="profile-group-consultant"
                            field_class="mandatory-field"
                            :readonly="updating"
                            v-on:change="$root.unHighlightErrors('profile-group-consultant')"
                        ></searchable-option-list>
                    </div>
                    <div class="form-group">
                        <label for="profile-group-notes">Notes</label>
                        <textarea
                            v-model="profile_group.notes"
                            type="text"
                            id="profile-group-notes"
                            class="form-control"
                            rows="5"
                            :readonly="updating"
                        ></textarea>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="($root.hasPermission('profiles_groups_add') && !updated)" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-check mr-1"></i> Add
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Add</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                profile_group: {},
                folders: [],
                approvers: [],
                consultants: []
            }
        },
        mounted () {
            if (this.$root.client.print_ordering_approval) {
                this.getApprovers();
            }
            if (this.$root.client.show_profile_group_consultant) {
                this.getConsultants();
            }
            if (this.$root.client.show_profile_group_subfolder_id) {
                this.getFolders();
            }
        },
        methods: {
            resetForm: function () {
                if (!this.$root.hasPermission('profiles_groups_add')) {
                    this.errors = 'Sorry, you do not have access to this function.';

                    return false;
                }
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.profile_group = {
                    folder_id: this.defaultFolderId(),
                    subfolder_id: this.defaultFolderId() ? 0 : null
                };

                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                this.getFolders();

                setTimeout(function () {
                    $('#profile-group-name').focus();
                }, 500);
            },
            defaultFolderId: function () {
                if (!this.$root.client.profile_groups_folder) {
                    return null;
                }
                if (parseInt(this.$root.client.profile_groups_folder) > 0) {
                    return parseInt(this.$root.client.profile_groups_folder)
                }

                return null;
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-group';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: this.profile_group,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = vm.$root.ucfirst(vm.$root.contents.profile_group_title) + ' added.';
                    vm.profile_group.id = response.data.profile_group_id;

                    vm.$root.profile_group_filters = [];
                    if (!vm.profile_group.subfolder_id) {
                        vm.$root.folders_changed = true;
                    }

                    setTimeout(function () {
                        $('#profile-group-add-form-modal').modal('hide');
                        vm.$router.push({
                            name: 'view-profile-group',
                            params: {
                                id: vm.profile_group.id
                            }
                        });
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            $('#profile-group-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error
                                    .replace(/profile groups/g, vm.$root.contents.profile_groups_title)
                                    .replace(/profile group/g, vm.$root.contents.profile_group_title);
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                let errorHtml = null;
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    if ((key == 'subfolder_id') && !vm.profile_group.folder_id) {
                                        vm.errors += 'The ' + vm.$root.contents.folder_title + ' field is required.<br>';
                                        $('#profile-group-folder_id-label').addClass('text-danger');
                                        $('#profile-group-folder_id').addClass('field-error');
                                    }
                                    errorHtml = value.join('<br>') + '<br>';
                                    vm.errors += errorHtml.replace(
                                        /subfolder id/g, vm.$root.contents.subfolder_title
                                    )
                                    .replace(
                                        /description/g, vm.$root.contents.profile_group_description_title
                                    );
                                    $('#profile-group-' + key + '-label').addClass('text-danger');
                                    $('#profile-group-' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            getFolders: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/folders/!archived|!pagination|sb::name_asc';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folders = response.data.folders;
                })
                .catch(function (error) {
                    //
                });
            },
            getFolderOptions: function () {
                let options = [];
                if (!this.$root.client.profile_group_subfolder_id_required) {
                    options.push({
                        id: null,
                        name: 'None'
                    });
                }
                this.folders.forEach((folder) => {
                    //if (folder.subfolders.length > 0) {
                        options.push(folder);
                    //}
                });

                return options;
            },
            getSubfolderOptions: function () {
                let options = [];
                if (!this.$root.client.profile_group_subfolder_id_required) {
                    options.push({
                        id: null,
                        name: 'None'
                    });
                }
                options.push({
                    id: 0,
                    name: 'Create a new one automatically'
                });
                this.folders.forEach((folder) => {
                    if (folder.id == this.profile_group.folder_id) {
                        folder.subfolders.forEach((item) => {
                            options.push(item);
                        });
                    }
                });

                return options;
            },
            onFolderChange: function () {
                this.profile_group.subfolder_id = null;
                this.$root.unHighlightErrors('profile-group-folder_id');
            },
            getApprovers: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|financial-approver';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.approvers = response.data.users;
                })
                .catch(function (error) {
                    //
                });
            },
            getConsultants: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|profile-group-consultant';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.consultants = response.data.users;
                })
                .catch(function (error) {
                    //
                });
            },
            getApproverOptions: function () {
                let options = [];
                if (this.approvers) {
                    this.approvers.forEach((item) => {
                        if (!item.deleted && item.approved) {
                            options.push({
                                id: item.id,
                                name: item.first_name + ' ' + item.last_name
                            });
                        }
                    });
                }

                return options;
            },
            getConsultantOptions: function () {
                let options = [];
                if (this.consultants) {
                    this.consultants.forEach((item) => {
                        if (!item.deleted && item.approved) {
                            options.push({
                                id: item.id,
                                name: item.first_name + ' ' + item.last_name
                            });
                        }
                    });
                }

                return options;
            }
        }
    }
</script>
