<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="pre-approved-email-domain-add-form-modal-label">Add domain</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form v-if="$root.hasPermission('users_pre_approved_email_domains')" v-on:submit.prevent="onSubmit()">
                    <div class="form-group">
                        <label for="domain-name" id="domain-name-label" class="mandatory-label">
                            Domain name *
                        </label>
                        <input
                            v-model="domain.domain"
                            type="text"
                            id="domain-name"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('domain-name')"
                            v-on:change="$root.unHighlightErrors('domain-name')"
                        >
                        <small class="form-text text-muted">
                            For example gmail.com
                        </small>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="($root.hasPermission('users_pre_approved_email_domains') && !updated)" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-check mr-1"></i> Add
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Add</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                domain: {}
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function () {
                if (!this.$root.hasPermission('users_pre_approved_email_domains')) {
                    this.errors = 'Sorry, you do not have access to this function.';

                    return false;
                }
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.domain = {};
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                setTimeout(function () {
                    $('#domain-name').focus();
                }, 500);
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const data = this.domain;
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id
                    + '/pre-approved-email-domain';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.updated = 'Success';
                    if (response.data.message) {
                        vm.updated = 'Domain added';
                        vm.$parent.getDomains();
                    }

                    setTimeout(function () {
                        $('#pre-approved-email-domain-add-form-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#pre-approved-email-domain-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                let errorHtml = null;
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    errorHtml = value.join('<br>') + '<br>';
                                    vm.errors += errorHtml.replace(/domain/g, 'domain name');
                                    $('#' + key + '-name-label').addClass('text-danger');
                                    $('#' + key + '-name').addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            }
        }
    }
</script>
