<template>
    <div>
        <div v-click-outside="config" class="right-bar scrollable">
            <div>
                <div class="rightbar-title px-3 py-4">
                    <a href="#" class="right-bar-toggle float-right" @click.prevent="hide()">
                        <i class="far fa-times noti-icon" style="font-size: 12px"></i>
                    </a>
                    <h5 class="m-0">Filters</h5>
                </div>

                <hr class="mt-0">

                <div class="pl-3 pr-3 pt-1 pb-3">
                    <form :class="$parent.isFolderView() ? '' : 'd-md-none'" v-on:submit.prevent="keywordSearch()">
                        <div class="form-group keyword">
                            <div class="input-group">
                                <input
                                    v-model="keyword"
                                    type="text"
                                    class="form-control"
                                    id="filter-by-keyword"
                                    :placeholder="'Search ' + ($parent.isArchive() ? 'archived' : 'live') + ' proofs'"
                                    aria-label="Filter by title"
                                >
                                <div class="input-group-append">
                                    <button class="btn btn-primary big-icon" type="submit">
                                        <i class="fal fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <button v-if="keyword" class="btn btn-link clear-keyword" @click.prevent="clearKeyword()">
                                <i class="far fa-times"></i>
                            </button>
                        </div>
                    </form>
                    <div v-if="$root.hasPermission('proofs_view_others')" class="form-group">
                        <label for="filter-by-proof-user">Team member</label>
                        <searchable-option-list
                            v-model="user"
                            :value="user"
                            :options="getUserOptions()"
                            field_id="filter-by-proof-user"
                            search_placeholder="Select team member"
                        ></searchable-option-list>
                    </div>
                    <div class="form-group">
                        <label for="filter-by-can-review">Review status</label>
                        <searchable-option-list
                            v-model="can_review"
                            :value="can_review"
                            :options="can_review_options"
                            field_id="filter-by-can-review"
                        ></searchable-option-list>
                    </div>
                    <div class="form-group date-range">
                        <label for="filter-by-date-range">Date range</label>
                        <date-range-picker
                            v-model="date_range"
                            :opens="'left'"
                            :locale-data="{ format: 'dd/mm/yyyy', firstDay: 1 }"
                            :max-date="new Date()"
                            @toggle="setRightBarStyle"
                            @update="updateDateRange"
                            :autoApply="true"
                        >
                            <template v-slot:input="picker">
                                <span v-html="showDateRange(picker)"></span>
                            </template>
                        </date-range-picker>
                    </div>
                    <div class="form-group">
                        <label>Order proofs by</label>
                        <div class="form-control" style="height: auto">
                            <div class="mb-2">
                                <div class="custom-control custom-radio" style="display: inline-block">
                                    <input
                                        v-model="sort_by"
                                        type="radio"
                                        id="sort-by-created-desc"
                                        class="custom-control-input"
                                        value="created_desc"
                                    >
                                    <label class="custom-control-label" for="sort-by-created-desc">
                                        Newest first
                                    </label>
                                </div>
                            </div>
                            <div class="mb-2">
                                <div class="custom-control custom-radio" style="display: inline-block">
                                    <input
                                        v-model="sort_by"
                                        type="radio"
                                        id="sort-by-created-asc"
                                        class="custom-control-input"
                                        value="created_asc"
                                    >
                                    <label class="custom-control-label" for="sort-by-created-asc">
                                        Oldest first
                                    </label>
                                </div>
                            </div>
                            <div class="mb-2">
                                <div class="custom-control custom-radio" style="display: inline-block">
                                    <input
                                        v-model="sort_by"
                                        type="radio"
                                        id="sort-by-title-asc"
                                        class="custom-control-input"
                                        value="title_asc"
                                    >
                                    <label class="custom-control-label" for="sort-by-title-asc">
                                        Title
                                    </label>
                                </div>
                            </div>
                            <div class="mb-2">
                                <div class="custom-control custom-radio" style="display: inline-block">
                                    <input
                                        v-model="sort_by"
                                        type="radio"
                                        id="sort-by-target-date-desc"
                                        class="custom-control-input"
                                        value="target-date_desc"
                                    >
                                    <label class="custom-control-label" for="sort-by-target-date-desc">
                                        Project target date
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div class="custom-control custom-radio" style="display: inline-block">
                                    <input
                                        v-model="sort_by"
                                        type="radio"
                                        id="sort-by-version-target-date-desc"
                                        class="custom-control-input"
                                        value="version-target-date_desc"
                                    >
                                    <label class="custom-control-label" for="sort-by-version-target-date-desc">
                                        Cycle target date
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="rightbar-overlay"></div>
    </div>
</template>

<script>
    import DateRangePicker from 'vue2-daterange-picker';
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
    export default {
        components: { DateRangePicker },
        props: ['proof_filters', 'proof_users'],
        data() {
            return {
                config: {
                    handler: this.handleRightBarClick,
                    middleware: this.middleware,
                    events: ['click']
                },
                users: [],
                user: null,
                block_user_search: false,
                keyword: '',
                date_range: {
                    startDate: null,
                    endDate: null
                },
                sort_by: 'created_desc',
                block_sort: false,
                can_review: parseInt(
                    this.$cookie.get(this.$root.$data.app.client_id + '_crp' + this.$root.$data.user.id) || 2
                ),
                can_review_options: [
                    {
                        id: 2,
                        name: 'All proofs'
                    },
                    {
                        id: 1,
                        name: 'Proofs awaiting my review'
                    }
                ]
            };
        },
        mounted () {
            this.proof_filters.forEach((item) => {
                if (this.$root.hasPermission('proofs_view_others') && (item.key == 'user')) {
                    //this.block_user_search = true;
                    this.user = item.value;
                }
                if (item.key == 'sort_by') {
                    this.block_sort = true;
                    this.sort_by = item.value;
                }
            });
        },
        watch: {
            proof_filters: function (val) {
                this.updateFilters();
            },
            proof_users: function (val) {
                if (val.length > 0) {
                    let users = [];
                    val.forEach((item) => {
                        users.push(item);
                    });
                    this.users = users;
                }
            },
            user: function (val) {
                if (this.block_user_search) {
                    this.block_user_search = false;

                    return null;
                }
                this.userSearch(val);
            },
            can_review: function (val) {
                if (val === null) {
                    var vm = this;
                    setTimeout(function () {
                        vm.can_review = 2;
                    }, 200);

                    return null;
                }
                this.$root.addFilter('proof_filters', {
                    key: 'can_review',
                    value: val,
                    text: (val == 1) ? 'Proofs awaiting my review' : 'All proofs',
                    hidden: (val == 1) ? false : true,
                    filters_key: 'proof_filters'
                });
                this.$parent.getProofs(1, true);
            },
            sort_by: function (val) {
                if (this.block_sort) {
                    this.block_sort = false;

                    return null;
                }
                let text = 'Title';
                if (val == 'created_desc') {
                    text = 'Newest first';
                }
                if (val == 'created_asc') {
                    text = 'Oldest first';
                }
                if (val == 'target-date_desc') {
                    text = 'Project target date';
                }
                if (val == 'version-target-date_desc') {
                    text = 'Cycle target date';
                }
                this.$root.addFilter('proof_filters', {
                    key: 'sort_by',
                    value: val,
                    text: 'Order proofs by: ' + text,
                    filters_key: 'proof_filters'
                });
                this.$parent.getProofs();
            }
        },
        methods: {
            hide() {
                $('body').toggleClass('right-bar-enabled');
            },
            handleRightBarClick(e, el) {
                $('body').removeClass('right-bar-enabled');
            },
            middleware(event, el) {
                return !event.target.classList.contains('toggle-right');
            },
            updateFilters: function () {
                let userFound = null;
                let canReviewFound = false;
                let dateRangeFound = false;
                let sortByFound = 'created_desc';
                this.proof_filters.forEach((item) => {
                    if (this.$root.hasPermission('proofs_view_others') && (item.key == 'user')) {
                        userFound = item.value;
                    }
                    if (item.key == 'can_review') {
                        canReviewFound = true;
                    }
                    if (item.key == 'date_range') {
                        dateRangeFound = true;
                    }
                    if (item.key == 'sort_by') {
                        sortByFound = item.value;
                    }
                });
                if (this.user != userFound) {
                    //this.block_user_search = true;
                    this.user = userFound;
                }
                if (!canReviewFound) {
                    this.can_review = parseInt(
                        this.$cookie.get(this.$root.$data.app.client_id + '_crp' + this.$root.$data.user.id) || 2
                    );
                }
                if (!dateRangeFound) {
                    this.date_range = {
                        startDate: null,
                        endDate: null
                    };
                }
                this.sort_by = sortByFound;
            },
            keywordSearch: function () {
                const keywordTrimmed = this.keyword.trim();
                if (keywordTrimmed == '') {
                    return null;
                }
                let keywordsAdded = [];
                this.proof_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        keywordsAdded.push(item.value);
                    }
                });
                if (keywordsAdded.indexOf(keywordTrimmed) > -1) {
                    return null;
                }
                this.$root.addFilter('proof_filters', {
                    key: 'keyword',
                    value: keywordTrimmed,
                    text: '&quot;' + keywordTrimmed.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + '&quot;',
                    filters_key: 'proof_filters'
                });
                this.$parent.getProofs();
            },
            clearKeyword: function () {
                this.keyword = '';
                let filters = this.$root.proof_filters;
                for (let itemIndex = (filters.length -1); itemIndex >= 0; itemIndex--) {
                    if (filters[itemIndex].key == 'keyword') {
                        filters.splice(itemIndex, 1);
                    }
                }
                this.$parent.getProofs();
                $('#filter-by-keyword').focus();
            },
            setRightBarStyle: function (datePickerOpen) {
                if (datePickerOpen) {
                    $('.right-bar').removeClass('scrollable');
                } else {
                    $('.right-bar').addClass('scrollable');
                }
            },
            showDateRange: function(picker) {
                const icon = '<i class="fal fa-calendar-alt font-size-18 mr-3" style="position: relative; top: 2px; '
                    + 'opacity: 0.8"></i>';
                if (!picker.startDate) {
                    return icon + 'Select date range';
                }

                return icon + this.formatDate(picker.startDate) + ' - ' + this.formatDate(picker.endDate);
            },
            updateDateRange: function () {
                this.date_range = {
                    startDate: this.formatDate(this.date_range.startDate, 'Y-m-d'),
                    endDate: this.formatDate(this.date_range.endDate, 'Y-m-d')
                }
                this.dateRangeSearch();
            },
            formatDate: function (date, format = 'd/m/Y') {
                const newDate = new Date(date);
                const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
                const day = ('0' + newDate.getDate()).slice(-2);
                if (format == 'd/m/Y') {
                    return day + '/' + month + '/' + newDate.getFullYear();
                }

                return newDate.getFullYear() + '-' + month + '-' + day;
            },
            dateRangeSearch: function () {
                if (this.date_range.startDate == null) {
                    return null;
                }
                this.$root.addFilter('proof_filters', {
                    key: 'date_range',
                    value: this.date_range.startDate + '-' + this.date_range.endDate,
                    text: this.formatDate(this.date_range.startDate) + ' - '
                        + this.formatDate(this.date_range.endDate),
                    filters_key: 'proof_filters'
                });
                
                var vm = this;
                setTimeout(function () {
                    vm.$parent.getProofs();
                }, 200);
            },
            getUserOptions: function () {
                let options = [{
                    id: -1,
                    name: 'All team members'
                }];
                this.users.forEach((item) => {
                    options.push(item);
                });

                return options;
            },
            userSearch: function (userId, oldUserId) {
                if ((userId === null) && (oldUserId !== null)) {
                    this.$root.addFilter('proof_filters', {
                        key: 'user',
                        value: null
                    });
                    this.$parent.getProofs(1, true);

                    return null;
                }
                let user = null;
                const users = this.getUserOptions();
                users.forEach((item) => {
                    if (item.id == userId) {
                        user = item;
                    }
                });
                if (user) {
                    this.$root.addFilter('proof_filters', {
                        key: 'user',
                        value: user.id,
                        text: user.name.replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                        filters_key: 'proof_filters'
                    });
                    this.$parent.getProofs(1, true);

                    return null;
                }
                var vm = this;
                setTimeout(function () {
                    vm.$parent.getProofs(1, true);
                }, 200);
            }
        }
    }
</script>
<style scoped>
    .form-group.keyword {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }

    .form-group.date-range {
        position: relative;
    }
</style>
