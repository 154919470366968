<template>
    <div :class="is_embedded ? '' : 'modal-content'">
        <div v-if="!is_embedded" class="modal-header">
            <h5 class="modal-title" id="template-image-upload-modal-label">Upload images</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClose()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div :class="is_embedded ? '' : 'modal-body'">
            <div v-if="errors" v-html="errors" class="alert alert-danger form-errors" role="alert"></div>
            <div :style="'display: ' + (has_access ? 'block' : 'none')">
                <vue-dropzone
                    ref="templateImageDropzone"
                    :id="'template-image-dropzone' + (is_embedded ? '-embedded' : '')"
                    :options="dropzone_options"
                    v-on:vdropzone-sending="onSendingFile"
                    v-on:vdropzone-upload-progress="onProgressUpdate"
                    v-on:vdropzone-success="onFileUploaded"
                    v-on:vdropzone-error="onUploadError"
                    v-on:vdropzone-drag-over="onDragOver"
                    v-on:vdropzone-drag-leave="onDragLeave"
                    v-on:vdropzone-drop="onDragLeave"
                    v-on:vdropzone-file-added="onFileAdded"
                    :useCustomSlot=true
                >
                    <div v-for="file in uploaded_files" :key="file.id" class="dz-uploaded-file p-2 mb-1">
                        <div v-if="!is_embedded" class="dz-thumbnail">
                            <span v-if="!file.preview_image">
                                <img
                                    :src="$root.imagePlaceholder(file.filename)"
                                    class="img-fluid"
                                    alt=""
                                >
                            </span>
                            <img
                                v-else
                                :src="file.preview_image"
                                class="img-fluid"
                                alt=""
                                v-on:mouseover="viewImageIcon(file.id, true)"
                                v-on:mouseout="viewImageIcon(file.id, false)"
                            >
                            <div
                                v-if="file.preview_image"
                                class="view-image-icon"
                                :id="'view-image-icon' + file.id"
                                v-b-tooltip.hover
                                title="Enlarge"
                                @click="viewImage(file)"
                                v-on:mouseover="viewImageIcon(file.id, true)"
                                v-on:mouseout="viewImageIcon(file.id, false)"
                            >
                                <i class="fal fa-expand-arrows"></i>
                            </div>
                        </div>
                        <div class="dz-filename" :style="is_embedded ? 'width:100%' : ''">{{ file.filename }}</div>
                        <div
                            v-if="!is_embedded || !uploading"
                            class="dz-size"
                            v-html="$root.formatFileSize(file.file_size)"
                        ></div>
                        <div class="dz-remove">
                            <a
                                href="#"
                                v-b-tooltip.hover
                                title="Delete"
                                @click.prevent="removeUploadedFile(file.id)"
                            >
                                <i class="fal fa-trash-alt"></i>
                            </a>
                        </div>
                        <div
                            class="dz-upload-info"
                            :id="'upload-info' + file.id"
                            :style="'left: ' + (is_embedded ? '8px' : '160px')"
                        >
                            <i class="fal fa-clock mr-1"></i> Pending upload
                        </div>
                        <div
                            class="dz-progress"
                            :id="'upload-progress' + file.id"
                            :style="'width: calc(100% - ' + (is_embedded ? '20px' : '172px') + ')'"
                        >
                            <div class="dz-upload"></div>
                        </div>
                    </div>
                    <a
                        href="#"
                        class="dz-add-files"
                        :id="'template-image-add-files' + (is_embedded ? '-embedded' : '')"
                        @click.prevent="doNothing()"
                        :style="dzAddFilesStyle()"
                    >
                        <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                        Click or drop files here <br v-if="is_embedded">to upload
                        <br>Supported types: JPEG, PNG, SVG or PDF
                    </a>
                    <div
                        v-if="is_embedded && can_choose_asset && (this.uploaded_files.length == 0)"
                        class="choose-asset px-4"
                    >
                        <button
                            type="button"
                            class="btn btn-secondary btn-block"
                            @click.prevent="$parent.chooseAsset()"
                        >
                            <i class="far fa-images mr-1"></i>
                            Choose {{ $root.contents.asset_title }}s
                        </button>
                    </div>
                </vue-dropzone>
            </div>
        </div>
        <div v-if="!is_embedded" class="modal-footer">
            <div v-if="uploading" class="progress-display" v-html="uploadProgress()"></div>

            <span class="button-holder" :style="addMoreFilesStyle()">
                <button type="button" class="btn btn-secondary" id="template-image-add-more-files">
                    <i class="far fa-cloud-upload-alt mr-1"></i>
                    Add more files
                </button>
            </span>

            <button
                type="button"
                class="btn btn-primary"
                @click="onSubmit()"
                :disabled="(uploading || (uploaded_files.length == 0))"
            >
                <i class="far fa-check mr-1"></i> Done
            </button>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    export default {
        components: {
            'vue-dropzone': vue2Dropzone
        },
        props: ['is_embedded', 'can_choose_asset'],
        data () {
            return {
                errors: false,
                uploading: false,
                upload_progress_message: false,
                uploaded_files: [],
                upload_progress: {
                    progress: 0,
                    bytes_total: 0,
                    bytes_sent: 0
                },
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    acceptedFiles: 'image/jpeg,image/pjpeg,image/png,image/svg,image/svg+xml,application/pdf',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: true,
                    chunkSize: 6000000,
                    retryChunks: true,
                    retryChunksLimit: 3,
                    maxFilesize: (20 * 1000 * 1000),
                    autoProcessQueue: true,
                    timeout: 0,
                    clickable: this.is_embedded ? '#template-image-add-files-embedded'
                        : '#template-image-add-files, #template-image-add-more-files',
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                caller_component: null,
                has_access: false
            }
        },
        mounted () {
            if (this.is_embedded) {
                this.reset(this.$parent);
            }
        },
        watch: {
            //
        },
        methods: {
            reset: function (component) {
                if (component.component_name == 'template-add-form') {
                    this.has_access = this.$root.hasPermission('templates_upload');
                }
                if (component.component_name == 'manage-images') {
                    this.has_access = this.$root.hasPermission('templates_edit');
                }
                if (component.component_name == 'template-images') {
                    this.has_access = this.$root.hasPermission('templates_use');
                }
                if (!this.has_access) {
                    this.errors = 'Sorry, you do not have access to this function.';

                    return false;
                }
                this.errors = false;
                this.uploading = false;
                this.upload_progress_message = false;
                this.uploaded_files = [];
                this.upload_progress = {
                    progress: 0,
                    bytes_total: 0,
                    bytes_sent: 0
                };
                this.caller_component = component;
                if (this.$refs.templateImageDropzone) {
                    this.$refs.templateImageDropzone.removeAllFiles();
                }
            },
            onSubmit: function () {
                if (this.uploaded_files.length == 0) {
                    this.errors = 'Please add at least 1 file';

                    return null;
                }

                if (this.uploading) {
                    return null;
                }

                let files = [];
                this.uploaded_files.forEach((item) => {
                    files.push({
                        id: item.id,
                        filename: item.filename,
                        file_type: item.file_type,
                        file_size: item.file_size,
                        temp_file: item.temp_file,
                        preview: item.preview_image
                    });
                });

                this.caller_component.addUploadedImages(files);
                $('#template-image-upload-modal').modal('hide');
                this.onClose();
            },
            doNothing: function () {
                return false;
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', file.upload.uuid);
                //formData.append('create_preview', '500x500');
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if ((this.uploaded_files.length == 0) || (progress > 99.9)) {
                    return null;
                }
                let bytesTotal = 0;
                let bytesSentTotal = 0;
                this.uploaded_files.forEach((item) => {
                    bytesTotal += item.file_size;
                    if (item.id == file.upload.uuid) {
                        if (isNaN(bytesSent)) {
                            bytesSent = file.size * (progress / 100);
                        }
                        item.upload.progress = progress;
                        item.upload.bytes_sent = bytesSent;
                        bytesSentTotal += bytesSent;
                        $('#upload-info' + item.id)
                            .html('Uploading ' + this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%');
                        $('#upload-progress' + item.id + ' > div').css('width', progress + '%');
                    } else if (item.upload.progress == 100) {
                        bytesSentTotal += item.file_size;
                    }
                });
                this.upload_progress.progress = ((bytesTotal * bytesSentTotal) > 0)
                    ? ((bytesSentTotal / bytesTotal) * 100) : 0;
                this.upload_progress.bytes_total = bytesTotal;
                this.upload_progress.bytes_sent = bytesSentTotal;
            },
            onFileUploaded: function (file, response) {
                if (this.uploaded_files.length == 0) {
                    return null;
                }
                // Find file.
                let itemIndex = -1;
                this.uploaded_files.forEach((item, index) => {
                    if (item.id == file.upload.uuid) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.uploaded_files[itemIndex].upload.progress = 100;
                    this.uploaded_files[itemIndex].upload.bytes_sent = file.size;
                    $('#upload-progress' + file.upload.uuid + ' > div').css('width', '100%');
                    if (!this.uploaded_files[itemIndex].preview_image) {
                        this.uploaded_files[itemIndex].preview_image = (file.dataURL || null);
                    }
                    $('#upload-info' + file.upload.uuid).html(
                        (this.uploaded_files[itemIndex].preview_image || this.is_embedded) ? 'Finalising upload...'
                        : 'Previewing...'
                    );
                    this.getUploadInfo(this.uploaded_files[itemIndex].id);
                    this.$refs.templateImageDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId;
                if (!this.is_embedded) {
                    url += '/preview/500x500';
                }
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Find file.
                    let itemIndex = -1;
                    vm.uploaded_files.forEach((item, index) => {
                        if (item.id == response.data.file_id) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex < 0) {
                        return null;
                    }
                    vm.uploaded_files[itemIndex].temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.uploaded_files[itemIndex].id).html('');

                    if (response.data.preview) {
                        vm.uploaded_files[itemIndex].preview_image = response.data.preview.src;
                    }
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                let allUploaded = true;
                this.uploaded_files.forEach((item) => {
                    if (!item.temp_file) {
                        allUploaded = false;
                    }
                });
                if (!allUploaded) {
                    return null;
                }
                this.uploading = false;
                this.upload_progress.progress = 0;
                if (!this.is_embedded || this.uploaded_files.length == 0) {
                    return null;
                }
                this.onSubmit();
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    if (!file.accepted) {
                        let error = 'Invalid file type: ' + file.name;
                        if (!this.errors) {
                            error += '<br><br>You can only upload JPEG, PNG, SVG images or PDF documents.';
                            this.errors = error;
                        } else {
                            this.errors = error + '<br>' + this.errors;
                        }
                        this.removeUploadedFile(file.upload.uuid, true);
                        this.$refs.templateImageDropzone.removeFile(file);
                    }
                    if (!this.errors) {
                        this.errors = 'Something went wrong, please try again';
                    }
                }
                console.log(files, message);
            },
            onDragOver: function (event) {
                $('#template-image-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#template-image-dropzone').removeClass('dz-drag-highlight');
            },
            onFileAdded: function (file) {
                if (!this.uploading) {
                    this.errors = false;
                }
                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.templateImageDropzone.removeFile(file);
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: 'Maximum upload size exceeded',
                        text: 'The maximum size of each image you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of ' + file.name + ' is '
                            + this.$root.formatFileSize(file.size) + '.'
                    };
                    $('#message-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                    return null;
                }

                let newFile = {
                    id: file.upload.uuid,
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null)
                };
                this.uploaded_files.push(newFile);
                this.uploading = true;
                setTimeout(function () {
                    $('#upload-info' + file.upload.uuid).html('Preparing upload...');
                }, 500);
            },
            dzAddFilesStyle: function () {
                if (this.uploaded_files.length == 0) {
                    return 'display: block';
                }

                return 'display: none';
            },
            addMoreFilesStyle: function () {
                if (!this.uploading && (this.uploaded_files.length > 0)) {
                    return 'display: block';
                }

                return 'display: none';
            },
            uploadProgress: function () {
                if (this.upload_progress_message) {
                    return this.upload_progress_message;
                }
                const percent = this.upload_progress.progress.toFixed(1);

                return 'Uploading ' + ((percent < 100) ? percent : '100') + '%';
            },
            viewImage: function (file) {
                this.viewImageIcon(file.id, false);

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: null,
                    title: file.filename,
                    text: '<div class="text-center"><img src="' + file.preview_image
                        + '" alt="" class="img-fluid" style="max-height: 500px"></div>'
                };
                $('#message-modal > div').addClass('modal-lg');
                $('#message-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            viewImageIcon: function (fileId, show) {
                if (show) {
                    $('#view-image-icon' + fileId).show();
                } else {
                    $('#view-image-icon' + fileId).hide();
                }
            },
            removeUploadedFile: function(fileId, confirmed = false) {
                let fileIndex = -1;
                this.uploaded_files.forEach((item, index) => {
                    if (item.id == fileId) {
                        fileIndex = index;
                    }
                });
                if (fileIndex > -1) {
                    if (confirmed) {
                        const removeUploadFileId = (this.uploaded_files[fileIndex].upload.progress < 100) ? fileId
                            : null;

                        this.uploaded_files.splice(fileIndex, 1);

                        if (removeUploadFileId) {
                            this.$refs.templateImageDropzone.getUploadingFiles().forEach((file) => {
                                if (file.upload.uuid == removeUploadFileId) {
                                    this.$refs.templateImageDropzone.removeFile(file);
                                }
                            });
                            this.$refs.templateImageDropzone.getQueuedFiles().forEach((file) => {
                                if (file.upload.uuid == removeUploadFileId) {
                                    this.$refs.templateImageDropzone.removeFile(file);
                                }
                            });
                        }
                    
                        return null;
                    }

                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.confirm_delete = {
                        title: 'Delete file',
                        text: 'Are you sure you want to delete: ' + this.uploaded_files[fileIndex].filename + '?',
                        component: this,
                        action: 'delete-file-' + fileId
                    };
                    $('#confirm-delete-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
                }
            },
            confirmDelete: function (action) {
                if (action.indexOf('delete-file-') > -1) {
                    const fileId = action.substr(12);
                    this.removeUploadedFile(fileId, true);
                }
            },
            onClose: function () {
                if (this.caller_component.component_name == 'manage-images') {
                    setTimeout(function () {
                        $('#manage-images-modal').modal('show');
                    }, 500);
                }
            }
        }
    }
</script>
<style scoped>
    #template-image-dropzone {
        min-height: 300px;
    }

    #template-image-dropzone-embedded {
        height: 246px;
    }

    .dz-uploaded-file {
        cursor: default;
    }

    #template-image-add-more-files {
        position: absolute;
        left: 15px;
        bottom: 16px;
    }

    .progress-display {
        position: absolute;
        left: 15px;
        bottom: 10px;
        width: calc(100% - 100px);
    }

    #template-image-add-files {
        padding-top: 118px;
    }

    #template-image-add-files-embedded {
        padding-top: 84px;
    }

    .choose-asset {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 24px;
    }
</style>
