<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="folder-de-archive-modal-label">
                De-archive {{ $root.contents.folder_title }}: {{ folder_name }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="archived" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="archived"></div>
                </div>
            </div>
            <form v-else v-on:submit.prevent="deArchiveFolder()">
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="loading"><loading></loading></div>

                <div v-else>
                    <p>
                        This action de-archives the {{ $root.contents.folder_title }}{{ additionalThings() }}.
                    </p>
                    <p v-if="(assets_archived_count > 0)">
                        If you need to manage {{ $root.contents.asset_title }}s you should do this first.
                    </p>
                </div>
            </form>
        </div>
        <div v-if="!archived" class="modal-footer">
            <span v-if="!archiving">
                <span>
                    <button type="button" class="btn btn-primary mr-2" @click="deArchiveFolder()" :disabled="loading">
                        <i class="far fa-archive mr-1"></i> De-archive {{ $root.contents.folder_title }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary mr-2">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">De-archive {{ $root.contents.folder_title }}</span>
                </button>
            </span>
            <button type="button" class="btn btn-light" data-dismiss="modal" :disabled="archiving">
                <i class="far fa-times mr-1"></i> Cancel
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: false,
                errors: false,
                folder_id: 0,
                folder_name: '',
                subfolders_archived_count: 0,
                assets_archived_count: 0,
                archiving: false,
                archived: false
            }
        },
        mounted () {
            //
        },
        methods: {
            reset: function (folder, subfoldersArchivedCount) {
                this.loading = true;
                this.folder_id = folder.id;
                this.folder_name = folder.name;
                this.subfolders_archived_count = subfoldersArchivedCount;
                this.assets_archived_count = 0;
                this.archiving = false;
                this.archived = false;
                this.getAssetInfo();
            },
            getAssetInfo: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/' + this.folder_id
                    + '/de-archive/resource-info';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.assets_archived_count = response.data.archived;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#folder-de-archive-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            additionalThings: function () {
                let text = '';
                if (this.subfolders_archived_count > 0) {
                    text = ', ' + this.subfolders_archived_count + ' ';
                    if (this.subfolders_archived_count > 1) {
                        text += this.$root.contents.subfolders_title;
                    } else {
                        text += this.$root.contents.subfolder_title;
                    }
                }
                if (this.assets_archived_count > 0) {
                    text += ' with ' + this.assets_archived_count + ' ' + this.$root.contents.asset_title;
                    if (this.assets_archived_count > 1) {
                        text += 's';
                    }
                }

                return text;
            },
            deArchiveFolder: function () {
                this.archiving = true;
                /**
                 * Send de-archive folder request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/' + this.folder_id
                    + '/de-archive';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.archived = response.data.message;
                    vm.$parent.getFolders();
                    vm.$root.folders_changed = true;

                    setTimeout(function () {
                        $('#folder-de-archive-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#folder-de-archive-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.archiving = false;
                });
            }
        }
    }
</script>
