<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="detected-label-exclusion-add-form-modal-label">Add keyword</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form
                    v-if="!limit_reached && $root.hasPermission('assets_keyword_assistant')"
                    v-on:submit.prevent="onSubmit()"
                >
                    <div class="form-group">
                        <label for="dl-label" id="dl-label-label" class="mandatory-label">
                            Keyword *
                        </label>
                        <input
                            v-model="detected_label.label"
                            type="text"
                            id="dl-label"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('dl-label')"
                            v-on:change="$root.unHighlightErrors('dl-label')"
                            maxlength="50"
                            style="text-transform: capitalize"
                        >
                        <small class="form-text text-muted">
                            Allowed characters: <code>A-Z</code>, <code>a-z</code>, <code>0-9</code>,
                            <code>àâèçñó'-_().</code> and space.
                            <br>Maximum length: 50 characters. 
                        </small>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="!limit_reached && $root.hasPermission('assets_keyword_assistant') && !updated" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-check mr-1"></i> Add
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Add</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                detected_label: {},
                limit_reached: false
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function () {
                if (!this.$root.hasPermission('assets_keyword_assistant')) {
                    this.errors = 'Sorry, you do not have access to this function.';

                    return false;
                }
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.detected_label = {};
                this.limit_reached = false;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                // Jira RN-717 - remove limit.
                /*if (this.$parent.items.length >= 100) {
                    this.errors = 'The maximum number of 100 keywords already added to the list. '
                        + 'You need to delete one of them in order to add a new one.';
                    this.limit_reached = true;
                }*/

                setTimeout(function () {
                    $('#dl-label').focus();
                }, 500);
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API.
                 */
                const data = {
                    label: this.detected_label.label,
                    excluded: 1
                };
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/detected-label';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.updated = 'Keyword added to the list.';
                    vm.$parent.getLabels();
                    vm.updating = false;

                    setTimeout(function () {
                        $('#detected-label-exclusion-add-form-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            $('#detected-label-exclusion-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.status == 406) {
                            if (error.response.data.detected_label.excluded) {
                                vm.errors = 'This keyword is already added to the list.';
                                vm.updating = false;

                                return null;
                            }
                            vm.excludeLabel(error.response.data.detected_label);

                            return null;
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                let errorHtml = null;
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    errorHtml = value.join('<br>') + '<br>';
                                    vm.errors += errorHtml.replace(/label/g, 'keyword')
                                        .replace(/format is invalid/g, 'contains disallowed characters');
                                    $('#dl-' + key + '-label').addClass('text-danger');
                                    $('#dl-' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                    if (!vm.errors) {
                        vm.errors = 'Something went wrong, please try again';
                    }
                    vm.updating = false;
                });
            },
            excludeLabel: function (label) {
                label.excluded = 1;
                /**
                 * Send request to API.
                 */
                const data = label;
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/detected-label/'
                    + label.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.updated = 'Keyword added to the list.';
                    vm.$parent.getLabels();

                    setTimeout(function () {
                        $('#detected-label-exclusion-add-form-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            }
        }
    }
</script>
<style scoped>
    code {
        font-size: 0.8rem;
    }
</style>
