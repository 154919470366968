<template>
    <div>
        <div class="assets-container">
            <div class="assets-grid">
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="(!errors && !loading && (total_items == 0))" class="alert alert-warning" role="alert">
                    <span v-if="(!getStatus() || (getStatus() == 'Awaiting approval'))">
                        There are no {{ $root.contents.asset_title }}s to approve
                    </span>
                    <span v-if="(getStatus() == 'Approved')">
                        There are no approved {{ $root.contents.asset_title }}s
                    </span>
                    <span v-if="(getStatus() == 'Declined')">
                        There are no declined {{ $root.contents.asset_title }}s
                    </span>
                </div>
                <div v-if="(total_items > 0)" class="card">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-centered table-hover">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">
                                            <div class="row">
                                                <div class="col-sm-3 col-12 pb-sm-0 pb-1">
                                                    Submitted on
                                                </div>
                                                <div class="col-sm-5 col-12 pb-sm-0 pb-1">
                                                    Submitted by
                                                </div>
                                                <div class="col-sm-3 col-12">
                                                    Status
                                                </div>
                                                <div class="col-sm-1 d-none d-sm-block">&nbsp;</div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in items" :key="item.id">
                                        <td scope="row" :id="'approval-row' + item.id" class="pb-0">
                                            <div class="row">
                                                <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                                    {{ formatDate(item.created_at) }}
                                                </div>
                                                <div class="col-5 d-none d-sm-block" style="padding-top: 3px">
                                                    <span v-if="hasDetails(item.id)">
                                                        <h5 class="font-size-14 mb-1">
                                                            <a
                                                                href="#"
                                                                class="text-dark"
                                                                @click.prevent="toggleViewApproval(item)"
                                                            >
                                                                {{ approval_details[item.id].name }}
                                                            </a>
                                                        </h5>
                                                        <p class="text-muted mb-0">
                                                            <i class="far fa-envelope mr-1"></i>
                                                            {{ approval_details[item.id].email }}
                                                            <br>
                                                            <i class="far fa-phone mr-1"></i>
                                                            {{ approval_details[item.id].telephone }}
                                                        </p>
                                                    </span>
                                                    <span v-else><loading :align="'left'" :size="1"></loading></span>
                                                </div>
                                                <div class="col-3 d-none d-sm-block" style="padding-top: 2px">
                                                    <span
                                                        v-if="item.declined"
                                                        class="badge badge-danger font-size-12"
                                                    >Declined</span>
                                                    <span v-if="item.approved" style="margin-top: 1px">
                                                        Approved
                                                        <div v-if="approvalExpired(item.id)">
                                                            <span class="badge badge-danger font-size-12">
                                                                Expired on {{ formatDate(item.expires_at) }}
                                                            </span>
                                                        </div>
                                                        <div v-else>
                                                            <span class="badge badge-info font-size-12">
                                                                Expires on {{ formatDate(item.expires_at) }}
                                                            </span>
                                                        </div>
                                                    </span>
                                                    <span
                                                        v-if="(!item.approved && !item.declined)"
                                                        class="badge badge-warning font-size-12"
                                                    >Awaiting approval</span>
                                                </div>
                                                <div class="col-10 d-sm-none">
                                                    <p class="mb-1">{{ formatDate(item.created_at) }}</p>
                                                    <span v-if="hasDetails(item.id)">
                                                        <h5 class="font-size-14 mb-1">
                                                            <a
                                                                href="#"
                                                                class="text-dark"
                                                                @click.prevent="toggleViewApproval(item)"
                                                            >
                                                                {{ approval_details[item.id].name }}
                                                            </a>
                                                        </h5>
                                                        <p class="text-muted mb-1">
                                                            <i class="far fa-envelope mr-1"></i>
                                                            {{ approval_details[item.id].email }}
                                                            <br>
                                                            <i class="far fa-phone mr-1"></i>
                                                            {{ approval_details[item.id].telephone }}
                                                        </p>
                                                    </span>
                                                    <p v-else class="mb-1"><loading :align="'left'" :size="1"></loading></p>
                                                    <p class="mb-0">
                                                        <span
                                                            v-if="item.declined"
                                                            class="badge badge-danger font-size-12"
                                                        >Declined</span>
                                                        <span v-if="item.approved">
                                                            Approved
                                                            <div v-if="approvalExpired(item.id)">
                                                                <span class="badge badge-danger font-size-12">
                                                                    Expired on {{ formatDate(item.expires_at) }}
                                                                </span>
                                                            </div>
                                                            <div v-else>
                                                                <span class="badge badge-info font-size-12">
                                                                    Expires on {{ formatDate(item.expires_at) }}
                                                                </span>
                                                            </div>
                                                        </span>
                                                        <span
                                                            v-if="(!item.approved && !item.declined)"
                                                            class="badge badge-warning font-size-12"
                                                        >Awaiting approval</span>
                                                    </p>
                                                </div>
                                                <div class="col-sm-1 col-2 font-size-18 contact-links">
                                                    <a
                                                        href="#"
                                                        class="float-right"
                                                        @click.prevent="toggleViewApproval(item)"
                                                    >
                                                        <span
                                                            v-if="(approval_details_open.indexOf(item.id) < 0)"
                                                            :key="'close-' + item.id"
                                                        >
                                                            <i class="fa-regular fa-chevron-circle-down"></i>
                                                        </span>
                                                        <span v-else :key="'open-' + item.id">
                                                            <i class="fa-regular fa-chevron-circle-up"></i>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="row list-details-td mt-2">
                                                <div class="col-12 list-details" :id="'approval-details' + item.id">
                                                    <asset-approval-details
                                                        :data="item"
                                                        :details="approval_details[item.id]"
                                                    ></asset-approval-details>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <asset-details></asset-details>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <div
            class="modal fade"
            id="decline-asset-approval-request-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="decline-asset-approval-request-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <decline-asset-approval-request></decline-asset-approval-request>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'asset-approvals',
                loading: false,
                errors: false,
                items: [],
                total_items: 0,
                current_page: 1,
                last_page: 1,
                approval_details_open: [],
                approval_details: {},
                view_asset: false,
                view_asset_tab: this.$root.client.asset_details_default_tab,
                view_asset_from_selection: false,
                container_height: 0,
                scroll_top: 0
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            $('body').removeClass('login-body');
            
            this.$root.asset_approval_filters = [];
            if (!this.$root.hasPermission('assets_approval_system')) {
                this.errors = 'Sorry, you do not have access to this function.';

                return false;
            }
            this.getApprovals();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            view_asset: function (val) {
                if (val) {
                    this.showAssetDetails(val);

                    return null;
                }
                this.hideAssetDetails();
            }
        },
        methods: {
            getApprovals: function (page = 1) {
                if (this.loading || (page < 1) || ((page > 1) && (page <= this.current_page))) {
                    return false;
                }

                let filters = [];
                let filteredByStatus = false;
                this.$root.asset_approval_filters.forEach((item, index) => {
                    if (item.key == 'status') {
                        filteredByStatus = true;
                        if (item.value == 'Awaiting approval') {
                            filters.push('!approved');
                            filters.push('!declined');
                        } else {
                            filters.push(item.value.toLowerCase());
                        }
                    }
                    if ((item.key == 'expired') || (item.key == '!expired')) {
                        filters.push(item.key);
                    }
                    if (item.key == 'keyword') {
                        filters.push('kw::' + btoa(item.value));
                    }
                });
                if (!filteredByStatus) {
                    filters.push('!approved');
                    filters.push('!declined');
                    this.$root.addFilter('asset_approval_filters', {
                        key: 'status',
                        value: 'Awaiting approval',
                        text: 'Awaiting approval',
                        filters_key: 'asset_approval_filters'
                    }, true);
                }
                this.current_page = page;
                this.loading = true;
                this.errors = false;
                const filtersString = (filters.length > 0) ? '/' + filters.join('|') : '';

                if (page == 1) {
                    this.items = [];
                    this.approval_details = {};
                    this.total_items = 0;
                    this.current_page = 1;
                    this.last_page = 1;
                    this.approval_details_open = [];
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource-access-requests' + filtersString + '?page=' + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.total_items = response.data.requests.total;
                    vm.current_page = response.data.requests.current_page;
                    vm.last_page = response.data.requests.last_page;
                    if (response.data.requests.data.length == 0) {
                        vm.loading = false;
                    } else {
                        response.data.requests.data.forEach((item, index) => {
                            vm.items.push(item);
                            vm.getApprovalDetails(item);
                        });
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                    vm.loading = false;
                })
                .finally(() => {
                    //vm.loading = false;
                });
            },
            getApprovalDetails: function (item) {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource-access-request/' + item.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.approval_details[response.data.request.id] = {
                        expired: response.data.request.expired,
                        asset: response.data.request.resource,
                        name: response.data.request.name,
                        email: response.data.request.email,
                        telephone: response.data.request.telephone,
                    }
                    if (response.data.request.approval_info) {
                        vm.approval_details[response.data.request.id].approval_info =
                            response.data.request.approval_info;
                    }

                    let allLoaded = true;
                    vm.items.forEach((item) => {
                        if (!vm.hasDetails(item.id)) {
                            allLoaded = false;
                        }
                    });
                    if (allLoaded) {
                        this.loading = false;
                    }
                })
                .catch(function (error) {
                    //
                })
                .finally(() => {
                    //
                });
            },
            formatDate: function (rawDate) {
                const date = rawDate.substr(0, 10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            handleScroll(event) {
                if (this.view_asset/* && (this.current_page > 1)*/) {
                    return null;
                }
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < 200) {
                        this.getApprovals(this.current_page + 1);
                    }
                }
            },
            toggleViewApproval: function (approval) {
                if (this.approval_details_open.indexOf(approval.id) < 0) {
                    this.approval_details_open.push(approval.id);
                    $('#approval-row' + approval.id).addClass('highlighted');
                    $('#approval-details' + approval.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#approval-details' + approval.id).slideUp('slow', function () {
                        $('#approval-row' + approval.id).removeClass('highlighted');
                        for (var n = 0; n < vm.approval_details_open.length; n++) { 
                            if (vm.approval_details_open[n] == approval.id) { 
                                vm.approval_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            hasDetails: function (itemId) {
                if (itemId in this.approval_details) {
                    return true;
                }

                return false;
            },
            approvalExpired: function (itemId) {
                if (!this.hasDetails(itemId)) {
                    return false;
                }

                return this.approval_details[itemId].expired
            },
            removeApproval: function (approvalId) {
                for (let n = (this.items.length - 1); n >= 0; n--) {
                    if (this.items[n].id == approvalId) {
                        this.items.splice(n, 1);
                        this.total_items--;
                    }
                }
            },
            getStatus: function () {
                let status = null;
                this.$root.asset_approval_filters.forEach((item) => {
                    if (item.key == 'status') {
                        status = item.value;
                    }
                });

                return status;
            },
            getAssets: function () {
                this.view_asset = false;
                this.getApprovals();
            },
            showAssetDetails: function (asset, fade = true) {
                var vm = this;
                const component = this.$root.findComponent(this, 'asset-details');
                component.setAsset(asset, this.view_asset_tab);
                if (!fade) {
                    return null;
                }
                this.scroll_top = $('html, body').scrollTop();
                this.container_height = $('.assets-container').outerHeight();
                $('.assets-container').css('min-height', this.container_height);
                $('.assets-grid').fadeOut(350, function () {
                    $('.page-topnav').hide();
                    $('.status-bar').hide();
                    let headerBarHeight = $('.header-bar').outerHeight();
                    if ($('.maintenance-notice').is(":visible")) {
                        headerBarHeight += $('.maintenance-notice').outerHeight() || 0;
                    }
                    const mainNavHeight = $('.main-nav-container').outerHeight();
                    if (vm.scroll_top > headerBarHeight) {
                        $('html, body').scrollTop(headerBarHeight + 1);
                        $('.page-content').css('margin-top', mainNavHeight);
                    } else {
                        $('html, body').scrollTop(0);
                    }
                    $('.asset-details').fadeIn(350, function () {
                        $('.assets-container').css('min-height', 'auto');
                    });
                });
            },
            hideAssetDetails: function () {
                var vm = this;
                $('.asset-details').fadeOut(350, function () {
                    $('.assets-container').css('min-height', vm.container_height);
                    $('html, body').scrollTop(vm.scroll_top);
                    $('.page-topnav').show();
                    $('.status-bar').show();
                    $('.assets-grid').fadeIn(350, function () {
                        const component = vm.$root.findComponent(vm, 'asset-details');
                        component.setAsset(null);
                        $('.assets-container').css('min-height', 'auto');
                    });
                });
            }
        }
    }
</script>
