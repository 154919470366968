<template>
    <div style="position: relative; left: -5px; margin-top: 1px">
        <div class="filter keyword">
            <form v-on:submit.prevent="keywordSearch()">
                <div class="keyword-field">
                    <div class="input-group">
                        <input
                            v-model="keyword"
                            type="text"
                            class="form-control"
                            id="filter-by-keyword"
                            placeholder="Keyword search"
                            aria-label="Keyword search"
                            :disabled="isProfileGroupsLoading()"
                        >
                        <div class="input-group-append">
                            <button class="btn btn-primary big-icon" type="submit" :disabled="isProfileGroupsLoading()">
                                <i class="fal fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <button
                        v-if="keyword && !isProfileGroupsLoading()"
                        class="btn btn-link clear-keyword"
                        @click.prevent="clearKeyword()"
                    >
                        <i class="far fa-times"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['profile_group_filters'],
        data() {
            return {
                keyword: ''
            };
        },
        mounted () {
            this.profile_group_filters.forEach((item) => {
                if (item.key == 'keyword') {
                    this.keyword = item.value;
                }
            });
        },
        watch: {
            //
        },
        methods: {
            keywordSearch: function () {
                const keywordTrimmed = this.keyword.trim();
                
                let keywordsRemoved = false;
                let filters = [];
                this.$root.profile_group_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        keywordsRemoved = true;
                    } else {
                        filters.push(item);
                    }
                });
                this.$root.profile_group_filters = filters;

                if (!keywordTrimmed) {
                    if (keywordsRemoved) {
                        this.getProfileGroups();
                    }

                    return null;
                }
                this.$root.addFilter('profile_group_filters', {
                    key: 'keyword',
                    value: keywordTrimmed,
                    text: '&quot;' + keywordTrimmed.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + '&quot;',
                    filters_key: 'profile_group_filters'
                });
                this.getProfileGroups();
            },
            clearKeyword: function () {
                this.keyword = '';
                this.keywordSearch();
                $('#filter-by-keyword').focus();
            },
            getProfileGroups: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'profile-groups');
                if (component) {
                    component.getProfileGroups();
                }
            },
            isProfileGroupsLoading: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'profile-groups');
                if (!component) {
                    return true;
                }

                return component.loading;
            }
        }
    }
</script>
<style scoped>
    .filter {
        display: inline-block;
        width: 200px;
        position: relative;
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .filter.keyword {
        width: 300px;
    }

    .keyword-field {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }

    @media (max-width: 575px) {
        .filter, .filter.keyword {
            width: 100%;
            margin-right: 0;
        }
    }
</style>
