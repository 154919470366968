<template>
    <div class="table-responsive">
        <table class="table table-centered table-hover">
            <thead class="thead-light">
                <tr>
                    <th scope="col">
                        <div class="row">
                            <div class="col-3">Keyword</div>
                            <div class="col-8">Supporting keywords</div>
                            <div class="col-1">&nbsp;</div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td scope="row" :id="'keyword-suggestion-row' + item.id" class="pb-0">
                        <div class="row">
                            <div class="col-3" style="padding-top: 3px">
                                <h5 class="font-size-14 mb-0">
                                    <a href="#" class="text-dark" @click.prevent="toggleEditKeywordSuggestion(item)">
                                        {{ item.keyword }}
                                    </a>
                                </h5>
                            </div>
                            <div class="col-8" style="padding-top: 2px">
                                <span
                                    v-for="sk_item in getSupportingKeywords(item.supporting_keywords)"
                                    :key="sk_item.id"
                                    class="badge badge-info font-size-12 mr-1 mb-1"
                                >
                                    {{ sk_item.keyword }}
                                </span>
                            </div>
                            <div class="col-1 font-size-18 contact-links">
                                <a
                                    href="#"
                                    class="float-right"
                                    @click.prevent="toggleEditKeywordSuggestion(item)"
                                >
                                    <span
                                        v-if="(keyword_suggestion_details_open.indexOf(item.id) < 0)"
                                        :key="'close-' + item.id"
                                    >
                                        <i class="fa-regular fa-chevron-circle-down"></i>
                                    </span>
                                    <span v-else :key="'open-' + item.id">
                                        <i class="fa-regular fa-chevron-circle-up"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="row list-details-td mt-2">
                            <div class="col-12 list-details" :id="'keyword-suggestion-details' + item.id">
                                <keyword-suggestion-edit-form
                                    :data="item"
                                ></keyword-suggestion-edit-form>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: ['ks_items'],
        data () {
            return {
                items: [],
                keyword_suggestion_details_open: []
            }
        },
        mounted () {
            this.items = this.ks_items;
        },
        watch: {
            ks_items: function (val) {
                this.keyword_suggestion_details_open = [];
                this.items = val;
            }
        },
        methods: {
            toggleEditKeywordSuggestion: function (keyword_suggestion) {
                if (this.keyword_suggestion_details_open.indexOf(keyword_suggestion.id) < 0) {
                    this.keyword_suggestion_details_open.push(keyword_suggestion.id);
                    $('#keyword-suggestion-row' + keyword_suggestion.id).addClass('highlighted');
                    $('#keyword-suggestion-details' + keyword_suggestion.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#keyword-suggestion-details' + keyword_suggestion.id).slideUp('slow', function () {
                        $('#keyword-suggestion-row' + keyword_suggestion.id).removeClass('highlighted');
                        for (var n = 0; n < vm.keyword_suggestion_details_open.length; n++) { 
                            if (vm.keyword_suggestion_details_open[n] == keyword_suggestion.id) { 
                                vm.keyword_suggestion_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            getSupportingKeywords: function (supportingKeywords) {
                if (supportingKeywords) {
                    let supportingKeywordsArray = [];
                    supportingKeywords.split(',').forEach((item, index) => {
                        supportingKeywordsArray.push({
                            id: index,
                            keyword: item
                        });
                    });

                    return supportingKeywordsArray;
                }

                return [];
            }
        }
    }
</script>
