<template>
    <div>
        <div v-if="updated" class="alert alert-success" role="alert">
            <div class="row">
                <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                <div class="col" v-html="updated"></div>
            </div>
        </div>

        <form v-else v-on:submit.prevent="onSubmit()">
            <div v-if="errors" v-html="errors" class="alert alert-danger form-errors" role="alert"></div>

            <div class="form-group">
                <vue-dropzone
                    ref="replaceTemplateDropzone"
                    id="replace-template-dropzone"
                    :options="dropzone_options"
                    v-on:vdropzone-sending="onSendingFile"
                    v-on:vdropzone-upload-progress="onProgressUpdate"
                    v-on:vdropzone-success="onFileUploaded"
                    v-on:vdropzone-error="onUploadError"
                    v-on:vdropzone-drag-over="onDragOver"
                    v-on:vdropzone-drag-leave="onDragLeave"
                    v-on:vdropzone-drop="onDragLeave"
                    v-on:vdropzone-file-added="onFileAdded"
                    :useCustomSlot=true
                >
                    <div v-if="file" class="dz-uploaded-file p-2 mb-1">
                        <div class="dz-thumbnail">
                            <span v-if="!file.preview_image">
                                <img
                                    :src="$root.imagePlaceholder(file.filename)"
                                    class="img-fluid"
                                    alt=""
                                >
                            </span>
                            <img
                                v-else
                                :src="file.preview_image"
                                class="img-fluid"
                                alt=""
                                v-on:mouseover="viewImageIcon(file.id, true)"
                                v-on:mouseout="viewImageIcon(file.id, false)"
                            >
                            <div
                                v-if="file.preview_image"
                                class="view-image-icon"
                                :id="'view-image-icon' + file.id"
                                v-b-tooltip.hover
                                title="Enlarge"
                                @click="viewImage(file)"
                                v-on:mouseover="viewImageIcon(file.id, true)"
                                v-on:mouseout="viewImageIcon(file.id, false)"
                            >
                                <i class="fal fa-expand-arrows"></i>
                            </div>
                        </div>
                        <div :class="('dz-filename' + xlgClass())">{{ file.filename }}</div>
                        <div class="dz-size" v-html="$root.formatFileSize(file.file_size)"></div>
                        <div class="dz-remove">
                            <a
                                v-if="!updating"
                                href="#"
                                v-b-tooltip.hover
                                title="Delete"
                                @click.prevent="removeUploadedFile()"
                            >
                                <i class="fal fa-trash-alt"></i>
                            </a>
                            <span v-if="updating"><i class="fal fa-trash-alt"></i></span>
                        </div>
                        <div
                            :class="('dz-upload-info' + xlgClass())"
                            :id="'upload-info' + file.id"
                        >
                            <!--<i class="fal fa-clock mr-1"></i> Pending upload-->
                        </div>
                        <div
                            :class="('dz-progress' + xlgClass())"
                            :id="'upload-progress' + file.id"
                        >
                            <div class="dz-upload"></div>
                        </div>
                    </div>
                    <a
                        href="#"
                        class="dz-add-files px-3"
                        id="replace-template-add-files"
                        @click.prevent="doNothing()"
                        :style="dzAddFilesStyle()"
                    >
                        <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                        Click or drop file here to upload. Must be a PDF document containing editable fields.
                    </a>
                </vue-dropzone>
            </div>
            <div class="form-group">
                <button
                    v-if="!updating"
                    type="submit"
                    class="btn btn-primary mr-2"
                    :disabled="(!processed || errors)"
                >
                    <i class="far fa-retweet-alt mr-1"></i> Replace
                </button>
                <span v-else>
                    <button type="button" class="btn btn-primary mr-2">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">Replace</span>
                    </button>
                </span>
                <button type="button" class="btn btn-light" @click="onCancel()" :disabled="(uploading || updating)">
                    <i class="far fa-times mr-1"></i> Cancel
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    export default {
        props: ['data', 'template'],
        components: {
            'vue-dropzone': vue2Dropzone
        },
        data () {
            return {
                errors: false,
                uploading: false,
                updating: false,
                updated: false,
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    acceptedFiles: 'application/pdf',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: true,
                    chunkSize: 6000000,
                    retryChunks: true,
                    retryChunksLimit: 3,
                    maxFilesize: (100 * 1000 * 1000),
                    autoProcessQueue: false,
                    timeout: 0,
                    clickable: '#replace-template-add-files',
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                file: null,
                processed: false,
                template_fields: [],
                template_crop_marks: false
            }
        },
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                if (!this.processed) {
                    this.errors = 'Template not processed.';

                    return null;
                }

                /**
                 * Send request to API.
                 */
                let data = {
                    temp_file: this.file.temp_file,
                    filename: this.file.filename,
                    file_type: this.file.file_type,
                    file_size: this.file.file_size
                };
                if (this.$root.isFeatured('proofs')) {
                    data.app_info = {
                        app_url: this.$root.app.app_url
                    };
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/'
                    + this.data.id + '/replace';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.replaceTemplate();
                })
                .catch(function (error) {
                    vm.updating = false;
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    }
                })
                .finally(() => {
                    if ($('#message-modal').hasClass('show')) {
                        $('#message-modal').modal('hide');
                    }
                });
            },
            replaceTemplate: function () {
                let fields = [];
                if (this.template_fields.length > 0) {
                    if (this.template_fields[0].number_of_fields > 0) {
                        this.template_fields.forEach((pageFields, pageNum) => {
                            if (pageNum > 0) {
                                pageFields.forEach((field, sortOrder) => {
                                    let fieldData = {
                                        type: field.type,
                                        title: field.title,
                                        page_number: pageNum,
                                        sort_order: sortOrder
                                    };
                                    if (field.type == 'T') {
                                        fieldData.styles = field.styles;
                                        fieldData.repeatable = field.repeatable;
                                        fieldData.group_id = field.group_id;
                                    } else {
                                        fieldData.profile_images = field.profile_images || false;
                                        fieldData.user_images = field.user_images || false;
                                        fieldData.image_editing = field.image_editing || false;
                                    }
                                    fields.push(fieldData);
                                });
                            }
                        });
                    }
                }
                /**
                 * Send request to API.
                 */
                const data = {
                    file_id: this.file.id,
                    crop_marks: this.template_crop_marks,
                    editable: true,
                    fields: fields,
                    fields_managed: !this.hasMultipleTemplateFields(),
                    images_managed: !this.hasTemplateImageFields()
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.template.id + '/replace';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = vm.$root.ucfirst(vm.$root.contents.template_title) + ' replaced';

                    // Close after 2 seconds.
                    setTimeout(function () {
                        vm.onCancel();
                        // Reload assets.
                        vm.resetAsset();

                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    }
                })
                .finally(() => {
                    vm.updating = false;
                    if ($('#message-modal').hasClass('show')) {
                        $('#message-modal').modal('hide');
                    }
                });
            },
            onCancel: function () {
                this.file = null;
                this.errors = false,
                this.uploading = false,
                this.updating = false,
                this.updated = false,
                this.processed = false,
                this.$parent.replacing = false;
            },
            viewImage: function (file) {
                this.viewImageIcon(file.id, false);

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: null,
                    title: file.filename,
                    text: '<div class="text-center"><img src="' + file.preview_image
                        + '" alt="" class="img-fluid" style="max-height: 500px"></div>'
                };
                $('#message-modal > div').addClass('modal-lg');
                $('#message-modal').modal('show');
            },
            viewImageIcon: function (fileId, show) {
                if (show) {
                    $('#view-image-icon' + fileId).show();
                } else {
                    $('#view-image-icon' + fileId).hide();
                }
            },
            doNothing: function () {
                return false;
            },
            dzAddFilesStyle: function () {
                if (!this.file) {
                    return 'display: block';
                }

                return 'display: none';
            },
            onFileAdded: function (file) {
                this.errors = false;
                if (this.file) {
                    this.$refs.replaceTemplateDropzone.removeFile(file);

                    return null;
                }

                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.replaceTemplateDropzone.removeFile(file);
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: 'Maximum upload size exceeded',
                        text: 'The maximum size of the file you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of ' + file.name + ' is '
                            + this.$root.formatFileSize(file.size) + '.'
                    };
                    $('#message-modal').modal('show');

                    return null;
                }

                let newFile = {
                    id: file.upload.uuid,
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null)
                };
                this.file = newFile;
                var vm = this;
                setTimeout(function () {
                    if (vm.file) {
                        vm.$refs.replaceTemplateDropzone.processQueue();
                        vm.uploading = true;
                        $('#upload-info' + vm.file.id).html('Preparing upload...');
                    }
                }, 500);
            },
            onDragOver: function (event) {
                $('#replace-template-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#replace-template-dropzone').removeClass('dz-drag-highlight');
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', file.upload.uuid);
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if (!this.file) {
                    return null;
                }
                if ((progress < 100) && (this.file.id == file.upload.uuid)) {
                    if (isNaN(bytesSent)) {
                        bytesSent = file.size * (progress / 100);
                    }
                    this.file.upload.progress = progress;
                    this.file.upload.bytes_sent = bytesSent;
                    $('#upload-info' + this.file.id)
                        .html('Uploading ' + this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%');
                    $('#upload-progress' + this.file.id + ' > div').css('width', progress + '%');
                }
            },
            onFileUploaded: function (file, response) {
                if (!this.file) {
                    return null;
                }
                if (this.file.id == file.upload.uuid) {
                    this.file.upload.progress = 100;
                    this.file.upload.bytes_sent = file.size;
                    $('#upload-progress' + this.file.id + ' > div').css('width', '100%');
                    if (!this.file.preview_image) {
                        this.file.preview_image = (file.dataURL || null);
                    }
                    $('#upload-info' + this.file.id).html(
                        this.file.preview_image ? 'Finalising upload...' : 'Previewing...'
                    );

                    this.getUploadInfo(this.file.id);
                    this.$refs.replaceTemplateDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId
                    + '/preview/500x500';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.file.id != response.data.file_id) {
                        return null;
                    }
                    vm.file.temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.file.id).html('');

                    if (response.data.preview) {
                        vm.file.preview_image = response.data.preview.src;
                    }

                    vm.processTemplate();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error || 'Something went wrong, pleaase try again.';
                    $('#upload-info' + vm.file.id).hide();
                });
            },
            processTemplate: function () {
                $('#upload-info' + this.file.id).html('Detecting editable fields...');
                /**
                 * Send request to API
                 */
                const data = {
                    id: this.file.id,
                    file: this.file.temp_file
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/process';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.file.id == response.data.file_id) {
                        $('#upload-info' + vm.file.id).hide();
                        if (!response.data.editable) {
                            vm.errors = 'This file does not contain any editable field.';

                            return null;
                        }
                        vm.template_fields = response.data.fields;
                        let hasErrors = false;
                        vm.template_fields.forEach((fields, page) => {
                            if (page > 0) {
                                fields.forEach((field) => {
                                    if ((hasErrors === false) && field.error) {
                                        hasErrors = page;
                                    }
                                    if (field.error) {
                                        hasErrors = true;
                                        if (!vm.errors) {
                                            vm.errors = '';
                                        }
                                        vm.errors += field.error + ' (Page ' + page + ', Field title: ' + field.title + ')<br>';
                                    }
                                });
                            }
                        });
                        if (hasErrors) {
                            return null;
                        }
                        vm.template_crop_marks = response.data.crop_marks || false;
                        vm.processed = true;
                    }
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error || 'Something went wrong, pleaase try again.';
                    vm.file = null;
                })
                .finally(() => {
                    //
                });
            },
            onUploadComplete: function () {
                this.uploading = false;
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    $('#upload-info' + file.upload.uuid).hide();
                    let error = null;
                    if (!file.accepted) {
                        error = 'Invalid file type: ' + file.name + '<br><br>Please choose a PDF document.';
                    }
                    if (!error) {
                        error = 'Something went wrong, please try again';
                    }
                    this.errors = error;
                }
                this.uploading = false;
                console.log(file, message);
            },
            removeUploadedFile: function(confirmed = false) {
                if (confirmed) {
                    const removeUploadFileId = (this.file.upload.progress < 100) ? this.file.id : null;

                    this.file = null;
                    this.errors = false;
                    this.processed = false;

                    if (removeUploadFileId) {
                        this.$refs.replaceTemplateDropzone.getUploadingFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.replaceTemplateDropzone.removeFile(file);
                            }
                        });
                        this.$refs.replaceTemplateDropzone.getQueuedFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.replaceTemplateDropzone.removeFile(file);
                            }
                        });
                    }
                
                    return null;
                }

                this.$parent.$parent.$parent.confirm_delete = {
                    title: 'Delete file',
                    text: 'Are you sure you want to delete: ' + this.file.filename + '?',
                    component: this,
                    action: 'delete-file'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                if (action == 'delete-file') {
                    this.removeUploadedFile(true);
                }
            },
            resetAsset: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/in::'
                    + this.data.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.resources.data[0]) {
                        vm.$parent.setAsset(response.data.resources.data[0]);
                        vm.$parent.reload_assets_on_close = true;
                    }
                })
                .catch(function (error) {
                    vm.$parent.reload_assets_on_close = true;
                    vm.$parent.close();
                });
            },
            xlgClass: function () {
                if (this.$root.is_lg_screen && !this.$root.is_xxl_screen) {
                    return ' xlg';
                }

                return '';
            },
            hasMultipleTemplateFields: function () {
                if (!this.template_fields) {
                    return false;
                }
                let numFields = 0;
                this.template_fields.forEach((fields, page) => {
                    if (page > 0) {
                        //numFields += fields.length;
                        fields.forEach((field) => {
                            let suffix = field.title.substr(-3);
                            if ((field.type == 'T') || (suffix != '_Lx')) {
                                numFields++;
                            }
                        });
                    }
                });

                return (numFields > 1);
            },
            hasTemplateImageFields: function () {
                if (!this.template_fields) {
                    return false;
                }
                let numFields = 0;
                this.template_fields.forEach((fields, page) => {
                    if (page > 0) {
                        fields.forEach((field) => {
                            //let title = field.title.substr(0, field.title.length - 3);
                            let suffix = field.title.substr(-3);
                            if (
                                (field.type == 'I')
                                && ((suffix != '_Lx')/* || (this.locked_profile_fields.indexOf('I' + title) < 0)*/)
                            ) {
                                numFields++;
                            }
                        });
                    }
                });

                return (numFields > 0);
            }
        }
    }
</script>
<style scoped>
    #replace-template-dropzone {
        min-height: 126px;
    }

    #replace-template-add-files {
        padding-top: 36px;
    }

    .dz-uploaded-file {
        cursor: default;
    }

    .dz-uploaded-file .dz-upload-info {
        left: 160px;
    }

    .dz-uploaded-file .dz-progress {
        width: calc(100% - 172px);
    }

    .dz-uploaded-file .dz-filename.xlg {
        height: 82px;
        max-height: 82px;
    }

    .dz-uploaded-file .dz-upload-info.xlg {
        left: 12px;
    }

    .dz-uploaded-file .dz-progress.xlg {
        width: calc(100% - 24px);
    }
</style>
