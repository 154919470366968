<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="price-panel-add-form-modal-label">Add price panel</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <form v-on:submit.prevent="onSubmit()">
                    <button type="submit" class="d-none"></button>
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="row">
                        <div class="col-lg-6 col-12">
                            <div class="form-group">
                                <label>Price panel type</label>
                                <input
                                    v-model="panel_type_name"
                                    type="text"
                                    class="form-control"
                                    :readonly="true"
                                >
                            </div>
                            <div class="form-group">
                                <label for="price-panel-title" id="price-panel-title-label" class="mandatory-label">
                                    Title *
                                </label>
                                <input
                                    v-model="price_panel.title"
                                    type="text"
                                    id="price-panel-title"
                                    class="form-control mandatory-field"
                                    :readonly="updating"
                                    v-on:keyup="$root.unHighlightErrors('price-panel-title')"
                                    v-on:change="$root.unHighlightErrors('price-panel-title')"
                                >
                            </div>
                            <div class="form-group">
                                <label for="price-panel-delivery_days">Delivery days</label>
                                <input
                                    v-model="price_panel.delivery_days"
                                    type="number"
                                    id="price-panel-delivery_days"
                                    class="form-control"
                                    :readonly="updating"
                                    min="0"
                                    max="100"
                                >
                            </div>
                            <div class="form-group">
                                <label for="price-panel-min_order">Minimum order</label>
                                <input
                                    v-model="price_panel.min_order"
                                    type="number"
                                    id="price-panel-min_order"
                                    class="form-control"
                                    :readonly="updating"
                                    min="0"
                                    max="100000"
                                >
                            </div>
                            <div class="form-group">
                                <label for="price-panel-specification">Specification</label>
                                <textarea
                                    v-model="price_panel.specification"
                                    id="price-panel-specification"
                                    class="form-control"
                                    :readonly="updating"
                                    rows="4"
                                ></textarea>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group">
                                <label for="price-panel-supplier_pounds">&pound; supplier</label>
                                <searchable-option-list
                                    v-model="price_panel.supplier_pounds"
                                    :value="price_panel.supplier_pounds"
                                    :options="suppliers"
                                    field_id="price-panel-supplier_pounds"
                                    :readonly="updating"
                                ></searchable-option-list>
                            </div>
                            <div class="form-group">
                                <label for="price-panel-supplier_euros">&euro; supplier</label>
                                <searchable-option-list
                                    v-model="price_panel.supplier_euros"
                                    :value="price_panel.supplier_euros"
                                    :options="suppliers"
                                    field_id="price-panel-supplier_euros"
                                    :readonly="updating"
                                ></searchable-option-list>
                            </div>
                            <div class="form-group">
                                <label for="price-panel-supplier_dollars">&dollar; supplier</label>
                                <searchable-option-list
                                    v-model="price_panel.supplier_dollars"
                                    :value="price_panel.supplier_dollars"
                                    :options="suppliers"
                                    field_id="price-panel-supplier_dollars"
                                    :readonly="updating"
                                ></searchable-option-list>
                            </div>
                            <div class="form-group">
                                <label for="price-panel-max_order">Maximum order</label>
                                <input
                                    v-model="price_panel.max_order"
                                    type="number"
                                    id="price-panel-max_order"
                                    class="form-control"
                                    :readonly="updating"
                                    min="0"
                                    max="100000"
                                >
                            </div>
                            <div class="form-group">
                                <label for="price-panel-notes">Notes</label>
                                <textarea
                                    v-model="price_panel.notes"
                                    id="price-panel-notes"
                                    class="form-control"
                                    :readonly="updating"
                                    rows="4"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <price-panel-form-items
                        v-model="price_panel.items"
                        :value="price_panel.items"
                        :readonly="updating"
                        :add_current_item="updating"
                    ></price-panel-form-items>
                    <hr>
                    <p>Increments - (Increments are only available as an addition to the largest pack size)</p>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label for="price-panel-inc_quantity" class="d-block text-center">Quantity</label>
                                <input
                                    v-model="price_panel.inc_quantity"
                                    type="number"
                                    id="price-panel-inc_quantity"
                                    class="form-control"
                                    :readonly="updating"
                                    min="0"
                                    max="100000"
                                >
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="price-panel-inc_pounds" class="d-block text-center">&pound;</label>
                                <input
                                    v-model="price_panel.inc_pounds"
                                    type="number"
                                    id="price-panel-inc_pounds"
                                    class="form-control"
                                    :readonly="updating"
                                    min="0"
                                    max="100000"
                                    step="0.01"
                                >
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="price-panel-inc_euros" class="d-block text-center">&euro;</label>
                                <input
                                    v-model="price_panel.inc_euros"
                                    type="number"
                                    id="price-panel-inc_euros"
                                    class="form-control"
                                    :readonly="updating"
                                    min="0"
                                    max="100000"
                                    step="0.01"
                                >
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="price-panel-inc_dollars" class="d-block text-center">&dollar;</label>
                                <input
                                    v-model="price_panel.inc_dollars"
                                    type="number"
                                    id="price-panel-inc_dollars"
                                    class="form-control"
                                    :readonly="updating"
                                    min="0"
                                    max="100000"
                                    step="0.01"
                                >
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label
                                    for="price-panel-inc_pounds_pnp"
                                    class="d-block text-center"
                                    style="white-space: nowrap"
                                >&pound; P&amp;P</label>
                                <input
                                    v-model="price_panel.inc_pounds_pnp"
                                    type="number"
                                    id="price-panel-inc_pounds_pnp"
                                    class="form-control"
                                    :readonly="updating"
                                    min="0"
                                    max="100000"
                                    step="0.01"
                                >
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label
                                    for="price-panel-inc_euros_pnp"
                                    class="d-block text-center"
                                    style="white-space: nowrap"
                                >&euro; P&amp;P</label>
                                <input
                                    v-model="price_panel.inc_euros_pnp"
                                    type="number"
                                    id="price-panel-inc_euros_pnp"
                                    class="form-control"
                                    :readonly="updating"
                                    min="0"
                                    max="100000"
                                    step="0.01"
                                >
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label
                                    for="price-panel-inc_dollars_pnp"
                                    class="d-block text-center"
                                    style="white-space: nowrap"
                                >&dollar; P&amp;P</label>
                                <input
                                    v-model="price_panel.inc_dollars_pnp"
                                    type="number"
                                    id="price-panel-inc_dollars_pnp"
                                    class="form-control"
                                    :readonly="updating"
                                    min="0"
                                    max="100000"
                                    step="0.01"
                                >
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="!updated" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button type="submit" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-check mr-1"></i> Add
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Add</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['suppliers'],
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                panel_type_name: '',
                list_component: null,
                price_panel: {
                    items: []
                }
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (panel_type, listComponent = null) {
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.panel_type_name = panel_type.name;
                this.list_component = listComponent;
                this.price_panel = {
                    price_panel_type_id: panel_type.id,
                    items: []
                };
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                setTimeout(function () {
                    $('#price-panel-title').focus();
                }, 500);
            },
            onSubmit: function (delay = true) {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                var vm = this;

                if (delay) {
                    setTimeout(function () {
                        vm.onSubmit(false);
                    }, 500);

                    return null;
                }

                /**
                 * Send request to API
                 */
                const data = this.price_panel;
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/price-panel';

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = 'Success';
                    if (response.data.message) {
                        vm.updated = response.data.message;
                        vm.list_component.getPricePanels();
                    }

                    setTimeout(function () {
                        $('#price-panel-add-form-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#price-panel-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    $('#price-panel-' + key + '-label').addClass('text-danger');
                                    $('#price-panel-' + key).addClass('field-error');
                                }
                            }
                            $('#price-panel-add-form-modal .modal-body').animate({ scrollTop: 0 }, 350);
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            }
        }
    }
</script>
