<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (total_items == 0))" class="alert alert-warning" role="alert">
            There are no notifications at the moment
        </div>
        <div v-if="(total_items > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-1 col-3" style="min-width: 80px">&nbsp;</div>
                                        <div class="col">Notification</div>
                                        <div class="col-sm-2 d-none d-sm-block">Time</div>
                                        <div class="col-sm-2 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row">
                                    <notification-item :item="item"></notification-item>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'notifications',
                loading: false,
                errors: false,
                items: [],
                total_items: 0,
                current_page: 1,
                last_page: 1
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            $('body').removeClass('login-body');
            this.getNotifications();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            total_items: function (val) {
                this.$root.status_info.right = val + ' notification';
                if (val !== 1) {
                    this.$root.status_info.right += 's';
                }
            }
        },
        methods: {
            getNotifications: function (page = 1) {
                if (this.loading || (page < 1) || ((page > 1) && (page <= this.current_page))) {
                    return false;
                }
                this.current_page = page;
                this.loading = true;
                this.errors = false;
                if (page == 1) {
                    this.items = [];
                    this.total_items = 0;
                    this.current_page = 1;
                    this.last_page = 1;
                }

                /**
                 * Send get notifications request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/notifications?page=' + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.total_items = response.data.notifications.total;
                    vm.current_page = response.data.notifications.current_page;
                    vm.last_page = response.data.notifications.last_page;
                    response.data.notifications.data.forEach((item) => {
                        vm.items.push(item);
                    });
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            handleScroll(event) {
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < 200) {
                        this.getNotifications(this.current_page + 1);
                    }
                }
            },
            clearNotification: function (notification) {
                const component = this.$root.findComponent(this.$parent, 'main-nav');
                if (component) {
                    component.clearNotification(notification.type, notification.id);
                    /*let itemIndex = -1;
                    this.items.forEach((item, index) => {
                        if (item.id == notification.id) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex > -1) {
                        console.log('delete notification');
                        this.items.splice(itemIndex, 1);
                    }*/
                }
            },
            markNotificationAsRead: function (notification) {
                const component = this.$root.findComponent(this.$parent, 'main-nav');
                if (component) {
                    component.markNotificationAsRead(notification.type, notification.id);

                    let itemIndex = -1;
                    this.items.forEach((item, index) => {
                        if (item.id == notification.id) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex > -1) {
                        this.items[itemIndex].read = 1;
                    }
                }
            }
        }
    }
</script>
