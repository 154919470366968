import { render, staticRenderFns } from "./FlyteFilePreview.vue?vue&type=template&id=62befa8a&scoped=true"
import script from "./FlyteFilePreview.vue?vue&type=script&lang=js"
export * from "./FlyteFilePreview.vue?vue&type=script&lang=js"
import style0 from "./FlyteFilePreview.vue?vue&type=style&index=0&id=62befa8a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62befa8a",
  null
  
)

export default component.exports