<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="chase-artwork-approval-request-modal-label">
                Chase order {{ print_order ? print_order.id : '' }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="sent" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>
            <div v-else>
                <form>
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div v-if="print_order" class="table-responsive mb-0">
                        <table class="table table-sm">
                            <tbody>
                                <tr>
                                    <td style="border-top: none">
                                        Order date:
                                        <span v-html="order_details ? order_details.created_at_formatted : ' '"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{ $root.ucfirst($root.contents.asset_title) }}:
                                        <span v-html="print_order.resource_title"></span>
                                    </td>
                                </tr>
                                <tr v-if="showArtworkTitle()">
                                    <td>Your title: <span v-html="order_details.artwork_title"></span></td>
                                </tr>
                                <tr>
                                    <td>
                                        Quantity:
                                        <span v-html="print_order.quantity"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Cost:
                                        <span v-if="(print_order.currency == 'pound')">
                                            &pound;{{ print_order.total_price.toFixed(2) }}
                                        </span>
                                        <span v-if="(print_order.currency == 'euro')">
                                            &euro;{{ print_order.total_price.toFixed(2) }}
                                        </span>
                                        <span v-if="(print_order.currency == 'dollar')">
                                            &dollar;{{ print_order.total_price.toFixed(2) }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Additional instructions:
                                        <span v-html="order_details ? order_details.comments_html : ' '"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="form-group">
                        <label for="last_chased_comments" id="last_chased_comments-label" class="mandatory-label">
                            Message
                        </label>
                        <textarea
                            v-model="last_chased_comments"
                            id="last_chased_comments"
                            class="form-control mandatory-field"
                            :readonly="sending"
                            rows="4"
                            v-on:keyup="$root.unHighlightErrors('last_chased_comments')"
                            v-on:change="$root.unHighlightErrors('last_chased_comments')"
                        ></textarea>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="!sent" class="modal-footer">
            <span v-if="!sending">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-paper-plane mr-1"></i> Send
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Send</span>
                </button>
            </span>
            <button type="button" class="btn btn-light ml-2" data-dismiss="modal" :disabled="sending">
                <i class="far fa-times mr-1"></i> Cancel
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                last_chased_comments: null,
                print_order: null,
                order_details: null
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (data, orderDetails) {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.last_chased_comments = null;
                this.print_order = data;
                this.order_details = orderDetails;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
            },
            onSubmit: function () {
                this.errors = false;
                this.sending = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send decline print order request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order/'
                    + this.print_order.id + '/chase';
                const data = {
                    comments: this.last_chased_comments,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.sent = 'Your order and message have been sent for approval again';

                    // Close after 4 seconds automatically.
                    setTimeout(function () {
                        $('#chase-print-order-modal').modal('hide');
                    }, 4000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#' + key + '-label').addClass('text-danger');
                                $('#' + key).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            },
            showArtworkTitle: function () {
                if (!this.order_details) {
                    return false;
                }
                if (this.order_details.artwork_title && (this.order_details.artwork_created_by == this.$root.user.id)) {
                    return true;
                }

                return false;
            }
        }
    }
</script>
