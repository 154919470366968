<template>
    <div>
        <div v-click-outside="config" class="right-bar scrollable">
            <div>
                <div class="rightbar-title px-3 py-4">
                    <a href="#" class="right-bar-toggle float-right" @click.prevent="hide()">
                        <i class="far fa-times noti-icon" style="font-size: 12px"></i>
                    </a>
                    <h5 class="m-0">Filters</h5>
                </div>

                <hr class="mt-0">

                <div class="pl-3 pr-3 pt-1 pb-3">
                    <div class="form-group">
                        <label for="filter-by-report">Report</label>
                        <searchable-option-list
                            v-model="report"
                            :value="report"
                            :options="report_options"
                            field_id="filter-by-report"
                            search_placeholder="Select a report"
                            :readonly="$parent.loading"
                        ></searchable-option-list>
                    </div>
                    <div v-if="report == 'templates'" class="form-group">
                        <label for="filter-by-type">Type</label>
                        <searchable-option-list
                            v-model="type"
                            :value="type"
                            :options="type_options"
                            field_id="filter-by-type"
                            search_placeholder="Select a type"
                            :readonly="$parent.loading"
                        ></searchable-option-list>
                    </div>
                    <div class="form-group">
                        <label for="filter-by-period">Period</label>
                        <searchable-option-list
                            v-model="period"
                            :value="period"
                            :options="period_options"
                            field_id="filter-by-period"
                            search_placeholder="Select a period"
                            :readonly="$parent.loading"
                        ></searchable-option-list>
                    </div>
                    <div class="form-group date-range" :style="'display: ' + ((period == 'custom') ? 'block' : 'none')">
                        <label for="filter-by-date-range">Date range</label>
                        <date-range-picker
                            v-model="date_range"
                            :opens="'left'"
                            :locale-data="{ format: 'dd/mm/yyyy', firstDay: 1 }"
                            :max-date="new Date()"
                            @toggle="setRightBarStyle"
                            @update="updateDateRange"
                            :autoApply="true"
                        >
                            <template v-slot:input="picker">
                                <span v-html="showDateRange(picker)"></span>
                            </template>
                        </date-range-picker>
                    </div>
                    <form v-if="report == 'templates'" v-on:submit.prevent="keywordSearch()">
                        <label for="filter-by-keyword">Search {{ $root.contents.template_title }}</label>
                        <div class="form-group keyword">
                            <div class="input-group">
                                <input
                                    v-model="keyword"
                                    type="text"
                                    class="form-control"
                                    id="filter-by-keyword"
                                    :placeholder="'Enter ' + $root.contents.template_title + ' No. or title'"
                                    aria-label="Filter by keyword"
                                    :disabled="$parent.loading"
                                    autocomplete="off"
                                    v-on:keyup="keywordSearch(true)"
                                    v-on:focus="keyword = ''"
                                >
                                <div class="input-group-append">
                                    <button
                                        class="btn btn-primary big-icon"
                                        type="submit"
                                        :disabled="$parent.loading"
                                    ><i class="fal fa-search"></i></button>
                                </div>
                            </div>
                            <button
                                v-if="keyword && !$parent.loading"
                                class="btn btn-link clear-keyword"
                                @click.prevent="clearKeyword()"
                            >
                                <i class="far fa-times"></i>
                            </button>
                            <div v-if="keyword" class="dropdown">
                                <div
                                    class="dropdown-menu asset-results"
                                    id="asset-results"
                                    style="width: 270px; max-height: 210px; overflow-x: hidden; overflow-y: auto"
                                >
                                    <div v-if="searching"><loading size="1"></loading></div>
                                    <div v-if="searched && (assets.length == 0)" class="dropdown-item text-center">
                                        No results found
                                    </div>
                                    <a
                                        v-for="item in assets"
                                        :key="item.id"
                                        href="#"
                                        class="dropdown-item"
                                        style="white-space: normal"
                                        @click.prevent="selectAsset(item)"
                                    >{{ item.id }} {{ item.title }}</a>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div v-if="report == 'templates'" class="form-group">
                        <label for="filter-by-folder">{{ $root.ucfirst($root.contents.folder_title) }}</label>
                        <searchable-option-list
                            v-model="folder"
                            :value="folder"
                            :options="folders"
                            field_id="filter-by-folder"
                            :search_placeholder="'Select ' + $root.contents.folder_title"
                            :readonly="$parent.loading"
                            v-on:sol-dropdown="cfDropdown"
                        ></searchable-option-list>
                    </div>
                    <div v-if="(report == 'templates') && $root.isFeatured('profiles')" class="form-group">
                        <label for="filter-by-profile-group">
                            {{ $root.ucfirst($root.contents.profile_group_title) }}
                        </label>
                        <searchable-option-list
                            v-model="profile_group"
                            :value="profile_group"
                            :options="profile_groups"
                            :multiple="true"
                            field_id="filter-by-profile-group"
                            field_class="form-control"
                            placeholder="You can choose more than one"
                            :readonly="$parent.loading"
                            v-on:sol-dropdown="cfDropdown"
                        ></searchable-option-list>
                    </div>
                    <div v-if="(report != 'overview') && (user_groups.length > 0)" class="form-group">
                        <label for="filter-by-user-group">
                            User group
                        </label>
                        <searchable-option-list
                            v-model="user_group"
                            :value="user_group"
                            :options="user_groups"
                            :multiple="true"
                            field_id="filter-by-user-group"
                            field_class="form-control"
                            placeholder="You can choose more than one"
                            :readonly="$parent.loading"
                            v-on:sol-dropdown="cfDropdown"
                        ></searchable-option-list>
                    </div>
                </div>
            </div>
        </div>

        <div class="rightbar-overlay"></div>
    </div>
</template>

<script>
    import DateRangePicker from 'vue2-daterange-picker';
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
    export default {
        components: { DateRangePicker },
        props: ['report_filters', 'current_report'],
        data() {
            return {
                config: {
                    handler: this.handleRightBarClick,
                    middleware: this.middleware,
                    events: ['click']
                },
                report_options: [],
                report: null,
                type_options: [],
                type: null,
                period_options: [
                    {
                        id: 'week',
                        name: 'Week'
                    },
                    {
                        id: 'month',
                        name: 'Month'
                    },
                    {
                        id: 'quarter',
                        name: 'Quarter'
                    },
                    {
                        id: 'year',
                        name: 'Year'
                    },
                    {
                        id: 'custom',
                        name: 'Custom date range'
                    }
                ],
                period: 'month',
                date_range: {
                    startDate: null,
                    endDate: null
                },
                keyword: '',
                keypress_time: 0,
                searching: false,
                searched: false,
                assets: [],
                folders: [],
                folder: null,
                profile_groups: [],
                profile_group: [],
                user_groups: [],
                user_group: []
            };
        },
        mounted () {
            $(document).on('click', function () {
                $('#asset-results').hide();
            });
            if (this.current_report) {
                this.report = this.current_report;
                if (this.current_report == 'templates') {
                    this.type = 'all';
                }
            }
            if (this.$root.hasPermission('reports_overview')) {
                this.report_options.push({
                    id: 'overview',
                    name: 'System overview'
                });
            }
            if (this.$root.hasPermission('reports_users')) {
                this.report_options.push({
                    id: 'registrations',
                    name: 'Registrations'
                });
                this.report_options.push({
                    id: 'visits',
                    name: 'Visits'
                });
            }
            if (this.$root.hasPermission('reports_assets')) {
                this.report_options.push({
                    id: 'asset-uploads',
                    name: 'Uploads'
                });
                this.report_options.push({
                    id: 'asset-downloads',
                    name: 'Downloads'
                });
            }
            if (this.$root.hasPermission('reports_templates')) {
                this.report_options.push({
                    id: 'artwork-created',
                    name: this.$root.ucfirst(this.$root.contents.template_title) + 's created'
                });
                this.report_options.push({
                    id: 'templates',
                    name: this.$root.ucfirst(this.$root.contents.template_title) + 's'
                });
            }
            if (this.$root.hasPermission('reports_print_orders')) {
                this.report_options.push({
                    id: 'orders',
                    name: 'Orders'
                });
            }
            if (this.$root.hasPermission('reports_flyte')) {
                this.report_options.push({
                    id: 'flytes-sent',
                    name: 'Flyte activity'
                });
            }
            this.type_options.push({
                id: 'all',
                name: 'All'
            });
            if (this.$root.isFeatured('printing')) {
                this.type_options.push({
                    id: 'orders',
                    name: 'Orders'
                });
            }
            this.type_options.push({
                id: 'downloads',
                name: 'Downloads'
            });
            this.type_options.push({
                id: 'shares',
                name: 'Emails'
            });
        },
        watch: {
            report_filters: function (val) {
                this.updateFilters();
            },
            current_report: function (val) {
                if (val) {
                    this.report = val;
                    this.type = (val == 'templates') ? 'all' : null;
                    if (val != 'templates') {
                        this.keyword = '';
                        this.assets = [];
                        this.folder = null;
                        this.profile_group = [];
                        let filters = this.$root.report_filters;
                        const keys = ['type', 'id', 'report_folder', 'report_pg'];
                        for (let filterIndex = (filters.length - 1); filterIndex >= 0; filterIndex--) {
                            if (keys.indexOf(filters[filterIndex].key) > -1) {
                                filters.splice(filterIndex, 1);
                            }
                        }
                    }
                    if (val == 'overview') {
                        this.user_group = [];
                        let filters = this.$root.report_filters;
                        for (let filterIndex = (filters.length - 1); filterIndex >= 0; filterIndex--) {
                            if (filters[filterIndex].key == 'report_ug') {
                                filters.splice(filterIndex, 1);
                            }
                        }
                    }
                    if ((val == 'templates') && (this.folders.length == 0)) {
                        this.getFolders();
                    }
                    if ((val == 'templates') && this.$root.isFeatured('profiles') && (this.profile_groups.length == 0)) {
                        this.getProfileGroups();
                    }
                    if ((val != 'overview') && (this.user_groups.length == 0)) {
                        this.getUserGroups();
                    }
                    this.updateFilters();
                }
            },
            report: function (val) {
                if (!val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.report = vm.report_options[0].id;
                    }, 200);

                    return null;
                }
                if (val != this.current_report) {
                    this.$parent.overview = 'registrations';
                    this.$router.push({ name: 'reports-' + val });
                }
            },
            type: function (val) {
                if (!val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.type = 'all';
                    }, 200);

                    return null;
                }

                let typeFound = null;
                this.report_filters.forEach((item) => {
                    if (item.key == 'type') {
                        typeFound = item.value;
                    }
                });
                if (typeFound == val) {
                    return null;
                }

                this.$root.addFilter('report_filters', {
                    key: 'type',
                    value: val,
                    text: (val == 'shares') ? 'Emails' : this.$root.ucfirst(val),
                    filters_key: 'report_filters'
                });

                var vm = this;
                setTimeout(function () {
                    vm.$parent.overview = 'registrations';
                    vm.$parent.getReport();
                }, 200);
            },
            period: function (val) {
                if (!val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.period = 'month';
                    }, 200);

                    return null;
                }

                let periodFound = null;
                this.report_filters.forEach((item) => {
                    if (item.key == 'period') {
                        periodFound = item.value;
                    }
                });
                if (periodFound == val) {
                    return null;
                }

                this.$root.addFilter('report_filters', {
                    key: 'period',
                    value: val,
                    text: this.$root.ucfirst(val),
                    filters_key: 'report_filters'
                });

                if (val == 'custom') {
                    this.date_range = {
                        startDate: null,
                        endDate: null
                    };
                    setTimeout(function () {
                        $('.reportrange-text').click();
                    }, 500);

                    return null;
                }

                let itemIndex = -1;
                this.$root.report_filters.forEach((item, index) => {
                    if (item.key == 'date_range') {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.$root.report_filters.splice(itemIndex, 1);
                }

                var vm = this;
                setTimeout(function () {
                    vm.$parent.overview = 'registrations';
                    vm.$parent.getReport();
                }, 200);
            },
            folder: function (val) {
                this.$root.addFilter('report_filters', {
                    key: 'report_folder',
                    value: val,
                    text: this.getFolderName(val).replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                    filters_key: 'report_filters'
                });

                var vm = this;
                setTimeout(function () {
                    vm.$parent.overview = 'registrations';
                    vm.$parent.getReport();
                }, 200);
            },
            profile_group: function (val) {
                let filters = this.$root.report_filters;
                let numProfileGroupFilters = 0;
                filters.forEach((item) => {
                    if (item.key == 'report_pg') {
                        numProfileGroupFilters++;
                    }
                });
                if (val.length != numProfileGroupFilters) {
                    // Remove all profile group filters.
                    for (let itemIndex = (filters.length -1); itemIndex >= 0; itemIndex--) {
                        if (filters[itemIndex].key == 'report_pg') {
                            filters.splice(itemIndex, 1);
                        }
                    }
                    // Add selected ones.
                    val.forEach((item) => {
                        this.$root.addFilter('report_filters', {
                            key: 'report_pg',
                            value: item,
                            text: this.getProfileGroupName(item).replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                            filters_key: 'report_filters'
                        });
                    });
                    var vm = this;
                    setTimeout(function () {
                        vm.$parent.overview = 'registrations';
                        vm.$parent.getReport();
                    }, 200);
                }
            },
            user_group: function (val) {
                let filters = this.$root.report_filters;
                let numUserGroupFilters = 0;
                filters.forEach((item) => {
                    if (item.key == 'report_ug') {
                        numUserGroupFilters++;
                    }
                });
                if (val.length != numUserGroupFilters) {
                    // Remove all user group filters.
                    for (let itemIndex = (filters.length -1); itemIndex >= 0; itemIndex--) {
                        if (filters[itemIndex].key == 'report_ug') {
                            filters.splice(itemIndex, 1);
                        }
                    }
                    // Add selected ones.
                    val.forEach((item) => {
                        this.$root.addFilter('report_filters', {
                            key: 'report_ug',
                            value: item,
                            text: this.getUserGroupName(item).replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                            filters_key: 'report_filters'
                        });
                    });
                    var vm = this;
                    setTimeout(function () {
                        vm.$parent.overview = 'registrations';
                        vm.$parent.getReport();
                    }, 200);
                }
            }
        },
        methods: {
            hide() {
                $('body').toggleClass('right-bar-enabled');
            },
            handleRightBarClick(e, el) {
                $('body').removeClass('right-bar-enabled');
            },
            middleware(event, el) {
                return !event.target.classList.contains('toggle-right');
            },
            updateFilters: function () {
                let typeFound = null;
                let periodFound = null;
                let dateRangeFound = false;
                let keywordFound = false;
                let folderFound = null;
                let profileGroupFound = [];
                let userGroupFound = [];
                this.report_filters.forEach((item) => {
                    if ((this.report == 'templates') && (item.key == 'type')) {
                        typeFound = item.value;
                    }
                    if (item.key == 'period') {
                        periodFound = item.value;
                    }
                    if (item.key == 'date_range') {
                        dateRangeFound = item.value;
                    }
                    if ((this.report == 'templates') && (item.key == 'id')) {
                        keywordFound = true;
                    }
                    if ((this.report == 'templates') && (item.key == 'report_folder')) {
                        folderFound = item.value;
                    }
                    if ((this.report == 'templates') && (item.key == 'report_pg')) {
                        profileGroupFound.push(item.value);
                    }
                    if (item.key == 'report_ug') {
                        userGroupFound.push(item.value);
                    }
                });
                this.type = typeFound;
                this.period = periodFound;
                if ((periodFound != 'custom') || !dateRangeFound) {
                    this.date_range = {
                        startDate: null,
                        endDate: null
                    };
                } else {
                    this.date_range = {
                        startDate: dateRangeFound.substring(0, 10),
                        endDate: dateRangeFound.substring(11)
                    };
                }
                if (!keywordFound && this.keyword) {
                    this.keyword = '';
                    this.assets = [];
                }
                this.folder = folderFound;
                this.profile_group = profileGroupFound;
                this.user_group = userGroupFound;
            },
            showDateRange: function(picker) {
                const icon = '<i class="fal fa-calendar-alt font-size-18 mr-3" style="position: relative; top: 2px; '
                    + 'opacity: 0.8"></i>';
                if (!picker.startDate) {
                    return icon + 'Select date range';
                }
                return icon + this.formatDate(picker.startDate) + ' - ' + this.formatDate(picker.endDate);
            },
            updateDateRange: function () {
                this.date_range = {
                    startDate: this.formatDate(this.date_range.startDate, 'Y-m-d'),
                    endDate: this.formatDate(this.date_range.endDate, 'Y-m-d')
                }
                this.dateRangeSearch();
            },
            setRightBarStyle: function (datePickerOpen) {
                if (datePickerOpen) {
                    $('.right-bar').removeClass('scrollable');
                } else {
                    $('.right-bar').addClass('scrollable');
                }
            },
            formatDate: function (date, format = 'd/m/Y') {
                const newDate = new Date(date);
                const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
                const day = ('0' + newDate.getDate()).slice(-2);
                if (format == 'd/m/Y') {
                    return day + '/' + month + '/' + newDate.getFullYear();
                }

                return newDate.getFullYear() + '-' + month + '-' + day;
            },
            dateRangeSearch: function () {
                if (this.date_range.startDate == null) {
                    return null;
                }
                this.$root.addFilter('report_filters', {
                    key: 'date_range',
                    value: this.date_range.startDate + '-' + this.date_range.endDate,
                    text: this.formatDate(this.date_range.startDate) + ' - '
                        + this.formatDate(this.date_range.endDate),
                    filters_key: 'report_filters'
                });
                
                var vm = this;
                setTimeout(function () {
                    vm.$parent.overview = 'registrations';
                    vm.$parent.getReport();
                }, 200);
            },
            keywordSearch: function (delay = false) {
                if (!this.keyword) {
                    this.searching = false;
                    this.searched = false;

                    return null;
                }
                this.searching = true;
                this.assets = [];
                this.searched = false;
                $('#asset-results').show();

                if (delay) {
                    this.keypress_time = new Date();
                    var vm = this;
                    setTimeout(function () {
                        vm.keywordSearch();
                    }, 500);
                }

                const time = new Date();
                if ((time - this.keypress_time) < 500) {
                    return null;
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resources/!archived|template|kw::' + btoa(this.keyword) + '|sb::relevance';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.assets = response.data.resources.data;
                })
                .catch(function (error) {
                    //
                })
                .finally(() => {
                    vm.searching = false;
                    vm.searched = true;
                });
            },
            clearKeyword: function () {
                if (!this.keyword) {
                    return null;
                }
                this.keyword = '';
                this.assets = [];
                this.$root.addFilter('report_filters', {
                    key: 'id',
                    value: null
                });
                this.$parent.overview = 'registrations';
                this.$parent.getReport();
                $('#filter-by-keyword').focus();
            },
            selectAsset: function (item) {
                this.keyword = item.id + ' ' + item.title;
                this.$root.addFilter('report_filters', {
                    key: 'id',
                    value: item.id,
                    text: '&quot;' + this.keyword.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + '&quot;',
                    filters_key: 'report_filters'
                });
                this.$parent.overview = 'registrations';
                this.$parent.getReport();
            },
            getFolders: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/folders/!pagination|!archived|!empty';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folders = response.data.folders;
                })
                .catch(function (error) {
                    //
                });
            },
            getFolderName: function (folderId) {
                if (!folderId) {
                    return '';
                }
                let itemIndex = -1;
                this.folders.forEach((item, index) => {
                    if (item.id == folderId) {
                        itemIndex = index;
                    }
                });

                return this.folders[itemIndex].name;
            },
            getProfileGroups: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-groups/!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.profile_groups = response.data.profile_groups;
                })
                .catch(function (error) {
                    //
                });
            },
            getProfileGroupName: function (profileGroupId) {
                if (!profileGroupId) {
                    return '';
                }
                let itemIndex = -1;
                this.profile_groups.forEach((item, index) => {
                    if (item.id == profileGroupId) {
                        itemIndex = index;
                    }
                });

                return this.profile_groups[itemIndex].name;
            },
            getUserGroups: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/!private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_groups = response.data.user_groups;
                })
                .catch(function (error) {
                    //
                });
            },
            getUserGroupName: function (userGroupId) {
                if (!userGroupId) {
                    return '';
                }
                let itemIndex = -1;
                this.user_groups.forEach((item, index) => {
                    if (item.id == userGroupId) {
                        itemIndex = index;
                    }
                });

                return this.user_groups[itemIndex].name;
            },
            cfDropdown: function (event) {
                if (event.show) {
                    $('.right-bar.scrollable').css('padding-bottom', '150px');
                    $('.right-bar.scrollable').animate({ scrollTop: '+=200' }, 700);

                    return null
                }
                $('.right-bar.scrollable').css('padding-bottom', '0');
            }
        }
    }
</script>
<style scoped>
    .form-group.date-range {
        position: relative;
    }
    
    .form-group.keyword {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }
</style>
