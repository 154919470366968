<template>
    <div class="table-responsive" style="overflow-x: hidden">
        <div v-if="loading"><loading></loading></div>

        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!loading && !errors)" class="row">
            <div class="col-sm-5 col-12">
                <table class="table table-centered table-hover">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col" class="py-2">
                                <div class="row">
                                    <div class="col-12">File{{ (item.files.length == 1) ? '' : 's' }}</div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="file in item.files" :key="file.id">
                            <td scope="row" class="pt-2" style="padding-bottom: 5px">
                                <div class="row">
                                    <div class="col-12" style="padding-top: 1px">
                                        {{ file.filename }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-sm-7 col-12">
                <table class="table table-centered table-hover">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col" class="py-2">
                                <div class="row">
                                    <div class="col-12">Recipient{{ (item.recipients.length == 1) ? '' : 's' }}</div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="recipient in item.recipients" :key="recipient">
                            <td scope="row" class="pt-2" style="padding-bottom: 5px">
                                <div class="row">
                                    <div class="col-12" style="padding-top: 1px">
                                        {{ recipient }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-if="(!loading && !errors && hasDownloads())">
            <table class="table table-centered table-hover">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" class="py-2">
                            <div class="row">
                                <div class="col-sm-5 col-12">File{{ (item.files.length == 1) ? '' : 's' }}</div>
                                <div class="col-sm-5 col-12">
                                    Recipient{{ (item.recipients.length == 1) ? '' : 's' }}
                                </div>
                                <div class="col-sm-2 col-12">Downloaded</div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="file in item.files"
                        :key="file.id"
                        :style="(file.downloads.length > 0) ? '' : 'display: none'"
                    >
                        <td scope="row" class="pt-2" style="padding-bottom: 5px">
                            <div class="row">
                                <div class="col-sm-5 col-12" style="padding-top: 1px">
                                    {{ file.filename }}
                                    <small class="text-muted ml-2">{{ $root.formatFileSize(file.file_size) }}</small>
                                </div>
                                <div class="col-sm-5 col-12" style="padding-top: 1px">
                                    <p
                                        v-for="download in file.downloads"
                                        :key="file.id + '_recipient_' + download.recipient + '_'
                                            + download.downloaded_at_formatted"
                                        class="mb-1"
                                    >
                                        {{ download.recipient }}
                                    </p>
                                </div>
                                <div class="col-sm-2 col-12" style="padding-top: 1px">
                                    <p
                                        v-for="download in file.downloads"
                                        :key="file.id + '_downloaded_' + download.recipient + '_'
                                            + download.downloaded_at_formatted"
                                        class="mb-1"
                                    >
                                        {{ download.downloaded_at_formatted }}
                                    </p>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['share'],
        data () {
            return {
                loading: true,
                errors: false,
                item: null
            }
        },
        mounted () {
            this.item = this.share;
            this.getFlyteSentDetails();
        },
        methods: {
            getFlyteSentDetails: function () {
                /**
                 * Send get flyte sent details request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/report/flyte-sent/'
                    + this.share.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.item = response.data.flyte_sent;
                    
                    let itemIndex = -1;
                    vm.$parent.items.forEach((item, index) => {
                        if (item.id == vm.item.id) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex > -1) {
                        vm.$parent.items[itemIndex].sent_at_formatted = response.data.flyte_sent.sent_at_formatted;
                        vm.$parent.items[itemIndex].files_count = response.data.flyte_sent.files.length + ' file'
                            + ((response.data.flyte_sent.files.length == 1) ? '' : 's');
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            hasDownloads: function () {
                if (!this.item.files) {
                    return null;
                }
                let downloadsCount = 0;
                this.item.files.forEach((file) => {
                    downloadsCount += file.downloads.length;
                });

                return (downloadsCount > 0);
            }
        }
    }
</script>
