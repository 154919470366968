<template>
    <div class="topnav page-topnav" style="padding: 0 12px; max-height: none; overflow-y: visible">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand topnav-menu">
                <div class="navbar-collapse align-items-center justify-content-between">
                    <ul v-if="(user_groups.length > 0)" class="navbar-nav">
                        <li class="nav-item">
                            <h6 class="contact-links pr-2">
                                <i class="fal fa-user-friends font-size-16 mr-1"></i>
                            </h6>
                        </li>
                        <li class="nav-item" style="min-width: 200px; padding-top: 8px">
                            <div class="btn-group btn-block" style="height: 36.5px">
                                <button
                                    type="button"
                                    class="btn btn-light dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    style="text-align: left"
                                >
                                    {{ userGroupName() }}
                                    <i
                                        class="fas fa-chevron-down"
                                        style="font-size: 9px; position: absolute; top: 14px; right: 10px"
                                    ></i>
                                </button>
                                <div class="dropdown-menu">
                                    <a
                                        v-for="option in getUserGroupOptions()"
                                        class="dropdown-item"
                                        :key="option.id"
                                        href="#"
                                        @click.prevent="user_group = option.id"
                                    >{{ option.name }}</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul v-else class="navbar-nav">
                        <li class="nav-item">
                            <h6 class="contact-links">
                                &nbsp;
                            </h6>
                        </li>
                    </ul>
                    <ul class="navbar-nav">
                        <li class="nav-item" style="height: 36.5px">
                            <div style="position: absolute; height: 36.5px; right: 0">
                                <button type="button" class="btn btn-primary" @click="finishEditing()">
                                    <i class="far fa-check mr-1"></i> Finish editing
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                user_groups: [],
                user_group: -1
            }
        },
        mounted () {
            this.getUserGroups();
        },
        watch: {
            user_group: function (val) {
                this.$root.edit_homepage_user_group = val;
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'home');
                component.getPanels();
            }
        },
        methods: {
            getUserGroups: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/!private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_groups = response.data.user_groups;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getUserGroupOptions: function () {
                let options = [{
                    id: -1,
                    name: 'None'
                }];
                if (this.user_groups) {
                    this.user_groups.forEach((item) => {
                        options.push({
                            id: item.id,
                            name: item.name
                        });
                    });
                }

                return options;
            },
            userGroupName: function () {
                if ((this.user_group < 1) || (this.user_groups.length < 1)) {
                    return 'Select a user group';
                }
                let itemIndex = -1;
                this.user_groups.forEach((item, index) => {
                    if (item.id == this.user_group) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    return this.user_groups[itemIndex].name;
                }

                return 'None';
            },
            finishEditing: function () {
                this.$root.editing_homepage = false;
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'home');
                component.getPanels();
            }
        }
    }
</script>
