<template>
    <div class="card asset-preview">
        <div class="card-body p-0">
            <div class="mt-3 ml-3 mr-3">
                <div style="margin-bottom: 7px">
                    <div class="title text-dark font-size-15" :id="'proof-folder-name' + id" @click="viewFolder()">
                        {{ short_title }}
                    </div>

                    <b-tooltip
                        v-if="(short_title != title)"
                        :target="'proof-folder-name' + id"
                        triggers="hover"
                    >
                        {{ title }}
                    </b-tooltip>
                </div>
                <div class="row no-gutters" style="margin-bottom: 8px">
                    <div class="col pr-2" style="height: 113px">
                        <div
                            v-if="(data.proofs_in_folder.length > 0)"
                            class="thumbnail"
                            style="cursor: pointer"
                            @click="viewFolder()"
                            v-b-tooltip.hover
                            title="View proofs in this folder"
                        >
                            <img
                                :id="'preview-image1' + id"
                                :src="(image_src1 ? image_src1 : default_src)"
                                :alt="title"
                            >
                        </div>
                    </div>
                    <div class="col pr-2" style="height: 113px">
                        <div
                            v-if="(data.proofs_in_folder.length > 1)"
                            class="thumbnail"
                            style="cursor: pointer"
                            @click="viewFolder()"
                            v-b-tooltip.hover
                            title="View proofs in this folder"
                        >
                            <img
                                :id="'preview-image2' + id"
                                :src="(image_src2 ? image_src2 : default_src)"
                                :alt="title"
                            >
                        </div>
                    </div>
                    <div class="col pr-2" style="height: 113px">
                        <div
                            v-if="(data.proofs_in_folder.length > 2)"
                            class="thumbnail"
                            style="cursor: pointer"
                            @click="viewFolder()"
                            v-b-tooltip.hover
                            title="View proofs in this folder"
                        >
                            <img
                                :id="'preview-image3' + id"
                                :src="(image_src3 ? image_src3 : default_src)"
                                :alt="title"
                            >
                        </div>
                    </div>
                    <div class="col" style="height: 113px">
                        <div
                            v-if="(data.proofs_in_folder.length > 3)"
                            class="thumbnail"
                            style="cursor: pointer"
                            @click="viewFolder()"
                            v-b-tooltip.hover
                            title="View proofs in this folder"
                        >
                            <img
                                :id="'preview-image4' + id"
                                :src="(image_src4 ? image_src4 : default_src)"
                                :alt="title"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <hr class="m-0">
            <div class="mx-3" style="margin-top: 12px; margin-bottom: 12px; min-height: 25px">
                <span class="float-right">
                    <a href="#" @click.prevent="viewFolder()">
                        <span
                            class="number-of-proofs bg-third text-third text-center"
                            v-b-tooltip.hover
                            :title="itemsCount()"
                        >
                            {{ data.proofs_in_folder.length }}
                        </span>
                    </a>
                    <span class="contact-links font-size-18">
                        <a href="#" @click.prevent="viewFolder()">
                            <span v-b-tooltip.hover title="View proofs in this folder">
                                <i class="far fa-folder"></i>
                            </span>
                        </a>
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data () {
            return {
                id: 0,
                default_src: '/images/file-icon.png',
                image_src1: null,
                image_src2: null,
                image_src3: null,
                image_src4: null,
                title: '',
                short_title: ''
            }
        },
        mounted () {
            this.updateData(this.data);
            var vm = this;
            $(window).resize(function () {
                vm.short_title = vm.title;
                setTimeout(function () {
                    vm.fitTitle();
                }, 500);
            });
        },
        watch: {
            data: function (val) {
                if (val) {
                    this.updateData(val);
                }
            }
        },
        methods: {
            updateData: function (data) {
                if (data) {
                    this.id = data.proof_folder_id;
                    this.title = data.name;
                    this.short_title = data.name;
                    this.getPreviewImages();
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                }
            },
            getPreviewImages: function () {
                var vm = this;
                this.data.proofs_in_folder.forEach((item, index) => {
                    if (index < 4) {
                        setTimeout(function () {
                            vm.getPreviewImage(item);
                        }, (index * 500) + 50);
                    }
                });
            },
            getPreviewImage: function (proofId) {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + proofId + '/preview';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        if (!vm.image_src1) {
                            vm.image_src1 = response.data.image_src;
                            if (vm.image_src1.substr(0, 5) == 'data:') {
                                $('#preview-image1' + vm.id).addClass('image');
                            }

                            return null;
                        }
                        if (!vm.image_src2) {
                            vm.image_src2 = response.data.image_src;
                            if (vm.image_src2.substr(0, 5) == 'data:') {
                                $('#preview-image2' + vm.id).addClass('image');
                            }

                            return null;
                        }
                        if (!vm.image_src3) {
                            vm.image_src3 = response.data.image_src;
                            if (vm.image_src3.substr(0, 5) == 'data:') {
                                $('#preview-image3' + vm.id).addClass('image');
                            }

                            return null;
                        }
                        if (!vm.image_src4) {
                            vm.image_src4 = response.data.image_src;
                            if (vm.image_src4.substr(0, 5) == 'data:') {
                                $('#preview-image4' + vm.id).addClass('image');
                            }
                        }
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            fitTitle: function () {
                if (!$('#proof-folder-name' + this.id)[0]) {
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 200);

                    return null;
                }
                if ($('#proof-folder-name' + this.id)[0].scrollHeight > 45) {
                    let words = this.short_title.split(' ');
                    words.pop();
                    if (words.length > 0) {
                        this.short_title = words.join(' ') + '...';
                        var vm = this;
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 200);
                    }
                }
            },
            viewFolder: function () {
                let filters = [];
                this.$root.proof_filters.forEach((item) => {
                    if ((item.key == 'archived') || (item.key == 'user') || (item.key == 'sort_by')) {
                        filters.push(item);
                    }
                });
                this.$root.proof_filters = filters;
                this.$root.addFilter('proof_filters', {
                    key: 'folder',
                    value: this.id,
                    text: this.title.replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                    filters_key: 'proof_filters'
                });
                this.$parent.getProofs();
            },
            itemsCount: function () {
                const itemsTotal = this.data.proofs_in_folder.length;
                let text = itemsTotal + ' proof';
                if (itemsTotal != 1) {
                    text += 's';
                }

                return text;
            }
        }
    }
</script>
<style scoped>
    .asset-preview .thumbnail {
        height: 110px;
        overflow: hidden;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
    }

    .asset-preview .thumbnail img {
        max-height: 108px;
    }

    .asset-preview .card-body .title {
        height: 38px;
        line-height: 18px;
        font-weight: 500;
        cursor: pointer;
        overflow-wrap: anywhere;
    }

    .number-of-proofs {
        display: inline-block;
        min-width: 25px;
        height: 25px;
        padding: 3px;
        border-radius: 50%;
        position: relative;
        top: -3px;
        line-height: 1.6;
    }
</style>
