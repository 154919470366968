<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="price-panel-view-modal-label">Prices</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body" style="min-height: 300px">
            <loading v-if="loading"></loading>

            <div v-if="loading_errors" v-html="loading_errors" class="alert alert-danger" role="alert"></div>

            <div v-if="(!loading && !loading_errors)">
                <div v-if="price_panels.length > 1" class="form-group">
                    <searchable-option-list
                        v-model="price_panel_id"
                        :value="price_panel_id"
                        :options="price_panels"
                        field_id="view-price-panel"
                        search_placeholder="Select option"
                    ></searchable-option-list>
                </div>
                <div v-if="price_panel_id">
                    <p v-if="(price_panel.min_order || price_panel.max_order)">
                        <span v-if="price_panel.min_order" class="mr-3">
                            Minimum quantity: {{ price_panel.min_order }}
                        </span>
                        <span v-if="price_panel.max_order">Maximum quantity: {{ price_panel.max_order }}</span>
                    </p>
                    <div class="table-responsive mb-3">
                        <table class="table table-centered table-sm">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col" class="text-center">Quantity</th>
                                    <th scope="col" class="text-center">
                                        <span v-if="(getCurrency() == 'pound')">&pound;</span>
                                        <span v-if="(getCurrency() == 'euro')">&euro;</span>
                                        <span v-if="(getCurrency() == 'dollar')">&dollar;</span>
                                    </th>
                                    <th scope="col" class="text-center">
                                        <span v-if="(getCurrency() == 'pound')">&pound;</span>
                                        <span v-if="(getCurrency() == 'euro')">&euro;</span>
                                        <span v-if="(getCurrency() == 'dollar')">&dollar;</span>
                                        P&P
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in getPrices()" :key="item.id">
                                    <td scope="row" class="text-center">
                                        {{ item.quantity }}
                                    </td>
                                    <td scope="row" class="text-center">
                                        {{ (item.price > 0) ? item.price.toFixed(2) : '0.00' }}
                                    </td>
                                    <td scope="row" class="text-center">
                                        {{ (item.price_pnp > 0) ? item.price_pnp.toFixed(2) : '0.00' }}
                                    </td>
                                </tr>
                                <tr v-if="hasIncrements()">
                                    <td scope="row" colspan="3" class="bg-light pl-2">
                                        Increments (above max pack size)
                                    </td>
                                </tr>
                                <tr v-if="hasIncrements()">
                                    <td scope="row" class="text-center">{{ price_panel.inc_quantity }}</td>
                                    <td scope="row" class="text-center">
                                        {{ (price_panel['inc_' + getCurrency() + 's'] !== null)
                                            ? parseFloat(price_panel['inc_' + getCurrency() + 's']).toFixed(2)
                                            : '0.00' }}
                                    </td>
                                    <td scope="row" class="text-center">
                                        {{ (price_panel['inc_' + getCurrency() + 's_pnp'] !== null)
                                            ? parseFloat(price_panel['inc_' + getCurrency() + 's_pnp'])
                                                .toFixed(2)
                                            : '0.00' }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr v-if="price_panel.specification || price_panel.notes">
                    <div v-if="price_panel.specification" :class="price_panel.notes ? 'mb-3' : ''">
                        <label>Specification</label>
                        <div v-html="price_panel.specification_html"></div>
                    </div>
                    <div v-if="price_panel.notes">
                        <label>Notes</label>
                        <div v-html="price_panel.notes_html"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading_errors: false,
                loading: true,
                price_panel_ids: [],
                price_panels: [],
                price_panel_id: null,
                price_panel: null
            }
        },
        mounted () {
            //
        },
        watch: {
            price_panel_id: function (val) {
                let pricePanel = null;
                this.price_panels.forEach((item) => {
                    if (item.id == val) {
                        pricePanel = item;
                    }
                });
                this.price_panel = pricePanel;
            }
        },
        methods: {
            reset: function (pricePanelIds) {
                this.loading_errors = false;
                this.loading = true;
                this.price_panel_ids = pricePanelIds;
                this.price_panel_id = null;
                this.price_panel = null;
                this.price_panels = [];
                pricePanelIds.forEach((pricePanelId) => {
                    this.getPricePanel(pricePanelId);
                });
            },
            getPricePanel: function (pricePanelId) {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/price-panel/' + pricePanelId;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let pricePanel = response.data.price_panel;
                    pricePanel.name = pricePanel.title;
                    if (!pricePanel.specification_html) {
                        pricePanel.specification_html = '&nbsp;';
                    }
                    if (!pricePanel.items) {
                        vm.loading_errors = 'No prices available.';
                        vm.loading = false;

                        return null;
                    }
                    if (!vm.getCurrency(pricePanel)) {
                        vm.loading_errors = 'The price panel has been incorrectly set for this item. Please contact your'
                            + ' administrator using the Support link at the top of this screen.';
                        vm.loading = false;
                        
                        return null;
                    }
                    vm.price_panels.push(pricePanel);
                    if (vm.price_panels.length < vm.price_panel_ids.length) {
                        return null;
                    }
                    // Order by title.
                    for (let i = 0; i < vm.price_panels.length - 1; i++) {
                        for (let j = i + 1; j < vm.price_panels.length; j++) {
                            if (vm.price_panels[j].title < vm.price_panels[i].title) {
                                let temp = vm.price_panels[i];
                                vm.price_panels[i] = vm.price_panels[j];
                                vm.price_panels[j] = temp;
                            }
                        }
                    }

                    if (vm.price_panels.length == 1) {
                        vm.price_panel_id = vm.price_panels[0].id;
                    }
                    setTimeout(function () {
                        vm.loading = false;
                    },200);
                })
                .catch(function (error) {
                    vm.loading = false;
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#price-panel-view-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.status == 404) {
                        vm.loading_errors = 'A price panel has not been assigned to this item. Please contact your'
                            + ' administrator using the Support link at the top of this screen.';

                        return null;
                    }
                    vm.loading_errors = error.response.data.error || 'Something went wrong, please try again';
                });
            },
            getCurrency: function (pricePanel = null) {
                if (!pricePanel) {
                    pricePanel = this.price_panel;
                }
                if (!pricePanel) {
                    return null;
                }
                let poundsOk = false;
                let eurosOk = false;
                let dollarsOk = false;
                let currency = null;
                pricePanel.items.forEach((item) => {
                    if (item.quantity) {
                        if (item['price_pounds'] !== null) {
                            poundsOk = true;
                        }
                        if (item['price_euros'] !== null) {
                            eurosOk = true;
                        }
                        if (item['price_dollars'] !== null) {
                            dollarsOk = true;
                        }
                    }
                });
                if (poundsOk && pricePanel['supplier_pounds_name']) {
                    currency = 'pound';
                }
                if (
                    (!currency || this.$root.user.currency != currency)
                    && eurosOk
                    && pricePanel['supplier_euros_name']
                ) {
                    currency = 'euro';
                }
                if (
                    (!currency || this.$root.user.currency != currency)
                    && dollarsOk
                    && pricePanel['supplier_dollars_name']
                ) {
                    currency = 'dollar';
                }

                return currency;
            },
            getPrices: function () {
                let prices = [];
                this.price_panel.items.forEach((item) => {
                    if (item.quantity && (item['price_' + this.getCurrency() + 's'] !== null)) {
                        prices.push({
                            id: item.id,
                            quantity: item.quantity,
                            price: parseFloat(item['price_' + this.getCurrency() + 's']),
                            price_pnp: parseFloat(item['price_' + this.getCurrency() + 's_pnp'])
                        });
                    }
                });
                if (prices.length == 0) {
                    return null;
                }

                return prices;
            },
            hasIncrements: function () {
                return (this.price_panel.inc_quantity && (this.price_panel['inc_' + this.getCurrency() + 's'] !== null));
            }
        }
    }
</script>
