<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="print-order-copy-recipient-add-form-modal-label">
                Add recipient
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <form v-on:submit.prevent="keywordSearch()">
                <div class="form-group keyword">
                    <div class="input-group">
                        <input
                            v-model="keyword"
                            type="text"
                            class="form-control"
                            id="print-order-copy-recipient-user-keyword"
                            placeholder="Search user name or email"
                            aria-label="Search user name or email"
                            autocomplete="off"
                            v-on:keyup="keywordSearch(true)"
                        >
                        <div class="input-group-append">
                            <button class="btn btn-primary big-icon" type="submit">
                                <i class="fal fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <button v-if="keyword" class="btn btn-link clear-keyword" @click.prevent="clearKeyword()">
                        <i class="far fa-times"></i>
                    </button>
                </div>
            </form>

            <div v-if="errors" class="alert alert-danger" role="alert" v-html="errors"></div>

            <div v-else class="form-control" style="height: 200px; overflow-y: auto">
                <loading v-if="searching"></loading>

                <div v-else>
                    <p v-if="(users.length == 0)" class="text-center">
                        <span v-if="searched">No result found</span>
                    </p>

                    <table v-else class="table table-sm">
                        <tbody>
                            <tr
                                scope="row"
                                v-for="user in users"
                                :key="user.id"
                                :id="'print-order-copy-recipient-result-' + user.id"
                                :style="(users_added.indexOf(user.id) > -1) ? '' : 'cursor: pointer'"
                                @click="addUser(user)"
                            >
                                <td class="pl-2">
                                    {{ user.first_name }} {{ user.last_name }}
                                    <p class="small mb-1">{{ user.email }}</p>
                                </td>
                                <td class="pr-2 font-size-18 contact-links">
                                    <span
                                        v-if="(users_added.indexOf(user.id) > -1)"
                                        class="float-right"
                                        v-b-tooltip.hover.left
                                        title="Added"
                                    >
                                        <i class="far fa-check"></i>
                                    </span>
                                    <span v-else>
                                        <span v-if="(user.id in adding)" class="float-right" :key="'adding' + user.id">
                                            <i class="fal fa-spinner-third fa-spin"></i>
                                        </span>
                                        <span
                                            v-else
                                            class="float-right"
                                            :key="'add' + user.id"
                                            v-b-tooltip.hover.left
                                            title="Add"
                                        >
                                            <i class="far fa-user-plus"></i>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                adding: {},
                users_added: [],
                keyword: '',
                searching: false,
                searched: false,
                users: [],
                keypress_time: 0
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function () {
                this.errors = false;
                this.adding = {};
                this.keyword = '';
                this.searching = false;
                this.searched = false;
                this.users = [];
                this.users_added = [];
                this.getRecipients();

                setTimeout(function () {
                    $('#print-order-copy-recipient-user-keyword').focus();
                }, 500);
            },
            getRecipients: function() {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order-copy-recipients';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.recipients.forEach((item) => {
                        vm.users_added.push(item.user_id);
                    });
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#print-order-copy-recipient-add-form-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                });
            },
            keywordSearch: function (delay = false) {
                if (!this.keyword) {
                    this.searching = false;
                    this.searched = false;

                    return null;
                }
                this.searching = true;

                if (delay) {
                    this.keypress_time = new Date();
                    var vm = this;
                    setTimeout(function () {
                        vm.keywordSearch();
                    }, 500);
                }

                const time = new Date();
                if ((time - this.keypress_time) < 500) {
                    return null;
                }

                this.users = [];
                this.searched = false;
                
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|approved|!declined|!deleted|kw::' + btoa(this.keyword);
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.users = response.data.users;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#print-order-copy-recipient-add-form-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.searching = false;
                    vm.searched = true;
                });
            },
            clearKeyword: function () {
                this.keyword = '';
                this.users = [];
                this.searched = false;
                $('#print-order-copy-recipient-user-keyword').focus();
            },
            addUser: function (user) {
                if ((this.users_added.indexOf(user.id) > -1) || (user.id in this.adding)) {
                    return null;
                }
                this.searching = true;
                this.adding[user.id] = true;
                this.errors = false;
                this.searching = false;

                /**
                 * Send request to API.
                 */
                const data = {
                    user_id: user.id
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order-copy-recipient';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const userId = response.data.user_id;
                    vm.users_added.push(userId);
                    delete vm.adding[userId];
                    vm.$parent.getRecipients();
                    vm.keyword = '';
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            $('#print-order-copy-recipient-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    }
                });
            }
        }
    }
</script>
<style scoped>
    .form-group.keyword {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }

    .table-sm tr:hover {
        background-color: #f8f9fa;
    }

    .table-sm tr:first-child td {
        border-top: none;
    }
</style>
