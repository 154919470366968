<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="asset-archive-modal-label">Archive {{ $root.$data.contents.asset_title }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="sent" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>
            <div v-else>
                <form>
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <p>
                        Archiving this {{ $root.contents.asset_title }} will remove it from the system completely
                        including any collections and {{ $root.contents.template_title }}s it is associated with.
                    </p>
                    <p>
                        Are you sure you want to archive: {{ asset.title }}?
                    </p>
                </form>
            </div>
        </div>
        <div v-if="!sent" class="modal-footer">
            <span v-if="!sending">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-archive mr-1"></i> Archive
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Archive</span>
                </button>
            </span>
            <button type="button" class="btn btn-light ml-2" data-dismiss="modal" :disabled="sending">
                <i class="far fa-times mr-1"></i> Cancel
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                asset: {
                    title: ''
                },
                list_component: null
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (asset, listComponent) {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.asset = asset;
                this.list_component = listComponent;
            },
            onSubmit: function () {
                this.errors = false;
                this.sending = true;

                /**
                 * Send archive asset request to API
                 */
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/resource/'
                    + this.asset.id + '/archive';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: null,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.sent = vm.$root.ucfirst(vm.$root.$data.contents.asset_title) + ' has been archived.';
                    vm.$root.folders_changed = true;

                    // Deselect asset
                    let selectedAssets = vm.$root.$data.selected_assets;
                    let itemIndex = -1;
                    selectedAssets.forEach((item, index) => {
                        if (item == this.asset.id) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex > -1) {
                        selectedAssets.splice(itemIndex, 1);
                    }

                    // Close after 2 seconds automatically
                    setTimeout(function () {
                        $('#asset-archive-modal').modal('hide');
                    }, 2000);

                    // Reload assets
                    const pages = [
                        'assets',
                        'assets-filtered',
                        'view-collection',
                        'asset-reviews',
                        'shared',
                        'asset-approvals'
                    ];
                    if (pages.indexOf(vm.list_component.component_name) > -1) {
                        vm.list_component.getAssets();

                        return null;
                    }
                    vm.list_component.view_asset = null;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#asset-archive-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    // show error
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = 'Something went wrong, please try again';
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            }
        }
    }
</script>
