<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="terms-modal-label">{{ modalTitle() }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClose()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body" id="terms-content" v-on:scroll="onTermsScroll()">
            <div v-if="!terms_content" class="alert alert-danger" role="alert">
                Error: No terms of use and privacy policy content available. Please contact Resourcebase.
            </div>
            <div v-else>
                <loading v-if="(terms_content == 'loading')"></loading>
                <form v-else v-on:submit.prevent="onSubmit()">
                    <p v-if="must_accept">
                        <em>
                            Following GDPR guidance, we need you to review our updated Terms of use and privacy policy
                            before you continue to use this site. Please read through and scroll to the bottom of the
                            text before clicking the <strong>Agree</strong> button.
                        </em>
                    </p>
                    <div v-html="terms_content"></div>
                </form>
            </div>
        </div>
        <div v-if="(must_accept && terms_content && (terms_content != 'loading'))" class="modal-footer">
            <button
                type="button"
                class="btn btn-primary mr-2"
                :disabled="submitDisabled()"
                :title="submitTitle()"
                @click="onSubmit()"
            >
                <i class="far fa-check mr-1"></i> Agree
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['must_accept', 'content'],
        data () {
            return {
                terms_accepted: false,
                terms_seen: false,
                terms_content: 'loading',
                reopen_modal: null
            }
        },
        mounted () {
            if (this.content) {
                this.terms_content = this.content;
            }
        },
        watch: {
            content: function (val) {
                if (val) {
                    this.terms_content = val;
                }
            }
        },
        methods: {
            modalTitle: function () {
                if (this.must_accept) {
                    return 'Please agree to our terms of use and privacy policy';
                }

                return 'Terms of use and privacy policy';
            },
            submitTitle: function () {
                return this.terms_seen ? '': 'Please read to the bottom to agree';
            },
            submitDisabled: function () {
                return !this.terms_seen; 
            },
            onSubmit: function () {
                $('#terms-modal').modal('hide');
                this.$emit('input', true);
            },
            onTermsScroll: function () {
                if (!this.must_accept) {
                    return null;
                }
                const scrollPos = document.getElementById('terms-content').scrollTop;
                const fullHeight = document.getElementById('terms-content').scrollHeight;
                const visibleHeight = $('#terms-content').innerHeight();
				if ((fullHeight - scrollPos - visibleHeight) < 40) {
					this.terms_seen = true;
				}
            },
            onClose: function () {
                if (this.reopen_modal) {
                    $('#' + this.reopen_modal).modal('show');
                    this.reopen_modal = null;
                }
            }
        }
    }
</script>
