<template>
    <div :class="getClass()">
        <i class="fal fa-spinner-third fa-spin"></i>
    </div>
</template>

<script>
    export default {
        props: ['align', 'size'],
        mounted () {
            //
        },
        methods: {
            getClass: function () {
                const align = this.align ? this.align : 'center';
                const size = this.size ? this.size + 'x' : '3x';

                return 'text-' + align + ' fa-' + size + ' loading';
            }
        }
    }
</script>
