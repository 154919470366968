<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="asset-reviews-bulk-edit-modal-label">Bulk actions</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <form v-on:submit.prevent="bulkUpdateAssets()">
                <div class="row">
                    <div
                        class="col-md-4 col-6"
                        style="min-height: 280px; border-right: 1px solid #eff2f7"
                    >
                        <div
                            v-for="action in getActions()"
                            :key="action.index"
                            :class="actionClass(action.id)"
                            @click="changeAction(action.id)"
                        >
                            <hr v-if="(action.id == 'hr')" class="mt-2 mb-2">
                            <div v-else>
                                <span v-html="action.name"></span>
                                <span class="chevron-right ml-2">
                                    <i class="far fa-chevron-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col form-column" style="min-height: 280px">
                        <div v-if="updated" class="alert alert-success" role="alert">
                            <div class="row">
                                <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                                <div class="col" v-html="updated"></div>
                            </div>
                        </div>

                        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                        <div v-if="!updated">
                            <div v-if="(current_action == 'review_date')">
                                <h5 class="mb-4">
                                    Change review date of {{ collectionSelectedCount() }} {{ assets() }}
                                </h5>
                                <div class="input-group">
                                    <b-form-datepicker
                                        v-model="bulk_edit.review_date"
                                        id="asset-reviews-bulk-edit-review_date"
                                        class="form-control"
                                        start-weekday="1"
                                        locale="en-GB"
                                        :min="today()"
                                        :hideHeader="true"
                                        :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                                        :disabled="updating"
                                        label-no-date-selected="Choose a review date"
                                    ></b-form-datepicker>
                                    <div class="input-group-append">
                                        <button
                                            class="btn btn-light dropdown-toggle date-presets-button"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :disabled="(updating_review_date || updating)"
                                        >
                                            Preset periods
                                            <i
                                                class="fas fa-chevron-down d-none d-xl-inline-block"
                                                style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="preset in review_date_presets"
                                                :key="preset.month"
                                                class="dropdown-item"
                                                href="#"
                                                @click.prevent="setDate('review_date', preset.month)"
                                            >{{ preset.text }}</a>
                                        </div>
                                    </div>
                                    <button
                                        v-if="(bulk_edit.review_date && !updating)"
                                        type="button"
                                        class="btn btn-link clear-keyword-review-date"
                                        @click.prevent="clearReviewDate()"
                                    >
                                        <i class="far fa-times"></i>
                                    </button>
                                </div>
                            </div>

                            <div v-if="(current_action == 'archive')">
                                <h5 class="mb-4">
                                    Archive {{ collectionSelectedCount() }} {{ assets() }}
                                </h5>
                                <p v-if="(collectionSelectedCount() == 1)">
                                    Archiving this {{ assets() }} will remove it from the system completely including
                                    any collections and {{ $root.contents.template_title }}s it is associated with.
                                </p>
                                <p v-else>
                                    Archiving these {{ assets() }} will remove them from the system completely including
                                    any collections and {{ $root.contents.template_title }}s they are associated with.
                                </p>
                                <p>Please confirm that you want to archive selected {{ assets() }}.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div v-if="!updated" class="modal-footer">
            <span v-if="isBulkEditAction()">
                <span v-if="!updating">
                    <span>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="bulkUpdateAssets()"
                        >
                            <i class="far fa-save mr-1"></i> Update {{ assets() }}
                        </button>
                    </span>
                </span>
                <span v-else>
                    <button type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">Update {{ assets() }}</span>
                    </button>
                </span>
            </span>

            <span v-if="(current_action == 'archive')">
                <span><span>
                    <span v-if="!updating">
                        <span>
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="archiveAssets()"
                            >
                                <i class="far fa-archive mr-1"></i> Archive {{ assets() }}
                            </button>
                        </span>
                    </span>
                    <span v-else>
                        <button type="button" class="btn btn-primary">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Archive {{ assets() }}</span>
                        </button>
                    </span>
                </span></span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['selected_assets', 'num_assets_review_due'],
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                current_action: 'review_date',
                changing_action: false,
                bulk_edit: {},
                review_date_presets: [
                    {
                        month: 0,
                        text: 'Today'
                    },
                    {
                        month: 1,
                        text: 'In a month'
                    },
                    {
                        month: 6,
                        text: 'In 6 months'
                    },
                    {
                        month: 12,
                        text: 'In a year'
                    },
                    {
                        month: 24,
                        text: 'In 2 years'
                    },
                    {
                        month: 36,
                        text: 'In 3 years'
                    },
                    {
                        month: 60,
                        text: 'In 5 years'
                    },
                ],
                updating_review_date: false
            }
        },
        mounted () {
            //
        },
        watch: {
            current_action: function (val) {
                this.errors = false;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
            }
        },
        methods: {
            resetModal: function () {
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.current_action = 'review_date';
                this.changing_action = false;
                this.bulk_edit = {};
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
            },
            getActions: function () {
                let actions = [];
                actions.push({
                    id: 'review_date',
                    name: '<i class="far fa-calendar-alt mr-1"></i> Change review date'
                });
                /*actions.push({
                    id: 'hr'
                });*/
                if (this.$root.hasPermission('assets_archive')) {
                    actions.push({
                        id: 'archive',
                        name: '<i class="far fa-archive mr-1"></i> Archive'
                    });
                }

                return actions;
            },
            actionName: function (id) {
                let name = null;
                const actions = this.getActions();
                actions.forEach((item) => {
                    if (item.id == id) {
                        name = item.name;
                    }
                });

                return name;
            },
            actionClass: function (action) {
                if (action == 'hr') {
                    return 'hr';
                }
                if (action == this.current_action) {
                    if (action.substr(0, 2) == 'cf') {
                        return 'action active py-1 pl-3 pr-2'
                    }
                    return 'action active py-1 pl-2 pr-2';
                }

                return 'action py-1 pl-2 pr-4';
            },
            changeAction: function (action, delay = true) {
                if (this.updated) {
                    return null;
                }
                this.changing_action = true;
                if (delay) {
                    var vm = this;
                    var cAction = action;
                    setTimeout(function () {
                        vm.changeAction(cAction, false);
                    }, 100);

                    return null;
                }
                this.current_action = action;
                this.changing_action = false;
            },
            collectionSelectedCount: function () {
                if (this.selected_assets.length == 0) {
                    return 0;
                }
                if (this.selected_assets[0] == 0) {
                    return this.num_assets_review_due;
                }

                return this.selected_assets.length;
            },
            assets: function () {
                const count = this.collectionSelectedCount();

                return this.$root.contents.asset_title + ((count == 1) ? '' : 's');
            },
            isBulkEditAction: function () {
                if (this.current_action == 'archive') {
                    return false;
                }

                return true;
            },
            bulkUpdateAssets: function (delay = true, confirmed = false) {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                if (this.selected_assets.length == 0) {
                    this.errors = 'No ' + this.$root.contents.asset_title + ' selected';

                    return null;
                }
                this.errors = false;
                this.updating = true;
                var vm = this;
                if (delay) {
                    setTimeout(function () {
                        vm.bulkUpdateAssets(false);
                    }, 200);

                    return null;
                }

                /**
                 * Send bulk update collection assets request to API
                 */
                let data = {
                    resources: this.selected_assets,
                    filters: ['!archived', 'review-due']
                };
                //let updateFields = [];
                if (this.bulk_edit.review_date || (this.current_action == 'review_date')) {
                    data.review_date = this.bulk_edit.review_date || null;
                    //updateFields.push(this.actionName('review_date'));
                }

                /*if ((updateFields.length > 1) && !confirmed) {
                    this.updating = false;
                    this.$parent.$parent.confirm_delete = {
                        title: 'Warning',
                        text: '<div class="row"> <div class="col-2 text-center"> '
                            + '<i class="fal fa-exclamation-triangle fa-2x"></i></div> <div class="col"> '
                            + 'You are updating ' + (updateFields.length) + ' fields: <br><br>'
                            + updateFields.join('<br>') + '</div> </div>',
                        component: this,
                        action: 'confirm-bulk-edit',
                        cancel_action: 'cancel-bulk-edit'
                    };
                    $('#confirm-delete-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                    return null;
                }*/

                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/0/bulk-update';

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = vm.assets() + ' updated.';
                    vm.$parent.getAssets();

                    // Close after 2 seconds automatically
                    setTimeout(function () {
                        $('#asset-reviews-bulk-edit-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#asset-reviews-bulk-edit-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#asset-reviews-bulk-edit-' + key + '-label').addClass('text-danger');
                                $('#asset-reviews-bulk-edit-' + key).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            /*confirmDelete: function (action) {
                if (action == 'confirm-bulk-edit') {
                    this.bulkUpdateAssets(false, true);

                    return null;
                }
                if (action == 'cancel-bulk-edit') {
                    //this.resetModal();

                    return null;
                }
            },*/
            today: function () {
                return new Date();
            },
            setDate: function (dateField, monthsToAdd) {
                this.updating_review_date = true;
                const newDate = new Date();
                newDate.setMonth(newDate.getMonth() + monthsToAdd);
                const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
                const day = ('0' + newDate.getDate()).slice(-2);
                this.bulk_edit[dateField] = newDate.getFullYear() + '-' + month + '-' + day;
                this.updating_review_date = false;
            },
            clearReviewDate: function () {
                this.updating_review_date = true;
                this.bulk_edit.review_date = null;
                var vm = this;
                setTimeout(function () {
                    vm.updating_review_date = false;
                }, 200);
            },
            archiveAssets: function () {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                if (this.selected_assets.length == 0) {
                    this.errors = 'No ' + this.$root.contents.asset_title + ' selected';

                    return null;
                }
                this.errors = false;
                this.updating = true;

                /**
                 * Send bulk archive assets request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/0/bulk-archive';
                var vm = this;

                const data = {
                    resources: this.selected_assets,
                    filters: ['!archived', 'review-due']
                };

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = vm.assets() + ' archived.';
                    vm.$parent.getAssets();

                    // Close after 2 seconds automatically
                    setTimeout(function () {
                        $('#asset-reviews-bulk-edit-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#asset-reviews-bulk-edit-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    // show error
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = 'Something went wrong, please try again';
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            }
        }
    }
</script>
<style scoped>
    .action-group .group-title {
        cursor: pointer;
        border-bottom: 1px solid #fff;
    }

    .action-group .group-title:hover {
        background-color: #eff2f7;
    }

    .action {
        cursor: pointer;
        border-bottom: 1px solid #fff;
    }

    .action.active, .action:hover {
        background-color: #eff2f7;
    }

    .action .chevron-right {
        float: right;
        display: none;
    }

    .action.active .chevron-right {
        display: block;
        position: relative;
        top: 1px;
    }

    .clear-keyword-review-date {
        position: absolute;
        top: 2px;
        right: 127px;
    }
</style>
