<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="decline-user-modal-label">Cancel user invite</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="sent" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>
            <div v-else>
                <p>Are you sure you want to cancel {{ user.first_name }} {{ user.last_name }}'s invite?</p>
            </div>
        </div>
        <div v-if="!sent" class="modal-footer">
            <span v-if="!sending">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-check mr-1"></i> Yes
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Yes</span>
                </button>
            </span>
            <button type="button" class="btn btn-light ml-2" data-dismiss="modal" :disabled="sending">
                <i class="far fa-times mr-1"></i> No
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                user: {
                    first_name: '',
                    last_name: ''
                }
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (user) {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.user = user;
            },
            onSubmit: function () {
                this.errors = false;
                this.sending = true;

                /**
                 * Send cancel invite request to API
                 */
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/user/' + this.user.id
                    + '/cancel-invite';
                var vm = this;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.sent = response.data.message;

                    // reload user list
                    vm.$parent.getUsers();

                    // close after 2 seconds automatically
                    setTimeout(function () {
                        $('#cancel-invite-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    // show error
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = 'Something went wrong, please try again';
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            }
        }
    }
</script>
