<template>
    <div class="py-3">
        <loading v-if="loading"></loading>
        <div v-else>
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form v-on:submit.prevent="onSubmit()">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label
                                    :for="'profile-name' + data.id"
                                    :id="'profile-name-label' + data.id"
                                    class="mandatory-label"
                                >
                                    {{ $root.ucfirst($root.contents.profile_title) }} title *
                                </label>
                                <input
                                    v-model="profile_name"
                                    type="text"
                                    :id="'profile-name' + data.id"
                                    class="form-control mandatory-field"
                                    :readonly="(updating || read_only)"
                                    v-on:keyup="$root.unHighlightErrors('profile-name', data.id)"
                                    v-on:change="$root.unHighlightErrors('profile-name', data.id)"
                                >
                            </div>
                        </div>
                        <div v-for="item in profile_fields" :key="item.id" class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label
                                    :for="'profile-field' + data.id + '_' + item.id"
                                    :id="'profile-field-label' + data.id + '_' + item.id"
                                    class="mandatory-label"
                                >
                                    {{ item.title }} <span v-if="item.required">*</span>
                                </label>
                                <a
                                    v-if="((item.type == 'I') && !fields[item.id] && !read_only)"
                                    class="font-size-15 float-right"
                                    :id="'profile-image-tips' + data.id + '_' + item.id"
                                    href="#"
                                    @click.prevent="doNothing()"
                                >
                                    <i class="far fa-info-circle"></i>
                                </a>
                                <b-tooltip
                                    v-if="((item.type == 'I') && !fields[item.id] && !read_only)"
                                    :target="'profile-image-tips' + data.id + '_' + item.id"
                                    triggers="hover"
                                >
                                    Your file should be a PDF document or an SVG, JPEG or PNG image
                                    <span v-if="item.min_pixels">
                                        and at least {{ item.min_pixels }} pixels wide or deep.
                                        <br><br>
                                        To check the resolution, print the image to your desktop printer at A5 size.
                                        If it is sharp, it should be OK.
                                    </span>
                                </b-tooltip>
                                <div v-if="(item.type == 'I')">
                                    <profile-image-field
                                        v-if="is_visible"
                                        :profile="data"
                                        :field="item"
                                        :data="fields[item.id]"
                                        :readonly="(updating || read_only)"
                                        v-on:change="updateImageField"
                                    ></profile-image-field>
                                </div>
                                <input
                                    v-if="((item.type == 'T') && item.single_line)"
                                    v-model="fields[item.id]"
                                    type="text"
                                    :id="'profile-field' + data.id + '_' + item.id"
                                    class="form-control mandatory-field"
                                    :readonly="(updating || read_only)"
                                >
                                <textarea
                                    v-if="((item.type == 'T') && !item.single_line)"
                                    v-model="fields[item.id]"
                                    :id="'profile-field' + data.id + '_' + item.id"
                                    class="form-control mandatory-field"
                                    :readonly="(updating || read_only)"
                                    style="height: 126px"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </form>

                <span v-if="(((data.id == 0) && canAdd()) || ((data.id > 0) && canUpdate()))">
                    <span v-if="!updating">
                        <button
                            :key="'save-button'"
                            type="button"
                            class="btn btn-primary mr-2"
                            @click="onSubmit()"
                            :disabled="updating_image_field"
                        >
                            <span v-if="(data.id > 0)"><i class="far fa-save mr-1"></i> Save</span>
                            <span v-else>
                                <i class="far fa-check mr-1"></i> Add {{ $root.contents.profile_title }}
                            </span>
                        </button>
                    </span>
                    <span v-else>
                        <button :key="'saving-button'" type="button" class="btn btn-primary mr-2">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">
                                {{ (data.id > 0) ? 'Save' : 'Add ' + $root.contents.profile_title }}
                            </span>
                        </button>
                    </span>
                </span>
                <span v-if="((data.id > 0) && canDelete())">
                    <span v-if="!deleting">
                        <button
                            :key="'delete-button'"
                            type="button"
                            class="btn btn-secondary"
                            @click="deleteProfile()"
                            :disabled="updating_image_field"
                        >
                            <i class="far fa-trash-alt mr-1"></i> Delete
                        </button>
                    </span>
                    <span v-else>
                        <button :key="'deleting-button'" type="button" class="btn btn-secondary">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Delete</span>
                        </button>
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['profile_fields', 'data', 'is_visible'],
        data () {
            return {
                loading: true,
                errors: false,
                updating: false,
                updated: false,
                profile_name: null,
                fields: {},
                read_only: false,
                deleting: false,
                updating_image_field: false
            }
        },
        mounted () {
            //
        },
        watch: {
            is_visible: function (val) {
                if (val) {
                    this.resetForm();
                }
            }
        },
        methods: {
            resetForm: function () {
                this.errors = false;
                this.updated = false;
                if (this.data.id > 0) {
                    this.loading = true;
                    this.profile_name = this.data.name;
                    this.fields = {};
                    this.read_only = !this.canUpdate();
                    this.getFieldContents();

                    return null;
                }

                this.loading = false;
                this.read_only = false;
            },
            getFieldContents: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile/' + this.data.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.field_contents.forEach((item) => {
                        vm.fields[item.profile_field_id] = item.content;
                    });
                })
                .catch(function (error) {
                    //
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                let data = {
                    name: this.profile_name
                }
                if (this.data.id == 0) {
                    data.profile_group_id = this.data.profile_group_id;
                }

                let fieldData = [];
                for (const [key, value] of Object.entries(this.fields)) {
                    fieldData.push({
                        id: key,
                        content: value
                    });
                }
                if (fieldData.length > 0) {
                    data.fields = fieldData;
                }

                /**
                 * Send request to API.
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile';
                if (this.data.id > 0) {
                    url += '/' + this.data.id;
                }
                var vm = this;

                axios({
                    method: (this.data.id > 0) ? 'put' : 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = vm.$root.ucfirst(vm.$root.contents.profile_title)
                        + ((vm.data.id > 0) ? ' updated.' : ' added.');

                    vm.$root.profiles_changed = true;

                    setTimeout(function () {
                        vm.$parent.getProfiles();
                        if (vm.data.id == 0) {
                            vm.profile_name = null;
                            vm.fields = {};
                        }
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error
                                    .replace(/profile/g, vm.$root.contents.profile_title);
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    if (key == 'name') {
                                        vm.errors += 'The profile title field is required.<br>';
                                    } else {
                                        vm.errors += value.join('<br>') + '<br>';
                                    }
                                    if (key.indexOf('pf_') > -1) {
                                        $('#profile-field-label' + vm.data.id + '_' + key.substring(3))
                                            .addClass('text-danger');
                                        $('#profile-field' + vm.data.id + '_' + key.substring(3))
                                            .addClass('field-error');
                                    } else {
                                        $('#profile-' + key + '-label' + vm.data.id).addClass('text-danger');
                                        $('#profile-' + key + vm.data.id).addClass('field-error');
                                    }
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            deleteProfile: function (confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.deleting = true;

                    /**
                     * Send delete request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile/' + this.data.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.$parent.getProfiles();
                        vm.$root.profiles_changed = true;
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error || 'Something went wrong, please try again';
                    })
                    .finally(() => {
                        vm.deleting = false;
                    });

                    return null;
                }

                if (this.updating) {
                    return null;
                }

                this.$parent.$parent.confirm_delete = {
                    title: 'Delete ' + this.$root.contents.profile_title,
                    text: 'Are you sure you want to delete: ' + this.data.name + '?',
                    component: this,
                    action: 'delete-profile'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deleteProfile(true);
            },
            updateImageField: function (event) {
                if (this.read_only) {
                    return null;
                }
                this.read_only = true;
                this.fields[event.id] = event.content;
                this.read_only = false;
            },
            canAdd: function () {
                if (this.$root.hasPermission('profiles_profiles_add')) {
                    return true;
                }

                return (this.$root.is_own_profile_group && this.$root.hasPermission('profiles_own_group_add_profiles'));
            },
            canUpdate: function () {
                if (this.$root.hasPermission('profiles_profiles_edit')) {
                    return true;
                }

                return (this.$root.is_own_profile_group && this.$root.hasPermission('profiles_own_group_edit_profiles'));
            },
            canDelete: function () {
                if (this.$root.hasPermission('profiles_profiles_delete')) {
                    return true;
                }
                if (!this.$root.is_own_profile_group) {
                    return false;
                }

                return this.$root.hasPermission('profiles_own_group_delete_profiles');
            }
        }
    }
</script>
