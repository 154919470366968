<template>
    <div>
        <div
            v-if="(data.id == 'add-panel')"
            class="card mb-3 add-panel"
            data-toggle="modal"
            data-target="#home-panel-edit-form-modal"
            @click="resetHomePanelEditForm()"
        >
            <div class="card-body text-center text-primary">
                <a
                    href="#"
                    @click.prevent="doNothing()"
                >
                    <p><i class="fal fa-plus-circle" style="font-size: 30px"></i></p>
                    <p>Add panel</p>
                </a>
            </div>
        </div>
        <div v-else :class="panelClass()" :id="'home-panel-' + panel_id" @click="openLink()" :style="homePanelStyle()">
            <div
                :class="headerClass()"
                :id="'panel-header-' + panel_id"
                style="text-transform: uppercase; line-height: 1.1; height: 52px; z-index: 1"
            >
                <table style="width: 100%; height: 44px">
                    <tbody>
                        <tr>
                            <td valign="middle">
                                <div style="overflow-wrap: anywhere; max-height: 37px; overflow: hidden">
                                    {{ data.header_text }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-body" :id="'card-body-' + panel_id" :style="homePanelBodyStyle()">
                <canvas
                    v-if="(panel_id == 'hp-preview')"
                    :id="'home-panel-' + panel_id + '-body-image-preview'"
                    style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; display: none"
                ></canvas>
                <div v-if="hasCarousel()" class="carousel-container">
                    <div class="carousel">
                        <img
                            :id="'carousel-image' + panel_id"
                            :class="carousel_images[carousel_image_index].image_format"
                            :src="carousel_images[carousel_image_index].src"
                            alt=""
                        >
                    </div>
                </div>
                <div class="panel-body" :id="'panel-body-' + panel_id" v-html="convertFontSizes(data.body_text)"></div>
                <div
                    v-if="(data.link_value && data.button_text)"
                    :class="'pb-3 text-' + data.button_position"
                    style="position: absolute; left: 20px; bottom: 0; width: calc(100% - 40px)"
                >
                    <button :class="buttonClass()" @click.prevent="doNothing()" style="text-transform: uppercase">
                        {{ data.button_text }}
                    </button>
                </div>
                <div
                    :class="'scroll-up text-' + data.body_colour"
                    :id="'hp-scroll-up-' + panel_id"
                    @click="scrollUp()"
                >
                    <i class="far fa-chevron-up"></i>
                </div>
                <div
                    :class="'scroll-down text-' + data.body_colour"
                    :id="'hp-scroll-down-' + panel_id"
                    @click="scrollDown()"
                >
                    <i class="far fa-chevron-down"></i>
                </div>
            </div>
            <div v-if="(!preview && $root.editing_homepage)" class="edit-tools">
                <a
                    href="#"
                    class="drag-handle"
                    @click.prevent="doNothing()"
                    v-b-tooltip.hover.left
                    title="Move"
                    style="cursor: move"
                >
                    <i class="fal fa-arrows-alt"></i>
                </a>
                <a
                    href="#"
                    data-toggle="modal"
                    data-target="#home-panel-edit-form-modal"
                    @click.prevent="resetHomePanelEditForm()"
                    v-b-tooltip.hover.left
                    title="Edit"
                >
                    <i class="fal fa-edit"></i>
                </a>
                <a
                    href="#"
                    @click.prevent="deleteHomePanel()"
                    v-b-tooltip.hover.left
                    title="Delete"
                >
                    <i class="fal fa-trash-alt"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'preview', 'is_visible', 'initial_height', 'folders'],
        data () {
            return {
                panel_id: '',
                block_open_link: false,
                reset_height_interval: null,
                body_image: null,
                body_image_loaded: false,
                prev_width: 0,
                prev_width2: 0,
                carousel_images: [],
                carousel_interval: null,
                carousel_image_index: -1
            }
        },
        mounted () {
            this.panel_id = this.preview ? 'hp-preview' : this.data.id;
            if (this.preview && !this.is_visible) {
                return null;
            }
            if (!this.preview) {
                $('.panel-body').css('display', 'none');
                $('.scroll-up').css('display', 'none');
                $('.scroll-down').css('display', 'none');
                if (this.data && (this.panel_id != 'add-panel')) {
                    this.getBodyImage(this.data);
                }
            }

            var vm = this;

            setTimeout(function () {
                if (!vm.preview || vm.is_visible) {
                    vm.resetHeight();
                }
            }, 200);

            vm.reset_height_interval = setInterval(function () {
                if (!vm.preview) {
                    vm.resetHeight();
                }
            }, 999);

            $(window).resize(function () {
                if (!vm.preview || vm.is_visible) {
                    vm.resetHeight();
                }
            });
        },
        beforeDestroy() {
            clearInterval(this.reset_height_interval);
            if (this.carousel_interval) {
                clearInterval(this.carousel_interval);
            }
        },
        watch: {
            data: function(val) {
                if (val && !this.preview && (data.id != 'add-panel')) {
                    this.getBodyImage(val);
                }
            },
            block_open_link: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.block_open_link = false;
                    }, 200);
                }
            },
            is_visible: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        this.resetHeight();
                    }, 200);
                }
            }
        },
        methods: {
            panelClass: function () {
                const panelColour = (this.data.body_colour || 'third');
                const panelTextColour = (this.data.body_text_colour || 'third');
                if (this.preview || !this.data.link_value) {
                    return 'card mb-3 home-panel bg-' + panelColour + ' text-' + panelTextColour;
                }

                return 'card mb-3 home-panel link bg-' + panelColour + ' text-' + panelTextColour;
            },
            headerClass: function () {
                const headerColour = (this.data.header_colour || 'third');
                const headerTextColour = (this.data.header_text_colour || 'third');

                return 'card-header font-size-16 py-1 bg-' + headerColour + ' text-' + headerTextColour;
            },
            buttonClass: function () {
                const buttonColour = (this.data.button_colour || 'primary');
                const buttonTextColour = (this.data.button_text_colour || 'primary');

                return 'btn btn-light bg-' + buttonColour + ' text-' + buttonTextColour;
            },
            resetHomePanelEditForm: function () {
                this.block_open_link = true;
                const component = this.$root.findComponent(this.$parent.$parent, 'home-panel-edit-form');
                component.resetForm((this.data.id == 'add-panel') ? null : this.data);

                return null;
            },
            deleteHomePanel: function (confirmed = false) {
                if (confirmed) {
                    /**
                     * Send delete home panel request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/homepage-panel/'
                        + this.data.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.$parent.$parent.getPanels();
                    })
                    .catch(function (error) {
                        //
                    });

                    return null;
                }
                this.block_open_link = true;
                const panelName = this.data.header_text ? ': ' + this.data.header_text.toUpperCase() : ' this panel';
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Delete homepage panel',
                    text: 'Are you sure you want to delete' + panelName + '?',
                    component: this,
                    action: 'delete-panel'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deleteHomePanel(true);
            },
            doNothing: function () {
                return null;
            },
            openLink: function () {
                if (this.block_open_link) {
                    return false;
                }
                if (this.preview || !this.data.link_value) {
                    return false;
                }
                if ((this.data.link_type == 'folder') || (this.data.link_type == 'subfolder')) {
                    if (!this.hasFolders()) {
                        return null;
                    }
                    this.$root.asset_filters = [];
                    const linkValueParts = this.data.link_value.split('::');
                    const folderId = parseInt(linkValueParts[0]);
                    let folder = null;
                    this.folders.forEach((item) => {
                        if (item.id == folderId) {
                            folder = item;
                        }
                    });
                    if (!folder) {
                        return null;
                    }
                    let liveSubfolders = [];
                    folder.subfolders.forEach((item) => {
                        if (!item.archived) {
                            liveSubfolders.push(item);
                        }
                    });
                    this.$root.addFilter('asset_filters', {
                        key: 'folder',
                        value: folderId,
                        subfolders: liveSubfolders,
                        text: '<i class="fal fa-folder font-size-16 mr-1"></i> '
                            + folder.name.replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                        filters_key: 'asset_filters'
                    });
                    if (this.data.link_type == 'subfolder') {
                        const subfolderId = parseInt(linkValueParts[2]);
                        let subfolder = null;
                        liveSubfolders.forEach((item) => {
                            if (item.id == subfolderId) {
                                subfolder = item;
                            }
                        });
                        this.$root.addFilter('asset_filters', {
                            key: 'subfolder',
                            value: subfolderId,
                            name: subfolder.name,
                            text: '<i class="fal fa-folder-tree font-size-16 mr-1"></i> '
                                + subfolder.name.replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                            filters_key: 'asset_filters'
                        });
                    }
                    this.$router.push({ name: 'assets' });
                }
                if (this.data.link_type == 'asset') {
                    this.$root.asset_filters = [];
                    this.$root.addFilter('asset_filters', {
                        key: 'resource_id',
                        value: parseInt(this.data.link_value),
                        text: '&quot;' + this.data.link_value + '&quot;',
                        filters_key: 'asset_filters'
                    });
                    this.$router.push({ name: 'assets' });

                    return null;
                }
                if (this.data.link_type == 'latest_assets') {
                    this.$root.asset_filters = [];
                    const s = (this.data.link_value > 1) ? 's' : '';
                    this.$root.addFilter('asset_filters', {
                        key: 'limit',
                        value: parseInt(this.data.link_value),
                        text: 'Latest ' + this.data.link_value + ' upload' + s + ' / edited '
                            + this.$root.contents.asset_title + s,
                        filters_key: 'asset_filters'
                    });
                    this.$router.push({ name: 'assets' });

                    return null;
                }
                if (this.data.link_type == 'keywords') {
                    this.$root.asset_filters = [];
                    const keywords = this.data.link_value.split(',');
                    this.$root.addFilter('asset_filters', {
                        key: 'keyword',
                        value: '[E]||' + keywords.join('||'),
                        text: '&quot;' + keywords.join('&quot;, &quot;').replace(/"/g, '&quot;')
                            .replace(/'/g, '&apos;') + '&quot;',
                        filters_key: 'asset_filters'
                    });
                    this.$router.push({ name: 'assets' });

                    return null;
                }
                if (this.data.link_type == 'url') {
                    const self = (this.data.link_value.indexOf('/') == 0);
                    window.open(this.data.link_value, self ? '_self' : '_blank').focus();

                    return null;
                }
                if (this.data.link_type == 'collection') {
                    const linkValueParts = this.data.link_value.split('::');
                    this.$router.push({ name: 'view-collection', params: { id: parseInt(linkValueParts[0]) } });

                    return null;
                }
            },
            homePanelStyle: function () {
                if (this.initial_height) {
                    return 'height: ' + this.initial_height + 'px';
                }

                return '';
            },
            homePanelBodyStyle: function () {
                let style = 'position: relative; overflow: hidden';
                if (this.body_image) {
                    style += '; background-image: url(' + this.body_image + ')';
                }
                if (this.data.body_image) {
                    style += '; background-image: url(' + this.data.body_image + ')';
                }

                return style;
            },
            initScroll: function () {
                let height = $('#home-panel-' + this.panel_id).outerHeight() - 77;
                if (this.data.link_value && this.data.button_text) {
                    height -= 55;
                }
                $('#panel-body-' + this.panel_id).css('height', height + 'px');
                $('#panel-body-' + this.panel_id).fadeIn('700');

                const panelWidth = $('#card-body-' + this.panel_id).outerWidth();
                const panelHeight = $('#card-body-' + this.panel_id).outerHeight();
                $('#card-body-' + this.panel_id).css({
                    'padding-top': (9.6 * (panelHeight / 300)) + 'px',
                    'padding-left': (20 * (panelWidth / 300)) + 'px',
                    'padding-right': (20 * (panelWidth / 300)) + 'px',
                    'padding-bottom': (20 * (panelHeight / 300)) + 'px'
                });
                $('#panel-body-' + this.panel_id).css('font-size', (panelHeight / 300) + 'rem');
                $('#panel-body-' + this.panel_id + ' p').css('margin-bottom', (panelHeight / 300) + 'rem');

                var vm = this;
                setTimeout(function () {
                    const bodyHeight = $('#panel-body-' + vm.panel_id).innerHeight() || 0;
                    const panelBody = $('#panel-body-' + vm.panel_id)[0] || null;
                    if (panelBody) {
                        const contentHeight = panelBody.scrollHeight;
                        if (contentHeight > (bodyHeight + 1)) {
                            $('#hp-scroll-up-' + vm.panel_id).css('top', '3px');
                            const top = (vm.data.link_value && vm.data.button_text) ? (bodyHeight + 4) : (bodyHeight - 6);
                            $('#hp-scroll-down-' + vm.panel_id).css('top', top + 'px');
                            $('#hp-scroll-up-' + vm.panel_id).fadeIn('700');
                            $('#hp-scroll-down-' + vm.panel_id).fadeIn('700');
                        } else {
                            $('#hp-scroll-up-' + vm.panel_id).fadeOut('700');
                            $('#hp-scroll-down-' + vm.panel_id).fadeOut('700');
                        }
                    }
                }, 990);
            },
            scrollUp: function () {
                this.block_open_link = true;
                $('#panel-body-' + this.panel_id).animate({ scrollTop: '-=100' }, 350);
            },
            scrollDown: function () {
                this.block_open_link = true;
                $('#panel-body-' + this.panel_id).animate({ scrollTop: '+=100' }, 350);
            },
            resetHeight: function () {
                if (this.panel_id == 'add-panel') {
                    const width = $('.add-panel').outerWidth();
                    let noReset = false;
                    if ((width == this.prev_width) || ((width == this.prev_width2) && (width < this.prev_width))) {
                        noReset = true;
                    }
                    this.prev_width2 = this.prev_width;
                    this.prev_width = width;
                    if (noReset) {
                        return null;
                    }
                    $('.add-panel').css('height', Math.round(width / 0.816) + 'px');

                    return null;
                }
                const width = $('#home-panel-' + this.panel_id).outerWidth();
                let noReset = false;
                if ((width == this.prev_width) || ((width == this.prev_width2) && (width < this.prev_width))) {
                    noReset = true;
                }
                this.prev_width2 = this.prev_width;
                this.prev_width = width;
                if (noReset) {
                    return null;
                }
                $('#home-panel-' + this.panel_id).css('height', Math.round(width / 0.816) + 'px');

                var vm = this;
                setTimeout(function () {
                    vm.initScroll();
                }, 200);
            },
            hasFolders: function () {
                if (!this.folders) {
                    return false;
                }
                if (this.folders.length == 0) {
                    return false;
                }

                return true;
            },
            getBodyImage: function (panel, noCarousel = false) {
                this.carousel_images = [];
                this.carousel_image_index = -1;
                if ((panel.link_type == 'latest_assets') && !noCarousel) {
                    const limit = parseInt(panel.link_value);
                    if (limit > 0) {
                        this.getLatestAssets(limit);
                    }

                    return null;
                }
                if (this.$root.editing_homepage || panel.body_image || this.body_image_loaded) {
                    this.body_image_loaded = true;

                    return null;
                }

                /**
                 * Send get panel body image request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/homepage-panel/' + panel.id
                    + '/body-image';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.body_image = response.data.body_image;
                    vm.body_image_loaded = true;
                })
                .catch(function (error) {
                    //
                });
            },
            getLatestAssets: function (limit) {
                if (limit > 100) {
                    limit = 100;
                }
                const filters = ['!archived', 'lt::' + limit];

                /**
                 * Send search assets request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/' + filters.join('|');
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.resources.total == 0) {
                        vm.getBodyImage(vm.data, true);

                        return null;
                    }
                    response.data.resources.data.forEach((item) => {
                        // Duplicate display protection - check if already added.
                        let itemIndex = -1;
                        vm.carousel_images.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            vm.carousel_images.push({
                                id: item.id,
                                extension: item.file_extension,
                                file_type: item.file_type,
                                image_format: item.image_format,
                                src: null
                            });
                        }
                    });
                    vm.getAssetPreview(0);
                })
                .catch(function (error) {
                    //
                });
            },
            getAssetPreview: function (imageIndex) {
                let image = this.carousel_images[imageIndex];

                /**
                 * Send request to API.
                 */
                let size = 'mediumres';
                if (image.file_type == 'image/gif') {
                    size = 'highres';
                }
                if (image.extension == 'svg') {
                    size = 'svg';
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + image.id + '/preview/' + size;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.body_image_loaded = true;

                    vm.carousel_images[imageIndex].src = response.data.image_src;
                    if (imageIndex < (vm.carousel_images.length - 1)) {
                        vm.getAssetPreview(imageIndex + 1);
                    }
                    if ((vm.carousel_image_index < 0) && (response.data.image_src.indexOf('data:') == 0)) {
                        vm.carousel_image_index = imageIndex;
                        vm.carousel_interval = setInterval(function () {
                            vm.runCarousel();
                        }, 3050);
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            convertFontSizes: function (html) {
                if (!html) {
                    return null;
                }
                let ratio = 0.092;

                return html.replace(/font-size: 8pt;/g, 'font-size: ' + (8 * ratio) + 'em;')
                    .replace(/font-size: 10pt;/g, 'font-size: ' + (10 * ratio) + 'em;')
                    .replace(/font-size: 12pt;/g, 'font-size: ' + (12 * ratio) + 'em;')
                    .replace(/font-size: 14pt;/g, 'font-size: ' + (14 * ratio) + 'em;')
                    .replace(/font-size: 16pt;/g, 'font-size: ' + (16 * ratio) + 'em;')
                    .replace(/font-size: 18pt;/g, 'font-size: ' + (18 * ratio) + 'em;')
                    .replace(/font-size: 20pt;/g, 'font-size: ' + (20 * ratio) + 'em;')
                    .replace(/font-size: 22pt;/g, 'font-size: ' + (22 * ratio) + 'em;')
                    .replace(/font-size: 24pt;/g, 'font-size: ' + (24 * ratio) + 'em;')
                    .replace(/font-size: 30pt;/g, 'font-size: ' + (30 * ratio) + 'em;')
                    .replace(/font-size: 36pt;/g, 'font-size: ' + (36 * ratio) + 'em;')
                    .replace(/font-size: 42pt;/g, 'font-size: ' + (42 * ratio) + 'em;')
                    .replace(/font-size: 48pt;/g, 'font-size: ' + (48 * ratio) + 'em;');
            },
            hasCarousel: function () {
                if (this.preview) {
                    return false;
                }
                if (this.carousel_images.length == 0) {
                    return false;
                }

                return (this.carousel_image_index > -1);
            },
            runCarousel: function () {
                let nextIndex = this.carousel_image_index + 1;
                if (nextIndex < this.carousel_images.length) {
                    while ((nextIndex < (this.carousel_images.length - 1)) && !this.carousel_images[nextIndex].src) {
                        nextIndex++;
                    }
                    if (this.carousel_images[nextIndex].src) {
                        this.swapCarouselImages(nextIndex);

                        return null;
                    }
                }
                nextIndex = 0;
                while ((nextIndex < (this.carousel_images.length - 1)) && !this.carousel_images[nextIndex].src && (nextIndex != this.carousel_image_index)) {
                    nextIndex++;
                }
                if (this.carousel_images[nextIndex].src && (nextIndex != this.carousel_image_index)) {
                    this.swapCarouselImages(nextIndex);
                }
            },
            swapCarouselImages: function (nextIndex) {
                var vm = this;
                $('#carousel-image' + vm.panel_id).fadeOut(700, function () {
                    vm.carousel_image_index = nextIndex;
                    $('#carousel-image' + vm.panel_id).fadeIn(700);
                });
            }
        }
    }
</script>
<style scoped>
    .add-panel {
        border: 3px dashed #adb5bd;
        background-color: transparent;
        height: 250px;
        transition: width height 0.7 ease;
    }

    .add-panel .card-body {
        height: calc(100% - 6px);
        padding-top: 50%;
        cursor: pointer;
        font-size: 15px;
    }

    .home-panel {
        height: 250px;
        transition: width height 0.7 ease;
        position: relative;
        overflow: hidden;
    }

    .home-panel .edit-tools {
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.6);
        padding-top: 5px;
        padding-bottom: 5px;
        z-index: 2;
    }

    .home-panel .edit-tools a {
        color: rgba(255, 255, 255, 0.85);
        font-size: 18px;
        text-align: center;
        width: 40px;
        padding-top: 5px;
        padding-bottom: 5px;
        display: block;
    }

    .home-panel .edit-tools a:hover {
        color: #ffffff;
    }

    .home-panel.link {
        cursor: pointer;
    }

    .home-panel .card-body {
        padding-top: 0.6rem;
        font-size: 15px;
        font-weight: 300;
        background: no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .home-panel .panel-body {
        height: 253px;
        padding-right: 5px;
        overflow-x: hidden;
        overflow-y: hidden;
        position: relative;
        transition: font-size 0.7s ease;
        overflow-wrap: anywhere;
    }

    .home-panel .scroll-up, .home-panel .scroll-down {
        position: absolute;
        top: -30px;
        right: 2px;
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
        font-size: 20px;
        line-height: 1;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        padding-left: 6px;
        transition: background-color top 0.7s ease;
    }

    .home-panel .scroll-up {
        padding-top: 5px;
    }

    .home-panel .scroll-down {
        padding-top: 6px;
    }

    .home-panel .scroll-up:hover, .home-panel .scroll-down:hover {
        background-color: rgba(0, 0, 0, 0.4);
    }

    .carousel-container {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .carousel {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .carousel img {
        height: 105%;
    }

    .carousel img.P {
        height: auto;
        width: 100%;
    }

    @media (max-width: 767px) {
        .home-panel .panel-body {
            overflow-y: auto;
        }
    }
</style>
