<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (total_items == 0))" class="alert alert-warning" role="alert">
            There are no {{ $root.contents.asset_title }}s due for review
        </div>
        <div v-if="(total_items > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-2 d-none d-sm-block">
                                            {{ $root.ucfirst($root.contents.asset_title) }} No.
                                        </div>
                                        <div
                                            :class="(hasCustomField() ? 'col-lg-4 col-sm-3' : 'col-lg-7 col-sm-5')
                                                + ' d-none d-sm-block'"
                                        >
                                            Title
                                        </div>
                                        <div v-if="hasCustomField()" class="col-lg-3 col-sm-2 d-none d-sm-block">
                                            <span v-if="custom_field">{{ custom_field.name }}</span>
                                        </div>
                                        <div class="col-lg-2 col-sm-3 d-none d-sm-block">
                                            Review date
                                        </div>
                                        <div class="col-8 d-sm-none">
                                            <p class="mb-1">{{ $root.ucfirst($root.contents.asset_title) }} No.</p>
                                            <p class="mb-1">Title</p>
                                            <p v-if="hasCustomField()" class="mb-1">
                                                <span v-if="custom_field">{{ custom_field.name }}</span>
                                            </p>
                                            <p class="mb-0">Review date</p>
                                        </div>
                                        <div class="col-lg-1 col-sm-2 col-4 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleSelectReview()"
                                                v-b-tooltip.hover
                                                :title="(!isSelected() ? 'Select all' : 'Deselect all')"
                                            >
                                                <span v-if="!isSelected()" :key="'select-all'">
                                                    <i class="fa-regular fa-square"></i>
                                                </span>
                                                <span v-else :key="'deselect-all'">
                                                    <i class="fa-solid fa-check-square"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row" :id="'review-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.id }}
                                        </div>
                                        <div
                                            :class="(hasCustomField() ? 'col-lg-4 col-sm-3' : 'col-lg-7 col-sm-5')
                                                + ' d-none d-sm-block'"
                                            style="padding-top: 3px"
                                        >
                                            <h5 class="font-size-14 mb-0">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleReviewDetails(item)"
                                                >
                                                    {{ item.title }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div
                                            v-if="hasCustomField()"
                                            class="col-lg-3 col-sm-2 d-none d-sm-block"
                                            style="padding-top: 3px"
                                        >
                                            {{ getCustomFieldValue(item) }}
                                        </div>
                                        <div class="col-lg-2 col-sm-3 d-none d-sm-block" style="padding-top: 3px">
                                            {{ formatDate(item.review_date) }}
                                        </div>
                                        <div class="col-8 d-sm-none">
                                            <p class="mb-1">{{ item.id }}</p>
                                            <h5 class="font-size-14 mb-1">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleReviewDetails(item)"
                                                >
                                                    {{ item.title }}
                                                </a>
                                            </h5>
                                            <p v-if="hasCustomField()" class="mb-1">
                                                {{ getCustomFieldValue(item) }}
                                            </p>
                                            <p class="mb-0">
                                                {{ formatDate(item.review_date) }}
                                            </p>
                                        </div>
                                        <div class="col-lg-1 col-sm-2 col-4 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right ml-3"
                                                @click.prevent="toggleSelectReview(item)"
                                            >
                                                <span v-if="!isSelected(item)" :key="'select-' + item.id">
                                                    <i class="fa-regular fa-square"></i>
                                                </span>
                                                <span v-else :key="'deselect-' + item.id">
                                                    <i class="fa-solid fa-check-square"></i>
                                                </span>
                                            </a>
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleReviewDetails(item)"
                                            >
                                                <span
                                                    v-if="(review_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'review-details' + item.id">
                                            <asset-review-details
                                                :data="item"
                                                :visible="(review_details_open.indexOf(item.id) > -1)"
                                            ></asset-review-details>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <div
            v-if="$root.hasPermission('assets_review')"
            class="modal fade"
            id="asset-reviews-bulk-edit-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="asset-reviews-bulk-edit-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <asset-reviews-bulk-edit
                    :selected_assets="reviews_selected"
                    :num_assets_review_due="total_items"
                ></asset-reviews-bulk-edit>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'asset-reviews',
                loading: false,
                errors: false,
                items: [],
                total_items: 0,
                current_page: 1,
                last_page: 1,
                review_details_open: [],
                reviews_selected: [],
                custom_field: null
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            $('body').removeClass('login-body');
            
            this.$root.asset_approval_filters = [];
            if (!this.$root.hasPermission('assets_review')) {
                this.errors = 'Sorry, you do not have access to this function.';

                return false;
            }
            this.getAssets();
            if (this.hasCustomField()) {
                this.getCustomField(parseInt(this.$root.client.asset_reviews_custom_field));
            }
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            //
        },
        methods: {
            getAssets: function (page = 1) {
                if (this.loading || (page < 1) || ((page > 1) && (page <= this.current_page))) {
                    return false;
                }
                this.current_page = page;
                this.loading = true;
                this.errors = false;

                if (page == 1) {
                    this.$root.report_xls_download = null;
                    this.items = [];
                    this.total_items = 0;
                    this.current_page = 1;
                    this.last_page = 1;
                    this.review_details_open = [];
                    this.reviews_selected = [];
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resources/!archived|review-due?page=' + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.total_items = response.data.resources.total;
                    vm.current_page = response.data.resources.current_page;
                    vm.last_page = response.data.resources.last_page;
                    if (response.data.resources.data.length == 0) {
                        vm.loading = false;
                    } else {
                        response.data.resources.data.forEach((item) => {
                            vm.items.push(item);
                        });
                    }
                    if (response.data.resources.total == 0) {
                        // Clear notification.
                        vm.$root.clearNotification('admin', 0, 'review_date_alert');
                    }
                    if ((response.data.resources.total > 0) && ('xls_download' in response.data)) {
                        this.$root.report_xls_download = response.data.xls_download;
                    }
                })
                .catch(function (error) {
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                    vm.loading = false;
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            formatDate: function (rawDate) {
                const date = rawDate.substr(0 ,10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            handleScroll(event) {
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < 200) {
                        this.getAssets(this.current_page + 1);
                    }
                }
            },
            toggleReviewDetails: function (asset) {
                if (this.review_details_open.indexOf(asset.id) < 0) {
                    this.review_details_open.push(asset.id);
                    $('#review-row' + asset.id).addClass('highlighted');
                    $('#review-details' + asset.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#review-details' + asset.id).slideUp('slow', function () {
                        $('#review-row' + asset.id).removeClass('highlighted');
                        for (var n = 0; n < vm.review_details_open.length; n++) { 
                            if (vm.review_details_open[n] == asset.id) { 
                                vm.review_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            isSelected: function (asset = null) {
                if (this.reviews_selected.length == 0) {
                    return false;
                }
                if (this.reviews_selected[0] == 0) {
                    return true;
                }

                if (!asset) {
                    return false;
                }

                return (this.reviews_selected.indexOf(asset.id) > -1);
            },
            toggleSelectReview: function (asset = null) {
                if (!this.isSelected(asset)) {
                    if (!asset) {
                        this.reviews_selected = [0];

                        return null;
                    }
                    this.reviews_selected.push(asset.id);

                    return null;
                }
                if (!asset) {
                    this.reviews_selected = [];

                    return null;
                }
                if (this.reviews_selected[0] == 0) {
                    this.reviews_selected = [];
                    this.items.forEach((item) => {
                        if (item.id != asset.id) {
                            this.reviews_selected.push(item.id);
                        }
                    });

                    return null;
                }
                for (var n = 0; n < this.reviews_selected.length; n++) { 
                    if (this.reviews_selected[n] == asset.id) { 
                        this.reviews_selected.splice(n, 1); 
                    }
                }
            },
            getCustomField: function (customFieldId) {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/custom-fields';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.custom_fields.forEach((item) => {
                        if (item.id == customFieldId) {
                            vm.custom_field = item;
                        }
                    });
                })
                .catch(function (error) {
                    //
                });
            },
            hasCustomField: function () {
                if (this.$root.client.asset_reviews_custom_field) {
                    return true;
                }

                return false;
            },
            getCustomFieldValue: function (item) {
                if (!item.custom_field_value) {
                    return '';
                }

                return item.custom_field_value.replace(/\]\[/g, ', ').replace(/\[/g, '').replace(/\]/g, '');
            }
        }
    }
</script>
