<template>
    <div
        :class="'card asset-preview select-item' + (selected ? ' selected' : ' selectable')"
        @click="selectAsset()"
    >
        <div class="thumbnail">
            <img :src="image_src" :alt="title">
            <div v-if="!selected" :key="'select-overlay'" class="asset-select-overlay">
                <div class="triangle"></div>
                <div
                    class="tick"
                    v-b-tooltip.hover
                    :title="'Select ' + $root.contents.asset_title"
                >
                    <i class="fa-regular fa-check"></i>
                </div>
                <div class="ghost"></div>
            </div>
            <div v-else :key="'deselect-overlay'" class="asset-select-overlay deselect">
                <div class="triangle"></div>
                <div
                    class="tick"
                    v-b-tooltip.hover
                    :title="'Deselect ' + $root.contents.asset_title"
                >
                    <span :key="'check-icon'" class="check-icon">
                        <i class="fa-regular fa-check"></i>
                    </span>
                    <span :key="'minus-icon'" class="minus-icon">
                        <i class="fa-regular fa-minus"></i>
                    </span>
                </div>
                <div class="ghost"></div>
            </div>
        </div>

        <div class="card-body" style="position: relative">
            <div class="title mb-2" :id="prefix + 'asset-title' + id">
                {{ short_title }}
            </div>

            <b-tooltip
                v-if="(short_title != title)"
                :target="prefix + 'asset-title' + id"
                triggers="hover"
            >
                {{ title }}
            </b-tooltip>

            <div class="asset-info stay">
                <span class="float-right" style="position: relative; top: 2px">{{ id }}</span>
                <span
                    v-if="extension"
                    class="badge badge-primary font-size-11"
                    style="text-transform: uppercase"
                >
                    {{ extension }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'selected', 'select_multiple', 'one_click_select'],
        data () {
            return {
                id: 0,
                image_src: '/images/file-icon.png',
                title: '',
                short_title: '',
                extension: null,
                file_type: null,
                prefix: 'select-item-'
            }
        },
        mounted () {
            this.updateData(this.data);
            var vm = this;
            $(window).resize(function () {
                if (!vm.in_selection || vm.is_visible) {
                    vm.short_title = vm.title;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                }
            });
        },
        watch: {
            data: function (val) {
                if (val) {
                    this.updateData(val);
                }
            }
        },
        methods: {
            updateData: function (data) {
                if (data) {
                    this.id = data.id;
                    this.title = data.title;
                    this.short_title = data.title;
                    this.extension = data.file_extension;
                    this.file_type = data.file_type;
                    this.getPreviewImage();
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                }
            },
            getPreviewImage: function () {
                /**
                 * Send request to API
                 */
                let size = (this.extension == 'svg') ? 'svg' : 'lowres';
                if (this.file_type == 'image/gif') {
                    size = 'highres';
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + this.id + '/preview/' + size;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            fitTitle: function () {
                if (!$('#' + this.prefix + 'asset-title' + this.id)[0]) {
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 200);

                    return null;
                }
                if ($('#' + this.prefix + 'asset-title' + this.id)[0].scrollHeight > 20) {
                    let words = this.short_title.split(' ');
                    words.pop();
                    if (words.length > 0) {
                        this.short_title = words.join(' ') + '...';
                        var vm = this;
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 200);
                    }
                }
            },
            selectAsset: function () {
                this.$emit('change', { id: this.id, selected: !this.selected });
            },
            doNothing: function () {
                return null;
            }
        }
    }
</script>
<style scoped>
    .asset-preview.select-item {
        border: 2px solid #fff; /*#e4e4e4;*/
        border-radius: 0.35rem;
        cursor: pointer;
    }

    .asset-preview.select-item .thumbnail {
        padding: 0;
        height: 128px;
    }

    .asset-preview.select-item .thumbnail img {
        max-width: 100%;
        max-height: 128px;
    }

    .asset-preview.select-item:hover .card-body .asset-info.stay {
        opacity: 1;
    }

    .asset-preview.select-item.selected:hover .asset-select-overlay.deselect .check-icon {
        display: none;
    }

    .asset-preview.select-item.selected:hover .asset-select-overlay.deselect .minus-icon {
        display: inline;
    }
</style>
