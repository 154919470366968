<template>
    <div class="modal-content">
        <div class="modal-body">
            <div class="close-modal text-center" data-dismiss="modal" @click="onClose()">
                <i class="far fa-times"></i>
            </div>
            <i class="fal fa-arrow-turn-up" data-fa-transform="flip-h"></i>
            <div class="info-title text-center">Now it’s faster to find your stuff</div>
            <div class="info-text text-center">
                <div class="float-left mr-2"><img src="images/snail.png" alt="snail" height="60"></div>
                <div class="float-right ml-2"><img src="images/rocket.png" alt="snail" height="60"></div>
                All those boxes with {{ $root.contents.folders_title }}, {{ $root.contents.subfolders_title }}
                and categories have been replaced by our new one-click ‘mega menu’ so that you can go straight
                to the material you need.
            </div>
            <div class="click-on-text">
                CLICK ON <span class="search-our-resources">SEARCH OUR {{ $root.contents.asset_title }}S</span>
                TO GO STRAIGHT THERE!
            </div>
            <div class="text-center">
                <div class="custom-control custom-checkbox">
                    <input
                        v-model="do_not_show_again"
                        type="checkbox"
                        class="custom-control-input"
                        id="remember_me"
                    >
                    <label class="custom-control-label" for="remember_me">
                        Don’t show this message again
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                do_not_show_again: false
            }
        },
        mounted () {
            //
        },
        methods: {
            onClose: function () {
                if (!this.do_not_show_again) {
                    return null;
                }

                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user/hide-mega-menu-info';
                axios({
                    method: 'put',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + this.$root.token }
                })
                .then(response => {
                    //
                })
                .catch(function (error) {
                    //
                })
            }
        }
    }
</script>
<style scoped>
    .modal-content {
        border-radius: 25px;
        overflow: visible;
    }

    .modal-body {
        overflow-y: visible;
        min-height: 240px;
        padding-bottom: 20px;
    }

    .close-modal {
        position: absolute;
        top: -15px;
        right: -15px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background-color: white;
        border: 1px solid #999;
        overflow: hidden;
        font-size: 35px;
    }

    .fa-arrow-turn-up {
        font-size: 168px;
        position: absolute;
        bottom: 69px;
        left: 15px;
    }

    .info-title {
        font-size: 30px;
        margin-top: 13px;
        margin-bottom: 15px;
        font-weight: 600;
    }

    .info-text {
        padding-left: 123px;
        padding-right: 12px;
        padding-bottom: 70px;
        font-size: 14px;
    }

    .click-on-text {
        text-transform: uppercase;
        position: absolute;
        bottom: 60px;
        left: 152px;
        font-size: 17px;
        letter-spacing: 0.04rem;
    }

    .search-our-resources {
        display: inline-block;
        background-color: #ccc;
        border-radius: 8px;
        padding-left: 6px;
        padding-right: 6px;
        color: white;
    }
</style>
