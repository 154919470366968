<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="register-modal-label">{{ formTitle() }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClose()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body" id="register-terms" v-on:scroll="onTermsScroll()">
            <div v-if="sent" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col">
                        <p v-html="sent"></p>
                        If the email does not appear in your inbox please check your spam or junk folders.
                    </div>
                </div>
            </div>
            <div v-else>
                <form v-on:submit.prevent="reCaptcha()">
                    <div v-if="show_terms" v-html="$root.contents.terms_and_conditions"></div>

                    <div v-else>
                        <div v-if="registration_info" v-html="registration_info"></div>

                        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div v-if="hasConfirmCheckbox() == 'top'" class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            v-model="confirmed"
                                            type="checkbox"
                                            class="custom-control-input"
                                            id="confirmed"
                                            v-on:change="$root.unHighlightErrors('confirmed')"
                                        >
                                        <label
                                            id="confirmed-label" 
                                            class="custom-control-label mandatory-label"
                                            for="confirmed"
                                        >
                                            {{ registration_confirm_text }} *
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="first_name" id="name-label" class="mandatory-label">Your name *</label>
                                    <div class="input-group">
                                        <input
                                            v-model="first_name"
                                            type="text"
                                            id="first_name"
                                            class="form-control mandatory-field"
                                            :readonly="sending"
                                            :placeholder="placeholder('first_name')"
                                            v-on:focus="placeholder('first_name', true)"
                                            v-on:blur="placeholder('first_name', true)"
                                            v-on:keyup="unHighlightNameErrors()"
                                            v-on:change="unHighlightNameErrors()"
                                        >
                                        <input
                                            v-model="last_name"
                                            type="text"
                                            id="last_name"
                                            class="form-control mandatory-field"
                                            :readonly="sending"
                                            :placeholder="placeholder('last_name')"
                                            v-on:focus="placeholder('last_name', true)"
                                            v-on:blur="placeholder('first_name', true)"
                                            v-on:keyup="unHighlightNameErrors()"
                                            v-on:change="unHighlightNameErrors()"
                                        >
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="job_title" id="job_title-label" class="mandatory-label">
                                        Job title *
                                    </label>
                                    <input
                                        v-model="job_title"
                                        type="text"
                                        id="job_title"
                                        class="form-control mandatory-field"
                                        :readonly="sending"
                                        v-on:keyup="$root.unHighlightErrors('job_title')"
                                        v-on:change="$root.unHighlightErrors('job_title')"
                                    >
                                </div>
                                <div class="form-group">
                                    <label for="company" id="company-label" class="mandatory-label">
                                        {{ $root.ucfirst(company_title) }} *
                                    </label>
                                    <input
                                        v-model="company"
                                        type="text"
                                        id="company"
                                        class="form-control mandatory-field"
                                        :readonly="sending"
                                        v-on:keyup="$root.unHighlightErrors('company')"
                                        v-on:change="$root.unHighlightErrors('company')"
                                    >
                                </div>
                                <div class="form-group">
                                    <label
                                        for="register_email"
                                        id="register_email-label"
                                        class="mandatory-label"
                                    >
                                        Your email *
                                    </label>
                                    <input
                                        v-model="register_email"
                                        type="email"
                                        id="register_email"
                                        class="form-control mandatory-field"
                                        :readonly="sending"
                                        v-on:keyup="$root.unHighlightErrors('register_email')"
                                        v-on:change="$root.unHighlightErrors('register_email')"
                                    >
                                </div>
                                <div v-if="hasConfirmCheckbox() == 'bottom'" class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            v-model="confirmed"
                                            type="checkbox"
                                            class="custom-control-input"
                                            id="confirmed"
                                            v-on:change="$root.unHighlightErrors('confirmed')"
                                        >
                                        <label
                                            id="confirmed-label" 
                                            class="custom-control-label mandatory-label"
                                            for="confirmed"
                                        >
                                            {{ registration_confirm_text }} *
                                        </label>
                                    </div>
                                </div>
                                <div v-if="$root.client.company_contact_details">
                                    <div class="form-group">
                                        <label for="telephone" id="telephone-label" class="mandatory-label">
                                            Telephone *
                                        </label>
                                        <input
                                            v-model="telephone"
                                            type="text"
                                            id="telephone"
                                            class="form-control mandatory-field"
                                            :readonly="sending"
                                            v-on:keyup="$root.unHighlightErrors('telephone')"
                                            v-on:change="$root.unHighlightErrors('telephone')"
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div v-if="$root.client.company_contact_details">
                                    <div class="form-group">
                                        <label
                                            for="company_contact_name"
                                            id="company_contact_name-label"
                                            v-html="companyContactNameLabel()"
                                        ></label>
                                        <input
                                            v-model="company_contact_name"
                                            type="text"
                                            id="company_contact_name"
                                            class="form-control"
                                            :readonly="sending"
                                        >
                                    </div>
                                    <div class="form-group">
                                        <label
                                            for="company_contact_email"
                                            id="company_contact_email-label"
                                        >Contact's email address</label>
                                        <input
                                            v-model="company_contact_email"
                                            type="email"
                                            id="company_contact_email"
                                            class="form-control"
                                            :readonly="sending"
                                        >
                                    </div>
                                    <div class="form-group">
                                        <label
                                            for="company_contact_phone"
                                            id="company_contact_phone-label"
                                        >Contact's telephone</label>
                                        <input
                                            v-model="company_contact_phone"
                                            type="text"
                                            id="company_contact_phone"
                                            class="form-control"
                                            :readonly="sending"
                                        >
                                    </div>
                                </div>
                                <div v-if="!$root.client.company_contact_details">
                                    <div class="form-group">
                                        <label for="telephone" id="telephone-label" class="mandatory-label">
                                            Telephone *
                                        </label>
                                        <input
                                            v-model="telephone"
                                            type="text"
                                            id="telephone"
                                            class="form-control mandatory-field"
                                            :readonly="sending"
                                            v-on:keyup="$root.unHighlightErrors('telephone')"
                                            v-on:change="$root.unHighlightErrors('telephone')"
                                        >
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label
                                        for="register_password"
                                        id="register_password-label"
                                        class="mandatory-label"
                                    >
                                        Choose your password *
                                    </label>
                                    <input
                                        v-model="register_password"
                                        type="password"
                                        id="register_password"
                                        class="form-control mandatory-field"
                                        :readonly="sending"
                                        v-on:keyup="$root.unHighlightErrors('register_password')"
                                        v-on:change="$root.unHighlightErrors('register_password')"
                                    >
                                    <password-strength
                                        :password="register_password"
                                        v-model="password_score"
                                    ></password-strength>
                                </div>
                                <div class="form-group">
                                    <label
                                        for="confirm_password"
                                        id="confirm_password-label"
                                        class="mandatory-label"
                                    >
                                        Confirm password *
                                    </label>
                                    <input
                                        v-model="confirm_password"
                                        type="password"
                                        id="confirm_password"
                                        class="form-control mandatory-field"
                                        :readonly="(sending || (password_score < 7))"
                                        v-on:keyup="$root.unHighlightErrors('confirm_password')"
                                        v-on:change="$root.unHighlightErrors('confirm_password')"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="!sent" class="modal-footer">
            <span v-if="!sending">
                <span>
                    <button
                        type="button"
                        class="btn btn-primary"
                        :disabled="submitDisabled()"
                        :title="submitTitle()"
                        @click="reCaptcha()"
                    >
                        <i :class="submitIcon()"></i> {{ submitText() }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ submitText() }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                first_name: '',
                last_name: '',
                job_title: '',
                company: '',
                telephone: '',
                register_email: '',
                register_password: '',
                confirm_password: '',
                company_contact_name: '',
                company_contact_email: '',
                company_contact_phone: '',
                get_content_response: null,
                registration_info: null,
                client_company_name: '',
                company_contact_name_label: '',
                password_score: 0,
                terms_accepted: false,
                show_terms: false,
                terms_seen: false,
                registration_confirm_text: null,
                confirmed: false,
                company_title: 'company'
            }
        },
        mounted () {
            //
        },
        watch: {
            get_content_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.key == 'registration_info') {
                            this.registration_info = val.content;
                        }
                        if (val.key == 'company_name') {
                            this.client_company_name = val.content;
                        }
                        if (val.key == 'company_contact_name_label') {
                            this.company_contact_name_label = val.content;
                        }
                        if (val.key == 'registration_confirm_text') {
                            this.registration_confirm_text = val.content;
                        }
                        if (val.key == 'company_title') {
                            this.company_title = val.content;
                        }
                    }
                }
            },
        },
        methods: {
            resetForm: function () {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.first_name = '';
                this.last_name = '';
                this.job_title = '';
                this.company = '';
                this.telephone = '';
                this.register_email = '';
                this.register_password = '';
                this.confirm_password = '';
                this.company_contact_name = '';
                this.company_contact_email = '';
                this.company_contact_phone = '';
                this.terms_accepted = false;
                this.show_terms = false;
                this.term_seen = false;
                this.confirmed = false;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                if (this.$root.hasReCaptcha()) {
                    this.$recaptchaInstance.showBadge();
                }
                // Show additional registration info?
                this.$root.getContent(this, 'registration_info');
                this.$root.getContent(this, 'company_title');
                // Show company contact details fields?
                this.client_company_name = this.$root.client.client_name;
                if (this.$root.client.company_contact_details) {
                    this.$root.getContent(this, 'company_name');
                    this.$root.getContent(this, 'company_contact_name_label');
                }
                if (this.hasConfirmCheckbox(false)) {
                    this.$root.getContent(this, 'registration_confirm_text');
                }
            },
            companyContactNameLabel: function () {
                if (this.company_contact_name_label) {
                    return this.company_contact_name_label;
                }

                return 'If you are not a ' + this.client_company_name + ' employee please complete these fields.'
                    + '<br><br>Your contact at ' + this.client_company_name;
            },
            formTitle: function () {
                return (this.show_terms && !this.sent) ? 'Please agree to our terms of use and privacy policy' : 'Register';
            },
            submitIcon: function () {
                return this.show_terms ? 'far fa-check -1' : 'far fa-paper-plane mr-1';
            },
            submitText: function () {
                return this.show_terms ? 'Agree' : 'Submit';
            },
            submitDisabled: function () {
                return (this.password_score < 7 || (this.show_terms && !this.terms_seen));
            },
            submitTitle: function () {
                return (this.show_terms && !this.terms_seen) ? 'Please read to the bottom to agree' : '';
            },
            reCaptcha: async function () {
                if (!this.$root.hasReCaptcha()) {
                    this.onSubmit();

                    return null;
                }
                // (optional) Wait until recaptcha has been loaded.
                await this.$recaptchaLoaded();
                
                // Execute reCAPTCHA with action "register".
                const token = await this.$recaptcha('register');
                
                // Do stuff with the received token.
                this.onSubmit(token);
            },
            onSubmit: function (reCaptchaToken = null) {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.sending = true;
                this.errors = false;
                if (this.show_terms && this.terms_seen) {
                    this.terms_accepted = true; 
                }

                /**
                 * Send request to API to register user
                 */
                let data = {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    job_title: this.job_title,
                    company: this.company,
                    telephone: this.telephone,
                    email: this.register_email,
                    password: this.register_password,
                    confirm_password: this.confirm_password,
                    company_contact_name: this.company_contact_name,
                    company_contact_email: this.company_contact_email,
                    company_contact_phone: this.company_contact_phone,
                    terms_accepted: this.terms_accepted,
                    confirmed: this.confirmed,
                    validate_only: (this.$root.client.must_accept_terms && !this.terms_accepted),
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                };
                if (this.$root.hasReCaptcha()) {
                    data.recaptcha_token = reCaptchaToken;
                }

                const path = '/' + this.$root.app.client_id + '/user/register';
                const url = this.$root.app.api_url + path;
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(response => {
                    if (vm.$root.client.must_accept_terms && !vm.terms_accepted) {
                        vm.show_terms = true;
                    } else {
                        vm.sent = 'Success';
                        if (response.data.message) {
                            vm.sent = response.data.message;
                        }
                    }
                })
                .catch(function (error) {
                    vm.terms_accepted = false;
                    vm.show_terms = false;
                    vm.term_seen = false;
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response) {
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (let [key, value] of Object.entries(error.response.data.error)) {
                                    if (key == 'email') {
                                        key = 'register_email';
                                    }
                                    if (key == 'password') {
                                        key = 'register_password';
                                    }
                                    if (key == 'confirmed') {
                                        vm.errors += vm.registration_confirm_text + '<br>';
                                    } else {
                                        vm.errors += value.join('<br>').replace(/company/g, vm.company_title) + '<br>';
                                    }
                                    if ((key == 'first_name') || (key == 'last_name')) {
                                        $('#name-label').addClass('text-danger');
                                    } else {
                                        $('#' + key + '-label').addClass('text-danger');
                                    }
                                    $('#' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            },
            onTermsScroll: function () {
                if (this.show_terms) {
                    const scrollPos = document.getElementById('register-terms').scrollTop;
                    const fullHeight = document.getElementById('register-terms').scrollHeight;
                    const visibleHeight = $('#register-terms').innerHeight();
                    if ((fullHeight - scrollPos - visibleHeight) < 40) {
                        this.terms_seen = true;
                    }
                }
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'first_name':
                        text = 'First';
                        break;
                    case 'last_name':
                        text = 'Last';
                        break;
                }
                if (update) {
                    $('#' + field).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            unHighlightNameErrors: function () {
                if (this.first_name) {
                    $('#first_name').removeClass('field-error');
                }
                if (this.last_name) {
                    $('#last_name').removeClass('field-error');
                }
                if (this.first_name && this.last_name) {
                    $('#name-label').removeClass('text-danger');
                }
            },
            hasConfirmCheckbox: function (checkText = true) {
                if (!this.$root.client) {
                    return false;
                }
                if (!this.$root.client.registration_confirm) {
                    return false;
                }
                if (!checkText) {
                    return true;
                }
                if (!this.registration_confirm_text) {
                    return false;
                }

                return this.$root.client.registration_confirm_position || 'top';
            },
            onClose: function () {
                this.$root.hideReCaptchaBadge();
            }
        }
    }
</script>
