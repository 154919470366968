<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="artwork-share-modal-label">Email your artwork</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>

        <div class="modal-body">
            <div v-if="sent">
                <p>Your artwork has been sent to:</p>
                <p v-for="item in getRecipientEmails()" :key="item.id" class="mb-0">{{ item.email }}</p>
                <p v-if="$root.editing_artwork" class="mt-3 mb-0" style="font-weight: 500">
                    What would you like to do now?
                </p>
            </div>

            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form v-on:submit.prevent="onSubmit()">
                    <div class="form-group">
                        <label for="artwork-share-subject" id="artwork-share-subject-label" class="mandatory-label">
                            Subject line *
                        </label>
                        <input
                            v-model="subject"
                            type="text"
                            id="artwork-share-subject"
                            class="form-control mandatory-field"
                            :readonly="sending"
                            v-on:keyup="$root.unHighlightErrors('artwork-share-subject')"
                            v-on:change="$root.unHighlightErrors('artwork-share-subject')"
                        >
                    </div>
                    <div class="form-group dropdown">
                        <label id="artwork-share-recipients-label" class="mandatory-label">
                            Recipient email(s) *
                        </label>
                        <div
                            class="form-control mandatory-field pb-0"
                            id="artwork-share-recipients"
                            @click="focusRecipientKeyword()"
                            style="height: auto; min-height: 38px"
                        >
                            <span
                                v-for="item in recipients"
                                :key="item.email"
                                class="badge badge-soft-secondary contact-links font-size-12 mr-2 mb-2"
                                v-b-tooltip.hover
                                title=""
                            >
                                <span v-if="!item.name">{{ item.email }}</span>
                                <span v-else v-b-tooltip.hover :title="item.email">{{ item.name }}</span>
                                <a
                                    v-if="!sending"
                                    href="#"
                                    v-b-tooltip.hover
                                    title="Remove"
                                    @click.prevent="removeRecipient(item)"
                                >
                                    <i class="far fa-times"></i>
                                </a>
                                <span v-if="sending">
                                    <i class="far fa-times"></i>
                                </span>
                            </span>

                            <div style="display: inline-block; position: relative">
                                <input
                                    v-model="recipient_keyword"
                                    type="text"
                                    id="artwork-share-recipient-keyword"
                                    class="form-control px-1 mb-2"
                                    v-on:focus="searchRecipient(true)"
                                    v-on:blur="focusRecipientKeyword(false)"
                                    style="width: 280px; max-width: calc(100% - 40px)"
                                >
                                <button
                                    v-if="recipient_keyword"
                                    type="button"
                                    class="btn btn-secondary btn-sm"
                                    v-b-tooltip.hover
                                    title="Add recipient"
                                    @click="addRecipient()"
                                    :disabled="sending"
                                    style="padding-top: 1px; padding-bottom: 1px; position: absolute; top: 0"
                                >
                                    <i class="far fa-plus"></i>
                                </button>
                            </div>
                        </div>

                        <div
                            class="dropdown-menu artwork-share-recipient-dropdown"
                            style="width: 280px; max-height: 230px; overflow-y: auto"
                        >
                            <div v-for="item in recipient_results" :key="item.id">
                                <a
                                    class="dropdown-item"
                                    href="#"
                                    @click.prevent="addRecipient(item)"
                                >
                                    {{ item.first_name }} {{ item.last_name }}
                                    <p class="small mb-1">{{ item.email }}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="artwork-share-message">Message</label>
                        <textarea
                            v-model="message"
                            id="artwork-share-message"
                            class="form-control"
                            :readonly="sending"
                            style="height: 120px"
                        ></textarea>
                    </div>
                </form>
            </div>
        </div>

        <div v-if="(!sent || $root.editing_artwork)" class="modal-footer">
            <span v-if="sent" class="sent-buttons">
                <span class="left">
                    <span v-if="(!opening_artwork_editor || duplicating_artwork)">
                        <button
                            :key="'create-new-version-button'"
                            type="button"
                            class="btn btn-secondary btn-xs-block mr-sm-2 mr-0 mb-sm-0 mb-2"
                            :disabled="opening_artwork_editor"
                            @click="createArtwork()"
                        >
                            <i class="far fa-pen-paintbrush mr-1"></i> Create new version
                        </button>
                    </span>
                    <span v-else>
                        <button
                            :key="'creating-new-button'"
                            type="button"
                            class="btn btn-secondary btn-xs-block mr-sm-2 mr-0 mb-sm-0 mb-2"
                        >
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Create new version</span>
                        </button>
                    </span>
                    <span v-if="(!opening_artwork_editor || !duplicating_artwork)">
                        <button
                            :key="'copy-this-version-button'"
                            type="button"
                            class="btn btn-secondary  btn-xs-block mr-sm-2 mr-0 mb-sm-0 mb-2"
                            :disabled="opening_artwork_editor"
                            @click="duplicateArtwork()"
                        >
                            <i class="far fa-copy mr-1"></i> Copy this version
                        </button>
                    </span>
                    <span v-else>
                        <button
                            :key="'copying-this-button'"
                            type="button"
                            class="btn btn-secondary btn-xs-block mr-sm-2 mr-0 mb-sm-0 mb-2"
                        >
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">Copy this version</span>
                        </button>
                    </span>
                </span>
                <button
                    :key="'finish-button'"
                    type="button"
                    class="btn btn-primary btn-xs-block"
                    data-dismiss="modal"
                    :disabled="opening_artwork_editor"
                    @click="finish()"
                >
                    <i class="far fa-check mr-1"></i> Finish
                </button>
            </span>
            <span v-else>
                <span v-if="!sending">
                    <button :key="'send-button'" type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-paper-plane mr-1"></i> Send
                    </button>
                </span>
                <span v-else>
                    <button :key="'send-button-spinning'" type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">Send</span>
                    </button>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                artwork_id: null,
                subject: null,
                recipients: [],
                message: null,
                recipient_keyword: '',
                block_recipient_keyword_auto_process: false,
                recipient_results: [],
                keypress_time: 0,
                opening_artwork_editor: false,
                duplicating_artwork: false,
                template: null,
                editor: null
            }
        },
        mounted () {
            //
        },
        watch: {
            errors: function (val) {
                if (!val) {
                    $('.mandatory-label').removeClass('text-danger');
                    $('.mandatory-field').removeClass('field-error');
                }
            },
            recipient_keyword: function (val) {
                this.$root.unHighlightErrors('artwork-share-recipients');
                if (val) {
                    this.searchRecipient(true);

                    return null;
                }
                $('.artwork-share-recipient-dropdown').dropdown('hide');
            }
        },
        methods: {
            reset: function (artworkId) {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.artwork_id = artworkId;
                this.subject = this.$root.user.first_name + ' ' + this.$root.user.last_name
                    + ' has sent you a file from ' + this.$root.client.client_name;
                this.message = null;
                this.recipients = [];
                this.recipient_keyword = '';
                this.block_recipient_keyword_auto_process = false;
                if (this.$root.editing_artwork) {
                    this.editor = this.$root.findComponent(this.$parent, 'artwork-editor');
                }
            },
            getRecipientEmails: function () {
                let recipients = [];
                this.recipients.forEach((item, index) => {
                    recipients.push({ id: index, email: item.email });
                });
                if (this.recipient_keyword) {
                    recipients.push({ id: 'last', email: this.recipient_keyword });
                }

                return recipients;
            },
            onSubmit: function () {
                this.errors = false;
                this.sending = true;

                let data = {
                    type: 'email',
                    artwork_id: this.artwork_id,
                    subject: this.subject,
                    recipients: this.recipients,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                };
                if (this.recipient_keyword) {
                    data.recipients.push({email: this.recipient_keyword});
                }
                if (this.message) {
                    data.message = this.message;
                }

                /**
                 * Send share artwork request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/share';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.sent = response.data.message;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#artwork-share-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    // show error
                    vm.errors = 'Something went wrong, please try again';
                    if (error.response.data.error) {
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#artwork-share-' + key + '-label').addClass('text-danger');
                                $('#artwork-share-' + key).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            },
            focusRecipientKeyword: function (focus = true, delayed = false) {
                if (focus) {
                    $('#artwork-share-recipient-keyword').css('display', 'inline');
                    $('#artwork-share-recipient-keyword').focus();
                    var vm = this;
                    setTimeout(function () {
                        vm.block_recipient_keyword_auto_process = false;
                    }, 500);

                    return null;
                }

                if (!delayed) {
                    var vm = this;
                    setTimeout(function () {
                        vm.focusRecipientKeyword(false, true);
                    }, 500);

                    return null;
                }
                if (this.block_recipient_keyword_auto_process) {
                    return null;
                }
                if (this.recipient_keyword) {
                    this.addRecipient(null, false);
                }
                $('#artwork-share-recipient-keyword').css('display', 'none');
                $('.artwork-share-recipient-dropdown').dropdown('hide');
            },
            searchRecipient: function (delay = false) {
                if (!this.recipient_keyword) {
                    return null;
                }
                if (delay) {
                    this.keypress_time = new Date();
                    var vm = this;
                    setTimeout(function () {
                        vm.searchRecipient();
                    }, 500);
                }

                const time = new Date();
                if ((time - this.keypress_time) < 500) {
                    return null;
                }

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|approved|!declined|!deleted|!invited|kw::'
                    + btoa(this.recipient_keyword);
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.recipient_results = [];
                    response.data.users.forEach((item) => {
                        if ((item.email != vm.$root.user.email) && !vm.isRecipientAdded(item.email)) {
                            vm.recipient_results.push(item);
                        }
                    });
                    if (vm.recipient_results.length > 0) {
                        setTimeout(function () {
                            $('.artwork-share-recipient-dropdown').dropdown('show');
                        }, 200);

                        return null;
                    }
                    $('.artwork-share-recipient-dropdown').dropdown('hide');
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#artwork-share-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                });
            },
            isRecipientAdded: function (email) {
                if (!email || (this.recipients.length == 0)) {
                    return false;
                }
                let found = false;
                this.recipients.forEach((item) => {
                    if (item.email.toLowerCase() == email.toLowerCase()) {
                        found = true;
                    }
                });

                return found;
            },
            addRecipient: function (user = null, addNew = true) {
                if (!user && !this.recipient_keyword) {
                    return null;
                }
                const email = user ? user.email.trim() : this.recipient_keyword.trim();

                this.recipient_keyword = '';
                this.recipient_results = [];
                setTimeout(function () {
                    $('.artwork-share-recipient-dropdown').dropdown('hide');
                }, 250);

                if ((email.length > 0) && !this.isRecipientAdded(email)) {
                    const recipient = {
                        email: email,
                        name: user ? user.first_name + ' ' + user.last_name : null,
                        user_id : user ? user.id : null
                    };
                    this.recipients.push(recipient);
                    //this.onSubmit(true);
                }

                if (addNew) {
                    this.block_recipient_keyword_auto_process = true;
                    var vm = this;
                    setTimeout(function () {
                        vm.focusRecipientKeyword();
                    }, 200);
                }
            },
            removeRecipient: function (recipient) {
                this.$root.unHighlightErrors('artwork-share-recipients');
                this.block_recipient_keyword_auto_process = true;
                let itemIndex = -1;
                this.recipients.forEach((item, index) => {
                    if (item == recipient) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.recipients.splice(itemIndex, 1);
                }
            },
            finish: function (action = null, delay = true) {
                if (delay) {
                    var vm = this;
                    setTimeout(function () {
                        vm.finish(action, false);
                    }, 200);

                    return null;
                }
                if (this.$root.editing_artwork) {
                    this.$parent.hideArtworkEditor();
                }
                if (!action) {
                    return null;
                }

                if (this.$route.name == action) {
                    return null;
                }
                this.$router.push({ name: action });
            },
            createArtwork: function () {
                this.opening_artwork_editor = true;
                this.duplicating_artwork = false;
                this.template = this.editor.template;

                /**
                 * Send get template details request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.editor.asset.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.template.fields = response.data.template.fields;
                    vm.showArtworkEditor();
                })
                .catch(function (error) {
                    //vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.opening_artwork_editor = false;
                });
            },
            duplicateArtwork: function () {
                this.opening_artwork_editor = true;
                this.duplicating_artwork = true;
                this.template = this.editor.template;

                /**
                 * Send get artwork request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.template.id + '/artwork/' + this.editor.artwork.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.showArtworkEditor(response.data.artwork, true);
                })
                .catch(function (error) {
                    //vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.opening_artwork_editor = false;
                });
            },
            showArtworkEditor: function (artwork = null, duplicate = false) {
                $('#artwork-share-modal').modal('hide');
                this.$parent.showArtworkEditor(
                    this.editor.asset,
                    this.template,
                    artwork,
                    duplicate
                );
            }
        }
    }
</script>
<style scoped>
    #artwork-share-recipient-keyword {
        display: none;
        width: auto;
        height: 20px;
        border: none;
    }

    #artwork-share-recipient-keyword:focus {
        background-color: #f8f9fa;
    }

    .sent-buttons .left {
        position: absolute;
        left: 15px;
    }

    @media (max-width: 575px) {
        .sent-buttons {
            width: 100%;
        }

        .sent-buttons .left {
            position: relative;
            left: 0;
            display: block;
            width: 100%;
        }

        .sent-buttons .btn-xs-block {
            width: 100%;
        }
    }
</style>
